/**
 * Joins all dynamic pages in a doc (for pagination)
 * - respects manual page boundaries
 * - only joins dynamic pages
 * - optional: only join pages between startPageIndex and next manual page
 * @param {EditorState} editorState - PM state instance
 * @param {object} [diffRange=null] - {start,end}
 * @returns {Transaction} - PM transaction
 */
function joiner(editorState, diffRange = null) {
  const { doc, tr } = editorState
  const startPageIndex = diffRange
    ? editorState.doc.resolve(diffRange.start).index(0)
    : null
  // TODO: this is not the most ideal / elegant way to do this; it would be
  // better to employ a regular loop that allow us to exit this function early.
  // i'm not doing that for now because i'm not sure how to calculate the index
  // for the next page after a join operation. could just be index remains same
  // for next loop?
  let stop = false
  // retain custom page numbers for splitting
  const pageNumbers = []
  doc.forEach((page, offset, index) => {
    // skip if this is the first page,
    // we've detected a stopping point,
    // or there's a start page index and we're not past it yet
    if (
      offset === 0 ||
      stop ||
      (startPageIndex != null && index <= startPageIndex)
    ) {
      return
    }
    // handle manual page breaks and locked pages
    if (page.attrs.locked || !page.attrs.dynamic) {
      if (diffRange != null) {
        const diffEnd = tr.mapping.map(diffRange.end)
        const pagePos = tr.mapping.map(offset)
        // if there's a diff range and this manual break lies past it, stop join
        // (must also stop if diffEnd and pagePos are same)
        if (diffEnd <= pagePos) {
          stop = true
        }
      }
      // return no matter what if this is a manual break (never join manual)
      return
    }
    // retain page number if it exists here
    if (page.attrs.pageNumber) {
      pageNumbers[index] = page.attrs.pageNumber
    }
    const pos = tr.mapping.map(offset)
    tr.join(pos)
  })
  return { tr, pageNumbers }
}
export default joiner
