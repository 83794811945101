import cn from 'classnames'
import { UnstyledButton, Flex } from '@mantine/core'
import { FaIcon } from '@components/FaIcon'

import styles from './TemplatesListItem.module.scss'

export const TemplateListItem = ({
  label,
  selected,
  onClick,
  itemType,
  sequence,
}: {
  label: string
  selected: boolean
  onClick: () => void
  itemType?: 'active' | 'new'
  sequence?: number
}) => {
  const icon = itemType === 'new' ? 'fa-plus' : 'fa-check'
  const iconColor =
    itemType === 'active'
      ? 'green'
      : itemType === 'new'
      ? undefined
      : 'transparent'
  return (
    <UnstyledButton
      className={cn(styles.item, { [styles.selected]: selected })}
      onClick={onClick}
      variant={selected ? 'light' : 'subtle'}
    >
      <Flex gap={10}>
        <FaIcon span icon={icon} c={iconColor} />
        <div style={{ flex: 1 }}>{label}</div>
        <div style={{ width: 20 }}>{sequence ?? ''}</div>
      </Flex>
    </UnstyledButton>
  )
}
