import { ApiConfig } from './types'

type SendOtpResponse = { oneTimePasscodeId: string }

export const changeStytchPassword: ApiConfig<
  {
    newPassword: string
    oldPassword: string
  },
  { status: 'success' }
> = ({ newPassword, oldPassword }) => ({
  method: 'PUT',
  url: '/users/password/change',
  data: { old: oldPassword, new: newPassword },
})

export const getUserStatusForForgotPassword: ApiConfig<
  string,
  { status: 'LEGACY' | 'STYTCH' | 'MISSING' }
> = (email) => ({
  method: 'POST',
  url: '/user-status',
  data: { email },
})

export const createAccountStytch: ApiConfig<
  {
    name: string
    email: string
    password: string
    token: string
  },
  void
> = (data) => ({
  method: 'POST',
  url: '/signup-otp',
  data,
})

export const initiateStychPasswordReset: ApiConfig<string, SendOtpResponse> = (
  email
) => ({
  method: 'POST',
  url: '/initiate-password-reset',
  data: { email },
})

export const initiateAccountMigration: ApiConfig<string, SendOtpResponse> = (
  email
) => ({
  method: 'POST',
  url: '/initiate-account-migration',
  data: { email },
})

export const initiateEmailChange: ApiConfig<
  {
    userId: string
    email: string
  },
  SendOtpResponse
> = ({ userId, email }) => ({
  method: 'POST',
  url: `/users/${userId}/initiate-email-change`,
  data: { email },
})

export const changeEmail: ApiConfig<
  {
    userId: string
    email: string
    oneTimePasscodeId: string
    code: string
  },
  void
> = ({ userId, email, oneTimePasscodeId, code }) => ({
  method: 'POST',
  url: `/users/${userId}/change-email`,
  data: { email, oneTimePasscodeId, code },
})
