import html from 'nanohtml'
import raw from 'nanohtml/raw'
import { format } from 'date-fns'
import Dropdown from '../../../../components/dropdown.js'
import { commentAvatar } from './comment-avatar.js'
import { commentMenu } from './comment-menu.js'
import {
  isEditingActive,
  isCommentEditable,
  autolink,
} from './state-selectors.js'
function commentView(comment, state, actions, components) {
  const isEditing = isEditingActive(state, comment)
  const className = `c-comment c-comment--self ${
    isEditing ? 'c-comment--edit' : ''
  }`
  // TODO: if date is today, use different format (ie: 'h:MM TT Today')
  const timestamp = format(new Date(comment.createdAt), 'LLLL d, yyyy')
  const canEditComment = isCommentEditable(state, comment)
  const author = comment.creator
  const kebabDropdown = canEditComment
    ? new Dropdown({ button: '.o-button' })
    : ''
  return html`
    <div class=${className}>
      <div class="c-comment__selfrow">
        <div class="c-comment__byline">
          ${commentAvatar(author)}
          <div class="c-comment__meta">
            <div class="c-comment__author"><strong>${author.name}</strong></div>
            <div class="c-comment__timestamp">${timestamp}</div>
          </div>
        </div>
        ${canEditComment
          ? kebabDropdown.render(commentMenu(comment, actions))
          : ''}
      </div>
      ${commentText(comment, state, actions, components)}
    </div>
  `
}
function commentText(comment, state, actions, components) {
  if (!isEditingActive(state, comment)) {
    // we only autolink for display, textarea gets the raw text
    const commentText =
      typeof comment.text === 'string'
        ? raw(autolink(state, comment.text))
        : html`<em class="is-light">Comment deleted by author</em>`
    return html`<div class="c-comment__content">${commentText}</div>`
  }
  return components.editForm.render(comment, state, actions, true)
}
export { commentView }
export default {
  commentView,
}
