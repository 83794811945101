import React from 'react'
import { useMst } from '@state'
import { useLocation } from 'wouter'

// this hook watches the pathname and triggers appropriate
// state changes when the pathname changes.
export const useRouteChangeEffects = () => {
  const [pathname] = useLocation()
  const mst = useMst()

  React.useEffect(() => {
    const requestedScriptId = mst.location.getPathParam('scriptId')
    const requestedRundownId = mst.location.getPathParam('rundownId')
    if (mst.currentScript?.id !== requestedScriptId) {
      mst.removeCurrentScript()
    }
    if (String(mst.currentRundown?.id) !== requestedRundownId) {
      mst.removeCurrentRundown()
    }
  }, [mst, pathname])
}
