import { CSSProperties } from 'react'

const SLOT_CONTENT_TYPES = [
  'full-title',
  'short-title',
  'timestamp',
  'page-number',
  'page-number-total',
] as const

export type SlotContent = typeof SLOT_CONTENT_TYPES[number]

export function isSlotContent(value: string): value is SlotContent {
  return SLOT_CONTENT_TYPES.includes(value as SlotContent)
}

export type SlotPosition =
  | 'topLeft'
  | 'topCenter'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomCenter'
  | 'bottomRight'

export type PageMarginSizes = Required<
  Pick<CSSProperties, 'top' | 'left' | 'bottom' | 'right'>
>

export type PageSize = Required<Pick<CSSProperties, 'height' | 'width'>>

export type PageLayout = {
  margins: PageMarginSizes
  orientation: 'portrait' | 'landscape'
  size: PageSize
}

// this is a general print strategy for printing with docraptor/prince. It doesn't assume
// that you're printing a script or that you're printing on 8.5 x 11 portrait. It's a bit TOO general
// at the moment but once we add printing of things like rundowns or side-by-side diffs, it will allow us
// to reuse the really universal parts
export interface IPrincePrintStrategy {
  generatePageLayout(): PageLayout
  generateStyles(): string
  generateHeaderAndFooter(): string
  generateBody(): string
  generateHeadElements(): string
  // FUTURE: we might want to add a fonts configuration rather than
  // always hard-coding it
}
