import NiceModal from '@ebay/nice-modal-react'
import { Anchor, Group, Image, Stack, Text, Title } from '@mantine/core'
import { useMst } from '@state'
import { ModalShell, useModalControls } from '@components/Modals'
import SEARCH_PNG from '@assets/images/sidebar-search.jpg'
import styles from './WhatsNew.module.scss'

export const LAST_WHATS_NEW_UPDATE = new Date('2023-11-26').getTime()

export const WhatsNew = () => {
  const controls = useModalControls()
  const { user } = useMst()
  const lastSeen = user.prefs.whatsNew ?? 0

  const handleClose = () => {
    user.updatePreferences({ whatsNew: new Date().getTime() })
    controls.onClose()
  }

  // dont show the new bling more than once
  if (lastSeen > LAST_WHATS_NEW_UPDATE) return null

  return (
    <ModalShell
      title="New in Scripto"
      onConfirm={handleClose}
      confirmLabel="OK"
      size={530}
      opened={controls.opened}
      onClose={handleClose}
    >
      <Group>
        <Image
          maw={300}
          className={styles.modal_img}
          src={SEARCH_PNG}
          alt="Searching for documents from the sidebar"
        ></Image>
        <Stack w={180}>
          <Title order={4}>Search has moved to the sidebar ✨</Title>
          <Text>
            Now you can search from any page.&nbsp;
            <Anchor
              target="_blank"
              rel="noopener noreferrer"
              href="https://scripto.live/whats-new"
            >
              Learn more
            </Anchor>
            .
          </Text>
        </Stack>
      </Group>
    </ModalShell>
  )
}

export const WhatsNewModal = NiceModal.create(WhatsNew)
export const showWhatsNewModal = () => NiceModal.show(WhatsNewModal)
