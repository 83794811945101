import { FindListingsPayload, GetFolderPayload } from '../types'
import { ApiConfig } from './types'

export const getRecentListings: ApiConfig<
  { orgId: string },
  FindListingsPayload
> = ({ orgId }) => ({
  method: 'GET',
  url: `/orgs/${orgId}/listings/latest`,
})

export const getFavoriteListings: ApiConfig<
  {
    orgId: string
    rundownIds: number[]
    scriptIds: string[]
  },
  FindListingsPayload
> = ({ orgId, rundownIds, scriptIds }) => ({
  method: 'POST',
  url: `/orgs/${orgId}/listings/find`,
  data: {
    rundownIds,
    scriptIds,
  },
})

export const getFolderDetails: ApiConfig<string, GetFolderPayload> = (
  folderId
) => ({
  method: 'GET',
  url: `/folders/${folderId}/details`,
})
