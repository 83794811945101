// note: attempting to inject a mantine component here will induce an error because
// the resulting HTML does NOT render within the corresponding provider
import cn from 'classnames'
import { SlotContent } from './types'
import { HeaderOrFooterPrefs, RundownPrintPrefs } from '../LocalPersistence'

const SingleMarginSlot = ({
  position,
  content,
  large,
}: {
  position: 'left' | 'right' | 'center'
  content?: SlotContent
  large?: boolean
}) => {
  return (
    <div className={cn('header-footer', position, content, { large })}>
      <span />
    </div>
  )
}

type HeaderFooterProps = {
  header: HeaderOrFooterPrefs
  footer: HeaderOrFooterPrefs
}

export const HeaderAndFooter = ({ header, footer }: HeaderFooterProps) => (
  <>
    {header.enabled && (
      <header>
        <div
          className={cn('header-contents', {
            'has-center-content': header.center,
          })}
        >
          <SingleMarginSlot position="left" content={header.left} />
          <SingleMarginSlot position="center" content={header.center} />
          <SingleMarginSlot position="right" content={header.right} />
        </div>
        <hr
          className={cn('header-footer-divider', { hidden: !header.divider })}
        />
      </header>
    )}

    {footer.enabled && (
      <footer>
        <hr
          className={cn('header-footer-divider', { hidden: !footer.divider })}
        />
        <div
          className={cn('footer-contents', {
            'has-center-content': footer.center,
          })}
        >
          <SingleMarginSlot position="left" content={footer.left} />
          <SingleMarginSlot position="center" content={footer.center} />
          <SingleMarginSlot position="right" content={footer.right} />
        </div>
      </footer>
    )}
  </>
)

export const RundownHeaderAndFooter = ({
  gridHeaderHtml,
  prefs,
}: {
  gridHeaderHtml: string
  prefs: RundownPrintPrefs
}) => {
  return (
    <>
      <header>
        <div className="header-contents">
          <SingleMarginSlot position="left" content="short-title" large />
          <SingleMarginSlot position="center" />
          <SingleMarginSlot position="right" />
        </div>

        <div
          className={cn('rundown-header', prefs.rowDensity)}
          dangerouslySetInnerHTML={{ __html: gridHeaderHtml }}
        />
      </header>

      <footer>
        <div className="rundown-footer-space"></div>
        <div className="footer-contents">
          <SingleMarginSlot position="left" content="timestamp" />
          <SingleMarginSlot position="center" />
          <SingleMarginSlot position="right" content="page-number-total" />
        </div>
      </footer>
    </>
  )
}
