import React from 'react'
import { observer } from 'mobx-react-lite'
import { Menu, Tooltip } from '@mantine/core'
import { FancyMenuItem } from '@components/FancyMenuItem'
import { StatusBar } from '@components/StatusBar'
import { useMst } from '@state'

export const WrapText = observer(function WrapText() {
  const { currentInkProject } = useMst()
  const [open, setOpen] = React.useState(false)
  const lineWrap = currentInkProject?.preferences?.lineWrap

  const setLineWrap = (lineWrap: boolean) => {
    currentInkProject?.setPreferences({ lineWrap })
  }

  return (
    <Menu onChange={setOpen} opened={open}>
      <Menu.Target>
        <Tooltip label="Text wrapping" disabled={open}>
          <StatusBar.FaActionIcon icon="fa-line-height" />
        </Tooltip>
      </Menu.Target>
      <Menu.Dropdown>
        <FancyMenuItem
          title="Wrap text"
          selected={lineWrap}
          onClick={() => setLineWrap(true)}
        />
        <FancyMenuItem
          title="Don't wrap text"
          selected={!lineWrap}
          onClick={() => setLineWrap(false)}
        />
      </Menu.Dropdown>
    </Menu>
  )
})
