import cn from 'classnames'
import {
  format,
  isThisYear,
  isToday,
  isYesterday,
  isEqual,
  startOfToday,
  subDays,
  differenceInDays,
} from 'date-fns'
import { Group, ActionIcon, Text, Divider, Space } from '@mantine/core'
import { FaIcon } from '@components/FaIcon'
import { SHORT_DAY_MASK, SHORT_DAY__YEARLESS_MASK } from '@hooks'
import { capitalize } from '@util'
import { usePendingSearchContext, useSearchContext } from './SearchContext'
import { SearchCriteria, hasActiveFilter, FILTERTAG_CLASSNAME } from './helpers'

import styles from './FilterTags.module.scss'
import { useMst } from '@state'

const PREFIX = 'Last edited '

const formatDate = (date: Date) =>
  format(date, isThisYear(date) ? SHORT_DAY__YEARLESS_MASK : SHORT_DAY_MASK)

const ModifiedLabel = ({
  date,
  isAfter = false,
}: {
  date: Date
  isAfter?: boolean
}) => (
  <>
    {PREFIX + (isAfter ? 'on or after' : 'before')}&thinsp;
    <Text span fw="bold">
      {formatDate(date)}
    </Text>
  </>
)

const DateFilterTag = ({
  after,
  before,
  onReset,
  onClick,
}: {
  after?: Date
  before?: Date
  onReset: () => void
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void
}) => {
  after
  before
  let label: React.ReactNode = 'Custom dates'

  if (before && !after) label = <ModifiedLabel date={before} />

  if (!before && after) {
    label = <ModifiedLabel date={after} isAfter />

    if (isToday(after)) {
      label = (
        <>
          {PREFIX}
          <Text span fw="bold">
            today
          </Text>
        </>
      )
    }

    if (isEqual(after, subDays(startOfToday(), 6))) {
      label = (
        <>
          {PREFIX}
          <Text span fw="bold">
            within 7 days
          </Text>
        </>
      )
    }

    if (isEqual(after, subDays(startOfToday(), 29))) {
      label = (
        <>
          {PREFIX}
          <Text span fw="bold">
            within 30 days
          </Text>
        </>
      )
    }
  }

  if (before && after) {
    label = (
      <>
        {PREFIX}
        <Text span fw="bold">
          {formatDate(after) + ' - ' + formatDate(before)}
        </Text>
      </>
    )

    if (differenceInDays(before, after) === 1) {
      label = (
        <>
          {PREFIX}
          <Text span fw="bold">
            {formatDate(after)}
          </Text>
        </>
      )
    }

    if (isYesterday(after) && isToday(before)) {
      label = (
        <>
          {PREFIX}
          <Text span fw="bold">
            yesterday
          </Text>
        </>
      )
    }
  }
  return <Tag label={label} onClick={onClick} onReset={onReset} />
}

const FolderFilterTag = ({
  name,
  onReset,
  onClick,
}: {
  name?: string
  onReset: () => void
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void
}) => {
  const label = (
    <>
      In&nbsp;
      <Text span fw="bold">
        {name}
      </Text>
    </>
  )

  return <Tag label={label} onClick={onClick} onReset={onReset} />
}

const Tag = ({
  label,
  onReset,
  onClick,
}: {
  label: React.ReactNode
  onReset: () => void
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void
}) => {
  return (
    <div className={styles.search_filterTag}>
      <div
        tabIndex={0}
        className={cn(styles.search_filterTagText, FILTERTAG_CLASSNAME)}
        onClick={(e) => onClick(e)}
      >
        <Text size="12">{label}</Text>
      </div>
      <Space w={5} />
      <Divider color="violet.1" orientation="vertical" mx={2} />
      <ActionIcon className={styles.search_filterTagClose} onClick={onReset}>
        <FaIcon c="dark.9" icon="fa-xmark" size="12" />
      </ActionIcon>
    </div>
  )
}

export const FilterTags = ({
  tagRef,
  onResetDateFilter,
  onResetFolderFilter,
  popoverOpened,
  setPopoverOpened,
}: {
  tagRef: React.Ref<HTMLDivElement>
  onResetDateFilter?: () => void
  onResetFolderFilter?: () => void
  popoverOpened: boolean
  setPopoverOpened?: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const mst = useMst()
  const context = useSearchContext()
  const pendingContext = usePendingSearchContext()
  /*
  TODO: advanced search
  this is an annoying side-effect of each context being null initially
  */
  if (!context || !pendingContext) return <></>

  const { criteria } = context
  if (!hasActiveFilter(criteria)) return <></>

  const { docType, folderId, includeTitleOnly, modifiedAfter, modifiedBefore } =
    criteria

  const handleReset = (resetVal: Partial<SearchCriteria>) => {
    pendingContext.mergePendingCriteria(resetVal)
    context.setCriteria({
      ...context.criteria,
      ...resetVal,
    })
  }

  // TODO: advanced search
  // it would be superior not to close the popover on filter tag click when it was already open
  // we do it to prevent the popover from losing focus and becoming undismissable.
  // this whole cartwheel would be avoidable if we could yank our janky custom onBlur logic
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setPopoverOpened?.(!popoverOpened)
  }

  return (
    <Group gap={5} ref={tagRef}>
      {docType && (
        <Tag
          label={`${capitalize(docType)}s only`}
          onReset={() => handleReset({ docType: undefined })}
          onClick={handleClick}
        />
      )}
      {includeTitleOnly && (
        <Tag
          label="Title only"
          onReset={() => handleReset({ includeTitleOnly: false })}
          onClick={handleClick}
        />
      )}
      {(modifiedAfter || modifiedBefore) && (
        <DateFilterTag
          after={modifiedAfter}
          before={modifiedBefore}
          onReset={() => {
            onResetDateFilter?.()
            handleReset({ modifiedAfter: undefined, modifiedBefore: undefined })
          }}
          onClick={handleClick}
        />
      )}
      {folderId && (
        <FolderFilterTag
          name={mst.view.getFolderName(folderId)}
          onReset={() => {
            onResetFolderFilter?.()
            handleReset({ folderId: undefined })
          }}
          onClick={handleClick}
        />
      )}
    </Group>
  )
}
