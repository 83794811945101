import React from 'react'
import { useSnapshotData, useScriptData } from '../useSnapshotQueries'
import { useMst } from '@state'
import { format } from 'date-fns'
import { createProsemirrorDoc, extractSleneFragment, listSlenes } from '@util'
import { SideBySideDiff } from './SideBySideDiff'
import { UnknownErrorPage } from '@components/ErrorPage'
import { usePrintableRef } from '@util/printing'
import { SideBySideViewMenu } from './SideBySideViewMenu'
import { SlenePickerToolbarButton } from './SlenePickerToolbarButton'
import { SleneItem, showSelectSlenes } from './SelectSlenesModal'

import { SnapshotPage } from '../SnapshotPage'

const SNAPSHOT_DATE_FMT = "MMM d, yyyy' - 'h:mm aaa"
const FETCHED_AT_DATE_FMT = "'as of 'h:mm aaa"

const parseLinesOfContext = (
  queryParamValue: string | undefined
): number | undefined => {
  if (typeof queryParamValue === 'string') {
    const intValue = parseInt(queryParamValue)
    if (intValue >= 0 && intValue < 100) {
      return intValue
    }
  }
}

export const SideBySideDiffPage = ({
  params,
}: {
  params: {
    scriptId: string
    snapshotId: string
  }
}) => {
  const { location } = useMst()
  const linesOfContext = parseLinesOfContext(location.getQueryParam('context'))
  const { scriptId, snapshotId } = params
  const {
    isError: isScriptError,
    isLoading: isScriptLoading,
    data: scriptPayload,
  } = useScriptData(scriptId)

  const {
    isError: isSnapshotError,
    isLoading: isSnapshotLoading,
    data: snapshotPayload,
  } = useSnapshotData({ snapshotId, scriptId })

  const leftPmDoc = snapshotPayload
    ? createProsemirrorDoc(snapshotPayload?.doc)
    : null

  const rightPmDoc = scriptPayload
    ? createProsemirrorDoc(scriptPayload.script.doc)
    : null

  const leftSleneItems: SleneItem[] = leftPmDoc ? listSlenes(leftPmDoc) : []
  const rightSleneItems: SleneItem[] = rightPmDoc ? listSlenes(rightPmDoc) : []

  const [monochrome, setMonochrome] = React.useState(false)
  const { printRef, handlePrint } = usePrintableRef({
    fileName: scriptPayload?.script.name ?? 'Side-by-side comparison',
    templateWrap: {
      headerHeight: 120,
      footerHeight: 20,
    },
  })

  const isScreenplay = scriptPayload?.script.doc.attrs.docType === 'screenplay'
  const [leftSlene, setLeftSlene] = React.useState<SleneItem | null>(null)
  const [rightSlene, setRightSlene] = React.useState<SleneItem | null>(null)

  const handlePickSlenes = ({
    left,
    right,
  }: {
    left: SleneItem | null
    right: SleneItem | null
  }) => {
    setLeftSlene(left)
    setRightSlene(right)
  }

  const showSlenePickerModal = () => {
    showSelectSlenes({
      leftItems: leftSleneItems,
      rightItems: rightSleneItems,
      selectedLeftId: leftSlene?.id,
      selectedRightId: rightSlene?.id,
      sleneType: isScreenplay ? 'scene' : 'slug',
      onSelect: handlePickSlenes,
    })
  }

  const scriptFetchedAt = scriptPayload
    ? format(scriptPayload.fetchedAt, FETCHED_AT_DATE_FMT)
    : ''
  const snapshotTimestamp = snapshotPayload
    ? format(new Date(snapshotPayload.createdAt), SNAPSHOT_DATE_FMT)
    : ''
  const snapshotName = snapshotPayload?.name ?? 'Snapshot'

  const leftFragment =
    leftPmDoc && leftSlene
      ? extractSleneFragment(leftPmDoc, leftSlene.id)
      : leftPmDoc?.content
  const rightFragment =
    rightPmDoc && rightSlene
      ? extractSleneFragment(rightPmDoc, rightSlene.id)
      : rightPmDoc?.content

  return (
    <SnapshotPage loading={isScriptLoading || isSnapshotLoading} {...params}>
      <SnapshotPage.Toolbar
        activeTab="diff"
        scriptId={scriptId}
        snapshotId={snapshotId}
        viewMenuItems={
          <SideBySideViewMenu
            monochrome={monochrome}
            setMonochrome={setMonochrome}
            linesOfContext={linesOfContext}
          />
        }
        onPrint={handlePrint}
        slenePicker={
          <SlenePickerToolbarButton
            sleneType={isScreenplay ? 'scene' : 'slug'}
            isComparing={!!(leftSlene || rightSlene)}
            onClick={showSlenePickerModal}
            disabled={leftSleneItems.length + rightSleneItems.length === 0}
          />
        }
      />

      {(isScriptError || isSnapshotError) && <UnknownErrorPage />}
      {leftFragment && rightFragment && (
        <SideBySideDiff
          ref={printRef}
          leftTitle={`${snapshotName} - ${snapshotTimestamp}`}
          leftFragment={leftFragment}
          rightFragment={rightFragment}
          rightSubtitle={scriptFetchedAt}
          linesOfContext={linesOfContext}
          monochrome={monochrome}
          leftSleneName={leftSlene?.label}
          rightSleneName={rightSlene?.label}
        />
      )}
    </SnapshotPage>
  )
}
