import React from 'react'
import { observer } from 'mobx-react-lite'
import { formatDistanceToNow } from 'date-fns'
import { ILoadedScript } from '@state'
import { SyncStatusLevel } from '@state/models/SyncStatus'
import { SyncStatusCloud, BadSocketCloud } from '../SyncStatusCloud'
import { showDebugSyncModal } from './DebugSyncModal'
import { useSyncStatusEffects } from './useSyncStatusEffects'

// exported for storybook
export const ScriptSyncStatusInternal = ({
  level,
  age,
  mostRecentSync,
  isSyncing,
}: {
  level: SyncStatusLevel
  age: number
  mostRecentSync: Date
  isSyncing: boolean
}) => {
  switch (level) {
    case 'ok': {
      return (
        <SyncStatusCloud
          status={level}
          tooltip={isSyncing ? 'Syncing' : 'Document in sync'}
          iconType={isSyncing ? 'syncing' : 'check'}
        />
      )
    }
    case 'warn': {
      const dots = ' . . .'.slice(0, (age % 4) * 2)
      return (
        <SyncStatusCloud
          status="warn"
          tooltip={'Trying to sync ' + dots}
          iconType="syncing"
        />
      )
    }
    default: {
      const ago = formatDistanceToNow(mostRecentSync, { addSuffix: true })
      return (
        <SyncStatusCloud
          status="error"
          tooltip={`Work last saved ${ago}`}
          iconType="xmark"
        />
      )
    }
  }
}

export const ScriptSyncStatus = observer(function ScriptSyncStatus({
  script,
}: {
  script: ILoadedScript
}) {
  const socketStatus = script.rootStore.socketManager.status
  const { stalest, inSync, secondsOfStaleness, status } = script.syncStatus
  const debugSync = script.rootStore.view.debugSync

  const handleClick = () => {
    if (debugSync) {
      showDebugSyncModal({ script })
    }
  }

  useSyncStatusEffects(script)

  if (socketStatus !== 'connected') {
    return <BadSocketCloud type={socketStatus} />
  }

  return (
    <div onClick={handleClick}>
      <ScriptSyncStatusInternal
        level={status}
        age={secondsOfStaleness}
        mostRecentSync={stalest ?? new Date()}
        isSyncing={!inSync}
      />
    </div>
  )
})
