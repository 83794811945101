import React from 'react'
import { Anchor, Text } from '@mantine/core'
import { CommentMeta } from '@util/ScriptoApiClient/types'
import { URLS_ANYWHERE } from '@util/constants'
import { PopoverContext } from './CommentHistory'
import { Counter, ThreadCount } from './helpers'

import styles from './CommentHistory.module.scss'

function jumpToComment(threadId: string, counters: Counter[]) {
  for (const counter of counters) {
    if (counter.commentIds?.includes(threadId)) {
      counter.el.scrollIntoView({ behavior: 'smooth', block: 'center' })
      const thread: HTMLElement | null = document.querySelector(
        `#comment-${threadId}`
      )
      // if it's not already open, force a click on the counter to show it
      if (thread === null) counter.el.click()

      // stop looping
      break
    }
  }
}

export const JumpLink = ({
  comment,
  counters,
  threadCounts,
}: {
  comment: CommentMeta
  counters: Counter[]
  threadCounts: ThreadCount
}) => {
  const { hidePopover } = React.useContext(PopoverContext)

  const threadId = comment.parentId || comment.id
  // thread exists in at least one of the counters
  const exists = counters.some((c) => c.commentIds?.includes(threadId))
  if (!exists) return null

  const label = comment.parentId
    ? `Thread (${threadCounts[comment.parentId]})`
    : 'Comment'

  return (
    <Anchor
      onClick={() => {
        hidePopover()
        jumpToComment(threadId, counters)
      }}
      className={styles.comment_link}
    >
      Jump to {label}
    </Anchor>
  )
}

// seems like overkill to dangerouslySetInnerHTML to preserve stuff like '&apos;
// but its more or less what we were doing in choo-land...
export const AutolinkedText = ({ text }: { text: string }) => {
  if (!text.match(URLS_ANYWHERE)) {
    return <Text span dangerouslySetInnerHTML={{ __html: text }}></Text>
  }

  // how to create a stable, predictable, unique key splitting a random string?
  const linkedText = text.split(' ').map((part, idx) =>
    URLS_ANYWHERE.test(part) ? (
      <Anchor
        key={part + String(idx)}
        className={styles.comment_link}
        href={part}
        target="_blank"
        rel="noopener noreferrer"
      >
        {part}
      </Anchor>
    ) : (
      <Text
        key={part + String(idx)}
        span
        dangerouslySetInnerHTML={{ __html: part + ' ' }}
      ></Text>
    )
  )

  return <Text span>{linkedText}</Text>
}
