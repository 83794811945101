import React from 'react'
import { Combobox, useCombobox, InputBase } from '@mantine/core'
import { useMst } from '@state'
import { IOrg } from '@state/types'
import { getAvatarUrl } from '@util'
import {
  filterOnLabelDescription,
  SelectDataItem,
  UserComboboxOption,
} from '../util'

import styles from '../Permissions/PermissionUserSelect.module.scss'

export const OwnerSelect = ({
  org,
  onChange,
}: {
  org: IOrg
  onChange: React.Dispatch<
    React.SetStateAction<{
      canSend: boolean
      ownerId: string
    }>
  >
}) => {
  const { environment } = useMst()
  const data: SelectDataItem[] = org.alphabetizedMembers.map((user) => {
    return {
      label: user.name,
      value: user.id,
      description: user.email,
      image: getAvatarUrl(user.avatar, environment.config),
      disabled: user.id === org.owner.id,
    }
  })

  const [search, setSearch] = React.useState('')

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  })

  const changeHandler = (id: string | null) => {
    if (!id) return
    setSearch('')
    onChange({ canSend: true, ownerId: id })
  }

  const shouldFilterOptions = data.every((item) => item.label !== search)
  const filteredOptions = shouldFilterOptions
    ? filterOnLabelDescription(search, data)
    : data

  const options = filteredOptions.map((item) => (
    <UserComboboxOption item={item} key={item.value} />
  ))

  return (
    <Combobox
      classNames={{ dropdown: styles.userselect_dropdown }}
      store={combobox}
      onOptionSubmit={async (val) => {
        changeHandler(val || '')
        setSearch(data.find((i) => i.value === val)?.label ?? '')
        combobox.closeDropdown()
      }}
    >
      <Combobox.Target>
        <InputBase
          className={styles.userselect_input}
          rightSection={
            <Combobox.Chevron onClick={() => combobox.focusTarget()} />
          }
          value={search}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => {
            combobox.closeDropdown()
          }}
          onChange={(event) => {
            combobox.openDropdown()
            combobox.updateSelectedOptionIndex()
            setSearch(event.currentTarget.value)
          }}
          placeholder="Choose New Owner"
        />
      </Combobox.Target>
      <Combobox.Dropdown>
        <Combobox.Options>
          {options.length > 0 ? (
            options
          ) : (
            <Combobox.Empty>Nobody here</Combobox.Empty>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}
