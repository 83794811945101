import React from 'react'
import { useMst } from '@state'
import { Anchor, Button, TextInput } from '@mantine/core'
import { schemas, ZInfer } from '@showrunner/scrapi'
import { NavAnchor } from '@components/NavAnchor'
import { PaginatedPayloadView } from '@components/PaginatedPayloadView'
import { CopyableId } from '@components/Staff/CopyableId'
import { StaffCheckbox } from './StaffCheckbox'
import { showAsyncConfirmModal } from '@components/Modals'
import { UserListings, UserListing, UserFilter } from './types'

const UsersResultsView = ({ results }: { results: UserListings }) => {
  const { apiClient } = useMst()
  const handleLogout = (user: UserListing) => {
    showAsyncConfirmModal({
      onConfirm: () => apiClient.revokeUserSessions(user.id),
      title: 'Log out User',
      children: `Are you sure you want to log ${user.email} out of all browsers?`,
      dangerous: true,
      errorMessage: 'An unexpected error occurred',
    })
  }

  return (
    <table>
      <thead>
        <tr>
          <th>Staff</th>
          <th>User Name</th>
          <th>Email</th>
          <th>ID</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {results.map((u) => {
          const { id, name, email } = u
          return (
            <tr key={id}>
              <td>
                <StaffCheckbox u={u} />
              </td>
              <td>{name}</td>
              <td>
                <Anchor href={`mailto:${email}`}>{email}</Anchor>
              </td>
              <td>
                <CopyableId id={id} />
              </td>
              <td>
                <Anchor onClick={() => handleLogout(u)}>Log out</Anchor>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

type UserFilterProps = {
  filter: UserFilter
  onChange: ({ email, name }: UserFilter) => void
}

const OrgFilterView = ({ onChange, filter }: UserFilterProps) => {
  const [formValues, setFormValues] = React.useState<UserFilter>({
    name: filter.name ?? '',
    email: filter.email ?? '',
    id: filter.id ?? '',
  })

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      updateFilters()
      e.preventDefault()
    }
  }

  const updateFilters = () => {
    onChange({
      name: formValues.name,
      email: formValues.email,
      id: formValues.id,
    })
  }

  const resetFilters = () => {
    const cleared = { name: '', email: '', id: '' }
    setFormValues(cleared)
    onChange(cleared)
  }

  return (
    <>
      <h1>
        <NavAnchor href="/staff">Staff Zone</NavAnchor>
      </h1>
      <div className="l-box l-box--center">
        <TextInput
          placeholder="Jane User"
          value={formValues.name}
          onChange={(e) =>
            setFormValues({ ...formValues, name: e.target.value })
          }
          onKeyUp={(e) => handleKeyUp(e)}
        />
        <TextInput
          placeholder="jane@writer.com"
          value={formValues.email}
          onChange={(e) =>
            setFormValues({ ...formValues, email: e.target.value })
          }
          onKeyUp={(e) => handleKeyUp(e)}
        />
        <TextInput
          placeholder="id"
          value={formValues.id}
          onChange={(e) => setFormValues({ ...formValues, id: e.target.value })}
          onKeyUp={(e) => handleKeyUp(e)}
        />
      </div>
      <br />
      <div className="l-box l-box--center l-box--center-items">
        <Button onClick={updateFilters}>Search</Button>
        <Button variant="subtle" onClick={resetFilters}>
          Reset filters
        </Button>
      </div>
    </>
  )
}

export const Users = () => {
  const { scrapi } = useMst()

  const findUsersForStaff = async ({
    from,
    size,
    name,
    id,
    email,
  }: ZInfer<typeof schemas.FindUsersRequest>) => {
    const response = await scrapi.staff.findUsers({
      body: {
        from,
        size,
        name,
        id: id && id.length > 0 ? id : undefined, // do not send an empty id
        email,
      },
    })
    if (response.status !== 200) throw new Error('oops')
    return response.body
  }

  return (
    <PaginatedPayloadView
      getPage={findUsersForStaff}
      FilterView={OrgFilterView}
      pageSize={20}
      ResultView={UsersResultsView}
      initialFilter={{}}
    />
  )
}
