import React from 'react'
import cn from 'classnames'
import { Divider, Group, Space, Stack, Text } from '@mantine/core'
import { SlotContent } from '@util/princePrinting'

import styles from './Example.module.scss'

const sampleText: Record<SlotContent, string> = {
  'full-title': 'Episode #607\nAct 1',
  'short-title': 'Episode #607',
  'page-number': '2.',
  timestamp: '7/20/1969 at 10:56 PM',
  'page-number-total': 'Page 2/10',
}

const ExampleSlot = ({
  text,
  pos,
}: {
  text?: SlotContent
  pos: 'left' | 'center' | 'right'
}) => {
  // even empty slots serve a purpose
  if (!text) return <Text size="12"></Text>

  if (text === 'full-title') {
    const splitText = sampleText[text].split('\n')
    return (
      <Stack gap={0}>
        <Text size="12" ta={pos}>
          {splitText[0]}
        </Text>
        <Text size="12" ta={pos} tt="uppercase">
          {splitText[1]}
        </Text>
      </Stack>
    )
  }

  return (
    <Text size="12" ta={pos}>
      {sampleText[text]}
    </Text>
  )
}

export const Example = ({
  left,
  center,
  right,
  hr,
  mode = 'header',
  hidden = false,
}: {
  left?: SlotContent
  center?: SlotContent
  right?: SlotContent
  hr?: boolean
  mode?: 'header' | 'footer'
  hidden?: boolean
}) => {
  const isFooter = mode === 'footer'
  return (
    <Stack gap={10} px={10}>
      <Text fw="bold">Example</Text>
      <div
        className={cn(styles.prefModal_exampleOuter, {
          [styles.prefModal_exampleOuter___footer]: mode === 'footer',
        })}
      >
        <div className={styles.prefModal_exampleInner}>
          {!hidden && (
            <>
              {mode === 'footer' && <Space h={10} />}
              {mode === 'footer' && hr && <Divider color="dark" />}
              <Group
                justify="space-between"
                align={isFooter ? 'start' : 'end'}
                className={styles.prefModal_exampleContent}
              >
                <Stack
                  gap={0}
                  align="flex-start"
                  className={styles.prefModal_exampleBookend}
                >
                  <ExampleSlot text={left} pos="left" />
                </Stack>
                <Stack align="center">
                  <ExampleSlot text={center} pos="center" />
                </Stack>
                <Stack
                  align="flex-end"
                  className={styles.prefModal_exampleBookend}
                >
                  <ExampleSlot text={right} pos="right" />
                </Stack>
              </Group>
              {mode === 'header' && hr && <Divider color="dark" />}
            </>
          )}
        </div>
      </div>
    </Stack>
  )
}
