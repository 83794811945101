import React from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '@state'
import { ToolbarButton, ToolbarButtonProps } from './ToolbarButton'

// we decorate a DOM node with this if it preserves editor focus
export const EDITOR_FOCUS_ATTRIBUTE = 'data-editor-preserve-focus'
// the selector to determine these nodes
export const EDITOR_FOCUS_SELECTOR = `[${EDITOR_FOCUS_ATTRIBUTE}="true"]`
// a spreadable prop to apply the dom node
const DATA_FOCUS_PROP = { [EDITOR_FOCUS_ATTRIBUTE]: true }

type EditorFocusButtonProps = ToolbarButtonProps & {
  // set to true if the button should work even if the editor doesn't
  // have focus (e.g. undo / redo)
  enableWithoutFocus?: boolean
  // Set to true to suppress re-focusing on the editor after click
  // (e.g. hyperlink)
  dontRestoreFocus?: boolean
}
const EditorFocusButtonInternal = React.forwardRef<
  HTMLButtonElement,
  EditorFocusButtonProps
>((props, ref) => {
  const {
    enableWithoutFocus,
    dontRestoreFocus,
    onClick,
    disabled,
    ...passthroughProps
  } = props
  const { currentScript } = useMst()
  if (!currentScript?.observableEditor) {
    return null
  }

  const { editorViewFocused, focusEditor } = currentScript.observableEditor
  const focusStateValid = editorViewFocused || enableWithoutFocus

  const handleClick = () => {
    onClick?.()
    if (!dontRestoreFocus) {
      focusEditor()
    }
  }

  return (
    <div {...DATA_FOCUS_PROP}>
      <ToolbarButton
        ref={ref}
        {...passthroughProps}
        disabled={disabled || !focusStateValid}
        onClick={handleClick}
      />
    </div>
  )
})
EditorFocusButtonInternal.displayName = 'EditorFocusButton'

export const EditorFocusButton = observer(EditorFocusButtonInternal)
