import { Plugin, PluginKey } from 'prosemirror-state'
import debounce from 'lodash.debounce'
import { types } from '@showrunner/codex'
import { getElementNumberState } from './element-numbers.js'
import { getPageCount } from './page-count.js'
import { getNavLinks } from './getNavLinks'
import { getTimingInfo } from './timing'
import { getLockedState } from './locked.js'
/**
 * Creates a new meta plugin to keep track of metadata
 * @param {object} config - plugin config
 * @param {function} config.emit - event emitter
 * @return {Plugin} page count plugin
 */
export function metaPlugin({ script, emit }) {
  const { type, readRate } = script
  const validReadRate = typeof readRate === 'number' && !isNaN(readRate)
  const computeTiming =
    validReadRate && (type === types.VARIETY || type === types.CLASSIC)
  let scriptMeta = {}

  function updateMeta(editorView) {
    scriptMeta.pageCount = getPageCount(editorView.state)
    scriptMeta.navLinks = getNavLinks(editorView)
    scriptMeta.elementNumbers = getElementNumberState(editorView, script.type)
    scriptMeta.locked = getLockedState(editorView)
    if (computeTiming) {
      const { timing, slugTiming, selectionTiming } = getTimingInfo(
        editorView.state,
        readRate
      )
      scriptMeta.timing = timing
      scriptMeta.slugTiming = slugTiming
      scriptMeta.selectionTiming = selectionTiming
    }
    // TODO: analyze doc before creating view
    // so that we can assign initial props/attributes on create, not afterward
    const lockedAttr = editorView.props.attributes['data-locked']
    if (
      // 1. if view attribute `data-locked` is unset, needs to be set
      lockedAttr == null ||
      // 2. if view attribute doesn't match locked state, update it
      scriptMeta.locked !== !!lockedAttr
    ) {
      editorView.setProps({
        // NOTE: need to clone attributes, setter overwrites previous obj
        attributes: {
          ...editorView.props.attributes,
          'data-locked': scriptMeta.locked,
        },
      })
    }
    // TODO: only fire if there was a change in data (be smarter)
    emit('editor:setScriptMeta', scriptMeta)
  }
  // fallback to ensure invocation after 10 seconds of debounced calls
  // can only happen if writers type non-stop with no 750ms gaps for 10s
  const debouncedUpdate = debounce(updateMeta, 750, { maxWait: 10000 })

  let forceUpdate = false
  return new Plugin({
    key: new PluginKey('METATRON'),
    state: {
      init() {
        // noop
      },
      apply(tr) {
        forceUpdate = tr.getMeta('choo')
      },
    },
    view(editorView) {
      updateMeta(editorView)
      return {
        update(editorView, prevState) {
          const docChanged = !editorView.state.doc.eq(prevState.doc)
          const prevSel = prevState.tr.selection
          const currSel = editorView.state.tr.selection
          const selectionChanged = !currSel.eq(prevSel)

          if (forceUpdate) {
            updateMeta(editorView)
          } else if (docChanged || selectionChanged) {
            // if the script or selection has changed, recalculate
            debouncedUpdate(editorView)
          }
        },
        destroy() {
          debouncedUpdate.cancel()
          scriptMeta = {}
        },
      }
    },
  })
}
