import NiceModal from '@ebay/nice-modal-react'
import { useState, useEffect } from 'react'
import { email } from '@sideway/address'
import { ModalShell, useModalControls } from '@components/Modals'
import { TextInput } from '@mantine/core'
import { useMst, IOrg } from '@state'
import { delay } from '@util'
import { useInviteData } from '@components/Settings/useInviteData'

const CreateInvite = ({ org }: { org: IOrg }) => {
  const { view } = useMst()
  const controls = useModalControls()
  const { invalidateInviteQuery } = useInviteData(org)
  const [emailInput, setEmailInput] = useState('')

  useEffect(() => {
    controls.setDisabled(!email.isValid(emailInput))
  }, [controls, emailInput])

  const handleClose = () => {
    controls.onClose()
    setEmailInput('')
  }

  const handleSubmit = async () => {
    controls.setLoading(true)
    try {
      if (view.isDebugEnabled('slow')) await delay(2)
      if (view.isDebugEnabled('fail')) throw new Error('oopsie')
      await org.createInvite(emailInput)
      invalidateInviteQuery()
      handleClose()
    } catch (e) {
      controls.setErrorMessage('Failed to send invitation')
    } finally {
      controls.setLoading(false)
    }
  }

  return (
    <ModalShell
      size="sm"
      title="Invite New Member"
      opened={controls.opened}
      onClose={handleClose}
      onConfirm={handleSubmit}
      confirmLabel="Create Invitation"
      cancelLabel="Cancel"
      disabled={controls.disabled}
      loading={controls.loading}
      errorMessage={controls.errorMessage}
    >
      <TextInput
        placeholder="name@email.com"
        value={emailInput}
        onChange={(e) => setEmailInput(e.target.value)}
      />
    </ModalShell>
  )
}
const CreateInviteModal = NiceModal.create(CreateInvite)

export const showCreateInviteModal = (props: { org: IOrg }) =>
  NiceModal.show(CreateInviteModal, props)
