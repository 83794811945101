import { observer } from 'mobx-react-lite'
import { MarkTypeKey, MarkTypeMap, schema } from '@showrunner/codex'
import { CustomIcon } from '@components/CustomIcon'
import { Toolbar } from '@components/Toolbar'
import { Keys, ShortcutConfig, useShortcuts } from '@hooks'
import { useMst } from '@state'
import { capitalize, forceCaps, toggleMark } from '@util'

const { STRONG, EM, UNDERLINE, STRIKE } = MarkTypeMap

type SHORTCUTTED_MARK = 'strong' | 'em' | 'underline' | 'strike'

const formattingOptions = {
  [STRONG]: { label: 'bold', keys: [Keys.CMD, 'B'] },
  [EM]: { label: 'italic', keys: [Keys.CMD, 'I'] },
  [UNDERLINE]: { label: 'underline', keys: [Keys.CMD, 'U'] },
  [STRIKE]: {
    label: 'strikethrough',
    keys: [Keys.SHIFT, Keys.CMD, 'U'],
  },
}

export const StyleButtons = observer(function StyleButtons() {
  const { currentScript } = useMst()
  const observableEditor = currentScript?.observableEditor

  const toggleStyle = (type: MarkTypeKey) => {
    if (observableEditor) {
      const { editorState, editorView } = observableEditor
      toggleMark(schema.marks[type], undefined, { removeWhenPresent: false })(
        editorState,
        editorView.dispatch
      )
    }
  }

  const disabled = !observableEditor?.canFormatText

  const config: Record<string, ShortcutConfig> = {}
  for (const MARK_KEY in formattingOptions) {
    config[MARK_KEY as SHORTCUTTED_MARK] = {
      keys: formattingOptions[MARK_KEY as SHORTCUTTED_MARK].keys,
      action: () => toggleStyle(MARK_KEY as MarkTypeKey),
      disabled,
    }
  }

  const { getItemProps } = useShortcuts(config)
  const capsItemProps = useShortcuts({
    caps: {
      keys: [Keys.CMD, Keys.ALT, 'A'],
      action: () => forceCaps(observableEditor?.editorView),
      disabled,
    },
  }).getItemProps('caps')

  if (!observableEditor) return null

  return (
    <>
      {/*
        TODO:

        Object.entries does not guarantee order! We should convert the
        data to an array or sort in some way
      */}
      {Object.entries(formattingOptions).map(([name, { label }]) => (
        <Toolbar.EditorFocusButton
          key={name}
          icon={`fa-${label}`}
          tooltip={`${capitalize(label)} (${getItemProps(name).shortcut})`}
          {...getItemProps(name)}
          active={observableEditor.selectionContainsMark(name)}
        />
      ))}
      <div className="o-icon o-icon--custom">
        <Toolbar.EditorFocusButton
          customIcon={<CustomIcon icon="icon-caps" />}
          tooltip={`All caps (${capsItemProps.shortcut})`}
          {...capsItemProps}
        />
      </div>
    </>
  )
})
