import { useLocation } from 'wouter'
import { Menu } from '@mantine/core'
import { FancyMenuItem } from '@components/FancyMenuItem'
import { SOME_DIFF_CONTEXT_LINES } from '@util/printing'

export const SideBySideViewMenu = ({
  linesOfContext,
  monochrome,
  setMonochrome,
}: {
  monochrome: boolean
  setMonochrome: (value: boolean) => void
  linesOfContext?: number
}) => {
  const [, setUrl] = useLocation()

  const changeContextLines = (value?: number) => {
    const pathname = window.location.pathname
    const search = typeof value === 'number' ? `?context=${value}` : ''
    setUrl(`${pathname}${search}`)
  }

  return (
    <>
      <Menu.Label>SIDE-BY-SIDE VIEW SETTINGS</Menu.Label>
      <FancyMenuItem
        key="0"
        title="Changes only"
        onClick={() => changeContextLines(0)}
        selected={linesOfContext === 0}
      />
      <FancyMenuItem
        key="5"
        title="Changes with some context"
        onClick={() => changeContextLines(SOME_DIFF_CONTEXT_LINES)}
        selected={linesOfContext === SOME_DIFF_CONTEXT_LINES}
      />
      <FancyMenuItem
        key="full"
        title="Full text"
        onClick={() => changeContextLines()}
        selected={linesOfContext === undefined}
      />
      <Menu.Divider />

      <Menu.Item onClick={() => setMonochrome(!monochrome)}>
        {monochrome
          ? 'Show changes in color'
          : 'Show changes in black and white'}
      </Menu.Item>
    </>
  )
}
