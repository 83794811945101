import { CSSProperties } from 'react'
import { FormatUtils } from '@showrunner/codex'
import { getColumnPlacement } from './helpers'
import { ScriptPrintPreferences } from '@util/LocalPersistence'

export const buildTwoColumnStyles = ({
  blocks,
  prefs,
}: {
  blocks: FormatUtils.BlockConfiguration
  prefs: ScriptPrintPreferences
}): CSSProperties => {
  const result: CSSProperties = {
    '--left-col-width': '3.25in',
    '--right-col-width': '4.25in',
    '--two-column-gap': '0.5in',
    '--right-column-line-height': prefs.twoColDialogueSpacing,
  }

  FormatUtils.ALL_BLOCK_TYPES.forEach((blockType) => {
    const { color, bold, underline, italic, uppercase, blockTopMargin } =
      blocks[blockType]

    if (color && !prefs.monochrome) {
      result[`--${blockType}-color`] = color
    }
    if (bold) {
      result[`--${blockType}-font-weight`] = 'bold'
    }
    if (underline) {
      result[`--${blockType}-text-decoration`] = 'underline'
    }
    if (italic) {
      result[`--${blockType}-font-style`] = 'italic'
    }
    if (uppercase) {
      result[`--${blockType}-text-transform`] = 'uppercase'
    }
    // we use the blockTopMargin only for right-column blocks
    if (getColumnPlacement(blockType) === 'right') {
      result[`--${blockType}-margin-top`] = `${blockTopMargin}em`
    }
  })

  return result
}
