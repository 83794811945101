import {
  useQuery,
  useMutation,
  useQueryClient,
  useIsMutating,
} from '@tanstack/react-query'
import { schemas, ZInfer } from '@showrunner/scrapi'
import { useMst } from '@state'
import { showError } from '@components/Modals'

export type TemplateWorkspaceData = ZInfer<typeof schemas.TemplateWorkspaceData>
export type TemplateWorkspaceListing = ZInfer<
  typeof schemas.TemplateWorkspaceListing
>

const PREFIX = 'staff-template'
const QUERY_KEYS = {
  LIST: [PREFIX, 'list'],
  UPDATE: [PREFIX, 'update'],
  CREATE: [PREFIX, 'create'],
}

const handleError = (message: string, error?: unknown) => {
  if (error) {
    showError(message + ' see console for details')
    // eslint-disable-next-line no-console
    console.error(error)
  } else {
    showError(message)
  }
}

export const useStaffTemplates = () => {
  const { scrapi, doDebug } = useMst()
  const queryClient = useQueryClient()

  const templatesQuery = useQuery({
    queryFn: () => scrapi.staff.getTemplates(),
    queryKey: QUERY_KEYS.LIST,
  })

  const createTemplateMutation = useMutation({
    mutationFn: async (template: TemplateWorkspaceData) => {
      await doDebug()
      return scrapi.staff.createTemplate({ body: template })
    },
    mutationKey: QUERY_KEYS.CREATE,
    onSettled: (data, error) => {
      if (error) {
        handleError('Network error', error)
      } else if (data?.status === 201) {
        queryClient.invalidateQueries(QUERY_KEYS.LIST)
      } else if (data?.status === 420) {
        handleError(data.body.message)
      } else {
        handleError('Unexepected server error', data)
      }
    },
  })

  const updateTemplateMutation = useMutation({
    mutationFn: async (template: TemplateWorkspaceData) => {
      await doDebug()
      const { code, workspaceId, ...updateValues } = template
      const result = await scrapi.staff.updateTemplateMetadata({
        params: { code },
        body: updateValues,
      })
      return result
    },
    mutationKey: QUERY_KEYS.UPDATE,
    onSettled: (data, error) => {
      if (error) {
        handleError('Network error', error)
      } else if (data?.status === 200) {
        queryClient.invalidateQueries(QUERY_KEYS.LIST)
      } else {
        handleError('Unexepected server error', data)
      }
    },
  })

  const decommissionMutation = useMutation({
    mutationFn: async (code: string): Promise<{ workspaceId: string }> => {
      const result = await scrapi.staff.decommissionTemplate({
        params: { code },
        body: {},
      })
      if (result.status !== 200) {
        throw new Error('Unexpected server error', { cause: result })
      }
      return result.body
    },

    onSettled: (result, error) => {
      if (error) {
        handleError('Network error', error)
      } else {
        queryClient.invalidateQueries(QUERY_KEYS.LIST)
      }
    },
  })

  const mutationCount = useIsMutating([PREFIX])

  const isLoading = templatesQuery.isFetching || mutationCount > 0

  return {
    templatesQuery,
    isLoading,
    updateStatus: updateTemplateMutation.status,
    createTemplateMutation,
    updateTemplateMutation,
    decommissionMutation,
  }
}
