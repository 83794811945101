import * as Comlink from 'comlink'
import { colors } from '@theme/colors'

import type {
  IInkPlayerController,
  RemotePlayer,
  CompileMessageType,
} from './types'

// NOTE: do not import from @util/index.ts or the Compiler will not be able to run
// (some bad build-time problem with nanohtml and webworkers)
import { ROUTE_PATTERNS } from '@util/pathConfigs'

export const createPopupWindowProxy = () =>
  window.open(
    ROUTE_PATTERNS.inkPlayer,
    'The Popup',
    `dependent=yes, width=600, height=600, location=no, screenX=${
      window.screenX - 100
    }, screenY=${window.screenY + 10}`
  )

export const createRemotePlayer = (proxy: WindowProxy): RemotePlayer => {
  return Comlink.wrap<RemotePlayer>(Comlink.windowEndpoint(proxy))
}

export const exposeControllerToPlayer = (
  options: IInkPlayerController & { proxy: WindowProxy }
): void => {
  const { proxy, onPlayerClosed, onPlayerReady } = options
  Comlink.expose(
    { onPlayerClosed, onPlayerReady },
    Comlink.windowEndpoint(proxy)
  )
}

// we use raw unicode and colors to style pseudoelements in
// the InlineErrorStyles, so we need to give both the fa-
// class AND the unicode value for these.
export const STATUS_ICON_CONFIG: Record<
  CompileMessageType,
  {
    iconClass: `fa-${string}`
    iconUnicode: string
    color: string
  }
> = {
  error: {
    iconClass: 'fa-circle-xmark',
    iconUnicode: "'\\f057'",
    color: colors.red[6],
  },
  warning: {
    iconClass: 'fa-triangle-exclamation',
    color: colors.orange[6],
    iconUnicode: "'\\f071'",
  },
  todo: {
    iconClass: 'fa-clipboard',
    color: colors.blue[6],
    iconUnicode: "'\\f328'",
  },
}
