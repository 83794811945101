import { Plugin, PluginKey } from 'prosemirror-state'
import { DecorationSet } from 'prosemirror-view'
import {
  applyNewCursorData,
  remapCursors,
  applyDecorations,
} from './helpers.js'
import { RemoteAvatars } from './remote-avatars-component.js'
const remoteCursorKey = new PluginKey('REMOTE_CURSOR_PLUGIN')

/**
 * Creates a new remote cursor plugin  - this plugin manages the following state
 *    - an array of data objects about all the remote cursors on the current doc (state.cursors)
 *    - the DecorationSet for those cursors (exposed as a view prop as 'decorations')
 *    - a boolean flag, "updating", used by the element-menu plugin to skip rendering
 *
 * @param {object} config - plugin config
 * @param {object} config.clientId - socket client ID
 * @param {object} config.script - script object from backend
 * @param {array} config.script.users - users array
 *
 * @returns {Plugin} new plugin instance
 */

function remoteCursorPlugin(args) {
  return new Plugin({
    key: remoteCursorKey,
    state: {
      init() {
        return {
          cursors: [],
          decorationSet: DecorationSet.empty,
          updating: false,
        }
      },
      apply(tr, state) {
        const cursorData = tr.getMeta(remoteCursorKey)
        state.updating = false
        if (cursorData && cursorData.reset) {
          state.cursors = []
        } else if (cursorData) {
          state.updating = true
          state.cursors = applyNewCursorData(cursorData, state.cursors)
        } else {
          state.cursors = remapCursors(tr.mapping, state.cursors)
        }
        state.decorationSet = applyDecorations(tr.doc, state.cursors)
        return state
      },
    },
    props: {
      decorations(state) {
        return args.appState.mst.user.prefs.showCollabCursors
          ? state[remoteCursorKey.key].decorationSet
          : DecorationSet.empty
      },
    },
    view(editorView) {
      const avatarsComponent = new RemoteAvatars({
        editorView,
        mst: args.appState.mst,
      })
      const gutterLeft = document.querySelector('.c-editor__gutterleft')
      const el = avatarsComponent.render()
      gutterLeft.appendChild(el)
      function destroy() {
        gutterLeft.removeChild(el)
      }
      function update(view) {
        const { cursors } = remoteCursorKey.getState(view.state)
        avatarsComponent.update(cursors)
      }
      return {
        update,
        destroy,
      }
    },
  })
}
export { remoteCursorPlugin, remoteCursorKey }
