import { Blockquote, Text } from '@mantine/core'
import { CommentMeta } from '@util/ScriptoApiClient/types'

import styles from './CommentHistory.module.scss'

export const CommentSnippet = ({ comment }: { comment: CommentMeta }) => (
  <Blockquote
    classNames={{
      icon: styles.comment_quoteicon,
      root: styles.comment_quoteinner,
    }}
    color="yellow"
    className={styles.comment_quote}
  >
    <Text lineClamp={2}>{comment.snippet}</Text>
  </Blockquote>
)
