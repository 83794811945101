import { Badge, Group, Switch, Text } from '@mantine/core'
import { showAsyncConfirmModal } from '@components/Modals'
import { useMst } from '@state'

import styles from './CommentHistory.module.scss'

export const SlackToggle = ({ isPaid }: { isPaid: boolean }) => {
  const { analytics, currentScript, environment } = useMst()
  const { APP_URL, SLACK_CLIENT_ID } = environment.config

  const disableSlack = async () => {
    await currentScript?.disableSlack()
    analytics.track(analytics.EVENTS.SCRIPT_SLACK_INTEGRATION_DISABLED)
  }

  const toggleSlackConnection = () => {
    const scriptId = currentScript?.id

    // null or false, trigger the OAuth flow
    // we pass the scriptId as state to redirect the user back to where they started
    if (!currentScript?.slackWebhook) {
      const url = `https://slack.com/oauth/v2/authorize?scope=incoming-webhook&client_id=${SLACK_CLIENT_ID}&redirect_uri=${APP_URL}/oauth/callback&state=${scriptId}`

      window.location.href = url
    } else {
      // disable the integration
      showAsyncConfirmModal({
        onConfirm: disableSlack,
        title: 'Disconnect from Slack',
        children:
          'Are you sure you want to disable the Slack integration for this script?',
        dangerous: true,
        errorMessage: 'An unexpected error occurred',
      })
    }
  }

  // TODO: pass down click handler to make overriding in storybook possible
  return isPaid ? (
    <Switch
      className={styles.commentHistory_footer}
      label="Connect to Slack"
      checked={currentScript?.slackWebhook}
      onChange={toggleSlackConnection}
    />
  ) : (
    <Group className={styles.commentHistory_footer}>
      <Text>Connect to Slack</Text>
      <Badge color="violet">Paid</Badge>
    </Group>
  )
}
