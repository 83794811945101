import React from 'react'
import { ColumnState, IRowNode } from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import { types, Instance, getSnapshot, applySnapshot } from 'mobx-state-tree'
import { PDFDocument } from 'pdf-lib'
import printJS from 'print-js'
import { util as codexUtil } from '@showrunner/codex'
import { ListingBase } from '../ListingBase'
import {
  findFocusedRowId,
  clearFocusedCell,
  setFocusedCell,
  FocusedCellData,
} from './grid-helpers'
import { PrompterPushCandidate } from '../PrompterPushCandidate'
import { RundownBlobData } from './RundownBlobData'
import { RundownRow } from './RundownRow'
import { IRundownRow, ScriptReference } from '@state/types'
import { delay, saveBlobToFile } from '@util'
import { getPdfBlobForScript } from '@util/printing'
import { RundownColumnState, RundownLayoutType } from '@util/LocalPersistence'
import {
  RundownSchema,
  GridBlobColumnKey,
  CONTROLS_COLUMN_ID,
  ROW_LEVEL_BLOB_FIELD,
  stripBlobKeyPrefix,
} from '@util/rundowns'
import {
  RundownRowData,
  RundownPayload,
  BlobData,
} from '@util/ScriptoApiClient/types'
import {
  RundownRowMessage,
  BlobsUpdatedPayload,
  RUNDOWN_EVENTS,
} from '../SocketManager/types'
import { isClipboardCurrent } from '@util/rundowns'
import { rowDataFromScript } from '@util/rundownImport'

import { schemaColumnToColDef } from '@components/RundownGrid/columns/columnTypes'

// This is to replace an enum in ag-grid-community that jest cannot
// import without barfing.
const RowHighlightPosition = {
  Above: 0,
  Below: 1,
}

export const Rundown = ListingBase.named('Rundown')
  .props({
    id: types.number,
    rowMap: types.map(RundownRow),

    // tracks when we are loading or updating data via the API
    gridIsLoading: false,
    // tracks when the user is actively editing a cell in AGGrid
    gridIsEditing: false,

    // This lets the UI keep track of which row a script is
    // being dragged over at any given time.
    currentDropTargetRow: types.safeReference(RundownRow),

    blobData: RundownBlobData,
    prompterPushCandidate: types.maybe(PrompterPushCandidate),
    focusedRow: types.safeReference(RundownRow),
    schema: types.frozen<RundownPayload['schema']>(),

    // local storage-persisted values settings
    screenColumnPrefs: types.frozen<RundownColumnState>({}),
    printColumnPrefs: types.frozen<RundownColumnState>({}),
    // Pending socket events that need to be applied at the next
    // opportunity (when we're not loading data/editing/etc)
    pendingRowMessages: types.array(types.frozen<RundownRowMessage>()),
  })
  .volatile<{
    gridRef: React.RefObject<AgGridReact<RundownRowData>>
    printableGridRef: React.RefObject<AgGridReact>
    pendingFocus?: FocusedCellData
  }>(() => ({
    gridRef: React.createRef<AgGridReact>(),
    printableGridRef: React.createRef<AgGridReact>(),
  }))
  .views((self) => ({
    get path() {
      return `/rundowns/${self.id}`
    },
    get sortedRowInstances() {
      return Array.from(self.rowMap.values()).sort(
        (r1, r2) => r1.sequence - r2.sequence
      )
    },
    getRowNode(rowId: string | number): IRowNode<RundownRowData> | undefined {
      return self.gridRef.current?.api.getRowNode(String(rowId))
    },
    // For AG grid and for checksumming
    get immutableRowData(): RundownRowData[] {
      return Array.from(self.rowMap.values())
        .sort((r1, r2) => r1.sequence - r2.sequence)
        .map((rd) => rd.pojo)
    },
    get checksum(): string {
      return codexUtil.hashRundownRows(this.immutableRowData)
    },
    checksumMatches(checksum: string) {
      return this.checksum === checksum
    },
    getRow(rowId: number): Instance<typeof RundownRow> | undefined {
      return self.rowMap.get(String(rowId))
    },
    get firstRow(): Instance<typeof RundownRow> | undefined {
      return this.sortedRowInstances[0]
    },
    get lastRow(): Instance<typeof RundownRow> | undefined {
      return this.sortedRowInstances[this.sortedRowInstances.length - 1]
    },
    findScriptRow(scriptId: string): Instance<typeof RundownRow> | undefined {
      return this.sortedRowInstances.find(
        (r) => r.identityScriptId === scriptId
      )
    },
    get selectedRows(): IRundownRow[] {
      return this.sortedRowInstances.filter((row) => row.selectedInGrid)
    },
    get hasNumberedRows(): boolean {
      return this.sortedRowInstances.some((row) =>
        row.getBlobValue('blobData.itemNumber')
      )
    },
    get selectionHasNumberedRows(): boolean {
      return this.selectedRows.some((row) =>
        row.getBlobValue('blobData.itemNumber')
      )
    },
    get selectionSummary(): { rows: IRundownRow[]; continuous: boolean } {
      const rows: IRundownRow[] =
        this.selectedRows.length > 0
          ? this.selectedRows
          : self.focusedRow
          ? [self.focusedRow]
          : []

      const rowWithGap = this.selectedRows.find((row, index) => {
        const previousSelectedRow = this.selectedRows[index - 1]
        return (
          !!previousSelectedRow &&
          previousSelectedRow.sequence !== row.sequence - 1
        )
      })

      return {
        rows,
        continuous: !rowWithGap,
      }
    },
    getChildRows(rowId: number): IRundownRow[] {
      const rowIndex = this.sortedRowInstances.findIndex((r) => r.id === rowId)
      const row = this.sortedRowInstances[rowIndex]
      const rowLevel = row?.rowLevel
      if (!row || rowLevel === undefined) {
        return []
      }
      const result: IRundownRow[] = []
      for (let i = rowIndex + 1; i < this.sortedRowInstances.length; i++) {
        const nextRow = this.sortedRowInstances[i]
        if (!nextRow) {
          break
        }
        if (nextRow.rowLevel && nextRow.rowLevel <= rowLevel) {
          break
        }
        result.push(nextRow)
      }

      return result
    },
    get orderedScripts(): ScriptReference[] {
      const scriptColumnId = self.schema.schema.importRules.script.columnId
      const result: Array<{ scriptId: string; name: string }> = []
      this.sortedRowInstances.forEach((r) => {
        if (r.identityScriptId) {
          const rawName = r.blobData.get(scriptColumnId)
          const name = typeof rawName === 'string' ? rawName : 'Script'

          result.push({
            scriptId: r.identityScriptId,
            name,
          })
        }
      })
      return result
    },
    get pasteableRows(): RundownRowData[] {
      const clipboard =
        self.rootStore.environment.localPersistence.getRundownClipboard(
          self.rootStore.user.id
        )
      if (clipboard) {
        const { rows, copiedAt } = clipboard
        if (isClipboardCurrent(new Date(copiedAt))) {
          return rows
        }
      }
      return []
    },
    get hasTrackedTiming(): boolean {
      return self.blobData.episodeLengthSeconds > 0
    },
    getConfigurableColumnDefs(layout: RundownLayoutType) {
      const savedColumnState =
        layout === 'screen' ? self.screenColumnPrefs : self.printColumnPrefs

      return self.schema.schema.columns.map((schemaColumn) => {
        const colDef = schemaColumnToColDef(schemaColumn)
        const { hide, width } = savedColumnState[colDef.colId] ?? {}
        if (hide) {
          colDef.hide = true
        }
        if (typeof width === 'number') {
          colDef.width = width
        }
        return colDef
      })
    },
    get hiddenScreenColumnCount() {
      return this.getConfigurableColumnDefs('screen').filter((cd) => cd.hide)
        .length
    },
  }))

  .actions((self) => ({
    trackModified() {
      self.analytics.trackDocModified('rundown', self.id)
    },
    prepareForRowManipulation() {
      // when we insert or delete rows programmatically, we need to cancel the focused selection
      // otherwise when the rows change, the focus will remain on the same index which will be the
      // wrong target, but we save off the data so that after the row manipulation
      // is complete we can re-apply the focus to the right spot
      if (self.gridRef.current) {
        self.pendingFocus = clearFocusedCell(self.gridRef.current.api)
      }
    },
    restoreFocus() {
      if (self.gridRef.current && self.pendingFocus) {
        setFocusedCell(self.pendingFocus, self.gridRef.current.api)
        self.pendingFocus = undefined
      }
    },
    clearRows() {
      self.rowMap.clear()
    },
    saveColumnState(colPrefs: ColumnState[], layout: RundownLayoutType) {
      const columnState: RundownColumnState = {}
      colPrefs.forEach(({ colId, hide, width }) => {
        if (colId !== CONTROLS_COLUMN_ID) {
          columnState[colId] = { hide, width }
        }
      })
      if (layout === 'screen') {
        self.screenColumnPrefs = columnState
      } else {
        self.printColumnPrefs = columnState
      }
      self.environment.localPersistence.setRundownColumnState({
        userId: self.rootStore.user.id,
        schemaId: self.schema.name,
        layout,
        columnState,
      })
    },
    packLocalSequences() {
      self.sortedRowInstances.forEach((row, index) => {
        row.setSequence(index + 1)
      })
    },
    makeRoomForRows(targetSequence: number, size: number) {
      Array.from(self.rowMap.values()).forEach((row) => {
        if (row.sequence >= targetSequence) {
          row.setSequence(row.sequence + size)
        }
      })
    },
    ingestLocalRows(rowData: RundownRowData[]) {
      if (rowData.length === 0) {
        return
      }
      this.prepareForRowManipulation()
      const insertionSequence = rowData[0].sequence
      // move all rows in the range higher so we can insert at the target sequence
      this.makeRoomForRows(insertionSequence, rowData.length)

      // now insert the rows
      rowData.forEach((rd) => {
        // todo: get rid of this destructuring after we stop using aggridhelpers copy of the data
        self.rowMap.put({ ...rd, blobData: { ...rd.blobData } })
      })
      // now pack sequences
      this.packLocalSequences()
    },
    removeLocalRows(rowIds: number[]) {
      this.prepareForRowManipulation()
      rowIds.forEach((rid) => self.rowMap.delete(String(rid)))
      this.packLocalSequences()
    },
    moveLocalRows(rowIds: number[], targetSequence: number) {
      this.prepareForRowManipulation()
      this.makeRoomForRows(targetSequence, rowIds.length)
      rowIds.forEach((rowId, index) => {
        self.getRow(rowId)?.setSequence(targetSequence + index)
      })
      this.packLocalSequences()
    },
    applyBlobUpdates({ delta }: BlobsUpdatedPayload) {
      const { key, rowValues } = delta
      rowValues.forEach(({ rowId, value }) => {
        const row = self.getRow(rowId)
        row?.updateValue(`blobData.${key}`, value)
      })
    },
    clearDragOverRow() {
      self.currentDropTargetRow?.clearDragStatus()
      self.currentDropTargetRow = undefined
    },
    setScriptDropTargetRow({
      rowId,
      position,
    }: {
      rowId: number
      position: 0 | 1
    }) {
      // If a different row has the drop target highlight, clear it
      if (self.currentDropTargetRow && self.currentDropTargetRow.id !== rowId) {
        self.currentDropTargetRow.clearDragStatus()
      }
      // find the new row and set the highlight above or below
      const row = self.getRow(rowId)
      const dragStatus =
        position === RowHighlightPosition.Above ? 'over' : 'under'
      self.currentDropTargetRow = row
      self.currentDropTargetRow?.setDragStatus(dragStatus)
    },
    createPushCandidate() {
      const candidate = PrompterPushCandidate.create({
        parentId: self.id,
        parentType: 'rundown',
      })
      self.prompterPushCandidate = candidate
      return candidate
    },
    clearPushCandidate() {
      self.prompterPushCandidate = undefined
    },
    updateFocusedRow() {
      const api = self.gridRef.current?.api
      if (api) {
        const rowId = findFocusedRowId(api)
        if (typeof rowId === 'number') {
          self.focusedRow = self.getRow(rowId)
          return
        }
      }
      self.focusedRow = undefined
    },
    setPreviewSchema(schema: RundownSchema) {
      self.schema = {
        id: -1,
        name: 'Preview',
        description: 'Preview',
        schema,
      }
    },
    copyRows() {
      const rows = self.selectedRows.map((r) => r.pojo)
      const data = {
        rows,
        schemaId: self.schema.id,
        copiedAt: new Date().toISOString(),
      }
      self.environment.localPersistence.setRundownClipboard(
        self.rootStore.user.id,
        data
      )
    },
  }))

  // special actions for working with sockets
  .actions((self) => ({
    applySocketChange([eventName, payload]: RundownRowMessage) {
      switch (eventName) {
        case RUNDOWN_EVENTS.ROWS_INSERTED:
          self.ingestLocalRows(payload.data)
          break
        case RUNDOWN_EVENTS.ROWS_DELETED:
          self.removeLocalRows(payload.delta.rowIds)
          break
        case RUNDOWN_EVENTS.ROWS_MOVED:
          self.moveLocalRows(payload.delta.rowIds, payload.delta.sequence)
          break
        case RUNDOWN_EVENTS.ROW_UPDATED:
          self
            .getRow(payload.data.id)
            ?.updateValue(payload.delta.key, payload.delta.value)
          break
        case RUNDOWN_EVENTS.RUNDOWN_ROW_BLOBS_UPDATED:
          self.applyBlobUpdates(payload)
          break
      }
    },

    // usually we call setGridLoading but that has side effects. When
    // we're doing a reload, we need to control this directly so we don't
    // get an infinite loop
    setLoadingInternal(value: boolean) {
      self.gridIsLoading = value
    },
    clearPendingQueue() {
      self.pendingRowMessages.clear()
    },
    processPendingQueue() {
      // bail if no changes
      if (self.pendingRowMessages.length === 0) {
        return
      }

      // check if we're already in sync (this happens when we receive
      // events of our own changes)
      const lastMessage =
        self.pendingRowMessages[self.pendingRowMessages.length - 1]
      const targetChecksum = lastMessage[1].checksum
      if (self.checksumMatches(targetChecksum)) {
        self.pendingRowMessages.clear()
        return
      }

      try {
        let nextMessage: RundownRowMessage | undefined
        while ((nextMessage = self.pendingRowMessages.shift())) {
          this.applySocketChange(nextMessage)
        }
      } catch (e) {
        self.log.error('Error applying socket message', { topic: 'sockets' }, e)
      }

      if (!self.checksumMatches(targetChecksum)) {
        self.log.error('Checksum mismatch after socket event applied', {
          topic: 'sockets',
        })
        this.reloadData()
      }
    },
    refreshColumnPrefs(layout: RundownLayoutType) {
      // load prefs for this rundown schema from local storage
      const { id: userId } = self.rootStore.user
      const colPrefs = self.environment.localPersistence.getRundownColumnState({
        userId,
        schemaId: self.schema.name,
        layout,
      })

      if (layout === 'screen') {
        self.screenColumnPrefs = colPrefs
      } else {
        self.printColumnPrefs = colPrefs
      }
    },

    // This forces a full refresh of all grid data from the server and
    // replaces the ag grid's rowData entirely. We use this if we're out of
    // sync or if we get an error on a grid API call
    async reloadData() {
      this.setLoadingInternal(true)
      try {
        const result = await self.apiClient.getRundown(self.id)
        self.clearRows()
        applySnapshot(self, { ...getSnapshot(self), ...result })
        self.ingestLocalRows(result.rows)
        // we are in sync so any delta updates are irrelevant
        this.clearPendingQueue()
      } catch (e) {
        self.log.error('Error reloading rundown data', { topic: 'sockets' }, e)
      } finally {
        this.setLoadingInternal(false)
      }
    },
  }))

  // sync actions
  .actions((self) => ({
    setName(name: string) {
      self.name = name
    },
    setGridEditing(value: boolean) {
      self.gridIsEditing = value
      if (value) {
        this.deselectAllRows()
      } else {
        this.processPendingIfAppropriate()
      }
    },
    setGridLoading(value: boolean) {
      if (self.gridIsLoading === value) {
        return
      }

      // this observable can be used for internal logic
      // or ReactUI
      self.setLoadingInternal(value)

      // if we move from loading true to false, see if we have any
      // pending socket messages we need to apply
      this.processPendingIfAppropriate()
    },
    setBlobData(blob: Instance<typeof RundownBlobData>) {
      self.blobData = blob
    },
    deselectAllRows() {
      self.selectedRows.forEach((r) => r.setSelected(false))
    },
    selectAllRows() {
      self.sortedRowInstances.forEach((r) => r.setSelected(true))
    },
    replaceSelectedRows(rows: IRundownRow[]) {
      self.selectedRows.forEach((r) => {
        if (!rows.includes(r)) {
          r.setSelected(false)
        }
      })
      rows.forEach((r) => r.setSelected(true))
    },
    processPendingIfAppropriate() {
      if (
        !(self.gridIsLoading || self.gridIsEditing) &&
        self.pendingRowMessages.length > 0
      ) {
        self.processPendingQueue()
      }
    },
  }))

  // async actions
  .actions((self) => ({
    async updateRowBlobData({
      rowId,
      columnKey,
      value,
    }: {
      rowId: number
      columnKey: GridBlobColumnKey
      value: JSONValue
    }) {
      const row = self.getRow(rowId)
      if (row) {
        await self.apiClient.updateRundownRowsBlobData({
          key: stripBlobKeyPrefix(columnKey),
          rundownId: self.id,
          rowValues: [
            {
              rowId,
              value,
            },
          ],
        })
        self.trackModified()
      }
    },

    async updateBlobData(newBlob: Instance<typeof RundownBlobData>) {
      await self.apiClient.updateRundownBlobData({
        rundownId: self.id,
        blobData: newBlob.forServer(),
      })
      self.setBlobData(newBlob)
      self.trackModified()
    },

    async updateEpisodeLength(newValue: number) {
      if (newValue === self.blobData.episodeLengthSeconds) {
        return
      }
      const newBlob = RundownBlobData.create({
        ...getSnapshot(self.blobData),
        episodeLengthSeconds: newValue,
      })
      await this.updateBlobData(newBlob)
      self.blobData.setEpisodeLength(newValue)
    },

    async updateStartTimeAndLength(
      startTime: Date,
      episodeLengthSeconds: number
    ) {
      const newBlob = RundownBlobData.create({
        ...getSnapshot(self.blobData),
        startTime: startTime,
        episodeLengthSeconds,
      })
      await this.updateBlobData(newBlob)
      self.blobData.setStartTime(startTime)
    },

    async moveRowsToPosition({
      rowIds,
      sequence,
    }: {
      rowIds: number[]
      sequence: number
    }) {
      await self.apiClient.moveRundownRows({
        rundownId: self.id,
        rowIds,
        sequence,
      })
      self.moveLocalRows(rowIds, sequence)
      self.trackModified()
    },

    async insertScriptRow({
      scriptId,
      sequence,
    }: {
      scriptId: string
      sequence: number
    }) {
      const existingRowNode = self.findScriptRow(scriptId)
      const { columnId, rowLevel } = self.schema.schema.importRules.script
      if (!existingRowNode) {
        const listing = self.rootStore.scriptMap.get(scriptId)
        if (listing) {
          const blobData: BlobData = {
            [columnId]: listing.name,
          }
          if (rowLevel) {
            blobData[ROW_LEVEL_BLOB_FIELD] = rowLevel
          }
          const result = await self.apiClient.insertRundownRows({
            rundownId: self.id,
            sequence,
            rowDataList: [
              {
                rundownId: self.id,
                rowTypeId: 'script',
                identityScriptId: scriptId,
                blobData,
              },
            ],
          })
          if (result.data && result.data.length > 0) {
            self.ingestLocalRows(result.data)
          }
        }
        self.trackModified()
      }
    },

    async insertBlankRows({
      sequence,
      rowCount,
      rowTypeId,
      rowLevel,
    }: {
      sequence: number
      rowCount: number
      rowTypeId: 'element' | 'header'
      rowLevel?: 1 | 2 | 3
    }) {
      const rowDataList: Array<Partial<RundownRowData>> = []
      const blobData: BlobData = {}
      if (rowLevel) {
        blobData[ROW_LEVEL_BLOB_FIELD] = rowLevel
      }
      for (let i = 0; i < rowCount; i++) {
        rowDataList.push({
          rowTypeId,
          blobData,
        })
      }
      const { data } = await self.apiClient.insertRundownRows({
        rundownId: self.id,
        rowDataList,
        sequence,
      })
      self.ingestLocalRows(data)
    },

    async removeRows(rowIds: number[]) {
      await self.apiClient.deleteRundownRows({
        rundownId: self.id,
        rowIds,
      })
      self.removeLocalRows(rowIds)
      if (self.rowMap.size === 0) {
        await this.insertBlankRows({
          rowTypeId: 'element',
          rowCount: 10,
          sequence: 1,
        })
      }
      self.trackModified()
    },

    async setItemNumbers(
      rowValues: Array<{
        rowId: number
        value: string
      }>
    ) {
      await self.apiClient.updateRundownRowsBlobData({
        rundownId: self.id,
        rowValues,
        key: 'itemNumber',
      })
      rowValues.forEach(({ rowId, value }) => {
        self.getRow(rowId)?.updateBlobValue('itemNumber', value)
      })
      self.trackModified()
    },

    async updateName(name: string) {
      await self.apiClient.updateRundownName({ rundownId: self.id, name })
      self.setName(name)
      const folder = self.rootStore.folderMap.get(self.folderId)
      folder?.load()
    },

    async importRows({
      scriptId,
      sequence,
    }: {
      scriptId: string
      sequence: number
    }) {
      const payload = await self.apiClient.getScript(scriptId)
      const rowDataList = rowDataFromScript({
        payload,
        schema: self.schema.schema,
      })

      const { data } = await self.apiClient.insertRundownRows({
        rundownId: self.id,
        rowDataList,
        sequence,
      })
      self.ingestLocalRows(data)
      self.trackModified()
    },

    async insertRowsFromClipboard() {
      const rows = self.pasteableRows
      if (rows.length === 0) {
        return
      }
      const sequence = self.selectionSummary.rows[0]?.sequence ?? 1
      const rowDataList: Partial<RundownRowData>[] = rows.map(
        ({ id, rundownId, sequence, ...rest }) => rest
      )
      const { data } = await self.apiClient.insertRundownRows({
        rundownId: self.id,
        rowDataList,
        sequence,
      })
      self.ingestLocalRows(data)
      self.trackModified()
    },

    async glueScriptPdfs(type: 'print' | 'download') {
      const mergedPdf = await PDFDocument.create()

      // fire off api requests simultaneously
      const responses = await Promise.all(
        self.orderedScripts.map(({ scriptId, name }) =>
          getPdfBlobForScript({
            scriptId,
            title: name,
            mst: self.rootStore,
          }).catch((e) => {
            const message = 'Failure fetching script for rundown pdf export'
            self.log.error(message, { scriptId, message: e.message })
            return null
          })
        )
      )

      const scriptBlobs = responses.filter((b) => b !== null)

      // stitch merged PDF together synchronously
      for (const scriptBlob of scriptBlobs) {
        const buffer = await new Response(scriptBlob).arrayBuffer()
        // Load a PDFDocument from each of the existing PDFs
        const pdf = await PDFDocument.load(new Uint8Array(buffer))
        const pages = await mergedPdf.copyPages(pdf, pdf.getPageIndices())
        pages.forEach((page) => mergedPdf.addPage(page))
      }

      // Serialize the PDFDocument
      const pdfUint8 = await mergedPdf.save()
      const blob = new Blob([pdfUint8], {
        type: 'application/octet-stream',
      })
      const fileName = `${self.name}-scripts.pdf`

      if (type === 'download') saveBlobToFile({ blob, fileName })
      if (type === 'print') {
        // blank output is generated without this. i have *no idea* why
        await delay(1)
        const href = URL.createObjectURL(blob)
        printJS({ printable: href, type: 'pdf' })
        URL.revokeObjectURL(href)
      }
    },
    receiveSocketMessage(message: RundownRowMessage) {
      self.pendingRowMessages.push(message)
      self.processPendingIfAppropriate()
    },

    afterAttach() {
      self.rootStore.socketManager.joinRundown(self.id)
      self.refreshColumnPrefs('screen')
      self.refreshColumnPrefs('print')
    },

    // Clean up listeners when this model is destroyed/removed from the tree
    beforeDetach() {
      self.pendingRowMessages.clear()
      self.rootStore.socketManager.leaveRundown(self.id)
    },
  }))

// helper function so we can convert the array from the server into a map
// which is a bit friendlier for mst data lookups, etc.
export const createRundownInstance = (
  data: RundownPayload
): Instance<typeof Rundown> => {
  const instance = Rundown.create(data)
  instance.ingestLocalRows(data.rows)
  return instance
}
