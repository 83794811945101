import type { RundownSchema } from '../rundownSchemaValidation'

export const templateSchema: RundownSchema = {
  /*
    How to create a rundown schema:

    1. REQUIRED: columns

    Define schema-specific columns that will appear in the rundown in the
    order they will appear.

    columns take the following properties:

    * rundownColumnType: REQUIRED
        e.g. 'text', 'longText', 'duration', etc.
    * colId: REQUIRED
        a unique ID string within this rundown
    * field: REQUIRED
        must be of the form "blobData.<colId>".
        For editable columns, the colId will be the
        colId value. But for things like cumulative duration
        you need to specify the colId of the column you're using
        to compute durations.
    * headerName: OPTIONAL
        How the column should be labelled on screen. If omitted
        there will be no label a the top of the column
    * headerTooltip: OPTIONAL
        If you hover over the header what do you see
    * width: OPTIONAL
        The default width for this column. If not specified
        then a fallback width will be used. Once a user resizes
        a column, then that overrides this width.
  */

  columns: [
    {
      colId: 'itemNumber',
      rundownColumnType: 'itemNumber',
      field: 'blobData.itemNumber',
    },
    {
      colId: 'item',
      rundownColumnType: 'longText',
      field: 'blobData.item',
      width: 100,
      headerName: 'Item',
    },
    {
      colId: 'blockId',
      rundownColumnType: 'readonlyBlockId',
      headerName: 'Block ID',
    },
    {
      colId: 'scriptId',
      rundownColumnType: 'readonlyScriptId',
      headerName: 'Script ID',
    },
    {
      colId: 'itemType',
      rundownColumnType: 'text',
      field: 'blobData.itemType',
      headerName: 'Item type',
      width: 150,
    },
    {
      colId: 'source',
      rundownColumnType: 'text',
      field: 'blobData.source',
      headerName: 'Source',
      width: 85,
    },
    {
      colId: 'elementTime',
      rundownColumnType: 'duration',
      headerName: 'Time',
      field: 'blobData.elementTime',
      width: 70,
      headerTooltip: 'Time: length of a media element, e.g. SOT',
    },
    {
      colId: 'runTime',
      rundownColumnType: 'duration',
      headerName: 'Duration',
      field: 'blobData.runTime',
      width: 85,
      headerTooltip: 'Duration: length of a segment in the show',
    },
    {
      colId: 'cumulative',
      rundownColumnType: 'cumulativeDuration',
      headerName: 'Cumulative',
      field: 'blobData.runTime',
      width: 85,
      headerTooltip:
        'Cumulative duration: automatically sums values in the duration column',
    },
    {
      colId: 'notes',
      rundownColumnType: 'text',
      headerName: 'Notes',
      field: 'blobData.notes',
    },
  ],

  /*
    2. OPTIONAL duration settings

      If you want the rundown to support over/under values for total
      runtime, define a durationMode and a primary duration field.

      The duration mode is either 'target-length' or 'start-end-time'.

      Target length will allow users to configure total number of minutes/seconds
      that a rundown SHOULD run and can display over/under.

      Start/End Time lets you specify a start & end time and shows the
      total over/under PLUS adds support for front/back timing columns.

      If you set a duration mode, you must specify which field (defined in the
      columns above) you are using to compute the actual runtime of the rundown.

  */
  durationMode: 'target-length',
  primaryDurationField: 'blobData.runTime',

  /*

    3. REQUIRED: levels

        Rundowns are like outlines and you can have up to three
        levels of "indentation".  The levels are identified
        as 1, 2 or 3 and you can choose to define none, one
        or all of them.

        Levels have two properties,

        enableInsert:  can a user insert one of these row types manually?
          (if false, then they can still participate in imported rules)

        displayName: How should this level be displayed in user menus, etc.
          e.g. "Insert Slug Row" vs. "Import Section Row" vs "Insert Potato Row"
  */
  levels: {
    '1': {
      enableInsert: false,
      displayName: 'Script',
    },
    '2': {
      enableInsert: true,
      displayName: 'Act',
    },
    '3': {
      enableInsert: true,
      displayName: 'Slug',
    },
  },

  /*

    4. REQUIRED: Import Rules

      Import rules are set up for two phases of importing from
      scripts-- how the scripts are brought into the rundown and then
      how rows in the script are imported.
  */
  importRules: {
    /*
       for 'script' import configure these properties:

      * rowLevel: OPTIONAL.
          if you want the script to come in as a level row, specify the row level here.
          (if you don't supply this, the script will come in as a "regular" row, like an element)
      * columnId: REQUIRED.
          When you add a script to a rundown, we populate the script's name into the column specified
          here.
    */
    script: {
      rowLevel: 1,
      columnId: 'item',
    },
    /*
      For 'rows' we want to configure an import rule for each element type we want to import
      from scripts. Most element types can be imported (a few are omitted, like "end of act",
      "transition", etc.)

      Import rules can be quite involved, in their simplest form, you specify the element type
      that should be imported and the column you want the text to go into.

      Here's the simplest example-- a 'simple' import rule
      that finds all dialogue elements in the script and
      creates a row for each and puts the dialogue into the 'item' column

      {
        ruleType: 'simple',
        nodeType: 'dialogue',
        columnId: 'item',
      }

      There are several extras that can be added to any import rule:
        * rowLevel:  if you give a rowLevel of 1, 2, or 3
          that will make the rundown rows created use that level.
        * elementNumberColumnId: If the imported row has an elementNumber,
          that number will get written into the column specified
        * contextColumns -- this can copy the name of the most recent "context"
          type block to a specific columns. You can add context rules for
          character, slug, sceneHeading or act blocks.

      Here's an example of a simple import rule with extras. It will import
      all dialog rows as level 2, putting their element number into the itemNumber
      column and putting the character context into the charName column:

      {
        ruleType: 'simple',
        nodeType: 'dialogue',
        columnId: 'item',
        rowLevel: 2,
        elementNumberColumnId: 'itemNumber',
        contextColumns: {
          character: charName
        },
      }


      Finally, there are special import rules that can be used when importing
      slugs or brackets. If you use a rule type of slug-with-timing, the computed
      timing of the slug will get imported into the column specified. If you
      use the 'bracket-parse' rule type, then the bracket string
      will be split into a prefix (bracketType), time (looks for t=45 kind of stuff)
      and content (the text of the bracket) and puts it into three columns.
    */
    rows: [
      {
        nodeType: 'bracket',
        ruleType: 'bracket-parse',
        contentColumnId: 'item',
        bracketTypeColumnId: 'itemType',
        timeColumnId: 'elementTime',
        elementNumberColumnId: 'itemNumber',
      },
      {
        nodeType: 'slug',
        ruleType: 'slug-with-timing',
        durationColumnId: 'runTime',
        columnId: 'item',
        rowLevel: 3,
      },
      {
        nodeType: 'new_act',
        ruleType: 'simple',
        columnId: 'item',
        rowLevel: 2,
      },
      {
        nodeType: 'sceneHeading',
        ruleType: 'simple',
        columnId: 'item',
        rowLevel: 3,
      },
    ],
  },
}
