import React from 'react'
import { StrengthResult } from './types'
import {
  TextInput,
  TextInputProps,
  Tooltip,
  ActionIcon,
  Stack,
} from '@mantine/core'
import { StrengthFeedback } from './StrengthFeedback'
import { FaIcon } from '@components/FaIcon'

export type PasswordInputProps = TextInputProps & {
  value: string
  error?: string
  strengthInfo?: StrengthResult
}

export const PasswordInput = (props: PasswordInputProps) => {
  const [showPassword, setShowPassword] = React.useState(false)
  const placeholder = showPassword ? 'super-secret-password' : '*********'
  const { strengthInfo, error, ...textInputProps } = props

  // if we're using the strength feedback, we pass the error message
  // through to it, but we want a boolean for error so the text input has
  // a red outline
  const textInputError = strengthInfo ? !!error : error

  return (
    <Stack gap={0}>
      <TextInput
        {...textInputProps}
        error={textInputError}
        type={showPassword ? 'text' : 'password'}
        placeholder={placeholder}
        rightSection={
          <Tooltip label={showPassword ? 'Hide password' : 'Show password'}>
            <ActionIcon
              onClick={() => {
                setShowPassword(!showPassword)
              }}
              c="gray.6"
            >
              <FaIcon icon={showPassword ? 'fa-eye-slash' : 'fa-eye'} />
            </ActionIcon>
          </Tooltip>
        }
      />
      {strengthInfo && textInputProps.value.length > 0 && (
        <StrengthFeedback
          isBlank={textInputProps.value.length === 0}
          strengthInfo={strengthInfo}
          errorMessage={error}
        />
      )}
    </Stack>
  )
}
