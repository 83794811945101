import { Diff, DIFF_EQUAL, DIFF_DELETE, DIFF_INSERT } from 'diff-match-patch'

export type EnrichedDiff = {
  text: string
  diffType: Diff[0]
  leftPosition: number
  rightPosition: number
}

type LineType = 'unchanged' | 'linediff' | 'worddiff' | 'omitted'

type DiffLineBase = {
  type: LineType
  newline?: 'left' | 'right'
}

type WordDiffItem = {
  changed: boolean
  text: string
}

type WordDiffLine = DiffLineBase & {
  type: 'worddiff'
  left: Array<WordDiffItem>
  right: Array<WordDiffItem>
}

type LineDiffLine = DiffLineBase & {
  type: 'linediff'
  value: string
  side: 'left' | 'right'
}

type UnchangedLine = DiffLineBase & {
  type: 'unchanged'
  text: string
}

type OmittedLine = {
  type: 'omitted'
  count: number
}

export type SideBySideDiffLine = DiffLineBase &
  (WordDiffLine | LineDiffLine | UnchangedLine | OmittedLine)

// typeguards
export function isUnchanged(line: SideBySideDiffLine): line is UnchangedLine {
  return line.type === 'unchanged'
}
export function isLineDiff(line: SideBySideDiffLine): line is LineDiffLine {
  return line.type === 'linediff'
}
export function isWordDiff(line: SideBySideDiffLine): line is WordDiffLine {
  return line.type === 'worddiff'
}
export function isOmitted(line: SideBySideDiffLine): line is OmittedLine {
  return line.type === 'omitted'
}

export type FirstPassRowItem = {
  left: WordDiffItem[]
  right: WordDiffItem[]
  // Sometimes we get the insertion of a linebreak without other changes
  // tag this so we can render something in the side-by-side view
  newline?: 'right' | 'left'
}

// To create a side-by-side presentation we want to produce an array of "rows" that
// can be lined up so that changes are vertically. We do this by making each block into a
// separate line, and putting in extra blocks where needed
export const buildSideBySideStructure = (diffs: EnrichedDiff[]) => {
  const firstPassRows: Array<FirstPassRowItem> = []

  const addRow = () => {
    firstPassRows.push({
      left: [],
      right: [],
    })
  }

  // add the first row
  addRow()

  const getCurrentRow = () => firstPassRows[firstPassRows.length - 1]

  const processNextDiff = ({ text, diffType }: EnrichedDiff) => {
    const isWhitespace =
      diffType !== DIFF_EQUAL && !!text.includes('\n') && text.trim() === ''
    if (isWhitespace) {
      getCurrentRow().newline = diffType === DIFF_INSERT ? 'right' : 'left'
    }
    text.split('\n').forEach((line, index) => {
      if (index > 0) {
        addRow()
      }
      const changed = diffType !== DIFF_EQUAL
      if (diffType !== DIFF_INSERT && line !== '') {
        getCurrentRow().left.push({ changed, text: line })
      }
      if (diffType !== DIFF_DELETE && line !== '') {
        getCurrentRow().right.push({ changed, text: line })
      }
    })
  }

  diffs.forEach(processNextDiff)
  return firstPassRows
}

// on the first pass we wind up with some sub-optimal arrays for
// word diffs (e.g. { changed: false, value: '' } or back-to-back
// changed items that can be consolidated)
// const consolidateWordDiffItems = (items: WordDiffItem[]) => {}

const tweakFirstPassRow = (row: FirstPassRowItem): SideBySideDiffLine => {
  const leftIsSame = !row.left.find((r) => r.changed)
  const rightIsSame = !row.right.find((r) => r.changed)

  if (leftIsSame && rightIsSame) {
    return {
      type: 'unchanged',
      text: row.left.map((item) => item.text).join(''),
      newline: row.newline,
    }
  }

  if (row.left.length === 0) {
    return {
      type: 'linediff',
      side: 'right',
      value: row.right.map((v) => v.text).join(''),
      newline: row.newline,
    }
  }

  if (row.right.length === 0) {
    return {
      type: 'linediff',
      side: 'left',
      value: row.left.map((v) => v.text).join(''),
      newline: row.newline,
    }
  }

  return {
    type: 'worddiff',
    left: row.left,
    right: row.right,
    newline: row.newline,
  }
}

const isMeaningfulLine = (line: SideBySideDiffLine): boolean => {
  if (isUnchanged(line)) {
    return line.text !== ''
  }
  if (isLineDiff(line)) {
    return line.value !== ''
  }
  return true
}

export const buildSideBySide = (diffs: EnrichedDiff[]) => {
  const firstPassRows = buildSideBySideStructure(diffs)

  const lines = firstPassRows.map(tweakFirstPassRow)
  // strip out lines where both sides are blank. Many lines returns were inserted by
  // this class to keep the PM character count
  const meaningfulLines = lines.filter(isMeaningfulLine)

  return meaningfulLines
}

export const filterLines = (
  lines: SideBySideDiffLine[],
  linesOfContext: number
) => {
  const changedLineIndexMap: { [key: number]: boolean } = {}
  lines.forEach((line, index) => {
    const same = isUnchanged(line) && line.newline === undefined
    if (!same) {
      for (
        let i = index - linesOfContext;
        i < index + linesOfContext + 1;
        i++
      ) {
        changedLineIndexMap[i] = true
      }
    }
  })

  let currentOmittedCount = 0
  const result: SideBySideDiffLine[] = []

  const pushOmittedIfNeeded = () => {
    if (currentOmittedCount > 0) {
      result.push({
        type: 'omitted',
        count: currentOmittedCount,
      })
      currentOmittedCount = 0
    }
  }

  lines.forEach((line, index) => {
    const include = !!changedLineIndexMap[index]
    if (include) {
      pushOmittedIfNeeded()
      result.push(line)
    } else {
      currentOmittedCount += 1
    }
  })

  pushOmittedIfNeeded()
  return result
}
