import React from 'react'
import { useEventListener } from 'usehooks-ts'
import { Mark } from 'prosemirror-model'
import { useMst } from '@state'
import { selectionHeadPosition } from '@util'
import {
  addOrEditLinkMark,
  getLinkForSelection,
  removeLinkMark,
} from './getLinkForSelection'

export const useInlineHyperlink = () => {
  const { view, currentScript } = useMst()
  const ref = React.useRef<HTMLElement | null>(null)

  const editorView = currentScript?.observableEditor?.editorView
  const selection = currentScript?.observableEditor?.textSelection
  const editorViewElt = currentScript?.observableEditor?.editorView?.dom
  const isEditing = currentScript?.observableEditor?.editingHyperlink
  const setEditing = (value: boolean) =>
    currentScript?.observableEditor?.setEditingHyperlink(value)

  React.useEffect(() => {
    if (editorViewElt instanceof HTMLElement) {
      ref.current = editorViewElt
    } else {
      ref.current = null
    }
  }, [editorViewElt])

  const [mouseDown, setMouseDown] = React.useState(false)
  const [shiftDown, setShiftDown] = React.useState(false)

  useEventListener(
    'mousedown',
    () => {
      setMouseDown(true)
    },
    ref
  )

  useEventListener(
    'mouseup',
    () => {
      setMouseDown(false)
    },
    ref
  )

  useEventListener(
    'keydown',
    (e) => {
      setShiftDown(e.shiftKey)
    },
    ref
  )

  useEventListener(
    'keyup',
    (e) => {
      if (shiftDown && !e.shiftKey) {
        setShiftDown(false)
      }
    },
    ref
  )

  const userSelecting = (mouseDown || shiftDown) && editorView?.hasFocus()
  const currentHyperlink = editorView
    ? getLinkForSelection(editorView)
    : undefined
  const headPosition =
    editorView && selection
      ? selectionHeadPosition({
          editorView,
          selection,
          zoomLevel: view.editorZoom,
        })
      : undefined

  const removeCurrentLink = () => {
    if (currentHyperlink && editorView) {
      removeLinkMark({ mark: currentHyperlink, editorView })
    }
  }

  const addOrEditLink = (text: string, mark?: Mark) =>
    addOrEditLinkMark({ text, mark, editorView })

  return {
    userSelecting,
    headPosition,
    currentHyperlink,
    isEditing,
    addOrEditLink,
    removeCurrentLink,
    setEditing,
  }
}
