import React from 'react'
import cn from 'classnames'
import { useTimeout } from 'usehooks-ts'
import { showError } from '@components/Modals'
import { Toolbar } from '@components/Toolbar'
import { FaIcon, GreenCheckIcon } from '@components/FaIcon'
import { useMst } from '@state'
import { ButtonMode, ILoadedScript } from '@state/types'

import styles from './CreateSnapshot.module.scss'

const btnLabel = 'Snapshot'
const btnIcon = <FaIcon icon="fa-camera" />

export const CreateSnapshotButton = ({
  script,
  mode,
}: {
  script?: ILoadedScript
  mode?: ButtonMode
}) => {
  const { doDebug } = useMst()
  const [label, setLabel] = React.useState(btnLabel)
  const [icon, setIcon] = React.useState(btnIcon)
  // we set delay to a number to trigger reverting the buttons default props
  const [delay, setDelay] = React.useState<number | null>(null)

  const resetBtn = () => {
    setDelay(null)
    setLabel(btnLabel)
    setIcon(btnIcon)
  }

  useTimeout(resetBtn, delay)

  const handleSnapshot = async () => {
    try {
      await doDebug()
      await script?.createSnapshot()
      setLabel('Saved')
      setIcon(<GreenCheckIcon />)
      setDelay(1000)
    } catch {
      showError('An unexpected error was encountered saving a snapshot')
    }
  }

  return (
    <Toolbar.EditorFocusButton
      enableWithoutFocus
      buttonClasses={cn({
        [styles.btn]: mode === 'normal',
      })}
      customIcon={icon}
      label={label}
      onClick={handleSnapshot}
      mode={mode}
    />
  )
}
