import { useMst } from '@state'
import { Button } from '@mantine/core'
import { parseServerError } from '@util'
import { OrgPayload, ScriptFormatSummary } from '@util/ScriptoApiClient/types'
import { useForm } from 'react-hook-form'
import { showAlert } from '@components/Modals'

type Inputs = {
  studioFormatId: string
  screenplayFormatId: string
}

export function DefaultFormatsForm({
  org,
  formats,
}: {
  org: OrgPayload
  formats: ScriptFormatSummary[]
}) {
  const { apiClient } = useMst()
  const { register, handleSubmit, setError } = useForm({
    defaultValues: {
      apiError: '',
      studioFormatId: org.studioFormat.id,
      screenplayFormatId: org.screenplayFormat.id,
    },
  })

  const setFormats = async (data: Inputs) => {
    try {
      await apiClient.setScriptFormatsForOrg({
        orgId: org.id,
        data,
      })
      showAlert({
        title: 'Success',
        children: 'Default formats updated successfully',
      })
    } catch (e) {
      const { message } = parseServerError(e)
      setError('apiError', { type: 'custom', message })
    }
  }

  return (
    <form onSubmit={handleSubmit(setFormats)} className="s-form s-form--inline">
      <div>
        <label>Studio Format</label>
        <br />
        <select {...register('studioFormatId')}>
          {formats.map((f) => {
            const label = `${f.id} (${f.name}) `
            return (
              <option key={f.id} value={f.id}>
                {label}
              </option>
            )
          })}
        </select>
      </div>
      <br />
      <div>
        <label>Screenplay Format</label>
        <br />
        <select {...register('screenplayFormatId')}>
          {formats.map((f) => {
            const label = `${f.id} (${f.name}) `
            return (
              <option key={f.id} value={f.id} title={f.description}>
                {label}
              </option>
            )
          })}
        </select>
      </div>
      <br />
      <Button type="submit">Save Changes</Button>
    </form>
  )
}
