import {
  ActionIcon,
  Center,
  Group,
  Space,
  Stack,
  Text,
  Title,
} from '@mantine/core'
import { FaIcon } from '@components/FaIcon'

export const SplitLayoutPlaceholder = ({
  onClose,
}: {
  onClose: () => void
}) => (
  <div>
    <Group justify="flex-end">
      <ActionIcon onClick={onClose}>
        <FaIcon icon="fa-close" />
      </ActionIcon>
    </Group>
    <Center>
      <Stack>
        <Space h={50} />
        <Title order={3}>Split View</Title>
        <Text>Click on a script to open it in this pane</Text>
      </Stack>
    </Center>
  </div>
)
