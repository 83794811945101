import { types, getRoot, getEnv, IAnyStateTreeNode } from 'mobx-state-tree'
import { Dict } from 'mixpanel-browser'
import type { Environment, IRoot } from '../types'

export const getEnvironment = (node: IAnyStateTreeNode) =>
  getEnv<Environment>(node)

export const getRootInstance = (node: IAnyStateTreeNode) => getRoot<IRoot>(node)

// the base model is a common base for all the other models (except root?). It has convenience
// methods attached to get the mst environment and to access other tree nodes by means of getting to root
export const BaseModel = types
  .model('Base', {})
  .views((self) => ({
    get environment(): Environment {
      return getEnv(self)
    },
    get rootStore(): IRoot {
      return getRootInstance(self)
    },
    get apiClient(): Environment['apiClient'] {
      return this.environment.apiClient
    },
    get scrapi(): Environment['scrapiClient'] {
      return this.environment.scrapiClient
    },
    get legacyApi(): Environment['legacyApiClient'] {
      return this.environment.legacyApiClient
    },
    get analytics() {
      return this.environment.analytics
    },
    get MIXPANEL_EVENTS() {
      return this.environment.analytics.EVENTS
    },
    /*
      All mst model instances now have log.debug, log.warn, etc.
      And since the mst root has it you can do:

      const { log } = useMst()
      log.info('This is a log message', { details: 'boop' })
    */
    get log() {
      const { debug, info, warn, error } = this.environment.datadog
      return {
        debug,
        info,
        warn,
        error,
      }
    },
  }))
  .actions((self) => ({
    trackEvent(eventName: string, eventProperties?: Dict) {
      // on every event, we log some standard properties. These are properties
      // on the event, not user properties.
      const standardProps: Dict = {}
      const { currentOrg, currentScript } = self.rootStore

      if (currentOrg) {
        standardProps.orgId = currentOrg.id
      }

      if (currentScript) {
        standardProps.scriptId = currentScript.id
      }

      self.rootStore.environment.analytics.track(eventName, {
        ...standardProps,
        ...eventProperties,
      })
    },
  }))
