import { ScriptoApiClient } from '@util'

// 15 minutes seems often enough to refetch
export const staleTime = 1000 * 60 * 15

export const SCHEMA_QUERY_KEY = 'staff_schema_query'

export const rundownSchemaQuery = (apiClient: ScriptoApiClient) => {
  return {
    queryKey: [SCHEMA_QUERY_KEY],
    queryFn: async () => await apiClient.fetchRundownSchemas(),
    staleTime: 1000 * 60 * 15,
  }
}
