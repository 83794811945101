import React from 'react'
import NiceModal from '@ebay/nice-modal-react'
import { Group, Stack, Radio, ScrollArea, Text, Divider } from '@mantine/core'
import { useModalControls } from '@components/Modals'
import { ModalShell } from '@components/Modals'

import styles from './SelectSlenesModal.module.scss'

export const FULL_DOCUMENT = 'full'
export type SleneItem = { label: string; id: string }

const SideSelector = ({
  position,
  items,
  selectedId,
  setSelectedId,
}: {
  position: 'left' | 'right'
  items: Array<SleneItem>
  selectedId: string
  setSelectedId: (id: string) => void
}) => {
  return (
    <Stack className={styles.sideSelector} gap={0}>
      <div className={styles.sideTitle}>
        {position === 'left' ? 'Snapshot' : 'Current Document'}
      </div>

      <Radio
        checked={selectedId === 'full'}
        value="full"
        label="Full Document"
        className={styles.fullDocument}
        onChange={() => setSelectedId('full')}
      />
      <Divider orientation="horizontal" className={styles.divider} />
      <ScrollArea className={styles.sleneList_scroller} type="auto">
        <Stack className={styles.sleneList}>
          {items.map(({ id, label }) => (
            <Radio
              className={styles.sideRadio}
              key={id}
              label={label}
              value={id}
              checked={selectedId === id}
              onChange={() => setSelectedId(id)}
            />
          ))}
        </Stack>
      </ScrollArea>
    </Stack>
  )
}

export type SelectSlenesProps = {
  leftItems: SleneItem[]
  rightItems: SleneItem[]
  selectedLeftId?: string | null
  selectedRightId?: string | null
  sleneType: 'slug' | 'scene'
  onSelect: (choices: {
    left: SleneItem | null
    right: SleneItem | null
  }) => void
}

const SelectSlenesModal = NiceModal.create<SelectSlenesProps>(
  ({
    leftItems,
    rightItems,
    selectedLeftId,
    selectedRightId,
    sleneType,
    onSelect,
  }) => {
    const title =
      sleneType === 'scene'
        ? 'Select scenes to compare'
        : 'Select slugs to compare'
    const { onClose, opened } = useModalControls()
    const [localLeftId, setLocalLeftId] = React.useState<string>(
      selectedLeftId ?? FULL_DOCUMENT
    )
    const [localRightId, setLocalRightId] = React.useState<string>(
      selectedRightId ?? FULL_DOCUMENT
    )

    const handleSelect = () => {
      const left = leftItems.find((item) => item.id === localLeftId) ?? null
      const right = rightItems.find((item) => item.id === localRightId) ?? null
      onSelect({ left, right })
      onClose()
    }

    return (
      <ModalShell
        size={600}
        confirmLabel="Compare"
        cancelLabel="Cancel"
        opened={opened}
        title={title}
        onClose={onClose}
        onConfirm={handleSelect}
      >
        <Group gap={10} justify="center" align="flex-start">
          <SideSelector
            position="left"
            selectedId={localLeftId}
            setSelectedId={setLocalLeftId}
            items={leftItems}
          />

          <Text>vs</Text>

          <SideSelector
            position="right"
            selectedId={localRightId}
            setSelectedId={setLocalRightId}
            items={rightItems}
          />
        </Group>
      </ModalShell>
    )
  }
)

export const showSelectSlenes = (props: SelectSlenesProps) => {
  NiceModal.show(SelectSlenesModal, props)
}
