import React from 'react'
import { observer } from 'mobx-react-lite'
import cn from 'classnames'
import { IRundown } from '@state/types'
import { useMst } from '@state'
import { useNavigation, useScriptMultiClick } from '@hooks'
import { NoScriptsInRundown } from '../EmptyOutline'
import styles from './RundownOutline.module.scss'

const ScriptItem = ({
  children,
  scriptId,
}: {
  scriptId: string
  children: React.ReactNode
}) => {
  const { location } = useMst()
  const { navigateToScript } = useNavigation()
  const isSelected = location.getPathParam('scriptId') === scriptId
  const ref = React.useRef<HTMLDivElement>(null)

  useScriptMultiClick<HTMLDivElement>({
    ref,
    onClick: () => navigateToScript(scriptId),
  })

  return (
    <div
      ref={ref}
      className={cn(styles.scriptItem, { [styles.selected]: isSelected })}
    >
      {children}
    </div>
  )
}

export const RundownOutline = observer(function RundownOutline({
  rundown,
}: {
  rundown: IRundown
}) {
  return (
    <div className={styles.rundownOutline}>
      {rundown.orderedScripts.length === 0 && <NoScriptsInRundown />}
      {rundown.orderedScripts.map(({ scriptId, name }) => (
        <ScriptItem key={scriptId} scriptId={scriptId}>
          {name}
        </ScriptItem>
      ))}
    </div>
  )
})
