import { PageMarginSizes } from './types'

export const TWO_COLUMN_MARGIN_SIZE: PageMarginSizes = {
  top: '1in',
  left: '0.5in',
  right: '0.5in',
  bottom: '0.75in',
}

export const ONE_COLUMN_MARGIN_SIZE: PageMarginSizes = {
  top: '1.0in',
  left: '1.5in',
  right: '1in',
  // should be an inch, but we need to cheat it
  // see OneColumnScript.scss for associated translation
  bottom: '0.5in',
}

type MarginValues = {
  top: number
  left: number
  right: number
  bottom: number
}
class MarginDef {
  constructor(private values: MarginValues, private units: 'in' | 'px') {}

  private format(side: keyof MarginValues) {
    return `${this.values[side]}${this.units}`
  }

  get top() {
    return this.values.top
  }

  get bottom() {
    return this.values.bottom
  }

  get left() {
    return this.values.left
  }

  get right() {
    return this.values.right
  }

  get pageMarginSizes(): PageMarginSizes {
    return {
      top: this.format('top'),
      left: this.format('left'),
      bottom: this.format('bottom'),
      right: this.format('right'),
    }
  }
}

export const RUNDOWN_MARGIN_DEF = new MarginDef(
  {
    top: 1,
    left: 0.5,
    right: 0.25,
    bottom: 0.6,
  },
  'in'
)

export const RUNDOWN_MARGIN_SIZE: PageMarginSizes =
  RUNDOWN_MARGIN_DEF.pageMarginSizes

export const HIDE_HEADERS_ON_FIRST_PAGE = `
  @page:first {
    @top {
      content: normal;
    }
  }
`

export const HIDE_FOOTERS_ON_FIRST_PAGE = `
  @page:first {
    @bottom {
      content: normal;
    }
  }
`

export const FONTS = `
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap" rel="stylesheet">
`
