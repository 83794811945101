import { types } from 'mobx-state-tree'
import {
  FormattedTiming,
  FormattedSlugTiming,
} from 'src/choo-app/lib/editor/plugins/meta/timing'

const TimingPojo = {
  total: '',
  bracket: '',
  dialogue: '',
}

export const Timing = types.frozen<FormattedTiming>(TimingPojo)

export const SlugTiming = types.frozen<FormattedSlugTiming>({
  ...TimingPojo,
  title: '',
  isBlank: false,
  isZeroth: false,
})
