import html from 'nanohtml'
const hl = 'c-commentthread__highlight'
class CommentNodeView {
  constructor(node) {
    this.highlight = this.highlight.bind(this)
    this.id = node.attrs.id
    let className = 'e-comment'
    if (!node.attrs.resolved) {
      className += ' is-highlighted'
    }
    this.dom = html`<span
      id="e-comment-${this.id}"
      class=${className}
      onclick=${this.highlight}
    ></span>`
    this.contentDOM = this.dom
  }
  // just a quick visual to demonstrate
  highlight() {
    const prev = document.querySelector(`.${hl}`)
    if (prev) {
      prev.classList.remove(hl)
    }
    const cur = document.getElementById(`comment-${this.id}`)
    if (cur) {
      cur.classList.add(hl)
    }
  }
}
export { CommentNodeView }
export default {
  CommentNodeView,
}
