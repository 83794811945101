import { observer } from 'mobx-react-lite'
import { Menu } from '@mantine/core'
import { OrgOptionMap, OrgPermissionMap } from '@showrunner/codex'
import { useMst } from '@state'
import { IRundown } from '@state/types'
import { FancyMenuItem } from '@components/FancyMenuItem'
import { Spinner } from '@components/Spinner'
import { showColumnVisibilityModal } from './ColumnVisibilityModal'
import { EditMenu } from './EditMenu'
import { InsertMenu } from './InsertMenu'
import { NumberingMenu } from './NumberingMenu'
import { showPrintPreferences } from '@components/RundownPrinting/PrintPreferences'
import { showPushRundownToPrompterModal } from '@components/Modals'
import { ReadonlyRundownBanner } from '@components/ReadOnlyBanner'
import { Toolbar } from '@components/Toolbar'
import {
  useFileCreation,
  useShortcuts,
  Keys,
  useElementDimensions,
} from '@hooks'

const makeHiddenColumnLabel = (columnCount: number): string => {
  if (columnCount === 0) {
    return 'Hide columns'
  }
  const plural = columnCount > 1 ? 's' : ''
  return `${columnCount} hidden column${plural}`
}

export const RundownToolbar = observer(function RundownToolbar({
  rundown,
}: {
  rundown: IRundown
}) {
  const { currentOrg, user, view } = useMst()
  const { ref, dimensions } = useElementDimensions()

  const creation = useFileCreation()
  const TOOLBAR_BREAKPOINT = user.canEditRundowns ? 600 : 450
  const btnMode = dimensions.width >= TOOLBAR_BREAKPOINT ? 'normal' : 'mini'

  const fakeWindows = view.isDebugEnabled('windows')

  const openPrintPreferences = () => showPrintPreferences({ rundown })

  const orgHasPrompter = currentOrg?.options.some(
    (c) => c.code === OrgOptionMap.PROMPTER_INTEGRATION && c.enabled
  )
  const userCanPrompt = user.currentOrgPermissions.includes(
    OrgPermissionMap.PUSH_TO_PROMPTER
  )

  const canExportScripts = rundown.orderedScripts.length < 1

  const openPushToPrompter = () => {
    if (orgHasPrompter && userCanPrompt) {
      showPushRundownToPrompterModal(rundown)
    }
  }

  const shortcutConfigs = {
    print: {
      keys: [Keys.CMD, 'P'],
      action: openPrintPreferences,
    },
    pushToPrompter: {
      keys: [Keys.CMD, Keys.SHIFT, 'P'],
      action: openPushToPrompter,
      disabled: !userCanPrompt,
    },
  }

  const { menuProps, getItemProps } = useShortcuts(
    shortcutConfigs,
    fakeWindows ? { platform: 'win' } : {}
  )

  const hiddenColumnCount = rundown.hiddenScreenColumnCount

  return (
    <div ref={ref}>
      <Toolbar>
        {creation.isCreating && <Spinner delayMs={300} fullScreen />}
        <Toolbar.Section position="right">
          {user.canEditRundowns && (
            <>
              <EditMenu rundown={rundown} buttonMode={btnMode} />
              <InsertMenu rundown={rundown} buttonMode={btnMode} />
              <NumberingMenu rundown={rundown} buttonMode={btnMode} />
            </>
          )}
          <Menu>
            <Menu.Target>
              <div>
                <Toolbar.Button
                  active={hiddenColumnCount > 0}
                  icon="fa-eye-slash"
                  onClick={() => showColumnVisibilityModal(rundown)}
                  label={makeHiddenColumnLabel(hiddenColumnCount)}
                  mode={btnMode}
                  dropdown
                />
              </div>
            </Menu.Target>
          </Menu>
          <Toolbar.Divider />
          <Menu {...menuProps} withinPortal>
            <Menu.Target>
              <div>
                <Toolbar.Button
                  label="Export"
                  icon="fa-share-from-square"
                  dropdown
                  mode={btnMode}
                />
              </div>
            </Menu.Target>
            <Menu.Dropdown>
              <FancyMenuItem
                title="Print rundown..."
                {...getItemProps('print')}
              />
              <FancyMenuItem
                title="Print rundown scripts..."
                onClick={() => {
                  creation.createFile({ fileType: 'printScripts', rundown })
                }}
                disabled={canExportScripts}
              />
              <FancyMenuItem
                title="Export rundown scripts as PDF"
                onClick={() => {
                  creation.createFile({ fileType: 'downloadScripts', rundown })
                }}
                disabled={canExportScripts}
              />
              {orgHasPrompter && (
                <>
                  <Menu.Divider />
                  <FancyMenuItem
                    title="Push to prompter..."
                    {...getItemProps('pushToPrompter')}
                  />
                </>
              )}
            </Menu.Dropdown>
          </Menu>
        </Toolbar.Section>
      </Toolbar>
      {!user.canEditRundowns && <ReadonlyRundownBanner />}
    </div>
  )
})
