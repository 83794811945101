import { delay } from '@util'
import { DatadogClient } from '@util/datadog'
import { buildLoginRedirect, ROUTE_PATTERNS } from './pathConfigs'

const ddLog = DatadogClient.getInstance()

export const handle401 = async () => {
  // If we're on the login page itself, noop
  if (window.location.pathname === ROUTE_PATTERNS.login) {
    return
  }

  ddLog.warn('Unexpected 401, redirecting to login', {
    topic: 'auth',
    path: window.location.pathname,
  })

  window.location.replace(buildLoginRedirect(window.location))
  window.location.reload()
  // We might be in the middle of an api call & we don't really want to show
  // an unknown error ui, so put in a delay that will keep the app from rendering
  // before it reloads (the time is irrelevant)
  await delay(3)
}
