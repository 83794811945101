import { MouseEvent } from 'react'
import { Button } from '@mantine/core'
import { useMst } from '@state'
import { showAlert } from '@components/Modals'

export function ReindexDocsForm({ orgId }: { orgId: string }) {
  const { apiClient } = useMst()

  const onClick = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    try {
      await apiClient.reindexDocsInOrgByStaff({ orgId })
      showAlert({
        title: 'Success',
        children: 'A reindex job has been triggered',
      })
    } catch (e) {
      showAlert({
        title: 'Error',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        children: (e as any).message,
      })
    }
  }

  return (
    <form className="s-form s-form--inline">
      <p>
        Reindexing all the documents in a workspace in ElasticSearch can resolve
        problems with search results.
      </p>
      <Button onClick={onClick}>Reindex documents</Button>
    </form>
  )
}
