import Choo from 'choo'
import Nanobus from 'nanobus'
import io from './io'
import editor from './editor/index'

// for some reason, the typings for choo-store and choo don't match
// so we'll force the things that choo-store creates into the type that
// choo.use needs
type StoreFn = (state: Choo.IState, emitter: Nanobus, app: Choo) => void

export function registerStores(app: Choo) {
  const stores = [io, editor] as unknown as StoreFn[]

  stores.forEach((store) => app.use(store))
}
