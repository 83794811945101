import React from 'react'
import { startOfToday, subDays } from 'date-fns'
import { Group, Stack, Text } from '@mantine/core'
import { DateInput } from '@mantine/dates'
import { FilterSelect } from './Select'
import { usePendingSearchContext } from '../SearchContext'
import { SearchCriteria } from '../helpers'

export const LastEditedFilter = ({
  dateFilterCategory,
  setDateFilterCategory,
}: {
  dateFilterCategory: string
  setDateFilterCategory: React.Dispatch<React.SetStateAction<string>>
}) => {
  const context = usePendingSearchContext()
  const today = startOfToday()

  if (!context) return <></>
  const { pendingCriteria, mergePendingCriteria } = context

  const updateFilters = (filters?: Partial<SearchCriteria>) =>
    mergePendingCriteria({
      modifiedAfter: undefined,
      modifiedBefore: undefined,
      ...filters,
    })

  const handleChange = (val: string | null) => {
    const yesterday = subDays(today, 1)
    switch (val) {
      case 'Today':
        updateFilters({ modifiedAfter: today })
        break
      case 'Yesterday':
        updateFilters({
          modifiedBefore: today,
          modifiedAfter: yesterday,
        })
        break
      case 'Within 7 days':
        updateFilters({ modifiedAfter: subDays(today, 6) })
        break
      case 'Within 30 days':
        updateFilters({ modifiedAfter: subDays(today, 29) })
        break
      default:
        // Custom..., Any date
        updateFilters()
    }
    if (val) setDateFilterCategory(val)
  }

  // the mantine clear button is flaky and sometimes needs to be clicked more than
  // once to clear the input if it doesn't have focus or the picker is visible.
  // this is a hack to hide it altogether and require users to clear using the keyboard
  const btnProps = {
    style: { color: '#fff', cursor: 'default' },
  }

  return (
    <>
      <Stack gap={6}>
        <Text fw="bold" fz={15}>
          Last edited
        </Text>
        <FilterSelect
          isSelected={dateFilterCategory !== 'Any date'}
          data={[
            'Any date',
            'Today',
            'Yesterday',
            'Within 7 days',
            'Within 30 days',
            'Custom...',
          ]}
          value={dateFilterCategory}
          onChange={handleChange}
        />
      </Stack>
      {dateFilterCategory === 'Custom...' && (
        <Group justify="space-between">
          <DateInput
            value={pendingCriteria.modifiedAfter}
            onChange={(d) => {
              mergePendingCriteria({
                modifiedAfter: d ?? undefined,
              })
            }}
            label="On or after"
            placeholder="mm/dd/yyyy"
            valueFormat="MM/DD/YYYY"
            maxDate={pendingCriteria.modifiedBefore ?? today}
            clearable
            clearButtonProps={btnProps}
            mx={0}
            w={140}
          />
          <DateInput
            value={pendingCriteria.modifiedBefore}
            onChange={(d) => {
              mergePendingCriteria({
                modifiedBefore: d ?? undefined,
              })
            }}
            label="Before"
            placeholder="mm/dd/yyyy"
            valueFormat="MM/DD/YYYY"
            minDate={pendingCriteria.modifiedAfter}
            maxDate={today}
            clearable
            clearButtonProps={btnProps}
            mx={0}
            w={140}
          />
        </Group>
      )}
    </>
  )
}
