import { Button, Group, Menu, Text } from '@mantine/core'
import { FaIcon } from '@components/FaIcon'
import { FancyMenuItem } from '@components/FancyMenuItem'
import { capitalize } from '@util'
import { CommentFilter, CommentFilterValues } from './helpers'

import styles from './CommentHistory.module.scss'

export const CommentHeader = ({
  filter,
  setFilter,
}: {
  filter: CommentFilter
  setFilter: (value: CommentFilter) => void
}) => (
  <Group
    className={styles.commentHistory_header}
    gap={0}
    justify="space-between"
  >
    <Text fw="bold">Comments</Text>
    <Menu position="bottom" withinPortal={false}>
      <Menu.Target>
        <Button className={styles.commentHistory_filter} size="compact-xs">
          <Text size="14" c="dark">
            {capitalize(filter)}
          </Text>
          <FaIcon
            className={styles.commentHistory_caret}
            size="12"
            color="dark"
            icon="fa-caret-down"
          />
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        {CommentFilterValues.map((f) => (
          <FancyMenuItem
            key={f}
            title={capitalize(f)}
            selected={filter === f}
            onClick={() => setFilter(f)}
          />
        ))}
      </Menu.Dropdown>
    </Menu>
  </Group>
)
