import { Script } from '@layouts'
import { ScriptLoadErrorPage } from '@components/ErrorPage'
import { useQuery } from '@tanstack/react-query'
import { Loader } from '@components/Loader'
import { useMst } from '@state'

// lightweight wrapper to trigger the enter/exit effects for the Script. The
// rest of the data loading is happening inside the choo app
export const EditorRoute = ({ params }: { params: { scriptId: string } }) => {
  const mst = useMst()
  const { data, isLoading, isError, error } = useQuery({
    queryFn: () => mst.requestScript(params.scriptId),
    queryKey: ['script', params.scriptId],
    cacheTime: 0,
    staleTime: Infinity,
  })

  if (isError) {
    return <ScriptLoadErrorPage error={error} scriptId={params.scriptId} />
  }

  if (isLoading) {
    return <Loader visible />
  }

  /*
    Note, the key on <Script> is important. We want to recreate the component
    any time the scriptId changes so choo can tear down/rebuild the editor
  */
  return <Script payload={data} key={data.id} />
}
