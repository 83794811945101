import { Stack, Text, Container } from '@mantine/core'
import styles from './Placeholder.module.scss'

const Placeholder = ({ t }: { t: string }) => (
  <Container px={30} className={styles.placeholder_container}>
    <Stack align="center" justify="center" className={styles.placeholder_stack}>
      <Text ta="center" c="dimmed">
        {t}
      </Text>
    </Stack>
  </Container>
)

export const FavoritesPlaceholder = () => (
  <Placeholder t="You have not favorited any documents yet." />
)

export const RecentPlaceholder = () => (
  <Placeholder t="No documents have been edited in the last 30 days." />
)
