import React from 'react'
import { useMst } from '@state'
import { showError } from '@components/Modals'
import {
  getBlobAndPrint,
  getBlobAndSave,
  getPdfBlobForScript,
} from '@util/printing'
import { ILoadedScript, IRundown } from '@state/types'

export type ExportOptions =
  | 'bracketsList'
  | 'characterReport'
  | 'fdx'
  | 'fountain'
  | 'prompter'
  | 'downloadScripts'
  | 'printScripts'
  | 'line-data'

export const useFileCreation = () => {
  const [isCreating, setIsCreating] = React.useState(false)
  const mst = useMst()

  const createFile = async ({
    fileType,
    script,
    rundown,
  }: {
    fileType: 'download' | 'print' | ExportOptions
    script?: ILoadedScript
    rundown?: IRundown
  }) => {
    setIsCreating(true)

    const createScriptPdf = async (type: 'print' | 'download') => {
      if (!script) {
        return
      }
      const downloadFn = async () =>
        getPdfBlobForScript({
          scriptId: script.id,
          title: script.name,
          mst,
        })

      if (type === 'print') {
        await getBlobAndPrint(downloadFn)
      } else {
        await getBlobAndSave({ downloadFn, fileName: script.name + '.pdf' })
      }
    }

    try {
      await mst.doDebug()
      switch (fileType) {
        case 'print':
        case 'download':
          await createScriptPdf(fileType)
          break
        case 'bracketsList':
          await script?.exportBracketsList()
          break
        case 'characterReport':
          await script?.exportCharacterReport()
          break
        case 'fdx':
          await script?.exportFdx()
          break
        case 'fountain':
          await script?.exportFountain()
          break
        case 'prompter':
          await script?.exportPrompter()
          break
        case 'downloadScripts':
          await rundown?.glueScriptPdfs('download')
          break
        case 'printScripts':
          await rundown?.glueScriptPdfs('print')
          break
        case 'line-data': {
          const success = await script?.exportLineData()
          if (!success) {
            showError('We encountered an unexpected error during export')
          }
          break
        }
      }
    } catch (e) {
      showError('We encountered an unexpected error during export')
    } finally {
      setIsCreating(false)
    }
  }

  return {
    isCreating,
    createFile,
  }
}
