// This hook lets us handle async rundown operations that might succeed
// or fail using a common framework for error messages,
import React from 'react'
import { Text, Checkbox } from '@mantine/core'
import { IRundown, IUser } from '@state/types'
import { GridBlobColumnKey } from '@util/rundowns'
import { ItemNumberGenerator } from '@components/RundownToolbar/ItemNumberingModal'
import { showAsyncConfirmModal } from '@components/Modals'
import { showItemNumberingModal } from '@components/RundownToolbar'
import { showError } from '@components/Modals'
import { pluralize } from '@util'
import { buildSmartItemNumberGenerator } from '@components/RundownToolbar/generators'

export function useRundownOperations(rundown: IRundown, user?: IUser) {
  // any time we start an async operation, we want to mark the grid as loading
  // and do the debug flag dance
  const startAsyncOperation = async () => {
    await rundown.rootStore.doDebug()
    rundown.setGridLoading(true)
  }

  // This handles all the async operations that don't have a confirmation.
  const makeAsyncUpdate = async <T,>({
    updateFn,
    rollbackFn,
    errorMessage,
  }: {
    updateFn: () => Promise<T>
    rollbackFn?: () => void
    errorMessage: string
  }): Promise<boolean | undefined> => {
    let updateSucceeded = false

    try {
      rundown.setGridLoading(true)
      await startAsyncOperation()
      await updateFn()
      updateSucceeded = true
    } catch (e) {
      updateSucceeded = false
      showError({
        message: errorMessage,
      })
      rollbackFn?.()
    }
    rundown.setGridLoading(false)
    return updateSucceeded
  }

  const insertBlankRows = async (params: {
    rowCount: number
    sequence: number
    rowTypeId: 'element' | 'header'
    rowLevel?: 1 | 2 | 3
  }) => {
    const errorMessage =
      params.rowCount > 1 ? 'Failed to add new rows' : 'Failed to add a new row'
    await makeAsyncUpdate({
      updateFn: () => rundown.insertBlankRows(params),
      errorMessage,
    })
  }

  // this one is a little different because it has a confirmation modal
  const deleteRows = async (rowIds: number[]) => {
    // we update the pref immediately and revert if the user cancels out later
    const onChange = (val: React.ChangeEvent<HTMLInputElement>) => {
      user?.setSuppressConfirmations(val.target.checked)
    }

    const confirmationMessage = (
      <>
        <Text>{`Are you sure you want to delete ${rowIds.length} ${pluralize(
          rowIds.length,
          'row'
        )} from the rundown?`}</Text>
        <Checkbox size="xs" label="Don't ask me again" onChange={onChange} />
      </>
    )

    const onConfirm = async () => {
      try {
        await startAsyncOperation()
        await rundown.removeRows(rowIds)
      } finally {
        rundown.setGridLoading(false)
      }
    }

    showAsyncConfirmModal({
      title: 'Delete Rows',
      children: confirmationMessage,
      dangerous: true,
      confirmLabel: 'Delete',
      errorMessage: 'Failed to delete any rows',
      onConfirm,
      onClose: () => user?.setSuppressConfirmations(false),
    })
  }

  const moveRowsToPosition = async ({
    rowIds,
    sequence,
  }: {
    rowIds: number[]
    sequence: number
  }) =>
    makeAsyncUpdate({
      updateFn: () => rundown.moveRowsToPosition({ rowIds, sequence }),
      errorMessage: `Failed to re-order row${rowIds.length > 1 ? 's' : ''}`,
    })

  const insertScriptRow = async (scriptId: string, sequence: number) => {
    return makeAsyncUpdate({
      updateFn: () => rundown.insertScriptRow({ scriptId, sequence }),
      errorMessage: 'Failed to add script to rundown',
    })
  }

  const importRows = async (params: { scriptId: string; sequence: number }) => {
    return makeAsyncUpdate({
      updateFn: async () => rundown.importRows(params),
      errorMessage: 'Failed to import rows from rundown',
    })
  }

  const updateCellValue = async ({
    rowId,
    columnKey,
    value,
  }: {
    rowId: number
    columnKey: GridBlobColumnKey
    value: JSONValue
  }) => {
    const row = rundown.getRow(rowId)
    if (!row) {
      return
    }

    const oldValue = row.getBlobValue(columnKey)
    // set the value before we make the api call to provide
    // an optimistic UI update (so editing doesn't feel laggy)
    row.updateValue(columnKey, value)

    makeAsyncUpdate({
      updateFn: () => rundown.updateRowBlobData({ rowId, columnKey, value }),
      errorMessage: `Failed to save new value: ${JSON.stringify(value)}`,
      rollbackFn: () => row.updateValue(columnKey, oldValue),
    })
  }

  // this both triggers the item numbers modal and handles
  // the changes
  const handleSetItemNumbers = async (allrows: boolean) => {
    const selectedRows = allrows
      ? rundown.sortedRowInstances
      : rundown.selectedRows

    const onApplyNumbers = async (generator: ItemNumberGenerator) => {
      await rundown.rootStore.doDebug()
      const rowValues = selectedRows.map((row, index) => ({
        rowId: row.id,
        value: generator(index),
      }))
      await rundown.setItemNumbers(rowValues)
    }

    const firstValue = selectedRows[0]?.getBlobValue('blobData.itemNumber')

    showItemNumberingModal({
      firstItemNumber: typeof firstValue === 'string' ? firstValue.trim() : '',
      rowCount: selectedRows.length,
      onApplyNumbers,
    })
  }

  // this both triggers the modal and handles the changes
  const handleRemoveItemNumbers = async (allrows: boolean) => {
    const selectedRows = allrows
      ? rundown.sortedRowInstances
      : rundown.selectedRows

    const rowCount = selectedRows.length

    const onConfirm = async () => {
      const selectedRows = allrows
        ? rundown.sortedRowInstances
        : rundown.selectedRows

      const rowValues = selectedRows.map((row) => ({
        rowId: row.id,
        value: '',
      }))

      await rundown.setItemNumbers(rowValues)
    }

    showAsyncConfirmModal({
      onConfirm,
      size: 'sm',
      title: 'Remove row numbers',
      children: 'This operation will delete existing row numbers.',
      confirmLabel: `Update ${rowCount} ${pluralize(rowCount, 'row')}`,
      errorMessage: 'Failed to remove row numbers',
    })
  }

  // handles changes without triggering an interstitial modal
  const handleSmartNumbering = async () => {
    const generator = buildSmartItemNumberGenerator()
    const rows = rundown.sortedRowInstances

    // we need to keep track of the most recent seed value
    let seedValue: string | undefined
    // and also the row count since
    let emptyRowCount = 0

    const rowValues = rows.map((row) => {
      const value = row.getBlobValue('blobData.itemNumber')
      if (value) {
        seedValue = String(value).trim()
        emptyRowCount = 0
      } else {
        emptyRowCount++
      }

      return {
        rowId: row.id,
        value: value ? String(value) : generator(emptyRowCount, seedValue),
      }
    })

    // no loading state, no problem?
    try {
      await rundown.setItemNumbers(rowValues)
    } catch (e) {
      showError('Failed to update item numbers')
    }
  }

  const insertFromClipboard = async () => {
    const { pasteableRows } = rundown
    if (pasteableRows.length === 0) {
      return
    }

    // check to make sure we're not trying to put in a copy of a script row.
    // The database will not allow this and it's friendlier to catch it here
    // and let the user know before we submit
    const duplicateScriptRow = pasteableRows.find((r) => {
      return r.identityScriptId && rundown.findScriptRow(r.identityScriptId)
    })
    if (duplicateScriptRow) {
      showError({
        title: 'Cannot insert rows',
        message:
          "The copied rows include a script that's already in the rundown",
      })
      return
    }

    makeAsyncUpdate({
      updateFn: () => rundown.insertRowsFromClipboard(),
      errorMessage: `Failed to insert rows`,
    })
  }

  return {
    deleteRows,
    importRows,
    insertBlankRows,
    insertScriptRow,
    moveRowsToPosition,
    updateCellValue,
    handleSetItemNumbers,
    handleSmartNumbering,
    handleRemoveItemNumbers,
    insertFromClipboard,
  }
}
