import React from 'react'
import { observer } from 'mobx-react-lite'
import { Text } from '@mantine/core'
import { useMst } from '@state'
import {
  IFolder,
  IListing,
  IRoot,
  CollectionId,
  isCollectionId,
} from '@state/types'
import { pluralize } from '@util'
import { CreateDocMenu } from '@components/FolderExplorer/FolderTreeHeader/CreateDocMenu'
import { SortMenu } from './SortMenu'
import { FavoritesPlaceholder, RecentPlaceholder } from './Placeholder'
import { DocumentList } from './DocumentList'
import { ExplorerFolderList } from './FolderList'
import { ExplorerView } from '@components/ExplorerView'
import { FolderExplorerToastHost } from '@components/Toast'
import { collectionItemConfig } from './FolderList/folderListHelpers'

import styles from './FolderExplorer.module.scss'

const FOLDER_SECTION_ID = 'folders'
const DOCUMENTS_SECTION_ID = 'documents'

const FolderHeader = observer(function FolderHeader() {
  const { view, folderMap, currentOrg } = useMst()
  const { selectedFolderId, selectedFolderIsCollection } = view
  const selectedFolder = folderMap.get(selectedFolderId)
  const { isSectionCollapsed, toggleSectionCollapsed, setSectionCollapsed } =
    view.explorerState
  // we're only showing a document list if there's a selected folder or
  // selected collection
  const documentListActive = !!selectedFolder || selectedFolderIsCollection
  const isExpanded = !isSectionCollapsed(FOLDER_SECTION_ID)
  const canCreateChild = selectedFolder && !selectedFolder.inTrash
  const handleCreate = () => {
    // Make sure the folder tree section of the explorer is open
    setSectionCollapsed(FOLDER_SECTION_ID, false)
    view.setCreatingChild(true)
  }

  const buttons = canCreateChild ? (
    <ExplorerView.HeaderButton
      tooltip={`Create sub-folder in ${selectedFolder.displayName}`}
      onClick={handleCreate}
      icon="fa-folder-plus"
      iconSet="fa-regular"
    />
  ) : undefined

  const expandIcon = !documentListActive
    ? undefined
    : isExpanded
    ? 'point-down'
    : 'point-right'

  return (
    <ExplorerView.Header
      enableCollapse={documentListActive}
      buttons={buttons}
      title={<span className={styles.folderTreeTitle}>{currentOrg?.name}</span>}
      expandIcon={expandIcon}
      onClick={() => toggleSectionCollapsed(FOLDER_SECTION_ID)}
    />
  )
})

const CollectionHeader = observer(function CollectionHeader({
  docCount,
  collectionId,
}: {
  docCount: number
  collectionId: CollectionId
}) {
  const { view } = useMst()
  const { isSectionCollapsed, toggleSectionCollapsed } = view.explorerState
  const isExpanded = !isSectionCollapsed(DOCUMENTS_SECTION_ID)
  const toggleExpanded = () => toggleSectionCollapsed(DOCUMENTS_SECTION_ID)
  const showDocCount = collectionId !== 'recentlyEdited'

  const docCountStr = showDocCount ? `${docCount} ` : ''
  const label = (
    <>
      <Text span>{`${docCountStr}${pluralize(docCount, 'doc')} in `}</Text>
      <Text span fw="bold">
        {collectionItemConfig[collectionId].label}
      </Text>
    </>
  )

  return (
    <ExplorerView.Header
      enableCollapse
      expandIcon={isExpanded ? 'point-down' : 'point-right'}
      onClick={toggleExpanded}
      title={<SortMenu label={label} />}
    />
  )
})

const DocumentsHeader = observer(function DocumentsHeader({
  parentFolder,
}: {
  parentFolder: IFolder
}) {
  const { view } = useMst()
  const { isSectionCollapsed, toggleSectionCollapsed } = view.explorerState
  const isExpanded = !isSectionCollapsed(DOCUMENTS_SECTION_ID)
  const toggleExpanded = () => toggleSectionCollapsed(DOCUMENTS_SECTION_ID)

  if (!parentFolder) {
    return null
  }

  const docCount = parentFolder.unsortedDocuments.length
  const label = (
    <>
      <Text span>{`${docCount} ${pluralize(docCount, 'doc')} in `}</Text>
      <Text span fw="bold">
        {parentFolder.displayName}
      </Text>
    </>
  )

  const buttons = parentFolder.inTrash ? undefined : (
    <CreateDocMenu folder={parentFolder} />
  )

  return (
    <ExplorerView.Header
      enableCollapse
      expandIcon={isExpanded ? 'point-down' : 'point-right'}
      onClick={toggleExpanded}
      title={<SortMenu label={label} />}
      buttons={buttons}
    />
  )
})

const selectableListings: CollectionId[] = ['favorites', 'recentlyEdited']

const getCurrentListings = (mst: IRoot): IListing[] => {
  const { selectedFolderId } = mst.view

  if (selectedFolderId === 'favorites') return mst.favoriteListings
  if (selectedFolderId === 'recentlyEdited') return mst.recentListings

  const currentFolder = mst.folderMap.get(selectedFolderId)
  return currentFolder?.sortedDocuments ?? []
}

export const FolderExplorer = observer(function FolderExplorer() {
  const mst = useMst()
  const { currentFolder, view } = mst
  const { selectedFolderId } = view
  const listings = getCurrentListings(mst)
  const isCollection = isCollectionId(selectedFolderId)
  const isEmpty = listings.length === 0
  const isFavorites = selectedFolderId === 'favorites'
  const isRecent = selectedFolderId === 'recentlyEdited'

  const showCollection =
    !currentFolder &&
    selectableListings.includes(view.selectedFolderId as CollectionId)
  const { crosshairsRequestDocId } = view.explorerState

  return (
    <ExplorerView>
      <FolderHeader />
      <ExplorerView.Content sectionId="folders" enableCollapse>
        <ExplorerFolderList />
      </ExplorerView.Content>
      {currentFolder && <DocumentsHeader parentFolder={currentFolder} />}
      {isCollection && (
        <CollectionHeader
          collectionId={selectedFolderId}
          docCount={listings.length}
        />
      )}
      <ExplorerView.Content sectionId="documents" enableCollapse>
        {isEmpty && isFavorites && <FavoritesPlaceholder />}
        {isEmpty && isRecent && <RecentPlaceholder />}
        <DocumentList
          documents={listings}
          crosshairsDocId={showCollection ? crosshairsRequestDocId : undefined}
          key={view.selectedFolderId}
        />
      </ExplorerView.Content>
      <FolderExplorerToastHost />
    </ExplorerView>
  )
})
