import React from 'react'
import { Anchor } from '@mantine/core'
import { useMst } from '@state'
import { showError } from '@components/Modals'
import { PopoverContext } from './CommentHistory'

import styles from './CommentHistory.module.scss'

export const UnresolveButton = ({
  commentId,
  scriptId,
}: {
  commentId: string
  scriptId: string
}) => {
  const { analytics, apiClient, choo, doDebug } = useMst()
  const { hidePopover } = React.useContext(PopoverContext)

  // detect if comment still exists in doc to allow unresolve
  const exists = document.querySelector(`#e-comment-${commentId}`) !== null
  if (!exists) return null

  const handleUnresolve = async () => {
    hidePopover()
    try {
      await doDebug()
      const res = await apiClient.unresolveComment({ commentId, scriptId })
      if (res.status === 'success') {
        choo.finishUnresolvingComment(commentId)
        analytics.track(analytics.EVENTS.SCRIPT_COMMENT_UNRESOLVED)
      }
    } catch {
      showError({ message: 'Unexpected error unresolving comment' })
    }
  }

  return (
    <Anchor size="sm" className={styles.comment_link} onClick={handleUnresolve}>
      Unresolve
    </Anchor>
  )
}
