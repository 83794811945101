import { EditorView } from 'prosemirror-view'
import {
  getVersion,
  sendableSteps,
  receiveTransaction,
} from 'prosemirror-collab'
import { Transaction } from 'prosemirror-state'
import { nanoid } from 'nanoid'
import debounce from 'lodash.debounce'
import throttle from 'lodash.throttle'
import { ScriptPayload } from '@util/ScriptoApiClient/types'
import { ScriptoChooApp } from '@choo-app'
import { ScriptoApiClient, delay } from '@util'
import { createLiveEditorState } from './createEditorState'
import { commentsKey } from './plugins/comments/index.js'
import { remoteCursorKey } from './plugins/remote-cursor/index.js'
import { SCROLL_MARGIN } from './constants.js'
import { validateConfirmedSteps, convertStepData } from './editor-helpers'
import { DatadogClient } from '@util/datadog'
import { handleClick } from './handleClick'
import {
  EDITOR_EVENTS,
  UserEventPayload,
  CursorUpdatePayload,
} from '@state/models/SocketManager/types'
import { launchScriptToast, dismissToast, TOAST_ID } from '@components/Toast'

const ddLog = DatadogClient.getInstance()

const safeJson = (value: unknown) => {
  try {
    const parsed = JSON.parse(JSON.stringify(value))
    return parsed
  } catch (e) {
    return 'bad json'
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const extractErrorInfo = (e: any) => {
  const code = typeof e?.code === 'number' ? e.code : undefined
  const message = typeof e?.message === 'string' ? e.message : 'Unknown error'
  const stack = e?.stack
  return {
    code,
    message,
    stack,
  }
}

const DEBOUNCE_OPTS = { leading: true, maxWait: 1000 }
const RECEIVE_TR_OPTS = { mapSelectionBackward: true }
const RECEIVE_TR_PHASE = 'receiveTransaction'

export class EditorManager {
  private state: ScriptoChooApp['state']
  private emit: ScriptoChooApp['emitter']['emit']
  private io: ScriptoChooApp['io']
  private apiClient: ScriptoApiClient
  private isProcessingSteps: boolean
  private cycleStartTime: number
  private cycleTrigger: string
  private disconnected: boolean
  private availableServerVersion = 0
  private readonly collabId = nanoid()

  view: EditorView | null

  constructor({
    state,
    emit,
    io,
  }: {
    state: ScriptoChooApp['state']
    emit: ScriptoChooApp['emitter']['emit']
    io: ScriptoChooApp['io']
  }) {
    this.state = state
    this.emit = emit
    this.io = io
    this.apiClient = this.state.mst.apiClient
    this.view = null
    // use this boolean as a guard against trying to push & pull steps at the same time
    this.isProcessingSteps = false
    // Tracking values about the recursive sync cycle -- used for logging
    this.cycleStartTime = 0
    this.cycleTrigger = ''
    // track disconnected status for reconnect
    // only true if previously connected, then connection was lost
    // back to false once session is restored
    this.disconnected = false
    // bound methods
    this.dispatch = this.dispatch.bind(this)

    // we no longer store or attempt to recover steps in localStorage
    // to ensure that writers dont encounter multiple errors when rebasing fails

    // differ wait to add jitter and prevent collision
    // this.sync = throttle(this.sync.bind(this), 100)
    this.sendCursorUpdate = debounce(
      this.sendCursorUpdate.bind(this),
      229,
      DEBOUNCE_OPTS
    )
    // activity analytics
    this.trackScriptActivity = throttle(
      this.trackScriptActivity.bind(this),
      10000
    )
  }
  // call this to initialize or re-initialize with a new script
  initialize() {
    // This is the highest version number we've heard of
    // for the script
    this.availableServerVersion = 0
  }

  get element() {
    return document.getElementById('editor')
  }

  get debugSync(): boolean {
    return this.state.mst.view.debugSync
  }

  get debugPullDelay(): number | undefined {
    return this.state.mst.currentScript?.syncStatus.settings.pullDelay
  }

  get debugPushDelay(): number | undefined {
    return this.state.mst.currentScript?.syncStatus.settings.pushDelay
  }

  get locallyConfirmedVersion() {
    try {
      return this.view ? getVersion(this.view.state) : 0
    } catch (e) {
      ddLog.warn('document version unavailable')
      return 0
    }
  }
  get hasLatestServerSteps() {
    return this.availableServerVersion === this.locallyConfirmedVersion
  }
  get hasLocalStepsToSend() {
    return this.view && !!sendableSteps(this.view.state)
  }
  get hasStepsToProcess() {
    return this.hasLocalStepsToSend || !this.hasLatestServerSteps
  }
  get shouldProcessSteps() {
    return (
      !this.disconnected &&
      this.view &&
      this.hasStepsToProcess &&
      !this.isProcessingSteps
    )
  }
  get scriptId() {
    if (this.state && this.state.editor && this.state.editor.script) {
      return this.state.editor.script.id
    }
    return null
  }
  // TODO: we can stop passing the raw scriptPayload and
  // use the mst currentScript model once we ensure all the
  // plugins can handle that in the config instead of the
  start(script: ScriptPayload) {
    const { user, currentScript } = this.state.mst
    if (!(this.element && currentScript)) {
      return
    }
    this.initialize()
    // clear element of all contents
    this.setView(null)
    const isEditable = !!currentScript.isEditable
    // create new state with provided script
    const state = createLiveEditorState({
      // provided by starting payload
      clientId: this.io.id ?? '',
      collabId: this.collabId,
      scriptId: script.id,
      script,
      user,
      // provided on class init
      appState: this.state,
      emit: this.emit,
      socket: this.io,
    })

    // create new editor view
    const view = new EditorView(this.element, {
      state,
      attributes: {
        class: `is-${this.state.editor.script.type}`,
      },
      editable: () => isEditable,
      scrollThreshold: SCROLL_MARGIN,
      scrollMargin: SCROLL_MARGIN,
      dispatchTransaction: this.dispatch,
      handleClick,
    })
    // attach view to DOM & focus
    this.setView(view)
    view.focus()
    this.onScriptVersionUpdated(
      {
        scriptId: script.id,
        version: script.version,
      },
      'start'
    )
  }
  dispatch(tr: Transaction, meta?: boolean) {
    if (!this.view) {
      return
    }
    const editorState = this.view.state.apply(tr)
    this.view.updateState(editorState)
    // if we're fully in sync, send a cursor update and emit to react app
    if (!meta && !this.hasStepsToProcess && tr && tr.steps.length === 0) {
      this.sendCursorUpdate()
    }
    // if we have steps on the tr, kick off the sync cycle
    if (tr && tr.steps.length > 0) {
      this.trackScriptActivity()
      this.processOutstandingSteps('dispatch')
    }
  }
  /**
   * This is a wrapper around getSteps and createSteps and ensures we are only involved
   * in one attempt to get in sync with the server at a time. The field member: this.isProcessingSteps
   * acts as a guard (replacing throttle, debounce and asyncLimit in editor v1)
   *
   * Source and depth are passed in for logging purposes
   */
  async processOutstandingSteps(trigger: string, depth = 0) {
    this.reportSyncStatus()
    // beginning and end of cycle are for logging/tracking how long it's taking us
    // to process outstanding steps
    const beginningOfCycle = depth === 0
    const endOfCycle = !this.shouldProcessSteps && !beginningOfCycle

    if (this.shouldProcessSteps) {
      this.isProcessingSteps = true
      if (beginningOfCycle) {
        this.cycleStartTime = new Date().valueOf()
        this.cycleTrigger = trigger
        ddLog.info('editor-sync start', {
          trigger,
          startTimeMs: this.cycleStartTime,
        })
      }
      try {
        await (this.hasLatestServerSteps ? this.pushSteps() : this.pullSteps())
      } finally {
        this.isProcessingSteps = false
      }
      // recurse to handle any new steps we created locally or
      // or learned about on the server while processing
      this.processOutstandingSteps('recursion', depth + 1)
    }

    if (endOfCycle) {
      this.emit('wallaby:liveScriptUpdated', this.scriptId)
      // we hit the end of our recursion and have nothing left to process, log success
      ddLog.info('editor-sync complete', {
        trigger: this.cycleTrigger,
        depth,
        duration: new Date().valueOf() - this.cycleStartTime,
      })
    }
  }

  // When we discover there's a script version available, we call this. We ONLY want to
  // use this to increase this.availableServerVersion
  onScriptVersionUpdated(
    {
      scriptId,
      version: advertisedVersion,
    }: { scriptId: string; version: number },
    trigger: string
  ) {
    if (scriptId !== this.scriptId) {
      return
    }
    // check if we already know there's a more recent version available.
    // this can happen due to a socket message coming in before a push steps completes
    if (advertisedVersion < this.availableServerVersion) {
      // we can probably yank this log message
      ddLog.info('editor-sync skip outdated', {
        advertisedVersion,
        availableServerVersion: this.availableServerVersion,
        localServerVersion: this.locallyConfirmedVersion,
      })
      return
    }

    // This case shouldn't happen in real life
    if (
      this.locallyConfirmedVersion &&
      advertisedVersion < this.locallyConfirmedVersion
    ) {
      ddLog.warn('editor-sync skip behind', {
        advertisedVersion,
        availableServerVersion: this.availableServerVersion,
        localServerVersion: this.locallyConfirmedVersion,
      })
      return
    }

    this.availableServerVersion = advertisedVersion
    this.processOutstandingSteps(trigger)
  }

  async pullSteps() {
    if (!this.scriptId) {
      return
    }

    let rawSteps
    if (this.debugPullDelay) {
      await delay(this.debugPullDelay)
    }
    try {
      rawSteps = await this.apiClient.getSteps({
        scriptId: this.scriptId,
        from: this.locallyConfirmedVersion,
      })
    } catch (err: unknown) {
      const { code, message, stack } = extractErrorInfo(err)
      ddLog.warn(
        'get steps failed',
        { scriptId: this.scriptId, code, message, stack, err },
        err
      )

      // If err.code is 0 or undefined we are offline
      if (!code || code === 0) {
        return launchScriptToast({
          type: 'warning',
          message: 'Unable to get latest changes from server',
        })
      } else {
        return launchScriptToast({
          type: 'warning',
          message: `Error ${code}: ${message}`,
        })
      }
    }

    const isValid = validateConfirmedSteps(
      rawSteps,
      this.locallyConfirmedVersion
    )
    if (isValid && this.view) {
      const { steps, collabIds } = convertStepData(rawSteps)
      let phase = ''
      try {
        phase = RECEIVE_TR_PHASE
        const tr = receiveTransaction(
          this.view.state,
          steps,
          collabIds,
          RECEIVE_TR_OPTS
        )
        phase = 'apply'
        const newState = this.view.state.apply(tr)
        phase = 'update'
        this.view.updateState(newState)
        const { currentScript } = this.state.mst
        currentScript?.syncStatus.reportGetSuccess()
      } catch (err: unknown) {
        const { message, stack } = extractErrorInfo(err)
        // const unsentStepInfo = this.getSendableSteps()
        const localSteps = steps.map((step) => step.toJSON())
        const remoteStepRange = safeJson([steps[0], steps[steps.length - 1]])
        ddLog.error(
          'apply steps failure summary',
          { phase, remoteStepRange },
          err
        )
        ddLog.error('apply steps failure', {
          localSteps: safeJson(localSteps),
          message: safeJson(message),
          stack: safeJson(stack),
          remoteStepRange,
          phase,
        })
        /* intermittently in very busy scripts we see an unrecoverable error
          from within the ProseMirror collab plugin when it is unable to
          rebase local unconfirmed steps on top of remote confirmed steps.

          we are operating under the assumption that this happens when local
          structural repagination steps are valid but not invertable.

          this causes an endless error loop in an otherwise fault tolerant flow.
          displaying a normal modal doesn't interrupt attempts to synchronize
          so (for now) we signal a fatal error and force a reload.

          this has no hope of eradicating the error, but we expect it to
          minimize the repurcussions for editors because their own local steps
          should be discarded when they reload the script with new steps.

          if the same editor encounters this error several times in succession
          we can investigate a more nuclear option to avoid saving unconfirmed
          steps in local storage entirely.
        */
        if (phase === RECEIVE_TR_PHASE) {
          ddLog.error('fatal error rebasing remote steps', {
            remoteStepRange,
          })
          window.alert(
            "We encountered an error we can't recover from. Reload to continue working."
          )
          window.location.reload()
        }
      }
    }
  }
  async pushSteps() {
    if (!(this.view && this.scriptId)) {
      return
    }
    const sendable = sendableSteps(this.view.state)
    if (!sendable) {
      return
    }

    const { version, steps } = sendable
    const scriptId = this.scriptId
    let result

    const stepDetails = {
      scriptId,
      clientId: this.collabId,
      version,
      steps,
    }
    const ddInfo = {
      scriptId,
      socketId: this.io.id ?? 'none',
      collabId: this.collabId,
      version,
      stepCount: steps.length,
    }
    try {
      if (this.debugPushDelay) {
        await delay(this.debugPushDelay)
      }
      result = await this.apiClient.createSteps(stepDetails)
    } catch (err: unknown) {
      const { code, message } = extractErrorInfo(err)
      // If err.code is 0 or undefined we are offline, don't spam user
      if (!code) {
        return
      }
      // this is bad territory, we got a failure from the server
      // and we're about to start spamming the server with retries
      // AND spamming the user with toast
      // TODO: introduce some kind of backoff
      ddLog.warn('create steps failed', ddInfo)
      return launchScriptToast({
        type: 'warning',
        message: `Error ${code}: ${message}`,
      })
    }

    // recheck view is still present after the async
    // operation
    if (!this.view) {
      return
    }

    // If the server response is success, the steps were confirmed by the authority,
    // and we can mark them as confirmed in prosemirror-collab
    if (result.success) {
      try {
        const tr = receiveTransaction(
          this.view.state,
          steps,
          new Array(steps.length).fill(this.collabId),
          RECEIVE_TR_OPTS
        )
        const newState = this.view.state.apply(tr)
        this.view.updateState(newState)
        const { currentScript } = this.state.mst
        currentScript?.syncStatus.reportPushSuccess()

        // sanity check- the returned version from the server should be
        // exactly the same as the confirmed version in prosemirror collab
        if (result.version !== this.locallyConfirmedVersion) {
          ddLog.error('Push steps logic bug', {
            result,
            locallyConfirmedVersion: this.locallyConfirmedVersion,
          })
        }
      } catch (err) {
        ddLog.error('Failure confirming own steps', {
          ...ddInfo,
          err,
        })
      }
      // this else block means the server rejected our push steps because we were behind,
      // so let's record the new version available
    } else {
      const { version: serverVersion } = result
      this.onScriptVersionUpdated({ scriptId, version: serverVersion }, 'push')
    }
  }

  // interop for old implementation
  onUserEvent(data: UserEventPayload) {
    // if a cursor has been removed, set meta and dispatch a cursor event
    if (data.removedCursor && this.view) {
      const { tr } = this.view.state
      tr.setMeta(remoteCursorKey, data)
      return this.dispatch(tr, true)
    }
    // if a user (that is not us) joins, send a cursor event from our current
    // state so they get a view of our cursor
    // TODO: revise this, should only show if user has a focused selection.
    // would be nice to remove remote cursor, disable click-to-jump, dim icon if user removes cursor from editor.
    if (data.newUser && data.newUser !== this.io.id) {
      this.sendCursorUpdate()
    }
  }
  /**
   * Send cursor event to the current script.
   * v1 edition of createCursorEvent.
   */
  sendCursorUpdate() {
    if (!(this.view && this.view.state)) {
      return
    }
    // only send cursor updates if we are in sync
    if (this.hasStepsToProcess) {
      ddLog.info('skipping cursor update')
      return
    }
    const { selection } = this.view.state
    const payload = {
      scriptId: this.scriptId,
      clientId: this.io.id,
      version: this.locallyConfirmedVersion,
      headPosition: selection.$head.pos,
      anchorPosition: selection.$anchor.pos,
    }
    this.emit(`io:${EDITOR_EVENTS.UPDATE_CURSOR}`, payload)
  }
  /**
   * Receive cursor update from the current script.
   * v1 edition of onCursorEvent.
   * @param {object} data - socket event data
   */
  onCursorUpdated(data: CursorUpdatePayload) {
    // TODO: be super smart and map forwards and backwards in time
    if (data.version !== this.locallyConfirmedVersion || !this.view) {
      return
    }
    const { tr } = this.view.state
    tr.setMeta(remoteCursorKey, data)
    // IMPORTANT pass in true here so we don't send OUR cursor updates when we receive cursor updates, generating a feedback loop!
    // TODO: this feels like a flaky way of guarding against cursor spam-- switch to saving a ownCursorDirty flag or something similar
    // and using that to only send cursor updates when WE have changed our cursor
    this.dispatch(tr, true)
  }

  // comments
  /**
   * commentEvent socket event handler.
   * must be bound to Editor instance.
   * @param {object} data - socket event data
   */
  onCommentEvent(data: unknown) {
    if (this.view) {
      const { tr } = this.view.state
      tr.setMeta(commentsKey, data)
      this.dispatch(tr, true)
    }
  }
  // recovery methods
  onDisconnect() {
    this.disconnected = true
    // ignore disconnects if no editor view
    if (!this.view) {
      return
    }
    this.view.setProps({
      editable: () => false,
    })
    this.emit('editor:setScript', { users: [] })
    this.dispatch(this.view.state.tr.setMeta(remoteCursorKey, { reset: true }))
  }
  onReconnect() {
    // ignore socket reconnect if there's no editor involved
    if (!(this.view && this.scriptId)) {
      return
    }
    if (!this.disconnected) {
      return ddLog.warn('called onReconnect when not disconnected')
    }
    const { scriptId } = this
    const socketId = this.io.id
    const version = this.locallyConfirmedVersion

    const logInfo = { version, scriptId, socketId }
    if (!socketId) {
      const message = 'missing clientID on reconnect'
      ddLog.error(message, logInfo)
      return
    }

    if (!Number.isInteger(version)) {
      const message = 'invalid version on reconnect'
      ddLog.error(message, logInfo)
      return
    }

    dismissToast({ id: TOAST_ID.SCRIPT_DISCONNECT })
    this.emit('io:JOIN_SCRIPT', { scriptId })
    this.view.setProps({
      editable: () => true,
    })
    this.disconnected = false
    this.onScriptVersionUpdated({ scriptId, version }, 'reconnect')
  }
  // matching the editor-v1 signature here-- editor-v1 doesn't use the
  // scriptId. If we switch
  setServerVersion(version: number, scriptId: string) {
    this.onScriptVersionUpdated(
      {
        scriptId,
        version,
      },
      'join'
    )
  }
  getSendableSteps() {
    if (!this || !this.view || !this.view.state) {
      return null
    }
    return sendableSteps(this.view.state)
  }
  trackScriptActivity() {
    ddLog.info('script activity')
    this.emit('editor:trackScriptActivity')
  }
  // sets current view and cleans up any previous view state
  setView(prosemirrorView: EditorView | null) {
    // tear down the current prosemirror view if it exists
    this.view?.destroy()
    // if we're not creating a new view, clean up any
    // errant html we've added
    if (!prosemirrorView && this.element) {
      this.element.innerHTML = ''
    }
    this.view = prosemirrorView
    this.state.mst.currentScript?.updateEditorViewObservables()
  }
  // destroys editor view
  destroyEditorView() {
    this.setView(null)
  }

  setEditable(value: boolean) {
    if (this.view && this.view.editable !== value) {
      this.view.setProps({ editable: () => value })
    }
  }

  reportSyncStatus() {
    const { currentScript } = this.state.mst
    if (currentScript) {
      const getStepsRequired = !this.hasLatestServerSteps
      const sendStepsRequired = !!this.hasLocalStepsToSend
      currentScript.syncStatus.update({
        getStepsRequired,
        sendStepsRequired,
      })
    } else {
      ddLog.error('Editor manager reporting status without LoadedScript')
    }
  }
}
