import { Space, Text } from '@mantine/core'
import { PrintPreviewWrapper } from '@layouts/Script/PrintPreviewWrapper'
import styles from './ArchivedScript.module.scss'
import { findLinksAndSplit } from '@choo-app/lib/editor/prose-utils'
import { OutsideAnchor } from '@components/OutsideAnchor'
import { ArchivedScriptHeader } from './ArchivedScriptHeader'
import { ClassicScript } from './types'

type BracketColor = 'red'

const PlainTextLine = ({
  text,
  inline,
  color,
}: {
  color?: BracketColor
  text: string
  inline?: boolean
}) => {
  const component = inline ? 'span' : 'div'

  return (
    <Text c={color} component={component} mb={10}>
      {text}
    </Text>
  )
}

const LinkedLineSegment = ({
  url,
  text,
  color,
}: {
  url?: string
  text: string
  color?: BracketColor
}) => {
  if (!url) {
    return <PlainTextLine inline text={text} color={color} />
  }

  return (
    <OutsideAnchor
      className={styles.archivedScript_anchor}
      href={url}
      c="#0000FF"
    >
      {text}
    </OutsideAnchor>
  )
}

const Line = ({ line }: { line: string }) => {
  const splitText = findLinksAndSplit(line)
  const isLinked = splitText.some((t) => t.url)
  const color = line.trim().startsWith('[') ? 'red' : undefined

  return isLinked ? (
    <div className={styles.archivedScript_linkedLine}>
      {splitText.map(({ url, text }, index) => (
        <LinkedLineSegment url={url} text={text} color={color} key={index} />
      ))}
    </div>
  ) : (
    <PlainTextLine color={color} text={splitText[0].text} />
  )
}

export const ArchivedScript = ({ script }: { script: ClassicScript }) => {
  const lines = script.text.split('\n')
  return (
    <div className={styles.archivedScript}>
      <ArchivedScriptHeader script={script} />
      <PrintPreviewWrapper>
        <div className={styles.archivedScript_content}>
          <Space h={20} />
          {lines.map((l, idx) => (
            <Line key={idx} line={l} />
          ))}
        </div>
      </PrintPreviewWrapper>
    </div>
  )
}
