import { Plugin, PluginKey } from 'prosemirror-state'
import { IRoot } from '@state/types'

export const recordSelection = ({
  appState,
}: {
  appState: { mst: IRoot }
}): Plugin => {
  return new Plugin({
    key: new PluginKey('mstMeta'),
    view() {
      return {
        update() {
          // [sc-1458]
          appState.mst.currentScript?.updateEditorViewObservables()
        },
      }
    },
  })
}
