import { ActionIcon, Anchor, Menu, Tooltip } from '@mantine/core'
import { FancyMenuItem } from '@components/FancyMenuItem'
import { useMst } from '@state'
import { FaIcon } from '@components/FaIcon'
import { showShortcutsModal } from '@components/Modals'
import env from '../../../choo-app/lib/env.js'

const { browser } = env

type HelpMenuItemType =
  | 'intercom'
  | 'help'
  | 'whatsnew'
  | 'shortcuts'
  | 'divider'

type HelpMenuItem = {
  key: HelpMenuItemType
  label: string
  icon: string
  href?: string
}

const itemMap: Record<HelpMenuItemType, HelpMenuItem> = {
  intercom: {
    key: 'intercom',
    label: 'Live Support',
    icon: 'fa-commenting',
  },
  help: {
    key: 'help',
    label: 'Help Center',
    icon: 'fa-info-circle',
    href: 'https://help.scripto.live/',
  },
  whatsnew: {
    key: 'whatsnew',
    label: "What's New",
    icon: 'fa-gift',
    href: 'https://scripto.live/whats-new',
  },
  shortcuts: {
    key: 'shortcuts',
    label: 'Keyboard Shortcuts',
    icon: 'fa-keyboard-o',
  },
  divider: {
    key: 'divider',
    label: '',
    icon: '',
  },
}

const ALL_ITEMS = [
  itemMap.intercom,
  itemMap.help,
  itemMap.whatsnew,
  itemMap.divider,
  itemMap.shortcuts,
]
const NON_EDITOR_ITEMS = [itemMap.intercom, itemMap.help, itemMap.whatsnew]

export function HelpMenu({ showShortcuts }: { showShortcuts: boolean }) {
  const { currentScript } = useMst()
  const orderedItems = showShortcuts ? ALL_ITEMS : NON_EDITOR_ITEMS

  const handleClick = (key: HelpMenuItemType) => {
    if (key === 'intercom') {
      const intercom = window.Intercom
      if (typeof intercom === 'function') {
        intercom('show')
      }
    }
  }

  return (
    <Menu shadow="xl" withinPortal>
      <Menu.Target>
        <Tooltip
          label="Help, What's New, Keyboard Shortcuts"
          position="right"
          withinPortal
        >
          <ActionIcon color="dark" variant="transparent">
            <FaIcon icon="fa-question-circle" />
          </ActionIcon>
        </Tooltip>
      </Menu.Target>
      <Menu.Dropdown>
        {orderedItems.map((item) => {
          if (item.key === 'divider') return <Menu.Divider key={item.key} />
          if (item.key === 'shortcuts') {
            return (
              <FancyMenuItem
                onClick={() =>
                  showShortcutsModal({
                    scriptType: currentScript?.type,
                    isSafari: browser.safari,
                  })
                }
                key={item.key}
                iconClass={`fa ${item.icon}`}
                title={item.label}
              ></FancyMenuItem>
            )
          }

          return item.href ? (
            <Menu.Item key={item.key}>
              <Anchor
                target="_blank"
                rel="noreferrer"
                href={item.href}
                className="is-plain-link"
              >
                <span className="c-dropdown__icon">
                  <i className={`fa fa-fw ${item.icon}`} />
                </span>
                &nbsp;&nbsp;{item.label}
              </Anchor>
            </Menu.Item>
          ) : (
            <FancyMenuItem
              key={item.key}
              iconClass={`fa ${item.icon}`}
              onClick={() => handleClick(item.key)}
              title={item.label}
            ></FancyMenuItem>
          )
        })}
      </Menu.Dropdown>
    </Menu>
  )
}
