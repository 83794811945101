import { useState } from 'react'
import { Combobox, useCombobox, InputBase, Group, Text } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { useMst } from '@state'
import { IOrg } from '@state/types'
import { getAvatarUrl } from '@util'
import { Avatar } from '@components/Avatar'
import { showError } from '@components/Modals'
import { Spinner } from '@components/Spinner'
import { filterOnLabelDescription, SelectDataItem } from '../util'

import styles from './PermissionUserSelect.module.scss'

export const PermissionUserSelect = observer(function PermissionUserSelect({
  permissionCode,
  org,
}: {
  permissionCode: string
  org: IOrg
}) {
  const { environment, doDebug } = useMst()
  const data: SelectDataItem[] = org.alphabetizedMembers.map((user) => {
    return {
      label: user.name,
      value: user.id,
      description: user.email,
      image: getAvatarUrl(user.avatar, environment.config),
      disabled: user.permissions.includes(permissionCode),
    }
  })

  const [loading, setLoading] = useState(false)

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  })

  const grantPermission = async (userId: string | null) => {
    setLoading(true)
    try {
      await doDebug()
      if (userId) await org.grantMemberPermission({ userId, permissionCode })
    } catch (e) {
      showError({
        message: 'Failed to add user permission',
      })
    }
    setLoading(false)
  }

  const [search, setSearch] = useState('')

  const shouldFilterOptions = data.every((item) => item.label !== search)
  const filteredOptions = shouldFilterOptions
    ? filterOnLabelDescription(search, data)
    : data

  const options = filteredOptions.map((item) => (
    <Combobox.Option
      value={item.value}
      key={item.value}
      disabled={item.disabled}
    >
      <Group wrap="nowrap">
        <Avatar avatarUrl={item.image} />
        <Text>
          {item.label}
          <br />
          <Text span c="dimmed" size="13">
            {item.description}
          </Text>
        </Text>
      </Group>
    </Combobox.Option>
  ))

  return (
    <Group justify="flex-end">
      <Combobox
        classNames={{ dropdown: styles.userselect_dropdown }}
        store={combobox}
        onOptionSubmit={async (val) => {
          await grantPermission(val || '')
          // clear the input immediately regardless of success
          setSearch('')
          combobox.closeDropdown()
        }}
      >
        <Combobox.Target>
          <InputBase
            className={styles.userselect_input}
            rightSection={
              <Combobox.Chevron onClick={() => combobox.focusTarget()} />
            }
            value={search}
            onClick={() => combobox.openDropdown()}
            onFocus={() => combobox.openDropdown()}
            onBlur={() => {
              combobox.closeDropdown()
            }}
            onChange={(event) => {
              combobox.openDropdown()
              combobox.updateSelectedOptionIndex()
              setSearch(event.currentTarget.value)
            }}
            placeholder="Add Members"
          />
        </Combobox.Target>
        <Combobox.Dropdown>
          {loading && <Spinner delayMs={300} />}
          <Combobox.Options>
            {options.length > 0 ? (
              options
            ) : (
              <Combobox.Empty>Nobody here</Combobox.Empty>
            )}
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    </Group>
  )
})
