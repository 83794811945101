import React from 'react'
import { observer } from 'mobx-react-lite'
import { Popover } from '@mantine/core'
import { useMst } from '@state'
import { ButtonMode, ILoadedScript } from '@state/types'
import { noop, pluralize } from '@util'
import { Toolbar } from '@components/Toolbar'
import { CommentHeader } from './Header'
import { CommentList } from './List'
import { SlackToggle } from './SlackToggle'
import { CommentFilter } from './helpers'

import styles from './CommentHistory.module.scss'

export const PopoverContext = React.createContext({ hidePopover: noop })

/*
the way we fetch/manage comments in the app is a bit disjointed.

1. the ProseMirror comment plugin is responsible for fetching raw counts for each block once when the document is loaded both to decorate the document itself and supply an aggregate total for the button in this component.

2. the PM plugin also listens for socket messages to keep the document itself in sync and increment/decrement the toolbar button count.

3. this component independently fetches comment history each time the feed is toggled open and it uses the payload only to hydrate the actual list of comments within the menu.
*/
export const CommentHistoryPopover = observer(function CommentHistoryPopover({
  script,
  mode = 'normal',
}: {
  script: ILoadedScript
  mode?: ButtonMode
}) {
  const [opened, setOpened] = React.useState(false)
  const [filter, setFilter] = React.useState<CommentFilter>('open')
  const { currentOrg, view, currentScript } = useMst()
  const { totalComments: count } = script

  // reset each time popover is hidden
  React.useEffect(() => {
    if (!opened) setFilter('open')
  }, [opened])

  if (count === undefined) return <></>

  // on small screens we condense the label instead of hiding it entirely
  const label =
    mode === 'normal'
      ? `${count} ${pluralize(count, ' Comment')}`
      : String(count)

  return (
    <Popover
      width={350}
      position="bottom-end"
      opened={opened}
      onChange={setOpened}
    >
      <Popover.Target>
        <Toolbar.Button
          dropdown
          icon="fa-comment"
          label={label}
          onClick={() => {
            setOpened((o) => !o)
            currentScript?.observableEditor?.focusEditor()
          }}
        />
      </Popover.Target>
      <Popover.Dropdown className={styles.commentHistory_dropdown}>
        <PopoverContext.Provider
          value={{ hidePopover: () => setOpened(false) }}
        >
          <CommentHeader setFilter={setFilter} filter={filter} />
          <CommentList
            containerHeight={view.dimensions.scriptScroller.height}
            filter={filter}
            scriptId={script.id}
          />
          <SlackToggle isPaid={!currentOrg?.isUnpaid} />
        </PopoverContext.Provider>
      </Popover.Dropdown>
    </Popover>
  )
})
