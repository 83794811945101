import { types } from 'mobx-state-tree'
import { BaseModel } from '@state/models/BaseModel'
import { differenceInSeconds } from 'date-fns'
import { isUpdateAvailable } from '@util/checkVersion'
import { showAsyncConfirmModal } from '@components/Modals'

const launchTime = new Date()

// some older browsers don't have UserActivation, so just
// pretend these users have been active
const getActivation = (): UserActivation => {
  return (
    navigator.userActivation ?? {
      isActive: true,
      hasBeenActive: true,
    }
  )
}

const offerUpdate = () =>
  showAsyncConfirmModal({
    title: 'Update available',
    children:
      "We've updated Scripto. Please refresh your browser to get the latest and greatest.",
    errorMessage: 'Error reloading application',
    confirmLabel: 'Refresh',
    cancelLabel: 'Not Now',
    onConfirm: () => Promise.resolve(window.location.reload()),
  })

export const AppStatus = BaseModel.named('AppStatus')
  .props({
    // universal timer for the app. We update this once per second
    now: types.optional(types.Date, launchTime),
    visibility: types.optional(
      types.enumeration<DocumentVisibilityState>(['hidden', 'visible']),
      document.visibilityState
    ),
    lastVisibilityChange: types.optional(types.Date, launchTime),
    lastUserInteraction: types.optional(types.Date, launchTime),
  })
  .views((self) => ({
    secondsSince(value: Date) {
      return differenceInSeconds(self.now, value)
    },
    get launchTime() {
      return launchTime
    },
  }))
  .actions((self) => ({
    updateNow() {
      self.now = new Date()
      self.environment.datadog.setAppStatus({
        inBackground: self.visibility === 'hidden',
        backgroundAgeSeconds: self.secondsSince(self.lastVisibilityChange),
        activityAgeSeconds: self.secondsSince(self.lastUserInteraction),
      })
    },
    handleUserActivity() {
      self.lastUserInteraction = new Date()
    },
    updateVisibility() {
      const { visibilityState } = document
      if (visibilityState !== self.visibility) {
        self.visibility = visibilityState
        self.lastVisibilityChange = new Date()
        self.environment.datadog.setVisibility(visibilityState)
      }
    },
    async checkForAvailableUpdate() {
      const updateAvailable = await isUpdateAvailable()
      if (updateAvailable && self.visibility === 'visible') {
        offerUpdate
      }
    },
  }))
  .actions((self) => ({
    afterAttach: () => {
      setInterval(() => {
        self.updateNow()
        if (getActivation().isActive) {
          self.handleUserActivity()
        }
      }, 1000)

      document.addEventListener('visibilitychange', () => {
        self.updateVisibility()
        self.checkForAvailableUpdate()
      })

      // if the user is scrolling with a trackpad or mouse wheel,
      // the navigator.userActivation doesn't update, so this catches that
      // case.
      document.addEventListener(
        'scrollend', // 'scroll' fires continuously so we use 'scrollend' instead
        () => self.handleUserActivity(),
        true
      )
      self.updateVisibility()
    },
  }))
