import { observer } from 'mobx-react-lite'
import { useMst } from '@state'
import { Toolbar } from '@components/Toolbar'
import { ReadonlyScriptBanner } from '@components/ReadOnlyBanner'
import { CreateSnapshotButton } from './buttons/CreateSnapshot'
import { ExcludeFromTimingButton } from './buttons/ExcludeFromTiming'
import { FormattingOptions } from './Formatting'
import { ElementMenu } from './ElementMenu'
import { Collaborators } from './Collaborators'
import { CommentHistoryPopover } from './CommentHistory'
import { ExtrasMenu } from './ExtrasMenu/ExtrasMenu'
import { TimingPopover } from './TimingPopover'
import { ToolbarItem, getItemsToShrink } from './responsiveHelpers'
import { useElementDimensions } from '@hooks'
import { ButtonMode } from '@state/types'
import { InkEditorToolbar } from './InkEditorToolbar'

import styles from './EditorToolbar.module.scss'

export const EditorToolbar = observer(function EditorToolbar() {
  const { currentScript, currentOrg, socketManager } = useMst()

  const { ref, dimensions } = useElementDimensions()

  if (!currentScript || !currentOrg) return null

  const { isEditable, isScreenplay, hasRemoteUsers, users } = currentScript

  if (currentScript.isInk) {
    return <InkEditorToolbar inkScript={currentScript} />
  }

  const itemsToRender: ToolbarItem[] = [
    'EXTRAS_MENU',
    'COMMENT_MENU',
    'SNAPSHOT_BUTTON',
  ]

  if (!isScreenplay) {
    itemsToRender.push('TIMING_POPOVER_BUTTON')
    if (isEditable) {
      itemsToRender.push('EXCLUDE_FROM_TIMING_BUTTON')
    }
  }
  if (isEditable) {
    itemsToRender.push('FORMATTING_BUTTONS')
    itemsToRender.push('ELEMENT_MENU')
  }
  if (hasRemoteUsers) {
    itemsToRender.push('COLLABORATORS')
  }

  const { mini, micro } = getItemsToShrink(itemsToRender, dimensions.width)
  const getModeFor = (item: ToolbarItem): ButtonMode => {
    return micro.includes(item)
      ? 'micro'
      : mini.includes(item)
      ? 'mini'
      : 'normal'
  }
  const { observableEditor } = currentScript

  return (
    <div className={styles.toolbar} ref={ref}>
      <Toolbar>
        <Toolbar.Section position="left">
          <Toolbar.ButtonGroup spacing={4}>
            {itemsToRender.includes('ELEMENT_MENU') && observableEditor && (
              <ElementMenu
                script={currentScript}
                observableEditor={observableEditor}
                mode={getModeFor('ELEMENT_MENU')}
              />
            )}
            {itemsToRender.includes('TIMING_POPOVER_BUTTON') && (
              <>
                <TimingPopover
                  script={currentScript}
                  mode={getModeFor('TIMING_POPOVER_BUTTON')}
                />
                {itemsToRender.includes('EXCLUDE_FROM_TIMING_BUTTON') &&
                  observableEditor && (
                    <ExcludeFromTimingButton
                      observableEditor={observableEditor}
                    />
                  )}
              </>
            )}
          </Toolbar.ButtonGroup>
          {itemsToRender.includes('FORMATTING_BUTTONS') && (
            <FormattingOptions mode={getModeFor('FORMATTING_BUTTONS')} />
          )}
        </Toolbar.Section>
        <Toolbar.Section position="right">
          {itemsToRender.includes('COLLABORATORS') && (
            <Collaborators
              users={users}
              clientId={socketManager.socketId}
              mode={getModeFor('COLLABORATORS')}
            />
          )}
          <CommentHistoryPopover
            script={currentScript}
            mode={getModeFor('COMMENT_MENU')}
          />
          <CreateSnapshotButton
            script={currentScript}
            mode={getModeFor('SNAPSHOT_BUTTON')}
          />
          <ExtrasMenu />
        </Toolbar.Section>
      </Toolbar>
      <ReadonlyScriptBanner script={currentScript} />
    </div>
  )
})
