import Nanocomponent from 'nanocomponent'
import html from 'nanohtml'
import _get from 'lodash.get'
import { EDITOR_RELOAD_CLICKED } from '@util/mixpanel/eventNames'
const types = {
  NONE: 'none',
  ERROR: 'error',
  MESSAGE: 'message',
  RELOAD: 'reload',
}
const CLEAR_MESSAGE_INTERVAL = 5000
/**
 * deprecated
 * TODO: replace with notifications
 * origin:
 */
class ErrorMessageView extends Nanocomponent {
  constructor() {
    super()
    this.timer = null
    this.types = types
    this.typeNames = Object.keys(types)
    this.message = ''
    this.messageType = this.types.NONE
    this.timeout = CLEAR_MESSAGE_INTERVAL
    this.closeMessage = this.closeMessage.bind(this)
    this.reload = this.reload.bind(this)
    this.state = {}
    this.emit = () => {
      // noop
    }
  }
  update() {
    if (this.timer) {
      window.clearTimeout(this.timer)
    }
    return true
  }
  get reloadButton() {
    return html`<button onclick=${this.reload}>refresh browser</button>`
  }
  get closeColor() {
    if (this.messageType === this.types.MESSAGE) {
      return 'blue'
    }
    return 'red'
  }
  get noteType() {
    if (this.messageType === this.types.MESSAGE) {
      return 'neutral'
    }
    return 'error'
  }
  handleEvent() {
    this.render()
  }
  closeMessage(evt) {
    evt.preventDefault()
    this.message = ''
    this.messageType = this.types.NONE
    this.render()
  }
  reload(evt) {
    evt.preventDefault()
    const scriptId = _get(this.state, 'mst.currentScript.id', '')
    const reload = () => window.location.reload()
    const timer = setTimeout(reload, 300)
    this.emit('analytics:track', {
      name: EDITOR_RELOAD_CLICKED,
      opts: {
        scriptId,
      },
      callback: () => {
        clearTimeout(timer)
        reload()
      },
    })
  }
  createElement(message, messageType, state, emit) {
    if (
      typeof messageType === 'string' &&
      !this.typeNames.includes(messageType.toUpperCase())
    ) {
      throw new Error(
        `${messageType} is not one of ${this.typeNames.join(', ')}`
      )
    }
    this.message = message
    this.messageType = messageType
    this.state = state
    this.emit = emit
    if (
      typeof this.messageType !== 'string' ||
      this.messageType === this.types.NONE
    ) {
      return html`<div style="display: none"></div>`
    }
    window.removeEventListener('online', this)
    // if (this.messageType === this.types.RELOAD && typeof navigator !== 'undefined' && !navigator.onLine) {
    //   window.addEventListener('online', this)
    // } else
    if (this.messageType !== this.types.RELOAD) {
      this.timer = setTimeout(() => {
        this.message = ''
        this.messageType = this.types.NONE
        this.timer = null
        this.render()
      }, this.timeout)
    }
    setTimeout(() => window.scrollTo(0, 0), 1000)
    const error = html`<div class="row">
      <div
        class="note ${this.noteType}-note closeable-note animated fadeIn"
        onclick=${this.closeMessage}
      >
        <span>${this.message}</span>
        <span
          class="icon muted-${this.closeColor}-text close-note-icon"
          onclick=${this.closeMessage}
        >
          <i class="fa fa-close"></i>
        </span>
        ${this.messageType === this.types.RELOAD ? this.reloadButton : ''}
      </div>
    </div>`
    return error
  }
}
export default ErrorMessageView
