import React from 'react'
import { createRoot } from 'react-dom/client'
import axiosStatic from 'axios'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { ModuleRegistry } from '@ag-grid-community/core'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { ClipboardModule } from '@ag-grid-enterprise/clipboard'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { LicenseManager } from '@ag-grid-enterprise/core'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import NiceModal from '@ebay/nice-modal-react'
import { MstProvider, createRootStore } from '@state'
import { getConfigFromWindow } from '@util'
import { ThemeProvider } from './theme'
import { MainRouter } from './routes'
import reportWebVitals from './reportWebVitals'
import { LocalPersistence } from '@util/LocalPersistence'

import 'normalize.css/normalize.css'
import '@mantine/core/styles.layer.css'
import '@mantine/dates/styles.layer.css'
import './wombat-scss/showrunner.scss'

LicenseManager.setLicenseKey(
  'Using_this_AG_Grid_Enterprise_key_( AG-043470 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Scripto Enterprises Inc )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Scripto )_only_for_( 2 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_( Scripto )_need_to_be_licensed___( Scripto )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 4 August 2024 )____[v2]_MTcyMjcyNjAwMDAwMA==59e689963caca0694b3d35c9e69da9e1'
)

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  ClipboardModule,
  MenuModule,
])

const config = Object.freeze(getConfigFromWindow())

const axios = axiosStatic.create({
  // enables cookie auth
  withCredentials: true,
  baseURL: config.API_URL,
  headers: {
    'content-type': 'application/json',
  },
})

const localPersistence = new LocalPersistence()
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
})

const store = createRootStore({
  snapshot: {},
  axios,
  config,
  localPersistence,
})
store.bootstrap().catch(() => store.setServerDown(true))
window.addEventListener('storage', store.handleStorageEvent)

if (config.NODE_ENV === 'development') {
  window.mst = store
}

const container = document.getElementById('root')
if (container) {
  const root = createRoot(container)

  root.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <MstProvider value={store}>
          <ThemeProvider>
            <NiceModal.Provider>
              <DndProvider backend={HTML5Backend}>
                <MainRouter />
              </DndProvider>
            </NiceModal.Provider>
          </ThemeProvider>
        </MstProvider>
      </QueryClientProvider>
    </React.StrictMode>
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
