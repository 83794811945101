import { Group } from '@mantine/core'
import { NavAnchor, NavAnchorHref } from '@components/NavAnchor'
import cn from 'classnames'
import { useMst } from '@state'
import { ROUTE_PATTERNS } from '@util'
import styles from './LoginSignupButtons.module.scss'

type LoginButtonType = 'login' | 'signup' | 'logout'

const buttonLabels: Record<LoginButtonType, string> = {
  login: 'SIGN IN',
  signup: 'SIGN UP',
  logout: 'SIGN OUT',
}

const buttonHrefs: Record<LoginButtonType, NavAnchorHref> = {
  login: ROUTE_PATTERNS.login,
  signup: ROUTE_PATTERNS.createAccount,
  logout: ROUTE_PATTERNS.logout,
}

const LoginSignupButton = ({
  type,
  active,
}: {
  type: LoginButtonType
  active?: boolean
}) => {
  const { location } = useMst()
  const label = buttonLabels[type]

  // If we've got a query string, we want to preserve it if the user toggles between
  // login and create-account. This preserves ?invite= and ?returnTo=
  const href = location.pathWithCurrentSearch(buttonHrefs[type])
  const classes = cn(styles.loginSignupButton, {
    [styles.active]: active,
  })
  return (
    <NavAnchor href={href} tabIndex={0} className={classes}>
      {label}
    </NavAnchor>
  )
}

const ReturnToDashButton = ({ label }: { label: string }) => (
  <NavAnchor
    href={ROUTE_PATTERNS.root}
    tabIndex={0}
    className={cn(styles.loginSignupButton, styles.active)}
  >
    {label.toUpperCase()}
  </NavAnchor>
)

export const LoginSignupButtons = ({
  activeButton,
  includedButtons = ['login', 'signup'],
  homeButtonLabel,
}: {
  activeButton?: LoginButtonType
  includedButtons?: LoginButtonType[]
  homeButtonLabel?: string
}) => {
  return (
    <Group gap="sm" className={styles.loginSignupButtons}>
      {homeButtonLabel && <ReturnToDashButton label={homeButtonLabel} />}
      {includedButtons.map((btnType) => (
        <LoginSignupButton
          key={btnType}
          type={btnType}
          active={activeButton === btnType}
        />
      ))}
    </Group>
  )
}
