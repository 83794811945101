// Invisible input that we click programmatically
// to launch the native FilePicker.
import React from 'react'
import { useResourceCreation } from '@hooks/useResourceCreation'
import { IFolder } from '@state/types'
import { showError } from '@components/Modals'
import { Spinner } from '@components/Spinner'

// this is what the ref for InvisibleFdxPicker returns
export type InvisibleFdxPickerHandle = {
  launchPicker: () => void
}

export const InvisibleFdxPicker = React.forwardRef<
  InvisibleFdxPickerHandle,
  { folder: IFolder }
>(({ folder }, forwardedRef) => {
  const { importScript, isCreating } = useResourceCreation('import')
  const inputRef = React.useRef<HTMLInputElement>(null)
  const launchPicker = () => {
    inputRef.current?.click()
  }

  React.useImperativeHandle(forwardedRef, () => ({
    launchPicker,
  }))

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = async (
    e
  ) => {
    const file = e.target.files?.[0]
    if (file) {
      const extension = file.name
        .substring(file.name.lastIndexOf('.') + 1)
        .toLowerCase()

      if (extension !== 'fdx') {
        showError({
          message: (
            <div className="l-box l-box--column">
              Only Final Draft files (.fdx) can be imported.
              <a
                href="https://help.scripto.live/en/articles/2844990-creating-or-importing-a-script#h_085dff6bb1"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more.
              </a>
            </div>
          ),
        })
        return
      }

      // not awaiting the result, useResourceCretation handles
      // showing error messages
      await importScript({
        file,
        folder,
      })

      // reset the input's value so we detect the next change
      if (inputRef.current) {
        inputRef.current.value = ''
      }
    }
  }

  return (
    <>
      {isCreating && <Spinner fullScreen delayMs={300} />}
      <input
        ref={inputRef}
        type="file"
        style={{ display: 'none' }}
        onChange={handleChange}
        accept=".fdx"
      />
    </>
  )
})

InvisibleFdxPicker.displayName = 'InvisibleFdxPicker'
