/**
 * create initial state for comments view
 * @param {EditorView} editorView - current PM editorView instance
 * @param {string} scriptId - current script ID
 * @param {object} user - current user
 */
function createState(editorView, scriptId, user, appState) {
  return {
    appState,
    // app state
    editorView,
    scriptId,
    user,
    // container state
    commentIds: null,
    comments: [],
    top: null,
    isEditing: null,
    isAdding: null,
    isSubmitting: false,
    // overall comment counts
    commentCounts: null,
    totalComments: 0,
    // TODO: consolidate the overlapping comment state?
  }
}
export { createState }
export default {
  createState,
}
