import React from 'react'
import { NodeTypeMap } from '@showrunner/codex'
import { colors } from '@theme'

const { BRACKET, DIALOGUE, SCENE_HEADING, SLUG } = NodeTypeMap

// these are element types that can be nav links
export const NAV_LINK_VALUES = [
  'new_act',
  'end_of_act',
  'sceneHeading',
  'slug',
  'bracket',
] as const

export const SCREENPLAY_NUMBERABLE_KEYS = [SCENE_HEADING, DIALOGUE]
export const STUDIO_NUMBERABLE_KEYS = [SLUG, DIALOGUE, BRACKET]

export type NavLinkElement = typeof NAV_LINK_VALUES[number]
export type NavLinkData = {
  id: string
  text: string
  isBlank: boolean
  pos: number
  nodeLength: number
  elementNumber?: string | null
  type: NavLinkElement
}

export function isNavLinkElement(value: string): value is NavLinkElement {
  return NAV_LINK_VALUES.includes(value as NavLinkElement)
}

export const SHARE_SCRIPT_WARNING_MESSAGE =
  "Once you share your script, you won't be able to make it private again."

export const GRAB_HANDLE_WIDTH = 5
// width of the button panel + side-strip
const SIDESTRIP_WIDTH = 49
export const CLOSED_SIDEBAR_WIDTH = SIDESTRIP_WIDTH + GRAB_HANDLE_WIDTH
// if you make it really small, we just collapse it
export const MIN_OPEN_SIDEBAR_WIDTH = 125 + CLOSED_SIDEBAR_WIDTH
export const DEFAULT_SIDEBAR_WIDTH = 280
export const TOOLBAR_HEIGHT = 40

// yet another regex to find urls. this one doesnt care about end of line. ref: https://stackoverflow.com/a/29288898/3019940
export const URLS_ANYWHERE =
  /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/gim

export const PDF_HEADER_DATEMASK = "M/d/yyyy 'at' h:mmaaa"

// A few constants are needed in both css and javascript. This
// generates them as css vars and they're added to the dom with
// the theme
export const globalStyleVars: React.CSSProperties = {
  '--grab-handle-size': `${GRAB_HANDLE_WIDTH}px`,
  '--sidestrip-width': `${SIDESTRIP_WIDTH}px`,
  '--toolbar-height': `${TOOLBAR_HEIGHT}px`,
}

export const successColor = colors.green[9]
