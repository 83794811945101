import React from 'react'
import { SnapshotViewerBase } from './SnapshotViewer'
import { SnapshotPage } from '../SnapshotPage'
import { createComparisonEditorState } from '@util'
import { useMst } from '@state'
import { showError } from '@components/Modals'
import { getBlobAndPrint, getBlobAndSave } from '@util/printing'
import { buildPrintableRevisionHtml } from '@util/princePrinting'
import { useSnapshotData, useScriptData } from '../useSnapshotQueries'

export const CompareSnapshotPage = ({
  params,
}: {
  params: {
    scriptId: string
    snapshotId: string
  }
}) => {
  const {
    isLoading: isSnapshotLoading,
    isError: isSnapshotError,
    data: snapshotPayload,
  } = useSnapshotData(params)
  const {
    isLoading: isScriptLoading,
    isError: isScriptError,
    data: scriptPayload,
  } = useScriptData(params.scriptId)

  const { apiClient, user, view } = useMst()
  const [isPrinting, setIsPrinting] = React.useState(false)

  const handleCreatePdf = async (type: 'print' | 'download') => {
    setIsPrinting(true)
    try {
      const html = document.getElementById('prosemirror-editor')?.innerHTML
      if (html && snapshotPayload) {
        const title = scriptPayload?.script.name ?? 'compare-snapshot'
        const fileName = `${title}.pdf`

        const downloadFn = () => {
          const revHtml = buildPrintableRevisionHtml({
            snapshot: snapshotPayload,
            prefs: user.scriptPrintPrefs,
            title,
            html,
          })
          return apiClient.passThroughPrint({
            html: revHtml,
            fileName,
            unwatermark: view.shouldUnwatermark,
          })
        }
        if (type === 'print') {
          await getBlobAndPrint(downloadFn)
        } else {
          await getBlobAndSave({ downloadFn, fileName })
        }
      }
    } catch (e) {
      showError('Failed to create PDF')
    } finally {
      setIsPrinting(false)
    }
  }

  return (
    <SnapshotPage
      loading={isSnapshotLoading || isScriptLoading || isPrinting}
      error={isSnapshotError || isScriptError}
      {...params}
    >
      <SnapshotPage.Toolbar
        activeTab="compare"
        scriptId={params.scriptId}
        snapshotId={params.snapshotId}
        onPrint={() => handleCreatePdf('print')}
        onDownloadPdf={() => handleCreatePdf('download')}
      />
      {snapshotPayload && scriptPayload && (
        <SnapshotViewerBase
          editorState={createComparisonEditorState({
            script: scriptPayload.script,
            snapshot: snapshotPayload,
          })}
          format={scriptPayload.script.scriptFormat}
        />
      )}
    </SnapshotPage>
  )
}
