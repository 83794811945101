import Nanocomponent from 'nanocomponent'
import html from 'nanohtml'
import { getInputSize } from '../../prose-utils.js'
import { collectElementNumberData, labelToPmElementNumber } from './helpers.js'
class ElementNumber extends Nanocomponent {
  constructor({ top, label, reportInput, canEdit }) {
    super()
    this.label = label
    this.top = top
    this.reportInput = reportInput
    this._canEdit = canEdit
  }
  createElement() {
    const style = `top: ${this.top}px`

    if (this._canEdit) {
      return html`<input
        class="e-element-number-input"
        style=${style}
        type="text"
        value=${this.label}
        placeholder="#"
        onblur=${(e) => this.reportInput(e.target.value)}
        oninput=${(e) => {
          this.element.size = getInputSize(e.target.value)
        }}
        maxlength="5"
        size=${getInputSize(this.label)}
      />`
    } else {
      return html`<input
        class="e-element-number-input"
        style=${style}
        type="text"
        value=${this.label}
        placeholder="#"
        maxlength="5"
        size=${getInputSize(this.label)}
        disabled="disabled"
      />`
    }
  }
  // Always self-update and return false for efficiency (top changes a LOT)
  // and we don't want to trigger unnecssary render cycles
  update({ top, label }) {
    if (this.top !== top) {
      this.top = top
      this.element.style = `top: ${top}px`
    }
    if (this.label !== label) {
      this.label = label
      this.element.value = label
      this.element.size = getInputSize(label)
    }
    return false
  }
}
class ElementNumbers extends Nanocomponent {
  constructor(editorView, appState) {
    super()
    this.editorView = editorView
    this.appState = appState
    this.childMap = {} // collectElementNumbersMap(editorView, this.appState)
    this.forceRender = false
    this._onChildInput = this._onChildInput.bind(this)
  }
  // just render the container once. After this, all children are managed via DOM additions/deletions
  createElement() {
    return html` <div id="editor-element-numbers"></div> `
  }
  load() {
    this.updateChildren()
  }
  updateChildren() {
    const newMap = collectElementNumberData(this.editorView, this.appState)
    const idsToRemove = Object.keys(this.childMap).filter((key) => !newMap[key])
    idsToRemove.forEach((id) => {
      const child = this.childMap[id]
      const component = child ? child.component : null
      if (component && component.element) {
        component.element.remove()
      }
      this.childMap[id] = undefined
    })
    Object.keys(newMap).forEach((key) => {
      const { label, top } = newMap[key]
      if (this.childMap[key]) {
        Object.assign(this.childMap[key], newMap[key])
        this.childMap[key].component.update({ top, label })
      } else {
        const newChild = new ElementNumber({
          top,
          label,
          reportInput: (newValue) => this._onChildInput(newValue, key),
          canEdit: this.editorView.editable,
        })
        this.element.appendChild(newChild.render())
        this.childMap[key] = { ...newMap[key], component: newChild }
      }
    })
  }
  update(view, prevState) {
    // rerender the component if the doc has changed or the
    // transaction was dispatched specifically to repaint
    const docIsEq = view.state.doc.eq(prevState.doc)
    if (!docIsEq || this.forceRender) {
      this.updateChildren()
    }
    // otherwise return false to avoid DOM churn on every plugin run
    return false
  }
  _onChildInput(newValue, id) {
    const childData = this.childMap[id]
    if (!childData) {
      console.warn('cannot find child element number to update')
      return
    }
    const { pos, attrs } = childData
    const elementNumber = labelToPmElementNumber(newValue)
    // only dispatch a transaction if a new value was provided
    if (elementNumber !== attrs.elementNumber) {
      const newAttrs = { ...attrs, elementNumber }
      const tr = this.editorView.state.tr.setNodeMarkup(pos, null, newAttrs)
      this.editorView.dispatch(tr)
    }
  }
}

export { ElementNumbers }
