import { keymap } from 'prosemirror-keymap'
import { EditorState } from 'prosemirror-state'
import { retrieveCommentIds } from '@util'
import { indent, outdent } from './tabIndent'
import { toggleCodeComment } from './codeComment'
import { ChooEmitter } from '../types'

export const inkKeymapPlugin = ({ emit }: { emit: ChooEmitter }) => {
  const cutShortcut = (viewState: EditorState) => {
    emit('editor:setCutComments', retrieveCommentIds(viewState))
    // carry on either way
    return false
  }

  return keymap({
    Tab: indent,
    'Shift-Tab': outdent,
    'Mod-/': toggleCodeComment,
    'Mod-x': cutShortcut,
  })
}
