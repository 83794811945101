import { useQuery, useQueryClient } from '@tanstack/react-query'
import { IOrg, useMst } from '@state'

const buildInvitesQueryKey = (orgId: string) => ['workspaceInvites', { orgId }]

// helper for workspace admins to determine an open invite count
// at most once every 5 minutes no matter how frequently they
// load the members settings and workspace settings routes
export const useInviteData = (org: IOrg) => {
  const { apiClient, user } = useMst()
  const orgId = org.id

  const queryClient = useQueryClient()
  const { data } = useQuery({
    queryFn: async () => {
      if (!org) return
      const orgId = org.id
      const res = await apiClient.fetchOrgInvites({ orgId })
      org.setInvites(
        res.results.map((i) => {
          return {
            id: i.key.replace('invite:', ''),
            email: i.email,
            status: i.status,
          }
        })
      )

      const invites = res.results
      const openInvites = invites.filter((i) => i.status === 'open')
      return openInvites.length
    },
    queryKey: buildInvitesQueryKey(orgId ?? ''),
    staleTime: 60 * 1000 * 5,
    enabled: user.isCurrentOrgAdmin,
  })

  const invalidateInviteQuery = () =>
    queryClient.invalidateQueries({
      queryKey: ['workspaceInvites', { orgId }],
    })

  return { openInviteCount: data ?? 0, invalidateInviteQuery }
}
