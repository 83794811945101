import * as envalid from 'envalid'
const { cleanEnv, str, testOnly } = envalid
const buildEnv = (rawEnv) =>
  cleanEnv(rawEnv, {
    NODE_ENV: str({
      choices: ['development', 'test', 'production'],
      default: 'development',
      desc: 'The build type. Production should be used in ALL hosted environments',
    }),
    LOG_LEVEL: str({
      choices: ['error', 'warn', 'info', 'debug', 'silent'],
      default: 'info',
      desc: 'how do you like your spam?',
    }),
    MIXPANEL_TOKEN: str({
      devDefault: '',
      desc: 'Mixpanel token for user analytics. Required in production',
    }),
    SLACK_CLIENT_ID: str({
      devDefault: '',
      desc: 'Enables slack integration with comments. Required in production',
    }),
    INTERCOM_APP_ID: str({
      default: '',
      desc: 'If present, ID for the in-app Intercom help. Used only in some production environments',
    }),
    APP_URL: str({
      desc: 'The runtime URL of this app',
      devDefault: testOnly('dummy'),
    }),
    HOME_URL: str({
      default: '',
      desc: 'The URL of the corresponding microsite (e.g. https://www.scripto.live)',
    }),
    API_URL: str({
      desc: 'The scripto legacy API server.',
      devDefault: testOnly('dummy'),
    }),
    NIDO_URL: str({
      desc: 'The shiny new API server.',
      devDefault: 'http://localhost:3008',
    }),
  })
const rehydrateConfigFromWindow = () => {
  const rawConfig = window && window.scripto ? window.scripto.config : null
  if (!rawConfig) {
    throw new Error('window.scripto.config missing')
  }
  return buildEnv(rawConfig)
}
const getEnv = () => {
  // hack for test: we don't have a good way to put the config onto
  // window.global, so this is left as a browserified/envified baked
  // in constant. TODO: inject the env at app creation time everywhere
  if (process.env.NODE_ENV === 'test') {
    return buildEnv({ NODE_ENV: 'test' })
  }
  return rehydrateConfigFromWindow()
}

export { buildEnv, getEnv }
