import { CommentHistoryPayload } from '../types'
import { ApiConfig } from './types'

type CommentHistoryParams = { scriptId: string }
type GetCommentParams = { scriptId: string; commentId: string }
type EditCommentParams = { scriptId: string; commentId: string; text: string }
type CommentThreadParams = { scriptId: string; commentIds: string[] }
type CreateCommentParams = {
  scriptId: string
  text: string
  parentId?: string
  snippet?: string
}

type Comment = {
  id: string
  scriptId: string
  text: string | null
  // createdBy: string
  // createdAt: string
  // creator: {
  //   id: string
  //   name: string
  //   avatar: string | null
  // }
  // updatedAt: string
  // parentId: string | null
  // snippet: string | null
  // deletedAt: string | null
  // replies?: []
}

type SuccessPayload = { status: 'success' }

export const getComment: ApiConfig<GetCommentParams, Comment> = ({
  scriptId,
  commentId,
}) => ({
  method: 'GET',
  url: `/scripts/${scriptId}/comments/${commentId}`,
})

export const fetchCommentHistory: ApiConfig<
  CommentHistoryParams,
  CommentHistoryPayload
> = ({ scriptId }) => ({
  method: 'GET',
  url: `/scripts/${scriptId}/comments/history`,
})

export const getCommentCounts: ApiConfig<
  CommentHistoryParams,
  Record<string, number>
> = ({ scriptId }) => ({
  method: 'GET',
  url: `/scripts/${scriptId}/comments/counts`,
})

export const getCommentThreads: ApiConfig<CommentThreadParams, Comment[]> = ({
  scriptId,
  commentIds,
}) => ({
  method: 'GET',
  url:
    `/scripts/${scriptId}/comments/` +
    (commentIds && commentIds.length
      ? `?ids[]=${commentIds.join('&ids[]=')}`
      : ''),
})

export const createComment: ApiConfig<CreateCommentParams, Comment> = ({
  scriptId,
  text,
  parentId,
  snippet,
}) => ({
  method: 'POST',
  url:
    `/scripts/${scriptId}/comments` + (parentId ? `/${parentId}/replies` : ''),
  data: snippet ? { text, snippet } : { text },
})

export const editComment: ApiConfig<EditCommentParams, Comment> = ({
  scriptId,
  commentId,
  text,
}) => ({
  method: 'PUT',
  url: `/scripts/${scriptId}/comments/${commentId}`,
  data: { text },
})

export const resolveComment: ApiConfig<GetCommentParams, SuccessPayload> = ({
  scriptId,
  commentId,
}) => ({
  method: 'PUT',
  url: `/scripts/${scriptId}/comments/${commentId}/resolve`,
})

export const unresolveComment: ApiConfig<GetCommentParams, SuccessPayload> = ({
  scriptId,
  commentId,
}) => ({
  method: 'PUT',
  url: `/scripts/${scriptId}/comments/${commentId}/unresolve`,
})

export const deleteComment: ApiConfig<
  GetCommentParams,
  { status: 'success'; replyCount: number; parentDeleted: false }
> = ({ scriptId, commentId }) => ({
  method: 'DELETE',
  url: `/scripts/${scriptId}/comments/${commentId}`,
})
