import { types } from '@showrunner/codex'
// gathers element number data from doc
function getElementNumberState(editorView, scriptType) {
  const activeTypes = []
  // setup state for each type (must have access to editorView instance)
  function getState(type) {
    const selectors =
      type === 'dialogue'
        ? ['.o-dialogue', '.o-dual-dialogue']
        : type === types.SCENE_HEADING
        ? ['.o-scene'] // another exception :(
        : [`.o-${type}`]
    const numState = {
      exists: editorView.dom.querySelector(selectors.join(',')) !== null,
      isOn: false,
    }
    if (numState.exists) {
      numState.isOn =
        editorView.dom.querySelector(
          selectors.map((x) => `${x}[data-element-number]`).join(',')
        ) !== null
    }
    if (numState.isOn) {
      activeTypes.push(type)
    }
    return numState
  }
  // setup default type (dialogue)
  const elementNumbers = {
    activeTypes,
    [types.DIALOGUE]: getState(types.DIALOGUE),
  }
  // add types that are scriptType-dependent
  if (scriptType === types.SCREENPLAY) {
    Object.assign(elementNumbers, {
      [types.SCENE_HEADING]: getState(types.SCENE_HEADING),
    })
  } else {
    Object.assign(elementNumbers, {
      [types.SLUG]: getState(types.SLUG),
      [types.BRACKET]: getState(types.BRACKET),
    })
  }
  return elementNumbers
}
export { getElementNumberState }
export default {
  getElementNumberState,
}
