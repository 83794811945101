import { useRef, ReactNode } from 'react'
import cn from 'classnames'
import { Stack, Title } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { OrgPermissionMap } from '@showrunner/codex'
import { Spinner } from '@components/Spinner'
import {
  InvisibleFdxPicker,
  InvisibleFdxPickerHandle,
} from '@components/InvisibleFdxPicker/InvisibleFdxPicker'
import { CreateCard } from './CreateCard'
import { useResourceCreation } from '@hooks/useResourceCreation'
import { useMst } from '@state'
import { IFolder } from '@state/types'
import styles from './Dashboard.module.scss'

const CreateHeader = ({ folder }: { folder?: IFolder | undefined }) => {
  const crumb: string | ReactNode = folder ? (
    <>
      in <em>{folder.displayName}</em>
    </>
  ) : (
    ''
  )
  return (
    <Title order={1} className={styles.dash_header}>
      Create New {crumb}
    </Title>
  )
}

export const CreateCards = observer(function CreateCards({
  breakpoint,
}: {
  breakpoint: string
}) {
  const creation = useResourceCreation('dashboard')

  const { currentOrg, user, view, folderMap } = useMst()
  const fdxPickerRef = useRef<InvisibleFdxPickerHandle>(null)

  const folder = folderMap.get(view.selectedFolderId)

  if (!currentOrg) return <></>

  const { hasRundownsEnabled } = currentOrg

  const createDisabled = !folder || folder.inTrash
  const createRundownDisabled =
    createDisabled ||
    !hasRundownsEnabled ||
    folder.isPrivate ||
    !user.currentOrgPermissions.includes(OrgPermissionMap.UPDATE_RUNDOWNS)

  const handleCreate = async (itemId: string) => {
    if (!folder) {
      return
    }
    switch (itemId) {
      case 'import':
        fdxPickerRef?.current?.launchPicker()
        break
      case 'screenplay':
      case 'studio':
        await creation.createScript({
          docType: itemId,
          folder,
        })
        break
      case 'rundown': {
        await creation.createRundown(folder)
        break
      }
      default:
        break
    }
  }

  const cards = [
    {
      onClick: () => handleCreate('studio'),
      isDisabled: createDisabled,
      ...currentOrg.studioFormat,
    },
    {
      onClick: () => handleCreate('screenplay'),
      isDisabled: createDisabled,
      ...currentOrg.screenplayFormat,
    },
    {
      id: 'import',
      name: 'Import',
      description: 'Choose a .FDX file from your computer',
      icon: 'fa-upload',
      onClick: () => handleCreate('import'),
      isDisabled: createDisabled,
    },
  ]

  if (hasRundownsEnabled) {
    cards.push({
      id: 'rundown',
      name: 'Rundown',
      description:
        'For listing the running order of elements and technical cues',
      icon: 'fa-table',
      onClick: () => handleCreate('rundown'),
      isDisabled: createRundownDisabled,
    })
  }

  return (
    <>
      <Stack gap={0}>
        <CreateHeader folder={view.currentFolder} />
        <div
          className={cn(styles.dash_buttonrow, breakpoint, {
            [styles.dash_buttonrow___four]: hasRundownsEnabled,
          })}
        >
          {cards.map((c) => (
            <div key={c.id}>
              <CreateCard
                name={c.name}
                description={c.description}
                icon={c.icon}
                onClick={c.onClick}
                isDisabled={c.isDisabled}
              />
            </div>
          ))}
          {folder && <InvisibleFdxPicker folder={folder} ref={fdxPickerRef} />}
        </div>
      </Stack>
      {creation.isCreating && <Spinner delayMs={300} fullScreen />}
    </>
  )
})
