import React from 'react'
import { observer } from 'mobx-react-lite'
import { Stack } from '@mantine/core'
import { SearchForm } from './Form/Form'
import { SearchPagination } from './Pagination'
import { ResultsSummary, SearchResults } from './Results'
import { FilterTags } from './FilterTags'
import { ExplorerView } from '@components/ExplorerView'
import { Loader } from '@components/Loader'
import { IOrg, useMst } from '@state'
import { useSearchQuery, PAGE_SIZE, stackHeight } from './helpers'
import {
  defaultCriteria,
  usePendingSearchContext,
  useSearchContext,
} from './SearchContext'

import { useElementDimensions } from '@hooks'

export const SearchExplorer = observer(function SearchExplorer({
  org,
  inputRef,
}: {
  org: IOrg
  inputRef: React.RefObject<HTMLInputElement>
}) {
  const mst = useMst()
  const [popoverOpened, setPopoverOpened] = React.useState(false)
  const [from, setFrom] = React.useState(0)
  const [dateFilterCategory, setDateFilterCategory] =
    React.useState<string>('Any date')
  const [folderFilters, setFolderFilters] = React.useState<{
    category: string
    folderName: string | undefined
  }>({
    category: 'Any folder',
    folderName: undefined,
  })
  const { ref, dimensions } = useElementDimensions()
  const context = useSearchContext()
  const pendingContext = usePendingSearchContext()

  // to preserve active searches, we keep the component mounted always and
  // just hide it when another tab is selected
  const hidden = mst.view.explorerState.selectedView !== 'search'

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    context?.setCriteria(pendingContext?.pendingCriteria ?? defaultCriteria)
    setFrom(0)
  }

  const onClear = () => {
    context?.setCriteria({
      ...context.criteria,
      phrase: '',
    })
    pendingContext?.mergePendingCriteria({ phrase: '' })
    inputRef.current?.focus()
  }

  const apiResult = useSearchQuery({
    workspaceId: org.id,
    from,
    filters: context?.criteria ?? defaultCriteria,
  })

  // if filter tags are present, calculate their height dynamically (and account for padding)
  const filterOffset = dimensions.height ? dimensions.height + 10 : 0

  return (
    <ExplorerView hidden={hidden}>
      <ExplorerView.Header title="Search" />
      <ExplorerView.Content sectionId="search">
        <Loader visible={apiResult.isFetching} />
        <Stack gap={10} style={{ height: stackHeight }}>
          <Stack gap={10} px={10} pt={10}>
            <SearchForm
              inputRef={inputRef}
              onSubmit={onSubmit}
              onClear={onClear}
              placeholder={`Search ${org.name}`}
              dateFilterCategory={dateFilterCategory}
              setDateFilterCategory={setDateFilterCategory}
              folderFilters={folderFilters}
              setFolderFilters={setFolderFilters}
              opened={popoverOpened}
              setOpened={setPopoverOpened}
            />
            {apiResult.isFetched && (
              <FilterTags
                tagRef={ref}
                popoverOpened={popoverOpened}
                setPopoverOpened={setPopoverOpened}
                onResetDateFilter={() => setDateFilterCategory('Any date')}
                onResetFolderFilter={() =>
                  setFolderFilters({
                    category: 'Any folder',
                    folderName: undefined,
                  })
                }
              />
            )}
            <ResultsSummary
              apiResult={apiResult}
              searchTerm={context?.criteria.phrase ?? ''}
            />
          </Stack>
          <SearchResults apiResult={apiResult} filterOffset={filterOffset} />
          <SearchPagination
            apiResult={apiResult}
            onChange={(e) => setFrom((e - 1) * PAGE_SIZE)}
          />
        </Stack>
      </ExplorerView.Content>
    </ExplorerView>
  )
})
