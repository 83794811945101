import { NumberInput } from '@mantine/core'
import { isInRange, useForm } from '@mantine/form'
import NiceModal from '@ebay/nice-modal-react'
import { ModalShell } from '@components/Modals/ModalShell'
import { useModalControls } from '@components/Modals/useModalControls'

type CustomZoomModalProps = {
  onConfirm: (level: number) => void
}

type CustomZoomFormValues = {
  level: number
}

const CustomZoom = (props: CustomZoomModalProps) => {
  const controls = useModalControls()
  const { onConfirm, ...passThroughProps } = props

  const handleConfirm = () => {
    onConfirm(Number(form.values.level) / 100 ?? 1)
    controls.onClose()
  }

  const form = useForm<CustomZoomFormValues>({
    validateInputOnBlur: true,
    validateInputOnChange: true,
    validate: {
      level: isInRange(
        { min: 50, max: 300 },
        'Enter a number between 50 and 300'
      ),
    },
  })

  return (
    <ModalShell
      {...passThroughProps}
      title="Zoom"
      confirmLabel="OK"
      size={175}
      onConfirm={form.onSubmit(handleConfirm)}
      opened={controls.opened}
      onClose={controls.onClose}
    >
      <NumberInput
        clampBehavior="blur"
        allowDecimal={false}
        allowNegative={false}
        suffix="%"
        defaultValue={100}
        min={50}
        max={300}
        step={5}
        data-autofocus
        {...form.getInputProps('level')}
      />
    </ModalShell>
  )
}

const CustomZoomModal = NiceModal.create<CustomZoomModalProps>(CustomZoom)

export const showCustomZoomModal = (props: CustomZoomModalProps) => {
  NiceModal.show(CustomZoomModal, props)
}
