import React from 'react'
import { Avatar, Card, Space, Stack, Text } from '@mantine/core'
import { format } from 'date-fns'
import { CommentMeta } from '@util/ScriptoApiClient/types'
import { getAvatarUrl } from '@util'
import { useMst } from '@state'
import { AutolinkedText, JumpLink } from './Link'
import { CommentSnippet } from './Snippet'
import { Counter, isChildComment, ThreadCount } from './helpers'

import styles from './CommentHistory.module.scss'

export const OpenComments = ({ comments }: { comments: CommentMeta[] }) => {
  const { environment } = useMst()
  let lastCommentDate: string

  const today = new Date()
  const threadCounts: ThreadCount = {}
  // gather parents
  comments
    .filter((c) => c.parentId === null)
    .forEach((c) => {
      threadCounts[c.id] = 1
    })
  // add replies
  comments.filter(isChildComment).forEach((c) => {
    const parentId = c.parentId
    // define it if it's not there (deleted parent)
    if (threadCounts[parentId] === undefined) {
      threadCounts[parentId] = 1
    } else {
      threadCounts[parentId] += 1
    }
  })
  // gather counter & thread data
  const counters: Counter[] = []
  const counterNodes: NodeListOf<HTMLElement> =
    document.querySelectorAll('.c-commentcount')

  Array.from(counterNodes).forEach((el) => {
    const commentIds = el.dataset.commentIds?.split(',')
    counters.push({ el, commentIds })
  })

  const commentCards = comments.map((comment) => {
    let formattedCommentDate = <></>
    const updatedAt = new Date(comment.updatedAt)
    // today is a special day
    const isToday = format(updatedAt, 'M d yyyy') === format(today, 'M d yyyy')
    const commentDate = isToday ? 'Today' : format(updatedAt, 'LLLL d, yyyy')
    if (commentDate !== lastCommentDate) {
      formattedCommentDate = <Text fw={700}>{commentDate}</Text>
    }
    lastCommentDate = commentDate

    return (
      <React.Fragment key={comment.id}>
        {formattedCommentDate}
        <Card className={styles.comment_card}>
          <Stack gap="xs">
            {comment.snippet && <CommentSnippet comment={comment} />}
            <div className={styles.comment_contents}>
              <div className={styles.comment_byline}>
                <Avatar
                  src={getAvatarUrl(comment.creator.avatar, environment.config)}
                  alt={comment.creator.name}
                  radius="xl"
                  size="sm"
                ></Avatar>
                <div className={styles.comment_text}>
                  <span className={styles.comment_author}>
                    {comment.creator.name}:
                  </span>
                  <AutolinkedText text={comment.text} />
                </div>
              </div>
              <Space h="xs" />
              <JumpLink
                comment={comment}
                counters={counters}
                threadCounts={threadCounts}
              />
            </div>
          </Stack>
        </Card>
      </React.Fragment>
    )
  })

  return <>{commentCards}</>
}
