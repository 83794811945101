import { useForm } from 'react-hook-form'
import { ErrorMessage } from './ErrorMessage'
import { parseServerError } from '@util'
import isUUID from 'is-uuid'
import { ScriptSnapshotPayload } from '@util/ScriptoApiClient/types'
import { useMst } from '@state'

type Inputs = {
  scriptId: string
}

export const LoadSnaphotForm = ({
  onSnapshotLoaded,
}: {
  onSnapshotLoaded: (snapshot: ScriptSnapshotPayload) => void
}) => {
  const { register, handleSubmit, formState, setError } = useForm({
    defaultValues: {
      apiError: '',
      scriptId: '',
    },
  })
  const { errors } = formState
  const { apiClient } = useMst()

  const getSnapshot = async ({ scriptId }: Inputs) => {
    try {
      const snapshot = await apiClient.getSnapshot({
        scriptId,
        snapshotId: 'latest',
      })
      onSnapshotLoaded(snapshot)
    } catch (e) {
      const { message } = parseServerError(e)
      setError('apiError', { type: 'custom', message })
    }
  }

  return (
    <form onSubmit={handleSubmit(getSnapshot)}>
      <div>
        enter the ID of a script to load its latest snapshot and the script
        format.
      </div>
      <div>
        <input
          {...register('scriptId', {
            required: 'Enter a script ID',
            validate: {
              scriptId: (scriptId) =>
                isUUID.v4(scriptId) ||
                'Invalid script ID (did you copy/paste correctly?)',
            },
          })}
        />

        <input type="submit" />
      </div>
      <ErrorMessage message={errors.scriptId?.message} />
      <ErrorMessage message={errors.apiError?.message} />
    </form>
  )
}
