import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Group } from '@mantine/core'
import AutosizeInput from 'react-input-autosize'
import cn from 'classnames'
import { useCallbackRef } from '@hooks/useCallbackRef'
import { ILoadedScript, IRundown, ListingId } from '@state/types'
import { DocLocator } from './DocLocator'

import styles from './DocumentTitle.module.scss'
import { FavoriteToggle } from '../FavoriteToggle'

export const DocumentTitle = ({
  currentName,
  icon,
  disabled = false,
  onSubmit,
  docId,
  folderId,
  docType,
}: {
  currentName: string
  docId: ListingId
  folderId: string
  icon: string
  disabled: boolean
  onSubmit?: (value: string) => Promise<void>
  docType: 'rundown' | 'script'
}) => {
  const [editedName, setEditedName] = useState(currentName)
  const [inputRef, setInputRef] = useCallbackRef<HTMLInputElement | null>()

  // respond to a rename of the doc listing in the sidebar
  useEffect(() => {
    setEditedName(currentName)
  }, [currentName])

  const handleSubmit = async () => {
    const trimmedName = editedName.trim()
    if (trimmedName.length === 0) {
      setEditedName(currentName)
    } else if (onSubmit && trimmedName !== currentName) {
      try {
        // this is trying to save to the database
        await onSubmit(trimmedName)
      } catch {
        // if it fails, go back to the original value
        setEditedName(currentName)
      }
    }
  }

  return (
    <>
      <div className={styles.documentTitle_crosshairs}>
        <DocLocator docType={docType} folderId={folderId} />
      </div>
      <span className={styles.documentTitle_divider}>/</span>
      <Group gap={0} wrap="nowrap">
        <form
          className={styles.documentTitle}
          onSubmit={(e) => {
            e.preventDefault()
            handleSubmit()
            inputRef.current?.blur()
          }}
        >
          <span className={styles.documentTitle__iconwrap}>
            <div className={styles.documentTitle_icon}>
              <i className={icon} />
            </div>
          </span>
          <AutosizeInput
            inputRef={(inputElt) => setInputRef(inputElt)}
            inputClassName={styles.documentTitle_input}
            type="text"
            value={editedName}
            disabled={disabled}
            onBlur={handleSubmit}
            onChange={({ target }) => setEditedName(target.value)}
          />
        </form>
        <FavoriteToggle docId={docId} />
      </Group>
    </>
  )
}

export const ScriptTitle = observer(function ScriptTitle({
  script,
}: {
  script: ILoadedScript
}) {
  return (
    <DocumentTitle
      docId={script.id}
      folderId={script.folderId}
      currentName={script.name}
      disabled={!script.isEditable}
      onSubmit={script.updateName}
      icon={cn('fas', script.scriptFormat.icon)}
      docType="script"
    />
  )
})

export const RundownTitle = observer(function RundownTitle({
  rundown,
  readOnly = false,
}: {
  rundown: IRundown
  readOnly?: boolean
}) {
  return (
    <DocumentTitle
      docId={rundown.id}
      folderId={rundown.folderId}
      currentName={rundown.name}
      disabled={readOnly}
      onSubmit={rundown.updateName}
      icon="fas fa-table-cells"
      docType="rundown"
    />
  )
})
