import { types } from 'mobx-state-tree'
import { BaseModel } from './BaseModel'
import { ScriptStatus, ScriptStatusMap } from '@showrunner/codex'
import type { IOrgMember, IFolder } from '../types'
import { IsoDate } from './IsoDate'

export const ListingBase = BaseModel.named('ListingBase')
  .props({
    id: types.union(types.string, types.number),
    name: types.string,
    contentsModifiedAt: IsoDate,
    contentsModifiedBy: types.string,
    createdAt: IsoDate,
    folderId: types.string,
  })
  .views((self) => ({
    get lastModifier(): IOrgMember | undefined {
      return self.rootStore.currentOrg?.getMember(self.contentsModifiedBy)
    },
    get parentFolder(): IFolder | undefined {
      return self.rootStore.folderMap.get(self.folderId)
    },
    get inTrash(): boolean {
      return !!this.parentFolder?.inTrash
    },
    get isFavorite(): boolean {
      return !!self.rootStore.currentOrg?.favoriteListingIds.includes(self.id)
    },
    get isPrivate(): boolean {
      return !!this.parentFolder?.isPrivate
    },
  }))
  .actions((self) => ({
    processSocketUpdate({
      name,
      folderId,
      contentsModifiedAt,
      contentsModifiedBy,
    }: {
      name: string
      folderId: string
      contentsModifiedAt: string
      contentsModifiedBy: string
    }) {
      self.contentsModifiedAt = IsoDate.create(contentsModifiedAt)
      self.contentsModifiedBy = contentsModifiedBy
      self.name = name
      self.folderId = folderId
    },
  }))

export const ScriptListingBase = ListingBase.named('ScriptListingBase')
  .props({
    // The goal is to turn this into just LIMITED and OPEN
    status: types.enumeration<ScriptStatus>(Object.values(ScriptStatusMap)),
  })
  .views((self) => ({
    get accessLevel(): ScriptStatus {
      return self.isPrivate ? 'PRIVATE' : self.status
    },
  }))
  .actions((self) => ({
    setSharedStatus(value: 'OPEN' | 'LIMITED') {
      self.status = value
    },
    // little hack so we pretend the api NEVER sends us
    // a status of private
    afterAttach() {
      if (self.isPrivate) {
        self.status = 'OPEN'
      }
    },
  }))
