import { observer } from 'mobx-react-lite'
import { RundownEditor } from '@components/RundownEditor'
import { useMst } from '@state'
import { IRundown } from '@state/types'
import { SideBySide, SideBySideProps } from './SideBySide'

const limit = {
  // mini-header height
  minHeight: 44,
  // enough to accommodate the mini-header control bars
  minWidth: 120,
}

const limits: SideBySideProps['limits'] = {
  firstChild: limit,
  secondChild: limit,
}

export const RundownAndScript = observer(function RundownAndScript({
  rundown,
  children,
}: {
  rundown: IRundown
  children?: JSX.Element
}) {
  const { view } = useMst()
  const { layout, rundownHeight, rundownWidth } = view.rundownScriptLayout

  const handleSplitResize = ({ height, width }: Partial<Dimensions>) => {
    view.updateRundownScriptLayout({
      rundownHeight: height,
      rundownWidth: width,
    })
  }

  return (
    <SideBySide
      limits={limits}
      layout={layout}
      preferredHeight={rundownHeight}
      preferredWidth={rundownWidth}
      onResizeEnd={handleSplitResize}
      firstChild={<RundownEditor rundown={rundown} />}
      secondChild={children}
    />
  )
})
