import { ReactNode } from 'react'
import axios from 'axios'
import {
  Anchor,
  Center,
  Container,
  Image,
  Space,
  Stack,
  Text,
  Title,
} from '@mantine/core'
import tvImage from '../../assets/images/TV.png'
import brokenTvImage from '../../assets/images/TV-broken.png'

import styles from './ErrorPage.module.scss'

type ErrorPageProps = {
  code?: string
  title: string
  message?: ReactNode | string
  image?: string
  imageAlt?: string
}

const pageConfigs: Record<string, ErrorPageProps> = {
  notFound: {
    code: '404',
    title: 'Not Found',
    image: tvImage,
    message: "Oh no! Looks like this page doesn't exist.",
    imageAlt: 'TV with color bars',
  },
  unknown: {
    code: '500',
    title: 'Internal Server Error',
    image: brokenTvImage,
    message: "Our bad! Something's gone wrong on our end.",
    imageAlt: 'TV with broken screen',
  },
  serverDown: {
    code: 'Uh-oh!',
    title: "Our servers aren't responding",
    image: brokenTvImage,
    message: (
      <Text span>
        If reloading the page doesn&apos;t do the trick, please contact us via
        live chat or&nbsp;
        <Anchor href="mailto:help@scripto.live">email</Anchor>.
      </Text>
    ),
    imageAlt: 'TV with broken screen',
  },
}

export function ErrorPage({
  code,
  title,
  message,
  image,
  imageAlt,
}: ErrorPageProps) {
  return (
    <Center>
      <Container size={400}>
        <Stack>
          <Space h={5} />
          {image && (
            <Container size={260}>
              <Center>
                <Image src={image} alt={imageAlt} />
              </Center>
            </Container>
          )}
          {code && (
            <Title className={styles.errorPage_title} order={1}>
              {code}
            </Title>
          )}
          <Title order={3}>{title}</Title>
          {message && <Text>{message}</Text>}
        </Stack>
      </Container>
    </Center>
  )
}

export const NotFoundPage = () => <ErrorPage {...pageConfigs.notFound} />
export const UnknownErrorPage = () => <ErrorPage {...pageConfigs.unknown} />
export const ServerDownPage = () => <ErrorPage {...pageConfigs.serverDown} />

export const ScriptLoadErrorPage = ({
  scriptId,
  error,
}: {
  scriptId: string
  error: unknown
}) => {
  const status = axios.isAxiosError(error) ? error.response?.status : undefined
  const message = axios.isAxiosError(error) ? error.message : undefined

  if (status === 404 || status === 422) {
    return <NotFoundPage />
  }

  if (status === 500) {
    return (
      <ErrorPage
        {...pageConfigs.unknown}
        message={
          <div>
            {message ?? "We couldn't load that script"}
            <br />
            <a href={`/scripts/${scriptId}/snapshots/latest`}>
              Try loading a backup?
            </a>
          </div>
        }
      />
    )
  }

  return <UnknownErrorPage />
}
