/**
 * Checks if *any* page in the doc is locked
 * @param {EditorView} editorView - prose view
 * @returns {boolean}
 */
function getLockedState(editorView) {
  return editorView.dom.querySelector('.o-page[data-locked]') !== null
}
export { getLockedState }
export default {
  getLockedState,
}
