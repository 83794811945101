/*
  This hook is used across the app to attach exactly one format stylesheet to the
  window. (If we ever want to have two scripts with different formats on screen at
  once we'll need to modify/prefix the formats).

  This is used by the Script.tsx which manages the live editor and by the SnapshotViewer
*/
import React from 'react'
import { StyleSheet } from 'jss'
import { ScriptFormatPayload } from '@util/ScriptoApiClient/types'
import { FormatUtils } from '@showrunner/codex'

type FormatContext = {
  format: null | ScriptFormatPayload
  attachedStyles: null | StyleSheet
}

const currentFormatData: FormatContext = {
  format: null,
  attachedStyles: null,
}

const ScriptFormatContext =
  React.createContext<FormatContext>(currentFormatData)
export const ScriptFormatProvider = ScriptFormatContext.Provider

const setFormat = (format: ScriptFormatPayload) => {
  // if we've already got the right format, noop
  if (currentFormatData.format?.id === format.id) {
    return
  }
  // we need to set up the new format, if we've got a stylesheet already,
  // we should detach it
  currentFormatData?.attachedStyles?.detach()

  const newStylesheet = FormatUtils.createStylesheet(format.definition, {
    media: 'screen',
  })
  newStylesheet.attach()

  currentFormatData.attachedStyles = newStylesheet
  currentFormatData.format = format
}

export const useFormatStyles = () => {
  const { format } = React.useContext(ScriptFormatContext)
  return {
    format,
    setFormat,
  }
}
