import { useEffect, useState } from 'react'
import { Spinner } from '@components/Spinner'
import { launchExplorerToast } from '@components/Toast'
import { Direction, Paginator } from '@components/Paginator'
import { SnapshotInfo } from './SnapshotInfo'
import { SnapshotFeed } from './SnapshotFeed'
import {
  ScriptSnapshotPayload,
  SnapshotFindPayload,
  SnapshotSummary,
} from '@util/ScriptoApiClient/types'
import styles from './SnapshotHistory.module.scss'

// number of snapshots to fetch at once
const RESULT_PAGE_SIZE = 20

const SNAPSHOT_FILTER_VALUES = ['all', 'manual'] as const
export type SnapshotFilter = typeof SNAPSHOT_FILTER_VALUES[number]

export type SharedSnapshotHistoryProps = {
  onUpdate?: ({
    snapshotId,
    name,
  }: {
    snapshotId: string
    name: string
  }) => Promise<ScriptSnapshotPayload>
}

export type SnapshotHistoryProps = SharedSnapshotHistoryProps & {
  filter: SnapshotFilter
  toggleRefresh: boolean
  onRetrieve?: ({
    from,
    size,
    filter,
  }: {
    from?: number
    size?: number
    filter?: SnapshotFilter
  }) => Promise<SnapshotFindPayload>
}

export function SnapshotHistory({
  filter,
  toggleRefresh,
  onRetrieve,
  onUpdate,
}: SnapshotHistoryProps) {
  const [loading, setLoading] = useState(true)
  const [snapshots, setSnapshots] = useState([] as Array<SnapshotSummary>)
  const [noSnapshots, setNoSnapshots] = useState(false)
  const [from, setFrom] = useState(0)
  const [total, setTotal] = useState(0)

  useEffect(() => {
    async function retrieveSnapshots() {
      if (!onRetrieve) return
      setLoading(true)
      try {
        const history = await onRetrieve({
          from,
          size: RESULT_PAGE_SIZE,
          filter,
        })

        setSnapshots(history.results)
        setTotal(history.total)
        if (history.results.length < 1) setNoSnapshots(true)
      } catch (e) {
        // displays twice in dev mode (React 18?)
        launchExplorerToast({
          type: 'error',
          message: 'Failed to retrieve snapshots',
        })
      }
      setLoading(false)
    }
    retrieveSnapshots()
  }, [from, onRetrieve, filter, toggleRefresh])

  useEffect(() => {
    setNoSnapshots(false)
  }, [filter])

  const handlePageClick = (direction: Direction) => {
    setFrom(
      direction === 'left' ? from - RESULT_PAGE_SIZE : from + RESULT_PAGE_SIZE
    )
  }

  return (
    <div className={styles.snapshotHistory}>
      {!loading && !noSnapshots && (
        <>
          <div className={styles.snapshotHistory_results}>
            <SnapshotFeed snapshots={snapshots} onUpdate={onUpdate} />
          </div>
          <Paginator
            from={from + 1}
            to={from + snapshots.length}
            total={total}
            canPageBackward={from > 0}
            canPageForward={total > from + snapshots.length}
            onClick={handlePageClick}
            mode={'mini'}
          />
        </>
      )}
      {!loading && noSnapshots && <SnapshotInfo />}
      {loading && <Spinner delayMs={300} noOverlay />}
    </div>
  )
}
