/**
 * Get page count of current document.
 * @param {EditorState} state
 * @returns {number} page count
 */
function getPageCount(state) {
  return state.doc.childCount
}
export { getPageCount }
export default {
  getPageCount,
}
