import React from 'react'
import { useMst } from '@state'
import { Button, TextInput } from '@mantine/core'
import { schemas, ZInfer } from '@showrunner/scrapi'
import { NavAnchor } from '@components/NavAnchor'
import { PaginatedPayloadView } from '@components/PaginatedPayloadView'
import { CopyableId } from '@components/Staff/CopyableId'

type WorkspaceFilter = Omit<
  ZInfer<typeof schemas.FindWorkspacesRequest>,
  'from' | 'size'
>

// Convenience type for any kind of event that has a preventDefault on it
type StoppableEvent = { preventDefault: () => void }

const WorkspacesResultsView = ({
  results,
}: {
  results: ZInfer<typeof schemas.FindWorkspacesResults>['results']
}) => (
  <table>
    <thead>
      <tr>
        <th>Workspace Name</th>
        <th>Tier</th>
        <th>ID</th>
      </tr>
    </thead>
    <tbody>
      {results.map(({ id, name, tier }) => (
        <tr key={id}>
          <td>
            <NavAnchor href={`/staff/workspaces/${id}`}>{name}</NavAnchor>
          </td>
          <td>{tier}</td>
          <td>
            <CopyableId id={id} />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

type WorkspaceFilterProps = {
  filter: WorkspaceFilter
  onChange: ({ tier, name, flag }: WorkspaceFilter) => void
}

const WorkspaceFilterView = ({ onChange, filter }: WorkspaceFilterProps) => {
  const [formValues, setFormValues] = React.useState<WorkspaceFilter>({
    tier: filter.tier ?? '',
    name: filter.name ?? '',
    flag: filter.flag ?? '',
  })

  const handleSumbit = (e: StoppableEvent) => {
    e.preventDefault()
    onChange(formValues)
  }

  const clearForm = (e: StoppableEvent) => {
    e.preventDefault()
    const newValues = {
      name: '',
      tier: '',
      flag: '',
    }
    setFormValues(newValues)
    onChange(newValues)
  }

  const activeFilters: string[] = [
    filter.flag ? `with feature flag "${filter.flag}"` : '',
    filter.name ? `matching name: "${filter.name}"` : '',
    filter.tier ? `on tier: ${filter.tier}` : '',
  ].filter((f) => f)
  const filterMessage =
    activeFilters.length > 0
      ? `Showing workspaces ${activeFilters.join(', ')}`
      : ''

  const hasFlag = formValues.flag !== ''

  return (
    <>
      <h1>
        <NavAnchor href="/staff">Staff Zone</NavAnchor>
      </h1>
      <div className="l-box">
        {filterMessage && <p>{filterMessage}</p>}
        <form onSubmit={handleSumbit}>
          <div className="l-box">
            <TextInput
              label="Workspace name"
              placeholder={hasFlag ? '' : 'macgruber'}
              value={formValues.name}
              disabled={hasFlag}
              onChange={(e) =>
                setFormValues({ ...formValues, name: e.target.value })
              }
            />
            <TextInput
              label="Tier"
              placeholder={hasFlag ? '' : 'tv-film'}
              value={formValues.tier}
              disabled={hasFlag}
              onChange={(e) =>
                setFormValues({ ...formValues, tier: e.target.value })
              }
            />
            <TextInput
              label="Flag name"
              placeholder="beta"
              value={formValues.flag}
              onChange={(e) =>
                setFormValues({ flag: e.target.value, name: '', tier: '' })
              }
            />
          </div>
          <br />
          <div className="l-box l-box--center">
            <Button type="submit">Search</Button>
            <Button variant="subtle" onClick={clearForm}>
              Reset Filters
            </Button>
          </div>
          <br />
        </form>
      </div>
    </>
  )
}

export const Workspaces = () => {
  const { scrapi, location } = useMst()
  // when we wire up UI for filtering we can make this bi-directional
  const flag: string | undefined = location.getQueryParam('flag')
  const filter = { flag }

  const findWorkspacesForStaff = async ({
    from,
    size,
    name,
    tier,
    flag,
  }: ZInfer<typeof schemas.FindWorkspacesRequest>) => {
    const response = await scrapi.staff.findWorkspaces({
      body: { from, size, name, tier, flag },
    })

    if (response.status !== 200) throw new Error('oops')
    return response.body
  }

  return (
    <PaginatedPayloadView
      getPage={findWorkspacesForStaff}
      FilterView={WorkspaceFilterView}
      pageSize={20}
      ResultView={WorkspacesResultsView}
      initialFilter={filter}
    />
  )
}
