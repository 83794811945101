import { z } from 'zod'

const SERIALIZED_PREF_RE = /^{.*}$/

const localStorageValueToPojo = (value: unknown): object => {
  if (typeof value === 'string' && SERIALIZED_PREF_RE.test(value.trim())) {
    try {
      return JSON.parse(value)
    } catch {
      return {}
    }
  }
  return {}
}

const basePreference = z.unknown().transform(localStorageValueToPojo)

const rundownPrintPrefs = basePreference.pipe(
  z.object({
    orientation: z.enum(['portrait', 'landscape']).catch('portrait'),
    pageSize: z.enum(['letter', 'a4', 'legal', 'ledger']).catch('letter'),
    monochrome: z.boolean().catch(false),
    rowDensity: z.enum(['compact', 'standard', 'loose']).catch('standard'),
  })
)

export type RundownPrintPrefs = z.infer<typeof rundownPrintPrefs>

export const schemas = {
  rundownPrintPrefs,
}
