import { useState } from 'react'
import {
  Button,
  FileButton,
  Divider,
  Group,
  Space,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useMst } from '@state'
import { schemaAlreadyExists, schemaFromFile, isValidName } from './helpers'
import { SCHEMA_QUERY_KEY, rundownSchemaQuery } from '../helpers'
import { showError } from '@components/Modals'

type CreateRundownFormValues = {
  name: string
  description: string
}

export const CreateRundownSchema = () => {
  const mst = useMst()
  const { data: schemas } = useQuery(rundownSchemaQuery(mst.apiClient))
  const queryClient = useQueryClient()

  const [file, setFile] = useState<File | null>(null)
  const [loading, setLoading] = useState(false)

  const form = useForm<CreateRundownFormValues>({
    validateInputOnBlur: true,
    initialValues: {
      name: '',
      description: '',
    },
    validate: {
      name: (value) => {
        if (value.length < 1) return 'Name is required'
        if (!isValidName(value)) {
          return 'Special characters and/or spaces are not allowed'
        }
        if (schemaAlreadyExists(value, schemas)) {
          return 'Schema already exists'
        }
        return null
      },
      description: (value) =>
        value.length > 0 ? null : 'Description is required',
    },
  })

  const onSubmit = form.onSubmit(async (values) => {
    setLoading(true)
    try {
      await mst.doDebug()
      if (!file) throw new Error('Schema definition is required')
      const schema = await schemaFromFile(file)
      await mst.apiClient.createRundownSchema({
        name: values.name,
        description: values.description,
        schema,
      })
      queryClient.invalidateQueries({ queryKey: [SCHEMA_QUERY_KEY] })
      form.reset()
    } catch (e) {
      showError((e as Error).message ?? 'An unknown error occurred')
    } finally {
      setLoading(false)
    }
  })

  return (
    <>
      <Title order={3}>Create</Title>
      <form onSubmit={onSubmit}>
        <Stack>
          <TextInput
            label="Name"
            placeholder="The Late Show"
            {...form.getInputProps('name')}
          />
          <TextInput
            label="Description"
            placeholder="For what its worth"
            {...form.getInputProps('description')}
          />
          <Space />
          <Group justify="center">
            <Stack gap={0}>
              <FileButton onChange={setFile} accept="application/json">
                {(props) => (
                  <Button {...props} color="orange">
                    Upload schema definition
                  </Button>
                )}
              </FileButton>
              {file && (
                <Text size="sm" ta="center" mt="sm">
                  selected: {file.name}
                </Text>
              )}
            </Stack>
          </Group>
          <Divider />
          <Group justify="center">
            <Button type="submit" loading={loading}>
              Save
            </Button>
          </Group>
        </Stack>
      </form>
    </>
  )
}
