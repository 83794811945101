import React from 'react'
import { Toolbar } from '@components/Toolbar'

import styles from './MiniHeader.module.scss'

export const LeftSection = ({ children }: { children: React.ReactNode }) => (
  <Toolbar.Section position="left" className={styles.leftSection}>
    {children}
  </Toolbar.Section>
)

export const RightSection = ({ children }: { children: React.ReactNode }) => (
  <Toolbar.Section position="right" className={styles.rightSection}>
    {children}
  </Toolbar.Section>
)

export const MiniHeader = () => <></>

MiniHeader.LeftSection = LeftSection
MiniHeader.RightSection = RightSection
