import cn from 'classnames'
import { format } from 'date-fns'
import { useMst } from '@state'
import { KeyboardEvent, useState } from 'react'
import { Stack, TextInput } from '@mantine/core'
import { NavAnchor, NavAnchorHref } from '@components/NavAnchor'
import { snapshotDescription, snapshotAuthor } from './helpers'
import { SharedSnapshotHistoryProps } from './SnapshotHistory'
import { SnapshotMenu } from './SnapshotMenu'
import { launchExplorerToast } from '@components/Toast'
import { SnapshotSummary } from '@util/ScriptoApiClient/types'

import styles from './SnapshotItem.module.scss'

type SnapshotItemProps = SharedSnapshotHistoryProps & {
  snapshot: SnapshotSummary
  isRenaming: boolean
  renameHandler: (id: string) => void
}

export function SnapshotItem({
  onUpdate,
  snapshot,
  isRenaming,
  renameHandler,
}: SnapshotItemProps) {
  const [loading, setLoading] = useState(false)
  const { autoSave } = snapshot
  const { view } = useMst()

  const staticUrl: NavAnchorHref = `/scripts/${snapshot.scriptId}/snapshots/${snapshot.id}`

  const className = cn(styles.snapshot, {
    [styles.snapshot___mini]: autoSave,
    [styles.snapshot___elevated]: !autoSave,
  })

  const snapshotCreationDate = new Date(snapshot.createdAt)
  const formattedTime = format(snapshotCreationDate, 'p')
  const slug = snapshotDescription(snapshot) + snapshotAuthor(snapshot)

  const handleBlur = async (value: string) => {
    if (value !== '' && value !== snapshot.name && onUpdate) {
      setLoading(true)
      try {
        await onUpdate({
          snapshotId: snapshot.id,
          name: value,
        })
        snapshot.name = value
        snapshot.autoSave = null
      } catch (e) {
        launchExplorerToast({
          message: 'Failed to rename snapshot',
          type: 'error',
          autoCloseDelayMs: 3000,
        })
      }
      setLoading(false)
    }
    renameHandler('')
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') renameHandler('')
    if (e.key === 'Enter') handleBlur(e.currentTarget.value)
  }

  const snapshotMeta = (
    <div className={styles.snapshot_meta}>
      {slug} at {formattedTime}
    </div>
  )

  return (
    <div className={className}>
      {isRenaming ? (
        <Stack className={styles.snapshot_input__wrapper} gap={0}>
          <TextInput
            defaultValue={snapshot.name ?? ''}
            autoFocus
            onBlur={(e) => handleBlur(e.currentTarget.value)}
            onKeyDown={handleKeyDown}
            onClick={(e) => e.preventDefault()}
            disabled={loading}
            classNames={{ input: styles.snapshot_input }}
          ></TextInput>
          {snapshotMeta}
        </Stack>
      ) : (
        <NavAnchor
          className={styles.snapshot_link}
          href={staticUrl}
          onClick={() => {
            view.explorerState.setLastScriptPath(location.pathname)
          }}
        >
          <div className="l-box l-box--column">
            {snapshot.name && (
              <div className={styles.snapshot_name}>
                <div className={styles.snapshot_title___manual}>
                  {snapshot.name}
                </div>
              </div>
            )}
            {snapshotMeta}
          </div>
        </NavAnchor>
      )}
      <SnapshotMenu snapshot={snapshot} renameHandler={renameHandler} />
    </div>
  )
}
