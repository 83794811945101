import {
  OrgPayload,
  ScriptFormatPayload,
  ScriptFormatSummary,
  StaffInviteListPayload,
  RundownSchemaListingPayload,
} from '../types'
import { RundownSchema } from '@util/rundowns'
import { OrgOption } from '@showrunner/codex'
import { ApiConfig } from './types'

export const getOrgForStaff: ApiConfig<string, OrgPayload> = (orgId) => ({
  method: 'GET',
  url: `/staff/orgs/${orgId}`,
})

export const createScriptFormat: ApiConfig<ScriptFormatPayload, void> = (
  data: ScriptFormatPayload
) => ({
  method: 'POST',
  url: `/staff/scriptFormats`,
  data,
})

export const getFormats: ApiConfig<void, ScriptFormatSummary[]> = () => ({
  method: 'GET',
  url: `/staff/scriptFormats`,
})

// NOTE: unauthenticated endpoint
export const createInviteByStaff: ApiConfig<
  { email: string },
  { success: true }
> = (data) => ({
  method: 'POST',
  url: `/invites/request`,
  data,
})

export const getInvitesByStaff: ApiConfig<
  string | undefined,
  StaffInviteListPayload
> = (email) => {
  const url = `/staff/invites${email ? `/${email}` : ''}`
  return {
    method: 'GET',
    url,
  }
}

export const setScriptFormatsForOrg: ApiConfig<
  {
    orgId: string
    data: Partial<{ screenplayFormatId: string; studioFormatId: string }>
  },
  void
> = ({ orgId, data }) => ({
  method: 'PUT',
  url: `/staff/orgs/${orgId}/script-format`,
  data,
})

export const fetchRundownSchemas: ApiConfig<
  void,
  RundownSchemaListingPayload
> = () => ({
  method: 'GET',
  url: '/staff/rundown-schemas',
})
export const createRundownSchema: ApiConfig<
  {
    name: string
    description: string
    schema: RundownSchema
  },
  { id: number }
> = (data) => ({
  method: 'POST',
  url: '/staff/rundown-schemas',
  data,
})

// if we don't audit this action, we should
export const updateRundownSchema: ApiConfig<
  {
    schemaId: number
    description: string
  },
  { id: number }
> = ({ schemaId, description }) => ({
  method: 'PUT',
  url: `/staff/rundown-schemas/${schemaId}`,
  data: { description },
})

export const setRundownSchemaForOrg: ApiConfig<
  {
    orgId: string
    schemaName: string
  },
  void
> = ({ orgId, schemaName }) => ({
  method: 'PUT',
  url: `/staff/orgs/${orgId}/rundownschema/${schemaName}`,
})

export const togglePlanOptionByStaff: ApiConfig<
  {
    orgId: string
    planOption: OrgOption
    enabled: boolean
  },
  void
> = ({ orgId, planOption, enabled }) => {
  const endpoint = enabled ? 'enable' : 'disable'
  const url = `/staff/orgs/${orgId}/options/${planOption}/${endpoint}`
  return {
    method: 'PUT',
    url,
  }
}

export const toggleBetaFlagByStaff: ApiConfig<
  {
    orgId: string
    flagId: string
    enable: boolean
  },
  void
> = ({ orgId, flagId, enable }) => {
  const method = enable ? 'POST' : 'DELETE'
  return {
    method,
    url: `/staff/features/${flagId}/actors/${orgId}`,
  }
}

export const revokeUserSessions: ApiConfig<string, void> = (userId) => ({
  method: 'PUT',
  url: `/staff/users/${userId}/revoke-sessions`,
})

export const reindexDocsInOrgByStaff: ApiConfig<{ orgId: string }, void> = ({
  orgId,
}) => ({
  method: 'POST',
  url: `/staff/orgs/${orgId}/reindex`,
})
