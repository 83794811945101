import React from 'react'
import { TextInput, Space } from '@mantine/core'
import { PasswordInput, useStrongPasswordForm } from '@components/PasswordInput'
import { Pinput } from '@components/Pinput'
import { showError } from '@components/Modals'
import { useMst } from '@state'
import { ROUTE_PATTERNS } from '@util'
import * as formUtil from '@util/formUtil'
import { parseServerError } from '@util/parseServerError'
import { useReturnTo } from './useReturnTo'
import { OnboardingPage } from './OnboardingPage'
import { ProgressIndicator } from './ProgressIndicator'
import { OtpBailouts } from './OnboardingPage/BailoutLink'
import { FormError, knownCodes, serverErrorMessages } from './FormErrors'
import { SentOtpSubtitle, CreateWorkspaceSubtitle } from './PageSubtitle'

const extractErrorMessage = (e: unknown): string => {
  const { code, message } = parseServerError(e)
  return code ?? message
}

export type CreateStytchAccountFormValues = {
  email: string
  verificationCode: string
  name: string
  password: string
}

export type CreateAccountPhase =
  | 'enterEmail'
  | 'confirmEmail'
  | 'enterAccountDetails'

const PHASE_CONFIG: Record<
  CreateAccountPhase,
  {
    title: string
    renderSubtitle?: (values: CreateStytchAccountFormValues) => React.ReactNode
  }
> = {
  enterEmail: {
    title: 'Enter Your Email',
    renderSubtitle: () => <CreateWorkspaceSubtitle />,
  },
  confirmEmail: {
    title: 'Verify Your Email',
    renderSubtitle: ({ email }: CreateStytchAccountFormValues) => (
      <SentOtpSubtitle email={email} />
    ),
  },
  enterAccountDetails: {
    title: 'Create Your Account',
  },
}

type CreateStytchAccountPageProps = {
  phase: CreateAccountPhase
  prepopulatedEmail?: string
  serverError?: string
  loading?: boolean
  onReset: () => void
  onSubmitEmail: (email: string) => Promise<void>
  onSubmitVerification: (code: string) => Promise<void>
  onSubmitAccountDetails: (
    values: CreateStytchAccountFormValues
  ) => Promise<void>
}

export const CreateStytchAccountPage = ({
  phase,
  onReset,
  onSubmitEmail,
  onSubmitVerification,
  onSubmitAccountDetails,
  loading,
  prepopulatedEmail,
  serverError,
}: CreateStytchAccountPageProps) => {
  const { form, passwordStrength } =
    useStrongPasswordForm<CreateStytchAccountFormValues>({
      passwordField: 'password',
      skipPasswordCheck: phase !== 'enterAccountDetails',
      initialValues: {
        email: prepopulatedEmail ?? '',
        verificationCode: '',
        password: '',
        name: '',
      },
      validate: {
        email: formUtil.validateEmail,
        verificationCode: (value) => {
          if (phase === 'confirmEmail') {
            return formUtil.validateOtpCode(value)
          }
        },
        name: (value) => {
          if (phase === 'enterAccountDetails') {
            return formUtil.validateNotEmpty(value)
          }
        },
      },
    })

  const handleSubmit = async (values: CreateStytchAccountFormValues) => {
    if (phase === 'enterEmail') {
      onSubmitEmail(values.email)
    } else if (phase === 'confirmEmail') {
      await onSubmitVerification(values.verificationCode)
      form.setFieldValue('verificationCode', '')
    } else if (phase === 'enterAccountDetails') {
      onSubmitAccountDetails(values)
    }
  }

  const handleStartOver = () => {
    form.reset()
    onReset()
  }

  const phaseIndex: 0 | 1 | 2 =
    phase === 'enterEmail' ? 0 : phase === 'confirmEmail' ? 1 : 2

  const { title, renderSubtitle } = PHASE_CONFIG[phase]

  const resendCode = () => {
    form.setFieldValue('verificationCode', '')
    form.clearFieldError('verificationCode')
    onSubmitEmail(form.values.email)
  }

  const renderError = () => {
    if (!serverError) {
      return null
    }

    const handleClick = () => {
      switch (serverError) {
        case knownCodes.OTP_EXPIRED_OR_USED:
          resendCode()
          break
        case knownCodes.SESSION_NOT_FOUND:
          handleStartOver()
          break
      }
    }

    return <FormError code={serverError} onClick={handleClick} />
  }

  return (
    <OnboardingPage title={title} loading={loading}>
      <OnboardingPage.Buttons activeButton="signup" />
      {renderSubtitle && renderSubtitle(form.values)}
      <OnboardingPage.Form
        onSubmit={form.onSubmit(handleSubmit)}
        errorMessage={renderError()}
      >
        {phase === 'enterEmail' && (
          <TextInput
            label="Email"
            placeholder="name@email.com"
            disabled={phase !== 'enterEmail'}
            {...form.getInputProps('email')}
          />
        )}

        {phase === 'confirmEmail' && (
          <Pinput {...form.getInputProps('verificationCode')} />
        )}

        {phase === 'enterAccountDetails' && (
          <>
            <TextInput
              size="md"
              label="Name"
              placeholder="I. M. Human"
              {...form.getInputProps('name')}
            />
            <PasswordInput
              size="md"
              label="Password"
              strengthInfo={passwordStrength}
              {...form.getInputProps('password')}
            />
          </>
        )}
        <OnboardingPage.SubmitButton label="Next" />
        {phase === 'confirmEmail' && (
          <OtpBailouts
            onResendCode={resendCode}
            onStartOver={handleStartOver}
          />
        )}
      </OnboardingPage.Form>
      <OnboardingPage.Links types={['support']} />
      <Space h={30} />
      <ProgressIndicator currentStep={phaseIndex} />
    </OnboardingPage>
  )
}

export const CreateStytchAccountRoute = () => {
  const mst = useMst()
  const { returnTo } = useReturnTo({
    fallback: ROUTE_PATTERNS.createWorkspace,
  })

  const [loading, setLoading] = React.useState(false)
  const [phase, setPhase] = React.useState<CreateAccountPhase>('enterEmail')
  const [oneTimePasscodeId, setOneTimePasscodeId] = React.useState('')
  const [token, setToken] = React.useState('')
  const [serverError, setServerError] = React.useState<string | undefined>()

  const startRequest = () => {
    setServerError(undefined)
    setLoading(true)
  }

  const goToPhase = (nextPhase: CreateAccountPhase) => {
    setServerError(undefined)
    setPhase(nextPhase)
  }
  const handleSubmitEmail = async (email: string) => {
    startRequest()
    try {
      const result = await mst.apiClient.initiateSignup(email)
      setOneTimePasscodeId(result.oneTimePasscodeId)
      goToPhase('confirmEmail')
    } catch (e) {
      setServerError(extractErrorMessage(e))
    } finally {
      setLoading(false)
    }
  }

  const handleSubmitVerification = async (code: string) => {
    startRequest()
    try {
      const result = await mst.apiClient.validateOneTimePasscode({
        code,
        oneTimePasscodeId,
      })
      setToken(result.token)
      goToPhase('enterAccountDetails')
    } catch (e) {
      const errorMessage = extractErrorMessage(e)
      if (errorMessage === knownCodes.TOO_MANY_REQUESTS) {
        showError(serverErrorMessages.tooManyRequests)
      } else {
        setServerError(errorMessage)
      }
    } finally {
      setLoading(false)
    }
  }

  const handleSubmitAccountDetails = async ({
    email,
    name,
    password,
  }: CreateStytchAccountFormValues) => {
    startRequest()

    try {
      await mst.apiClient.createAccountStytch({ email, name, password, token })
    } catch (e) {
      setServerError(extractErrorMessage(e))
      setLoading(false)
      return
    }
    // we've still got loading = true from the create account call.
    // Now log in and redirect
    try {
      await mst.apiClient.login({ email, password })
      returnTo()
    } catch (e) {
      setServerError(extractErrorMessage(e))
      setLoading(false)
    }
  }

  return (
    <CreateStytchAccountPage
      phase={phase}
      loading={loading}
      serverError={serverError}
      onReset={() => goToPhase('enterEmail')}
      onSubmitEmail={handleSubmitEmail}
      onSubmitVerification={handleSubmitVerification}
      onSubmitAccountDetails={handleSubmitAccountDetails}
    />
  )
}
