/*
  Click handler for our editor view. Modifies cmd+click behavior

  * Prevents prosemirror from creating a node selection
  * if in a link mark, opens the hyperlink in a new tab

*/
import { isMacOs } from 'react-device-detect'
import { EditorProps } from 'prosemirror-view'
import { MarkTypeMap } from '@showrunner/codex'

export const handleClick: EditorProps['handleClick'] = (view, pos, event) => {
  const isCmdClickEvent =
    event.button === 0 && isMacOs ? event.metaKey : event.ctrlKey

  if (isCmdClickEvent) {
    const linkMark = view.state.doc
      .resolve(pos)
      .marks()
      .find((m) => m.type.name === MarkTypeMap.LINK)

    // if the editorView is NOT content editable, hyperlinks are
    // handled by the browser, but if not, we will handle on cmd+click
    if (view.editable) {
      const href = linkMark?.attrs.href
      if (typeof href === 'string') {
        window.open(href, '_blank', 'noopener noreferrer')
      }
    }
  }
  // we return 'true' to prevent PM from selecting nodes via modified click
  // we return 'false' to preserve default PM behavior for normal clicks.
  return isCmdClickEvent
}
