import { useForm, isNotEmpty } from '@mantine/form'
import { v4 as isUuid } from 'is-uuid'
import { TemplateWorkspaceData } from './useTemplateApis'

import { extractFaIconInfo } from '@components/FaIcon'
import { isTemplateImageAsset } from '@components/Onboarding/NewWorkspace/TemplateImage'

export type TemplateFormData = TemplateWorkspaceData & {
  mode: 'create' | 'update'
  selectedCode: string
}

const initialValues: TemplateFormData = {
  mode: 'create',
  selectedCode: '',
  name: '',
  code: '',
  description: '',
  sequence: 0,
  active: false,
  workspaceId: '',
  icon: '',
}

export const useTemplateForm = (templates: TemplateWorkspaceData[]) => {
  const form = useForm<TemplateFormData>({
    initialValues,
    validate: {
      workspaceId: (value) => (isUuid(value) ? null : 'Invalid UUID'),
      name: isNotEmpty('Required'),
      description: isNotEmpty('Required'),
      sequence: (value) => {
        if (typeof value === 'number' && Math.round(value) === value) {
          return null
        }
        return 'Provide an integer value > 0'
      },
      icon: (value) => {
        if (value.trim().length === 0) {
          return 'Required'
        }
        if (isTemplateImageAsset(value)) {
          return null
        }
        const { iconName } = extractFaIconInfo(value)
        return iconName ? null : 'Supply a valid icon name'
      },
      code: (value, { mode }) => {
        // only validate CODE if we're creating a new one
        if (mode === 'update') {
          return null
        }
        if (value.trim().length === 0) {
          return 'Required'
        }

        if (encodeURIComponent(value) !== value) {
          return `Pick a code that doesn't need encoding in a URL (stick with letters, numbers, dashes, etc)`
        }
        if (templates.some((t) => t.code === value)) {
          return 'That code is already in use'
        }
        return null
      },
    },
  })

  return form
}
