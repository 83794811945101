import React from 'react'
import styles from './ScriptAndToolbars.module.scss'

type ScriptAndToolbarsProps = {
  children: React.ReactNode
  height: number
  width: number
  zoomLevel: number
}

export const ScriptAndToolbars = React.forwardRef<
  HTMLDivElement,
  ScriptAndToolbarsProps
>(({ children, zoomLevel, height, width }, ref) => {
  // We keep track of the dimensions and update mst so that things
  // like the extras menu can resize to fit

  return (
    <div
      className={styles.scriptAndToolbars}
      ref={ref}
      style={{
        '--zoom-level': zoomLevel,
        '--prose-editor-height': `${height}px`,
        '--prose-editor-width': `${width}px`,
      }}
    >
      {children}
    </div>
  )
})

ScriptAndToolbars.displayName = 'ScriptAndToolbars'
