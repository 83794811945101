import { EditorView } from 'prosemirror-view'
import { EditorState, TextSelection } from 'prosemirror-state'
import { EditorManager } from '@choo-app/lib/editor/EditorManager'
import { schema, MarkTypeMap } from '@showrunner/codex'
import { BaseModel } from './BaseModel'
import {
  getBlock,
  getBlockType,
  isSelectionTooBig,
  isSingleBlockTextSelection,
  shouldDisableAlignment,
} from '@util'

export const PmEditor = BaseModel.named('PmEditor')
  .props({
    editorViewFocused: false,
    editingHyperlink: false,
  })
  .volatile<{
    editorManager: EditorManager | null
    editorState: EditorState | null
  }>(() => ({
    editorManager: null,
    // we could get the editorState via the editorManager, but instead we
    // store it separately and re-set it any time the editorState changes so
    // it becomes observable
    editorState: null,
  }))
  .views((self) => ({
    get editorView(): EditorView | null {
      return self.editorManager?.view ?? null
    },
  }))
  .views((self) => ({
    get selection() {
      return self.editorState?.selection
    },
    get textSelection(): TextSelection | undefined {
      return self.editorState?.selection instanceof TextSelection
        ? self.editorState.selection
        : undefined
    },
    get selectionTouchesEndOfBlock(): boolean {
      if (!this.selection) return false
      const { $from, $to } = this.selection
      if (!$from.parent.isBlock) return false
      if ($to.parentOffset === $to.parent.content.size) return true
      return false
    },
    get singleBlockTextSelection(): boolean {
      if (self.editorState === null) return false
      return isSingleBlockTextSelection(self.editorState)
    },
    get selectionBlockType() {
      if (self.editorState === null) return
      if (!this.singleBlockTextSelection) return
      return getBlockType(self.editorState)
    },
    /**
     * attempt to pluck the relevant attribute from
     * plain cursors and text selections spanning a single block
     * otherwise return null
     *
     * @returns {string|undefined} - 'right' | 'center' | 'left' | undefined
     */
    get selectionAlignment(): string | undefined {
      if (self.editorState === null) return
      if (!this.singleBlockTextSelection) return
      return getBlock(self.editorState)?.attrs?.alignment
    },
    get disableAlignment(): boolean {
      if (self.editorState === null) return true
      return shouldDisableAlignment(self.editorState)
    },
  }))
  .views((self) => ({
    selectionContainsMark(name: string): boolean {
      const markType = schema.marks[name]
      const { textSelection, editorState } = self
      if (editorState && markType && textSelection) {
        const { from, $from, to, empty } = textSelection
        if (empty) {
          return !!markType.isInSet(editorState.storedMarks || $from.marks())
        } else {
          return editorState.doc.rangeHasMark(from, to, markType)
        }
      }
      return false
    },
    get canFormatText(): boolean {
      if (self.editorState && self.editorView) {
        return (
          self.editorView.editable &&
          !!self.selection &&
          // is this replaceable with just a check that the selection is
          // not AllSelection
          !(self.selection.$anchor.depth === 0) &&
          !isSelectionTooBig(self.editorState)
        )
      }
      return false
    },
    get hasModifiableSelectedText(): boolean {
      if (self.editorState && self.editorView) {
        return this.canFormatText && !self.selection?.empty
      }
      return false
    },
    get hyperlinkingEnabled(): boolean {
      if (!this.hasModifiableSelectedText) {
        return false
      }
      if (!self.singleBlockTextSelection) {
        return false
      }
      // check there's no overlapping hyperlink
      return !this.selectionContainsMark(MarkTypeMap.LINK)
    },
    get selectionContainsFormattingMark(): boolean {
      return (
        this.selectionContainsMark(MarkTypeMap.STRONG) ||
        this.selectionContainsMark(MarkTypeMap.EM) ||
        this.selectionContainsMark(MarkTypeMap.UNDERLINE) ||
        this.selectionContainsMark(MarkTypeMap.STRIKE)
      )
    },
  }))
  .actions((self) => ({
    setEditingHyperlink(value: boolean) {
      self.editingHyperlink = value
    },
    setEditorManager(value: EditorManager | null) {
      self.editorManager = value
      self.editorState = value?.view?.state ?? null
    },
    setEditorViewFocused(value: boolean) {
      self.editorViewFocused = value
    },
    syncEditorView() {
      if (self.editorView) {
        self.editorState = self.editorView.state
        // only set focus to true here, we have special
        // handlers for dealing with blur
        if (self.editorView.hasFocus()) {
          self.editorViewFocused = true
        }
      } else {
        self.editorState = null
        self.editorViewFocused = false
      }
    },
    focusEditor() {
      if (self.editorView) {
        self.editorView.focus()
        this.syncEditorView()
      }
    },
    syncEditorViewFocusState() {
      if (self.editorView) {
        if (self.editorViewFocused) {
          self.editorView.focus()
        }
        this.syncEditorView()
      }
    },
    rerender() {
      if (self.editorView) {
        const { tr } = self.editorView.state
        tr.setMeta('choo', true)
        self.editorView.dispatch(tr)
      }
    },
  }))
