import React from 'react'
import cn from 'classnames'
import { TextProps, Text } from '@mantine/core'
import styles from './CustomIcon.module.scss'

export type CustomIconProps = Omit<TextProps, 'children'> & {
  icon: `icon-${string}`
  fixedWidth?: number
}

export const CustomIcon = React.forwardRef<HTMLDivElement, CustomIconProps>(
  ({ icon, className, fixedWidth, ...textProps }, ref) => {
    const wrapperClasses = cn(className, styles.customIcon)

    return (
      <Text
        {...textProps}
        ref={ref}
        className={wrapperClasses}
        style={{
          width: fixedWidth,
        }}
      >
        <i className={cn(styles.icomoonIcon, icon)} />
      </Text>
    )
  }
)

CustomIcon.displayName = 'CustomIcon'
