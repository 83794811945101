import html from 'nanohtml'
function commentMenu(comment, actions) {
  const deleteAction = () => actions.deleteComment(comment.id, comment.parentId)
  return html`
    <div class="c-comment__menu c-dropdown">
      <button class="o-button o-button--iconic o-button--inverted">
        <i class="fa fa-ellipsis-v"></i>
      </button>
      <div class="c-dropdown__list c-dropdown__list--dark">
        <button
          class="c-dropdown__item"
          onclick="${() => actions.startEditing(comment.id)}"
        >
          Edit
        </button>
        <button
          class="c-dropdown__item c-dropdown__item--red"
          onclick="${deleteAction}"
        >
          Delete
        </button>
      </div>
    </div>
  `
}
export { commentMenu }
export default {
  commentMenu,
}
