import NiceModal from '@ebay/nice-modal-react'
import { useState } from 'react'
import { Stack } from '@mantine/core'
import { Toast } from '@components/Toast'
import { OwnerSelect } from '@components/Settings/Members/OwnerSelect'
import { useMst } from '@state'
import { ModalShell } from './ModalShell'
import { useModalControls } from './useModalControls'
import styles from './TransferOwnershipModal.module.scss'

const TransferOwnershipModalInternal = () => {
  const { doDebug, currentOrg } = useMst()

  const controls = useModalControls()
  const [orgData, setOrgData] = useState<{
    canSend: boolean
    ownerId: string
  }>({
    canSend: false,
    ownerId: currentOrg?.owner.id ?? '',
  })

  if (!currentOrg) return <></>

  const handleConfirm = async () => {
    controls.setLoading(true)
    try {
      await doDebug()
      await currentOrg?.update({ ownerId: orgData.ownerId })
      controls.onClose()
    } catch {
      controls.setErrorMessage('Failed to assign new owner')
    } finally {
      controls.setLoading(false)
      setOrgData({
        canSend: false,
        ownerId: orgData.ownerId,
      })
    }
  }

  return (
    <ModalShell
      title="Transfer Ownership"
      confirmLabel="Transfer Ownership"
      cancelLabel="Cancel"
      onConfirm={handleConfirm}
      opened={controls.opened}
      loading={controls.loading}
      disabled={!orgData.canSend}
      errorMessage={controls.errorMessage}
      onClose={controls.onClose}
      dangerous={true}
    >
      <Stack justify="flex-start" gap={0} className={styles.modal_content}>
        <OwnerSelect org={currentOrg} onChange={(obj) => setOrgData(obj)} />
        <Toast
          icon="fa fa-exclamation-triangle"
          type="warning"
          message={
            <div>
              <strong>You currently own this workspace. </strong>
              <span>
                Your role will be set to Admin if you transfer ownership.
              </span>
            </div>
          }
          dismissable={false}
        ></Toast>
      </Stack>
    </ModalShell>
  )
}

const TransferOwnershipModal = NiceModal.create(TransferOwnershipModalInternal)

export const showTransferOwnershipModal = () => {
  NiceModal.show(TransferOwnershipModal)
}
