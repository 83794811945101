import {
  DefaultMantineColor,
  Stack,
  Text,
  Space,
  Progress,
} from '@mantine/core'
import type { StrengthResult } from './types'

const getLabel = (score: number) => {
  return score > 3 // multi-line
    ? 'GREAT!'
    : score > 2
    ? 'GOOD'
    : score > 1
    ? 'ALMOST'
    : 'TOO WEAK'
}

const getColor = (score: number): DefaultMantineColor => {
  return score > 2 // multi-line
    ? 'green.7'
    : score === 2
    ? 'orange.7'
    : 'red'
}

const getRecommendation = ({ feedback, score }: StrengthResult): string => {
  const autoGeneratedRecommendation =
    feedback.warning.length > 0
      ? feedback.warning
      : feedback.suggestions.join(' ')
  if (autoGeneratedRecommendation.trim().length > 0) {
    return autoGeneratedRecommendation
  }

  if (score > 3) {
    return 'This is a strong password 😎'
  }
  if (score > 2) {
    return 'This works, but it could be better.'
  }
  // We shouldn't hit this, zxcvbn will give feedback for lower scores
  return 'Add another word or two. Uncommon words are better.'
}

export type StrengthFeedbackProps = {
  strengthInfo: StrengthResult
  isBlank: boolean
  errorMessage?: string
}

export const StrengthFeedback = ({
  strengthInfo,
  isBlank,
  errorMessage,
}: StrengthFeedbackProps) => {
  const color = getColor(strengthInfo.score)
  const progress = isBlank ? 0 : ((strengthInfo.score as number) + 1) * 20
  const feedbackMessage = isBlank ? (
    'Pick a hard-to-guess password'
  ) : (
    <span>&nbsp;&mdash;&nbsp;{getRecommendation(strengthInfo)}</span>
  )

  return (
    <Stack gap="sm">
      <Space h={0} />
      <Progress color={color} value={progress} />

      <Text size="sm" style={{ height: 30 }}>
        {!errorMessage && (
          <>
            <Text span size="sm" fw={700} color={color}>
              {!isBlank && getLabel(strengthInfo.score)}
            </Text>
            <Text span color="dark">
              {feedbackMessage}
            </Text>
          </>
        )}
        {errorMessage && <Text color="red">{errorMessage}</Text>}
      </Text>
    </Stack>
  )
}
