import { Decoration, DecorationSet } from 'prosemirror-view'
import env from '../../../env.js'
const { AVATAR_ROOT, GRAYFACE_URL } = env
// data-only class to carry around the data we need to keep track
// of remote cursors and their positions
class RemoteCursorItem {
  constructor({ clientId, anchorPosition, headPosition, user, color }) {
    // user data (doesn't change)
    this.clientId = clientId
    this.name = user.name
    this.color = color
    this.imageUrl = user.avatar ? `${AVATAR_ROOT + user.avatar}` : GRAYFACE_URL
    // position data (changes)
    this.anchorPosition = anchorPosition
    this.headPosition = headPosition
  }
  // used when cursor event sends explicit data
  update(anchor, head) {
    this.anchorPosition = anchor
    this.headPosition = head
  }
  // used to transform local copy of cursors as doc changes
  applyMapping(mapping) {
    this.anchorPosition = mapping.map(this.anchorPosition)
    this.headPosition = mapping.map(this.headPosition)
  }
}
// Below here:  Helper utilities to work with a collection of cursor objects
/**
 * Handle a cursor add/remove or update from a transaction
 *
 * @param {object} newCursorData - the plugin data extracted from a prosemirror transaction
 * @param {object} cursors - an array of RemoteCursorItem
 *
 * @returns The transformed array of RemoteCursorItem
 */
const applyNewCursorData = (newCursorData, cursors) => {
  // removed
  if (newCursorData.removedCursor) {
    const { clientId } = newCursorData.removedCursor
    return cursors.filter((c) => c.clientId !== clientId)
  }
  // added or updated
  const { clientId } = newCursorData
  const index = cursors.findIndex((c) => c.clientId === clientId)
  if (index > -1) {
    const result = [...cursors]
    result[index].update(
      newCursorData.anchorPosition,
      newCursorData.headPosition
    )
    return result
  } else {
    return [...cursors, new RemoteCursorItem(newCursorData)]
  }
}
/**
 * Modify the position information about cursors based on a document change
 *
 * @param {object} mapping - Prosemirror mapping
 * @param {object} cursors - an array of RemoteCursorItem
  @returns The transformed array of RemoteCursorItem
 */
const remapCursors = (mapping, cursors) => {
  const result = [...cursors]
  result.forEach((cursor) => cursor.applyMapping(mapping))
  return result
}
// Get the PM decoration for a vertical line cursor (no text selected)
const getDecorationsForCursor = (cursor) => {
  const spec = { key: `cursor-${cursor.clientId}` }
  const dom = document.createElement('span')
  dom.className = 'js-remote-cursor'
  dom.style = `border-right-color: ${cursor.color}`
  return Decoration.widget(cursor.headPosition, dom, spec)
}
// Get the PM decoration for a remote cursor's selection
const getDecorationsForSelection = (cursor) => {
  const spec = { key: `selection-${cursor.clientId}` }
  const attrs = {
    // NOTE: this class isn't defined in CSS
    // but it's still useful when inspecting the DOM
    class: 'remoteSelect',
    style: `background: ${cursor.color}50;`,
  }
  // left to right highlighted selection
  if (cursor.headPosition > cursor.anchorPosition) {
    return Decoration.inline(
      cursor.anchorPosition,
      cursor.headPosition,
      attrs,
      spec
    )
  }
  // right to left highlighted selection
  if (cursor.headPosition < cursor.anchorPosition) {
    return Decoration.inline(
      cursor.headPosition,
      cursor.anchorPosition,
      attrs,
      spec
    )
  }
  console.error('unhandled remote cursor state', cursor)
}
// Get the decorations for the remote cursors and apply to the doc
const applyDecorations = (doc, cursors) => {
  const decos = []
  cursors.forEach((cursor) => {
    if (!cursor.headPosition || !cursor.anchorPosition) {
      // we don't want to show cursors with no position information
      return
    }
    if (cursor.headPosition === cursor.anchorPosition) {
      decos.push(getDecorationsForCursor(cursor))
    } else {
      decos.push(getDecorationsForSelection(cursor))
    }
  })
  return DecorationSet.create(doc, decos)
}
export { applyNewCursorData }
export { remapCursors }
export { applyDecorations }
export default {
  applyNewCursorData,
  remapCursors,
  applyDecorations,
}
