import { Avatar, Button, Group, Table, Text } from '@mantine/core'
import styles from './PermissionUserRow.module.scss'

type TableUser = {
  id: string
  name: string
  email: string
  image: string | undefined
}

export const UserRow = ({
  user,
  onRevoke,
}: {
  user: TableUser
  onRevoke: (id: string) => void
}) => (
  <Table.Tr className={styles.membertable_row}>
    <Table.Td>
      <Group wrap="nowrap">
        <Avatar radius="xl" size="md" src={user.image} />
        <div>
          <Text size="md">{user.name}</Text>
          <Text size="sm" c="dimmed">
            {user.email}
          </Text>
        </div>
      </Group>
    </Table.Td>
    <Table.Td>
      <Group justify="flex-end">
        <Button variant="subtle" color="red" onClick={() => onRevoke(user.id)}>
          <Text size="15">Remove</Text>
        </Button>
      </Group>
    </Table.Td>
  </Table.Tr>
)
