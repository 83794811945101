import { EditorView } from 'prosemirror-view'
import { NavLinkData, isNavLinkElement } from '@util/constants'
import { LEGIBLE_BLOCK_NAMES } from '../../prose-utils'

const DOC_OFFSET = 1

export const getNavLinks = (editorView: EditorView): NavLinkData[] => {
  const navLinks: NavLinkData[] = []

  editorView.state.doc.forEach((page, pageOffset) => {
    page.forEach((block, blockOffset) => {
      const type = block.type.name
      const { elementNumber, id } = block.attrs
      // Ignore blocks that aren't NavLink types and ones that don't have
      // an ID (which can happen if the id-maker plugin hasn't run on a new NavLink)
      if (!(isNavLinkElement(type) && id)) {
        return
      }

      const trimmedText = block.textContent.trim()
      const isBlank = trimmedText === ''
      const text = isBlank ? LEGIBLE_BLOCK_NAMES[type] : trimmedText
      const pos = blockOffset + pageOffset + DOC_OFFSET
      const nodeLength = block.nodeSize

      navLinks.push({
        type,
        text,
        pos,
        elementNumber,
        id,
        isBlank,
        nodeLength,
      })
    })
  })

  return navLinks
}
