import { PageLayout } from './types'

/*
  This generates the css variables for the margin sizes and header/footer content.
  Note that the header/footer css vars aren't necessarily used when printing, but the
  variables are generated.
*/
export const buildPageCssVars = ({
  layout,
  monochrome,
  title,
  timestamp,
}: {
  layout: PageLayout
  monochrome?: boolean
  title: string
  timestamp: string
}) => {
  const { margins, orientation, size } = layout
  const escapedTitle = CSS.escape(title)
  const escapedTimestamp = CSS.escape(timestamp)
  const linkColor = monochrome ? '--link-color: #000 !important' : ''
  const pageVars = `
    :root {
      ${linkColor};
      --doc-title: '${escapedTitle}';
      --section-title: string(act-title, last) string(slug-title, last);
      --timestamp: '${escapedTimestamp}';
      --page-margins: ${margins.top} ${margins.right} ${margins.bottom} ${margins.left};
      --page-size: ${size.width} ${size.height} ${orientation};
    }
  `
  return pageVars
}
