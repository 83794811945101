/*
  The DatadogClient exposes a public singleton instance. It can be imported
  directly but it's also available in the mst environment.

  At app launch time MST sets up observable reactions to update certain
  global context variables like user_id and org_id.
*/

import { datadogLogs, StatusType } from '@datadog/browser-logs'
import { autorun } from 'mobx'
import { IRoot } from '@state/types'
import { config } from './config'

// DEV TIP:

// uncomment one of the two lines below to send datadog logs to the
// console- either in addition to sending to datadog or not at all

// datadogLogs.logger.setHandler(['console', 'http'])
// datadogLogs.logger.setHandler(['console'])

type LogMetadata = Record<string, unknown>

type AppStatusSummary = {
  inBackground: boolean
  backgroundAgeSeconds: number
  activityAgeSeconds: number
}

const sendLog = ({
  data,
  message,
  level,
  error,
}: {
  message: string
  data?: LogMetadata
  level: StatusType
  error?: unknown
}) => {
  const attributes = data ? { data } : undefined
  const sendableError = error instanceof Error ? error : undefined
  datadogLogs.logger.log(message, attributes, level, sendableError)
}

export class DatadogClient {
  private initialized = false

  static instance: DatadogClient | null = null

  // Prevent instantiation from outside this file to ensure a singleton
  private constructor() {
    datadogLogs.init(config)
  }

  public static getInstance(): DatadogClient {
    if (!DatadogClient.instance) {
      DatadogClient.instance = new DatadogClient()
    }
    return DatadogClient.instance
  }

  // when certain values change we want to update datadog
  // context variables
  initializeContextTracking(mst: IRoot) {
    if (!this.initialized) {
      this.initialized = true
      // watch some mst values for changes and auto-update
      autorun(() => {
        datadogLogs.setGlobalContext({
          staff: mst.user.staff,
          user_id: mst.user.id,
          org_id: mst.currentOrg?.id ?? null,
          script_id: mst.currentScript?.id ?? null,
        })
      })
    }
  }

  setVisibility(state: DocumentVisibilityState) {
    datadogLogs.addLoggerGlobalContext('visibility', state)
    this.info(`visibility ${state}`)
  }

  setAppStatus({
    inBackground,
    backgroundAgeSeconds,
    activityAgeSeconds,
  }: AppStatusSummary) {
    datadogLogs.addLoggerGlobalContext('inBackground', inBackground)
    datadogLogs.addLoggerGlobalContext(
      'backgroundAgeSeconds',
      backgroundAgeSeconds
    )
    datadogLogs.addLoggerGlobalContext('activityAgeSeconds', activityAgeSeconds)
  }

  setSocketId(socketId: string) {
    datadogLogs.addLoggerGlobalContext('socket_id', socketId)
  }

  debug(message: string, data?: LogMetadata, error?: unknown) {
    sendLog({ message, data, level: 'debug', error })
  }

  info(message: string, data?: LogMetadata, error?: unknown) {
    sendLog({ message, data, level: 'info', error })
  }

  warn(message: string, data?: LogMetadata, error?: unknown) {
    sendLog({ message, data, level: 'warn', error })
  }

  error(message: string, data?: LogMetadata, error?: unknown) {
    sendLog({ message, data, level: 'error', error })
  }
}
