import html from 'nanohtml'
import Component from 'nanocomponent'
import { createState } from './view-state.js'
import { bindActions } from './view-actions.js'
import { commentCounts } from './comment-counts.js'
import { CommentsContainer } from './comments-container.js'
import { CommentForm } from './comment-form.js'
import { CommentFormNew } from './comment-form-new.js'
import { commentButton } from './comment-button.js'
import { collabUpdateKey } from '../collab-update.js'
export class CommentsView extends Component {
  constructor({ editorView, scriptId, user, emit, appState }) {
    super()
    this.state = createState(editorView, scriptId, user, appState)
    this.actions = bindActions(this.state, this, emit, appState)
    this.commentsContainer = new CommentsContainer()
    // keep three copies of comment form so they can't clobber each other
    this.components = {
      replyForm: new CommentForm(),
      editForm: new CommentForm(),
      addForm: new CommentFormNew(),
    }
    // this is necessary because the moment that the user lifts up their mouse to complete
    // making a selection, PM has aleady triggered its last state change
    this._triggerUpdate = this._triggerUpdate.bind(this)
  }
  createElement() {
    return html`
      <div>
        ${commentButton(this.state, this.actions)}
        <div id="comments">
          ${commentCounts(this.state, this.actions)}
          ${this.commentsContainer.render(
            this.state,
            this.actions,
            this.components
          )}
        </div>
      </div>
    `
  }
  update(editorView, prevState) {
    // if neither the doc nor the selection has changed, no need to rerender
    const docIsEq = editorView.state.doc.eq(prevState.doc)
    const selectionIsEq = editorView.state.selection.eq(prevState.selection)
    if (docIsEq && selectionIsEq) {
      return
    }
    // if collab update and updated selection not empty, no need to rerender
    const collabUpdate = collabUpdateKey.getState(editorView.state)
    if (collabUpdate && !editorView.state.selection.empty) {
      return
    }
    /*
          right now we hide the new comment input when the user updates their selection
          it would be superior to:
          1. maintain a reference to the original selection
          2. map its positions as additional transactions are applied
          3. continue highlighting the original selection when the selection changes
          4. associate the comment with the original selection when its finally submitted
          5. we could even selectively dismiss the dialogue
          if the user hadnt actually started composing a comment
        */
    if (!selectionIsEq && this.state.isAdding) {
      this.actions.cancelNewCommentEdit()
    }
    // TODO: don't rerender if comment marks haven't changed
    return true
  }
  load() {
    window.addEventListener('mouseup', this._triggerUpdate)
  }
  unload() {
    window.removeEventListener('mouseup', this._triggerUpdate)
  }
  _triggerUpdate() {
    this.rerender()
  }
}
