import { schema, types } from '@showrunner/codex'
import { DOC_OFFSET, MAX_LINES } from './constants.js'
import LastBlocks from './last-blocks.js'
function newPageOpts(pageNumber = null) {
  const opts = {
    type: schema.nodes[types.PAGE],
    attrs: { dynamic: true, pageNumber },
  }
  return [opts]
}
function getSplitterFuction(format) {
  /**
   * Splits content into pages. Passes through null value.
   * TODO: find split using DOM instead of font-dependent line calculations
   * @param {Transaction} [tr] - transaction with pages to split or null
   * @param {EditorState} editorState - PM state instance
   * @param {object} [diffRange=null] - {start,end}
   * @param {array} [pageNumbers=[]] - {start,end}
   * @returns {Transaction|null} transaction with split pages or null
   */
  function splitter(tr, editorState, diffRange = null, pageNumbers = []) {
    if (!tr) {
      return null
    }
    // NOTE: need to substract 1 because join effectively changes start index
    const startPageIndex = diffRange
      ? editorState.doc.resolve(diffRange.start).index(0) - 1
      : null
    // start offset at page offset (0) plus doc offset (1)
    let totalOffset = DOC_OFFSET
    let pageCount = tr.doc.childCount
    for (let pageIndex = 0; pageIndex < pageCount; pageIndex++) {
      const page = tr.doc.child(pageIndex)
      // skip targeted repagination check if there's no start index
      if (startPageIndex != null) {
        // if we're at start index or below, add page offset and continue
        if (startPageIndex > pageIndex) {
          totalOffset += page.nodeSize
          continue
        }
        // exit if we reach a manual break and we're past diff end
        if (
          !page.attrs.dynamic &&
          !page.attrs.locked &&
          tr.mapping.map(diffRange.end) < totalOffset - 1 // no doc offset
          // TODO: the above worries me. is DOC_OFFSET needed?
        ) {
          return tr
        }
      }
      const blockOffset = getNextSplitOffset(page, format)
      // No block offset means this page is fine,
      // so we move on to next index without any modifications.
      if (!blockOffset) {
        totalOffset += page.nodeSize
        continue
      }
      const splitPos = totalOffset + blockOffset
      const { depth } = tr.doc.resolve(splitPos)
      // needs to be index for _next_ page
      const opts = newPageOpts(pageNumbers[pageIndex + 1])
      tr.split(splitPos, depth, opts)
      // add updated node size of current page to offset
      totalOffset += tr.doc.child(pageIndex).nodeSize
      // update page count based on latest doc state
      pageCount = tr.doc.childCount // could be just ++?
    }
    return tr
  }
  return splitter
}
/**
 * get next split position of a page if it's over max lines
 * @param {ProseMirror.Node} page - prosemirror page node
 * @param {string} format
 * @returns {number|null} position of next page split (null if not found)
 */
function getNextSplitOffset(page, format) {
  let blockOffset = null
  const lastBlocks = new LastBlocks()
  getPageLines(page, format, function forEachBlock(blockData) {
    // exit if block offset has been set to a non-zero value
    // NOTE: should exit loop here instead of continuing to avoid needlessly
    // iterating through more blocks, but forEach doesn't have a `break`
    // statement. We could iterate ourselves using a for loop and see if that
    // would be more performant.
    if (blockOffset) {
      return
    }
    const { blockNode, offset, pageLines } = blockData
    if (pageLines > MAX_LINES) {
      const dialogueOffset = getDialogueOffset(blockNode, lastBlocks)
      if (dialogueOffset) {
        blockOffset = dialogueOffset
      } else {
        blockOffset = offset
      }
      // not sure if this could throw undefined, just being overly cautious
      const { nodeBefore } = page.resolve(blockOffset) || {}
      // need to check if node before blockOffset is scene heading
      // (must be done AFTER logic above to avoid orphaned scene heading)
      // if so use that node's offset instead
      if (nodeBefore && nodeBefore.type.name === types.SCENE_HEADING) {
        blockOffset -= nodeBefore.nodeSize
      }
      // TODO: Transitions have special PB behavior in old showrunner,
      // but don't appear to be treated differently from regular elements in FD.
      // Need to verify and make a product design call. I vote FD, because:
      // - More likely to match page counts and print styles for FD
      // - Right now it means less code and work in here
    } else {
      // only add to last blocks if blockOffset hasn't been set
      lastBlocks.add(blockData)
    }
  })
  return blockOffset
}
/**
 * Detect dialogue group and return new offset if needed.
 * A dialogue group is composed of:
 * - character block
 * - 1 or 2 of (parenthetical, dialogue)
 * Anything past that is ignored and follows standard page break logic.
 * TODO: (cont'd), (more), other fancy magic
 * @param {object} blockNode - current block node
 * @param {LastBlocks} lastBlocks - helper for managing previous block nodes
 * @returns {number|null} offset or null
 */
function getDialogueOffset(blockNode, lastBlocks) {
  // exit if no previous blocks
  if (lastBlocks.length === 0) {
    return null
  }
  const type = blockNode.type.name
  const acceptedTypes = [types.DIALOGUE, types.PARENTHETICAL]
  // exit if current block is not dialogue or paren
  if (!acceptedTypes.includes(type)) {
    return null
  }
  let prevType = lastBlocks.prevType(0)
  // if the last one was a character, return its offset
  if (prevType === types.CHARACTER) {
    return lastBlocks.prevOffset(0)
  }
  // exit if last type wasn't character, dialogue, or paren
  if (!acceptedTypes.includes(prevType)) {
    return null
  }
  // at this point we have two consecutive dialogue/paren blocks,
  // so we are looking two back to see if that's a character.
  // if it is, we return its offset, if not, we're done.
  prevType = lastBlocks.prevType(1)
  if (prevType === types.CHARACTER) {
    return lastBlocks.prevOffset(1)
  }
  // we're done
  return null
}
/**
 * Get total lines in a page.
 * Accepts a callback to call after each block is processed.
 * @param {ProseMirror.Node} page - page node
 * @param {string} format
 * @param {function} [forEachBlock] - callback for each block
 * @returns {number} total lines in this page according
 */
function getPageLines(page, format, forEachBlock) {
  if (!page) {
    return null
  }
  let pageLines = 0
  page.forEach((blockNode, offset, blockIndex) => {
    const lines = getBlockLines(blockNode, blockIndex, format)
    pageLines += lines
    if (forEachBlock) {
      forEachBlock({ blockNode, offset, lines, pageLines })
    }
  })
  return pageLines
}
/**
 * Calculate the number of lines in a given block.
 * If block is first in page (index is zero), lines may be different.
 * @param {object} blockNode - prosemirror node
 * @param {number} blockIndex - index of block in parent node (page)
 * @param {string} format
 * @returns {number} number of lines in block
 */
function getBlockLines(blockNode, blockIndex, format) {
  const type = blockNode.type.name
  if (type === types.DUAL_DIALOGUE) {
    // calculate size of both columns and return the higher one
    const columns = []
    blockNode.forEach((columnNode) => {
      let lines = 0
      columnNode.forEach((dualBlockNode, _, dualBlockIndex) => {
        // recurse!
        // pass block index plus dual block index to handle space before
        lines += getBlockLines(
          dualBlockNode,
          dualBlockIndex + blockIndex,
          format
        )
      })
      columns.push(lines)
    })
    return Math.max(columns[0], columns[1])
  }
  let chars = 0
  let lineBreaks = 0
  blockNode.forEach((inlineNode) => {
    // TODO: emoji! harder to detect, will def throw off character counts
    if (inlineNode.text) {
      chars += inlineNode.text.length
    } else if (inlineNode.type.name === types.HARD_BREAK) {
      lineBreaks++
    } else {
      console.error(`unknown inline node: ${inlineNode.type.name}`, inlineNode)
    }
  })
  if (chars === 0) {
    chars++
  }
  const spaceBefore = getSpaceBefore(type, blockIndex, format)
  const lineHeight = format[type].lineHeight
  const lines =
    lineBreaks +
    Math.ceil(chars / charsPerLine(type, format)) * lineHeight +
    spaceBefore
  return lines
}
function charsPerLine(type, format) {
  return format[type].width
}
/**
 * Get space before a given node type in number of lines.
 * NOTE: "space before" is a term used in FD to refer to the top margin above
 * an element measured in lines. I'm borrowing it hear for the sake of
 * consistency and clarity. Lines and CSS em values are interchangeable.
 * Adapted from CSS rules here:
 * https://github.com/showrunner/wombat/blob/master/scss/components/editor/_elements.scss
 * @param {string} nodeType - current node type
 * @param {number} blockIndex - index of block relative to page
 * @param {string} format - script format
 * @returns {number} space before in lines
 */
function getSpaceBefore(nodeType, blockIndex, format) {
  // beginning of page
  if (blockIndex === 0) {
    // we used to give top margin to NEW ACT only in first position but we don't anymore.
    return 0
  } else {
    return format[nodeType].blockTopMargin
  }
}
export default getSplitterFuction
