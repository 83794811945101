import { URLS_ANYWHERE } from '@util/constants'

function selectCommentById(state, commentId) {
  return state.comments.find((comment) => comment.id === commentId)
}
function selectReplyById(state, commentId) {
  return (
    state.comments
      // gather all active replies into a single array
      .reduce((acc, comment) => {
        acc = acc.concat(comment.replies)
        return acc
      }, [])
      // find by id (undefined if not found)
      .find((r) => r.id === commentId)
  )
}
function isEditingActive(state, comment) {
  const { isEditing } = state
  return isEditing != null && isEditing === comment.id
}
function isCommentEditable(state, comment) {
  return comment.text !== null && state.user.id === comment.createdBy
}
// this signature is atrocious
function autolink(state, commentText, isFeed) {
  const urls = commentText.match(URLS_ANYWHERE)
  if (urls) {
    urls.forEach((url) => {
      commentText = commentText.replace(
        url,
        `<a
        href=${url}
        target="_blank"
        rel="noopener noreferrer"
        class="${isFeed ? 'c-feed__link' : 'c-comment__link'}">${url}</a>`
      )
    })
  }
  return commentText
}
export { isEditingActive }
export { isCommentEditable }
export { selectCommentById }
export { selectReplyById }
export { autolink }
export default {
  isEditingActive,
  isCommentEditable,
  selectCommentById,
  selectReplyById,
  autolink,
}
