import { useState, Fragment } from 'react'
import { observer } from 'mobx-react-lite'
import { Container, Table } from '@mantine/core'
import { showError } from '@components/Modals'
import { Spinner } from '@components/Spinner'
import { useMst } from '@state'
import { IOrg } from '@state/types'
import { getAvatarUrl } from '@util'
import { UserRow } from './PermissionUserRow'
import styles from './PermissionMemberTable.module.scss'

export const PermissionMemberTable = observer(function PermissionMemberTable({
  permissionCode,
  org,
}: {
  permissionCode: string
  org: IOrg
}) {
  const { environment } = useMst()
  const [loading, setLoading] = useState(false)

  const data = org.alphabetizedMembers
    .filter((m) => m.permissions.includes(permissionCode))
    .map((user) => {
      return {
        id: user.id,
        name: user.name,
        email: user.email,
        image: getAvatarUrl(user.avatar, environment.config),
      }
    })

  if (data.length === 0) return <></>

  const revokePermission = async (userId: string) => {
    setLoading(true)
    try {
      await org.revokeMemberPermission({ userId, permissionCode })
    } catch (e) {
      showError({
        message: 'Failed to remove user permission',
      })
    }
    setLoading(false)
  }

  return (
    <Container className={styles.membertablecontainer}>
      {loading && <Spinner delayMs={300} />}
      <Table className={styles.membertable} verticalSpacing="sm">
        <Table.Tbody>
          {data.map((user) => (
            <Fragment key={user.id}>
              <UserRow user={user} onRevoke={revokePermission} />
            </Fragment>
          ))}
        </Table.Tbody>
      </Table>
    </Container>
  )
})
