import { Plugin, PluginKey } from 'prosemirror-state'
import { types } from '@showrunner/codex'
import { v4 as isUUID } from 'is-uuid'
import { v4 as uuid } from 'uuid'
const idMakerKey = new PluginKey('ID_MAKER')
/**
 * Makes sure every block always has ID.
 * @returns {Plugin} new plugin instance
 */
function idMakerPlugin() {
  return new Plugin({
    key: idMakerKey,
    appendTransaction(transactions, oldState, newState) {
      const last = transactions[transactions.length - 1]
      // exit early under the following conditions:
      // last transaction is from collab plugin
      // doc is unchanged
      if (last.getMeta('collab$') || oldState.doc.eq(newState.doc)) {
        return
      }
      const { tr, doc } = newState
      const ids = {}
      doc.descendants((node, pos) => {
        // skip pages
        if (node.type.name === types.PAGE) {
          return
        }
        // check for ID support
        // NOTE: attrs can be undefined (dual_dialogue_column)
        const blockSupportsIDs =
          node.type.spec.attrs && node.type.spec.attrs.id != null
        if (!blockSupportsIDs) {
          return
        }
        // verify new ID is needed
        // non-uuid ids snuck in before we landed
        // https://github.com/showrunner/codex/pull/92
        // the second check (lazily) cleans up the original mess.
        // given how hot this codepath is, we might run a DB query
        // in a month or two to see if its still needed.
        const blockHasID = node.attrs.id != null && isUUID(node.attrs.id)

        const isDuplicateID = blockHasID && ids[node.attrs.id]
        if (blockHasID && !isDuplicateID) {
          ids[node.attrs.id] = true // register ID for loop comparison
          if (node.isTextblock) {
            return false
          }
          return // return undefined for dual_dialogue to allow descending further
        }
        // generate new ID
        const attrs = { ...node.attrs }
        attrs.id = uuid()
        // second arg is node type, setting to null ensures it won't be changed
        tr.setNodeMarkup(pos, null, attrs)
        // don't descend into inline nodes
        if (node.isTextblock) {
          return false
        }
      })
      if (tr.docChanged) {
        return tr
      }
    },
  })
}
export { idMakerPlugin, idMakerKey }
