import { Menu } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { chainCommands } from 'prosemirror-commands'
import { lockedPageEnter } from '@choo-app/lib/editor/plugins/editor-keymap/enter'
import {
  insertDualDialogue,
  insertPageBreak,
} from '@choo-app/lib/editor/plugins/editor-keymap/commands'
import env from '@choo-app/lib/env'
import { CustomIcon } from '@components/CustomIcon'
import { FancyMenuItem } from '@components/FancyMenuItem'
import { Toolbar } from '@components/Toolbar'
import { useShortcuts, Keys } from '@hooks'
import { useMst, IPopulatedPmEditor } from '@state'
import { ButtonMode, ILoadedScript } from '@state/types'
import { setEditorBlockType } from '@util'
import { menuData } from './display-data'
import { BETA_ELEMENT_CHANGED } from '@util/mixpanel/eventNames'

export const ElementMenu = observer(function ElementMenu({
  script,
  observableEditor,
  mode,
}: {
  script: ILoadedScript
  observableEditor: IPopulatedPmEditor
  mode: ButtonMode
}) {
  const mst = useMst()

  const {
    editorView,
    editorState,
    focusEditor,
    selectionBlockType,
    selectionTouchesEndOfBlock,
    singleBlockTextSelection,
    editorViewFocused,
    rerender,
  } = observableEditor

  const { dispatch } = editorView

  const maxHeight = mst.view.dimensions.scriptScroller.height - 20
  const opened = script.elementMenuOpened

  const toggleMenu = () => {
    script.setElementMenuOpened(!opened)
    // if the user just closed the menu, refocus
    if (opened) focusEditor()
  }

  const menu = menuData[script.type]
  const curr = selectionBlockType
  // if a user copy/pastes a block type into a document that doesn't belong
  // we won't find it in the call below
  // this means we won't display an icon in the menu target, but this doesnt
  // they can't interact with the menu
  const meta = menu.find((i) => i.id === curr)
  const selectionSpansBlocks = !singleBlockTextSelection

  // we allow block changes when the editor is blurred if the menu is open
  // because its possible to launch it via keyboard shortcut instead of a click
  const disableBlockChanges = !editorViewFocused && !opened

  const tooltip = disableBlockChanges
    ? 'Click on a text block to enable'
    : 'Change block type'

  const targetLabel = selectionSpansBlocks
    ? 'Multiple'
    : disableBlockChanges
    ? 'Block type'
    : meta?.title

  // https://stackoverflow.com/questions/4215737/convert-array-to-object
  const elementShortcuts = menu.reduce(
    (a, v) => ({
      ...a,
      [v.id]: {
        keys: v.keys,
        action: () => updateBlockType(v.id, 'shortcut'),
        disabled: disableBlockChanges,
      },
    }),
    {}
  )

  const { getItemProps } = useShortcuts({
    menu: {
      keys: [Keys.CMD, '\\'],
      action: toggleMenu,
    },
    pageBreak: {
      keys: [Keys.CMD, Keys.ENTER],
      action: injectPageBreak,
      disabled: !editorViewFocused,
    },
    ...elementShortcuts,
  })

  function updateBlockType(
    blockType: string,
    source: 'element menu' | 'shortcut'
  ) {
    setEditorBlockType(editorState, dispatch, blockType)
    script.setElementMenuOpened(false)
    focusEditor()
    script.trackEvent(BETA_ELEMENT_CHANGED, { type: blockType, source })
    // ensure we take a fresh look at which block types have element numbers assigned
    rerender()
  }

  function injectPageBreak() {
    chainCommands(lockedPageEnter, insertPageBreak)(editorState, dispatch)
    focusEditor()
  }

  function injectDualDialogue() {
    insertDualDialogue(editorState, dispatch)
    focusEditor()
  }

  // we display a custom icon when:
  // 1. the selection is within a single block
  // 2. the menu isn't disabled
  // 3. a custom icon is actually present
  const useCustomIcon = !selectionSpansBlocks && !disableBlockChanges && meta

  return (
    <Menu
      shadow="md"
      closeOnEscape={true}
      opened={opened}
      onClose={() => {
        script.setElementMenuOpened(false)
        focusEditor()
      }}
    >
      <Menu.Target>
        <Toolbar.EditorFocusButton
          disabled={disableBlockChanges}
          dontRestoreFocus
          customIcon={useCustomIcon && <CustomIcon icon={meta.icon} />}
          icon={!useCustomIcon ? 'fa-layer-group' : undefined}
          tooltip={tooltip}
          onClick={toggleMenu}
          label={targetLabel}
          mode={mode}
          dropdown
          fixedWidth={mode === 'normal' ? 120 : 40}
          disableTooltip={opened}
        />
      </Menu.Target>
      <Menu.Dropdown>
        <div style={{ maxHeight }}>
          {menu.map(({ id, icon, title }) => {
            const selected = id === meta?.id
            const props = getItemProps(id as 'menu' /* yeesh 🙃 */)
            return (
              <FancyMenuItem
                key={id}
                customIcon={
                  <CustomIcon
                    icon={icon}
                    color={selected ? 'white' : 'dark.4'}
                  />
                }
                title={title}
                selected={selected}
                shortcut={props.shortcut}
                onClick={() => updateBlockType(id, 'element menu')}
                hideShortcut={env.browser.safari}
              />
            )
          })}
          <Menu.Divider />
          <FancyMenuItem
            customIcon={<CustomIcon icon="icon-page-break" />}
            title="Insert page break"
            {...getItemProps('pageBreak')}
          />
          {script.isScreenplay && (
            <FancyMenuItem
              customIcon={<CustomIcon icon="icon-dual-dialogue" />}
              title="Insert dual dialogue"
              onClick={injectDualDialogue}
              disabled={!selectionTouchesEndOfBlock}
            />
          )}
        </div>
      </Menu.Dropdown>
    </Menu>
  )
})
