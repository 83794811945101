import React from 'react'
import { useForm } from '@mantine/form'
import { UseFormInput } from '@mantine/form/lib/types'
import { StrengthResult, StrengthChecker } from './types'

interface UseStrongPasswordFormInput<FormValues extends Record<string, unknown>>
  extends UseFormInput<FormValues> {
  passwordField: keyof FormValues
  skipPasswordCheck?: boolean
}

let strengthChecker: StrengthChecker | null = null

const getZxcvbn = async () => {
  const zxcbv = await import('zxcvbn')
  strengthChecker = zxcbv.default
}

const checkStrength = (password: string): StrengthResult => {
  if (strengthChecker) {
    return strengthChecker(password)
  }

  getZxcvbn()
  return {
    score: 3,
    feedback: {
      warning: '',
      suggestions: [],
    },
  }
}

export const useStrongPasswordForm = <
  FormValues extends Record<string, unknown>
>(
  params: UseStrongPasswordFormInput<FormValues>
) => {
  const { passwordField, skipPasswordCheck, ...formProps } = params
  const [passwordStrength, setPasswordStrength] =
    React.useState<StrengthResult>(checkStrength(''))

  const form = useForm<FormValues>({
    ...formProps,
    validate: {
      ...formProps.validate,
      [passwordField]: (value: string) => {
        if (!skipPasswordCheck) {
          if (value.length === 0) {
            return 'This field is required'
          } else if (passwordStrength.score <= 2) {
            return 'Pick a stronger password'
          }
        }
      },
    } as UseFormInput<FormValues>['validate'],
  })

  const passwordValue = form.values[passwordField]

  React.useEffect(() => {
    if (typeof passwordValue === 'string') {
      setPasswordStrength(checkStrength(passwordValue))
    }
  }, [passwordValue])

  return {
    form,
    passwordStrength,
  }
}
