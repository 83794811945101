import { Toolbar } from '@components/Toolbar'
import { useToolbarBreakpoints } from '../SnapshotPage/useToolbarBreakpoints'

export const SlenePickerToolbarButton = ({
  sleneType,
  isComparing,
  onClick,
  disabled,
}: {
  onClick: () => void
  isComparing: boolean
  sleneType: 'slug' | 'scene'
  disabled?: boolean
}) => {
  const { buttonMode } = useToolbarBreakpoints()
  const label = `${isComparing ? 'Comparing' : 'Compare'} ${
    sleneType === 'scene' ? 'scenes' : 'slugs'
  }`

  return (
    <Toolbar.Button
      mode={buttonMode}
      disabled={disabled}
      icon="fa-arrow-down-left-and-arrow-up-right-to-center"
      label={label}
      active={isComparing}
      onClick={onClick}
    />
  )
}
