import * as Comlink from 'comlink'
import { utils } from '@showrunner/scrapi'

export type Compiler = utils.ink.Compiler

/*
  Returns a comlink Remote wrapper of a fresh CompileBundle instance.
  The instance executes on a different thread and all of its methods
  and properties are async, but otherwise it acts exactly like a local
  CompileBundle instance
*/

// lazy singleton-- We want 0 or 1 of these to exist in the lifetime of the app.
// If never requested, we can avoid downloading the inkjs bundle.
let remoteCompiler: Comlink.Remote<Compiler>
export const getRemoteCompiler = (): Comlink.Remote<Compiler> => {
  if (!remoteCompiler) {
    remoteCompiler = Comlink.wrap<Compiler>(
      new Worker(new URL('./worker.ts', import.meta.url))
    )
  }

  return remoteCompiler
}
