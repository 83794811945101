import { useState } from 'react'
import { useMst } from '@state'
import { OrgPayload } from '@util/ScriptoApiClient/types'
import { showAlert } from '@components/Modals'

export function MembersForm({ org }: { org: OrgPayload }) {
  const { apiClient } = useMst()
  const [removedUserIds, setRemovedUserIds] = useState<string[]>([])
  const removeMember = async (userId: string) => {
    try {
      await apiClient.removeOrgMemberByStaff({
        orgId: org.id,
        userId,
      })
      setRemovedUserIds([userId, ...removedUserIds])
      showAlert({
        title: 'Success',
        children: 'User removed from workspace',
      })
    } catch (e) {
      showAlert({
        title: 'Error',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        children: (e as any).message,
      })
    }
  }

  return (
    <form className="s-form s-form--inline">
      <table className="s-table">
        <thead>
          <tr className="s-table__row">
            <th className="s-table__header">Name</th>
            <th className="s-table__header">Role</th>
            <th className="s-table__header">Email</th>
            <td className="s-table__data">Remove?</td>
          </tr>
        </thead>
        <tbody>
          {org.members
            .filter((m) => !removedUserIds.includes(m.id))
            .map((member) => {
              const isOwner = member.id === org.owner.id
              const role = isOwner
                ? 'Owner'
                : member.isAdmin
                ? 'Admin'
                : 'Contributor'
              return (
                <tr className="s-table__row" key={member.id}>
                  <td className="s-table__data">{member.name}</td>
                  <td className="s-table__data">{role}</td>
                  <td className="s-table__data">{member.email}</td>
                  <td className="s-table__data">
                    {isOwner ? null : (
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => removeMember(member.id)}
                      >
                        🗑
                      </div>
                    )}
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
    </form>
  )
}
