import html from 'nanohtml'

function empty() {
  console.warn('no child nodes provided to app')
  return html`<div></div>`
}
/**
 * Application container.
 * @param  {function} children - function that produces child nodes
 * @return {HTMLElement}
 */
function app(children = empty) {
  return html` <div class="l-main">${children()}</div> `
}
export default app
