import { observer } from 'mobx-react-lite'
import { Accordion, Anchor, Badge, Grid, Group, Text } from '@mantine/core'
import { OrgOptionMap } from '@showrunner/codex'
import { useMst } from '@state'
import { FaIcon } from '@components/FaIcon'
import { Toast } from '@components/Toast'
import {
  PermissionConfig,
  CHANGE_SCRIPT_STATUS_CONFIG,
  MODIFY_LIMITED_SCRIPT_CONFIG,
  PUSH_TO_PROMPTER_CONFIG,
  UPDATE_RUNDOWNS_CONFIG,
  DESTROY_CONTENT_CONFIG,
} from './util'
import { NoPermissions } from './NoPermissions'
import { PermissionUserSelect } from './PermissionUserSelect'
import { PermissionMemberTable } from './PermissionMemberTable'
import { IOrgOptionModel } from '@state/types'

import styles from './PermissionSettings.module.scss'

const permissionsToInclude = (options: IOrgOptionModel[]) => {
  const permissionsToInclude: PermissionConfig[] = []

  const scriptLimiting = options.find(
    (o: IOrgOptionModel) => o.code === OrgOptionMap.SCRIPT_LIMITING
  )
  if (scriptLimiting) {
    const { enabled } = scriptLimiting
    permissionsToInclude.push(
      { ...CHANGE_SCRIPT_STATUS_CONFIG, enabled },
      { ...MODIFY_LIMITED_SCRIPT_CONFIG, enabled }
    )
  }

  const prompterIntegration = options.find(
    (o: IOrgOptionModel) => o.code === OrgOptionMap.PROMPTER_INTEGRATION
  )

  if (prompterIntegration) {
    const { enabled } = prompterIntegration
    permissionsToInclude.push({
      ...PUSH_TO_PROMPTER_CONFIG,
      enabled,
    })
  }

  const rundowns = options.find(
    (o: IOrgOptionModel) => o.code === OrgOptionMap.RUNDOWNS
  )

  if (rundowns) {
    const { enabled } = rundowns
    permissionsToInclude.push({
      ...UPDATE_RUNDOWNS_CONFIG,
      enabled,
    })
  }

  permissionsToInclude.push({
    ...DESTROY_CONTENT_CONFIG,
    enabled: true,
  })

  return permissionsToInclude
}

export const PermissionSettings = observer(function PermissionSettings() {
  const { currentOrg } = useMst()

  if (!currentOrg) return <NoPermissions />

  const permissions = permissionsToInclude(currentOrg.options)
  const permissionAccordions = permissions.map((permission) => {
    const memberInfo = permission.enabled ? (
      <>
        <PermissionUserSelect
          permissionCode={permission.code}
          org={currentOrg}
        ></PermissionUserSelect>
        <PermissionMemberTable
          permissionCode={permission.code}
          org={currentOrg}
        ></PermissionMemberTable>
      </>
    ) : (
      <Toast
        type="neutral"
        dismissable={false}
        message={
          <>
            <a href="mailto:help@scripto.live">Contact us&nbsp;</a>to enable
            this feature
          </>
        }
      ></Toast>
    )

    return (
      <Accordion
        classNames={{
          content: styles.accordion_content,
          item: styles.accordion_item,
        }}
        className={styles.accordion}
        key={permission.code}
      >
        <Accordion.Item value={permission.code}>
          <Accordion.Control>
            <Grid columns={6} align="center">
              <Grid.Col span={5}>
                <Group gap={5} align="center">
                  <Text size="md" fw="bold">
                    {permission.title}
                  </Text>
                  {permission.helpLink && (
                    <Anchor
                      href={permission.helpLink}
                      target="_blank"
                      rel="noreferrer"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <FaIcon size="14" icon="fa-circle-info" />
                    </Anchor>
                  )}
                </Group>
                <Text>{permission.description}</Text>
              </Grid.Col>
              <Grid.Col span={1} className="l-wrapper l-wrapper--justify-end">
                {!permission.enabled && (
                  <Badge size="md" radius="md" color="gray">
                    Disabled
                  </Badge>
                )}
              </Grid.Col>
            </Grid>
          </Accordion.Control>
          <Accordion.Panel className={styles.accordion_panel}>
            {memberInfo}
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    )
  })

  return (
    <div className={styles.permissionSettings_page}>
      <h1>Permissions</h1>
      {permissionAccordions}
    </div>
  )
})
