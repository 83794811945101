import { Group, Text } from '@mantine/core'
import { format } from 'date-fns'
import { capitalize } from '@util'
import { FaIcon } from '@components/FaIcon'
import { Toolbar } from '@components/Toolbar'
import {
  useSnapshotData,
  useScriptData,
} from '@components/SnapshotViews/useSnapshotQueries'
import { MiniHeader } from './MiniHeader'

export const SnapshotMiniHeader = ({
  scriptId,
  snapshotId,
}: {
  scriptId: string
  snapshotId: string
}) => {
  const snapshotQuery = useSnapshotData({ snapshotId, scriptId })
  const scriptQuery = useScriptData(scriptId)

  const title = scriptQuery.isSuccess
    ? scriptQuery.data.script.name
    : 'Snapshot'

  const subtitleParts: string[] = []
  if (snapshotQuery.isSuccess) {
    const name =
      snapshotQuery.data.name ?? snapshotQuery.data.autoSave ?? 'Snapshot'
    subtitleParts.push(capitalize(name))
    subtitleParts.push(
      format(new Date(snapshotQuery.data.createdAt), 'MMM d, yyyy - h:mm aa')
    )
  }

  return (
    <Toolbar>
      <MiniHeader.LeftSection>
        <Group gap="xs" wrap="nowrap">
          <FaIcon icon="fa-clock-rotate-left" />
          <Text fw="bold">{title}</Text>
          {subtitleParts.length > 0 && <Text>{subtitleParts.join(' - ')}</Text>}
        </Group>
      </MiniHeader.LeftSection>
    </Toolbar>
  )
}
