import { forwardRef } from 'react'
import { Avatar } from '@components/Avatar'
import { Avatar as MantineAvatar, Combobox, Group, Text } from '@mantine/core'
import { showAsyncConfirmModal } from '@components/Modals/AsyncConfirmModal'
import { IOrgMember } from '@state/types'

export const ROLES_DATA = ['Contributor', 'Admin']

export interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  image: string | undefined
  label: string
  description: string
  disabled: boolean
}

export const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ image, label, description, disabled, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group wrap="nowrap">
        <MantineAvatar radius="xl" src={image} />
        <div>
          <Text size="md">{label}</Text>
          <Text size="sm">{description}</Text>
        </div>
      </Group>
    </div>
  )
)

SelectItem.displayName = 'SelectItem'

export type SelectDataItem = {
  label: string
  value: string
  description: string
  image: string | undefined
  disabled: boolean
}

export const UserComboboxOption = ({ item }: { item: SelectDataItem }) => (
  <Combobox.Option value={item.value} key={item.value} disabled={item.disabled}>
    <Group wrap="nowrap">
      <Avatar avatarUrl={item.image} />
      <Text>
        {item.label}
        <br />
        <Text span c="dimmed" size="13">
          {item.description}
        </Text>
      </Text>
    </Group>
  </Combobox.Option>
)

export const filterOnLabelDescription = (
  search: string,
  options: SelectDataItem[]
) =>
  options.filter((item) => {
    return (
      item.label.toLowerCase().includes(search.toLowerCase().trim()) ||
      item.description.toLowerCase().includes(search.toLowerCase().trim())
    )
  })

export function openRemoveUserModal({
  member,
  isUserSelf = false,
  isOrgPrivateEnabled = true,
  onConfirm,
}: {
  member: IOrgMember
  isUserSelf?: boolean
  isOrgPrivateEnabled?: boolean
  onConfirm: (id: string) => Promise<void>
}) {
  const warning = isOrgPrivateEnabled ? (
    <Text>
      {isUserSelf ? 'You' : 'They'} will lose access to the workspace and any
      private scripts will be permanently deleted.
    </Text>
  ) : (
    <></>
  )

  showAsyncConfirmModal({
    title: 'Remove Member',
    children: (
      <>
        <Text>
          Are you sure you want to remove&thinsp;
          <strong>{isUserSelf ? 'yourself' : member.name}</strong>?
        </Text>
        {warning}
      </>
    ),
    confirmLabel: 'Remove',
    onConfirm: () => onConfirm(member.id),
    errorMessage: 'Failed to remove member',
    dangerous: true,
  })
}
