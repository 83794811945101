import React from 'react'
import { Button, Stack, TextInput, Title } from '@mantine/core'
import { useForm } from '@mantine/form'
import { NavAnchor } from '@components/NavAnchor'
import { useMst } from '@state'
import { ROUTE_PATTERNS } from '@util'
import { showAlert, showError } from '@components/Modals'

type CloneWorkspaceFormValues = {
  sourceId: string
  name: string
  ownerId: string
}

const displayError = (title: string, details: unknown) => {
  showError({
    title,
    message: 'See console for details',
  })

  // eslint-disable-next-line no-console
  console.error(details)
}

export const CloneWorkspace = () => {
  const { scrapi, switchOrgAndRelaunch, user } = useMst()

  const form = useForm<CloneWorkspaceFormValues>({
    initialValues: {
      name: '',
      sourceId: '',
      ownerId: '',
    },
  })

  const onSubmit = async ({
    sourceId,
    name,
    ownerId,
  }: CloneWorkspaceFormValues) => {
    const hasCustomOwner = ownerId.length > 0
    const ownerIdToUse = hasCustomOwner ? ownerId : user.id
    const workspaceUsers = [
      {
        userId: ownerIdToUse,
        isAdmin: true,
      },
    ]
    if (hasCustomOwner) {
      workspaceUsers.push({
        userId: user.id,
        isAdmin: true,
      })
    }

    try {
      const result = await scrapi.staff.cloneWorkspace({
        body: {
          ownerId: ownerIdToUse,
          workspaceUsers,
          sourceId,
          name: name.length > 0 ? name : undefined,
        },
      })

      if (result?.status === 201) {
        const { id } = result.body
        showAlert({
          title: 'Clone Success',
          children: (
            <Button onClick={() => switchOrgAndRelaunch(id)}>
              Go to the clone
            </Button>
          ),
        })
      } else {
        displayError(`Error: ${result?.status}`, result)
      }
    } catch (e) {
      displayError('Network error', e)
    }
  }

  return (
    <div>
      <Title order={1}>
        <NavAnchor href={ROUTE_PATTERNS.staffLanding}>Staff Zone</NavAnchor>
      </Title>

      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack>
          <TextInput
            label="Workspace ID to clone"
            required
            {...form.getInputProps('sourceId')}
          />
          <TextInput
            label="Name of the new workspace"
            description="Leave blank to use the same name"
            {...form.getInputProps('name')}
          />
          <TextInput
            label="ID of user to own the org"
            description="If you leave this blank, you will be the owner. If you populate this, you'll be a workspace admin"
            {...form.getInputProps('ownerId')}
          />
          <Button type="submit">Make it so</Button>
        </Stack>
      </form>
    </div>
  )
}
