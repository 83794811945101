import React from 'react'
import { EditorView } from 'prosemirror-view'
import { EditorState } from 'prosemirror-state'
import { createSnapshotEditorState, createComparisonEditorState } from '@util'
import {
  ScriptPayload,
  ScriptSnapshotPayload,
  ScriptFormatPayload,
} from '@util/ScriptoApiClient/types'
import { useElementDimensions } from '@hooks'
import { useFormatStyles } from '@hooks/useFormatStyles'
import { ScriptWrapper, ScriptAndToolbars } from '@layouts/Script'
import { handleClick } from '@choo-app/lib/editor/handleClick'

import styles from './SnapshotViewer.module.scss'

export function SnapshotViewerBase({
  format,
  editorState,
  pageless,
  noWrap,
  editorZoom = 1,
}: {
  editorZoom?: number
  pageless?: boolean
  noWrap?: boolean
  format: ScriptFormatPayload
  editorState: EditorState
}) {
  const hostDimensions = useElementDimensions()

  // store a ref to the PM editor view. We want to destroy it if the snapshot
  // or config changes
  const editorViewRef = React.useRef<EditorView | null>(null)
  const { setFormat } = useFormatStyles()
  setFormat(format)

  const ref = React.useCallback(
    (node: HTMLDivElement | null) => {
      // always destroy the editorView. This happens on unmount or
      // if we get a different snapshot or docType.
      if (editorViewRef.current) {
        editorViewRef.current.destroy()
      }
      // if node is not null, we either mounted or changed props, go
      // ahead and create a new editorView and attach.
      if (node) {
        editorViewRef.current = new EditorView(node, {
          state: editorState,
          attributes: {
            class: 'is-static',
          },
          editable: () => false,
          handleClick,
        })
      }
    },
    [editorState]
  )

  return (
    <div ref={hostDimensions.ref} className={styles.snapshotViewer}>
      <ScriptAndToolbars {...hostDimensions.dimensions} zoomLevel={editorZoom}>
        <ScriptWrapper pageless={pageless} noWrap={noWrap}>
          <div ref={ref} id="prosemirror-editor" />
        </ScriptWrapper>
      </ScriptAndToolbars>
    </div>
  )
}

export const StaticSnapshotViewer = ({
  snapshot,
}: {
  snapshot: Pick<ScriptSnapshotPayload, 'doc' | 'scriptFormat'>
}) => {
  const editorState = createSnapshotEditorState(snapshot)
  return (
    <SnapshotViewerBase
      editorState={editorState}
      format={snapshot.scriptFormat}
    />
  )
}

export const CompareSnapshotViewer = ({
  script,
  snapshot,
}: {
  script: Pick<ScriptPayload, 'doc' | 'scriptFormat'>
  snapshot: Pick<ScriptSnapshotPayload, 'doc'>
}) => {
  const editorState = createComparisonEditorState({
    script,
    snapshot,
  })
  return (
    <SnapshotViewerBase
      editorState={editorState}
      format={script.scriptFormat}
    />
  )
}
