import html from 'nanohtml'
import Nanocomponent from 'nanocomponent'
import { app, errorPage } from '../../components/index.js'
import { BETA_SCRIPT_CLOSED } from '@util/mixpanel/eventNames'

class EditorContainer extends Nanocomponent {
  createElement(state, emit) {
    this.state = state
    this.emit = emit

    return html`
      <div class="c-editor">
        <div class="c-editor__gutterleft"></div>
        <div class="c-editor__overlay"></div>
        <div class="l-box l-box--column c-editor-banner">
          <div id="editor"></div>
        </div>
        <div id="editor-gutter-right" class="c-editor__gutterright"></div>
      </div>
    `
  }
  update() {
    // never rerender the editor itself. The entire dom node will be destroyed
    // by react when we change or leave scripts
    return false
  }

  // When the node is unmounted from the dom, clean up prosemirror
  unload() {
    this.emit('editor:unload')
  }
}
export class ScriptView extends Nanocomponent {
  constructor() {
    super()
    this.editorContainer = new EditorContainer()
  }
  createElement(state, emit) {
    this.state = state
    this.emit = emit
    return app(() => {
      // we may have gotten an error back but it may be from a previous
      // script. Check and take the appropriate action
      const { err, errorId } = state.editor.script
      const requestedScriptId = state.mst.currentScript?.id
      // if the error is for the current script, show the error page
      if (err && requestedScriptId === errorId) {
        return errorPage(err.code)
      }
      // if not, the editor container will deal with getting the
      // right script loaded, even if the previous one errored
      return this.editorContainer.render(state, emit)
    })
  }
  update() {
    return true
  }
  unload() {
    // reset editor store after cleanup is done
    this.emit('editor:reset')
    // track script close
    this.emit('analytics:track', {
      name: BETA_SCRIPT_CLOSED,
    })
  }
}
