import Nanocomponent from 'nanocomponent'
class Dropdown extends Nanocomponent {
  constructor(options) {
    super()
    this.options = Object.assign(
      {
        button: '.o-dropdown__button',
        toggle: null, // null is stand in for top-level element of children
      },
      options || {}
    )
    // state
    this.state = { isOpen: false }
    // methods
    this._hide = this._hide.bind(this)
    this._toggle = this._toggle.bind(this)
  }
  createElement(children) {
    const button = children.querySelector(this.options.button)
    const toggle = this.options.toggle
      ? children.querySelector(this.options.toggle)
      : children
    button.addEventListener('click', this._toggle)
    if (this.state.isOpen) {
      toggle.classList.add('is-open')
    } else {
      toggle.classList.remove('is-open')
    }
    return children
  }
  update() {
    return true
  }
  unload() {
    this._hide()
  }
  _toggle(event) {
    event.preventDefault()
    event.stopPropagation()
    this.state.isOpen = !this.state.isOpen
    // add & remove outside click handler depending on state
    // NOTE: this will get removed on unload too
    if (this.state.isOpen) {
      document.querySelector('body').addEventListener('click', this._hide)
    } else {
      document.querySelector('body').removeEventListener('click', this._hide)
    }
    this.rerender()
  }
  _hide(event) {
    if (event && this.element) {
      const isInsideClick = this.element.contains(event.target)
      const isUnclickable =
        isInsideClick && event.target.classList.contains('is-unclickable')
      // mimicking macos dropdown behavior below
      // if it's unclickable, do nothing
      if (isUnclickable) {
        return
      }
      // if it's not an inside click, prevent default
      // (don't allow app actions when dropdown is open)
      if (!isInsideClick) {
        event.preventDefault()
        event.stopPropagation()
      }
      // if it is an inside click, and not unclickable, it's an action
      // when it's an action we want to allow default AND hide the dropdown
    }
    this.state.isOpen = false
    document.querySelector('body').removeEventListener('click', this._hide)
    // only rerender if component is mounted
    // necessary because this method might be called on unload
    if (this.element) {
      this.rerender()
    }
  }
}
export default Dropdown
