import React from 'react'
import { StaticSnapshotViewer } from '@components/SnapshotViews/SnapshotViewer'
import { FormatUtils } from '@showrunner/codex'
import { ScriptSnapshotPayload } from '@util/ScriptoApiClient/types'
import { LoadSnaphotForm } from './LoadSnapshotForm'
import { CreateFormatForm } from './CreateFormatForm'
import './FormatEditor.scss'

export const FormatEditor = () => {
  const [snapshot, setSnapshot] = React.useState<ScriptSnapshotPayload | null>(
    null
  )

  const applyModifiedDefinition = (
    definition: FormatUtils.ScriptConfiguration
  ) => {
    if (snapshot) {
      const newSnapshot = {
        ...snapshot,
        scriptFormat: {
          ...snapshot.scriptFormat,
          definition,
          id: new Date().toISOString(),
        },
      }
      setSnapshot(newSnapshot)
    }
  }

  return (
    <div className="c-format-editor">
      <div className="c-format-editor__tools">
        {!snapshot && <LoadSnaphotForm onSnapshotLoaded={setSnapshot} />}
        {snapshot && (
          <CreateFormatForm
            onApplyFormat={applyModifiedDefinition}
            currentFormat={snapshot.scriptFormat}
          />
        )}
      </div>
      <div className="snapshot-wrapper">
        {snapshot && <StaticSnapshotViewer snapshot={snapshot} />}
      </div>
    </div>
  )
}
