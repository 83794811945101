import { useMst } from '@state'
import { Anchor, Text } from '@mantine/core'
import { NavAnchor } from '@components/NavAnchor'

export const EmailInUse = () => {
  const { location, loggedIn } = useMst()

  return (
    <Text>
      This email is already in use.
      {!loggedIn && (
        <>
          <br />
          <NavAnchor href={location.pathWithCurrentSearch('/login')}>
            Try signing in?
          </NavAnchor>
        </>
      )}
    </Text>
  )
}

export const IncorrectOtp = ({ onClick }: { onClick?: () => void }) => {
  return (
    <Text>
      This code is expired.
      <br />
      <Anchor onClick={onClick}>Send a new one?</Anchor>
    </Text>
  )
}

export const StartOver = ({ onClick }: { onClick?: () => void }) => {
  return (
    <Text>
      For security reasons, this session
      <br />
      timed out. You need to&nbsp;
      <Anchor onClick={onClick}>start over.</Anchor>
    </Text>
  )
}

export const knownCodes = {
  EMAIL_ALREADY_IN_USE: 'EMAIL_ALREADY_IN_USE',
  OTP_EXPIRED_OR_USED: 'OTP_EXPIRED_OR_USED',
  INCORRECT_PASSCODE: 'INCORRECT_PASSCODE',
  SESSION_NOT_FOUND: 'SESSION_NOT_FOUND',
  WEAK_PASSWORD: 'WEAK_PASSWORD',
  INVALID_CREDENTIALS: 'INVALID_CREDENTIALS',
  RESET_PASSWORD: 'RESET_PASSWORD',
  TOO_MANY_REQUESTS: 'TOO_MANY_REQUESTS',
  UNDELIVERABLE_EMAIL: 'UNDELIVERABLE_EMAIL',
  USER_NOT_IN_STYTCH: 'USER_NOT_IN_STYTCH',
  BREACHED_PASSWORD: 'BREACHED_PASSWORD',
}

export type ServerErrorCode =
  | 'invalidCredentials'
  | 'noAccount'
  | 'mustResetPassword'
  | 'tooManyRequests'
  | 'undeliverableEmail'
  | 'breachedPassword'
  | 'unknown'

export const serverErrorMessages: Record<ServerErrorCode, React.ReactNode> = {
  invalidCredentials: 'Invalid credentials',
  noAccount: 'No account found with that email address',
  mustResetPassword: (
    <Text>
      For security reasons, you will need to&nbsp;
      <NavAnchor href="/forgot-password">reset your password</NavAnchor>
    </Text>
  ),
  tooManyRequests: (
    <Text>
      Uh oh! Too many login attempts. Please&nbsp;
      <NavAnchor href="/forgot-password">reset your password</NavAnchor>, or
      wait a while and try again.
    </Text>
  ),
  undeliverableEmail: 'This email address is unreachable',
  breachedPassword: 'Please choose a different password',
  unknown: 'An unknown server error occurred',
}

export const FormError = ({
  code,
  onClick,
}: {
  code?: string
  onClick?: () => void
}) => {
  if (code === knownCodes.EMAIL_ALREADY_IN_USE) {
    return <EmailInUse />
  }
  if (code === knownCodes.INCORRECT_PASSCODE) {
    return <Text>That code isn&apos;t valid. Try again!</Text>
  }
  if (code === knownCodes.OTP_EXPIRED_OR_USED) {
    return <IncorrectOtp onClick={onClick} />
  }
  if (code === knownCodes.SESSION_NOT_FOUND) {
    return <StartOver onClick={onClick} />
  }
  if (code === knownCodes.WEAK_PASSWORD) {
    return (
      <Text>
        Oh no! This password is on a list of logins that have been compromised
        by hackers. You&apos;ll have to use a different password.
      </Text>
    )
  }
  if (code === knownCodes.INVALID_CREDENTIALS) {
    return <Text>That&apos;s not your current password! Try again.</Text>
  }
  if (code === knownCodes.UNDELIVERABLE_EMAIL) {
    return <Text>{serverErrorMessages.undeliverableEmail}</Text>
  }
  if (code === knownCodes.BREACHED_PASSWORD) {
    return <Text>{serverErrorMessages.breachedPassword}</Text>
  }
  return <Text>An unknown error occurred</Text>
}
