import { Anchor, Button, Group, Stack, Text, Modal } from '@mantine/core'
import NiceModal from '@ebay/nice-modal-react'
import { useMst } from '@state'
import { useModalControls } from '@components/Modals'
import { defaultPrompterViewPrefs } from '@util/LocalPersistence'

export const LEARN_MORE_HREF = 'https://help.scripto.live/en/articles/7198624'

const PrompterViewInfoModal = NiceModal.create(() => {
  const { opened, onClose } = useModalControls()
  const { user } = useMst()

  const handleDismiss = () => {
    if (!user.prefs.prompterView) {
      user.updatePreferences({ prompterView: defaultPrompterViewPrefs })
      onClose()
    }
  }

  return (
    <Modal title="Prompter View " opened={opened} onClose={onClose} size="xs">
      <Group justify="center">
        <Stack>
          <Text>
            Prompter View is an experimental feature that lets you use your
            screen as a teleprompter.
          </Text>
          <Text>
            It&apos;s currently free to all workspaces. We&apos;re adding more
            bells and whistles and would LOVE to hear from you about how you
            think it should work.
          </Text>

          <Anchor
            target="_blank"
            rel="noopener noreferrer"
            href={LEARN_MORE_HREF}
            style={{ textDecoration: 'underline' }}
          >
            Learn more
          </Anchor>

          <Group align="center" justify="center">
            <Button variant="outline" onClick={handleDismiss}>
              Got It
            </Button>
          </Group>
        </Stack>
      </Group>
    </Modal>
  )
})

export const showPrompterViewInfo = () => {
  NiceModal.show(PrompterViewInfoModal)
}
