import { INavLink } from '@state/types'
// Helper function that gets an array of nav links, a moving item and
// a target item and decides if the moving item can be placed above the
// target item. We use this to configure the valid drop target UI
export const canPlaceAbove = ({
  links,
  movingId,
  targetId,
}: {
  links: INavLink[]
  movingId: string
  targetId: string
}): boolean => {
  const movingIndex = links.findIndex((item) => item.id === movingId)
  const targetIndex = links.findIndex((item) => item.id === targetId)
  return (
    // don't allow a link to be moved to precede itself
    targetIndex !== movingIndex &&
    // don't allow a link to be moved to precede its current successor
    targetIndex !== movingIndex + 1
  )
}
