import { FormattingButtons } from './Buttons'
import { FormattingMenu } from './Menu'
import { HyperlinkButton } from './Hyperlink'
import { StyleButtons } from './Style'
import { UndoRedo } from '../buttons/UndoRedo'
import { Toolbar } from '@components/Toolbar'
import { useMst } from '@state'
import { ButtonMode } from '@state/types'

export const FormattingOptions = ({
  mode = 'normal',
}: {
  mode: ButtonMode
}) => {
  const { currentScript } = useMst()

  // classic docs and ink scripts dont include any alignable block types
  const showAlignment = currentScript?.supportsBlockAlignment
  const showHyperlinks = currentScript?.supportsHyperlinks

  return mode === 'normal' ? (
    <FormattingButtons />
  ) : mode === 'mini' ? (
    <>
      <Toolbar.ButtonGroup spacing={0}>
        <Toolbar.Divider />
        <Toolbar.ButtonGroup spacing={0}>
          <UndoRedo />
        </Toolbar.ButtonGroup>
        <Toolbar.Divider />
        {showHyperlinks && <HyperlinkButton />}
        <StyleButtons />
        {showAlignment && <FormattingMenu mode={mode} />}
      </Toolbar.ButtonGroup>
    </>
  ) : (
    <FormattingMenu mode={mode} />
  )
}
