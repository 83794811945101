import { Card, Stack, Text } from '@mantine/core'
import { showUpgradeInquiryModal } from '@components/Modals'
import { IOrg } from '@state/types'

export const PlanInfo = ({ org }: { org: IOrg }) => {
  const card =
    org.tier === 'free' ? (
      <Card>
        <strong>Free plan</strong>
        <Text c="dimmed">Up to 3 workspace members</Text>
        <a onClick={() => showUpgradeInquiryModal(org)}>
          Contact us to discuss pricing for our paid plans
        </a>
      </Card>
    ) : (
      <Card>
        <strong>Paid plan</strong>
        <Text c="dimmed">Unlimited workspace members</Text>
        <a href="mailto:hello@scripto.live">Contact us about your account</a>
      </Card>
    )

  return (
    <Stack gap="sm">
      <Text fw="bold">Workspace plan</Text>
      {card}
    </Stack>
  )
}
