export type FaIconName = `fa-${string}`
const FA_ICON_SETS = ['fas', 'fa-regular', 'fa-kit', 'fa'] as const

export type FaIconSet = typeof FA_ICON_SETS[number]

export function isFaIcon(value: unknown): value is FaIconName {
  return typeof value === 'string' && value.trim().startsWith('fa-')
}

export function isFaIconSet(value: unknown): value is FaIconSet {
  return typeof value === 'string' && FA_ICON_SETS.includes(value as FaIconSet)
}

export const extractFaIconInfo = (
  value: unknown
): { iconName?: FaIconName; iconSet?: FaIconSet } => {
  const iconParts: string[] = typeof value === 'string' ? value.split(' ') : []
  const iconName = iconParts.find(
    (part) => !isFaIconSet(part) && isFaIcon(part)
  ) as FaIconName | undefined
  const iconSet = iconParts.find(isFaIconSet)

  return { iconName, iconSet }
}
