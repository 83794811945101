import { keymap } from 'prosemirror-keymap'
import { canSplit, liftTarget } from 'prosemirror-transform'
import {
  baseKeymap,
  chainCommands,
  createParagraphNear,
  splitBlock,
} from 'prosemirror-commands'
/**
 * Override for liftEmptyBlock to prevent an empty block causing a page split.
 * Only change is depth check of 2 instead of 1.
 * Original: https://github.com/ProseMirror/prosemirror-commands/blob/51a2f46ae40acb771c179b005836d3532fab99b3/src/commands.js#L267-L281
 * @param {object} state - PM view state
 * @param {function} dispatch - PM event dispatcher
 */
function liftEmptyBlock(state, dispatch) {
  const { $cursor } = state.selection
  if (!$cursor || $cursor.parent.content.size) {
    return false
  }
  if ($cursor.depth > 2 && $cursor.after() !== $cursor.end(-1)) {
    const before = $cursor.before()
    if (canSplit(state.doc, before)) {
      if (dispatch) {
        dispatch(state.tr.split(before).scrollIntoView())
      }
      return true
    }
  }
  const range = $cursor.blockRange()
  const target = range && liftTarget(range)
  if (target == null) {
    return false
  }
  if (dispatch) {
    dispatch(state.tr.lift(range, target).scrollIntoView())
  }
  return true
}
export function baseKeymapPlugin() {
  // Override default enter behavior for base keymap.
  // It's necessary to include this separately from the editor keymap,
  // as we want any behavior that doesn't get captured by the editor keymap
  // to fallthrough to this base keymap.
  // TODO: move baseKeymap from prosemirror-commands to this codebase,
  // so we have 100% control over all key bindings
  const keys = Object.assign({}, baseKeymap, {
    // drop newlineInCode, override liftEmptyBlock with our own implementation
    // TODO: may need to copy and modify splitBlock to cover cases when we want
    // to split a block on enter without duplicating all attributes
    // (e.g. element numbers)
    Enter: chainCommands(createParagraphNear, liftEmptyBlock, splitBlock),
  })
  return keymap(keys)
}
