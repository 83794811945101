import React from 'react'
import { useReactToPrint } from 'react-to-print'
import { showPrintOptions } from '@components/Modals'

// this is also in the css but doesn't seem to work properly
// unless we add it in again (maybe a chrome bug, or maybe we're clobbering
// it?)
const PAGE_STYLE = `
<style>
 @page {
   size: letter;
   margin: 0;
 }

 body {
   overflow: visible !important;
 }

 .o-page {
    height: 11in;
    padding: 1in 1in 1in 1.5in;
    position: relative; // needed for page numbering
    width: 8.5in;
 }

</style>
`

export const usePrintScriptInBrowser = () => {
  const headerText = React.useRef('')

  const printFn = useReactToPrint({
    content: () => document.getElementById('editor'),
    removeAfterPrint: true,
    print: async (iframeElt) => {
      if (iframeElt.contentWindow) {
        const { document } = iframeElt.contentWindow
        const pmRoot = document.querySelector('.ProseMirror')
        pmRoot?.setAttribute('contenteditable', 'false')
        pmRoot?.setAttribute('style', `--timestamp: '${headerText.current}'`)
        iframeElt.contentDocument?.head.insertAdjacentHTML(
          'beforeend',
          PAGE_STYLE
        )
        iframeElt.contentWindow.window.print()
      }
    },
  })

  // launch the modal to get custom header text and set
  // the ref value so react-to-print can use it
  const printInBrowser = () => {
    showPrintOptions({
      onPrint: (customHeader) => {
        headerText.current = customHeader
        printFn()
      },
    })
  }

  return {
    printInBrowser,
  }
}
