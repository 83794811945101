import { EditorState, TextSelection } from 'prosemirror-state'
import { canSplit } from 'prosemirror-transform'
import { Fragment, ResolvedPos } from 'prosemirror-model'
import { NodeTypeKey, schema, types } from '@showrunner/codex'

const TRAILING_SPACE = /^\s+$/
/**
 *
 * @param {object} $cursor - ResolvedPos.$cursor
 * @param {object} viewState - editor state
 * @return {number|null}
 */
function getTrimSize($cursor: ResolvedPos, viewState: EditorState) {
  const trimSize = $cursor.parent.content.size - $cursor.parentOffset
  if (trimSize === 0) {
    return null
  }
  const hasTrailingSpace = TRAILING_SPACE.test(
    viewState.tr.doc.textBetween($cursor.pos, $cursor.pos + trimSize)
  )
  if (!hasTrailingSpace) {
    return null
  }
  return trimSize
}
/**
 * Insert a new block of the given type on the next line,
 * if a bunch of arcane conditions are met.
 * @param {string} blockType - block type
 * @return {function} keydown handler
 */
function insertBlock(blockType: NodeTypeKey) {
  /**
   * Enter keydown handler.
   * @param {object} stateOrTr
   * @param {object} viewDispatch
   */
  return function handleEnter(viewState: EditorState, viewDispatch: Dispatch) {
    if (!(viewState.selection instanceof TextSelection)) return false

    // NOTE: the logic below was adapted from splitBlock in prosemirror-commands
    // it is very marijnh, so beware! you have been warned
    // ref: https://github.com/ProseMirror/prosemirror-commands/blob/bd923cc14f77df5e237525cc5e7d831b89204433/src/commands.js#L286-L316
    const { $from, $to, $cursor } = viewState.selection
    if (!$from.parent.isBlock) {
      return false
    }
    if (!$cursor) return false

    if (viewDispatch) {
      const atEnd = $to.parentOffset === $to.parent.content.size
      const trimSize = getTrimSize($cursor, viewState)

      if (!atEnd && !trimSize) {
        return false
      }

      const tr = viewState.tr
      if (viewState.selection instanceof TextSelection) {
        if (trimSize !== null) {
          tr.delete($cursor.pos, $cursor.pos + trimSize)
        } else {
          tr.deleteSelection()
        }
      }
      const type = schema.nodes[blockType]
      const parentAttrs = $from.parent.attrs

      // If the previous block is untimed, we want to make the new block
      // untimed as well.
      // Note: not all blocks respect the untimed
      // attr but by setting it, the ones that do will inherit it and the rest
      // will get stripped out by ProseMirror
      const attrs: { untimed?: boolean } = {}
      if (parentAttrs && parentAttrs.untimed === true) {
        attrs.untimed = true
      }

      const typesAfter = [{ type, attrs }]
      const can =
        canSplit(tr.doc, $from.pos, 1, typesAfter) ||
        canSplit(tr.doc, tr.mapping.map($from.pos), 1, typesAfter)

      if (can) {
        tr.split(tr.mapping.map($from.pos), 1, typesAfter)
        /*
          This next if clause is a bit of a mystery. It's doing a complicated check to see
          if it should change the type of the block BEFORE the cursor. It's unclear why we'd
          ever want to do that.  Rather than removing this whole clause and risking the unknown,
          we're just going to omit changing the type we hit enter after an empty block.
        */
        if (
          !$from.parentOffset && // i.e. $from is at 0 relative to parent
          !atEnd && // combined with the above, this means that parent is NOT empty
          $from.parent.type !== type &&
          $from
            .node(-1)
            .canReplace(
              $from.index(-1),
              $from.indexAfter(-1),
              Fragment.from(type.create())
            )
        ) {
          tr.setNodeMarkup(tr.mapping.map($from.before()), type)
        }
      }

      viewDispatch(tr.scrollIntoView())
    }
    return true
  }
}
// TODO: handle wrapping existing dialogue in dual container
function insertDualDialogue(viewState: EditorState, viewDispatch: Dispatch) {
  const { $from, $to } = viewState.selection
  if (!$from.parent.isBlock) {
    return false
  }
  if (viewDispatch) {
    // NOTE: not sure why this is needed, this is from some internal PM stuff
    const atEnd = $to.parentOffset === $to.parent.content.size
    if (!atEnd) {
      return false
    }
    const tr = viewState.tr
    const type = viewState.schema.nodes[types.DUAL_DIALOGUE]
    tr.replaceRangeWith($from.pos, $to.pos, type.createAndFill())

    // creating a text selection that is two positions further than where we
    // started, which should be the first character node
    const modifiedSelection = TextSelection.findFrom(
      tr.doc.resolve($from.pos + 2),
      1,
      true
    )

    if (modifiedSelection) {
      tr.setSelection(modifiedSelection)
    }

    viewDispatch(tr.scrollIntoView())
  }
  return true
}
// insert hard break (inline node) in current block
function insertHardBreak(state: EditorState, dispatch: Dispatch) {
  dispatch(
    state.tr
      .replaceSelectionWith(schema.nodes[types.HARD_BREAK].create())
      .scrollIntoView()
  )
  return true
}
/**
 * Split an existing block and insert a pagebreak where the active selection begins.
 * If the active selection starts at a block opening and that block isnt the first one
 * on the current page, we delete the empty orphaned block after inserting the new page
 * @param {object} viewState - PM view state
 * @param {function} [viewDispatch] - PM event dispatcher
 * @returns {Transaction} PM transaction
 */
function insertPageBreak(viewState: EditorState, viewDispatch?: Dispatch) {
  const { selection, tr } = viewState
  const { $from } = selection
  const { pos, depth } = $from

  const typesAfter = [
    {
      type: viewState.schema.nodes[types.PAGE],
      attrs: { dynamic: false },
    },
    // preserve the current block type and attributes
    {
      type: $from.parent.type,
      attrs: $from.parent.attrs,
    },
  ]

  // if the beginning of the selection opens a block and stepping to the
  // left leaves the cursor at the same depth on the same page
  // there will be an orphan we can clean up
  let priorPos
  if ($from.parentOffset === 0) {
    const $prior = tr.doc.resolve(pos - 2)
    if ($prior.depth === depth && $from.node(1).eq($prior.node(1))) {
      priorPos = $prior.pos
    }
  }

  // FIXME: canSplit is returning a false negative when we include typesAfter.
  // Need to find root cause then file a bug report.
  // Caution: canSplit and split PM methods are very difficult to debug.
  // Nate's investigation seems to indicate that canSplit and split are not
  // treating the typesAfter argument the same way.
  if (!canSplit(tr.doc, pos, depth)) {
    // Seems typesAfter array is treated differently in canSplit vs split.
    // eslint-disable-next-line no-console
    console.error(
      `insertPageBreak failed: cannot split at ${pos} (depth: ${depth})`
    )
    return false
  }
  let nextTr = tr.split(pos, depth, typesAfter)

  // in transactions with multiple steps we generally wrap
  // positions in tr.mapping.map() to ensure that they still
  // refer to the location in the document we expect.
  // we skip that intentionally here because the block we want
  // to delete didn't exist until *after* tr.split() was called
  if (priorPos) {
    nextTr = nextTr.replace(priorPos, pos)
  }

  nextTr = nextTr.scrollIntoView()

  // Check whether doc conforms to schema, and raise error when they do not.
  // TODO: Catch this and do something with it? Haven't raised an error yet.
  nextTr.doc.check()
  // return true for keydown handler if dispatch is present
  if (viewDispatch) {
    viewDispatch(nextTr)
    return true
  }
  // return transaction if this being used outside a keydown handler context
  return nextTr
}
/**
 * Insert a page break and a new act.
 * @param {object} viewState - PM view state
 * @param {function} [viewDispatch] - PM event dispatcher
 * @returns {Transaction} PM transaction
 */
function insertNewAct(viewState: EditorState, viewDispatch: Dispatch) {
  if (!(viewState.selection instanceof TextSelection)) return
  const { $cursor } = viewState.selection
  const tr = insertPageBreak(viewState)
  if (!$cursor || typeof tr === 'boolean') return
  // TODO: skip page break if cursor is in first element of page
  // (only relevant when selecting new act from element menu)
  // if ($cursor.index(1) !== 0) {
  //   tr = insertPageBreak(viewState)
  //   // catch error state and exit
  //   if (tr === false) return false
  // } else {
  //   tr = viewState.tr
  // }
  const pos = tr.mapping.map($cursor.pos)
  tr.setBlockType(pos, pos, schema.nodes[types.NEW_ACT])
  if (viewDispatch) {
    viewDispatch(tr)
    return true
  }
  return tr
}

export {
  insertBlock,
  insertDualDialogue,
  insertHardBreak,
  insertPageBreak,
  insertNewAct,
}
