import { useState, FormEvent } from 'react'
import { observer } from 'mobx-react-lite'
import {
  Card,
  Stack,
  Text,
  TextInput,
  Group,
  Space,
  Button,
} from '@mantine/core'
import { email } from '@sideway/address'
import { useMst } from '@state'
import {
  showAlert,
  showError,
  showStytchChangeEmailModal,
} from '@components/Modals'

export const ProfileUpdater = observer(function ProfileUpdater({
  setLoading,
}: {
  setLoading: (val: boolean) => void
}) {
  const { user } = useMst()

  const [userData, setUserData] = useState<{
    canSend: boolean
    name: string
    email: string
  }>({
    canSend: false,
    name: user.name,
    email: user.email,
  })

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)
    const isValidEmail = email.isValid(userData.email)
    try {
      if (!isValidEmail) throw Error()

      const name = userData.name !== user.name ? userData.name : undefined
      const email = userData.email !== user.email ? userData.email : undefined

      await user.update({ name, email })

      setUserData({
        canSend: false,
        email: userData.email,
        name: userData.name,
      })
      showAlert({
        title: 'Success',
        children: 'Your details have been updated',
      })
    } catch (e) {
      const message = isValidEmail
        ? 'Failed to update your details'
        : 'The email address you entered is not valid'
      showError({ message })
    }
    setLoading(false)
  }

  return (
    <form onSubmit={submit}>
      <Stack>
        <Card>
          <label htmlFor="email">
            <Text fw="bold">Email address</Text>
          </label>
          {user.isStytchUser ? (
            <Group justify="space-between">
              <Text>{user.email}</Text>
              <Button variant="subtle" onClick={showStytchChangeEmailModal}>
                Edit
              </Button>
            </Group>
          ) : (
            <TextInput
              type="email"
              value={userData.email}
              required
              onChange={(e) =>
                setUserData({
                  canSend:
                    user.name !== userData.name ||
                    user.email !== e.target.value,
                  email: e.target.value,
                  name: userData.name,
                })
              }
            />
          )}
        </Card>
        <Card>
          <Stack gap="xs">
            <label htmlFor="name">
              <Text fw="bold">Name</Text>
            </label>
            <TextInput
              value={userData.name}
              required
              onChange={(e) =>
                setUserData({
                  canSend:
                    user.name !== e.target.value ||
                    user.email !== userData.email,
                  email: userData.email,
                  name: e.target.value,
                })
              }
            />
          </Stack>
          <Space h="xl" />
          <Group>
            <Button
              type="submit"
              disabled={!userData.canSend}
              value="Save Changes"
            >
              Save changes
            </Button>
          </Group>
        </Card>
      </Stack>
    </form>
  )
})
