import * as Plugins from './plugins'
import { ScriptContext, PluginConfig, PluginFactory } from './plugins/types'

const STANDARD_PLUGINS: Array<PluginConfig> = [
  Plugins.meta,
  Plugins.collabUpdate,
  Plugins.characterAutocomplete,
  Plugins.elementMenu,
  Plugins.inputRules,
  Plugins.editorKeymap,
  Plugins.baseKeymap,
  Plugins.ensureNewline,
  Plugins.pageBreaker,
  Plugins.remoteCursor,
  Plugins.placeholder,
  Plugins.comments,
  Plugins.lockedPageNumbers,
  Plugins.idMaker,
  // element numbers needs idMaker
  Plugins.elementNumbers,
  // margin info needs to go after pagination
  // and element numbers
  Plugins.marginInfoPlugin,
  Plugins.marginSlotsPlugin,
  Plugins.historyPlugin,
  Plugins.recordSelectionPlugin,
]

export const INK_PLUGINS: Array<PluginConfig> = [
  Plugins.meta,
  Plugins.collabUpdate,
  Plugins.characterAutocomplete,
  Plugins.inkInputRules,
  Plugins.inkKeymap,
  Plugins.baseKeymap,
  Plugins.remoteCursor,
  Plugins.comments,
  Plugins.idMaker,
  Plugins.indentationStyles,
  Plugins.historyPlugin,
  Plugins.recordSelectionPlugin,
]

const storageKey = (pluginKey: string) => `omit-plugin:${pluginKey}`
const enablePlugin = (
  scriptContext: ScriptContext,
  pluginConfig: PluginConfig
) => {
  if (pluginConfig.liveEditorOnly && !scriptContext.isLiveEditor) {
    return false
  }
  if (pluginConfig.requiresWriteAccess && !scriptContext.userHasWriteAccess) {
    return false
  }
  if (pluginConfig.classicFlagOnly && !scriptContext.hasClassicFlag) {
    return false
  }
  if (window.localStorage.getItem(storageKey(pluginConfig.key))) {
    return false
  }
  return true
}

export const getLiveEditorPlugins = (scriptContext: ScriptContext) => {
  const result: PluginFactory[] = []
  const applicablePlugins = scriptContext.isInk ? INK_PLUGINS : STANDARD_PLUGINS
  applicablePlugins.forEach((pluginConfig) => {
    if (enablePlugin(scriptContext, pluginConfig)) {
      result.push(pluginConfig.plugin)
    }
  })
  return result
}

export const getPluginsByKey = (keys: string[]) => {
  const result: PluginFactory[] = []
  STANDARD_PLUGINS.forEach((pluginConfig) => {
    if (keys.includes(pluginConfig.key)) {
      result.push(pluginConfig.plugin)
    }
  })
  return result
}
