import React from 'react'
import cn from 'classnames'
import { Anchor, AnchorProps } from '@mantine/core'
import styles from './OutsideAnchor.module.scss'

type OutsideAnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement> &
  AnchorProps & {
    children: string
    target?: React.HTMLAttributeAnchorTarget
    rel?: string
  }

export const OutsideAnchor = React.forwardRef<
  HTMLAnchorElement,
  OutsideAnchorProps
>(
  (
    { children, target = '_blank', rel = 'noopener noreferrer', ...props },
    ref
  ) => (
    <Anchor {...props} ref={ref} target={target} rel={rel}>
      <span>
        {children}
        <i
          className={cn(
            'fas fa-arrow-up-right-from-square',
            styles.outsideAnchor_icon
          )}
        />
      </span>
    </Anchor>
  )
)

OutsideAnchor.displayName = 'OutsideAnchor'
