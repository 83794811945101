import { types } from 'mobx-state-tree'
import { BaseModel } from './BaseModel'

export const PrompterSegment = BaseModel.named('PrompterPushSegment')
  .props({
    isZerothSlug: types.boolean,
    hasChanged: types.boolean,
    hasText: types.boolean,
    lastPushedAt: types.maybeNull(types.string),
    slugTitle: types.string,
    snapshotSlugId: types.number,
    // rundown only
    originalSlugTitle: types.maybe(types.string),
    isSingleSlug: types.maybe(types.boolean),

    // mutable
    push: false,
  })
  .views((self) => ({
    get hasBeenPushed(): boolean {
      return self.lastPushedAt !== null
    },
    get shouldPush(): boolean {
      return self.push
    },
    // we flag both nameless slugs and preslug content
    // that is followed by a genuine slug
    get isUnnamed(): boolean {
      const noTitle =
        self.originalSlugTitle !== undefined
          ? self.originalSlugTitle === ''
          : self.slugTitle === ''

      const isAlone = self.isSingleSlug !== undefined && self.isSingleSlug

      return noTitle && !isAlone
    },
  }))
  // sync
  .actions((self) => ({
    setShouldPush(push: boolean) {
      self.push = push
    },
    afterAttach() {
      //initial setup
      if (!self.hasBeenPushed || self.hasChanged) {
        this.setShouldPush(true)
      }
    },
  }))
