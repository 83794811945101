import { useMst } from '@state'
import { useQuery } from '@tanstack/react-query'
import { Loader } from '@components/Loader'
import { useNavigation } from '@hooks'
import { noop } from '@util'

export const OAuthCallbackPage = () => {
  const { choo, location } = useMst()
  const { navigate } = useNavigation()
  const slackToken = location.getQueryParam('code')
  const scriptId = location.getQueryParam('state')

  useQuery({
    queryKey: ['retrieve_slack_params', { scriptId, slackToken }],
    staleTime: Infinity,
    queryFn: async () => {
      if (slackToken && scriptId) {
        await choo.addScriptToSlack({ slackToken, scriptId }).catch(noop)
      }
      // we redirect whether the handshake is completed/fails/aborted
      navigate(`/scripts/${scriptId}`)
    },
  })

  return <Loader fullScreen />
}
