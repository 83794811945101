import React from 'react'
import cn from 'classnames'
import { ButtonMode, IRemoteUser } from '@state/types'
import { Avatar, Group, Menu, Text, Tooltip } from '@mantine/core'
import { Toolbar } from '@components/Toolbar'
import { pluralize } from '@util'
import { jumpToCollaborator, getMaxCollaborators } from './helpers'

import styles from './Collaborators.module.scss'

export const OverflowCollaborators = ({
  containerHeight,
  collaborators,
  mode,
}: {
  containerHeight: number
  collaborators: IRemoteUser[]
  mode: ButtonMode
}) => {
  const [opened, setOpened] = React.useState(false)
  const totalCollaborators = collaborators.length
  const overflow = totalCollaborators - getMaxCollaborators(mode)
  const tooltip = `${totalCollaborators} ${pluralize(
    totalCollaborators,
    'collaborator'
  )}`
  // dont show half an avatar (38 for each row, 4 for padding)
  const maxHeight =
    containerHeight > 0 ? Math.floor(containerHeight / 38) * 38 + 4 : '70vh'

  return (
    <Menu
      position="bottom"
      withArrow
      shadow="md"
      opened={opened}
      onChange={setOpened}
      width={200}
    >
      <Menu.Target>
        <Tooltip disabled={opened} label={tooltip}>
          <Toolbar.Button label={`+${overflow}`} fixedWidth={50} dropdown />
        </Tooltip>
      </Menu.Target>
      <Menu.Dropdown style={{ maxHeight }}>
        {collaborators
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((u) => (
            <Menu.Item key={u.clientId} onClick={() => jumpToCollaborator(u)}>
              <Group align="center" wrap="nowrap" gap={3}>
                <Avatar
                  src={u.avatar}
                  style={{
                    border: `2px solid ${u.color}`,
                  }}
                  className={cn(
                    styles.collaborators_avatar,
                    styles.collaborators_menuAvatar
                  )}
                  radius="xl"
                  size="sm"
                />
                <Text size="sm">{u.name}</Text>
              </Group>
            </Menu.Item>
          ))}
      </Menu.Dropdown>
    </Menu>
  )
}
