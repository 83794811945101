import { types } from 'mobx-state-tree'
import {
  CollectionId,
  IFolder,
  SplitEditorPref,
  isCollectionId,
} from '@state/types'
import { BaseModel } from '../BaseModel'
import { Folder } from '../Folder'
import { ExplorerState } from './ExplorerState'
import { DimensionsModel } from './Dimensions'
import { RundownScriptLayout } from '@util/LocalPersistence'

type RequestedDocument = {
  id: string
  type: DocumentListingType
}

export const ReadonlyFolderListState = BaseModel.named(
  'ReadonlyFolderListState'
)
  .props({
    rootId: types.string,
    expandedFolders: types.array(types.string),
    disabledFolderId: types.maybe(types.string),
    selectedFolderId: '',
  })
  .views((self) => ({
    isExpanded(folder: IFolder) {
      return self.expandedFolders.includes(folder.id)
    },
    isDisabled(folder: IFolder) {
      return self.disabledFolderId === folder.id
    },
  }))
  .actions((self) => ({
    setSelectedFolderId(folderId: string) {
      self.selectedFolderId = folderId
    },
    toggleFolderExpanded(folder: IFolder) {
      if (self.expandedFolders.includes(folder.id)) {
        self.expandedFolders.remove(folder.id)
      } else {
        self.expandedFolders.push(folder.id)
      }
    },
    collapseAllFolders() {
      self.expandedFolders.replace([])
    },
    setFolderExpanded(folder: IFolder, expanded: boolean) {
      const currentlyExpanded = self.isExpanded(folder)
      if (currentlyExpanded !== expanded) {
        this.toggleFolderExpanded(folder)
      }
    },
  }))

/*
  This is the long-term home for mapping state -> url and for describing
  the view model that should be displayed.

  In the short term, we don't handle urls or manage app navigation directly,
  instead the ChooWrapper calls us to notify us of route changes.
*/
export const View = BaseModel.named('View')
  .props({
    explorerState: types.optional(ExplorerState, {}),
    // set when a document is requested and cleared when it's
    // loaded. We use this to figure out if we need to auto-navigate
    // in the folder browser after a script or rundown loads vs letting the user
    // control the folder browser
    requestedDocument: types.maybe(types.frozen<RequestedDocument>()),

    selectedFolderId: '',

    folderCreatingChildId: types.maybe(types.string),

    folderBeingRenamedId: types.maybe(types.string),

    // When we're showing a modal with
    readonlyFolderListState: types.maybe(ReadonlyFolderListState),

    // these are the folders expanded in the folder browser. By having safe references,
    // they just get removed from the array if they're destroyed.
    expandedFolders: types.array(types.safeReference(Folder)),

    debugFlags: types.array(types.string),

    dimensions: types.optional(DimensionsModel, {}),
  })
  .views((self) => ({
    get currentFolder(): IFolder | undefined {
      return self.rootStore.folderMap.get(self.selectedFolderId)
    },
    getFolder(folderId: string): IFolder | undefined {
      return self.rootStore.folderMap.get(folderId)
    },
    getFolderName(folderId: string): string | undefined {
      const folder = self.rootStore.folderMap.get(folderId)
      if (folder?.isRootFolder && !folder.isPrivate) return 'Shared'
      if (folder?.isRootFolder && folder.isPrivate) return 'Private'
      return folder?.name
    },
    isFolderExpanded(folder: IFolder): boolean {
      return self.expandedFolders.includes(folder)
    },
    isDebugEnabled(key: string): boolean {
      return self.debugFlags.includes(key.toLowerCase())
    },
    // any time there's a debug flag, we return true for devMode.
    // This is best used for features that are not annoying for
    // devs to see a lot (vs big behavioral changes)
    get devMode(): boolean {
      return self.debugFlags.length > 0
    },
    get debugSync() {
      return this.isDebugEnabled('sync')
    },
    get useScrapi() {
      return self.debugFlags.includes('scrapi')
    },
    get splitLayout(): SplitEditorPref {
      return self.rootStore.user.prefs.splitLayout?.layout ?? 'rows'
    },
    get rundownScriptLayout(): RundownScriptLayout {
      return (
        self.rootStore.user.prefs.splitLayout ?? {
          layout: 'rows',
          enabled: false,
        }
      )
    },
    isRequestedDocument(data: RequestedDocument): boolean {
      return (
        !!self.requestedDocument &&
        self.requestedDocument.type === data.type &&
        self.requestedDocument.id === data.id
      )
    },
    get editorZoom(): number {
      const pref = self.rootStore.user.prefs.editorZoom
      // avoid a divide-by-zero zoom situation
      return pref && pref > 0 ? pref : 1
    },
    get isZoomed(): boolean {
      return this.editorZoom !== 1
    },
    get selectedFolderIsCollection(): boolean {
      return isCollectionId(self.selectedFolderId)
    },
    get shouldUnwatermark(): boolean {
      return this.isDebugEnabled('unwatermark')
    },
    get showClassicSearchResults(): boolean {
      return (
        this.isDebugEnabled('archive') ||
        !!self.rootStore.currentOrg?.betaFlags.includes('search-archive')
      )
    },
  }))
  .actions((self) => ({
    setEditorZoom(value: number) {
      if (value > 0) {
        self.trackEvent(self.MIXPANEL_EVENTS.SET_ZOOM_LEVEL, {
          zoomLevel: value * 100,
        })
        self.rootStore.user.updatePreferences({ editorZoom: value })
      }
      self.rootStore.currentScript?.pmEditor.rerender()
    },
    updateRundownScriptLayout(value: Partial<RundownScriptLayout>) {
      self.rootStore.user.updatePreferences({
        splitLayout: {
          ...self.rundownScriptLayout,
          ...value,
        },
      })
    },
    initializeReadonlyFolderState(root: IFolder, disabledFolderId?: string) {
      self.readonlyFolderListState = ReadonlyFolderListState.create({
        rootId: root.id,
        disabledFolderId,
      })
      self.readonlyFolderListState.setFolderExpanded(root, true)
      return self.readonlyFolderListState
    },
    setRequestedDocument(item: RequestedDocument | undefined) {
      self.requestedDocument = item
    },
    /*
      When we are setting a selected folder ID we need to do some fancy
      footwork to deal with the fact that the shared and private trash roots
      are displayed together in the UI.
    */
    setSelectedFolderId(folderId: string) {
      const { sharedTrash, privateTrash } = self.rootStore.rootFolders
      const isPrivateTrashRoot = folderId === privateTrash?.id
      const isSharedTrashRoot = folderId === sharedTrash?.id

      // if the folderId is the private trash root, we actually want to mark the
      // shared trash root as selected because that's what's displayed in the folder browser
      const folderIdToSelect =
        isPrivateTrashRoot && sharedTrash ? sharedTrash.id : folderId

      // Now update the selected folder
      if (folderIdToSelect !== self.selectedFolderId) {
        self.folderCreatingChildId = undefined
        self.selectedFolderId = folderIdToSelect
      }

      if (isCollectionId(folderId)) {
        if (folderId === 'recentlyEdited') {
          self.rootStore.refreshRecentListings()
        } else if (folderId === 'favorites') {
          self.rootStore.refreshFavoriteListings()
        }
      } else {
        // if the selected folder ID is for either trash root, we want to refresh both
        if (isPrivateTrashRoot || isSharedTrashRoot) {
          if (privateTrash) self.rootStore.refreshFolder(privateTrash.id)
          if (sharedTrash) self.rootStore.refreshFolder(sharedTrash.id)
        } else {
          self.rootStore.refreshFolder(folderIdToSelect)
        }
      }
    },
    setSelectedCollectionId(collectionId: CollectionId) {
      self.folderCreatingChildId = undefined
      self.selectedFolderId = collectionId
    },
    setCreatingChild(value: boolean) {
      const selectedFolder = self.rootStore.folderMap.get(self.selectedFolderId)
      if (selectedFolder && value) {
        self.folderCreatingChildId = selectedFolder.id
        if (!self.isFolderExpanded(selectedFolder)) {
          self.expandedFolders.push(selectedFolder)
        }
      } else {
        self.folderCreatingChildId = undefined
      }
    },
    startRenamingFolder(folderId: string) {
      self.folderBeingRenamedId = folderId
    },
    stopRenamingFolder() {
      self.folderBeingRenamedId = undefined
    },
    // if the user navigated to a /folders/:folderId route we run this
    // after the folder tree is loaded
    handleFoldersLoaded() {
      const { requestedDocument } = self
      if (requestedDocument) {
        self.requestedDocument = undefined
        if (!self.selectedFolderId) {
          this.expandFolderPath(requestedDocument.id)
          this.setSelectedFolderId(requestedDocument.id)
        }
      }
    },
    handleDocumentLoaded({
      id,
      folderId,
      type,
    }: {
      id: string | number
      folderId: string
      type: DocumentListingType
    }) {
      if (self.requestedDocument) {
        const matchesRequest =
          type === self.requestedDocument.type &&
          String(id) === self.requestedDocument.id
        if (matchesRequest) {
          self.requestedDocument = undefined
          if (!self.selectedFolderId) {
            this.setSelectedFolderId(folderId)
            this.expandFolderPath(folderId)
          }
        }
      }
      self.analytics.trackDocOpened(type, id)
    },
    goToSearch(term: string) {
      self.rootStore.location.setPathname(`/search?q=${term}`)
    },
    toggleFolderExpanded(folder: IFolder) {
      if (self.expandedFolders.includes(folder)) {
        self.expandedFolders.remove(folder)
      } else {
        self.expandedFolders.push(folder)
      }
    },
    collapseAllFolders() {
      self.expandedFolders.replace([])
    },
    setFolderExpanded(folder: IFolder, expanded: boolean) {
      const currentlyExpanded = self.isFolderExpanded(folder)
      if (currentlyExpanded !== expanded) {
        this.toggleFolderExpanded(folder)
      }
    },
    // expand all the folders above a folder. If the folder has subfolders,
    // expand it as well.
    expandFolderPath(folderId: string) {
      const folders = self.rootStore.getFolderPath(folderId)
      folders.forEach((f) => {
        if (self.isFolderExpanded(f)) {
          return
        }
        if (f.id !== folderId || f.childFolders.length > 0) {
          self.expandedFolders.push(f)
        }
      })
    },
    updateDebugFlags() {
      if (self.rootStore.user.staff) {
        const rawFlags = self.rootStore.location.getQueryParam('debug')
        if (typeof rawFlags === 'string') {
          self.debugFlags.replace(rawFlags.split(','))
        }
      }
    },
  }))
