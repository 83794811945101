import { Radio, Stack } from '@mantine/core'
import { usePendingSearchContext } from '../SearchContext'

import styles from './IncludeRadio.module.scss'

const TITLE_ONLY = 'Document titles only'
const ANY_MATCHING = 'Any matching text'

export const IncludeRadio = () => {
  const context = usePendingSearchContext()
  return (
    <Stack gap={6}>
      <Radio.Group
        classNames={{ label: styles.includeRadio_label }}
        label="Include in search"
        value={
          context?.pendingCriteria.includeTitleOnly ? TITLE_ONLY : ANY_MATCHING
        }
        onChange={() => {
          context?.mergePendingCriteria({
            includeTitleOnly: !context?.pendingCriteria.includeTitleOnly,
          })
        }}
      >
        <Stack gap={6}>
          <Radio value={ANY_MATCHING} label={ANY_MATCHING} />
          <Radio value={TITLE_ONLY} label={TITLE_ONLY} />
        </Stack>
      </Radio.Group>
    </Stack>
  )
}
