import cn from 'classnames'
import React from 'react'
import { CloseButton, Group } from '@mantine/core'
import * as ToastifyLib from 'react-toastify'
import { nanoid } from 'nanoid'
import styles from './Toast.module.scss'
import { TOAST_HOST_ID, TOAST_ID } from './constants'

type ToastHostId = ValueOf<typeof TOAST_HOST_ID>
type DismissableToastId = ValueOf<typeof TOAST_ID>

type LaunchToastProps = {
  message: string | React.ReactNode
  type?: 'neutral' | 'warning' | 'success' | 'error'
  onDismiss?: () => void
  autoCloseDelayMs?: number
  icon?: string
  dismissable?: boolean
  children?: React.ReactNode
  id?: string
  containerId: ToastHostId
}

type ToastComponentProps = Pick<
  LaunchToastProps,
  'icon' | 'type' | 'dismissable' | 'onDismiss' | 'message' | 'children'
>

export function Toast(props: ToastComponentProps) {
  const { icon, type = 'neutral', dismissable = true } = props

  const [dismissed, setIsDismissed] = React.useState(false)

  const handleDismiss = () => {
    setIsDismissed(true)
    const { onDismiss } = props
    if (onDismiss) onDismiss()
  }

  if (dismissed) return <></>

  const classNames = cn(styles.toast, styles[`toast___${type}`])
  return (
    <div className={classNames}>
      <div className={styles.toast_message}>
        {icon && (
          <div className={styles.toast_icon}>
            <i className={icon}></i>
          </div>
        )}
        {props.message}
      </div>
      <Group wrap="nowrap">
        <div className={styles.toast_action}>{props.children}</div>
        {dismissable && (
          <CloseButton
            className={styles.toast_button}
            onClick={handleDismiss}
            variant="transparent"
          ></CloseButton>
        )}
      </Group>
    </div>
  )
}

export const dismissToast = ({ id }: { id: DismissableToastId }) => {
  ToastifyLib.toast.dismiss(id)
}

const launchToast = (props: LaunchToastProps) => {
  const { autoCloseDelayMs, id, containerId, ...toastProps } = props
  const toastId = id ?? nanoid()
  const canDismiss = toastProps.dismissable !== false

  const handleDismiss = () => {
    if (canDismiss) {
      ToastifyLib.toast.dismiss(toastId)
    }
  }

  ToastifyLib.toast(() => <Toast {...toastProps} onDismiss={handleDismiss} />, {
    toastId,
    containerId,
    autoClose: autoCloseDelayMs,
  })
}

export const launchScriptToast = ({
  id,
  message,
  type = 'error',
  autoDismiss = false,
  dismissable = true,
}: {
  message: string
  id?: string // if undefined, a guid will be assigned instead
  type?: 'warning' | 'error'
  autoDismiss?: boolean
  dismissable?: boolean
}) => {
  launchToast({
    containerId: 'script-notifications',
    icon: 'fa fa-exclamation-circle',
    id,
    message: <div dangerouslySetInnerHTML={{ __html: message }}></div>,
    type,
    dismissable,
    autoCloseDelayMs: autoDismiss ? 6000 : undefined,
  })
}

export const launchExplorerToast = (
  props: Omit<LaunchToastProps, 'containerId'>
) => launchToast({ ...props, containerId: TOAST_HOST_ID.FOLDER_EXPLORER })
