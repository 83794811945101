import { io, Socket } from 'socket.io-client'
import { EmittedEvents, ReceivedEvents } from './events'

export const createSocket = (apiUrl: string, getSocketToken: () => string) =>
  io(`${apiUrl}/v1`, {
    transports: ['websocket'],
    reconnectionDelay: 250,
    reconnectionDelayMax: 5000,
    timeout: 5000,
    // we are creating the instance at launch without a token
    // so we set autoconnect to false, then we'll call connect
    // later when we have a token
    autoConnect: false,
    auth: (cb) => {
      const socketToken = getSocketToken()
      cb({ socketToken })
    },
  }) as Socket<ReceivedEvents, EmittedEvents>
