import {
  BETA_ELEMENT_CHANGED,
  EDITOR_WRITING_ACTIVITY,
} from '@util/mixpanel/eventNames'

function getScriptId(state) {
  return state.mst.currentScript?.id ?? ''
}

export function /**
 * Editor event to track script element changes.
 * Delegates to analytics store.
 * NOTE: Using editor store to trigger this so we don't have to pass app
 * state all the way into the editor plugins.
 */
elementChange({ data, state, emitter }) {
  const scriptId = getScriptId(state)
  if (!data || !data.type || !data.source) {
    emitter.emit(
      'log:error',
      'elementChange event emitted without a script type. Script id: ',
      scriptId
    )
  } else {
    const opts = {
      type: data.type,
      source: data.source,
      scriptId,
    }
    emitter.emit('analytics:track', { name: BETA_ELEMENT_CHANGED, opts })
  }
}

export function trackScriptActivity({ state, emitter }) {
  const scriptId = getScriptId(state)
  const user = state.mst.user
  const opts = {
    scriptId,
    name: user.name,
    email: user.email,
  }
  emitter.emit('analytics:track', { name: EDITOR_WRITING_ACTIVITY, opts })
}
