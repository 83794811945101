import { Step } from 'prosemirror-transform'
import { schema } from '@showrunner/codex'
import { DatadogClient } from '@util/datadog'
import { GetStepsPayload } from '@util/ScriptoApiClient/configs/steps'

const ddLog = DatadogClient.getInstance()

const isConsecutiveInt = (v: number, i: number, arr: number[]) => {
  const isInt = Number.isInteger(v)
  if (!isInt) {
    return false
  }
  if (i === 0) {
    return true
  }
  return v === arr[i - 1] + 1
}
/**
 * When we get steps from the server, validate that we should even try to apply
 * them. Log to datadog about invalid steps
 */
export const validateConfirmedSteps = (
  stepData: GetStepsPayload,
  localServerVersion: number
) => {
  // compare steps to current version
  const stepVersions = stepData.map(({ version }) => version)
  // This is normal- we got back an async response for GetSteps that's no
  // longer the thing we need
  if (stepVersions[0] !== localServerVersion) {
    return false
  }
  const validVersions = stepVersions.every(isConsecutiveInt)
  if (!validVersions) {
    const message = 'applyConfirmedSteps called with non-consecutive versions'
    const details = {
      localVersion: localServerVersion,
      stepVersions: stepVersions,
    }
    ddLog.warn(message, details)
    return false
  }
  return true
}
export const convertStepData = (stepData: GetStepsPayload) => {
  const steps = stepData.map(({ step }) => Step.fromJSON(schema, step))
  const collabIds = stepData.map(({ clientId }) => clientId)
  return { steps, collabIds }
}
