import * as codex from '@showrunner/codex'
import { EditorState } from 'prosemirror-state'
import { formatSeconds } from '@util'

export type FormattedTiming = {
  total: string
  bracket: string
  dialogue: string
}

export type FormattedSlugTiming = FormattedTiming & {
  title: string
  isBlank: boolean
  isZeroth: boolean
}

type FormattedTimingSummary = {
  timing: FormattedTiming
  selectionTiming?: FormattedTiming
  slugTiming: FormattedSlugTiming[]
}

const formatTimeData = ({
  bracketSeconds,
  dialogueSeconds,
}: {
  bracketSeconds: number
  dialogueSeconds: number
}): FormattedTiming => {
  return {
    total: formatSeconds(bracketSeconds + dialogueSeconds),
    dialogue: formatSeconds(dialogueSeconds),
    bracket: formatSeconds(bracketSeconds),
  }
}

export const getTimingInfo = (
  editorState: EditorState,
  readRate: number
): FormattedTimingSummary => {
  const scriptBreakdown = new codex.util.ScriptBreakdown(
    editorState.doc,
    readRate
  )
  const timingData = scriptBreakdown.timing

  // overall timing
  const timing: FormattedTiming = formatTimeData({
    bracketSeconds: timingData.bracketSeconds,
    dialogueSeconds: timingData.dialogueSeconds,
  })

  const result: FormattedTimingSummary = {
    timing,
    slugTiming: [],
  }

  // iterate through the timing data for each slug
  timingData.slugs.forEach((slugTiming) => {
    // we only want to include the zeroth slug if it has
    // timing > 0 (this is existing logic but maybe it would be more
    // correct to include it any time there are brackets or dialogue in it?)
    if (slugTiming.isZerothSlug && slugTiming.totalSeconds === 0) {
      return
    }
    const formattedTiming = formatTimeData(slugTiming)
    const rawTitle = slugTiming.isZerothSlug
      ? 'before first slug'
      : slugTiming.title.trim()
    const isBlank = rawTitle.length === 0
    const title = isBlank ? 'slug' : rawTitle

    result.slugTiming.push({
      ...formattedTiming,
      title,
      isBlank,
      isZeroth: slugTiming.isZerothSlug,
    })
  })

  // finally process the selection if there is one
  const { selection } = editorState
  if (selection && !selection.empty) {
    const selectionBreakdown = new codex.util.FragmentBreakdown({
      fragment: selection.content().content,
      readRate,
      countExcludedTiming: true,
    })
    result.selectionTiming = formatTimeData(selectionBreakdown.timing)
  }

  return result
}
