import { useMst } from '@state'
import { IView } from '../state/types'
import { jiggle } from '@util/animation'

const folderNodeSelector = (folderId: string) =>
  `[data-folder-listing="${folderId}"]`
const docNodeSelector = (docId: string | number) =>
  `[data-doc-listing="${docId}"]`

export const locateDocumentInExplorer = ({
  id,
  folderId,
  view,
}: {
  id: string
  folderId: string
  view: IView
}) => {
  view.explorerState.setCrosshairsDocRequest(id)
  view.explorerState.setCrosshairsFolderRequest(folderId)

  // switch to the folder explorer view and ensure
  // both sections are expanded
  view.explorerState.expandFolderView()

  // Select the appropriate folder and expand the path
  if (folderId !== view.currentFolder?.id) {
    view.setSelectedFolderId(folderId)
    view.expandFolderPath(folderId)
  }

  // if we can find the folder and doc listing in the dom, jiggle them
  jiggle(folderNodeSelector(folderId))
  jiggle(docNodeSelector(id))
}

export const useCurrentDocLocator = (type: 'rundown' | 'script') => {
  const { view, currentRundown, currentScript } = useMst()
  const activeDoc = type === 'rundown' ? currentRundown : currentScript

  const locate = () => {
    if (!activeDoc) {
      view.explorerState.clearCrosshairsDocRequest()
      view.explorerState.clearCrosshairsFolderRequest()
      return
    }

    const { folderId } = activeDoc
    const id = String(activeDoc.id)
    locateDocumentInExplorer({
      id,
      folderId,
      view,
    })
  }

  return {
    locate,
    enabled: !!activeDoc,
  }
}
