import cn from 'classnames'
import { Divider, Group, Stack, Text } from '@mantine/core'
import { FormattedSlugTiming } from 'src/choo-app/lib/editor/plugins/meta/timing'
import { TimingBreakdown } from './TimingBreakdown'

import styles from './SlugTiming.module.scss'

export const SlugTiming = ({
  slugTimings,
  maxHeight,
}: {
  slugTimings: Array<FormattedSlugTiming>
  maxHeight: number
}) => {
  const formattedSlugs = slugTimings.map((slug, idx: number) => {
    const classNames = cn(styles.slugTiming_item, {
      [styles.slugTiming_item___blank]: slug.isBlank,
    })
    const itemClassNames = cn(styles.slugTiming_item___total, {
      [styles.slugTiming_item___blank]: slug.isBlank,
    })

    const title = slug.isBlank ? 'SLUG' : slug.title.toUpperCase()

    return (
      <Stack
        key={String(idx) + '-' + title}
        gap={5}
        pt={0}
        pb={5}
        className={classNames}
      >
        <Stack gap={5} px={10} pt={5} className={styles.slugTiming_stack}>
          <Group justify="space-between" wrap="nowrap">
            <Text className={itemClassNames}>{title}</Text>
            <Text>{slug.total}</Text>
          </Group>
          <Divider c="gray.3" />
          <TimingBreakdown dialogue={slug.dialogue} bracket={slug.bracket} />
        </Stack>
      </Stack>
    )
  })

  return (
    <div style={{ maxHeight }} className={styles.slugTiming_scroll}>
      {formattedSlugs}
    </div>
  )
}
