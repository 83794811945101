import Nanocomponent from 'nanocomponent'
import html from 'nanohtml'
import { getDOMNodeAtPos, safelyGetOffset } from '../../prose-utils.js'
const AVATAR_BASE_OFFSET = -12
const getAvatarOffset = (view, headPosition) =>
  safelyGetOffset({
    node: getDOMNodeAtPos(view, headPosition),
    includeParent: true,
    includeGrandparent: true,
    caller: 'remote-cursor',
  }) + AVATAR_BASE_OFFSET
/**
 * Nanocomponent for a single avatar element in the left gutter. We create and destroy
 * the DOM element as users join/leave the script, after that we just modify position and
 * visibility directly on the dom to optimize rendering performance
 */
class RemoteAvatar extends Nanocomponent {
  constructor({ clientId, top, imageUrl, color, hide, name }) {
    super()
    // user data (doesn't change)
    this.clientId = clientId
    this.imageUrl = imageUrl
    this.color = color
    this.name = name
    // dynamic
    this.top = top
    this.hide = hide
  }
  createElement() {
    return html` <div
      id="remote-user-${this.clientId}"
      style="${this.getStyle(false)}"
      class="o-dot o-hovertooltip"
      data-tooltip=${this.name}
    >
      <img src=${this.imageUrl} style="background-color: white" />
    </div>`
  }
  update({ top, hide }) {
    const changed = this.top !== top || this.hide !== hide
    if (this.element && changed) {
      // animate if move is small & cursor was already on screen
      const animate = !this.hide && Math.abs(this.top - top) < 1000
      this.top = top
      this.hide = hide
      this.element.style = this.getStyle(animate)
    }
    return false
  }
  getStyle(animate) {
    const styleParts = [`top: ${this.top}px`, `background-color: ${this.color}`]
    if (animate) {
      styleParts.push('transition: top 0.25s ease-in-out')
    }
    if (this.hide) {
      styleParts.push('opacity: 0')
      styleParts.push('pointer-events: none')
    }
    return styleParts.join(';')
  }
}
/**
 * Nanocomponent for the whole set of avatar elements in the left gutter. We create and destroy
 * this when creating/destroying the editor. During the editors lifetime we add/remove and update the
 * individual child nodes by direct DOM manipulation.
 */
class RemoteAvatars extends Nanocomponent {
  constructor({ editorView, mst }) {
    super()
    this.editorView = editorView
    this.mst = mst
    this.children = {}
  }
  // nanocomponent html creation
  createElement() {
    return html`<div class="avatarContainer"></div>`
  }
  update(cursors) {
    // remove the DOM elements for avatars which no longer have cursors
    const idsToRemove = Object.keys(this.children).filter(
      (clientId) => !cursors.find((c) => c.clientId === clientId)
    )
    idsToRemove.forEach((clientId) => {
      const avatar = this.children[clientId]
      if (avatar && avatar.element) {
        avatar.element.remove()
      }
      delete this.children[clientId]
    })
    // add or update the rest
    cursors.forEach((cursor) => {
      const { clientId, anchorPosition, headPosition, name, color, imageUrl } =
        cursor

      const hide =
        (anchorPosition < 2 && headPosition < 2) ||
        this.mst.user.prefs.hideCollabAvatars
      const top = getAvatarOffset(this.editorView, headPosition)
      // create o new avatar or update an existing one
      const existingAvatar = this.children[cursor.clientId]
      if (existingAvatar) {
        existingAvatar.update({ top, hide })
      } else {
        const newAvatar = new RemoteAvatar({
          clientId,
          top,
          color,
          hide,
          name,
          imageUrl,
        })
        this.children[clientId] = newAvatar
        this.element.appendChild(newAvatar.render())
      }
    })
    return false
  }
}
export { RemoteAvatars }
export default {
  RemoteAvatars,
}
