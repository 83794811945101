import { Plugin } from 'prosemirror-state'
import { pageBreakerKey } from './constants.js'
import { getRepaginateFunction } from './repaginate.js'
/**
 * Creates a new page breaker plugin
 * @returns {Plugin} new plugin instance
 */
function pageBreakerPlugin({ appState }) {
  return new Plugin({
    key: pageBreakerKey,
    /**
     * Breaks a document into dynamic pages on each transaction, as needed.
     * ref: http://prosemirror.net/docs/ref/#state.PluginSpec.appendTransaction
     * @param {array} transactions - array of transactions
     * @param {EditorState} oldState - old editor state
     * @param {EditorState} newState - new editor state
     * @returns {Transaction|null} pagination transaction or null
     */
    appendTransaction(transactions, oldState, newState) {
      const last = transactions[transactions.length - 1]
      // exit early under the following conditions:
      // last transaction is from page breaker plugin
      // last transaction is from collab plugin
      // doc is unchanged
      if (
        last.getMeta(pageBreakerKey) ||
        last.getMeta('collab$') ||
        oldState.doc.eq(newState.doc)
      ) {
        return
      }
      // TODO: once we always know the format we can stop falling back
      // on type (api change needed)
      const { scriptFormat } = appState.editor.script
      return getRepaginateFunction(scriptFormat.definition.blocks)(
        newState,
        oldState
      )
    },
  })
}
export { getRepaginateFunction }
export { pageBreakerPlugin }
export { pageBreakerKey }
export default {
  getRepaginateFunction,
  pageBreakerPlugin,
  pageBreakerKey,
}
