import { Fragment } from 'react'
import { ScrollArea, Table } from '@mantine/core'
import { InviteTableRow } from './InviteTableRow'
import { MemberTableRow } from './MemberTableRow'
import { IOrg } from '@state/types'

import styles from './MemberTable.module.scss'

export const MemberTable = ({ workspace }: { workspace: IOrg }) => (
  <ScrollArea className={styles.membertablescroll}>
    <Table
      verticalSpacing="sm"
      classNames={{
        th: styles.membertable_th,
        tbody: styles.membertable,
      }}
    >
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Name</Table.Th>
          <Table.Th>Role</Table.Th>
          <Table.Th></Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {workspace.openInvites.map((i) => (
          <Fragment key={i.id}>
            <InviteTableRow invite={i} workspace={workspace} />
          </Fragment>
        ))}
        {workspace.alphabetizedMembers.map((m) => (
          <Fragment key={m.id}>
            <MemberTableRow member={m} />
          </Fragment>
        ))}
      </Table.Tbody>
    </Table>
  </ScrollArea>
)
