import createStore from 'choo-store'
import { DatadogClient } from '@util/datadog'
import {
  EDITOR_EVENTS,
  SOCKET_STATUS_EVENTS,
} from '@state/models/SocketManager/types'
import { launchScriptToast } from '@components/Toast'

const ddLog = DatadogClient.getInstance()

function getInitialState() {
  return {
    scriptId: null,
  }
}
// this helper function makes sure app.editorManager is present and that the local script's id
// matches the one on the event. If so, it executes the callback, otherwise it logs warnings to
// datadog
function callEditorManagerForScript({ app, scriptId, cb, operation }) {
  const { editorManager } = app
  const emScriptId = editorManager.scriptId
  const editorManagerHasScript = !!editorManager.scriptId
  if (emScriptId !== scriptId) {
    ddLog.warn('editor manager script mismatch', {
      operation,
      editorManagerHasScript,
      emScriptId,
      messageScriptId: scriptId,
    })
    return
  }
  cb(editorManager)
}

export default createStore({
  storeName: 'io',
  initialState: getInitialState(),
  events: {
    [SOCKET_STATUS_EVENTS.CONNECT]({ app }) {
      app.editorManager.onReconnect()
    },
    [SOCKET_STATUS_EVENTS.DISCONNECT]({ state, app }) {
      state.io = getInitialState()
      app.editorManager.onDisconnect()
    },
    [EDITOR_EVENTS.JOIN_SCRIPT]({ app, data, state, emitter }) {
      const { scriptId } = data
      app.io.emit(EDITOR_EVENTS.JOIN_SCRIPT, scriptId, (data) => {
        const { version, users } = data
        state.io.scriptId = scriptId
        emitter.emit('editor:setScript', { users })
        callEditorManagerForScript({
          app,
          scriptId,
          operation: EDITOR_EVENTS.JOIN_SCRIPT,
          cb: (em) => em.setServerVersion(version, scriptId),
        })
      })
    },
    [EDITOR_EVENTS.LEAVE_SCRIPT]({ app, data, state }) {
      const { scriptId } = data
      state.io.scriptId = null // set to null before ack
      app.io.emit(EDITOR_EVENTS.LEAVE_SCRIPT, scriptId)
    },
    [EDITOR_EVENTS.UPDATE_CURSOR]({ app, data }) {
      app.io.volatile.emit(EDITOR_EVENTS.UPDATE_CURSOR, data)
    },
    // listeners
    // future join/leave implementation
    // [eventTypes.USER_JOINED] ({ app, data }) {
    //   if (app.editorManager) app.editorManager.onUserJoined(data)
    // },
    // [eventTypes.USER_LEFT] ({ app, data }) {
    //   if (app.editorManager) app.editorManager.onUserLeft(data)
    // },
    [EDITOR_EVENTS.SCRIPT_UPDATED]({ data, app }) {
      // dont ask choo to redraw the header, script nav etc.
      // each and every time a remote edit is detected
      callEditorManagerForScript({
        app,
        scriptId: data.scriptId,
        operation: EDITOR_EVENTS.SCRIPT_UPDATED,
        cb: (em) => em.onScriptVersionUpdated(data, 'socket'),
      })
    },
    [EDITOR_EVENTS.CURSOR_UPDATED]({ app, data }) {
      callEditorManagerForScript({
        app,
        scriptId: data.scriptId,
        operation: EDITOR_EVENTS.CURSOR_UPDATED,
        cb: (em) => em.onCursorUpdated(data),
      })
    },
    [EDITOR_EVENTS.COMMENT_ADDED]({ data, app }) {
      Object.assign(data, { eventType: EDITOR_EVENTS.COMMENT_ADDED })
      callEditorManagerForScript({
        app,
        scriptId: data.scriptId,
        operation: EDITOR_EVENTS.COMMENT_ADDED,
        cb: (em) => em.onCommentEvent(data),
      })
    },
    [EDITOR_EVENTS.COMMENT_UPDATED]({ data, app }) {
      Object.assign(data, { eventType: EDITOR_EVENTS.COMMENT_UPDATED })
      callEditorManagerForScript({
        app,
        scriptId: data.scriptId,
        operation: EDITOR_EVENTS.COMMENT_UPDATED,
        cb: (em) => em.onCommentEvent(data),
      })
    },
    [EDITOR_EVENTS.COMMENT_RESOLVED]({ data, app }) {
      Object.assign(data, { eventType: EDITOR_EVENTS.COMMENT_RESOLVED })
      callEditorManagerForScript({
        app,
        scriptId: data.scriptId,
        operation: EDITOR_EVENTS.COMMENT_RESOLVED,
        cb: (em) => em.onCommentEvent(data),
      })
    },
    [EDITOR_EVENTS.COMMENT_UNRESOLVED]({ data, app }) {
      Object.assign(data, { eventType: EDITOR_EVENTS.COMMENT_UNRESOLVED })
      callEditorManagerForScript({
        app,
        scriptId: data.scriptId,
        operation: EDITOR_EVENTS.COMMENT_UNRESOLVED,
        cb: (em) => em.onCommentEvent(data),
      })
    },
    [EDITOR_EVENTS.COMMENT_DELETED]({ data, app }) {
      Object.assign(data, { eventType: EDITOR_EVENTS.COMMENT_DELETED })
      callEditorManagerForScript({
        app,
        scriptId: data.scriptId,
        operation: EDITOR_EVENTS.COMMENT_DELETED,
        cb: (em) => em.onCommentEvent(data),
      })
    },
    // v0
    [EDITOR_EVENTS.userEvent]({ app, data, emitter }) {
      if (data.users) {
        emitter.emit('editor:setScript', { users: data.users })
      }
      app.editorManager.onUserEvent(data)
    },
    // Auth error message handler
    [EDITOR_EVENTS.AUTH_ERROR]({ data }) {
      const msg =
        typeof data === 'string'
          ? 'auth error: ' + data
          : 'You are not authorized to view this document.'
      launchScriptToast({
        type: 'error',
        message: msg,
      })
    },
    [EDITOR_EVENTS.SCRIPT_STATUS_CHANGED]({ app, emitter, data }) {
      // If the script status has changed, force the user to reload the browser
      // window-- including the user who changed the status (but give a different)
      // message to that user
      const { scriptId, socketId, statusType: newStatus } = data
      const localScript = app.editorManager.state.editor?.script
      if (!localScript || localScript.id !== scriptId) {
        return
      }
      const { status: oldStatus, name: scriptName } = localScript
      const sameClient = app.io && app.io.id === socketId
      const mustReload = !sameClient && oldStatus !== newStatus
      if (mustReload) {
        emitter.emit('log:debug', `script status changed to ${newStatus}`)
        if (!sameClient) {
          // This is where we'd like to use rehydrate
          // emitter.emit('editor:rehydrate', { status: data.statusType })
          // emitter.emit('notifications:create', {
          //   type: 'warning',
          //   autoDismiss: false,
          //   message: 'This document\'s access level has changed.'
          // })
          // nuclear option
          window.alert(
            `Sorry for the interruption!\n\nThe access level for "${scriptName}" has changed from ${oldStatus} to ${data.statusType}. We need to reload the page`
          )
          window.location.reload()
        }
      }
    },
  },
})
