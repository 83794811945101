import { Badge } from '@mantine/core'
import { FancyMenuItem } from '@components/FancyMenuItem'

export const PaidMenuItem = ({
  title,
  isUnpaidOrg,
  onClick,
  disabled,
}: {
  title: string
  isUnpaidOrg: boolean
  onClick: () => void
  disabled?: boolean
}) => (
  <FancyMenuItem
    title={title}
    onClick={onClick}
    disabled={disabled || isUnpaidOrg}
    badge={isUnpaidOrg ? <Badge size="xs">PAID</Badge> : undefined}
  ></FancyMenuItem>
)
