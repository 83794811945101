import { Route, Switch } from 'wouter'
import { GuardedRoute } from './util/GuardedRoute'
import { CenteredScrollingBody } from '@layouts'
import * as Staff from '@components/Staff'
import { NotFoundPage } from '@components/ErrorPage'
import { ROUTE_PATTERNS } from '@util'

export const StaffRouter = () => (
  <GuardedRoute path="/staff/:rest*" guard={(mst) => mst.user.staff}>
    <CenteredScrollingBody>
      <Switch>
        <Route
          path={ROUTE_PATTERNS.staffLanding}
          component={Staff.LandingPage}
        />
        <Route
          path={ROUTE_PATTERNS.staffWorkspaces}
          component={Staff.Workspaces}
        />
        <Route
          path={ROUTE_PATTERNS.staffWorkspaceDetail}
          component={Staff.OrgDetail}
        />
        <Route
          path={ROUTE_PATTERNS.staffCloneWorkspace}
          component={Staff.CloneWorkspace}
        />
        <Route path={ROUTE_PATTERNS.staffInvites} component={Staff.Invites} />
        <Route
          path={ROUTE_PATTERNS.staffTemplateWorkspaces}
          component={Staff.Templates}
        />
        <Route path={ROUTE_PATTERNS.staffUsers} component={Staff.Users} />
        <Route
          path={ROUTE_PATTERNS.staffFormats}
          component={Staff.FormatEditor}
        />
        <Route path={ROUTE_PATTERNS.staffFlags} component={Staff.BetaFlags} />
        <Route path={ROUTE_PATTERNS.staffRundowns} component={Staff.Rundowns} />
        <Route component={NotFoundPage} />
      </Switch>
    </CenteredScrollingBody>
  </GuardedRoute>
)
