import { Group, Title, Stack } from '@mantine/core'
import { NavAnchor } from '@components/NavAnchor'
import dashStyles from './Dashboard.module.scss'

export const DashboardSettings = ({ isAdmin }: { isAdmin: boolean }) => (
  <Stack gap={0}>
    <Group gap="xl" grow>
      <Stack gap={0}>
        <Title order={1} className={dashStyles.dash_header}>
          Your Settings
        </Title>
        <Stack gap={5}>
          <NavAnchor href="/settings">Upload a profile picture</NavAnchor>
          <NavAnchor href="/settings/security">Change your password</NavAnchor>
          <NavAnchor href="/settings">More...</NavAnchor>
        </Stack>
      </Stack>
      <Stack gap={0}>
        <Title order={1} className={dashStyles.dash_header}>
          Workspace Settings
        </Title>
        <Stack gap={5}>
          <NavAnchor href="/settings/org/members">
            {isAdmin ? 'Invite new users' : 'View members'}
          </NavAnchor>
          {isAdmin && (
            <NavAnchor href="/settings/org/permissions">Permissions</NavAnchor>
          )}
          <NavAnchor href="/settings/org">More...</NavAnchor>
        </Stack>
      </Stack>
    </Group>
  </Stack>
)
