// This component is used to attach the singleton choo app dom tree to
// a node in the pikachoo app. Different views create different instances of
// this component but we only show one at a time and it always re-attaches the same
// choo app instance (managed by ChooWrapper)
import React from 'react'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import { PrompterView } from '@components/PrompterView'
import { EditorToolbar } from '@components/EditorToolbar'
import { ScriptMiniHeader } from '@components/MiniHeader'
import { ScriptStatusBar } from '@components/ScriptStatusBar'
import { HyperlinkPopover } from '@components/SelectionPopovers'
import { ScriptToastHost } from '@components/Toast'
import { ScriptAndToolbars } from './ScriptAndToolbars'
import { ScriptWrapper } from './ScriptWrapper'
import { InlineInkStyles } from '@ink/components'
import { useEditorBlurDetection, useElementDimensions } from '@hooks'
import { useNavigation } from '@hooks'
import { useFormatStyles } from '@hooks/useFormatStyles'
import { useMst } from '@state'
import { scrollToBlock, measureScrollerHeight } from '@util/scrolling'
import { ScriptPayload } from '@util/ScriptoApiClient/types'

import styles from './Script.module.scss'

export const Script = observer(function Script({
  payload,
}: {
  payload: ScriptPayload
}) {
  const { choo, currentInkProject, currentScript, view, user, socketManager } =
    useMst()

  const { setFormat } = useFormatStyles()
  const { removeBlockParam } = useNavigation()
  const chooHasScript = React.useRef(false)

  // We keep track of the dimensions and update mst so that things
  // like the extras menu can resize to fit
  const hostDimensions = useElementDimensions()
  const { height, width } = hostDimensions.dimensions

  React.useEffect(() => {
    const scrollerHeight = measureScrollerHeight()
    if (height > 0 && width > 0 && scrollerHeight > 0) {
      view.dimensions.scriptScroller.update({
        height: scrollerHeight,
        width,
      })
    }
  }, [height, view.dimensions.scriptScroller, width])

  setFormat(payload.scriptFormat)
  useEditorBlurDetection()

  const ref = React.useCallback(
    (element: HTMLDivElement | null) => {
      if (element) {
        element.appendChild(choo.chooNode)
        // make sure we only emit the script payload once to choo
        if (!chooHasScript.current) {
          chooHasScript.current = true
          choo.sendScriptToChoo(payload)
        }
      }
    },
    [choo, payload]
  )

  // don't show script until we have the right styles loaded. This hidden flag
  // ONLY makes the script invisible, doesn't hide any error state coming from choo
  const hideScript = !(currentScript && choo.scriptFormat)
  const chooPageClasses = cn(styles.chooPage, {
    [styles.safelyHidden]: hideScript,
    [styles.noFocus]: !currentScript?.observableEditor?.editorViewFocused,
    [styles.elementMenuOpened]: currentScript?.elementMenuOpened,
    [styles.disconnected]:
      !socketManager.connected || currentScript?.syncStatus.isTooStaleForSafety,
  })

  // if you land on this page with a block param (ie: ?block=<blockId>) then once
  // the script is ready, we remove the query param from the URL and scroll to the block
  React.useEffect(() => {
    if (!hideScript) {
      const blockId = removeBlockParam()
      if (blockId) {
        scrollToBlock(blockId)
      }
    }
  }, [hideScript, removeBlockParam])

  const pageless = user.prefs.pageless || currentScript?.isInk
  const noWrap =
    currentScript?.isInk && !currentInkProject?.preferences?.lineWrap

  // When switching view modes or view sizes, trigger a choo re-render
  // so things like avatar and comment positioning get updated
  React.useEffect(
    () => currentScript?.pmEditor.rerender(),
    [height, width, view.editorZoom, pageless, noWrap, currentScript?.pmEditor]
  )

  return (
    <ScriptAndToolbars
      ref={hostDimensions.ref}
      {...view.dimensions.scriptScroller}
      zoomLevel={view.editorZoom}
    >
      {currentScript?.isInk && currentInkProject && (
        <InlineInkStyles
          messages={currentInkProject.compilerMessagesForScript(
            currentScript.id
          )}
        />
      )}
      <ScriptMiniHeader />
      <EditorToolbar />
      <ScriptWrapper pageless={pageless} noWrap={noWrap}>
        <div ref={ref} id="choo-page" className={chooPageClasses} />
        {currentScript?.showPrompterView && <PrompterView />}
        {currentScript?.supportsHyperlinks && <HyperlinkPopover />}
      </ScriptWrapper>
      <ScriptStatusBar />
      <ScriptToastHost />
    </ScriptAndToolbars>
  )
})
