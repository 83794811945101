import { ChangeEvent } from 'react'
import { observer } from 'mobx-react-lite'
import { Checkbox, Group, ScrollArea, Text } from '@mantine/core'
import { IPrompterPushCandidate, IPrompterSegment } from '@state/types'
import { PrompterSegmentItem } from './PrompterSegmentItem'

import styles from './PushToPrompterModal.module.scss'
import { pluralize } from '@util'

export const Blurb = ({ count }: { count: number }) => (
  <Text className={styles.promptermodal_status} c="dimmed" size="sm">
    Pushing the
    <Text span fw="bold">
      &nbsp;run order&nbsp;
    </Text>
    and&nbsp;
    <Text span fw="bold">
      {count}&nbsp;{pluralize(count, 'text file')}
    </Text>
  </Text>
)

export const ComplexPush = observer(function ComplexPush({
  candidate,
  segments,
}: {
  candidate: IPrompterPushCandidate
  segments: IPrompterSegment[]
}) {
  const { areAllSegmentsChecked, areNoSegmentsChecked, checkedSegmentCount } =
    candidate

  return (
    <>
      <Group className={styles.promptermodal_selectall}>
        <Checkbox
          checked={!areNoSegmentsChecked}
          indeterminate={!(areAllSegmentsChecked || areNoSegmentsChecked)}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            candidate.toggleSegmentsChecked(event.currentTarget.checked)
          }}
        />
      </Group>
      <ScrollArea className={styles.promptermodal_scroll} type="auto">
        {segments.map((s) => (
          <PrompterSegmentItem segment={s} key={s.snapshotSlugId} />
        ))}
      </ScrollArea>
      <Blurb count={checkedSegmentCount} />
    </>
  )
})
