import joiner from './joiner.js'
import getSplitterFunction from './splitter.js'
import { pageBreakerKey } from './constants.js'
/**
 * Get start and end diff position values for two states (old, new).
 * @param {EditorState} oldState - last PM state
 * @param {EditorState} newState - current PM state
 * @returns {object} - { start, end }
 */
function getDiffRange(oldState, newState) {
  // https://prosemirror.net/docs/ref/#model.Fragment.findDiffStart
  const start = oldState.doc.content.findDiffStart(newState.doc.content)
  // Important: diffEnd value is {a,b} object since end pos will differ.
  // https://prosemirror.net/docs/ref/#model.Fragment.findDiffEnd
  let { a, b } = oldState.doc.content.findDiffEnd(newState.doc.content)
  // WARNING: diffEnd may be lower than diffStart.
  // If so, add overlap to get correct range.
  // https://discuss.prosemirror.net/t/overlap-handling-of-finddiffstart-and-finddiffend/2856
  const overlap = start - Math.min(a, b)
  if (overlap > 0) {
    b += overlap
  }
  return { start, end: b }
}
function getRepaginateFunction(format) {
  /**
   * Repaginate a document based on two differing states.
   * Optionally repaginate a full document if only on state is provided.
   * @param {EditorState} editorState - current PM editor state
   * @param {EditorState} oldState - old editor state
   * @returns {Transaction|null} - PM transaction or null
   */
  function repaginate(editorState, oldState) {
    let splitTr
    // FIXME: TODO: PROBLEM!
    // in a locked script,
    // when repaginating dynamic pages that have custom page numbers,
    // those numbers will be lost during a join operation.
    // SO! we need to retain those numbers and reapply them when splitting,
    // somehow...
    const splitterFunction = getSplitterFunction(format)
    if (oldState == null) {
      const { tr: joinTr, pageNumbers } = joiner(editorState)
      splitTr = splitterFunction(joinTr, editorState, null, pageNumbers)
    } else {
      const diffRange = getDiffRange(oldState, editorState)
      const { tr: joinTr, pageNumbers } = joiner(editorState, diffRange)
      splitTr = splitterFunction(joinTr, editorState, diffRange, pageNumbers)
    }
    if (!splitTr || splitTr.doc.eq(editorState.doc)) {
      return null
    }
    return splitTr
      .setMeta('addToHistory', false) // pagination should never be undo-able
      .setMeta(pageBreakerKey, 'repaginate') // signal this is a pagination tr
  }
  return repaginate
}
export { getRepaginateFunction }
export { joiner }
export { getSplitterFunction }
export default {
  getRepaginateFunction,
  joiner,
  getSplitterFunction,
}
