import html from 'nanohtml'
import { CAPITALIZED_BLOCK_TYPES } from '../../prose-utils.js'
import { getBlockType, isSelectionTooBig } from '@util'
import { relativeSelectionRect } from '@util/prosemirrorHelpers'
import { NodeSelection, TextSelection } from 'prosemirror-state'

function getTop({ editorView, zoomLevel, selection }) {
  const { top, bottom } = relativeSelectionRect({
    editorView,
    zoomLevel,
    selection,
  })
  // this is the middle of the selection rect
  const midline = (bottom + top) / 2
  // we want to shift it up a bit so the MIDDLE of the comment button is
  // at the top
  const offset = 12 * zoomLevel
  return midline - offset
}

function formatTop(top) {
  return `top:${top}px;`
}

function getSnippet(editorView) {
  const { selection, doc } = editorView.state
  // get snippet as selected before any collab modification might happen
  let snippet = doc.textBetween(selection.from, selection.to, '\n')
  // TODO: fix uppercasing to apply style for each node, not just first
  const blockType = getBlockType(editorView.state)
  // to ensure that the raw snippet looks familiar, we make it upper case manually when appropriate
  if (CAPITALIZED_BLOCK_TYPES.includes(blockType)) {
    snippet = snippet.toUpperCase()
  }
  return snippet
}
function commentButton(props, actions) {
  const { editorView, appState } = props
  const { state } = editorView
  const { selection } = state
  const mouseDown = editorView.mouseDown
  const noSelection = selection.empty
  const isDocSelection = selection.$anchor.depth === 0
  const selectionTooBig = isSelectionTooBig(state)
  const selectionWithoutRange = selection.$anchor.pos === selection.$head.pos
  const validSelectionType =
    selection instanceof TextSelection || selection instanceof NodeSelection
  const zoomLevel = appState.mst.view.editorZoom

  if (
    mouseDown ||
    noSelection ||
    isDocSelection ||
    selectionTooBig ||
    selectionWithoutRange ||
    props.isAdding ||
    !validSelectionType
  ) {
    return ''
  }

  const top = getTop({ editorView, zoomLevel, selection })
  const style = formatTop(top)

  return html`
    <button
      style=${style}
      class="newcomment"
      onclick=${() =>
        actions.onCommentStarted({
          top,
          snippet: getSnippet(editorView),
        })}
    >
      <i class="fa-solid fa-comment-plus"></i>
    </button>
  `
}
export { commentButton }
export default {
  commentButton,
}
