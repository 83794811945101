import html from 'nanohtml'
import env from '../../../env'

const { AVATAR_ROOT, GRAYFACE_URL } = env

// cant use @util/getAvatarUrl because mst isnt readily accessible
const getCommentAvatarUrl = (user) => {
  // short circuit for old bad logic that needs refactoring
  if (user.avatar === GRAYFACE_URL) {
    return GRAYFACE_URL
  }
  if (user.avatar) {
    if (user.avatar.includes(AVATAR_ROOT)) {
      return user.avatar
    }
    return AVATAR_ROOT + user.avatar
  }
  return GRAYFACE_URL
}

export function commentAvatar(user) {
  return html`
    <img
      class="c-comment__avatar"
      alt="${user.name}"
      src="${getCommentAvatarUrl(user)}"
    />
  `
}
