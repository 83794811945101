import html from 'nanohtml'
import Nanocomponent from 'nanocomponent'
import isEqual from 'lodash.isequal'
import cloneDeep from 'lodash.clonedeep'
import { commentView } from './comment-view.js'
export class CommentsContainer extends Nanocomponent {
  constructor() {
    super()
    this.state = {
      top: 0,
      comments: [],
      isEditing: false,
      isAdding: false,
    }
  }
  createElement(props, actions, components) {
    const { top, comments, isEditing, isAdding } = props
    // store for update compare
    this.state.top = top
    // must deep clone to accurately compare replies
    this.state.comments = cloneDeep(comments)
    this.state.isEditing = isEditing
    this.state.isAdding = isAdding
    const style = `top: ${top}px`
    if (comments.length === 0) {
      if (!isAdding) {
        return html`<aside class="c-commentscontainer"></aside>`
      } else {
        return html`
          <aside class="c-commentscontainer" style=${style}>
            ${components.addForm.render(props, actions, true)}
          </aside>
        `
      }
    }
    return html`
      <aside class="c-commentscontainer" style=${style}>
        <button
          onclick=${() => actions.toggleComments()}
          class="c-comment__close o-button o-button--icon o-button--inverted"
        >
          <i class="fa f fa-close"></i>
        </button>
        ${comments.map((comment) => {
          return html`
            <div id="comment-${comment.id}" class="c-commentthread">
              <div class="c-comment c-comment--actions">
                <button
                  class="c-comment__resolve o-button o-button--inverted"
                  onclick="${() => actions.resolveComment(comment.id)}"
                >
                  Resolve
                </button>
              </div>
              <div class="c-comment c-comment--snippet">
                <div class="c-comment__highlight is-light">
                  <div class="c-comment__truncation">${comment.snippet}</div>
                </div>
              </div>
              ${commentView(comment, props, actions, components)}
              ${comment.replies.map((reply) =>
                commentView(reply, props, actions, components)
              )}
              ${components.replyForm.render(comment, props, actions)}
            </div>
          `
        })}
      </aside>
    `
  }
  update(props) {
    // update if there's been a change with at least one prop cached in state
    const shouldUpdate = Object.keys(this.state).some((key) => {
      return !isEqual(this.state[key], props[key])
    })
    return shouldUpdate
  }
}
