import { observer } from 'mobx-react-lite'
import {
  Stack,
  Group,
  LoadingOverlay,
  Modal,
  Box,
  ScrollArea,
  Button,
  Input,
  Text,
  Select,
} from '@mantine/core'
import cn from 'classnames'
import NiceModal from '@ebay/nice-modal-react'
import { useMst } from '@state'
import { IRundown } from '@state/types'
import { Toast } from '../Toast'
import { useShortcuts, Keys } from '@hooks'
import { PrintableRundownGrid } from './PrintableRundownGrid'
import { ColumnVisibilityControls } from '@components/RundownToolbar/ColumnVisibilityModal'
import { usePrincePrintableRef } from '@util/princePrinting'
import { RUNDOWN_MARGIN_DEF } from '@util/princePrinting/constants'
import { RundownPrintPrefs } from '@util/LocalPersistence'

import { orientationData, rowDensityData } from './helpers'

import styles from './PrintPreferences.module.scss'

export const PrintPreferencesModal = observer(function PrintPreferencesModal({
  rundown,
  visible,
  hide,
}: {
  rundown: IRundown
  visible: boolean
  hide: () => void
}) {
  const { printRef, handlePrint, loading, showDebugHtml, errorMessage } =
    usePrincePrintableRef(rundown.name)
  const { user, view, nodeEnv } = useMst()
  const handleDebug = showDebugHtml

  useShortcuts({
    print: {
      keys: [Keys.CMD, 'P'],
      action: () => handlePrint(false),
    },
  })

  const editable = rundown.rootStore.user.canEditRundowns
  const showDebugButton = view.devMode || nodeEnv === 'development'

  // TODO: these should read the settings for margins and page width
  // and compute dynamically
  const pageWidth =
    user.rundownPrintPrefs.orientation === 'landscape' ? 11 : 8.5
  const hMargins = RUNDOWN_MARGIN_DEF.left + RUNDOWN_MARGIN_DEF.right

  const sizingGuideStyle = {
    width: `${pageWidth - hMargins}in`,
  }

  return (
    <Modal
      opened={visible}
      onClose={hide}
      title="Print Rundown"
      size="100%"
      classNames={{
        content: styles.printPreferences_modal,
        body: styles.printPreferences_modalBody,
      }}
    >
      <LoadingOverlay visible={loading} />
      <div className={styles.printPreferences_content}>
        <Stack className={styles.leftColumn}>
          <Input.Wrapper label="Columns to print">
            <ScrollArea.Autosize
              type="auto"
              mah="40vh"
              className={styles.printPreferences_columnVizScroller}
            >
              <Box
                className={styles.printPreferences_columnVizScroller__content}
              >
                <ColumnVisibilityControls rundown={rundown} layout="print" />
              </Box>
            </ScrollArea.Autosize>
          </Input.Wrapper>
          <Select
            label="Orientation"
            value={user.rundownPrintPrefs.orientation}
            onChange={(orientation) => {
              user.updateRundownPrintPrefs({
                orientation: orientation as RundownPrintPrefs['orientation'],
              })
            }}
            data={orientationData}
          />
          <Select
            label="Row Density"
            value={user.rundownPrintPrefs.rowDensity}
            onChange={(rowDensity) => {
              user.updateRundownPrintPrefs({
                rowDensity: rowDensity as RundownPrintPrefs['rowDensity'],
              })
            }}
            data={rowDensityData}
          />
          {errorMessage.length > 0 && (
            <Toast message={errorMessage} type="error" />
          )}
          <Group justify="center" gap="xs" pt="md">
            <Button onClick={() => handlePrint(false)}>Print</Button>
            <Button variant="outline" onClick={hide}>
              Close
            </Button>
          </Group>
          {showDebugButton && (
            <Button variant="subtle" onClick={handleDebug}>
              Debug Printable HTML
            </Button>
          )}
        </Stack>
        <div className={styles.rightColumn}>
          <div className={styles.guideAndGrid}>
            <div
              className={cn(styles.sizingGuide, styles.hintText)}
              style={sizingGuideStyle}
            >
              <div>Printable area</div>
              <div>Adjust columns to fit width</div>
            </div>
            <PrintableRundownGrid ref={printRef} rundown={rundown} />
          </div>

          {editable && (
            <Text
              component="div"
              className={cn(styles.hintText, styles.pageBreakInfo)}
              style={sizingGuideStyle}
            >
              Double-click rows to add page breaks
              <div className={styles.printPreferences_pageBreakDemo} />
            </Text>
          )}
        </div>
      </div>
    </Modal>
  )
})

const NicePrintPreferences = NiceModal.create(
  ({ rundown }: { rundown: IRundown }) => {
    const { visible, hide } = NiceModal.useModal()
    return (
      <PrintPreferencesModal rundown={rundown} visible={visible} hide={hide} />
    )
  }
)

export const showPrintPreferences = (props: { rundown: IRundown }) =>
  NiceModal.show(NicePrintPreferences, props)
