import React from 'react'
import classname from 'classnames'
import { observer } from 'mobx-react-lite'
import { isMacOs } from 'react-device-detect'
import { Anchor, Tooltip } from '@mantine/core'
import { ScriptStatus, ScriptStatusMap } from '@showrunner/codex'
import { useMst } from '@state'
import { useNavigation } from '@hooks'
import {
  ScriptListingMenu,
  RundownListingMenu,
} from '@components/FolderExplorer/DotDotDotMenu'
import { useFormattedTimestamp, useScriptMultiClick } from '@hooks'
import { DraggableItem } from '../DraggableItem'
import { IListing, isScriptListing } from '@state/types'
import { ScriptStatusIcon } from '@components/FaIcon'
import { NameListingInput } from '@components/FolderExplorer/NameListingInput'

import styles from './DocumentListing.module.scss'

type DocumentListingProps = {
  id: string
  href: string
  icon: string
  title: string
  subtitle: string
  selected: boolean
  isDraggable: boolean
  menu: React.ReactNode
  status?: ScriptStatus
  onClick: (e: React.MouseEvent) => void
  hoverTooltip?: string
}

export const DocumentListingBase = (props: DocumentListingProps) => {
  const {
    icon,
    selected,
    title,
    subtitle,
    menu,
    href,
    onClick,
    isDraggable,
    id,
    status,
    hoverTooltip,
  } = props

  const miniBadge =
    status && status !== ScriptStatusMap.OPEN ? (
      <>
        &nbsp;
        <ScriptStatusIcon status={status} size="xs" />
      </>
    ) : null

  const linkRef = React.useRef<HTMLAnchorElement>(null)
  useScriptMultiClick({
    ref: linkRef,
    onClick,
  })

  return (
    <DraggableItem enableDrag={isDraggable} id={id}>
      <li
        className={classname(styles.doc, {
          [styles.doc___selected]: selected,
        })}
        data-doc-listing={id}
      >
        <Anchor
          className={styles.doc_link}
          ref={linkRef}
          href={href}
          onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
            // prevent default for normal clicks to avoid reloading the page.
            const modifiedClick = isMacOs ? e.metaKey : e.ctrlKey
            if (!modifiedClick) e.preventDefault()
          }}
        >
          <div className={styles.doc_info}>
            <div className={classname(styles.doc_name)}>
              <div className="l-box l-box--center-items">
                <div className={styles.doc_icon}>
                  <i className={icon} />
                </div>
                <Tooltip
                  disabled={!hoverTooltip}
                  openDelay={500}
                  label={hoverTooltip}
                  withinPortal
                  position="bottom-start"
                >
                  <div className={styles.doc_title}>{title}</div>
                </Tooltip>
                {miniBadge}
              </div>
            </div>
            <div className={styles.doc_meta}>{subtitle}</div>
          </div>
        </Anchor>
        <div className={styles.doc_menuWrapper}>{menu}</div>
      </li>
    </DraggableItem>
  )
}

export const DocumentListing = observer(function DocumentListing({
  listing,
}: {
  listing: IListing
}) {
  const { user, location, getFolderPath, view } = useMst()
  const { navigateToScript, navigateToRundown, pathToScript, pathToRundown } =
    useNavigation()
  const openRundownId = location.getPathParam('rundownId')
  const tooltip = view.selectedFolderIsCollection
    ? getFolderPath(listing.folderId)
        .map(({ displayName }) => displayName)
        .join(' > ')
    : undefined

  // let shared scripts be dragged into rundowns if org has the feature and user has permission
  const isValidScriptForRundown =
    openRundownId &&
    user.canEditRundowns &&
    isScriptListing(listing) &&
    !listing.isPrivate

  const { name, contentsModifiedAt, lastModifier } = listing
  const subtitle = useFormattedTimestamp({
    date: contentsModifiedAt,
    user: lastModifier?.name,
  }).replace('Last edited by ', '')

  const icon = isScriptListing(listing)
    ? `fas ${listing.scriptFormat.icon}`
    : 'fas fa-table-cells'

  const menu = isScriptListing(listing) ? (
    <ScriptListingMenu scriptListing={listing} />
  ) : (
    <RundownListingMenu rundownListing={listing} />
  )

  const href = isScriptListing(listing)
    ? pathToScript(listing.id)
    : pathToRundown(String(listing.id))

  const handleClick = (e: React.MouseEvent) => {
    // if a modifier key is detected, let the browser do its thing
    if (isMacOs ? e.metaKey : e.ctrlKey) return

    if (isScriptListing(listing)) {
      navigateToScript(listing.id)
    } else {
      navigateToRundown(String(listing.id))
    }
  }

  if (listing.isEditingName) {
    return (
      <div className={styles.doc}>
        <NameListingInput
          initialValue={listing.name}
          mode={'rename'}
          itemType={isScriptListing(listing) ? 'script' : 'rundown'}
          onCancel={() => listing.setIsEditingName(false)}
          onSubmit={async ({ name }) => {
            try {
              await listing.rename(name)
            } catch (e) {
              listing.setIsEditingName(false)
            }
          }}
          icon={icon}
        />
      </div>
    )
  } else {
    return (
      <DocumentListingBase
        id={String(listing.id)}
        isDraggable={!!isValidScriptForRundown}
        onClick={handleClick}
        title={name}
        subtitle={subtitle}
        selected={listing.isSelected}
        icon={icon}
        menu={menu}
        href={href}
        status={isScriptListing(listing) ? listing.accessLevel : undefined}
        hoverTooltip={tooltip}
      />
    )
  }
})
