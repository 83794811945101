import React from 'react'
import { Popover } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { useMst } from '@state'
import { ButtonMode, ILoadedScript } from '@state/types'
import { Toolbar } from '@components/Toolbar'
import { TimingInfo } from './TimingInfo'
import { useShortcuts, Keys } from '@hooks'

export const TimingPopover = observer(function TimingPopover({
  script,
  mode,
}: {
  script: ILoadedScript
  mode: ButtonMode
}) {
  const { view } = useMst()
  const [isOpen, setIsOpen] = React.useState(false)

  const togglePopover = () => setIsOpen(!isOpen)

  const { getItemProps } = useShortcuts({
    timing: {
      keys: [Keys.CMD, Keys.SHIFT, '0'],
      action: togglePopover,
    },
  })

  const tooltip = `Script timing (${getItemProps('timing').shortcut})`
  return (
    <Popover
      trapFocus
      opened={isOpen}
      position="bottom-start"
      shadow="xl"
      width={250}
      onChange={setIsOpen}
    >
      <Popover.Target>
        <Toolbar.EditorFocusButton
          icon="fa-clock"
          label={script.timing.total}
          tooltip={tooltip}
          dropdown
          mode={mode}
          onClick={togglePopover}
          enableWithoutFocus
          disableTooltip={isOpen}
        />
      </Popover.Target>
      <Popover.Dropdown pb={0} pt={0} px={0}>
        <TimingInfo
          containerHeight={view.dimensions.scriptScroller.height}
          timing={script.timing}
          slugTimings={script.slugTiming}
          selectionTiming={script.selectionTiming}
        />
      </Popover.Dropdown>
    </Popover>
  )
})
