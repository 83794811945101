import html from 'nanohtml'
import raw from 'nanohtml/raw'
import Nanocomponent from 'nanocomponent'
import { commentAvatar } from './comment-avatar.js'
class CommentForm extends Nanocomponent {
  createElement(comment, state, actions, isEditing) {
    const textNodes = isEditing ? raw(comment.text) : ''
    const submit = (event) =>
      isEditing
        ? actions.editComment(event, comment.id)
        : actions.addCommentReply(event, comment.id)
    const autogrowValue = isEditing ? textNodes[0].textContent : ''
    const input = html`
      <label class="c-comment__wrapper" data-value=${autogrowValue}>
        <textarea
          class="c-comment__content"
          rows="1"
          placeholder="Add a comment"
          onkeydown=${(event) => onkeydown(event, actions, submit)}
          oninput=${oninput}
        >
${textNodes}</textarea
        >
      </label>
      <input
        type="submit"
        value=${isEditing ? 'Update' : 'Send'}
        class="c-comment__submit o-button o-button--primary is-small"
        onclick=${submit}
      />
    `
    if (isEditing) {
      const editStyle = `
        display: flex;
        flex-direction: column;
        width: 100%;
      `
      return html`<div id="comment-edit-form" style=${editStyle}>${input}</div>`
    }
    return html`
      <form id="comment-reply-form" class="c-comment c-comment--new">
        <div class="c-comment__byline">${commentAvatar(state.user)}</div>
        ${input}
      </form>
    `
  }
  update() {
    // don't allow renders to reset input data
    return false
  }
}
function oninput(event) {
  // copy the text into an invisible wrapper div for autogrow magic.
  event.target.parentNode.dataset.value = event.target.value
}
function onkeydown(event, actions, submit) {
  // ESC hides the form
  if (event.keyCode === 27) {
    actions.cancelEdit()
  }
  // Enter submits the form
  if (event.keyCode === 13 && !event.shiftKey) {
    event.preventDefault()
    submit(event)
  }
}
export { CommentForm }
export default {
  CommentForm,
}
