import React from 'react'
import { observer } from 'mobx-react-lite'
import { Controls } from './Controls'
import { ICellRendererParams } from '@ag-grid-community/core'
import { RundownRowData } from '@util/ScriptoApiClient/types'
import { IRundown, IRundownRow } from '@state/types'
import { useFloatingMenu } from '@hooks'
import { ScriptLink } from './ScriptLink'

// helper to figure out what rows are valid targets for a multi-selection
const getRowsForMultiselect = (
  rundown: IRundown,
  targetRow: IRundownRow
): IRundownRow[] => {
  if (rundown.selectedRows.length === 0) {
    return [targetRow]
  }

  const firstSelectedSequence = rundown.selectedRows[0].sequence

  if (targetRow.sequence < firstSelectedSequence) {
    return rundown.sortedRowInstances.filter(
      (r) =>
        r.sequence >= targetRow.sequence && r.sequence < firstSelectedSequence
    )
  }
  const lastSelectedSequence =
    rundown.selectedRows[rundown.selectedRows.length - 1].sequence
  if (targetRow.sequence > lastSelectedSequence) {
    return rundown.sortedRowInstances.filter(
      (r) =>
        r.sequence <= targetRow.sequence && r.sequence > lastSelectedSequence
    )
  }

  return [targetRow]
}

export const ControlsCell = observer(function ControlsCell({
  api,
  context,
  node,
  column,
  registerRowDragger,
  showEditControls,
}: ICellRendererParams<RundownRowData> & { showEditControls: boolean }) {
  const rundown = context as IRundown
  const row = node.data ? rundown.getRow(node.data.id) : undefined
  const checked = !!row?.selectedInGrid
  const floatingMenu = useFloatingMenu()

  // keep an eye on the mst value of whether this row is checked. If it is
  // update the grid state. That is what will let us do multi-row drag, etc.
  React.useEffect(() => {
    node.setSelected(checked)
  }, [checked, node])

  const handleMouseDownCapture = (e: React.MouseEvent<Element>) => {
    // ctrl key means the user is bringing up the context menu. Don't
    // capture this, let it propagate
    if (e.ctrlKey) {
      return
    }

    if (typeof node.rowIndex === 'number' && column) {
      api.setFocusedCell(node.rowIndex, column.getColId())
    }
    e.stopPropagation()
    e.preventDefault()
    // because we stopped the event from propagating, ag-grid doesn't know about the
    // click so we need to dismiss the popup menu ourselves
    floatingMenu.hide()
  }

  // When we handle the click event, check first if the user has the shift key down and is
  // toggling the checkbox to on. If so, we will attempt a multi-select (setting many rows to true only)
  //
  // If not multiselecting, look for the alt/option key. If that's down and the row has a level,
  // we'll do a special operation to select (even if all were already selected) that row and its children
  const handleChange = (e: React.MouseEvent<Element>) => {
    const { shiftKey, altKey } = e
    const newValue = !checked
    if (!row) {
      return
    }

    // if the shift key was held and we're setting to true, we want to extend the existing
    // set of selected rows
    const multiSelect = shiftKey && newValue
    const selectChildren = altKey && row.rowLevel

    if (multiSelect) {
      getRowsForMultiselect(rundown, row).forEach((r) => r.setSelected(true))
    } else if (selectChildren) {
      row.setSelected(true)
      rundown.getChildRows(row.id).forEach((r) => r.setSelected(true))
    } else {
      row.setSelected(newValue)
    }
  }

  const scriptId = row?.scriptId

  return (
    <Controls
      showEditControls={showEditControls}
      checked={checked}
      registerDragger={registerRowDragger}
      onCheck={handleChange}
      onMouseDownCapture={handleMouseDownCapture}
      link={
        scriptId && <ScriptLink scriptId={scriptId} blockId={row?.blockId} />
      }
    />
  )
})
