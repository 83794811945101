import cn from 'classnames'
import { Group, Divider } from '@mantine/core'
import { ToolbarButton, CopyUrlButton } from './ToolbarButton'
import { EditorFocusButton } from './EditorFocusButton'
import { ToolbarTabs } from './ToolbarTabs'
import { ToolbarButtonGroup } from './ToolbarButtonGroup'
import { useMantineTheme } from '@mantine/core'

import styles from './Toolbar.module.scss'

export const Toolbar = ({
  children,
  bottomBar,
}: {
  children: React.ReactNode
  bottomBar?: boolean
}) => {
  return (
    <Group
      className={cn(styles.toolbar, {
        [styles.toolbar_bottom]: bottomBar,
      })}
    >
      {children}
    </Group>
  )
}

const ToolbarSection = ({
  position,
  children,
  className,
  bottomBar,
}: {
  position: 'left' | 'right'
  children: React.ReactNode
  className?: string
  bottomBar?: boolean
}) => (
  <Group
    justify={position}
    gap={6}
    className={cn(styles.toolbar___inner, className, {
      [styles.toolbar___innerRight]: position === 'right',
      [styles.toolbar___innerBottom]: bottomBar,
    })}
  >
    {children}
  </Group>
)

const ToolbarDivider = () => {
  const theme = useMantineTheme()
  return (
    <Divider
      color={theme.colors.gray[2]}
      orientation="vertical"
      className={styles.toolbar___divider}
    />
  )
}

Toolbar.Divider = ToolbarDivider
Toolbar.Button = ToolbarButton
Toolbar.Section = ToolbarSection
Toolbar.CopyUrlButton = CopyUrlButton
Toolbar.Tabs = ToolbarTabs
Toolbar.ButtonGroup = ToolbarButtonGroup
Toolbar.EditorFocusButton = EditorFocusButton
