import React from 'react'
import { Menu } from '@mantine/core'
import { FancyMenuItem } from '@components/FancyMenuItem'
import {
  InvisibleFdxPicker,
  InvisibleFdxPickerHandle,
} from '@components/InvisibleFdxPicker'
import { Spinner } from '@components/Spinner'
import { useResourceCreation } from '@hooks'
import { useMst } from '@state'
import { IFolder } from '@state/types'
import { ExplorerView } from '@components/ExplorerView'

export const CreateDocMenu = ({ folder }: { folder: IFolder }) => {
  const creation = useResourceCreation('explorer')
  const { currentOrg, user } = useMst()
  const fdxPickerRef = React.useRef<InvisibleFdxPickerHandle>(null)

  if (!currentOrg) return <></>

  const { studioFormat, screenplayFormat } = currentOrg

  const scriptItems: {
    id: 'studio' | 'screenplay'
    icon: `fas ${string}`
    title: string
  }[] = [
    {
      id: 'studio',
      icon: `fas ${studioFormat.icon}`,
      title: studioFormat.name,
    },
    {
      id: 'screenplay',
      icon: `fas ${screenplayFormat.icon}`,
      title: screenplayFormat.name,
    },
  ]

  const handleCreate = async (itemId: string) => {
    switch (itemId) {
      case 'import':
        fdxPickerRef?.current?.launchPicker()
        break
      case 'screenplay':
        await creation.createScript({
          docType: 'screenplay',
          folder,
        })
        break
      case 'studio':
        await creation.createScript({
          docType: 'studio',
          folder,
        })
        break

      case 'rundown':
        await creation.createRundown(folder)
        break
      default:
        break
    }
  }

  return (
    <>
      <Menu position="bottom-start">
        <Menu.Target>
          <div>
            <ExplorerView.HeaderButton
              tooltip={`Create document in ${folder.displayName}`}
              icon="fa-file-plus"
              iconSet="fa-regular"
            />
          </div>
        </Menu.Target>
        <Menu.Dropdown>
          {scriptItems.map((f) => (
            <FancyMenuItem
              key={f.id}
              iconClass={`fas ${f.icon}`}
              title={f.title}
              onClick={() => handleCreate(f.id)}
            />
          ))}

          {currentOrg.hasRundownsEnabled && !folder.isPrivate && (
            <FancyMenuItem
              disabled={!user.canEditRundowns}
              iconClass="fas fa-table-cells"
              title="Rundown"
              onClick={() => handleCreate('rundown')}
            />
          )}
          <Menu.Divider />
          <FancyMenuItem
            iconClass="fas fa-arrow-up-from-bracket"
            title="Import"
            subTitle="Upload a Final Draft file"
            onClick={() => handleCreate('import')}
          />
        </Menu.Dropdown>
      </Menu>
      <InvisibleFdxPicker folder={folder} ref={fdxPickerRef} />
      {creation.isCreating && <Spinner delayMs={300} fullScreen />}
    </>
  )
}
