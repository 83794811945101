import React from 'react'
import { RouteProps, DefaultParams, useRouter } from 'wouter'

// wouter doesn't let you do a route that matches one or more paths, this is a
// dumbed-down route that takes an array of paths but does not support the `component` prop
export const MultiRoute = <
  T extends DefaultParams | undefined,
  RoutePath extends string = string
>(
  props: Omit<RouteProps<T, RoutePath>, 'path' | 'component'> & {
    paths: string[]
    children: React.ReactNode
  }
) => {
  const { pathname } = location
  const { paths, ...routeProps } = props
  const { matcher } = useRouter()

  const matchedPath = paths.find((routePath) => {
    const [match] = matcher(routePath, pathname)
    return match
  })

  if (!matchedPath) {
    return null
  }

  return <>{routeProps.children}</>
}
