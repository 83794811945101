import { useEventListener } from 'usehooks-ts'
import { useMst } from '@state'
import { EDITOR_FOCUS_SELECTOR } from '@components/Toolbar/EditorFocusButton'

const editorSelector = '#editor'

const targetIsButton = (target: EventTarget | null): boolean => {
  return target instanceof Element && !!target.closest(EDITOR_FOCUS_SELECTOR)
}

const targetIsEditor = (target: EventTarget | null): boolean => {
  return target instanceof Element && !!target.closest(editorSelector)
}

export const useEditorBlurDetection = () => {
  const { currentScript } = useMst()
  useEventListener('focusout', ({ target, relatedTarget }) => {
    if (targetIsEditor(target) && !targetIsButton(relatedTarget)) {
      currentScript?.observableEditor?.setEditorViewFocused(false)
    }
  })
}
