import React from 'react'
import NiceModal from '@ebay/nice-modal-react'
import { List, Text, TextInput } from '@mantine/core'
import { ModalShell } from './ModalShell'
import { useModalControls } from './useModalControls'
import { useMst } from '@state'

type RetireWorkspaceModalProps = {
  workspaceName: string
  onConfirm: () => Promise<{ success: boolean; message?: string }>
  hasPrivate: boolean
}

const RetireWorkspaceModalInternal = ({
  onConfirm,
  workspaceName,
  hasPrivate,
}: RetireWorkspaceModalProps) => {
  const [value, setValue] = React.useState('')
  const { doDebug } = useMst()
  const controls = useModalControls()

  const label = 'Retire workspace'
  const disabled = value.trim() !== workspaceName.trim()

  const handleConfirm = async () => {
    controls.setLoading(true)
    try {
      await doDebug()
      const { success, message } = await onConfirm()
      if (message) controls.setErrorMessage(message)
      if (success) controls.onClose()
    } catch {
      controls.setErrorMessage('Failed to retire workspace')
    } finally {
      controls.setLoading(false)
    }
  }

  return (
    <ModalShell
      title={label}
      confirmLabel={label}
      cancelLabel="Cancel"
      size={400}
      onConfirm={handleConfirm}
      opened={controls.opened}
      dangerous
      disabled={disabled}
      loading={controls.loading}
      errorMessage={controls.errorMessage}
      onClose={controls.onClose}
    >
      <Text>
        Are you sure you want to retire&thinsp;
        <Text span fw="bold">
          {workspaceName}
        </Text>
        ?
      </Text>
      <List>
        {hasPrivate && (
          <List.Item>Your private scripts will be destroyed</List.Item>
        )}
        <List.Item>
          You will not be able to access the workspace again
        </List.Item>
      </List>
      <Text>To proceed, type the name of your workspace.</Text>
      <TextInput
        value={value}
        onChange={(event) => setValue(event.currentTarget.value)}
      />
    </ModalShell>
  )
}

const RetireWorkspaceModal = NiceModal.create<RetireWorkspaceModalProps>(
  RetireWorkspaceModalInternal
)

export const showRetireWorkspaceModal = (props: RetireWorkspaceModalProps) => {
  NiceModal.show(RetireWorkspaceModal, props)
}
