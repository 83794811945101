import { observer } from 'mobx-react-lite'
import { useMst } from '@state'
import { IRundown } from '@state'
import {
  SyncStatusCloud,
  BadSocketCloud,
  SyncStatusCloudProps,
} from './SyncStatusCloud'
import { pluralize } from '@util'

export const getCloudProps = ({
  pendingCount,
  editing,
}: {
  pendingCount: number
  editing: boolean
}): SyncStatusCloudProps => {
  if (pendingCount === 0) {
    return {
      status: 'ok',
      iconType: editing ? 'editing' : 'check',
      tooltip: 'Document in sync',
    }
  } else {
    const changeCountText = `${pendingCount} ${pluralize(
      pendingCount,
      editing ? 'incoming change' : 'change'
    )}`
    const tooltip = editing
      ? `Finish editing to sync ${changeCountText}`
      : `Applying ${changeCountText}`
    return {
      status: 'warn',
      tooltip,
      iconType: editing ? 'editing' : 'syncing',
    }
  }
}

export const RundownSyncStatus = observer(function SocketStatus({
  rundown,
}: {
  rundown: IRundown
}) {
  const { socketManager } = useMst()
  const { status } = socketManager
  if (status !== 'connected') {
    return <BadSocketCloud type={status} />
  }
  const cloudProps = getCloudProps({
    pendingCount: rundown.pendingRowMessages.length,
    editing: rundown.gridIsEditing,
  })

  return <SyncStatusCloud {...cloudProps} />
})
