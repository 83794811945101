import html from 'nanohtml'
export default (errCode) => {
  let content
  if (errCode === 404) {
    content = html`
      <img src="/images/TV.png" class="tv-img" alt="TV with color bars" />
      <span class="display-header">404</span>
      <h3>Not Found</h3>
      <p>Oh no! Looks like this page doesn't exist.</p>
    `
  } else {
    content = html`
      <img
        src="/images/TV-broken.png"
        class="tv-img"
        alt="TV with broken screen"
      />
      <span class="display-header">500</span>
      <h3>Internal Server Error</h3>
      <p>Our bad! Something's gone wrong on our end.</p>
    `
  }
  return html`
    <div class="container">
      <div class="row">
        <div class="form-outer">${content}</div>
      </div>
    </div>
  `
}
