import ChooJS from 'choo'
import { NodeTypeKey, ScriptFormatMap } from '@showrunner/codex'
import {
  ScriptPayload,
  ScriptFormatPayload,
} from '@util/ScriptoApiClient/types'
import { types, getSnapshot } from 'mobx-state-tree'
import { BaseModel } from './BaseModel'
import { stringMapEqual } from '@util'
import { showMoveToFolder } from '@components/Modals'
import { SHARE_SCRIPT_WARNING_MESSAGE } from '@util/constants'
import { buildChooApp, ScriptoChooApp } from '../../choo-app'
import { EditorMessage } from './SocketManager/types'
import { SCRIPT_SLACK_INTEGRATION_ENABLED } from '@util/mixpanel/eventNames'

// This function deterministically builds a string representing the list of
// orgs a user belongs to and what their current org is. This is pretty janky but
// necessary because the choo app doesn't track this properly when a user creates
// a new org (choo app modifies the legacy fields in the user state object directly
// and doesn't refresh the user). Once we fix that in the choo app or migrate the new org
// creation here, we can get rid of this.
const buildHashFromUserMemberships = (user: {
  orgMemberships: Array<{ orgId: string }>
  currentOrgId?: string
}) => {
  const sortedIdsString = user.orgMemberships
    .map((item) => item.orgId)
    .sort()
    .join('')

  return sortedIdsString + (user.currentOrgId ? `-${user.currentOrgId}` : '')
}

export const ChooWrapper = BaseModel.named('ChooWrapper')
  .props({
    userId: '',
    // This observable tracks the hash described above
    membershipHash: '',
    route: '',
    params: types.map(types.string),

    // Also keep track of the window's full URL as a way to combine the choo route, params & query
    // so we can trigger side effects when any of those three change
    href: '',

    // We need to rely on choo state for the scriptFormat. This will be the format for the current
    // script OR snapshot
    scriptFormat: types.maybe(types.frozen<ScriptFormatPayload>()),
  })
  .actions((self) => ({
    // This translates any key choo state values into observable MST values. It runs a lot
    // but all it does is update a few observables and ONLY when they change so it's a cheap
    // and easy way to go from choo state to observable mobx state
    updateFromChooState(state: ChooJS.IState) {
      const { route, params, editor } = state
      self.userId = self.rootStore.user.id || ''
      // TODO: is it time to yank this altogether?
      self.membershipHash = buildHashFromUserMemberships(self.rootStore.user)

      self.route = route

      // safe to yank?
      if (self.href !== window.location.href) {
        self.href = window.location.href
        // This ensures that when a choo route navigation happens, we
        // update our observable state. Once we have choo 100% out of the routing
        // game, we can get rid of this
        self.rootStore.location.updateFromWindow('pushState')
      }

      const scriptFormat = editor?.script?.scriptFormat
      if (scriptFormat) {
        self.scriptFormat = scriptFormat
      }

      // make sure these next two objects have actual changed values so we don't trigger re-renders
      // by replacing the object reference
      if (!stringMapEqual(getSnapshot(self.params), params)) {
        self.params.replace(params)
      }
    },
  }))
  .views((self) => {
    let ch: ScriptoChooApp
    let tree: HTMLElement

    return {
      // not meant to be called from outside this instance
      get _chooApp() {
        if (!ch) {
          ch = buildChooApp(self.rootStore)
          ch.use((state: ChooJS.IState) => {
            // On ANY emitted event from the choo app run the updateFromChooState function which will
            // cherry-pick out the things we care about and convert them to observables
            ch.emitter.on('*', () => {
              self.updateFromChooState(state)
            })

            // Pass along the data sent by the setScript event
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ch.emitter.on('editor:setScript', (data: any) => {
              self.rootStore.processSetScript(data)
            })

            /*
              if/when we refactor the static snapshot view to stop abusing
              this store in order to display page counts, we could tighten
              up the signature of the payload here significantly

              elementNumbers: undefined || {}
              locked: undefined || boolean
              navLinks: undefined || []
              pageCount: number
              selectionTiming: undefined || {}
              slugTiming: undefined || []
              timing: undefined || {}
            */
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ch.emitter.on('editor:setScriptMeta', (data: any) => {
              const { currentScript } = self.rootStore
              if (!currentScript) return

              if (typeof data.locked === 'boolean') {
                currentScript.setLocked(data.locked)
              }

              if (data.navLinks) {
                currentScript.updateNavLinks(data.navLinks)
              }

              if (data.elementNumbers) {
                currentScript.updateElementNumbers(data.elementNumbers)
              }

              currentScript.setPageCount(data.pageCount)

              if (currentScript.type !== ScriptFormatMap.SCREENPLAY) {
                currentScript.setTiming(data.timing)
                currentScript.setSlugTiming(data.slugTiming)
                currentScript.setSelectionTiming(data.selectionTiming)
              }
            })

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ch.emitter.on('analytics:track', function (data: any) {
              if (typeof data?.name === 'string') {
                self.analytics.track(data.name, data.opts)
              }
            })
          })
        }
        return ch
      },
      get chooNode(): HTMLElement {
        if (!tree) {
          tree = this._chooApp.start()
        }
        return tree
      },
    }
  })
  .actions((self) => ({
    addListener(eventName: string, listener: (...args: unknown[]) => void) {
      self._chooApp.emitter.addListener(eventName, listener)
    },
    removeListener(eventName: string, listener: (...args: unknown[]) => void) {
      self._chooApp.emitter.removeListener(eventName, listener)
    },
    // These are passthrough messages that the io-store
    // manages for the choo app
    relaySocketMessage([eventName, payload]: EditorMessage) {
      self._chooApp.emitter.emit(`io:${eventName}`, payload)
    },
    relaySocketConnectionChange(isConnected: boolean) {
      const eventName = isConnected ? 'io:connect' : 'io:disconnect'
      self._chooApp.emitter.emit(eventName)
    },
    async addScriptToSlack({
      scriptId,
      slackToken,
    }: {
      scriptId: string
      slackToken: string
    }) {
      await self.apiClient.addScriptToSlack({ scriptId, slackToken })
      self.trackEvent(SCRIPT_SLACK_INTEGRATION_ENABLED)
    },
    moveNavLink(oldIdx: number, newIdx: number) {
      self._chooApp.emitter.emit('editor:reorderScenes', { oldIdx, newIdx })
    },
    shareScriptWithOrg(scriptId: string) {
      const listing = self.rootStore.scriptMap.get(scriptId)
      const rootFolder = self.rootStore.rootFolders.sharedDashboard
      if (rootFolder && listing) {
        const folderListState =
          self.rootStore.view.initializeReadonlyFolderState(rootFolder)
        const name = ` "${listing.name}"`
        showMoveToFolder({
          title: 'Share Script',
          itemName: name,
          failureMessage: 'Could not share' + name,
          warningMessage: SHARE_SCRIPT_WARNING_MESSAGE,
          onSubmit: (folderId) => listing.moveToFolder(folderId),
          folderListState: folderListState,
        })
      }
    },
    togglePageLock() {
      self._chooApp.emitter.emit('editor:togglePageLock')
    },
    toggleElementNumbers(blockType: NodeTypeKey) {
      self._chooApp.emitter.emit('editor:toggleElementNumbers', blockType)
    },
    finishUnresolvingComment(commentId: string) {
      self._chooApp.emitter.emit(
        'editor:updateUnresolvedCommentMarks',
        commentId
      )
    },
    sendScriptToChoo(script: ScriptPayload) {
      self._chooApp.emitter.emit('editor:init', script)
    },
  }))
