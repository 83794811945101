import { keymap } from 'prosemirror-keymap'
import { undoInputRule } from 'prosemirror-inputrules'
import { chainCommands, joinUp, joinDown, lift } from 'prosemirror-commands'
import { types } from '@showrunner/codex'
import { retrieveCommentIds } from '@util'
import { insertHardBreak, insertPageBreak } from './commands'
import { selectAlmostAll } from './select-almost-all'
import { getTabHandler } from './tab.js'
import { lockedPageEnter, getEnterHandler } from './enter'
import {
  emptyBlockBackspace,
  dynamicPageBackspace,
  lockedPageBackspace,
  lockedPageDelete,
} from './backspace.js'

/**
 * Create a plugin to handle editor-specific key bindings.
 * Must be declared before other keymaps to intercept user actions first.
 * @param {object} config - plugin config
 * @param {function} config.emit - event emitter
 * @param {object} config.script - script object from backend
 * @param {string} config.script.type - script doc type
 */

export function editorKeymapPlugin({ emit, script }) {
  const { type } = script
  const handleEnter = getEnterHandler(type, emit)
  const handleTab = getTabHandler(type, emit)

  const cutShortcut = (viewState) => {
    emit('editor:setCutComments', retrieveCommentIds(viewState))
    // carry on either way
    return false
  }
  const keys = {
    // fun fact: on a mac the delete key is actually backspace (unless you hold down fn)
    Backspace: chainCommands(
      lockedPageBackspace,
      dynamicPageBackspace,
      emptyBlockBackspace,
      undoInputRule
    ),
    Delete: lockedPageDelete,
    'Alt-ArrowUp': joinUp,
    'Alt-ArrowDown': joinDown,
    'Mod-BracketLeft': lift,
    // Enter & Friends
    Enter: chainCommands(lockedPageEnter, handleEnter),
    // stopgap measure: disable hard breaks in the marginless script type
    'Shift-Enter':
      type === types.CLASSIC
        ? chainCommands(lockedPageEnter, handleEnter)
        : chainCommands(lockedPageEnter, insertHardBreak),
    // TODO: phase this out after we've advertised the new shortcut for a spell
    'Ctrl-Enter': chainCommands(lockedPageEnter, insertPageBreak),
    // NOTE: all keydown handlers below are overriding browser defaults.
    // this may prove to be a bad idea! so says nate.
    // TAB!!!
    // NOTE: tab and shift+tab are used for accessibility
    Tab: (state, dispatch) => handleTab(state, dispatch),
    'Shift-Tab': (state, dispatch) => handleTab(state, dispatch, true),
    'Mod-x': cutShortcut,
    'Mod-a': selectAlmostAll,
  }
  return keymap(keys)
}
