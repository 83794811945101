import { format } from 'date-fns'
import { HoverCard, Stack, Text } from '@mantine/core'
import { TIME_DAY_YEAR_MASK, useFormattedTimestamp } from '@hooks'

import styles from './HeaderTimestamp.module.scss'

export const HeaderTimestamp = ({
  userName,
  at,
  createdAt,
}: {
  at: Date
  createdAt?: Date
  userName?: string
}) => (
  <Stack>
    <HoverCard
      width={300}
      openDelay={750}
      shadow="lg"
      classNames={{
        dropdown: styles.headerTimestamp_dropdown,
      }}
    >
      <HoverCard.Target>
        <Text c="dimmed" size="sm" truncate="end" px={10}>
          {useFormattedTimestamp({ date: at, user: userName })}
        </Text>
      </HoverCard.Target>
      {createdAt && (
        <HoverCard.Dropdown>
          <Stack gap="xs">
            <Text size="sm" c="dimmed">
              {`Created at ${format(createdAt, TIME_DAY_YEAR_MASK)}`}
            </Text>
          </Stack>
        </HoverCard.Dropdown>
      )}
    </HoverCard>
  </Stack>
)
