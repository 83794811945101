import { PluginKey } from 'prosemirror-state'
// Constants shared between page breaker functions
const pageBreakerKey = new PluginKey('PAGE_BREAKER_PLUGIN')
const DOC_OFFSET = 1
const MAX_LINES = 54
export { pageBreakerKey }
export { MAX_LINES }
export { DOC_OFFSET }
export default {
  pageBreakerKey,
  MAX_LINES,
  DOC_OFFSET,
}
