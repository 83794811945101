import { Stack } from '@mantine/core'
import { useQuery } from '@tanstack/react-query'
import { useMst } from '@state'
import { Loader } from '@components/Loader'
import { Toast } from '@components/Toast'
import { CommentInfo } from './Info'
import { CommentMock } from './Mock'
import { OpenComments } from './Open'
import { ResolvedComments } from './Resolved'
import { CommentFilter, filterComments } from './helpers'

import styles from './CommentHistory.module.scss'

const CommentError = () => (
  <Toast
    dismissable={false}
    icon="fas fa-circle-exclamation"
    message="We encountered an unexpected error retrieving comments"
    type="error"
  ></Toast>
)

export const CommentList = ({
  filter,
  scriptId,
  containerHeight,
}: {
  containerHeight: number
  filter: CommentFilter
  scriptId: string
}) => {
  const { analytics, apiClient, view } = useMst()

  const {
    isLoading,
    isError,
    data: comments,
  } = useQuery({
    queryFn: async () => {
      analytics.track(analytics.EVENTS.SCRIPT_COMMENT_FEED_OPENED)
      return apiClient.fetchCommentHistory({ scriptId })
    },
    queryKey: ['commentHistory', { scriptId }],
    staleTime: Infinity,
    cacheTime: 0,
  })

  const filteredComments = filterComments(filter, comments)
  const showMock = view.isDebugEnabled('mock')
  const showInfo = !isLoading && !isError && filteredComments.length < 1
  const showOpen = filter === 'open' && !showInfo && !showMock
  const showResolved = filter === 'resolved' && !showInfo && !showMock

  return (
    <Stack
      justify="flex-start"
      gap="xs"
      className={styles.commentHistory_list}
      style={{ maxHeight: containerHeight - 105 }}
    >
      {isLoading && <Loader visible />}
      {isError && <CommentError />}
      {showMock && <CommentMock />}
      {showInfo && <CommentInfo filter={filter} />}
      {showOpen && <OpenComments comments={filteredComments} />}
      {showResolved && (
        <ResolvedComments comments={filteredComments} scriptId={scriptId} />
      )}
    </Stack>
  )
}
