import { types } from '@showrunner/codex'
const {
  types: {
    // doc types
    SCREENPLAY,
    VARIETY,
    CLASSIC,
    // block types
    ACTION,
    CHARACTER,
    DIALOGUE,
    PARENTHETICAL,
    END_OF_ACT,
    GENERAL,
    NEW_ACT,
    SCENE_HEADING,
    TRANSITION,
    BRACKET,
    SLUG,
    // special types
    DUAL_DIALOGUE,
  },
} = { types }
// the scripto classic emulator is light on context dependent tab ordering
const CLASSIC_TAB_ORDER = [DIALOGUE, BRACKET, CHARACTER, PARENTHETICAL, SLUG]
// map of nodes to create on enter for given current node and doc type
const enter = {
  // same in both
  [CHARACTER]: same(DIALOGUE),
  [END_OF_ACT]: same(NEW_ACT),
  [GENERAL]: same(GENERAL),
  [PARENTHETICAL]: same(DIALOGUE),
  // screenplay only
  [ACTION]: { [SCREENPLAY]: ACTION },
  [SCENE_HEADING]: { [SCREENPLAY]: ACTION },
  [TRANSITION]: { [SCREENPLAY]: SCENE_HEADING },
  // variety only
  [BRACKET]: {
    [VARIETY]: DIALOGUE,
    [CLASSIC]: DIALOGUE,
  },
  [SLUG]: {
    [VARIETY]: DIALOGUE,
    [CLASSIC]: DIALOGUE,
  },
  // different in both
  [DIALOGUE]: {
    [SCREENPLAY]: ACTION,
    [VARIETY]: DIALOGUE,
    [CLASSIC]: DIALOGUE,
  },
  [NEW_ACT]: {
    [SCREENPLAY]: SCENE_HEADING,
    [VARIETY]: SLUG,
    [CLASSIC]: DIALOGUE,
  },
}
// map of valid nodes to tab to for given last node and doc type
const tab = {
  blank: {
    [SCREENPLAY]: [SCENE_HEADING, ACTION, CHARACTER],
    [VARIETY]: [DIALOGUE, CHARACTER, SLUG, BRACKET, GENERAL],
    [CLASSIC]: CLASSIC_TAB_ORDER,
  },
  // same in both
  [END_OF_ACT]: same([NEW_ACT]),
  [ACTION]: {
    [SCREENPLAY]: [ACTION, CHARACTER, TRANSITION, SCENE_HEADING],
  },
  // screenplay only
  [SCENE_HEADING]: {
    [SCREENPLAY]: [ACTION, CHARACTER, TRANSITION, SCENE_HEADING],
  },
  [TRANSITION]: {
    [SCREENPLAY]: [ACTION, CHARACTER, TRANSITION, SCENE_HEADING],
  },
  // variety only
  [BRACKET]: {
    [VARIETY]: [DIALOGUE, BRACKET, CHARACTER, PARENTHETICAL],
    [CLASSIC]: CLASSIC_TAB_ORDER,
  },
  [SLUG]: {
    [VARIETY]: [CHARACTER, DIALOGUE],
    [CLASSIC]: CLASSIC_TAB_ORDER,
  },
  // different in both
  [GENERAL]: {
    [SCREENPLAY]: [GENERAL, ACTION, CHARACTER, TRANSITION, SCENE_HEADING],
    [VARIETY]: [CHARACTER, DIALOGUE, GENERAL],
  },
  [CHARACTER]: {
    [SCREENPLAY]: [DIALOGUE, PARENTHETICAL],
    [VARIETY]: [DIALOGUE, PARENTHETICAL],
    [CLASSIC]: CLASSIC_TAB_ORDER,
  },
  [DIALOGUE]: {
    [SCREENPLAY]: [ACTION, CHARACTER, PARENTHETICAL, TRANSITION, SCENE_HEADING],
    [VARIETY]: [DIALOGUE, PARENTHETICAL, CHARACTER, BRACKET],
    [CLASSIC]: CLASSIC_TAB_ORDER,
  },
  [NEW_ACT]: {
    [SCREENPLAY]: [ACTION, CHARACTER, TRANSITION, SCENE_HEADING],
    [VARIETY]: [CHARACTER, DIALOGUE, GENERAL, SLUG],
    [CLASSIC]: CLASSIC_TAB_ORDER,
  },
  [PARENTHETICAL]: {
    [SCREENPLAY]: [DIALOGUE, PARENTHETICAL],
    [VARIETY]: [DIALOGUE, PARENTHETICAL, CHARACTER, BRACKET],
    [CLASSIC]: CLASSIC_TAB_ORDER,
  },
  // special case, but same as dialogue
  [DUAL_DIALOGUE]: {
    [SCREENPLAY]: [ACTION, CHARACTER, TRANSITION, SCENE_HEADING],
    [VARIETY]: [BRACKET, CHARACTER, PARENTHETICAL],
  },
}
/**
 * convenience wrapper for nodes with same behavior in both doc types
 * @param {string|array} nodeType - node type string or array of strings
 */
function same(nodeType) {
  return {
    [SCREENPLAY]: nodeType,
    [VARIETY]: nodeType,
    [CLASSIC]: nodeType,
  }
}
export { enter }
export { tab }
export default {
  enter,
  tab,
}
