import React from 'react'
import { NodeTypeMap } from '@showrunner/codex'
import { Menu } from '@mantine/core'
import { PaidMenuItem } from './PaidMenuItem'
import { ILoadedScript } from '@state/types'
import { showAsyncConfirmModal } from '@components/Modals'

export const NumberingItems = ({
  isUnpaidOrg,
  script,
}: {
  isUnpaidOrg: boolean
  script: ILoadedScript
}) => {
  const numberingItems: React.ReactNode[] = []

  script.manuallyNumberableBlockTypes.forEach((t) => {
    const match = script.elementNumberMap.get(t)
    if (match) {
      const displayType = t === NodeTypeMap.SCENE_HEADING ? 'scene' : t
      const title = (match.isOn ? 'Remove' : 'Add') + ` ${displayType} numbers`

      const toggleNumbering = () => {
        if (match.isOn) {
          showAsyncConfirmModal({
            title: 'Element Numbering',
            children: `Are you sure you want to remove all ${displayType} numbers?`,
            errorMessage: 'Failed to remove element numbers',
            onConfirm: () => Promise.resolve(script.toggleElementNumbers(t)),
            dangerous: true,
          })
        } else {
          script.toggleElementNumbers(t)
        }
      }

      numberingItems.push(
        <PaidMenuItem
          key={t}
          title={title}
          onClick={toggleNumbering}
          disabled={!match.exists || !script.isEditable}
          isUnpaidOrg={isUnpaidOrg}
        ></PaidMenuItem>
      )
    }
  })

  return (
    <>
      {numberingItems}
      {numberingItems.length > 0 && <Menu.Divider />}
    </>
  )
}
