import { types, getSnapshot } from 'mobx-state-tree'
import { IRundown } from '@state/types'
import { RundownRowData } from '@util/ScriptoApiClient/types'
import { RowLevel, ZRowLevel, ROW_LEVEL_BLOB_FIELD } from '@util/rundowns'
import { isGridBlobKey, GridBlobColumnKey } from '@util/rundowns'
import { BaseModel } from '../BaseModel'

const stripBlobKeyPrefix = (key: GridBlobColumnKey): string =>
  key.replace(/^blobData\./, '')

export const RundownRow = BaseModel.named('RundownRow')
  .props({
    id: types.identifierNumber,
    rowTypeId: types.enumeration<'script' | 'element' | 'header'>([
      'script',
      'element',
      'header',
    ]),
    sequence: types.number,
    rundownId: types.number,
    identityScriptId: types.maybeNull(types.string),
    linkedScriptId: types.maybeNull(types.string),
    blockId: types.maybeNull(types.string),
    blobData: types.map(types.frozen<JSONValue>()),
    scriptDragStatus: types.maybe(
      types.enumeration<'over' | 'under'>(['over', 'under'])
    ),

    selectedInGrid: false,
  })
  .views((self) => ({
    getBlobValue(key: GridBlobColumnKey) {
      return self.blobData.get(stripBlobKeyPrefix(key))
    },
    // To avoid circular dependencies, we'll use the root store and verify
    // the ID (in the future we might have many rundowns in MST in a map)
    get parentRundown(): IRundown | undefined {
      const currentRundown = self.rootStore.currentRundown
      if (currentRundown && currentRundown.id === self.rundownId) {
        return currentRundown
      }
    },
    // This gives us a non-observable POJO that's we can use for ag-grid. We
    // want it to be non-observable because ag-grid modifies the blobData values
    // directly and then we handle the event to try to save the edits then refresh
    // the grid rows. (The changes are temporary and we don't want them to trigger
    // the full row refresh, hence the non-observable POJO)
    get clone() {
      const snapshot = getSnapshot(self)
      return {
        ...snapshot,
        blobData: { ...snapshot.blobData },
      }
    },
    get pojo(): RundownRowData {
      const {
        id,
        linkedScriptId,
        identityScriptId,
        rowTypeId,
        rundownId,
        blockId,
        sequence,
      } = self
      return {
        id,
        linkedScriptId,
        identityScriptId,
        rowTypeId,
        rundownId,
        blockId,
        sequence,
        blobData: { ...getSnapshot(self).blobData },
      }
    },
    get rowLevel(): RowLevel | undefined {
      const raw = self.blobData.get(ROW_LEVEL_BLOB_FIELD)
      const parsed = ZRowLevel.safeParse(raw)
      if (parsed.success) {
        return parsed.data
      }
    },
    get rowLevelName(): string | undefined {
      const { rowLevel, parentRundown } = this
      if (rowLevel && parentRundown) {
        return parentRundown.schema.schema.levels[rowLevel]?.displayName
      }
    },
    get scriptId(): string | null {
      return self.linkedScriptId ?? self.identityScriptId
    },
  }))
  .actions((self) => ({
    setSelected(value: boolean) {
      self.selectedInGrid = value
    },
    setSequence(value: number) {
      self.sequence = value
    },
    setDragStatus(value: 'over' | 'under') {
      self.scriptDragStatus = value
    },
    clearDragStatus() {
      self.scriptDragStatus = undefined
    },
    updateBlobValue(key: string, value: JSONValue | null | undefined) {
      if (value === null || value === undefined) {
        self.blobData.delete(key)
      } else {
        self.blobData.set(key, value)
      }
    },
    updateValue(key: string, value: JSONValue | null | undefined) {
      // TODO: once all clients are using the bulk endpoint for blob updates, this
      // endpoint will just be for other fields and we can stop checking for the gridBlobKeys
      if (isGridBlobKey(key)) {
        const blobPath = stripBlobKeyPrefix(key)
        this.updateBlobValue(blobPath, value)
      } else {
        // eslint-disable-next-line no-console
        console.error('BUG: Invalid rundown edit', { key, value })
      }
    },
  }))
