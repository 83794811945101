import * as AgGrid from '@ag-grid-community/core'
import {
  RundownColDef,
  CONTROLS_COLUMN_ID,
  RundownColumnType,
} from '@util/rundowns'
import { format } from 'date-fns'

import * as helpers from './durationHelpers'

import { CheckboxCell, ControlsCell, SnapstreamLinkCell } from '../cells'
import styles from './ColumnTypes.module.scss'
import { blockIdGetter, scriptIdGetter } from './idGetters'

const text: AgGrid.ColDef = {
  editable: true,
  width: 300,
}

const longText: AgGrid.ColDef = {
  editable: true,
  width: 300,
  cellEditor: 'agLargeTextCellEditor',
  cellEditorPopup: true,
  cellEditorParams: {
    maxLength: 2500,
    rows: 10,
    cols: 50,
  },
}

export const buildControlsColumn = (editable: boolean): AgGrid.ColDef => ({
  colId: CONTROLS_COLUMN_ID,
  cellRenderer: ControlsCell,
  headerName: '',
  lockPosition: 'left',
  suppressMovable: true,
  pinned: true,
  resizable: false,
  suppressColumnsToolPanel: true,
  cellClass: [styles.column___controls],
  width: editable ? 70 : 34,
  cellRendererParams: {
    showEditControls: editable,
  },
})

const duration: AgGrid.ColDef = {
  editable: true,
  valueFormatter: helpers.durationFormatter,
  valueParser: helpers.durationParser,
  cellEditorParams: {
    useFormatter: true,
  },
  cellClass: [styles.column___rightAlign],
}

const cumulativeDuration: AgGrid.ColDef = {
  valueFormatter: helpers.durationFormatter,
  valueGetter: helpers.cumulativeGetter,
  cellClass: [styles.column___rightAlign, styles.column___readOnly],
}

const readonlyBlockId: AgGrid.ColDef = {
  editable: false,
  valueGetter: blockIdGetter,
  cellClass: [styles.column___readOnly],
}

const readonlyScriptId: AgGrid.ColDef = {
  editable: false,
  valueGetter: scriptIdGetter,
  cellClass: [styles.column___readOnly],
}

const snapstreamLink: AgGrid.ColDef = {
  cellRenderer: SnapstreamLinkCell,
  cellClass: [styles.column___snapstreamLink],
}

const frontTiming: AgGrid.ColDef = {
  valueFormatter: helpers.timeFormatter,
  valueGetter: helpers.frontTimingGetter,
  cellClass: [styles.column___readOnly, styles.column___rightAlign],
}

const backTiming: AgGrid.ColDef = {
  valueFormatter: helpers.timeFormatter,
  valueGetter: helpers.backTimingGetter,
  cellClass: [styles.column___readOnly, styles.column___rightAlign],
}

const itemNumber: AgGrid.ColDef = {
  editable: true,
  headerName: '#',
  width: 70,
}

const checkbox: AgGrid.ColDef = {
  cellRenderer: CheckboxCell,
}

const columnTypes: Record<RundownColumnType, AgGrid.ColDef> = {
  backTiming,
  checkbox,
  cumulativeDuration,
  duration,
  frontTiming,
  itemNumber,
  snapstreamLink,
  text,
  longText,
  readonlyBlockId,
  readonlyScriptId,
}

export const schemaColumnToColDef = (
  schemaColumn: RundownColDef
): AgGrid.ColDef & { colId: string } => {
  const { rundownColumnType, ...overrides } = schemaColumn
  const baseColDef = columnTypes[rundownColumnType]
  return {
    ...baseColDef,
    ...overrides,
    autoHeight: true,
  }
}

const maybeFormatDate = (value: unknown) => {
  if (value instanceof Date) {
    return format(value, 'yyyy-MM-dd HH:mm:ss')
  }
  return value
}

// These transform some of our internal values to strings when exporting
// or copying to clipboard
export const CELL_VALUE_EXPORTER: Partial<
  Record<RundownColumnType, (value: unknown) => unknown>
> = {
  duration: helpers.durationExporter,
  cumulativeDuration: helpers.durationExporter,
  frontTiming: maybeFormatDate,
  backTiming: maybeFormatDate,
}
