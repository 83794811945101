import { AxiosResponse } from 'axios'
import sanitizeFilename from 'sanitize-filename'
import { ApiConfig } from './types'

type CharacterReportParams = {
  scriptId: string
  timestamp?: string
}

type ExportResponse = { fileName: string; text: string; contentType: string }

// we rely on API to tell us the appropriate filename
// clientside we decode encoded whitespace and trim prefix
const transformResponse = (result: AxiosResponse<string>): ExportResponse => {
  const rawFileName = result.headers['content-disposition'] ?? 'Untitled'

  const fileName = sanitizeFilename(
    decodeURI(rawFileName.replace('Attachment;filename=', ''))
  )

  const contentType = result.headers['content-type'] ?? 'text/plain'

  const text = result.data
  return {
    text,
    fileName,
    contentType,
  }
}

export const exportBracketsList: ApiConfig<string, ExportResponse> = {
  buildRequest: (scriptId) => ({
    method: 'GET',
    url: `/scripts/${scriptId}/export/rundown`,
  }),
  transformResponse,
}

export const exportCharacterReport: ApiConfig<
  CharacterReportParams,
  ExportResponse
> = {
  buildRequest: ({ scriptId, timestamp }) => ({
    method: 'GET',
    url:
      `/scripts/${scriptId}/export/character-report` +
      (timestamp ? `?timestamp=${timestamp}` : ''),
  }),
  transformResponse,
}

// we can start supporting exporting snapshots to FDX again someday if we want
export const exportFdx: ApiConfig<string, ExportResponse> = {
  buildRequest: (scriptId) => ({
    method: 'GET',
    url: `/scripts/${scriptId}/export/fdx`,
  }),
  transformResponse,
}

export const exportFountain: ApiConfig<string, ExportResponse> = {
  buildRequest: (scriptId) => ({
    method: 'GET',
    url: `/scripts/${scriptId}/export/fountain`,
  }),
  transformResponse,
}

export const exportPrompter: ApiConfig<string, ExportResponse> = {
  buildRequest: (scriptId) => ({
    method: 'GET',
    url: `/scripts/${scriptId}/export/prompter`,
  }),
  transformResponse,
}
