/*
TODO: advanced search
this was a fun excuse to get more familiar with React Context but
all this stuff could have just easily been landed in mst
*/
import React from 'react'
import { SearchCriteria } from './helpers'

type SearchDispatch = React.Dispatch<React.SetStateAction<SearchCriteria>>

export const defaultCriteria = {
  docType: undefined,
  folderId: undefined,
  includeTitleOnly: false,
  includeTrash: false,
  modifiedAfter: undefined,
  modifiedBefore: undefined,
  phrase: '',
}

export const SearchContext = React.createContext<{
  criteria: SearchCriteria
  setCriteria: SearchDispatch
} | null>(null)

export const PendingSearchContext = React.createContext<{
  pendingCriteria: SearchCriteria
  setPendingCriteria: SearchDispatch
  mergePendingCriteria: (val: Partial<SearchCriteria>) => void
} | null>(null)

export function SearchProvider({ children }: { children: React.ReactNode }) {
  const [criteria, setCriteria] =
    React.useState<SearchCriteria>(defaultCriteria)

  const [pendingCriteria, setPendingCriteria] =
    React.useState<SearchCriteria>(defaultCriteria)

  const mergePendingCriteria = (val: Partial<SearchCriteria>) =>
    setPendingCriteria({
      ...pendingCriteria,
      ...val,
    })

  return (
    <SearchContext.Provider value={{ criteria, setCriteria }}>
      <PendingSearchContext.Provider
        value={{ pendingCriteria, setPendingCriteria, mergePendingCriteria }}
      >
        {children}
      </PendingSearchContext.Provider>
    </SearchContext.Provider>
  )
}

export const useSearchContext = () => React.useContext(SearchContext)
export const usePendingSearchContext = () =>
  React.useContext(PendingSearchContext)
