import { observer } from 'mobx-react-lite'
import { showEpisodeLengthModal } from './EpisodeLengthModal'
import { RowHeightMenu } from './RowHeightMenu'
import { showStartTimeEndTimeModal } from './StartTimeEndTimeModal'
import { showError } from '@components/Modals'
import { StatusBar } from '@components/StatusBar'
import { useMst } from '@state'
import { IRundown } from '@state/types'
import { formatSeconds, pluralize, saveTextToFile } from '@util'
import { templateSchema, ZRundownSchema, sumColumnValue } from '@util/rundowns'
import { successColor } from '@util/constants'

const parseOverUnder = ({
  episodeLength,
  totalRuntime,
}: {
  episodeLength: number
  totalRuntime: number
}): {
  message: string
  isOverUnder?: boolean
} => {
  if (episodeLength === 0) {
    return {
      message: '',
    }
  }
  const diff = episodeLength - totalRuntime
  if (diff === 0) {
    return {
      message: 'Timed OK',
    }
  }

  const isOver = diff < 0
  const message = `${isOver ? 'Over' : 'Under'} ${formatSeconds(
    Math.abs(diff)
  )}`
  return {
    message,
    isOverUnder: true,
  }
}

export const RundownStatusbar = observer(function RundownStatusbar({
  rundown,
}: {
  rundown: IRundown
}) {
  const { user, view } = useMst()
  const { primaryDurationField, durationMode: rundownDurationMode } =
    rundown.schema.schema
  const selectedRowCount = rundown.selectedRows.length
  const hiddenColCount = rundown.hiddenScreenColumnCount
  const totalRuntime = sumColumnValue({
    rowData: rundown.immutableRowData,
    field: primaryDurationField,
  })

  const handleApplyTemplate = () => {
    // this check is almost unneeded because we're using typescript in
    // the templateSchema file, but just in case..
    const validationResult = ZRundownSchema.safeParse(templateSchema)
    if (validationResult.success) {
      rundown.setPreviewSchema(validationResult.data)
    } else {
      showError(
        'There are problems with your schema, see the console for details'
      )
      // eslint-disable-next-line no-console
      console.log(validationResult)
    }
  }

  const episodeLength = rundown.blobData.episodeLengthSeconds
  const overUnderInfo = parseOverUnder({ episodeLength, totalRuntime })

  const showDurationConfigModal = () => {
    if (rundownDurationMode === 'start-end-time') {
      showStartTimeEndTimeModal(rundown)
    } else if (rundownDurationMode === 'target-length') {
      showEpisodeLengthModal(rundown)
    }
  }

  const durationModeTooltip =
    rundownDurationMode === 'start-end-time'
      ? 'Configure start/end times'
      : 'Set episode length'

  return (
    <StatusBar>
      <StatusBar.Section>
        <RowHeightMenu />
        {selectedRowCount > 0 && (
          <StatusBar.Text>
            {selectedRowCount} selected {pluralize(selectedRowCount, 'row')}
          </StatusBar.Text>
        )}
        {hiddenColCount > 0 && (
          <StatusBar.Text>
            {hiddenColCount} hidden {pluralize(hiddenColCount, 'column')}
          </StatusBar.Text>
        )}
        {view.isDebugEnabled('schema') && (
          <>
            <StatusBar.Button
              tooltip="Temporarily apply templateSchema.ts to this rundown"
              onClick={handleApplyTemplate}
            >
              Preview Custom Schema
            </StatusBar.Button>
            <StatusBar.Button
              tooltip="Download a JSON copy of the template schema"
              onClick={() =>
                saveTextToFile({
                  text: JSON.stringify(templateSchema, null, 2),
                  fileName: 'template-rundown-schema.json',
                })
              }
            >
              Export as JSON
            </StatusBar.Button>
          </>
        )}
      </StatusBar.Section>
      <StatusBar.Section>
        {rundownDurationMode && (
          <>
            <StatusBar.Text
              txtColor={overUnderInfo.isOverUnder ? 'red' : successColor}
            >
              {overUnderInfo.message}
            </StatusBar.Text>
            <StatusBar.Text>
              Total run time: <strong>{formatSeconds(totalRuntime)}</strong>
            </StatusBar.Text>
            {user.canEditRundowns && (
              <StatusBar.FaActionIcon
                tooltip={durationModeTooltip}
                icon="fa-stopwatch"
                onClick={showDurationConfigModal}
                dangerous={!rundown.hasTrackedTiming}
              />
            )}
          </>
        )}
      </StatusBar.Section>
    </StatusBar>
  )
})
