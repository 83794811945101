import React from 'react'
import {
  Button,
  Group,
  Stack,
  Checkbox,
  Text,
  Modal,
  ScrollArea,
} from '@mantine/core'
import NiceModal from '@ebay/nice-modal-react'
import { useModalControls } from '@components/Modals'
import { IRundown } from '@state/types'
import { RundownLayoutType } from '@util/LocalPersistence'
import { getConfigurableColDefs } from '@components/RundownGrid/columns'

import styles from './ColumnVisibilityModal.module.scss'

export const ColumnVisibilityControls = ({
  rundown,
  layout,
}: {
  rundown: IRundown
  layout: RundownLayoutType
}) => {
  const colDefs = getConfigurableColDefs({
    rundown,
    layout,
  })

  const [hiddenColumns, setHiddenColumns] = React.useState<string[]>(
    colDefs.filter((cd) => cd.hide).map((cd) => cd.colId ?? '')
  )

  const toggleColumnVisibility = (colId: string) => {
    const hide = !hiddenColumns.includes(colId)
    if (hide) {
      setHiddenColumns([...hiddenColumns, colId])
    } else {
      setHiddenColumns(hiddenColumns.filter((id) => id !== colId))
    }

    // We need to call the column api on the mounted ag grid component
    const gridRef =
      layout === 'screen' ? rundown.gridRef : rundown.printableGridRef

    gridRef.current?.columnApi?.applyColumnState({
      state: [{ colId, hide }],
    })
  }

  return (
    <>
      <Stack gap="xs">
        {colDefs.map((cd) => (
          <Group key={cd.colId}>
            <Checkbox
              checked={!hiddenColumns.includes(String(cd.colId))}
              onChange={() => toggleColumnVisibility(String(cd.colId))}
            />
            <Text>{cd.headerName}</Text>
          </Group>
        ))}
      </Stack>
    </>
  )
}

const ColumnVisibilityModal = NiceModal.create<{ rundown: IRundown }>(
  ({ rundown }) => {
    const { opened, onClose } = useModalControls()

    return (
      <Modal
        title="Hide / Show Columns"
        opened={opened}
        onClose={onClose}
        size="sm"
      >
        <Stack>
          <ScrollArea.Autosize
            className={styles.scrollArea}
            mah={250}
            type="auto"
          >
            <div className={styles.scrollArea_contents}>
              <ColumnVisibilityControls rundown={rundown} layout="screen" />
            </div>
          </ScrollArea.Autosize>
          <Group justify="center">
            <Button onClick={onClose}>Done</Button>
          </Group>
        </Stack>
      </Modal>
    )
  }
)

export const showColumnVisibilityModal = (rundown: IRundown) => {
  NiceModal.show(ColumnVisibilityModal, { rundown })
}
