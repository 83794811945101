import { setBlockType } from 'prosemirror-commands'
import {
  EditorState,
  AllSelection,
  NodeSelection,
  TextSelection,
  SelectionRange,
} from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import { Node as PmNode, Fragment, MarkType } from 'prosemirror-model'
import {
  AlignmentType,
  MarkTypeKey,
  MarkTypeMap,
  NodeTypeMap,
  schema,
  util,
} from '@showrunner/codex'
import {
  ScriptSnapshotPayload,
  ScriptPayload,
  ScriptJson,
} from './ScriptoApiClient/types'
import {
  checkWrap,
  isWrappedBlock,
  maybeTrimWhitespace,
  maybeStripWrapChars,
  maybeAddWrapChars,
} from './wrapped-block-helpers'
import { marginInfo, marginSlots } from '@choo-app/lib/editor/plugins/margins'
import { indentationStylesFactory } from '@choo-app/lib/editor/plugins/indentation-styles'
import { inlineChangesPlugin } from '@choo-app/lib/editor/plugins/revision-asterisks'

const { BRACKET, DIALOGUE, CHARACTER, PARENTHETICAL, GENERAL } = NodeTypeMap

// same RE as in hyperlinker.js (without EOL token)
const HAS_URL =
  /(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?/i

export const createProsemirrorDoc = (json: ScriptJson) =>
  PmNode.fromJSON(schema, json)

export const createSnapshotEditorState = (
  snapshot: Pick<ScriptSnapshotPayload, 'doc' | 'scriptFormat'>
): EditorState => {
  const plugins = [
    marginInfo({ script: { ...snapshot, type: snapshot.doc.attrs.docType } }),
    marginSlots(),
  ]

  if (snapshot.scriptFormat.definition.scriptType === 'ink') {
    plugins.push(indentationStylesFactory())
  }

  const pmDoc = snapshot.doc
  return EditorState.create({
    doc: PmNode.fromJSON(schema, pmDoc),
    plugins,
  })
}

export const createComparisonEditorState = ({
  script,
  snapshot,
}: {
  script: Pick<ScriptPayload, 'doc' | 'scriptFormat'>
  snapshot: Pick<ScriptSnapshotPayload, 'doc'>
}): EditorState => {
  const snapshotDoc = PmNode.fromJSON(schema, snapshot.doc)
  const plugins = [
    marginInfo({ script: { ...script, type: script.doc.attrs.docType } }),
    marginSlots(),
    inlineChangesPlugin({ snapshotDoc }),
  ]

  return EditorState.create({
    doc: PmNode.fromJSON(schema, script.doc),
    plugins,
  })
}

export const incrementPushCount = (currValue: unknown): number => {
  const numericValue =
    typeof currValue === 'number' ? currValue : parseInt(String(currValue))
  return isNaN(numericValue) ? 1 : numericValue + 1
}

export const buildScriptBreakdown = (
  payload: ScriptPayload
): util.ScriptBreakdown => {
  const pmDoc = PmNode.fromJSON(schema, payload.doc)
  return new util.ScriptBreakdown(pmDoc, payload.readRate)
}

const { SCENE_HEADING, SLUG, NEW_ACT } = NodeTypeMap
const isSleneStart = (node: PmNode): boolean => {
  return SCENE_HEADING === node.type.name || SLUG === node.type.name
}

const isSleneEnd = (node: PmNode): boolean => {
  return isSleneStart(node) || node.type.name === NEW_ACT
}

// Given a slug or sceneHeading ID, extract a fragment from the
// doc that starts with that ID and ends at the next new_act or slug or sceneHeading
export const extractSleneFragment = (
  doc: PmNode,
  sleneId: string
): Fragment | null => {
  let startPosition = -1
  let endPosition: number | undefined = undefined
  doc.descendants((node, pos) => {
    if (startPosition < 0) {
      if (isSleneStart(node) && node.attrs.id === sleneId) {
        startPosition = pos
      }
    } else if (endPosition === undefined) {
      if (isSleneEnd(node)) {
        endPosition = pos
      }
    }
  })
  if (startPosition > -1) {
    const slice = doc.slice(startPosition, endPosition)
    return slice.content
  }
  return null
}

// extract a list of slene names/ids from a doc
export const listSlenes = (
  doc: PmNode
): Array<{ id: string; label: string }> => {
  const result: Array<{ id: string; label: string }> = []
  doc.descendants((node) => {
    if (isSleneStart(node) && typeof node.attrs.id === 'string') {
      result.push({
        id: node.attrs.id,
        label: node.textContent,
      })
    }
  })

  return result
}

export const shouldEnableTimingExclusion = (state: EditorState) => {
  if (!state) return false
  const { doc, selection } = state
  if (selection instanceof AllSelection) return false

  let enabled = false
  doc.nodesBetween(selection.from, selection.to, (node) => {
    if (blockIsTimeable(node)) {
      enabled = true
    }
  })
  return enabled
}

export const isSelectionUntimed = (state: EditorState) => {
  if (!state) return false
  const { doc, selection } = state
  if (selection instanceof AllSelection) return false

  let untimed = false
  doc.nodesBetween(selection.from, selection.to, (node) => {
    if (node.attrs?.untimed) {
      untimed = true
    }
  })
  return untimed
}

/**
 * @param {object} viewState - PM view state
 * @param {function} [viewDispatch] - PM event dispatcher
 * @returns {Transaction} PM transaction
 */
export const toggleTimingExclusion = (
  state: EditorState,
  dispatch: Dispatch
) => {
  const { doc, selection, tr } = state

  // loop through all the nodes in the selection once to determine if any of
  // them are already untimed
  let prevUntimed = false
  doc.nodesBetween(selection.from, selection.to, (node) => {
    if (!blockIsTimeable(node)) return
    if (!prevUntimed) {
      prevUntimed = node.attrs.untimed === true
    }
  })

  // update exclusion for all timed nodes in the selection accordingly
  doc.nodesBetween(selection.from, selection.to, (node, position) => {
    if (!blockIsTimeable(node)) return
    const attrs = {
      ...node.attrs,
      untimed: prevUntimed ? null : true,
    }
    tr.setNodeMarkup(position, undefined, attrs, node.marks)
  })
  if (tr.steps.length > 0) {
    dispatch(tr)
  }
}

export const setAlignment = (
  alignment: AlignmentType,
  state: EditorState,
  dispatch: Dispatch
) => {
  const { doc, selection, tr } = state
  // update alignment for all nodes in selection that have an alignment attr
  doc.nodesBetween(selection.from, selection.to, (node, position) => {
    // only process nodes
    // ignore pages
    // ignore nodes without alignment prop
    const shouldSkip =
      node.isText ||
      node.type.name === NodeTypeMap.PAGE ||
      !('alignment' in node.attrs)
    if (shouldSkip) {
      return
    }
    const newAttrs = { ...node.attrs, alignment }
    tr.setNodeMarkup(position, undefined, newAttrs, node.marks)
  })
  dispatch(tr)
}

// Checks if the currently selected text is something that can
// can be modified (e.g. can we format, align, etc)
export const hasModifiableSelectedText = (editorView: EditorView) => {
  const { state } = editorView
  const readOnly = !editorView.editable
  const noSelection = state.selection.empty
  const isDocSelection = state.selection.$anchor.depth === 0
  const selectionTooBig = isSelectionTooBig(state)

  return !(readOnly || noSelection || isDocSelection || selectionTooBig)
}

export const shouldDisableAlignment = (state: EditorState) => {
  if (state.selection instanceof AllSelection) {
    return true
  }
  const { doc, selection } = state
  let shouldDisable = true
  doc.nodesBetween(selection.from, selection.to, (node) => {
    // show alignment if node isn't text or page and has alignment prop
    if (
      !node.isText &&
      node.type.name !== NodeTypeMap.PAGE &&
      'alignment' in node.attrs
    ) {
      shouldDisable = false
    }
  })
  return shouldDisable
}

/**
 * Applies a link mark with the given href to the current selection.
 *
 * @param {object} EditorView
 * @param {string} string
 */
export const addLinkMarkToSelection = (
  editorView: EditorView,
  href: string
) => {
  const { tr } = editorView.state
  const { from, to } = tr.selection
  const mark = schema.marks[MarkTypeMap.LINK].create({ href })
  tr.addMark(from, to, mark)
  tr.scrollIntoView().setMeta('paste', true).setMeta('uiEvent', 'paste')
  editorView.dispatch(tr)
}

// if the text being copy/pasted includes one or more URLs, we:
// 1. slice up the string to separate links and non-links
// 1. delete the existing selection (if present)
// 1. loop through our data structure backwards
// 1. injecting plain text as plain text
// 1. injecting urls as text with an appended mark
// we purposely avoid tr.mapping.map() because we
// need to refer to positions in the document that didnt exist
// prior to these intermediate steps
export const injectMarkedUpLinks = ({
  editorView,
  ds,
}: {
  editorView: EditorView
  ds: { text: string; url?: string }[]
}) => {
  const { tr } = editorView.state
  const { from, to } = tr.selection
  tr.insertText('', from, to)
  for (let i = ds.length - 1; i >= 0; i--) {
    // we use $from and not $to to ensure we account for the
    // selected text which might have just been obliterated
    tr.insertText(ds[i].text, from, from)
    if (ds[i].url) {
      const mark = schema.marks[MarkTypeMap.LINK].create({
        href: ds[i].url,
      })
      tr.addMark(from, from + ds[i].text.length, mark)
    }
  }
  tr.scrollIntoView().setMeta('paste', true).setMeta('uiEvent', 'paste')
  editorView.dispatch(tr)
}

// possibly expensive operation for big selections
export const selectionContainsFormattingMark = (state: EditorState) =>
  selectionContainsMark(MarkTypeMap.STRONG, state) ||
  selectionContainsMark(MarkTypeMap.EM, state) ||
  selectionContainsMark(MarkTypeMap.UNDERLINE, state) ||
  selectionContainsMark(MarkTypeMap.STRIKE, state)

export const LINK_MARK = schema.marks[MarkTypeMap.LINK]

const TIMED_BLOCKNAMES: readonly string[] = [
  BRACKET,
  DIALOGUE,
  CHARACTER,
  PARENTHETICAL,
  GENERAL,
]

const blockIsTimeable = (node: PmNode) =>
  !node.isText && TIMED_BLOCKNAMES.includes(node.type.name)

/**
 * Identifies selections that include 10 or more pages of content.
 * we arbitrarily disallow adding comments or marks to these 'big' selections
 * @param {EditorState} state - current editor state
 * @return {Boolean}
 */
export const isSelectionTooBig = (state: EditorState) => {
  const { $from, $to } = state.selection
  const fromPageIndex = $from.index(0)
  const toPageIndex = $to.index(0)
  const span = toPageIndex - fromPageIndex

  if (span > 9) {
    return true
  }
  return false
}

/*
  HT https://discuss.prosemirror.net/t/some-pointers-in-creating-a-casing-plugin/2805/2
  insertText() is much more terse, but looping through individual TextNodes is a more dependable way to ensure that marks are preserved, even when the selection includes a mix of them.

  it also (hopefully) leaves us better poised to support selections that span multiple blocks in the future
*/
export const forceCaps = (editorView?: EditorView) => {
  if (!editorView) return
  const { dispatch } = editorView
  const { doc, schema } = editorView.state
  const tr = editorView.state.tr
  const selection = tr.selection
  let shouldUpdate = false
  doc.nodesBetween(selection.from, selection.to, (node, position) => {
    // only process text, must be a selection
    if (!node.isText || selection.from === selection.to) {
      return
    }
    // calculate the section of the current text node to replace
    const startPos = Math.max(position, selection.from)
    const endPos = Math.min(position + node.nodeSize, selection.to)
    // grab the content using offsets
    const substringFrom = Math.max(0, selection.from - position)
    const substringTo = Math.max(0, selection.to - position)
    // convert to all caps and ensure that the text is indeed altered
    const text = node.textBetween(substringFrom, substringTo)
    const capsText = text.toUpperCase()
    const marks = node.marks
    if (text !== capsText) {
      tr.replaceRangeWith(
        tr.mapping.map(startPos),
        tr.mapping.map(endPos),
        // create a new text node
        schema.text(capsText, marks)
      )
      shouldUpdate = true
    }
  })
  if (dispatch && shouldUpdate) {
    dispatch(tr)
  }
}

/**
 * lil selected helper cribbed from
 * https://github.com/ProseMirror/prosemirror-example-setup/blob/f84ad32ec79f9884709f5b50f4668bf34597a2b5/src/menu.ts#L58-L62
 *
 * @param {string} markType - mark type key
 * @param {object} state - editorState
 * @returns {boolean} true if current selection range has mark
 * or cursor sits on the righthand side of a marked character
 */
export const selectionContainsMark = (
  typeKey: MarkTypeKey,
  state: EditorState
) => {
  const type = schema.marks[typeKey]
  const { from, $from, to, empty } = state.selection
  if (empty) return !!type.isInSet(state.storedMarks || $from.marks())
  else return state.doc.rangeHasMark(from, to, type)
}

export const shouldDisableFormatting = (editorView: EditorView) => {
  const { state } = editorView
  const readOnly = !editorView.editable
  const isDocSelection = state.selection.$anchor.depth === 0
  const selectionTooBig = isSelectionTooBig(state)

  return readOnly || isDocSelection || selectionTooBig
}

export const isSingleBlockTextSelection = (state: EditorState) => {
  const { selection } = state
  if (!(selection instanceof TextSelection)) return false
  return selection.$anchor.parent.eq(selection.$head.parent)
}

export const selectionHasUrl = (state: EditorState) => {
  const selectedText = state.doc.textBetween(
    state.selection.from,
    state.selection.to
  )
  return HAS_URL.test(selectedText)
}

/**
 * Get the nearest block node from the beginning of the current selection.
 * @param {EditorState} state - editor state
 * @return {object} prosemirror node
 */
export const getBlock = (state: EditorState): PmNode | undefined => {
  const { selection } = state
  if (selection instanceof NodeSelection) {
    return selection.node
  }
  if (selection instanceof TextSelection) {
    return selection.$from.parent
  }
}

/**
 * Determine block type of the beginning of the current selection.
 * @param {EditorState} state - editor state
 * @return {string} block type
 */
export const getBlockType = (state: EditorState): string | undefined =>
  getBlock(state)?.type.name

/**
 * Uses either the PM setBlockType command or something more finegrained when we need to manipulate the block prior to changing its type
 * @param {EditorState} viewState - current editor state
 * @param {fn} viewDispatch - a function to dispatch a transaction
 * @param {String} blockType - the block type to switch to
 */
export const setEditorBlockType = (
  state: EditorState,
  dispatch: Dispatch,
  blockType: string
) => {
  // if the selection isnt a plain cursor or text confined
  // to a single block, we update in bulk without all the massaging
  if (!isSingleBlockTextSelection(state)) {
    setEditorBulkBlockType(state, dispatch, blockType)
    return
  }

  // the JS code i ported  claimed (falsely) to ignore node selections

  const { $from } = state.selection
  const currBlock = getBlock(state)
  const currBlockType = currBlock?.type.name
  if (!currBlockType) return

  // if the block type isnt actually changing, abort early
  if (currBlockType === blockType) return

  // pluck attributes to pass through when changing block type
  const { attrs } = currBlock
  const nodeType = schema.nodes[blockType]

  // if neither block type is wrapped, delegate to PM
  if (!isWrappedBlock(currBlockType) && !isWrappedBlock(blockType)) {
    setBlockType(nodeType, attrs)(state, dispatch)
    return
  }

  // if the old block type was wrapped, delete the wrapping (if present)
  // and preserve the selection and pass through existing attributes
  let { tr } = state
  const start = $from.start()

  // trim whitespace at the end of the block only if needed
  tr = maybeTrimWhitespace(tr)
  const wasWrapped = checkWrap(tr)
  // strip stale wrapping chars if needed
  tr = maybeStripWrapChars(tr)
  // use PM to change the actual blocktype and preserve attrs
  tr.setBlockType(start, start, nodeType, attrs)
  // if the new block type is wrapped, do the wrapping if it wasnt done already
  tr = maybeAddWrapChars(tr, wasWrapped)
  // dispatch a transaction to make the edit real
  dispatch(tr)
}

/**
 * loop through multiblock selections and dispatch a single PM transaction to change their type
 * @param {EditorState} viewState - current editor state
 * @param {fn} viewDispatch - a function to dispatch a transaction
 * @param {String} blockType - the block type to switch to
 */
export const setEditorBulkBlockType = (
  state: EditorState,
  dispatch: Dispatch,
  blockType: string
) => {
  const { doc, selection, tr } = state
  doc.nodesBetween(selection.from, selection.to, (node, pos, parent) => {
    const isDifferent = node.type.name !== blockType
    if (isStandardBlock({ node, parent }) && isDifferent) {
      const start = pos + 1 // shift inside from the block boundary
      tr.setBlockType(start, start, schema.nodes[blockType], node.attrs)
    }
  })
  if (tr.docChanged) dispatch(tr)
}

// if we call editorView.coordsAtPos after it's been destroyed,
// then prosemirror throws. This is just a race condition, so use
// soemthing with the right shape
const DUMMY_RECT = Object.freeze({ top: 0, bottom: 0, left: 0, right: 0 })
// When a bunch of text is selected on the screen, this gives us the
// viewport coordinates of a rectangle that encloses the selected text
export const absoluteSelectionRect = (
  editorView: EditorView,
  selection: TextSelection | NodeSelection
): {
  top: number
  left: number
  bottom: number
  right: number
} => {
  if (editorView.isDestroyed) {
    return { ...DUMMY_RECT }
  }
  // we destructure here because, for node selections only,
  // PM returns a DOMRectReadOnly
  let { top, left, bottom, right } = editorView.coordsAtPos(selection.from)
  for (let i = selection.from + 1; i < selection.to; i++) {
    const coords = editorView.coordsAtPos(i)
    top = Math.min(coords.top, top)
    left = Math.min(coords.left, left)
    bottom = Math.max(coords.bottom, bottom)
    right = Math.max(coords.right, right)
  }

  return { top, left, bottom, right }
}

const scaleForZoom = (
  { left, right, top, bottom }: Rect,
  zoomLevel: number
): Rect => {
  return {
    left: left / zoomLevel,
    right: right / zoomLevel,
    top: top / zoomLevel,
    bottom: bottom / zoomLevel,
  }
}

// use viewport coordinates to figure out the relative
// positioning of an element to a parent element
const relativeToParent = (
  { left, right, top, bottom }: Rect,
  parent: Element
): Rect => {
  const eltEdges = parent.getBoundingClientRect()
  return {
    left: left - eltEdges.left,
    // yes, right is relative to the left of the element
    right: right - eltEdges.left,
    top: top - eltEdges.top,
    // yes, bottom is relative to the top of the element
    bottom: bottom - eltEdges.top,
  }
}

// Use this to get the selection rect relative to the editor view
// (e.g. to position something inside the editor).
export const relativeSelectionRect = ({
  editorView,
  selection,
  zoomLevel,
}: {
  zoomLevel: number
  editorView: EditorView
  selection: TextSelection | NodeSelection
}): {
  left: number
  right: number
  bottom: number
  top: number
} => {
  if (editorView.isDestroyed) {
    return { ...DUMMY_RECT }
  }
  const absoluteEdges = absoluteSelectionRect(editorView, selection)
  const relativeEdges = relativeToParent(absoluteEdges, editorView.dom)
  return scaleForZoom(relativeEdges, zoomLevel)
}

export const selectionHeadPosition = ({
  editorView,
  selection,
  zoomLevel,
}: {
  zoomLevel: number
  editorView: EditorView
  selection: TextSelection | NodeSelection
}): Rect | undefined => {
  if (editorView.isDestroyed) {
    return { ...DUMMY_RECT }
  }

  const coords = relativeToParent(
    editorView.coordsAtPos(selection.$head.pos),
    editorView.dom
  )
  return scaleForZoom(coords, zoomLevel)
}

// Test to ensure that a PmNode is a textBlock and is not
// something weird like a dual dialogue block
export const isStandardBlock = ({
  node,
  parent,
}: {
  node: PmNode
  parent: PmNode
}) => node.isTextblock && parent.type.name === NodeTypeMap.PAGE

/**
 * inspects the active selection for comments and retrieves their id(s).
 * we gather comments contained in a cut in order to preserve them on subsequent paste.
 * @param {object} EditorState - PM EditorState
 * @returns {<string>} - array of guids
 */
export function retrieveCommentIds(viewState: EditorState) {
  const { from, to } = viewState.selection
  const ids: string[] = []
  viewState.doc.nodesBetween(from, to, (node) => {
    node.marks.forEach((m) => {
      if (m.type.name === MarkTypeMap.COMMENT) {
        ids.push(m.attrs.id)
      }
    })
  })
  return ids
}

// copy/paste without alterations to support the function below
function markApplies(
  doc: PmNode,
  ranges: readonly SelectionRange[],
  type: MarkType
) {
  for (let i = 0; i < ranges.length; i++) {
    const { $from, $to } = ranges[i]
    let can =
      $from.depth === 0
        ? doc.inlineContent && doc.type.allowsMarkType(type)
        : false

    doc.nodesBetween($from.pos, $to.pos, (node) => {
      if (can) return false
      can = node.inlineContent && node.type.allowsMarkType(type)
    })
    if (can) return true
  }
  return false
}

// port of
// https://github.com/ProseMirror/prosemirror-commands/pull/17
// https://github.com/ProseMirror/prosemirror-commands/pull/18
// we can yank this when prosemirror-commands is published next
/* eslint-disable */
export function toggleMark(
  markType: MarkType,
  attrs?: { [key: string]: any },
  options?: {
    /// Controls whether, when part of the selected range has the mark
    /// already and part doesn't, the mark is removed (`true`, the
    /// default) or added (`false`).
    removeWhenPresent: boolean
  }
) {
  let removeWhenPresent = (options && options.removeWhenPresent) !== false
  return function (state: EditorState, dispatch: Dispatch) {
    let { empty, $cursor, ranges } = state.selection as TextSelection
    if ((empty && !$cursor) || !markApplies(state.doc, ranges, markType))
      return false
    if (dispatch) {
      if ($cursor) {
        if (markType.isInSet(state.storedMarks || $cursor.marks()))
          dispatch(state.tr.removeStoredMark(markType))
        else dispatch(state.tr.addStoredMark(markType.create(attrs)))
      } else {
        let add,
          tr = state.tr
        if (removeWhenPresent) {
          add = !ranges.some((r) =>
            state.doc.rangeHasMark(r.$from.pos, r.$to.pos, markType)
          )
        } else {
          add = !ranges.every((r) => {
            let missing = false
            tr.doc.nodesBetween(r.$from.pos, r.$to.pos, (node, pos, parent) => {
              if (missing) return false
              missing =
                !markType.isInSet(node.marks) &&
                !!parent &&
                parent.type.allowsMarkType(markType) &&
                !(
                  node.isText &&
                  /^\s*$/.test(
                    node.textBetween(
                      Math.max(0, r.$from.pos - pos),
                      Math.min(node.nodeSize, r.$to.pos - pos)
                    )
                  )
                )
            })
            return !missing
          })
        }
        for (let i = 0; i < ranges.length; i++) {
          let { $from, $to } = ranges[i]
          if (!add) {
            tr.removeMark($from.pos, $to.pos, markType)
          } else {
            let from = $from.pos,
              to = $to.pos,
              start = $from.nodeAfter,
              end = $to.nodeBefore
            let spaceStart =
              start && start.isText ? /^\s*/.exec(start.text!)![0].length : 0
            let spaceEnd =
              end && end.isText ? /\s*$/.exec(end.text!)![0].length : 0
            if (from + spaceStart < to) {
              from += spaceStart
              to -= spaceEnd
            }
            tr.addMark(from, to, markType.create(attrs))
          }
        }
        dispatch(tr.scrollIntoView())
      }
    }
    return true
  }
}
/* eslint-enable */
