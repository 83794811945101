import cn from 'classnames'
import { LoadingOverlay, LoadingOverlayProps } from '@mantine/core'

import styles from './Loader.module.scss'

/*
  The Mantine LoadingOverlay doesn't have a "full screen" mode. This is a convenience
  wrapper to attach a css class to handle that. We can also use this to customize the
  look/feel of the loader itself
*/

type LoaderProps = Omit<LoadingOverlayProps, 'visible'> & {
  fullScreen?: boolean
  // just for convenience make the visible prop optional, we'll
  // force to a boolean
  visible?: boolean
  opacityDelay?: number
}
export const Loader = (props: LoaderProps) => {
  const { fullScreen, visible, opacityDelay, ...passThroughProps } = props
  const classes = cn(styles.loader, { [styles.loader__fullScreen]: fullScreen })

  const style: React.CSSProperties | undefined =
    typeof opacityDelay === 'number'
      ? { animationDelay: `${opacityDelay}ms` }
      : undefined

  return (
    <LoadingOverlay
      style={style}
      visible={!!visible}
      {...passThroughProps}
      className={classes}
    />
  )
}
