import { Plugin, PluginKey } from 'prosemirror-state'
import { ElementNumbers } from './component.js'
const elementNumbersKey = new PluginKey('ELEMENT_NUMBERS')
/**
 * Creates a new element numbers plugin
 * @param {object} config - plugin config
 */
function elementNumbersPlugin({ appState }) {
  let elementNumbers = null
  return new Plugin({
    key: elementNumbersKey,
    state: {
      init() {
        // noop
      },
      apply(tr) {
        // a (pretty convoluted) way to get a reference to the most recently
        // applied transaction from within the context of a PM plugin view
        elementNumbers.forceRender = tr.getMeta('choo')
      },
    },
    view(editorView) {
      const gutterLeft = document.querySelector('.c-editor__gutterleft')
      elementNumbers = new ElementNumbers(editorView, appState)
      const el = elementNumbers.render()
      gutterLeft.appendChild(el)
      function destroy() {
        gutterLeft.removeChild(el)
      }
      return {
        update: elementNumbers.render.bind(elementNumbers),
        destroy,
      }
    },
  })
}
export { elementNumbersKey, elementNumbersPlugin }
