import { observer } from 'mobx-react-lite'
import { useMst } from '@state'
import { ILoadedScript } from '@state/types'
import { Toolbar } from '@components/Toolbar'
import { CreateSnapshotButton } from './buttons/CreateSnapshot'
import { Collaborators } from './Collaborators'
import { CommentHistoryPopover } from './CommentHistory'
import { FormattingOptions } from './Formatting'
import { ToolbarItem, getItemsToShrink } from './responsiveHelpers'
import { useElementDimensions } from '@hooks'
import { ButtonMode } from '@state/types'
import { InkExportMenu } from '@ink/components/InkMenu'

import styles from './EditorToolbar.module.scss'

export const InkEditorToolbar = observer(function InkEditorToolbar({
  inkScript,
}: {
  inkScript: ILoadedScript
}) {
  const { currentOrg, socketManager, currentInkProject } = useMst()

  const { ref, dimensions } = useElementDimensions()

  if (!(currentOrg && currentInkProject)) return null

  const { isPrivate, hasRemoteUsers, users, isEditable } = inkScript

  const displayScriptStatus = isPrivate || currentOrg.hasLimitedScriptsEnabled

  const itemsToRender: ToolbarItem[] = [
    'INK_EXPORT_MENU',
    'COMMENT_MENU',
    'SNAPSHOT_BUTTON',
    'ZOOM_MENU',
  ]
  if (displayScriptStatus) {
    itemsToRender.push('SCRIPT_STATUS_BUTTON')
  }

  if (hasRemoteUsers) {
    itemsToRender.push('COLLABORATORS')
  }

  if (isEditable) {
    itemsToRender.push('FORMATTING_BUTTONS')
  }

  const { mini, micro } = getItemsToShrink(itemsToRender, dimensions.width)
  const getModeFor = (item: ToolbarItem): ButtonMode => {
    return micro.includes(item)
      ? 'micro'
      : mini.includes(item)
      ? 'mini'
      : 'normal'
  }

  return (
    <div className={styles.toolbar} ref={ref}>
      <Toolbar>
        <Toolbar.Section position="left">
          <Toolbar.Button
            tooltip="Show Player Window"
            label="Play"
            icon="fa-play"
            onClick={currentInkProject.launchPopup}
            primary
          />
          {itemsToRender.includes('FORMATTING_BUTTONS') && (
            <FormattingOptions mode={getModeFor('FORMATTING_BUTTONS')} />
          )}
        </Toolbar.Section>
        <Toolbar.Section position="right">
          {itemsToRender.includes('COLLABORATORS') && (
            <Collaborators
              users={users}
              clientId={socketManager.socketId}
              mode={getModeFor('COLLABORATORS')}
            />
          )}
          <CommentHistoryPopover
            script={inkScript}
            mode={getModeFor('COMMENT_MENU')}
          />
          <CreateSnapshotButton
            script={inkScript}
            mode={getModeFor('SNAPSHOT_BUTTON')}
          />
          <InkExportMenu mode={getModeFor('INK_EXPORT_MENU')} />
        </Toolbar.Section>
      </Toolbar>
    </div>
  )
})
