import { types, SnapshotIn } from 'mobx-state-tree'
import { TextSelection } from 'prosemirror-state'
import { BaseModel } from './BaseModel'
import { NAV_LINK_VALUES, NavLinkElement } from '@util/constants'

export const NavLinkModel = BaseModel.named('NavLink')
  .props({
    id: types.identifier,
    text: types.string,
    isBlank: types.boolean,
    pos: types.number,
    nodeLength: 0,
    // we set this when we reorder in the UI. On the next update from the
    // editor, it gets reset back to undefined. This lets us show the effect of
    // drag-drop without a delay
    tempPos: types.maybe(types.number),
    elementNumber: types.maybe(types.maybeNull(types.string)),
    type: types.enumeration<NavLinkElement>([...NAV_LINK_VALUES]),
  })
  .views((self) => ({
    get draggable() {
      return ['sceneHeading', 'slug'].includes(self.type)
    },
    // if we've dragged the link but haven't gotten the next scriptMeta
    // update, show the link above the target it was dragged to
    get displayPosition(): number {
      if (self.tempPos !== undefined) {
        return self.tempPos - 0.5
      }
      return self.pos
    },
    // does this nav link's node include the pm document position?
    includesPosition(position: number) {
      return self.pos <= position && position - self.pos < self.nodeLength
    },
  }))
  .actions((self) => ({
    setTempPos(value: number) {
      self.tempPos = value
    },
    selectInEditor() {
      const observableEditor = self.rootStore.currentScript?.observableEditor
      if (observableEditor) {
        const { editorView, editorState } = observableEditor
        try {
          // this will throw if self.pos is out of range
          const position = editorState.doc.resolve(self.pos)
          const selection = TextSelection.findFrom(position, 1, true)
          if (selection) {
            editorView.dispatch(editorState.tr.setSelection(selection))
            observableEditor.focusEditor()
          }
          // eslint-disable-next-line no-empty
        } catch {}
      }
    },
  }))

// typeguard -- just a little suspicious of the choo app scriptMeta
export function isValidNavLink(
  payload: Partial<{
    id: unknown
    text: string
    isBlank: boolean
    pos: number
    elementNumber: unknown
  }>
): payload is SnapshotIn<typeof NavLinkModel> {
  if (typeof payload !== 'object') {
    return false
  }
  const { id, text, isBlank, pos, elementNumber } = payload
  return (
    typeof id === 'string' &&
    typeof text === 'string' &&
    typeof isBlank === 'boolean' &&
    typeof pos === 'number' &&
    (typeof elementNumber === 'string' ||
      elementNumber === null ||
      elementNumber === undefined)
  )
}
