import type { Instance } from 'mobx-state-tree'
import type { EditorState } from 'prosemirror-state'
import type { EditorView } from 'prosemirror-view'
import { NodeTypeKey } from '@showrunner/codex'
import type { ServerConfig } from '../choo-app/types'
import {
  ScriptoApiClient,
  MixpanelClient,
  ScrapiClient,
  LegacyApiClient,
} from '@util'
import { DatadogClient } from '@util/datadog'
import { LocalPersistence } from '@util/LocalPersistence'
import * as Models from './models'

// environment is shared by all mobx-state-tree models. These are
// created at app launch time and always available
export type Environment = {
  apiClient: ScriptoApiClient
  config: ServerConfig
  analytics: MixpanelClient
  localPersistence: LocalPersistence
  datadog: DatadogClient
  scrapiClient: ScrapiClient
  legacyApiClient: LegacyApiClient
}

export interface IChooWrapper extends Instance<typeof Models.ChooWrapper> {}
export interface IRoot extends Instance<typeof Models.Root> {}
export interface IInvite extends Instance<typeof Models.Invite> {}
export interface IOrg extends Instance<typeof Models.Org> {}
export interface IOrgMember extends Instance<typeof Models.OrgMember> {}
export interface IOrgOptionModel
  extends Instance<typeof Models.OrgOptionModel> {}
export interface IUser extends Instance<typeof Models.User> {}
export interface IFolder extends Instance<typeof Models.Folder> {}
export type CollectionId = 'root' | 'favorites' | 'recentlyEdited'
export function isCollectionId(id: string): id is CollectionId {
  return ['root', 'favorites', 'recentlyEdited'].includes(id)
}

export interface IMembership extends Instance<typeof Models.Membership> {}
export interface ILoadedScript extends Instance<typeof Models.LoadedScript> {}
export interface INavLink extends Instance<typeof Models.NavLinkModel> {}
export interface IScriptListing extends Instance<typeof Models.ScriptListing> {}
export interface IRundownListing
  extends Instance<typeof Models.RundownListing> {}
export interface IInkProject extends Instance<typeof Models.InkProject> {}
export interface IRundown extends Instance<typeof Models.Rundown> {}
export interface IRundownRow extends Instance<typeof Models.RundownRow> {}
export interface IView extends Instance<typeof Models.View> {}
export interface IReadonlyFolderListState
  extends Instance<typeof Models.ReadonlyFolderListState> {}
export interface IPrompterPushCandidate
  extends Instance<typeof Models.PrompterPushCandidate> {}
export interface IPrompterSegment
  extends Instance<typeof Models.PrompterSegment> {}
export interface IPmEditor extends Instance<typeof Models.PmEditor> {}
export interface IPopulatedPmEditor extends IPmEditor {
  editorState: EditorState
  editorView: EditorView
}

export type IListing = IScriptListing | IRundownListing

export function isScriptListing(
  instance: IListing
): instance is IScriptListing {
  return instance.modelType === 'scriptListing'
}
export function isRundown(instance: IListing): instance is IRundownListing {
  return instance.modelType === 'rundown'
}

export interface IRemoteUser extends Instance<typeof Models.RemoteUser> {}
export interface IScriptFormatSummary
  extends Instance<typeof Models.ScriptFormatSummaryModel> {}

export type NavLinkCursorPosition =
  | 'navLink_cursor___above'
  | 'navLink_cursor___on'
  | 'navLink_cursor___below'

// using Extract<> ensures that only plain text values in the union below
// that are confirmed to be valid appear in the subsequent type
// this is too clever and not clever enough simultaneously
export type NumberableNodeTypeKeys = Extract<
  NodeTypeKey,
  'bracket' | 'sceneHeading' | 'slug' | 'dialogue'
>

export const EXPLORER_VIEW_TYPES = ['browser', 'outline', 'history']
export type ExplorerViewType = typeof EXPLORER_VIEW_TYPES[number]

// only formatting and collaborators truly have a micro mode
export type ButtonMode = 'normal' | 'mini' | 'micro'

export type ScriptReference = { scriptId: string; name: string }

export type SplitEditorPref = 'rows' | 'columns'

// shorthand to store ID of rundown or script. If the ID is a string
// it's a script, otherwise a rundown
export type ListingId = string | number
