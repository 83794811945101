import { Stack, Text } from '@mantine/core'
import { FilterSelect } from './Select'
import { usePendingSearchContext } from '../SearchContext'

type DisplayPartial = 'Rundowns only' | 'Scripts only'
type DocType = 'rundown' | 'script'

const data = {
  rundown: 'Rundowns only',
  script: 'Scripts only',
}

const inverted: Record<DisplayPartial, DocType> = {
  'Rundowns only': 'rundown',
  'Scripts only': 'script',
}

export const DocTypePicker = () => {
  const context = usePendingSearchContext()
  if (!context) return <></>
  const { pendingCriteria } = context

  return (
    <Stack gap={6}>
      <Text fw="bold" fz={15}>
        Document type
      </Text>
      <FilterSelect
        isSelected={!!pendingCriteria.docType}
        data={['All', 'Rundowns only', 'Scripts only']}
        value={pendingCriteria.docType ? data[pendingCriteria.docType] : 'All'}
        onChange={(val) => {
          const docType = inverted[val as DisplayPartial]
          context.mergePendingCriteria({ docType })
        }}
      />
    </Stack>
  )
}
