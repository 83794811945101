import React from 'react'
import cn from 'classnames'
import { SplitEditorPref } from '@state/types'

import styles from './DragHandle.module.scss'

type DragHandleProps = {
  handleAxis?: string
  dragging?: boolean
  layout: SplitEditorPref
  disabled?: boolean
}

export const DragHandle = React.forwardRef<HTMLDivElement, DragHandleProps>(
  (props, ref) => {
    const { dragging, layout, handleAxis, disabled, ...rest } = props
    const orientationStyle =
      layout === 'rows' ? styles.horizontal : styles.vertical
    return (
      <div
        ref={ref}
        className={cn(styles.dragHandle, orientationStyle, {
          [styles.dragging]: dragging,
          [styles.disabled]: disabled,
        })}
        {...rest}
      />
    )
  }
)

DragHandle.displayName = 'DragHandle'
