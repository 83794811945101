import { getEnv } from '../config.js'
const env = getEnv()
// Helpers (for convenience)
const helpers = {
  IS_DEV: env.isDev,
  IS_TEST: env.isTest,
  IS_PROD: env.isProduction,
}
const getAvatarRoot = () => {
  if (helpers.IS_DEV) {
    // use prod avatars if using a prod server
    return env.API_URL.includes('https://')
      ? 'https://s3.amazonaws.com/sr-avatars/prod/'
      : 'https://s3.amazonaws.com/sr-avatars/dev/'
  }
  return '/assets/avatars/'
}
// Misc settings
// not really env stuff, maybe move this elsewhere someday
const misc = {
  PAGE_SIZE: 40,
  AVATAR_SIZE_LIMIT: 2 * 1024 * 1024,
  AVATAR_ROOT: getAvatarRoot(),
  GRAYFACE_URL: '/images/grayface.svg',
  INVITE_ACTIONS: ['accepted', 'blocked'],
}
const browser = {}
// adapted from prosemirror-view's browser sniffing
// https://github.com/ProseMirror/prosemirror-view/blob/master/src/browser.js
if (typeof navigator !== 'undefined' && typeof document !== 'undefined') {
  const ieEdge = /Edge\/(\d+)/.exec(navigator.userAgent)
  const ieUpTo10 = /MSIE \d/.test(navigator.userAgent)
  const ie11up = /Trident\/(?:[7-9]|\d{2,})\..*rv:(\d+)/.exec(
    navigator.userAgent
  )
  browser.mac = /Mac/.test(navigator.platform)
  const ie = (browser.ie = !!(ieUpTo10 || ie11up || ieEdge))
  browser.ieVersion = ieUpTo10
    ? document.documentMode || 6
    : ie11up
    ? +ie11up[1]
    : ieEdge
    ? +ieEdge[1]
    : null
  browser.gecko = !ie && /gecko\/(\d+)/i.test(navigator.userAgent)
  browser.geckoVersion =
    browser.gecko && +(/Firefox\/(\d+)/.exec(navigator.userAgent) || [0, 0])[1]
  const chrome = !ie && /Chrome\/(\d+)/.exec(navigator.userAgent)
  browser.chrome = !!chrome
  browser.chromeVersion = chrome && +chrome[1]
  // Is true for both iOS and iPadOS for convenience
  browser.safari = !ie && /Apple Computer/.test(navigator.vendor)
  browser.ios =
    browser.safari &&
    (/Mobile\/\w+/.test(navigator.userAgent) || navigator.maxTouchPoints > 2)
  browser.android = /Android \d/.test(navigator.userAgent)
  browser.webkit = 'webkitFontSmoothing' in document.documentElement.style
  browser.webkitVersion =
    browser.webkit &&
    +(/\bAppleWebKit\/(\d+)/.exec(navigator.userAgent) || [0, 0])[1]
}
const isDebug = () => window.localStorage.getItem('logLevel') === 'debug'
export default Object.assign({}, env, helpers, misc, { browser, isDebug })
