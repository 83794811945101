/*
  In order to efficiently generate headers, we need information about
  the contents of a page and previous pages, hoisted into attributes
  on the page element.

  This plugin adds a node decoration to each page to append the
  style with css vars that can later be used in stylesheets to
  render headers and footers in the margin slots (created by the
  margin-slots plugin)
*/

import { Node } from 'prosemirror-model'
import { Plugin, PluginKey, EditorState } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'
import { types, FormatUtils, ScriptDocType } from '@showrunner/codex'

export const marginInfoPluginKey = new PluginKey('marginInfo')

// We run through the doc and accumulate data in this shape:
type AggregatedPageInfo = {
  // What slug or scene are we "in" at the end of the page
  // (i.e. what slug or scene happened most recently before this
  // page ended)
  sleneName: string

  // What page WITHIN that slug or scene are we in. Some shows want to
  // number within a slene, some want to suppress the header on the
  // first page of that, etc.
  slenePage: number

  // used for auto-numbered slenes only, when script's format has
  // numbering.slene = 'auto'
  sleneAutoNumber: number

  // used for the human editable slene numbers, when the script's format
  // has numbering.slene = 'manual'
  sleneManualNumber?: string

  // Ditto for the act name/page
  actName: string
  actPage: number
}

type LastOnPageInfo = {
  sleneName?: string
  actName?: string
  sleneCount: number
  sleneElementNumber?: string
}

const lastHeadersOnPage = (
  page: Node,
  docType: ScriptDocType
): LastOnPageInfo => {
  const isScreenplay = docType === 'screenplay'
  const result: LastOnPageInfo = {
    sleneCount: 0,
  }

  page.forEach((block) => {
    if ([types.SCENE_HEADING, types.SLUG].includes(block.type.name)) {
      result.sleneName =
        block.textContent ||
        (isScreenplay ? '<EMPTY SCENE HEADING>' : '<EMPTY SLUG>')

      // collect the number of slenes on the pages
      result.sleneCount += 1

      // get the manually assigned element number
      const rawEltNumber = block.attrs['elementNumber']
      // can these attributes be numbers?
      if (['string', 'number'].includes(typeof rawEltNumber)) {
        result.sleneElementNumber = String(rawEltNumber)
      } else {
        result.sleneElementNumber = undefined
      }
    } else if (block.type.name === types.NEW_ACT) {
      result.actName = block.textContent
    }
  })
  return result
}

const numberingAttributes = (
  docPage: number,
  actPage: number,
  slenePage: number
): StringMap => ({
  'data-doc-page': String(docPage),
  'data-act-page': String(actPage),
  'data-slene-page': String(slenePage),
})

export const marginInfo = ({
  script,
}: {
  script: {
    scriptFormat: {
      definition: FormatUtils.ScriptConfiguration
    }
    type: ScriptDocType
  }
}): Plugin<{ decorationSet: DecorationSet }> => {
  const { numbering } = script.scriptFormat.definition

  const sleneNumberingType =
    script.type === 'screenplay' ? numbering.sceneHeading : numbering.slug

  const getSleneNumber = (
    info: AggregatedPageInfo
  ): string | number | undefined => {
    switch (sleneNumberingType) {
      case 'auto':
        return info.sleneAutoNumber
      case 'manual':
        return info.sleneManualNumber
    }
  }

  const buildDecoSet = (state: EditorState): DecorationSet => {
    const aggregatedInfo: AggregatedPageInfo = {
      sleneAutoNumber: 0,
      sleneName: '',
      slenePage: 0,
      actName: '',
      actPage: 0,
    }

    const decos: Decoration[] = []
    const docPageCount = state.doc.childCount
    state.doc.content.forEach((page, offset, index) => {
      const { actName, sleneName, sleneCount, sleneElementNumber } =
        lastHeadersOnPage(page, script.type)

      aggregatedInfo.sleneAutoNumber += sleneCount
      aggregatedInfo.sleneManualNumber = sleneElementNumber

      if (actName) {
        aggregatedInfo.actName = actName
        aggregatedInfo.actPage = 1
      } else if (aggregatedInfo.actPage > 0) {
        aggregatedInfo.actPage += 1
      }
      if (sleneName) {
        aggregatedInfo.sleneName = sleneName
        aggregatedInfo.slenePage = 1
      } else if (aggregatedInfo.slenePage > 0) {
        aggregatedInfo.slenePage += 1
      }

      const docPage = index + 1
      const style = FormatUtils.toInlineStyles({
        docPage,
        docPageCount,
        sleneName: aggregatedInfo.sleneName,
        slenePage: aggregatedInfo.slenePage,
        actName: aggregatedInfo.actName,
        actPage: aggregatedInfo.actPage,
        sleneNumber: getSleneNumber(aggregatedInfo),
      })
      decos.push(
        Decoration.node(offset, offset + page.nodeSize, {
          style,
          ...numberingAttributes(
            docPage,
            aggregatedInfo.actPage,
            aggregatedInfo.slenePage
          ),
        })
      )
    })
    return DecorationSet.create(state.doc, decos)
  }

  return new Plugin({
    key: marginInfoPluginKey,
    state: {
      init(config, editorState) {
        return {
          decorationSet: buildDecoSet(editorState),
        }
      },
      apply(tr, pluginState, oldState, newState) {
        if (!oldState.doc.eq(newState.doc)) {
          pluginState.decorationSet = buildDecoSet(newState)
        }
        return pluginState
      },
    },
    props: {
      decorations(state) {
        return this.getState(state)?.decorationSet
      },
    },
  })
}
