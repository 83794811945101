import React from 'react'
import { Container, Image, Stack, Title, Space } from '@mantine/core'
import { Loader } from '@components/Loader'
import { LoginSignupButtons } from './LoginSignupButtons'
import { OnboardingForm } from './OnboardingForm'
import { OnboardingLinks } from './OnboardingLinks'
import { OnboardingInfo } from './OnboardingInfo'
import { SubmitButton } from './OnboardingSubmitButton'
import logo from '@assets/images/logo-light.svg'

import styles from './OnboardingPage.module.scss'

export const OnboardingPage = ({
  children,
  title,
  loading,
}: {
  children: React.ReactNode
  title: string
  loading?: boolean
}) => {
  return (
    <div className={styles.onboardingPage}>
      <Loader visible={loading} fullScreen opacityDelay={1000} />
      <Stack align="center">
        <Space h={30} />
        <Image src={logo} alt="Scripto Logo" w={240} />
        <Title order={1} c="white">
          {title}
        </Title>
        <Container className={styles.onboardingPage_contents}>
          {children}
          <Space h={30} />
        </Container>
      </Stack>
    </div>
  )
}

OnboardingPage.Buttons = LoginSignupButtons
OnboardingPage.Form = OnboardingForm
OnboardingPage.Links = OnboardingLinks
OnboardingPage.Info = OnboardingInfo
OnboardingPage.SubmitButton = SubmitButton
