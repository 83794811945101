import { z } from 'zod'
import { NodeTypeKey } from '@showrunner/codex'

const ZRundownColumnType = z.enum([
  'backTiming',
  'checkbox',
  'cumulativeDuration',
  'duration',
  'frontTiming',
  'itemNumber',
  'text',
  'longText',
  'snapstreamLink',
  'readonlyBlockId',
  'readonlyScriptId',
])
export type RundownColumnType = z.infer<typeof ZRundownColumnType>

export const ZGridBlobKey = z.custom<`blobData.${string}`>(
  (val) => typeof val === 'string' && /^blobData\./.test(val)
)
export type GridBlobColumnKey = z.infer<typeof ZGridBlobKey>
export function isGridBlobKey(key: string): key is GridBlobColumnKey {
  return /^blobData\./.test(key)
}

const ZRundownColDef = z.object({
  colId: z.string(),
  field: ZGridBlobKey.optional(),
  rundownColumnType: ZRundownColumnType,
  headerName: z.string().optional(),
  headerTooltip: z.string().optional(),
  width: z.number().int().positive().optional(),
})
export type RundownColDef = z.infer<typeof ZRundownColDef>

const ZLevelConfig = z.object({
  enableInsert: z.boolean(),
  displayName: z.string().min(1),
  rowTypeId: z.enum(['header', 'element']).optional(),
})

export const ZRowLevel = z.union([z.literal(1), z.literal(2), z.literal(3)])
export type RowLevel = z.infer<typeof ZRowLevel>
// unfortunately, we need a string version of this for the levels {} map because
// keys wind up as strings. In retrospect, we shouldn't have used numbers but the
// die is cast.
const ZRowLevelString = z.union([
  z.literal('1'),
  z.literal('2'),
  z.literal('3'),
])
const ZLevels = z.record(ZRowLevelString, ZLevelConfig.optional())

const ZScriptImportRule = z.object({
  rowTypeId: z.enum(['header', 'element']).optional(),
  rowLevel: ZRowLevel.optional(),
  columnId: z.string(),
})

const ZImportableBlock = z.enum([
  'action',
  'slug',
  'dialogue',
  'bracket',
  'sceneHeading',
  'character',
  'new_act',
  'parenthetical',
])
export const IMPORTABLE_BLOCK_TYPES: NodeTypeKey[] = ZImportableBlock.options

const ZContextBlock = z.enum(['slug', 'sceneHeading', 'character', 'new_act'])
export type ContextBlock = z.infer<typeof ZContextBlock>

const ZBaseImportRule = z.object({
  nodeType: ZImportableBlock,
  rowTypeId: z.enum(['header', 'element']).optional(),
  rowLevel: ZRowLevel.optional(),
  contextColumns: z.record(ZContextBlock, z.string().optional()).optional(),
  elementNumberColumnId: z.string().optional(),
})

const ZSimpleImportRule = ZBaseImportRule.extend({
  ruleType: z.literal('simple'),
  columnId: z.string(),
})
export type SimpleImportRule = z.infer<typeof ZSimpleImportRule>

const ZBracketImportRule = ZBaseImportRule.extend({
  ruleType: z.literal('bracket-parse'),
  nodeType: z.literal('bracket'),
  contentColumnId: z.string(),
  bracketTypeColumnId: z.string(),
  timeColumnId: z.string(),
})
export type BracketImportRule = z.infer<typeof ZBracketImportRule>

const ZSlugImportRule = ZBaseImportRule.extend({
  ruleType: z.literal('slug-with-timing'),
  nodeType: z.literal('slug'),
  columnId: z.string(),
  durationColumnId: z.string(),
})
export type SlugImportRule = z.infer<typeof ZSlugImportRule>

const ZRowImportRule = z.discriminatedUnion('ruleType', [
  ZSimpleImportRule,
  ZSlugImportRule,
  ZBracketImportRule,
])
export type RowImportRule = z.infer<typeof ZRowImportRule>

export function isBracketRule(rule: RowImportRule): rule is BracketImportRule {
  return rule.ruleType === 'bracket-parse'
}

export function isSlugRule(rule: RowImportRule): rule is SlugImportRule {
  return rule.ruleType === 'slug-with-timing'
}

export const ZRundownSchema = z.object({
  columns: ZRundownColDef.array(),
  durationMode: z.enum(['target-length', 'start-end-time']).optional(),
  primaryDurationField: ZGridBlobKey.optional(),
  levels: ZLevels,
  importRules: z.object({
    script: ZScriptImportRule,
    rows: ZRowImportRule.array(),
  }),
})

export type RundownSchema = z.infer<typeof ZRundownSchema>
