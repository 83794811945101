/*
  This plugin sets a css variable to block elements based on the
  number of leading spaces there are at the beginning of the block.

  This is used to produce a hanging indent on line wraps (e.g. in ink scripts
  to mimic the behavior of Inky)
*/

import { Node as PmNode } from 'prosemirror-model'
import { Plugin, PluginKey } from 'prosemirror-state'
import { DecorationSet, Decoration } from 'prosemirror-view'
import { getIndentLevel, TAB_SIZE } from './ink-keymap/tabIndent'

export const indentationStylesPluginKey = new PluginKey('indentationStyles')

const indentLevelToCSSVar = (indent: number) =>
  `--wrap-indent: ${(indent + 1) * TAB_SIZE}ch;`

const createDecorationForNode = (
  node: PmNode,
  pos: number
): Decoration | null => {
  if (typeof node.attrs.id === 'string' && node.isTextblock) {
    const indentLevel = getIndentLevel(node.textContent)
    return Decoration.node(
      pos,
      pos + node.nodeSize,
      {
        style: indentLevelToCSSVar(indentLevel),
      },
      { id: node.attrs.id, indentLevel }
    )
  }
  return null
}

const createDecorations = (doc: PmNode): Decoration[] => {
  const decorations: Decoration[] = []
  doc.descendants((node, pos) => {
    const deco = createDecorationForNode(node, pos)
    if (deco) {
      decorations.push(deco)
      // don't traverse inside an indented block
      return false
    }
  })

  return decorations
}

export const indentationStylesFactory = () => {
  return new Plugin<{
    decorationSet: DecorationSet
  }>({
    key: indentationStylesPluginKey,
    state: {
      init(config, editorState) {
        return {
          decorationSet: DecorationSet.create(
            editorState.doc,
            createDecorations(editorState.doc)
          ),
        }
      },
      apply(tr, pluginState) {
        if (!tr.docChanged) {
          return pluginState
        }

        // start by creating a new decoration set that assumes no
        // indentation has changed
        const mappedDecorationSet: DecorationSet =
          pluginState.decorationSet.map(tr.mapping, tr.doc)

        // now look through any affected nodes in this transaction
        // and see if we need to change the indent level.

        const staleNodes: {
          [blockId: string]: {
            node: PmNode
            pos: number
            oldDecorations: Decoration[]
          }
        } = {}

        tr.mapping.maps.forEach((stepMap) => {
          stepMap.forEach((oldStart, oldEnd, newStart, newEnd) => {
            tr.doc.nodesBetween(newStart, newEnd, (node, pos) => {
              if (typeof node.attrs.id === 'string' && node.isTextblock) {
                staleNodes[node.attrs.id] = {
                  node,
                  pos,
                  oldDecorations: mappedDecorationSet.find(
                    newStart,
                    newEnd,
                    (decoSpec) => decoSpec.id === node.attrs.id
                  ),
                }
                // don't recurse into children
                return false
              }
            })
          })
        })

        const decorationsToAdd: Decoration[] = []
        const decorationsToRemove: Decoration[] = []

        Object.values(staleNodes).forEach(({ node, pos, oldDecorations }) => {
          decorationsToRemove.push(...oldDecorations)
          const newDeco = createDecorationForNode(node, pos)
          if (newDeco) decorationsToAdd.push(newDeco)
        })

        const decorationSet = mappedDecorationSet
          .remove(decorationsToRemove)
          .add(tr.doc, decorationsToAdd)

        return {
          decorationSet,
        }
      },
    },
    props: {
      decorations(state) {
        return this.getState(state)?.decorationSet
      },
    },
  })
}
