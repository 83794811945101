import { Anchor, Text } from '@mantine/core'
import { StaffInvite } from '@util/ScriptoApiClient/types'
import styles from './InviteTable.module.scss'

const InviteRow = ({ invite }: { invite: StaffInvite }) => {
  const isOpen = invite.status === 'open'
  const href = `${window.origin}/signup?invite=${invite.key}`
  const displayHref = `/signup?invite=${invite.key.slice(0, 7)}`

  const url = isOpen ? (
    <Anchor href={href}>{displayHref}</Anchor>
  ) : (
    <Text fs="strikethrough">{displayHref}</Text>
  )

  // no point in displaying dummy emails
  const inviteEmail = /jane\+\d{13}@scripto.computer/.test(invite.email)
    ? ''
    : invite.email

  return (
    <>
      <td>{inviteEmail}</td>
      <td>{invite.status}</td>
      <td>{url}</td>
    </>
  )
}

export const InviteTable = ({ invites }: { invites: StaffInvite[] }) => {
  return (
    <table className={styles.invite_table}>
      <thead>
        <tr>
          <th>Email</th>
          <th>Status</th>
          <th>url</th>
        </tr>
      </thead>
      <tbody>
        {invites.map((i) => (
          <tr key={i.key}>
            <InviteRow invite={i} />
          </tr>
        ))}
      </tbody>
    </table>
  )
}
