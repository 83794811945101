import { Toolbar } from '@components/Toolbar'
import { StyleButtons } from './Style'
import { AlignmentButtons } from './Alignment'
import { HyperlinkButton } from './Hyperlink'
import { UndoRedo } from '../buttons/UndoRedo'
import { useMst } from '@state'
import { ButtonMode } from '@state/types'

export const FormattingButtons = ({
  overflow = false,
  mode,
}: {
  overflow?: boolean
  mode?: ButtonMode
}) => {
  const { currentScript } = useMst()

  // classic documents dont include any alignable block types
  const showAlignment = currentScript?.supportsBlockAlignment
  const showHyperlinks = currentScript?.supportsHyperlinks

  // no divider is needed when the buttons are displayed within the overflow menu
  return (
    <Toolbar.ButtonGroup spacing={0}>
      {!overflow && <Toolbar.Divider />}
      {mode !== 'mini' && (
        <>
          <Toolbar.ButtonGroup spacing={0}>
            <UndoRedo />
          </Toolbar.ButtonGroup>
          <Toolbar.Divider />
          <Toolbar.ButtonGroup spacing={0}>
            {showHyperlinks && <HyperlinkButton />}
            <StyleButtons />
          </Toolbar.ButtonGroup>
          {showAlignment && <Toolbar.Divider />}
        </>
      )}
      {showAlignment && (
        <>
          <Toolbar.ButtonGroup spacing={0}>
            <AlignmentButtons />
          </Toolbar.ButtonGroup>
        </>
      )}
    </Toolbar.ButtonGroup>
  )
}
