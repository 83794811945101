import { observer } from 'mobx-react-lite'
import { OrgPermissionMap } from '@showrunner/codex'
import { StaffBadges } from '@components/Staff/Badges'
import { Toolbar } from '@components/Toolbar'
import { RundownTitle } from './DocumentTitle'
import { HeaderTimestamp } from './HeaderTimestamp'
import { MiniHeader } from './MiniHeader'
import { useMst } from '@state'
import { useNavigation } from '@hooks'
import { RundownSyncStatus } from './RundownSyncStatus'

export const RundownHeader = observer(function RundownHeader() {
  const { currentOrg, currentRundown, location, user, view } = useMst()
  const { navigateToFolder, navigateToScript } = useNavigation()
  if (!currentRundown) return <></>

  const {
    prefersSplitLayout,
    prefersRowLayout,
    prefersColumnLayout,
    exitSplitLayout,
    updateLayoutPreference,
  } = user

  const scriptId = location.getPathParam('scriptId')

  const handleCloseRundown = () => {
    exitSplitLayout()
    if (scriptId) {
      navigateToScript(scriptId)
    } else {
      navigateToFolder(currentRundown.folderId)
    }
  }

  return (
    <Toolbar>
      <MiniHeader.LeftSection>
        <RundownTitle
          rundown={currentRundown}
          readOnly={
            !user.currentOrgPermissions?.includes(
              OrgPermissionMap.UPDATE_RUNDOWNS
            )
          }
        />
        <HeaderTimestamp
          at={currentRundown.contentsModifiedAt}
          userName={currentRundown.lastModifier?.name}
          createdAt={currentRundown.createdAt}
        />
        {user.staff && currentOrg && (
          <StaffBadges
            flags={currentOrg.betaFlags}
            debugFlags={view.debugFlags}
          />
        )}
      </MiniHeader.LeftSection>
      <MiniHeader.RightSection>
        <RundownSyncStatus rundown={currentRundown} />
        <Toolbar.Button
          icon="fa-table-rows"
          active={prefersSplitLayout && prefersRowLayout}
          onClick={() => updateLayoutPreference('rows')}
        />
        <Toolbar.Button
          icon="fa-table-columns"
          active={prefersSplitLayout && prefersColumnLayout}
          onClick={() => updateLayoutPreference('columns')}
        />
        <Toolbar.Button icon="fa-xmark" onClick={handleCloseRundown} />
      </MiniHeader.RightSection>
    </Toolbar>
  )
})
