// This component is used to attach the singleton choo app dom tree to
// a node in the wallaby app. Different views create different instances of
// this component but we only show one at a time and it always re-attaches the same
// choo app instance (managed by ChooWrapper)
import React from 'react'
import { PrintPreviewWrapper } from './PrintPreviewWrapper'
import { PagelessWrapper } from './PagelessWrapper'

export const ScriptWrapper = ({
  children,
  pageless,
  noWrap,
}: {
  noWrap?: boolean
  pageless?: boolean
  children: React.ReactNode
}) => {
  if (pageless) {
    return <PagelessWrapper noWrap={noWrap}>{children}</PagelessWrapper>
  }
  return <PrintPreviewWrapper>{children}</PrintPreviewWrapper>
}
