import React from 'react'
import { Menu } from '@mantine/core'
import { FloatingMenuProps } from '@hooks'

export const ContextMenu = ({
  items,
  floatProps,
}: {
  items: React.ReactNode[]
  floatProps: FloatingMenuProps
}) => {
  return (
    <Menu opened={floatProps.opened} onClose={floatProps.hide} withinPortal>
      <Menu.Target>
        <div style={floatProps.targetStyle} />
      </Menu.Target>
      <Menu.Dropdown>
        {items.map((item, index) => (
          <React.Fragment key={index}>
            {item}
            {index !== items.length - 1 && <Menu.Divider />}
          </React.Fragment>
        ))}
      </Menu.Dropdown>
    </Menu>
  )
}
