import { Divider, Stack, Title } from '@mantine/core'
import { NavAnchor } from '@components/NavAnchor'
import { CreateRundownSchema } from './CreateRundownSchema'
import { RundownSchemaList } from './RundownSchemaList'

export const Rundowns = () => (
  <Stack>
    <Title order={2}>
      <NavAnchor href="/staff">Staff Zone</NavAnchor>
    </Title>
    <RundownSchemaList />
    <Divider />
    <CreateRundownSchema />
  </Stack>
)
