import { IPrompterPushCandidate, IPrompterSegment } from '@state/types'
import { NoContent } from './NoContent'
import { SimplePush } from './SimplePush'
import { ComplexPush } from './ComplexPush'
import { PushStatus } from './util'

export const PrompterInnards = ({
  pushStatus,
  scriptName,
  segments,
  candidate,
}: {
  pushStatus: PushStatus
  scriptName: string
  segments: IPrompterSegment[]
  candidate: IPrompterPushCandidate
}) => {
  switch (pushStatus) {
    case 'no-content':
      return <NoContent scriptName={scriptName} />
    case 'simple':
      return <SimplePush scriptName={scriptName} segment={segments[0]} />
    case 'complex':
      return <ComplexPush candidate={candidate} segments={segments} />
    default:
      return <></>
  }
}
