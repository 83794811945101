import React from 'react'
import cn from 'classnames'
import { Fragment } from 'prosemirror-model'
import { Text } from '@mantine/core'
import { FragmentDiff } from '@util/diffing/FragmentDiff'
import {
  SideBySideDiffLine,
  isLineDiff,
  isWordDiff,
  isOmitted,
  isUnchanged,
} from '@util/diffing/sideBySide'
import { Toast } from '@components/Toast'
import {
  SelectionSide,
  useDiffSelection,
  DATA_DIFF_PROPS,
} from './useDiffSelection'
import styles from './SideBySideDiff.module.scss'

const Row = ({
  className,
  children,
}: {
  className?: string
  children: React.ReactNode
}) => (
  <div
    {...DATA_DIFF_PROPS.row}
    className={cn(styles.sideBySideDiff_row, className)}
  >
    {children}
  </div>
)

const LeftSide = ({
  className,
  children,
}: {
  className?: string
  children: React.ReactNode
}) => {
  return (
    <div
      {...DATA_DIFF_PROPS.left}
      className={cn(styles.sideBySideDiff_rowSide, styles.left, className)}
    >
      {children}
    </div>
  )
}

const RightSide = ({
  className,
  children,
}: {
  className?: string
  children: React.ReactNode
}) => {
  return (
    <div
      {...DATA_DIFF_PROPS.right}
      className={cn(styles.sideBySideDiff_rowSide, styles.right, className)}
    >
      {children}
    </div>
  )
}

const LineBreak = () => (
  <i className={cn(styles.changed, styles.lineBreak, 'fa fa-turn-down-left')} />
)

const OmittedRow = ({ count }: { count: number }) => {
  const s = count > 1 ? 's' : ''
  const text = `${count} row${s} omitted`
  return (
    <Row>
      <LeftSide className={styles.omitted}>{text}</LeftSide>
      <RightSide className={styles.omitted}>{text}</RightSide>
    </Row>
  )
}

const NoDiffChangesRow = () => {
  const message = (
    <Text span fw="bold">
      Both versions are identical
    </Text>
  )
  return (
    <Row>
      <LeftSide>
        <Toast message={message} dismissable={false} />
      </LeftSide>
      <RightSide>
        <Toast message={message} dismissable={false} />
      </RightSide>
    </Row>
  )
}

const SideBySideRow = ({ line }: { line: SideBySideDiffLine }) => {
  if (isOmitted(line)) {
    return <OmittedRow count={line.count} />
  }

  if (isLineDiff(line)) {
    const { side, value } = line

    return (
      <Row>
        <LeftSide>
          <span className={cn({ [styles.changed]: side === 'left' })}>
            {side === 'left' ? value : ''}
          </span>
          {line.newline === 'left' && <LineBreak />}
        </LeftSide>
        <RightSide>
          <span className={cn({ [styles.changed]: side === 'right' })}>
            {side === 'right' ? value : ''}
          </span>
          {line.newline === 'right' && <LineBreak />}
        </RightSide>
      </Row>
    )
  }

  if (isWordDiff(line)) {
    const { left, right } = line

    return (
      <Row>
        <LeftSide>
          {left.map(({ text, changed }, index) => (
            <span key={index} className={cn({ [styles.changed]: changed })}>
              {text}
            </span>
          ))}
          {line.newline === 'left' && <LineBreak />}
        </LeftSide>
        <RightSide>
          {right.map(({ text, changed }, index) => (
            <span key={index} className={cn({ [styles.changed]: changed })}>
              {text}
            </span>
          ))}
          {line.newline === 'right' && <LineBreak />}
        </RightSide>
      </Row>
    )
  }

  // we've type narrowed to unchanged
  return (
    <Row>
      <LeftSide>
        {line.text}
        {line.newline === 'left' && <LineBreak />}
      </LeftSide>
      <RightSide>
        {line.text}
        {line.newline === 'right' && <LineBreak />}
      </RightSide>
    </Row>
  )
}

type SideBySideDiffProps = {
  leftFragment: Fragment
  rightFragment: Fragment
  leftTitle: string
  rightSubtitle: string
  selectionSide?: SelectionSide
  linesOfContext?: number
  monochrome: boolean
  leftSleneName?: string
  rightSleneName?: string
}

export const SideBySideDiff = React.forwardRef<
  HTMLDivElement,
  SideBySideDiffProps
>(
  (
    {
      leftFragment,
      rightFragment,
      leftTitle,
      rightSubtitle,
      linesOfContext,
      monochrome,
      leftSleneName,
      rightSleneName,
    },
    printRef
  ) => {
    const { selectionSide } = useDiffSelection()
    const diff = new FragmentDiff({
      left: leftFragment,
      right: rightFragment,
    })
    const lines = diff.getSideBySideDiff(linesOfContext)
    const hasChanges = !!lines.find((l) => !(isOmitted(l) || isUnchanged(l)))

    return (
      <div
        ref={printRef}
        className={cn(styles.sideBySideDiff, {
          [styles.monochrome]: monochrome,
          [styles.leftSelect]: selectionSide === 'left',
          [styles.rightSelect]: selectionSide === 'right',
        })}
      >
        <Row className={styles.title}>
          <LeftSide className={styles.title}>
            {typeof leftSleneName === 'string' && (
              <div className={styles.sleneName}>{leftSleneName}</div>
            )}
            <span className={styles.titleText}>{leftTitle}</span>
          </LeftSide>
          <RightSide className={styles.title}>
            {typeof rightSleneName === 'string' ? (
              <div className={styles.sleneName}>{rightSleneName}</div>
            ) : (
              <span className={styles.titleText}>Current Document</span>
            )}
            <span className={styles.subtitleText}>&nbsp;{rightSubtitle}</span>
          </RightSide>
        </Row>

        {!hasChanges && <NoDiffChangesRow />}
        {lines.map((line, index) => (
          <SideBySideRow line={line} key={index} />
        ))}
      </div>
    )
  }
)

SideBySideDiff.displayName = 'SideBySideDiff'
