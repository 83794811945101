import { ScriptStatus } from '@showrunner/codex'
import { RundownRowData } from '@util/ScriptoApiClient/types'

export const SOCKET_STATUS_EVENTS = {
  CONNECT: 'connect',
  DISCONNECT: 'disconnect',
  CONNECT_ERROR: 'connect_error',
} as const

// These events are sent/received from the choo app
export const EDITOR_EVENTS = {
  // emitters
  JOIN_SCRIPT: 'JOIN_SCRIPT',
  LEAVE_SCRIPT: 'LEAVE_SCRIPT',
  UPDATE_CURSOR: 'UPDATE_CURSOR',

  SCRIPT_UPDATED: 'SCRIPT_UPDATED',
  USER_JOINED: 'USER_JOINED',
  USER_LEFT: 'USER_LEFT',
  CURSOR_UPDATED: 'CURSOR_UPDATED',
  SCRIPT_STATUS_CHANGED: 'SCRIPT_STATUS_CHANGED',
  // TODO Jan 2024: can we handle this in socketManager
  AUTH_ERROR: 'AUTH_ERROR',

  COMMENT_ADDED: 'COMMENT_ADDED',
  COMMENT_UPDATED: 'COMMENT_UPDATED',
  COMMENT_DELETED: 'COMMENT_DELETED',
  COMMENT_RESOLVED: 'COMMENT_RESOLVED',
  COMMENT_UNRESOLVED: 'COMMENT_UNRESOLVED',
  userEvent: 'userEvent',

  // Jan 2024 notes:
  // these constants were moved/cleaned up from choo-app/lib/editor/constants.js

  // These are now handled in SocketManager
  // PING: 'PING',
  // CONNECT: 'connect',
  // DISCONNECT: 'disconnect',
  // CONNECT_ERROR: 'connect_error',

  // These are unused in the app or are never sent from the server, deprecating
  // API_ERROR: 'API_ERROR',
  // NOTIFY: 'NOTIFY',
} as const

export type EditorEvent = ValueOf<typeof EDITOR_EVENTS>
export function isEditorEvent(eventName: string): eventName is EditorEvent {
  return Object.values<string>(EDITOR_EVENTS).includes(eventName)
}
export type EditorMessage = [EditorEvent, unknown]
export function isEditorMessage(
  message: [eventName: string, payload: unknown]
): message is EditorMessage {
  return Object.values(EDITOR_EVENTS).includes(message[0] as EditorEvent)
}

export type ConnectionStatus = 'connected' | 'disconnected' | 'error'

// Event names for the socket events the server
// emits pertaining to rundowns
export const RUNDOWN_EVENTS = {
  // received
  ROW_UPDATED: 'RUNDOWN_ROW_UPDATED',
  ROWS_INSERTED: 'RUNDOWN_ROWS_INSERTED',
  ROWS_DELETED: 'RUNDOWN_ROWS_DELETED',
  ROWS_MOVED: 'RUNDOWN_ROWS_MOVED',
  RUNDOWN_ROW_BLOBS_UPDATED: 'RUNDOWN_ROW_BLOBS_UPDATED',

  // emitted
  JOIN_RUNDOWN: 'JOIN_RUNDOWN',
  LEAVE_RUNDOWN: 'LEAVE_RUNDOWN',
} as const

const {
  ROWS_DELETED,
  ROWS_INSERTED,
  ROWS_MOVED,
  ROW_UPDATED,
  RUNDOWN_ROW_BLOBS_UPDATED,
} = RUNDOWN_EVENTS

// The base type for all rundown row socket messages
type RundownRowPayload = {
  rundownId: number
  checksum: string
  userId: string
}

// These are the shapes of the 4 different socket messages
// pertaining to rundown contents
export type RowsInsertedPayload = RundownRowPayload & {
  data: RundownRowData[]
  sequence: number
}
export type RowUpdatedPayload = RundownRowPayload & {
  data: RundownRowData
  delta: {
    key: string
    value: JSONValue | null
  }
}
export type RowsDeletedPayload = RundownRowPayload & {
  delta: {
    rowIds: number[]
  }
}
export type RowsMovedPayload = RundownRowPayload & {
  delta: {
    rowIds: number[]
    sequence: number
  }
}

export type BlobsUpdatedPayload = RundownRowPayload & {
  delta: {
    key: string
    rowValues: Array<{
      rowId: number
      value: JSONValue | null
    }>
  }
}

// Define a union type showing the permutations of
// each message name and payload type. This will allow us to know
// that the payload of a value like ['RUNDOWN_ROW_UPDATED', payload]
// is a RowUpdatedPayload
export type RundownRowMessage =
  | [typeof ROWS_INSERTED, RowsInsertedPayload]
  | [typeof ROWS_DELETED, RowsDeletedPayload]
  | [typeof ROWS_MOVED, RowsMovedPayload]
  | [typeof ROW_UPDATED, RowUpdatedPayload]
  | [typeof RUNDOWN_ROW_BLOBS_UPDATED, BlobsUpdatedPayload]

// This typeguard lets us see if an arbitrary socket message
// is one of those 4 options in the union type
export function isRundownRowMessage(
  message: [eventName: string, payload: unknown]
): message is RundownRowMessage {
  const name = (message as RundownRowMessage)[0]
  return [
    ROWS_DELETED,
    ROWS_INSERTED,
    ROWS_MOVED,
    ROW_UPDATED,
    RUNDOWN_ROW_BLOBS_UPDATED,
  ].includes(name)
}

// The signature of a function that can handle a row message
export type RowMessageDispatcher = (message: RundownRowMessage) => void

export const WORKSPACE_EVENTS = {
  // emitted
  JOIN_WORKSPACE: 'JOIN_WORKSPACE',
  LEAVE_WORKSPACE: 'LEAVE_WORKSPACE',
  // received
  RUNDOWN_LISTING_UPDATED: 'RUNDOWN_LISTING_UPDATED',
  SCRIPT_LISTING_UPDATED: 'SCRIPT_LISTING_UPDATED',
  FOLDER_LISTING_UPDATED: 'FOLDER_LISTING_UPDATED',
} as const

type ListingUpdatedPayload = {
  orgId: string
  name: string
  folderId: string
  contentsModifiedAt: string
  contentsModifiedBy: string
}

export type RundownListingUpdatePayload = ListingUpdatedPayload & {
  rundownId: number
}
export type ScriptListingUpdatePayload = ListingUpdatedPayload & {
  scriptId: string
  status: ScriptStatus
}

export type FolderListingUpdatePayload = {
  orgId: string
  folderId: string
  parentId: string
  name: string
  inTrash: boolean
  isPrivate: boolean
}

const {
  RUNDOWN_LISTING_UPDATED,
  FOLDER_LISTING_UPDATED,
  SCRIPT_LISTING_UPDATED,
} = WORKSPACE_EVENTS
export type WorkspaceMessage =
  | [typeof RUNDOWN_LISTING_UPDATED, RundownListingUpdatePayload]
  | [typeof FOLDER_LISTING_UPDATED, FolderListingUpdatePayload]
  | [typeof SCRIPT_LISTING_UPDATED, ScriptListingUpdatePayload]

export function isWorkspaceMessage(
  message: [eventName: string, payload: unknown]
): message is WorkspaceMessage {
  const name = (message as WorkspaceMessage)[0]
  return [
    RUNDOWN_LISTING_UPDATED,
    FOLDER_LISTING_UPDATED,
    SCRIPT_LISTING_UPDATED,
  ].includes(name)
}

export type WorkspaceMessageDispatcher = (message: WorkspaceMessage) => void

type RemoteUserData = {
  clientId: string
  color: string
  user: {
    name: string
    avatar: string | null
  }
}
export type UserEventPayload = {
  newUser?: string
  removedCursor?: {
    clientId: string
  }
  users: RemoteUserData[]
}

export type CursorUpdatePayload = {
  scriptId: string
  clientId: string
  version: number
  headPosition: number
  anchorPosition: number
  color: string
  user: {
    name: string
    avatar: string | null
  }
}

export type ScriptVersionUpdatePayload = {
  scriptId: string
  version: number
}

export type ScriptStatusChangePayload = {
  scriptId: string
  socketId: string
  userId: string
  statusType: 'OPEN' | 'LIMITED'
}

type CommentEventPayload = {
  parentId?: string | null
  commentId: string
  scriptId: string
  userId: string
  timestamp: string
}

export type CommentAddedPayload = CommentEventPayload & {
  eventType: 'COMMENT_ADDED'
  replyCount?: number
}

export type CommentUpdatedPayload = CommentEventPayload & {
  eventType: 'COMMENT_UPDATED'
  text: string
}

export type CommentDeletedPayload = CommentEventPayload & {
  eventType: 'COMMENT_DELETED'
  replyCount: 0
  // api is returning a weird serialization of a date here
  parentDeleted: unknown
}

export type CommentResolvedPayload = CommentEventPayload & {
  eventType: 'COMMENT_RESOLVED'
}

export type CommentUnresolvedPayload = CommentEventPayload & {
  eventType: 'COMMENT_UNRESOLVED'
}
