import { Menu, UnstyledButton } from '@mantine/core'
import { NavAnchor, NavAnchorHref } from '@components/NavAnchor'
import { FaIcon } from '@components/FaIcon'
import { FancyMenuItem } from '@components/FancyMenuItem'
import { SnapshotSummary } from '@util/ScriptoApiClient/types'
import { SOME_DIFF_CONTEXT_LINES } from '@util/printing'

import styles from './SnapshotItem.module.scss'

const getHref = (
  target: 'compare' | 'diff',
  scriptId: string,
  snapshotId: string
): NavAnchorHref => {
  const qs = target === 'diff' ? `?context=${SOME_DIFF_CONTEXT_LINES}` : ''
  return `/scripts/${scriptId}/${target}/${snapshotId}${qs}`
}

export const SnapshotMenu = ({
  snapshot,
  renameHandler,
}: {
  snapshot: SnapshotSummary
  renameHandler: (id: string) => void
}) => (
  <Menu withinPortal position="right">
    <Menu.Target>
      <UnstyledButton className={styles.snapshotmenu_button}>
        <FaIcon icon="fa-ellipsis-v" />
      </UnstyledButton>
    </Menu.Target>
    <Menu.Dropdown>
      <FancyMenuItem
        title="Rename snapshot"
        iconClass="fa fa-edit"
        onClick={() => renameHandler(snapshot.id)}
      ></FancyMenuItem>
      <Menu.Divider />
      <Menu.Item>
        <NavAnchor
          className="is-plain-link"
          href={getHref('compare', snapshot.scriptId, snapshot.id)}
        >
          <i className="fa fa-fw fa-asterisk" />
          &nbsp;&nbsp;Revision asterisk comparison
        </NavAnchor>
      </Menu.Item>
      <Menu.Item>
        <NavAnchor
          className="is-plain-link"
          href={getHref('diff', snapshot.scriptId, snapshot.id)}
        >
          <i className="fa fa-fw fa-columns" />
          &nbsp;&nbsp;Side-by-side comparison
        </NavAnchor>
      </Menu.Item>
    </Menu.Dropdown>
  </Menu>
)
