import { LogsEvent, LogsInitConfiguration } from '@datadog/browser-logs'
import pkg from '../../../package.json'

const version = pkg.version

const appLaunchTime = new Date().valueOf()
const CLIENT_TOKEN = 'pub389619c1c76486680ca38d505456f03d'
const SITE = 'datadoghq.com'

type EnvTag =
  | 'production'
  | 'beta'
  | 'rc'
  | 'staging'
  | 'chromatic'
  | 'netlify'
  | 'dev'

// by default we only log messages from production, beta, rc, and staging.
const sendMessageToDatadog = (tag: EnvTag): boolean => {
  return ['production', 'beta', 'staging', 'rc'].includes(tag)
}

// based on location.origin figure out what env tag to use. This
// gets sent on every message that goes to datadog (if we are logging
// for that environment)
const envTag: EnvTag = (() => {
  if (location.origin.includes('https://app.scripto.live')) {
    return 'production'
  }
  if (location.origin.includes('https://beta.scripto.live')) {
    return 'beta'
  }
  if (location.origin.includes('https://staging.scripto.dev')) {
    return 'staging'
  }
  if (location.origin.includes('https://rc.scripto.dev')) {
    return 'rc'
  }
  if (location.origin.includes('chromatic.com')) {
    return 'chromatic'
  }
  if (location.origin.startsWith('https://')) {
    return 'netlify'
  }

  return 'dev'
})()

// called before each event is sent
const beforeSend = (evt: LogsEvent) => {
  if (!sendMessageToDatadog(envTag)) {
    return false
  }

  const httpStatusCode = evt.http?.status_code
  if (httpStatusCode === 0) {
    // this tells datadogLogs NOT to send
    return false
  }
  // how long has the app been running
  evt.app_age_seconds = (new Date().valueOf() - appLaunchTime) / 1000
}

export const config: LogsInitConfiguration = {
  clientToken: CLIENT_TOKEN,
  site: SITE,
  service: 'wallaby',
  version,
  env: envTag,
  beforeSend,
}
