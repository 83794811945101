import { types } from 'mobx-state-tree'
import { BaseModel } from './BaseModel'

export type InviteStatus = 'open' | 'accepted'

export const Invite = BaseModel.named('Invite')
  .props({
    id: types.string,
    email: types.string,
    status: types.enumeration<InviteStatus>(['open', 'accepted']),
    isRevoking: false,
  })
  .actions((self) => ({
    setIsRevoking(value: boolean) {
      self.isRevoking = value
    },
  }))
  // async actions
  .actions((self) => ({
    async revoke() {
      const { currentOrg } = self.rootStore
      if (!currentOrg) return
      self.setIsRevoking(true)
      const inviteId = self.id
      await self.apiClient.revokeOrgInvite({
        orgId: currentOrg.id,
        inviteId,
      })
    },
  }))
