/*
  ExplorerView is a set of reusable components and context for sidebar content
  (e.g. the FolderExplorer)

  The ExplorerView provides a reusable header & content component that allow expand/collapse
  when there's more than one and a hook, useExplorerState, to let the components share state
  about whether they're expanded or collapsed.

  Pseudo code example: The folder explorer is structured like:

  <ExplorerView>
    <ExplorerView.Header>{folder tree header}</ExplorerView.Header>
    <ExplorerView.Content>{folder tree}</ExplorerView.Content>
    <ExplorerView.Header>{document header}</ExplorerView.Header>
    <ExplorerView.Content>{scrolling documents list}</ExplorerView.Content>
  </ExplorerView>
*/

import React from 'react'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import { Group } from '@mantine/core'
import { useMst } from '@state'
import { ExplorerHeaderButton } from './ExplorerHeaderButton'
import { ExpandIcon } from '@components/ExpandIcon'

import styles from './ExplorerView.module.scss'

export const ExplorerView = ({
  children,
  hidden = false,
}: {
  children: React.ReactNode
  hidden?: boolean
}) => (
  <div
    className={cn(styles.explorerView, {
      [styles.explorerView___hidden]: hidden,
    })}
  >
    {children}
  </div>
)

const ExplorerContent = observer(function ExplorerContent({
  children,
  sectionId,
  enableCollapse,
}: {
  children: React.ReactNode
  sectionId: string
  enableCollapse?: boolean
}) {
  const { view } = useMst()
  const isCollapsed =
    enableCollapse && view.explorerState.isSectionCollapsed(sectionId)
  return (
    <div
      className={cn(styles.explorerView_contents, {
        [styles.expanded]: !isCollapsed,
      })}
    >
      {children}
    </div>
  )
})

const ExplorerHeader = ({
  title,
  buttons,
  expandIcon,
  onClick,
  enableCollapse,
}: {
  title: React.ReactNode
  buttons?: React.ReactNode
  expandIcon?: 'point-right' | 'point-down'
  enableCollapse?: boolean
  onClick?: () => void
}) => {
  const handleClick = () => {
    if (enableCollapse) {
      onClick?.()
    }
  }
  return (
    <Group className={styles.explorerHeader} gap={0} wrap="nowrap">
      <Group
        className={cn(styles.explorerHeader_titleWrapper, {
          [styles.expandable]: expandIcon,
        })}
        gap={0}
        wrap="nowrap"
      >
        {expandIcon && (
          <div onClick={handleClick}>
            <ExpandIcon
              width={24}
              expanded={expandIcon === 'point-down'}
              variant="header"
            />
          </div>
        )}
        <div className={styles.explorerHeader_title}>{title}</div>
      </Group>

      {buttons && (
        <Group wrap="nowrap" gap={0}>
          {buttons}
        </Group>
      )}
    </Group>
  )
}

ExplorerView.Content = ExplorerContent
ExplorerView.Header = ExplorerHeader
ExplorerView.HeaderButton = ExplorerHeaderButton
