import type {
  RundownPayload,
  BlobData,
  RundownRowData,
  RundownRowPayload,
  RundownRowBulkInsertPayload,
} from '@util/ScriptoApiClient/types'

import type { ApiConfig } from './types'

// note that importing noop from @util makes the codegen barf
const noop = () => {
  // noop
}

/* Entire rundown methods */
export const createRundown: ApiConfig<
  { name: string; folderId: string },
  { id: number }
> = (data) => ({
  method: 'POST',
  url: '/rundowns',
  data,
})

export const getRundown: ApiConfig<number | string, RundownPayload> = (id) => ({
  method: 'GET',
  url: `/rundowns/${id}`,
})

export const duplicateRundown: ApiConfig<
  { rundownId: number; name: string },
  { id: number }
> = ({ rundownId, name }) => ({
  method: 'POST',
  url: `/rundowns/${rundownId}/copy`,
  data: { name },
})

export const cloneRundown: ApiConfig<
  { rundownId: number },
  {
    id: number
    folderId: string
  }
> = ({ rundownId }) => ({
  method: 'POST',
  url: `/rundowns/${rundownId}/clone`,
  // this isn't configurable by end users (yet)
  data: { prefix: 'Copy of ' },
})

export const trashRundown: ApiConfig<
  { rundownId: number },
  { message?: 'success'; error?: 'string' }
> = ({ rundownId }) => ({
  method: 'DELETE',
  url: `/rundowns/${rundownId}`,
})

export const moveRundown: ApiConfig<
  { rundownId: number; folderId: string },
  void
> = {
  buildRequest: ({ rundownId, folderId }) => ({
    method: 'PUT',
    url: `/rundowns/${rundownId}/move`,
    data: { folderId },
  }),
  transformResponse: noop,
}

export const updateRundownName: ApiConfig<
  { rundownId: number; name: string },
  void
> = {
  buildRequest: ({ rundownId, name }) => ({
    method: 'PUT',
    url: `/rundowns/${rundownId}`,
    data: { name },
  }),
  transformResponse: noop,
}

export const updateRundownBlobData: ApiConfig<
  {
    rundownId: number
    blobData: BlobData
  },
  Omit<RundownPayload, 'rows'>
> = ({ rundownId, blobData }) => ({
  method: 'PUT',
  url: `/rundowns/${rundownId}`,
  data: { blobData },
})

/* Rundown Row methods */
export const deleteRundownRows: ApiConfig<
  {
    rundownId: number
    rowIds: number[]
  },
  { checksum: string }
> = ({ rundownId, rowIds }) => ({
  method: 'PUT',
  url: `/rundowns/${rundownId}/removerows`,
  data: { rowIds },
})

export const insertRundownRows: ApiConfig<
  {
    rundownId: number
    rowDataList: Partial<RundownRowData>[]
    sequence: number
  },
  RundownRowBulkInsertPayload
> = ({ rundownId, rowDataList, sequence }) => ({
  method: 'POST',
  url: `/rundowns/${rundownId}/insertrows`,
  data: { sequence, rowDataList },
})

export const insertBlankRundownRows: ApiConfig<
  {
    rundownId: number
    rowCount: number
    sequence: number
  },
  RundownRowBulkInsertPayload
> = ({ rundownId, sequence, rowCount }) => {
  const rowDataList: Partial<RundownRowData>[] = []
  for (let i = 0; i < rowCount; i++) {
    rowDataList.push({ rowTypeId: 'element', blobData: {} })
  }
  return insertRundownRows({ rundownId, sequence, rowDataList })
}

export const moveRundownRows: ApiConfig<
  {
    rundownId: number
    rowIds: number[]
    sequence: number
  },
  RundownRowPayload
> = ({ rundownId, rowIds, sequence }) => ({
  method: 'PUT',
  url: `/rundowns/${rundownId}/moverows`,
  data: { sequence, rowIds },
})

type UpdateRowsParams = {
  rundownId: number
  key: string
  rowValues: Array<{
    rowId: number
    value: JSONValue | null
  }>
}
export const updateRundownRowsBlobData: ApiConfig<
  UpdateRowsParams,
  { checksum: string }
> = ({ rundownId, key, rowValues }) => ({
  method: 'PUT',
  url: `/rundowns/${rundownId}/rows/blobdata`,
  data: { key, rowValues },
})
