import { types } from 'mobx-state-tree'
import { format } from 'date-fns'
import { NodeTypeKey } from '@showrunner/codex'
import {
  FormattedTiming,
  FormattedSlugTiming,
} from 'src/choo-app/lib/editor/plugins/meta/timing'
import {
  ScriptFormat,
  ScriptFormatMap,
  ScriptStatus,
  ScriptStatusMap,
} from '@showrunner/codex'
import { PrompterPushCandidate } from './PrompterPushCandidate'
import { PmEditor } from './PmEditor'
import { Timing, SlugTiming } from './Timing'
import {
  INavLink,
  NavLinkCursorPosition,
  NumberableNodeTypeKeys,
  IPopulatedPmEditor,
} from '@state/types'
import { ScriptListingBase } from './ListingBase'
import { NavLinkModel } from './NavLinkModel'
import { SyncStatus } from './SyncStatus'
import { saveTextToFile } from '@util'
import {
  NavLinkData,
  PDF_HEADER_DATEMASK,
  SCREENPLAY_NUMBERABLE_KEYS,
  STUDIO_NUMBERABLE_KEYS,
} from '@util/constants'
import {
  BETA_EXPORTED_FDX,
  SCRIPT_EXPORTED_CHARACTER_REPORT,
  SCRIPT_EXPORTED_FOUNTAIN,
  SCRIPT_EXPORTED_RUNDOWN,
  SCRIPT_EXPORTED_TXT,
} from '@util/mixpanel/eventNames'
import { ScriptFormatModel } from './ScriptFormats'
import { IsoDate } from './IsoDate'

const ElementNumber = types.model('ElementNumber', {
  exists: types.boolean,
  isOn: types.boolean,
})

export const RemoteUser = types.model('RemoteUser', {
  color: '',
  clientId: '',
  avatar: '',
  name: '',
})

// A LoadedScript is one in the editor. This is where we keep information
// about things like the script nav, sync status, etc.
export const LoadedScript = ScriptListingBase.named('LoadedScript')
  .props({
    // these values we know before GET script/:scriptId
    id: types.string,
    type: types.enumeration<ScriptFormat>(Object.values(ScriptFormatMap)),
    format: types.maybe(types.maybeNull(types.string)),
    scriptFormat: ScriptFormatModel,

    // this is what we got back on GET script -- the choo app doesn't update
    // the value in state... it's really the availableServerVersion but then it gets
    // out of date
    version: types.number,
    orgId: types.string,
    readRate: types.number,
    createdAt: IsoDate,
    slackWebhook: types.boolean,
    // these values get loaded after & updated by the choo app
    navLinkMap: types.map(NavLinkModel),
    elementNumberMap: types.map(ElementNumber),
    syncStatus: types.optional(SyncStatus, {}),
    locked: types.optional(types.boolean, false),
    pageCount: types.maybe(types.number),
    timing: Timing,
    selectionTiming: Timing,
    slugTiming: types.array(SlugTiming),
    users: types.array(RemoteUser),
    totalComments: types.maybe(types.number),
    prompterPushCandidate: types.maybe(PrompterPushCandidate),
    showPrompterView: false,
    elementMenuOpened: false,
    pmEditor: types.optional(PmEditor, {}),
  })
  .views((self) => ({
    // The pmEditor instance either has both editorView and editorState
    // present or neither, but the volatile state makes that hard to
    // tease out. This helper gives you all or nothing
    get observableEditor(): IPopulatedPmEditor | undefined {
      if (self.pmEditor.editorState && self.pmEditor.editorView) {
        return self.pmEditor as IPopulatedPmEditor
      }
    },
    get path() {
      return `/scripts/${self.id}`
    },
    get sortedNavLinks() {
      return Array.from(self.navLinkMap.values()).sort(
        (a, b) => a.displayPosition - b.displayPosition
      )
    },
    get hasRemoteUsers(): boolean {
      const { status, socketId } = self.rootStore.socketManager
      return status === 'connected' && socketId !== '' && self.users.length > 1
    },
    get isScreenplay(): boolean {
      return self.type === ScriptFormatMap.SCREENPLAY
    },
    get isInk(): boolean {
      return self.scriptFormat.definition.scriptType === 'ink'
    },
    get isOpen(): boolean {
      return self.accessLevel === ScriptStatusMap.OPEN
    },
    get isClassic(): boolean {
      return self.type === ScriptFormatMap.CLASSIC
    },
    get isLimited(): boolean {
      return self.accessLevel === ScriptStatusMap.LIMITED
    },
    get isEditable(): boolean {
      return !this.isLimited || self.rootStore.user.canEditLimited
    },
    get canReorderSections(): boolean {
      return this.isEditable && !self.locked
    },
    get manuallyNumberableBlockTypes() {
      const possibleTypes: NumberableNodeTypeKeys[] = this.isScreenplay
        ? SCREENPLAY_NUMBERABLE_KEYS
        : STUDIO_NUMBERABLE_KEYS

      return possibleTypes.filter(
        (t) => self.scriptFormat.definition.numbering[t] === 'manual'
      )
    },
    get shouldPrintInBrowser(): boolean {
      return (
        self.rootStore.view.isDebugEnabled('printbutton') ||
        self.format === 'snl'
      )
    },
    // find the nav link that includes the current cursor position or selection start
    get currentNavLink() {
      const position = this.observableEditor?.selection?.$from.pos
      if (typeof position === 'number') {
        return this.sortedNavLinks.find((nl) => nl.includesPosition(position))
      }
    },
    get isLinkedToRundown(): boolean {
      return !!self.rootStore.currentRundown?.orderedScripts.some(
        (s) => s.scriptId === self.id
      )
    },
    findCurrentNavLink(visibleNavLinks: INavLink[]):
      | {
          link: INavLink
          position: NavLinkCursorPosition
        }
      | undefined {
      const cursorPosition = this.observableEditor?.selection?.$from.pos
      const firstLink = visibleNavLinks[0]

      if (!(typeof cursorPosition === 'number' && firstLink)) {
        return
      }

      // check if the cursor is before the first nav link
      if (firstLink.pos > cursorPosition) {
        return {
          link: firstLink,
          position: 'navLink_cursor___above',
        }
      }

      // find all nav links at or before the cursor position-- we want
      // to get the last one
      const precedingLinks = visibleNavLinks.filter(
        (nl) => nl.pos <= cursorPosition
      )

      const link = precedingLinks[precedingLinks.length - 1]
      const position = link.includesPosition(cursorPosition)
        ? 'navLink_cursor___on'
        : 'navLink_cursor___below'
      return {
        link,
        position,
      }
    },
    get supportsBlockAlignment() {
      return !(this.isClassic || this.isInk)
    },
    get supportsHyperlinks() {
      return !this.isInk
    },
  }))
  .actions((self) => ({
    setShowPrompterView(value: boolean) {
      const eventName = value
        ? self.MIXPANEL_EVENTS.PROMPTER_VIEW_OPENED
        : self.MIXPANEL_EVENTS.PROMPTER_VIEW_CLOSED

      self.trackEvent(eventName)
      self.showPrompterView = value
    },
    setElementMenuOpened(value: boolean) {
      self.elementMenuOpened = value
    },
    createPushCandidate() {
      const candidate = PrompterPushCandidate.create({
        parentId: self.id,
        parentType: 'script',
      })
      self.prompterPushCandidate = candidate
      return candidate
    },
    clearPushCandidate() {
      self.prompterPushCandidate = undefined
    },
    setTotalComments(value: number) {
      self.totalComments = value
    },
    setSlackWebhook(value: boolean) {
      self.slackWebhook = value
    },
    setName(name: string) {
      self.name = name
    },
    setLocked(value: boolean) {
      self.locked = value
    },
    setPageCount(value: number) {
      self.pageCount = value
    },
    setTiming(value: FormattedTiming) {
      self.timing = Timing.create(value)
    },
    setSlugTiming(value: FormattedSlugTiming[]) {
      self.slugTiming.replace(value)
    },
    setSelectionTiming(value: FormattedTiming) {
      self.selectionTiming = Timing.create(value)
    },
    setFolderId(value: string) {
      self.folderId = value
    },
    toggleElementNumbers(type: NodeTypeKey) {
      self.rootStore.choo.toggleElementNumbers(type)
    },
    updateNavLinks(navLinks: NavLinkData[]) {
      // keep track of the ids we find so we can remove nav links that are no longer valid
      const foundIds: Record<string, boolean> = {}
      navLinks.forEach((data) => {
        foundIds[data.id] = true
        self.navLinkMap.put({ ...data })
      })

      // now figure out any that need to be deleted from the map
      const idsToRemove: string[] = []
      self.sortedNavLinks.forEach(({ id }) => {
        if (!foundIds[id]) {
          idsToRemove.push(id)
        }
      })
      idsToRemove.forEach((id) => self.navLinkMap.delete(id))
      // uncomment this next line to generate fixture data from real scripts
      // console.log('loaded script snapshot', getSnapshot(self))
    },
    updateElementNumbers(elementNumbers: unknown) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const copy: any = { ...(elementNumbers as any) }

      // not sure what we're even using this prop for
      delete copy.activeTypes

      // would it be less expensive to selectively put/delete?
      self.elementNumberMap = copy
    },
    share() {
      self.rootStore.choo.shareScriptWithOrg(self.id)
    },
    updateEditorViewObservables() {
      self.pmEditor.syncEditorView()
    },
    // We call this to toggle the editability based on error states, the user
    // may have the rights to edit, but we're disconnected or sync is failing
    setPmEditability(allowEdits: boolean) {
      const { isEditable } = self
      const { editorManager } = self.pmEditor

      if (editorManager) {
        if (allowEdits && isEditable) {
          editorManager.setEditable(true)
        }
        if (!allowEdits) {
          editorManager.setEditable(false)
        }
      }
    },
  }))
  .actions((self) => ({
    async updateName(name: string) {
      await self.apiClient.renameScript({
        scriptId: self.id,
        name,
      })
      self.setName(name)
      const folder = self.rootStore.folderMap.get(self.folderId)
      folder?.load()
    },
    async createSnapshot(name = 'Snapshot') {
      await self.apiClient.createSnapshot({ scriptId: self.id, name })
    },
    async updateStatus(status: ScriptStatus) {
      await self.apiClient.updateScriptStatus({
        scriptId: self.id,
        status,
        socketId: self.rootStore.socketManager.socketId,
      })
      // Nuclear option: it would be better to rehydrate
      window.alert(
        `You've successfully changed the access level for "${self.name}" to ${status}\n\nWe need to reload the page`
      )
      window.location.reload()
    },
    moveNavLinkAboveOtherLink({
      movedLinkId,
      targetLinkId,
    }: {
      movedLinkId: string
      targetLinkId: string
    }) {
      const movedLink = self.navLinkMap.get(movedLinkId)
      const targetLink = self.navLinkMap.get(targetLinkId)
      // make sure we've got valid references. The UI should prevent this from
      // happening, but hey... belt and suspenders
      if (!(movedLink && targetLink)) {
        return
      }

      const movedLinkIndex = self.sortedNavLinks.indexOf(movedLink)
      const targetLinkIndex = self.sortedNavLinks.indexOf(targetLink)

      // if we're moving a nav link to be above a new act and there was a
      // an end of act above that, put the nav link in the previous act
      const moveAboveEndOfAct =
        targetLink.type === 'new_act' &&
        self.sortedNavLinks[targetLinkIndex - 1]?.type === 'end_of_act'
      const adjustedIndex = moveAboveEndOfAct
        ? targetLinkIndex - 1
        : targetLinkIndex

      // more belt and suspenders... UI should prevent the first two of these
      if (
        movedLinkIndex === adjustedIndex || // don't move it above itself (nonsensical)
        movedLinkIndex === adjustedIndex - 1 || // don't move it above its current successor (same place)
        movedLinkIndex === -1 || // make sure the items are still in the list (race condition?)
        adjustedIndex === -1
      ) {
        return
      }

      // set the tempPos on the moved link so the UI will update before our
      // next editor meta update resets it
      movedLink.setTempPos(self.sortedNavLinks[adjustedIndex].pos)
      self.rootStore.choo.moveNavLink(movedLinkIndex, adjustedIndex)
    },

    // slightly different logic for moving link to the end
    async moveNavLinkToEnd(movedLinkId: string) {
      const movedLink = self.navLinkMap.get(movedLinkId)
      const finalLink = self.sortedNavLinks[self.sortedNavLinks.length - 1]
      if (!(movedLink && finalLink)) {
        return
      }

      // if the final link is end_of_act, we want to move ABOVE it, so
      // hand this off to moveNavLinkAboveOtherLink
      if (finalLink.type === 'end_of_act') {
        this.moveNavLinkAboveOtherLink({
          movedLinkId: movedLink.id,
          targetLinkId: finalLink.id,
        })
        return
      }

      const movedLinkIndex = self.sortedNavLinks.indexOf(movedLink)
      const targetIndex = self.sortedNavLinks.length
      // verify we're moving to a valid place that isn't its current position
      // (probably this is all redundant)
      if (
        movedLinkIndex === -1 ||
        movedLinkIndex === targetIndex ||
        movedLinkIndex === targetIndex - 1
      ) {
        return
      }

      // update the ui by setting the tempPos HIGHER than the final item's position.
      // Thi will put it in the correct sorted order and still allow you to scroll to the end
      movedLink.setTempPos(finalLink.pos + 1)
      // now tell the choo app to edit the doc.
      self.rootStore.choo.moveNavLink(movedLinkIndex, targetIndex)
    },
    async fetchSnapshotHistory({
      from = 0,
      size = 20,
      filter = 'all',
    }: {
      from?: number
      size?: number
      filter?: 'manual' | 'all'
    }) {
      return await self.apiClient.fetchSnapshotHistory({
        scriptId: self.id,
        from,
        size,
        filter,
      })
    },
    async updateSnapshot({
      snapshotId,
      name,
    }: {
      snapshotId: string
      name: string
    }) {
      return await self.apiClient.updateSnapshot({
        scriptId: self.id,
        snapshotId,
        name,
      })
    },
    async disableSlack() {
      await self.apiClient.removeScriptFromSlack({ scriptId: self.id })
      self.setSlackWebhook(false)
    },
    async exportBracketsList() {
      const scriptId = self.id
      await self.rootStore.doDebug()
      const { fileName, text } = await self.apiClient.exportBracketsList(
        scriptId
      )
      saveTextToFile({ text, fileName })
      self.trackEvent(SCRIPT_EXPORTED_RUNDOWN, { scriptId })
    },
    async exportCharacterReport() {
      const scriptId = self.id
      await self.rootStore.doDebug()
      const date = new Date()
      const timestamp = format(date, PDF_HEADER_DATEMASK)
      const { fileName, text } = await self.apiClient.exportCharacterReport({
        scriptId,
        timestamp,
      })
      saveTextToFile({ text, fileName })
      self.trackEvent(SCRIPT_EXPORTED_CHARACTER_REPORT, { scriptId })
    },
    async exportFdx() {
      const scriptId = self.id
      await self.rootStore.doDebug()
      const { fileName, text, contentType } = await self.apiClient.exportFdx(
        scriptId
      )
      saveTextToFile({ text, fileName, contentType })
      self.trackEvent(BETA_EXPORTED_FDX, { scriptId })
    },
    async exportFountain() {
      const scriptId = self.id
      await self.rootStore.doDebug()
      const { fileName, text, contentType } =
        await self.apiClient.exportFountain(scriptId)
      saveTextToFile({ text, fileName, contentType })
      self.trackEvent(SCRIPT_EXPORTED_FOUNTAIN, { scriptId })
    },
    async exportPrompter() {
      const scriptId = self.id
      await self.rootStore.doDebug()
      const { fileName, text } = await self.apiClient.exportPrompter(scriptId)
      saveTextToFile({ text, fileName })
      self.trackEvent(SCRIPT_EXPORTED_TXT, { scriptId })
    },
    async exportLineData() {
      await self.rootStore.doDebug()
      const response = await self.scrapi.scripts.getLines({
        params: { id: self.id },
      })
      if (response.status === 200) {
        const { lines } = response.body
        saveTextToFile({
          text: JSON.stringify(lines, null, 2),
          fileName: `${self.name}.lines.json`,
        })
        return true
      }
      return false
    },
  }))
