import { Plugin } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'
import cn from 'classnames'
import { INITIAL_NODE_SIZE } from '../prose-utils'

function getEmpty(state) {
  const decorations = []
  function decorateEmpty(node, pos) {
    if (node.type.isBlock && node.childCount === 0) {
      decorations.push(
        Decoration.node(pos, pos + node.nodeSize, {
          class: cn('is-empty-block', {
            'is-empty-doc': state.doc.nodeSize === INITIAL_NODE_SIZE,
          }),
        })
      )
    }
  }
  state.doc.descendants(decorateEmpty)
  return decorations
}

function getSelected(state) {
  const selection = state.selection
  // ignore selection ranges
  if (selection.from !== selection.to) {
    return
  }
  const resolved = state.doc.resolve(selection.from)
  const node = resolved.node()
  if (!node.type.isBlock) {
    return null
  }
  return Decoration.node(resolved.before(), resolved.after(), {
    class: 'is-selected-block',
  })
}

/**
 * Creates a new placeholder plugin
 * @returns {Plugin} new plugin instance
 */
export function placeholderPlugin() {
  return new Plugin({
    props: {
      decorations: (state) => {
        const decorations = getEmpty(state)
        const selected = getSelected(state)
        if (selected != null) {
          decorations.push(selected)
        }
        if (decorations.length === 0) {
          return null
        }
        return DecorationSet.create(state.doc, decorations)
      },
    },
  })
}
