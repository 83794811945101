import { EditorState } from 'prosemirror-state'
import { collab } from 'prosemirror-collab'
import { dropCursor } from 'prosemirror-dropcursor'
import { gapCursor } from 'prosemirror-gapcursor'
import { schema, constants } from '@showrunner/codex'
import { ScriptoChooApp } from '@choo-app'
import { IUser } from '@state'
import { ScriptPayload } from '@util/ScriptoApiClient/types'
import { getLiveEditorPlugins } from './plugin-configs'
import { CLASSIC_DOC_TYPE } from '@util/featureFlags'

const { OrgPermissionType, ScriptStatusType } = constants

// TODO: there's a lot of redundant info in config, we should consolidate
// and stop using choo stores when we can use mst
type EditorStateConfig = {
  appState: ScriptoChooApp['state']
  script: ScriptPayload
  collabId: string
  clientId: string
  scriptId: string
  socket: ScriptoChooApp['io']
  emit: ScriptoChooApp['emitter']['emit']
  user: IUser
}

export function createLiveEditorState(config: EditorStateConfig): EditorState {
  const { script, collabId } = config
  const isInk = script.scriptFormat.definition.scriptType === 'ink'
  const debugFlags = config.appState.mst.view.debugFlags
  const currentOrgPermissions = config.appState.mst.user.currentOrgPermissions

  const isLiveEditor = true

  const userHasWriteAccess =
    script.status !== ScriptStatusType.LIMITED ||
    currentOrgPermissions.includes(OrgPermissionType.MODIFY_LIMITED_SCRIPT)

  const hasClassicFlag =
    !!config.appState.mst.currentOrg?.betaFlags.includes(CLASSIC_DOC_TYPE)

  const ourPlugins = getLiveEditorPlugins({
    isLiveEditor,
    userHasWriteAccess,
    hasClassicFlag,
    debugFlags,
    isInk,
  }).map((fn) => fn(config))

  // Stock ProseMirror plugins are added to the end of the array
  const plugins = ourPlugins.concat([
    dropCursor(),
    gapCursor(),
    collab({
      clientID: collabId,
      version: script.version,
    }),
  ])

  const editorState = EditorState.create({
    doc: schema.nodeFromJSON(script.doc),
    plugins,
  })

  // NOTE: we used to repaginate immediately upon loading the script but this
  // can be dangerous when other users are actively typing
  return editorState
}
