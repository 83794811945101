import { useMst } from '@state'
import { observer } from 'mobx-react-lite'
import { Toolbar } from '@components/Toolbar'

export const HyperlinkButton = observer(function HyperlinkButton() {
  const { currentScript } = useMst()
  if (!currentScript?.observableEditor) {
    return null
  }

  const disabled = !currentScript.observableEditor.hyperlinkingEnabled
  const tooltip = disabled ? 'Select text to insert a link' : 'Insert link'

  const handleClick = () => {
    currentScript?.observableEditor?.setEditingHyperlink(true)
  }

  return (
    <Toolbar.EditorFocusButton
      icon="fa-link"
      tooltip={tooltip}
      onClick={handleClick}
      disabled={disabled}
      dontRestoreFocus
    />
  )
})
