import { Fragment } from 'react'
import { observer } from 'mobx-react-lite'
import { Anchor } from '@mantine/core'
import { useMst } from '@state'
import { omitSharedRootFolder } from '@util'
import styles from './Breadcrumbs.module.scss'

export const DashboardBreadcrumbs = observer(function DashboardBreadcrumbs({
  folderId,
}: {
  folderId?: string
}) {
  const empty = <div className={styles.breadcrumb}></div>
  const { getFolderPath, view } = useMst()

  if (folderId === undefined) return empty

  const folderAncestors = getFolderPath(folderId)
  const folders = omitSharedRootFolder(folderAncestors)

  if (folders.length === 0) return empty

  return (
    <div className={styles.breadcrumb}>
      {folders.map((f, idx) => (
        <Fragment key={f.id}>
          <Anchor
            className={styles.breadcrumb_link}
            onClick={() => {
              view.setSelectedFolderId(f.id)
            }}
          >
            {f.displayName}
          </Anchor>
          {idx < folders.length - 1 ? (
            <div className={styles.breadcrumb_slash}>/</div>
          ) : null}
        </Fragment>
      ))}
    </div>
  )
})
