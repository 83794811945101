import { IFolder } from '@state/types'
import { email } from '@sideway/address'
import { format } from 'date-fns'

export * from './ScriptoApiClient'
export * from './mixpanel'
export * from './serverConfig'
export * from './getAvatarUrl'
export * from './prosemirrorHelpers'
export * from './scripts'
export * as styleHelpers from './styleHelpers'
export * from './fileDownload'
export * from './safeParseJSON'
export * as formUtil from './formUtil'
export * from './parseServerError'
export * from './pathConfigs'
export * from './pathUtil'
export * from './buildScrapiClient'

export const noop = () => {
  // do nothing
}

export const noopAsync = () => Promise.resolve()

export const delay = (seconds: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, seconds * 1000)
  })

export const stringMapEqual = (a: StringMap, b: StringMap): boolean => {
  if (a === b) {
    return true
  }
  const keys = Object.keys(a)
  if (keys.length !== Object.keys(b).length) {
    return false
  }
  return !keys.find((key) => a[key] !== b[key])
}

export const capitalize = (text: string) =>
  text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()

export const omitSharedRootFolder = (folders: IFolder[]): IFolder[] => {
  // the shared root is omitted from breadcrumbs
  if (
    folders.length > 0 &&
    folders[0].isRootFolder &&
    !folders[0].isPrivate &&
    !folders[0].inTrash
  ) {
    folders.splice(0, 1).pop()
  }
  return folders
}

export const isEmailValid = (value: unknown): boolean => {
  if (typeof value === 'string') {
    return email.isValid(value)
  }
  return false
}

export const pluralize = (count: number, text: string) =>
  count === 1 ? text : text + 's'

export const getPrintTimestamp = () => {
  return format(new Date(), "M/d/yyyy 'at' h:mm aa")
}
