import { RundownSchema, ZRundownSchema } from '@util/rundowns'
import { safeParseJSON } from '@util'

export const isValidName = (name: string) => /^[a-zA-Z0-9_-]{1,50}$/.test(name)
export const schemaAlreadyExists = (
  name: string,
  schemas?: { name: string }[]
) => schemas?.some((s) => s.name.toLowerCase() === name.toLowerCase()) ?? false

// https://stackoverflow.com/questions/51026420/filereader-readastext-async-issues
export const schemaFromFile = (file: File): Promise<RundownSchema> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      const schema = safeParseJSON(reader.result)
      const validationResult = ZRundownSchema.safeParse(schema)
      if (validationResult.success) {
        return resolve(validationResult.data)
      } else {
        // eslint-disable-next-line no-console
        console.error(validationResult)
        reject(new Error('Invalid schema. check console for details'))
      }
    }
    reader.onerror = () => reject(new Error('Unknown Error'))
    reader.readAsText(file as Blob)
  })
