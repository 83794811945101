import React from 'react'
import { Card, Stack } from '@mantine/core'
import { Toast } from '@components/Toast'
import styles from './OnboardingForm.module.scss'

export const OnboardingForm = ({
  children,
  onSubmit,
  errorMessage,
}: {
  errorMessage?: string | React.ReactNode
  onSubmit: React.FormEventHandler<HTMLFormElement>
  children: React.ReactNode
}) => {
  return (
    <Card className={styles.onboardingForm}>
      <form onSubmit={onSubmit}>
        <Stack>
          {errorMessage && (
            <Toast
              message={errorMessage}
              icon="fas fa-circle-exclamation"
              type="error"
              dismissable={false}
            />
          )}
          {children}
        </Stack>
      </form>
    </Card>
  )
}
