import { Group, Text } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { ILoadedScript, useMst } from '@state'
import cn from 'classnames'
import styles from './ReadOnlyBanner.module.scss'

const CTAS = {
  NO_PERMISSION_SCRIPT:
    ". You don't have permission to edit scripts when the the access level is limited.",
  NO_PERMISSION_RUNDOWN:
    ". You don't have permission to edit rundowns in this workspace.",
  DISCONNECTED:
    ". Your browser cannot connect to our server. We'll try to reconnect.",
  UNSAVED_STEPS:
    ' because we are having trouble saving your recent edits. Please copy your work before reloading the page.',
  UNFETCHED_STEPS:
    ' because we are unable to get the latest changes from the server. Please try reloading the page.',
  ARCHIVED: " because it's from the Classic Scripto archive.",
}

const BannerBase = ({
  level,
  docType,
  statusText,
  callToAction,
}: {
  level: 'info' | 'warn'
  docType: 'script' | 'rundown'
  statusText: string
  callToAction: string
}) => {
  const emoji = level === 'info' ? '👀' : '🚨'

  return (
    <Group
      className={cn(styles.banner, { [styles.warn]: level === 'warn' })}
      justify="center"
    >
      <Text>
        <Text span>
          {emoji} This {docType} is
        </Text>
        <Text span fw="bold">
          &nbsp;{statusText}
        </Text>
        <Text span>{callToAction}</Text>
      </Text>
    </Group>
  )
}

export const ReadonlyScriptPermissionsBanner = () => (
  <BannerBase
    docType="script"
    level="info"
    statusText="read only"
    callToAction={CTAS.NO_PERMISSION_SCRIPT}
  />
)

export const ReadonlyRundownBanner = () => (
  <BannerBase
    docType="rundown"
    level="info"
    statusText="read only"
    callToAction={CTAS.NO_PERMISSION_RUNDOWN}
  />
)

export const ArchivedScriptBanner = () => (
  <BannerBase
    docType="script"
    level="info"
    callToAction={CTAS.ARCHIVED}
    statusText="read only"
  />
)

export const DisconnectedBanner = () => (
  <BannerBase
    docType="script"
    level="warn"
    callToAction={CTAS.DISCONNECTED}
    statusText="offline"
  />
)

export const PushStepsBanner = () => (
  <BannerBase
    docType="script"
    level="warn"
    callToAction={CTAS.UNSAVED_STEPS}
    statusText="read only"
  />
)

export const PullStepsBanner = () => (
  <BannerBase
    docType="script"
    level="warn"
    callToAction={CTAS.UNFETCHED_STEPS}
    statusText="out of sync"
  />
)

export const ReadonlyScriptBanner = observer(function ReadonlyScriptBanner({
  script,
}: {
  script: ILoadedScript
}) {
  const { socketManager } = useMst()
  const { isEditable, syncStatus } = script
  const { isTooStaleForSafety, sendStepsRequired } = syncStatus

  if (!socketManager.connected) {
    return <DisconnectedBanner />
  }

  if (isTooStaleForSafety) {
    return sendStepsRequired ? <PushStepsBanner /> : <PullStepsBanner />
  }

  if (!isEditable) {
    return <ReadonlyScriptPermissionsBanner />
  }

  return null
})
