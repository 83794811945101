import React from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '@state'
import { ILoadedScript } from '@state/types'
import {
  SnapshotHistory,
  SnapshotFilter,
  SnapshotHistoryProps,
} from './SnapshotHistory'
import { RefreshButton, FilterMenu } from './SnapshotHistoryHeaderButtons'

import { ExplorerView } from '@components/ExplorerView'

export const SnapshotHistoryExplorer = observer(
  function SnapshotHistoryExplorer({ script }: { script?: ILoadedScript }) {
    const { user } = useMst()
    const [toggleRefresh, setToggleRefresh] = React.useState(false)
    const [enableRefresh, setEnableRefresh] = React.useState(true)

    const handleUpdateFilter = (snapshotFilter: SnapshotFilter) => {
      user.updatePreferences({ snapshotFilter })
    }
    const filter = user.prefs.snapshotFilter ?? 'all'

    if (!script) {
      return null
    }
    const handleRetrieveSnapshots: SnapshotHistoryProps['onRetrieve'] = ({
      from,
      size,
      filter,
    }) => {
      setEnableRefresh(from === 0)
      return script.fetchSnapshotHistory({ from, size, filter })
    }

    return (
      <ExplorerView>
        <ExplorerView.Header
          title="Snapshot History"
          buttons={
            <>
              {enableRefresh && (
                <RefreshButton
                  onClick={() => setToggleRefresh(!toggleRefresh)}
                />
              )}
              <FilterMenu filter={filter} onUpdateFilter={handleUpdateFilter} />
            </>
          }
        />
        <ExplorerView.Content sectionId="snapshot-history">
          <SnapshotHistory
            filter={filter}
            toggleRefresh={toggleRefresh}
            onUpdate={script.updateSnapshot}
            onRetrieve={handleRetrieveSnapshots}
          />
        </ExplorerView.Content>
      </ExplorerView>
    )
  }
)
