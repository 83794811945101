import Nanocomponent from 'nanocomponent'
import html from 'nanohtml'
import { Plugin, PluginKey } from 'prosemirror-state'
import { getDOMNodeAtPos, safelyGetOffset } from '../prose-utils.js'
import { getBlockType, isSingleBlockTextSelection } from '@util'
import { remoteCursorKey } from './remote-cursor/index.js'
import { allDisplayData } from '@components/EditorToolbar/ElementMenu/display-data'

const ICON_OFFSET = -9

/**
 * Get just the right offset for the menu element
 * @param {Node} node - DOM node
 * @return {number} top offset relative to viewport
 */
function getOffset(node) {
  // BEGIN MATH
  const offset =
    safelyGetOffset({
      node,
      caller: 'element-menu',
      includeParent: true,
      includeGrandparent: false,
    }) + ICON_OFFSET

  // ensure toolbar is not offset out of view
  return offset < 0 ? 0 : offset
  // END MATH
}
class ElementMenu extends Nanocomponent {
  constructor(editorView, appState) {
    super()
    this.editorView = editorView
    this.appState = appState
  }
  createElement() {
    // if selection spans blocks, short-circuit
    if (!isSingleBlockTextSelection(this.editorView.state)) {
      return html`<div id="editor-element-menu"></div>`
    }
    const blockOpening = this.editorView.state.selection.$from.start()
    const node = getDOMNodeAtPos(this.editorView, blockOpening)
    const currentType = getBlockType(this.editorView.state)
    const { icon } = allDisplayData[currentType] || {}
    const style = `top: ${getOffset(node)}px;`
    const onButtonClick = (event) => {
      if (event) {
        event.preventDefault()
      }
      // punt to the react element menu
      this.appState.mst.currentScript.setElementMenuOpened(true)
    }

    if (!this.editorView.props.editable()) {
      return html`<div id="editor-element-menu"></div>`
    }

    return html`
      <div
        id="editor-element-menu"
        data-editor-preserve-focus="true"
        tabindex="0"
      >
        <button
          class="o-elementicon o-hovertooltip"
          data-tooltip="Tab or Click to change element"
          onclick=${onButtonClick}
          style=${style}
        >
          <div class="o-elementicon__wrap o-icon">
            <i class=${icon}></i>
          </div>
        </button>
      </div>
    `
  }
  update(view) {
    this.editorView = view
    // don't update if it's a remote cursor change
    // TODO: this seems like a really janky way to figure this out -- we should
    // have a better way of detecting what's going on to avoid over-rendering
    const cursorState = remoteCursorKey.getState(view.state) || {}
    if (cursorState.updating) {
      return
    }
    // otherwise just rerender
    return this.rerender()
  }
}
/**
 * Creates a new element menu plugin
 * @param {object} config - plugin config
 * @returns {Plugin} new plugin instance
 */
export function elementMenuPlugin({ appState }) {
  let menu
  return new Plugin({
    key: new PluginKey('ELEMENT_MENU_PLUGIN'),
    view(editorView) {
      const gutterLeft = document.querySelector('.c-editor__gutterleft')
      menu = new ElementMenu(editorView, appState)
      const el = menu.render()
      gutterLeft.appendChild(el)
      function destroy() {
        gutterLeft.removeChild(el)
      }
      return {
        update: menu.update.bind(menu),
        destroy,
      }
    },
  })
}
