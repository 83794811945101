import html from 'nanohtml'
import { types } from '@showrunner/codex'
import { safelyGetOffset } from '../../prose-utils.js'
// constants (this is defined elsewhere, should be shared in single lib file)
const DOC_OFFSET = 1
// components
/**
 * container for all comment count indicators
 * @param {object} state - comments state
 * @param {object} actions - bound action handlers
 * @returns {HTMLElement} comment counts container
 */
function commentCounts(state, actions) {
  const blocksWithComments = getBlocksWithComments(state.editorView)
  // don't render counters until we have correct counts (after first request)
  if (setCommentCountData(state, actions)) {
    return html`<div id="comment-counts"></div>`
  }
  let total = 0
  const counters = blocksWithComments.map((blockData) => {
    const { el, num } = commentCount(blockData, state, actions)
    total += num
    return el
  })
  actions.setTotalComments(total)
  return html`<div id="comment-counts">${counters}</div>`
}
/**
 * Using the blockData, collect the comment ids and make a call to get the
 * counts (including reply count)
 *
 * @param {object} state - comments state
 * @param {object} actions - bound action handlers
 */
function setCommentCountData(state, actions) {
  // we only want to do this once, the socket updates will take care of new
  // counts as they occur
  if (state.commentCounts === null) {
    actions.getCommentCounts()
    return true
  }
}
/**
 * Get the total count of a block for display.
 *
 * @param {array} commentIds - ids of comment marks
 * @param {object} state - comments state
 */
function getTotalBlockCount(commentIds, state) {
  // if comment counts hasn't been initialized yet, just return the number of
  // top level comments
  if (!state.commentCounts) {
    return commentIds.length
  }
  let totalCount = 0
  commentIds.forEach((commentId) => {
    const count = state.commentCounts[commentId]
    if (count) {
      totalCount += count
    } else {
      // if the comment is not yet in the hash or has a value,
      // increment the totalCount by 1 (newly added comment)
      totalCount += 1
    }
  })
  return totalCount
}
/**
 * comment count indicator element
 * @param {object} blockData - comment and positioning data for associated block
 * @param {object} actions - bound action handlers
 * @returns {HTMLElement} comment count element
 */
function commentCount(blockData, state, actions) {
  const { top, commentIds } = blockData
  const num = getTotalBlockCount(commentIds, state)
  const el = html`
    <button
      class="c-commentcount"
      data-comment-ids=${commentIds.join(',')}
      onclick="${() => actions.toggleComments(commentIds, top)}"
      style="top: ${top}px"
    >
      <span class="c-commentcount__icon"><i class="fa fa-comment"></i></span>
      <span class="c-commentcount__label o-button__label--full">${num}</span>
    </button>
  `
  return { num, el }
}
/**
 * Get all comment IDs and positions in a document by block
 * @param {object} editorView - PM view
 * @return {array<Object>} comment IDs and position for every block with comments
 */
function getBlocksWithComments(editorView) {
  const { doc } = editorView.state
  const allIds = new Set()
  const blocksWithComments = []
  // FIXME: not very efficient, slows larger docs significantly. could potentially
  // extract mark data during same forEach loops as pagination. this is more or
  // less the recommended way to do it according to discussion.
  // ref: https://discuss.prosemirror.net/t/best-method-for-collecting-all-marks-in-a-block/2883
  // iterate through document to gather comment marks
  doc.forEach((page, pageOffset) => {
    page.forEach((block, blockOffset) => {
      const blockIds = new Set()
      // gather comments for this block
      // TODO: find a better way to do this in PM
      // (looked in docs, it's not obvious if there is a better one)
      block.nodesBetween(0, block.content.size, (inline) => {
        // marks is always an array
        inline.marks.forEach((mark) => {
          if (mark.type.name === types.COMMENT && !mark.attrs.resolved) {
            const { id } = mark.attrs
            // only add if it hasn't been added already
            if (!allIds.has(id)) {
              // we use a set to collect comments, as a single comment may be broken
              // up into multiple marks if other intersecting marks are present
              blockIds.add(id)
              // add to allIds to avoid duplicate counters
              allIds.add(id)
            }
          }
        })
      })
      if (blockIds.size === 0) {
        return
      }
      // convert set to array
      const commentIds = Array.from(blockIds)
      // get the position of the current block
      const offset = pageOffset + blockOffset + DOC_OFFSET
      const blockDOM = editorView.nodeDOM(offset)
      const top = safelyGetOffset({
        node: blockDOM,
        includeParent: true,
        includeGrandparent: true,
        caller: 'comment-counts',
      })
      blocksWithComments.push({ commentIds, top })
    })
  })
  return blocksWithComments
}
export { commentCounts }
export default {
  commentCounts,
}
