import { types } from 'mobx-state-tree'
import {
  OrgOption,
  OrgOptionMap,
  OrgTier,
  OrgTierMap,
  ScriptDocType,
} from '@showrunner/codex'
import { OrgMemberPayload } from '@util/ScriptoApiClient/types'
import { IInvite, IOrgMember, ListingId } from '@state/types'
import { CLASSIC_DOC_TYPE, PAGE_LOCKING } from '@util/featureFlags'
import {
  UPGRADE_INQUIRY_SENT,
  ORG_RENAMED,
  ORG_OWNERSHIP_TRANSFER,
} from '@util/mixpanel/eventNames'
import { BaseModel } from '../BaseModel'
import { ScriptFormatSummaryModel } from '../ScriptFormats'
import { Invite, InviteStatus } from '../Invite'
import { OrgMember } from '../OrgMember'
import {
  WorkspaceMessage,
  RundownListingUpdatePayload,
  ScriptListingUpdatePayload,
  FolderListingUpdatePayload,
  WORKSPACE_EVENTS,
} from '../SocketManager/types'

export type MemberRole = 'Owner' | 'Admin' | 'Contributor'

const { PRIVATE_SCRIPTS, PROMPTER_INTEGRATION, RUNDOWNS, SCRIPT_LIMITING } =
  OrgOptionMap

export const OrgOptionModel = types.model('OrgOption', {
  code: types.enumeration<OrgOption>([...Object.values(OrgOptionMap)]),
  enabled: types.boolean,
  staffManaged: types.boolean,
})

export const Org = BaseModel.named('Org')
  .props({
    id: types.string,
    name: types.string,
    loading: true,
    betaFlags: types.array(types.string),
    members: types.array(OrgMember),
    invites: types.array(Invite),
    owner: OrgMember,
    options: types.array(OrgOptionModel),
    studioFormat: ScriptFormatSummaryModel,
    screenplayFormat: ScriptFormatSummaryModel,
    tier: types.maybe(types.enumeration<OrgTier>(Object.values(OrgTierMap))),
    isUpdating: false,
    rundownSchemaName: types.string,
    favoriteListingIds: types.frozen<Array<string | number>>([]),
  })
  .views((self) => ({
    get hasClassicStudioFormat(): boolean {
      return self.betaFlags.includes(CLASSIC_DOC_TYPE)
    },
    get studioDocType(): ScriptDocType {
      return this.hasClassicStudioFormat ? 'classic' : 'variety'
    },
    get hasPageLocking(): boolean {
      return self.betaFlags.includes(PAGE_LOCKING)
    },
    get hasRundownsEnabled(): boolean {
      return !!self.options.find(({ code }) => code === RUNDOWNS)?.enabled
    },
    get alphabetizedMembers(): IOrgMember[] {
      return self.members.slice().sort((a, b) => a.name.localeCompare(b.name))
    },
    get openInvites(): IInvite[] {
      return self.invites
        .filter((i) => i.status === 'open')
        .sort((a, b) => a.email.localeCompare(b.email))
    },
    get hasPrivateScriptsEnabled(): boolean {
      return !!self.options.find(({ code }) => code === PRIVATE_SCRIPTS)
        ?.enabled
    },
    get hasPrompterAvailable(): boolean {
      return self.options.some((o) => o.code === PROMPTER_INTEGRATION)
    },
    get hasPrompterDisabled(): boolean {
      return (
        this.hasPrompterAvailable &&
        !self.options.find(({ code }) => code === PROMPTER_INTEGRATION)?.enabled
      )
    },
    get hasLimitedScriptsEnabled(): boolean {
      return !!self.options.find(({ code }) => code === SCRIPT_LIMITING)
        ?.enabled
    },
    get isUnpaid(): boolean {
      return self.tier === OrgTierMap.FREE
    },
    isFavorite(listingId: ListingId) {
      return self.favoriteListingIds.includes(listingId)
    },
  }))
  .actions((self) => ({
    setName(newName: string) {
      self.name = newName
    },
    setOwner(newOwner: OrgMemberPayload) {
      self.owner = OrgMember.create(newOwner)
    },
    setIsUpdating(value: boolean) {
      self.isUpdating = value
    },
    setMembers(members: OrgMemberPayload[]) {
      self.members.replace(members.map((m) => OrgMember.create(m)))
    },
    setInvites(invites: { id: string; email: string; status: InviteStatus }[]) {
      self.invites.replace(invites.map((i) => Invite.create(i)))
    },
    docTypeForFormat(formatId: string): ScriptDocType {
      if (formatId === self.screenplayFormat.id) {
        return 'screenplay'
      }
      if (self.hasClassicStudioFormat) {
        return 'classic'
      }
      return 'variety'
    },
    processRundownListingUpdate(data: RundownListingUpdatePayload) {
      const { rundownMap, currentRundown, refreshFolder } = self.rootStore
      const listing = rundownMap.get(String(data.rundownId))
      const rundown =
        currentRundown?.id === data.rundownId ? currentRundown : undefined

      rundown?.processSocketUpdate(data)
      // if we have the listing update it, if not it might be new in a
      // a folder we have, let the root store refresh if so
      if (listing) {
        listing.processSocketUpdate(data)
      } else {
        refreshFolder(data.folderId)
      }
    },
    processScriptListingUpdate(data: ScriptListingUpdatePayload) {
      const { scriptMap, currentScript, refreshFolder } = self.rootStore
      const listing = scriptMap.get(data.scriptId)
      const script =
        data.scriptId === currentScript?.id ? currentScript : undefined

      script?.processSocketUpdate(data)
      listing?.processSocketUpdate(data)
      if (data.status === 'OPEN' || data.status === 'LIMITED') {
        script?.setSharedStatus(data.status)
        listing?.setSharedStatus(data.status)
      }

      if (!listing) {
        refreshFolder(data.folderId)
      }
    },
    processFolderListingUpdate(data: FolderListingUpdatePayload) {
      // if we have the folder and the new parent we can just update it.
      // If we have the new parent but not the folder, we can refresh the
      // parent- this was a new folder creation.
      // if we're missing the new parent, refresh the tree
      const folder = self.rootStore.folderMap.get(data.folderId)
      const parentFolder = self.rootStore.folderMap.get(data.parentId)
      if (folder && parentFolder) {
        folder.updateFields(data)
      } else if (parentFolder) {
        parentFolder.refresh()
      } else {
        self.rootStore.loadFolderTree(self.id)
      }
    },
    processSocketMessage([name, data]: WorkspaceMessage) {
      switch (name) {
        case WORKSPACE_EVENTS.RUNDOWN_LISTING_UPDATED:
          this.processRundownListingUpdate(data)
          break
        case WORKSPACE_EVENTS.SCRIPT_LISTING_UPDATED:
          this.processScriptListingUpdate(data)
          break
        case WORKSPACE_EVENTS.FOLDER_LISTING_UPDATED:
          this.processFolderListingUpdate(data)
          break
        default:
          self.log.error('Unrecognized workspace event', { name, data })
          break
      }
    },
    updateFavorites(favorites: Array<ListingId>) {
      self.favoriteListingIds = favorites
      self.environment.localPersistence.setFavoriteListings({
        userId: self.rootStore.user.id,
        orgId: self.id,
        favorites,
      })
    },
    addFavorite(listingId: ListingId) {
      const newFavorites = [listingId, ...self.favoriteListingIds].slice(0, 100)
      this.updateFavorites(newFavorites)
    },
    removeFavorite(listingId: string | number) {
      this.updateFavorites(
        self.favoriteListingIds.filter((id) => id !== listingId)
      )
    },
    refreshFavorites() {
      self.favoriteListingIds =
        self.environment.localPersistence.getFavoriteListings({
          userId: self.rootStore.user.id,
          orgId: self.id,
        })
    },
    getMember(userId: string): IOrgMember | undefined {
      return self.members.find((u) => u.id === userId)
    },
  }))
  // async actions
  .actions((self) => ({
    createScript({
      docType,
      folderId,
    }: {
      docType: 'screenplay' | 'studio'
      folderId: string
    }): Promise<{ id: string }> {
      // If a show has the classic feature flag, use that docType. Once we wean
      // ourselves off that docType, we can stop doing this
      const type = docType === 'studio' ? self.studioDocType : 'screenplay'
      const format =
        docType === 'studio' ? self.studioFormat.id : self.screenplayFormat.id

      return self.apiClient.createScript({
        parentId: folderId,
        type,
        name: 'Untitled',
        format,
      })
    },

    async update({ name, ownerId }: { name?: string; ownerId?: string }) {
      self.setIsUpdating(true)
      try {
        await self.rootStore.doDebug()
        const response = await self.apiClient.updateOrg({
          orgId: self.id,
          name,
          ownerId,
        })
        if (self.name !== response.name) {
          self.setName(response.name)
          self.rootStore.user.selectedMembership?.setName(response.name)
          self.trackEvent(ORG_RENAMED)
        }
        if (self.owner.id !== response.owner.id) {
          self.setOwner(response.owner)
          self.trackEvent(ORG_OWNERSHIP_TRANSFER)
        }
        self.setMembers(response.members)
      } finally {
        self.setIsUpdating(false)
      }
    },

    async upgradeInquiry() {
      await self.apiClient.orgUpgradeInquiry({ orgId: self.id })
      self.trackEvent(UPGRADE_INQUIRY_SENT)
    },

    async createInvite(email: string) {
      await self.apiClient.createOrgInvite({
        orgId: self.id,
        email,
      })
    },

    async grantMemberPermission({
      userId,
      permissionCode,
    }: {
      userId: string
      permissionCode: string
    }) {
      const response = await self.apiClient.grantMemberPermission({
        orgId: self.id,
        userId,
        permissionCode,
      })
      self.setMembers(response.members)
      return response
    },

    async revokeMemberPermission({
      userId,
      permissionCode,
    }: {
      userId: string
      permissionCode: string
    }) {
      const response = await self.apiClient.revokeMemberPermission({
        orgId: self.id,
        userId,
        permissionCode,
      })
      self.setMembers(response.members)
      return response
    },
  }))
  .actions((self) => ({
    // set a superproperty in mixpanel so that all subsequent events
    // are tracked with the correct workspace id and name
    afterAttach() {
      self.analytics.setPersistentEventProperties({
        workspaceId: self.id,
        workspaceName: self.name,
      })

      self.rootStore.socketManager.joinWorkspace(self.id)

      self.refreshFavorites()
    },
    beforeDestroy() {
      self.rootStore.socketManager.leaveWorkspace(self.id)
    },
  }))
