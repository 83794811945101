/**
 * Helper class for managing minimum preceding block information.
 * Used by page splitter.
 */
class LastBlocks {
  constructor() {
    this.blocks = []
  }
  // adds block data in reverse chronological order to internal blocks array
  // e.g. index 0 is the latest addition, index 1 is the one before.
  // only keeps last two entries.
  add(block) {
    this.blocks.unshift(block)
    // ensure max length is always 2
    while (this.blocks.length > 2) {
      this.blocks.pop()
    }
  }
  prevType(index) {
    const blockData = this.blocks[index]
    if (!blockData) {
      return null
    }
    return blockData.blockNode.type.name
  }
  prevOffset(index) {
    const blockData = this.blocks[index]
    if (!blockData) {
      return null
    }
    return blockData.offset
  }
  get length() {
    return this.blocks.length
  }
}
export default LastBlocks
