import React from 'react'
import { useMst } from '@state'
import { useWindowSize, useDebounce } from 'usehooks-ts'
import { CLOSED_SIDEBAR_WIDTH, DEFAULT_SIDEBAR_WIDTH } from '@util/constants'

export function useDimensions() {
  const { view } = useMst()
  const { isExplorerClosed, explorerOpenWidth } = view.explorerState
  const { width, height } = useWindowSize()
  const sidebarWidth = isExplorerClosed
    ? CLOSED_SIDEBAR_WIDTH
    : explorerOpenWidth ?? DEFAULT_SIDEBAR_WIDTH

  return { mainPane: { width: width - sidebarWidth, height } }
}

export function useElementDimensions<T extends HTMLElement = HTMLDivElement>() {
  const [elt, setElt] = React.useState<T | null>(null)

  const [dimensions, setDimensions] = React.useState<{
    height: number
    width: number
  }>({ height: 0, width: 0 })

  const update = () => {
    if (elt) {
      const { height, width } = elt.getBoundingClientRect()
      setDimensions({ height, width })
    } else {
      setDimensions({ height: 0, width: 0 })
    }
  }

  const { user } = useMst()

  const windowSize = useWindowSize()
  // when dragging the window we don't want to rerender too much, so
  // debounce the value and update at most every 250ms
  const debouncedWindowSize = useDebounce(windowSize, 250)

  React.useEffect(update, [debouncedWindowSize, elt, user.prefs])

  return {
    ref: setElt,
    dimensions,
  }
}
