import { OrgPermissionMap, ScriptStatusMap } from '@showrunner/codex'

// actual constants
const keyToIdRE = /^\w*:/
const publicRE = /^\/(auth|login|logout|signup)/

const DEFAULT_PREFS = { bracketsInNav: false }

// utility functions
const getIdFromKey = (key) =>
  typeof key === 'string' ? key.replace(keyToIdRE, '') : ''

const SHOW_TIERS = {
  FREE: 'free',
  NEW_MEDIA: 'new-media',
  TV_FILM: 'tv-film',
}

const getPreferences = (userId, prefix, defaultPrefs) => {
  const key = `${prefix}:${userId}`
  const val = window.localStorage.getItem(key)
  const prefs = Object.assign({}, defaultPrefs)
  // return default prefs right away if nothing's been set
  if (val == null) {
    return prefs
  }
  try {
    // attempt to get stored preferences and merge with defaults
    const storedPrefs = JSON.parse(val)
    Object.assign(prefs, storedPrefs)
  } catch (err) {
    console.error('error parsing preferences in storage', err)
    // clear storage if it's invalid
    window.localStorage.removeItem(key)
  }
  return prefs
}

const canEditScript = (script, user) => {
  const isLimited = script.status === ScriptStatusMap.LIMITED
  return (
    !isLimited ||
    (isLimited &&
      user.currentOrgPermissions.includes(
        OrgPermissionMap.MODIFY_LIMITED_SCRIPT
      ))
  )
}

// look at the query string to see if it contains debug=<key of flag>
export const checkDebugFlag = (chooAppState, flagKey) => {
  const rawQueryValue = chooAppState.query?.debug
  if (rawQueryValue) {
    return Array.isArray(rawQueryValue)
      ? rawQueryValue.includes(flagKey)
      : rawQueryValue === flagKey
  }
  return false
}

export {
  canEditScript,
  getIdFromKey,
  getPreferences,
  publicRE,
  SHOW_TIERS,
  DEFAULT_PREFS,
}
