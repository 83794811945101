import { useQuery } from '@tanstack/react-query'
import { ZInfer, schemas } from '@showrunner/scrapi'
import { useMst } from '@state'

export const PAGE_SIZE = 20
const HEADER_HEIGHT = '38px'
const INPUT_HEIGHT = '44px'
const SUMMARY_HEIGHT = '26px'
const PAGINATOR_HEIGHT = '52px'

export const stackHeight = `calc(100vh - ${HEADER_HEIGHT})`
export const FILTERTAG_CLASSNAME = 'advanced-search-filter-tag'

export const calcMaxScrollerHeight = ({
  isPaginated,
  filterOffset = 0,
}: {
  isPaginated: boolean
  filterOffset?: number
}) =>
  `calc(100vh
    - ${filterOffset}px
    - ${HEADER_HEIGHT}
    - ${INPUT_HEIGHT}
    - ${SUMMARY_HEIGHT}
    - ${isPaginated ? PAGINATOR_HEIGHT : 0}
  )`

export const hasPhraseOrActiveFilter = (criteria?: SearchCriteria) => {
  return (
    !!(hasActiveFilter(criteria) || criteria?.phrase?.trim().length) ?? 0 > 0
  )
}

export const hasActiveFilter = (criteria?: SearchCriteria) =>
  criteria &&
  !!(
    criteria.docType ||
    criteria.folderId ||
    criteria.includeTitleOnly ||
    criteria.includeTrash ||
    criteria.modifiedAfter ||
    criteria.modifiedBefore
  )

export const useSearchQuery = ({
  workspaceId,
  from,
  filters,
}: {
  workspaceId: string
  from: number
  filters: SearchCriteria
}) => {
  const { doDebug, scrapi } = useMst()

  const {
    docType,
    folderId,
    includeTitleOnly,
    includeTrash,
    modifiedAfter,
    modifiedBefore,
    phrase,
  } = filters

  const queryFn = async () => {
    await doDebug()

    const apiResult = await scrapi.workspaces.searchDocuments({
      params: { id: workspaceId },
      body: {
        from,
        size: PAGE_SIZE,
        phrase: phrase === '' ? undefined : phrase,
        docType,
        folderId,
        includeTitleOnly,
        includeTrash,
        modifiedAfter,
        modifiedBefore,
      },
    })
    if (apiResult.status !== 200) {
      throw new Error('Error getting search results')
    }
    return apiResult
  }

  const queryResult = useQuery({
    queryFn,
    queryKey: [
      'searchDocuments',
      {
        phrase,
        from,
        docType,
        folderId,
        includeTitleOnly,
        includeTrash,
        modifiedAfter,
        modifiedBefore,
      },
    ],
    // staleTime only applies to the window being foregrounded
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
    // TODO: advanced search
    // it would be ideal if users could execute the same query more than
    // once manually to retrieve results indexed after the initial search
    cacheTime: 0,
    enabled: hasPhraseOrActiveFilter(filters),
  })
  return queryResult
}

export type ApiSearchResult = Awaited<ReturnType<typeof useSearchQuery>>
export type SearchCriteria = ZInfer<typeof schemas.SearchCriteria>
