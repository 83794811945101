/*
  This plugin sets up a tree of empty dom elements in each page. There is
  one slot allocated per place on the page we might want to put header or
  footer info.  (top-right, bottom-center, etc). Each slot has two rows.

  Thus, the first child of each .o-page dom element looks like this:

  <div data-margins>
    <div data-margin-slot="top-left">
      <div></div>
      <div></div>
    </div>
    <div data-margin-slot="top-center">

    ... etc


  This plugin DOES NOTHING to decide whether/what to put into each page-
  every page gets the same set of margin slots.  The margin-info plugin
  populates the page with css vars that a stylesheet can use to put
  content into these slots.
*/

import { Decoration, DecorationSet } from 'prosemirror-view'
import { Plugin, PluginKey, EditorState } from 'prosemirror-state'
import html from 'nanohtml'

export const MarginSlotsKey = new PluginKey('marginSlots')

const createSingleSlotForPage = (
  v: 'top' | 'bottom',
  h: 'left' | 'right' | 'center'
) => html`
  <div data-margin-slot="${v}-${h}">
    <div></div>
    <div></div>
  </div>
`

const createSlotDivForPage = (): HTMLElement => html`
  <div data-margins>
    ${createSingleSlotForPage('top', 'left')}
    ${createSingleSlotForPage('top', 'center')}
    ${createSingleSlotForPage('top', 'right')}
    ${createSingleSlotForPage('bottom', 'left')}
    ${createSingleSlotForPage('bottom', 'center')}
    ${createSingleSlotForPage('bottom', 'right')}
  </div>
`

// find the prosemirror positions of all the margin slots
// we want to add as decorations
const findPositions = (state: EditorState): number[] => {
  const result: number[] = []
  state.doc.content.forEach((node, offset) => {
    result.push(offset + 1)
  })

  return result
}

// given an array of offsets for where we want margin slots,
// build a decoration set with one set of slots for each
const positionsToDecorationSet = (
  positions: number[],
  editorState: EditorState
): DecorationSet => {
  const decos = positions.map((p) => Decoration.widget(p, createSlotDivForPage))
  return DecorationSet.create(editorState.doc, decos)
}

// determines if we need to regenerate the decoration set or if we can
// just reuse the last one
const positionsChanged = (
  oldPositions: number[],
  newPositions: number[]
): boolean => {
  if (oldPositions.length !== newPositions.length) {
    return true
  }
  return !!oldPositions.find((value, index) => newPositions[index] !== value)
}

// the internal state this plugin maintains
type MarginSlotState = {
  positions: number[]
  decorationSet: DecorationSet
}

export const marginSlots = (): Plugin<MarginSlotState> => {
  return new Plugin({
    key: new PluginKey('headerFooterSlots'),
    state: {
      init(config, editorState) {
        const positions = findPositions(editorState)
        const decorationSet = positionsToDecorationSet(positions, editorState)
        return {
          positions,
          decorationSet,
        }
      },
      apply(tr, pluginState, oldState, newState) {
        if (oldState.doc.eq(newState.doc)) {
          return pluginState
        }
        const newPositions = findPositions(newState)
        if (positionsChanged(pluginState.positions, newPositions)) {
          pluginState.positions = newPositions
          pluginState.decorationSet = positionsToDecorationSet(
            newPositions,
            newState
          )
        }
        return pluginState
      },
    },
    props: {
      decorations(state) {
        return this.getState(state)?.decorationSet
      },
    },
  })
}
