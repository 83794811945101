import { types } from 'mobx-state-tree'
import { reaction } from 'mobx'
import { BaseModel } from '@state/models/BaseModel'
import { createSocket } from '@state/models/SocketManager/createSocket'
import {
  ConnectionStatus,
  SOCKET_STATUS_EVENTS,
  RUNDOWN_EVENTS,
  WORKSPACE_EVENTS,
  isRundownRowMessage,
  isWorkspaceMessage,
  isEditorMessage,
} from './types'
import { authToken } from '@util/authToken'

export const SocketManager = BaseModel.named('SocketManager')
  .props({
    status: types.optional(
      types.enumeration<ConnectionStatus>([
        'connected',
        'disconnected',
        'error',
      ]),
      'disconnected'
    ),
    hasConnectedOnce: false,
  })
  .views((self) => ({
    get connected() {
      return self.status === 'connected'
    },
  }))
  .actions((self) => ({
    setStatus(status: ConnectionStatus) {
      self.status = status
      if (status === 'connected' && !self.hasConnectedOnce) {
        self.hasConnectedOnce = true
      }
    },
  }))
  .extend((self) => {
    const socket = createSocket(
      self.environment.config.API_URL,
      () => authToken.get() ?? ''
    )

    // uncomment this to debug socket directly.
    // e.g. window.socket.disconnect()
    // ;(window as any).socket = socket

    // When the socket connects and disconnects, we set the
    // observable state. Side effects are triggered via a
    // mobx autorun (below) that observes this model's status.
    socket.on(SOCKET_STATUS_EVENTS.CONNECT, () => {
      self.setStatus('connected')
    })

    socket.on(SOCKET_STATUS_EVENTS.DISCONNECT, () => {
      self.setStatus('disconnected')
    })

    socket.on(SOCKET_STATUS_EVENTS.CONNECT_ERROR, (e: unknown) => {
      if (self.status !== 'error') {
        self.setStatus('error')
        if (!self.hasConnectedOnce) {
          self.log.error(
            'Socket connect error',
            {
              topic: 'sockets',
              lastStatus: self.status,
              isOnline: navigator.onLine,
              error: e,
            },
            e
          )
        }
      }
    })

    socket.onAny((ev: string, data: unknown) => {
      const message: [string, unknown] = [ev, data]
      const { currentRundown, currentOrg, choo } = self.rootStore
      if (isRundownRowMessage(message)) {
        const targetRundownId = message[1].rundownId
        if (currentRundown?.id === targetRundownId) {
          currentRundown.receiveSocketMessage(message)
        } else {
          self.log.warn('Received inappropriate rundown message', {
            topic: 'sockets',
            messageRundownId: message[1].rundownId,
            currentRundownId: currentRundown?.id ?? 'none',
          })
        }
      } else if (isWorkspaceMessage(message)) {
        if (message[1].orgId === currentOrg?.id) {
          currentOrg.processSocketMessage(message)
        } else {
          self.log.warn('Received inappropriate workspace message', {
            topic: 'sockets',
            messageRundownId: message[1].orgId,
            currentOrgId: currentOrg?.id ?? 'none',
          })
        }
      } else if (isEditorMessage(message)) {
        // Let the choo io store handle messages for the editor until
        // we refactor
        choo.relaySocketMessage(message)
      } else {
        self.log.warn('Unrecognized socket message', {
          topic: 'sockets',
          eventName: message[0],
          payload: message[1],
        })
      }
    })

    return {
      views: {
        get socketId() {
          return socket.id ?? ''
        },
        // we want to deprecate attaching this to the choo app - hence the naming
        get socketForChooAppOnly() {
          return socket
        },
      },
      actions: {
        connectOnAuthReady() {
          socket.connect()
        },
        joinRundown(rundownId: number) {
          if (socket.connected) {
            socket.emit(RUNDOWN_EVENTS.JOIN_RUNDOWN, rundownId)
          }
        },
        leaveRundown(rundownId: number) {
          if (socket.connected) {
            socket.emit(RUNDOWN_EVENTS.LEAVE_RUNDOWN, rundownId)
          }
        },
        joinWorkspace(workspaceId: string) {
          if (socket.connected) {
            socket.emit(WORKSPACE_EVENTS.JOIN_WORKSPACE, workspaceId)
          }
        },
        leaveWorkspace(workspaceId: string) {
          if (socket.connected) {
            socket.emit(WORKSPACE_EVENTS.LEAVE_WORKSPACE, workspaceId)
          }
        },
        checkLatency() {
          if (socket.connected) {
            const start = Date.now()
            // using volatile emit & callback pattern
            socket.volatile.emit('PING', () => {
              const latency = Date.now() - start
              // eslint-disable-next-line no-console
              console.log(`ping: ${latency}ms`)
            })
          }
        },
      },
    }
  })
  .actions((self) => ({
    afterAttach() {
      // whenever self.status changes we need to do things like
      // rejoin rooms and notify choo, etc.
      reaction(
        () => self.status,
        () => {
          if (self.status === 'connected') {
            self.environment.datadog.setSocketId(self.socketId)
            // when we reconnect, we need to rejoin any relevant rooms
            const { currentRundown, currentOrg } = self.rootStore
            if (currentRundown) {
              self.joinRundown(currentRundown.id)
              currentRundown.reloadData()
            }
            if (currentOrg) {
              self.joinWorkspace(currentOrg.id)
            }
            self.rootStore.choo.relaySocketConnectionChange(true)
            self.checkLatency()
          } else if (self.status === 'disconnected') {
            self.rootStore.choo.relaySocketConnectionChange(false)
          }
        }
      )
    },
  }))
