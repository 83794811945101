import React from 'react'
import { useMst } from '@state'

type ChooListener = (...args: unknown[]) => void

// add a listener for a choo event and remove when the component unmounts
export const useChooEventListener = (
  eventName: string,
  listener: ChooListener
) => {
  const { choo } = useMst()

  React.useEffect(() => {
    choo.addListener(eventName, listener)

    return () => {
      choo.removeListener(eventName, listener)
    }
  }, [choo, eventName, listener])
}
