import { Divider, Group, Stack, Text } from '@mantine/core'
import { FormattedTiming } from 'src/choo-app/lib/editor/plugins/meta/timing'
import { TimingBreakdown } from './TimingBreakdown'

import styles from './SelectionTiming.module.scss'

export const SelectionTiming = ({ timing }: { timing: FormattedTiming }) => (
  <Stack
    className={styles.selectionTiming}
    gap={5}
    pt={5}
    pb={5}
    px={10}
    mx={10}
    mt={5}
    mb={10}
  >
    <Group justify="space-between">
      <Text fw="bold">Selected Text</Text>
      <Text>{timing.total}</Text>
    </Group>
    <Divider color="blue.2" />
    <TimingBreakdown dialogue={timing.dialogue} bracket={timing.bracket} />
  </Stack>
)
