import { TextSelection } from 'prosemirror-state'
import { schema, types } from '@showrunner/codex'
import { DatadogClient } from '@util/datadog'
import { EDITOR_EVENTS } from '@state/models/SocketManager/types'
import {
  BETA_SCRIPT_OPENED,
  SCRIPT_NAV_REORDERED,
  SCRIPT_ELEMENTS_NUMBERED,
} from '@util/mixpanel/eventNames'
import { launchScriptToast, dismissToast, TOAST_ID } from '@components/Toast'

const ddLog = DatadogClient.getInstance()

function handleBeforeUnload(app, event) {
  if (!app.editorManager.view) {
    throw new Error('missing editor instance')
  }
  // check for sendable steps before unloading to prevent data loss
  const steps = app.editorManager.getSendableSteps()
  if (steps) {
    ddLog.warn('User unloading with unsent steps')
    // NOTE: socket events never fire if user confirms leaving the page,
    // as browser overrides any async calls and cancels them on close.
    // However, these _do_ work if the user decides to stay.
    event.preventDefault() // this is the browser spec standard
    event.returnValue = '' // Chrome requires `returnValue` to be set
    // Some browsers require a return value (separate from event.returnValue).
    // This message will only show up on old browsers. New browsers prevent
    // custom messages for security reasons. More details:
    // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
    return 'Data you have entered may not be saved. Are you sure you want to close this page?'
  }
  // null doesn't work as a cancel in _some_ cases, so we return nothing,
  // which resolves to undefined, which should work in all browsers.
  // FINAL NOTE: window.onbeforeunload is a PITA
}
async function wait(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function setNewScript({ data, state, emitter }) {
  state.editor.script = { ...data }
  emitter.emit('render')
}

export function setScript({ data, state, emitter }) {
  Object.assign(state.editor.script, data)
  emitter.emit('render')
}
export function setScriptMeta({ data, state, emitter }) {
  Object.assign(state.editor.scriptMeta, data)
  emitter.emit('render')
}

export async function init({ app, data, state, emitter }) {
  const script = data
  const scriptId = data.id
  const features = state.mst.currentOrg?.betaFlags

  emitter.emit('analytics:track', {
    name: BETA_SCRIPT_OPENED,
    opts: {
      scriptId,
      features,
    },
  })

  // allow delayed connection with some retry backoff
  // (must ensure socket ID is defined before starting editor)
  let delay = 1000
  while (app.io.id == null) {
    if (delay > 10000) {
      return launchScriptToast({
        type: 'error',
        message:
          'Timed out while awaiting server connection. Please make sure you are connected to the internet.',
      })
    }
    await wait(delay)
    delay *= 1.25
  }
  // don't need full doc in state, and XXL JSON docs cause choo to choke
  // see https://github.com/showrunner/web/issues/575
  const { doc, ...meta } = script
  // prep the choo state but do not trigger anything
  // in mst
  emitter.emit('editor:setNewScript', meta)
  state.mst.setCurrentScript(meta)
  app.editorManager.start(script)
  // Eventually, we'll move the editor manager into the PmEditor and
  // won't need to double-couple them
  state.mst.currentScript?.pmEditor.setEditorManager(app.editorManager)
  app.unloadHandler = (event) => handleBeforeUnload(app, event)
  window.addEventListener('beforeunload', app.unloadHandler)
  emitter.emit(`io:${EDITOR_EVENTS.JOIN_SCRIPT}`, { scriptId })
}

export function reorderScenes({ state, data, emitter, app }) {
  const { oldIdx, newIdx } = data
  const { navLinks } = state.editor.scriptMeta
  const { view } = app.editorManager
  const editorState = view.state
  // end of doc minus page token (very wtf)
  const lastPos = editorState.doc.content.size - 1
  // range for the scene/slug to be shuffled
  const from = navLinks[oldIdx].pos
  // find the next SCENE/SLUG/ACT in the list of blocks in script nav
  // to create the relevant document slice. (ie: ignore brackets)
  // if we make it to the end without finding anything
  // we use the last position in the document
  let to = lastPos
  let x = oldIdx + 1
  while (x < navLinks.length) {
    if (
      [
        types.SLUG,
        types.NEW_ACT,
        types.SCENE_HEADING,
        types.END_OF_ACT,
      ].includes(navLinks[x].type)
    ) {
      to = navLinks[x].pos
      break
    }
    x++
  }
  // the new position might not exist yet
  const newTo = newIdx + 1 <= navLinks.length ? navLinks[newIdx].pos : lastPos
  const tr = editorState.tr
  // place the cursor for the paste
  tr.setSelection(TextSelection.create(tr.doc, newTo))
  // paste
  tr.replaceSelection(editorState.doc.slice(from, to))
  // cut (mapped)
  tr.deleteRange(tr.mapping.map(from), tr.mapping.map(to))
  view.dispatch(tr)
  emitter.emit('analytics:track', {
    name: SCRIPT_NAV_REORDERED,
    opts: {
      scriptId: state.mst.currentScript?.id,
    },
  })
}

// called by both the choo and react editor toolbars
export function updateUnresolvedCommentMarks({ data, app }) {
  const commentId = data
  const { view } = app.editorManager
  const { tr, doc } = view.state
  const size = doc.nodeSize - 2
  const resolvedMark = schema.marks[types.COMMENT].create({
    id: commentId,
    resolved: true,
  })
  const removed = tr.removeMark(0, size, resolvedMark)
  if (removed.steps.length) {
    removed.steps.forEach((step) => {
      const unresolvedMark = schema.marks[types.COMMENT].create({
        id: commentId,
        resolved: false,
      })
      tr.addMark(step.from, step.to, unresolvedMark)
    })
    tr.setMeta('addToHistory', false)
    view.dispatch(tr)
    view.dom.click() // force click to close dropdown
    view.focus()
    const el = document.querySelector(`#e-comment-${commentId}`)
    if (el) {
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }
}

// ☠️ assumes that only one script can be displayed at a time
export function toggleElementNumbers({ data, state, emitter, app }) {
  const { elementNumbers = {} } = state.editor.scriptMeta
  let num = 0
  const { view } = app.editorManager
  const { tr, doc } = view.state
  const { isOn } = elementNumbers[data]
  const isDialogue = data === types.DIALOGUE
  doc.forEach((pageNode, pageOffset) => {
    pageNode.forEach((blockNode, blockOffset) => {
      // special case: dialogue numbering must include dual dialogue
      if (
        (isDialogue && blockNode.type.name === types.DUAL_DIALOGUE) ||
        blockNode.type.name === data
      ) {
        // add 1 for doc offset :\
        const pos = pageOffset + blockOffset + 1
        const elementNumber = isOn ? null : ++num + '' // coerce to string
        // must inherit previous attrs or they'll be wiped >:|
        tr.setNodeMarkup(
          pos,
          null,
          Object.assign({}, blockNode.attrs, { elementNumber })
        )
      }
    })
  })
  tr.setMeta('choo', true)
  view.dispatch(tr)
  emitter.emit('analytics:track', {
    name: SCRIPT_ELEMENTS_NUMBERED,
    opts: {
      scriptId: state.mst.currentScript?.id,
      elementType: data,
      turnedOn: !isOn,
    },
  })
}

export function togglePageLock({ state, emitter, app }) {
  const { locked } = state.editor.scriptMeta
  const { view } = app.editorManager
  const { tr, doc } = view.state
  // turn on page locking
  if (locked === false) {
    doc.forEach((pageNode, pageOffset, pageIndex) => {
      const pos = pageOffset
      const attrs = Object.assign({}, pageNode.attrs, {
        locked: true,
        pageNumber: pageIndex + 1,
      })
      tr.setNodeMarkup(pos, null, attrs)
    })
  }
  // turn off page locking
  if (locked === true) {
    // exit if they change their mind
    const sure = window.confirm(
      'Unlocking pages will reset all page numbers. Are you sure you want to unlock all pages?'
    )
    if (!sure) {
      return
    }
    doc.forEach((pageNode, pageOffset) => {
      const pos = pageOffset
      const attrs = Object.assign({}, pageNode.attrs, {
        locked: false,
        pageNumber: null,
      })
      tr.setNodeMarkup(pos, null, attrs)
    })
  }
  // noop -- locked is not bool or no... pages?
  if (tr.steps.length === 0) {
    return
  }
  // prevent undo from unlocking pages
  tr.setMeta('addToHistory', false)
  // update production menu state right away
  state.editor.scriptMeta.locked = !locked
  emitter.emit('render')
  // dispatch to prose and hope for the best
  view.dispatch(tr)
}

export function // NOTE: Rehydrate didn't seem to work quite well enough so it's not
// currently being called.
// sometimes its not enough to set a new property on the script and continue
// when status changes we need to reinstantiate the editor altogether
// and make it read-only if the users permission level requires it
rehydrate({ app, emitter, data }) {
  const { script } = app.state.editor
  script.doc = app.editorManager.view.state.doc.toJSON()
  script.status = data.status
  app.editorManager.destroyEditorView()
  emitter.emit('editor:init', script)
  emitter.emit('render')
}
export function unload({ emitter, app, state }) {
  if (state.io.scriptId != null) {
    emitter.emit(`io:${EDITOR_EVENTS.LEAVE_SCRIPT}`, {
      scriptId: state.io.scriptId,
    })
  }
  // remove beforeunload handler
  window.removeEventListener('beforeunload', app.unloadHandler)
  app.unloadHandler = null
  // destroy disconnect notification if it's still around
  dismissToast({ id: TOAST_ID.SCRIPT_DISCONNECT })

  // destroy prosemirror view instance
  app.editorManager.destroyEditorView()
}
