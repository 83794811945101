import { differenceInSeconds } from 'date-fns'
import { RundownRowData } from '@util/ScriptoApiClient/types'
import { CLIPBOARD_LIFESPAN_SECONDS } from './constants'
import { isGridBlobKey, GridBlobColumnKey } from './rundownSchemaValidation'

export const stripBlobKeyPrefix = (key: GridBlobColumnKey): string =>
  key.replace(/^blobData\./, '')

export const getBlobValue = ({
  data,
  field,
}: {
  data?: RundownRowData
  field?: string
}) => {
  if (data && field && isGridBlobKey(field)) {
    return data.blobData[stripBlobKeyPrefix(field)]
  }
}

export const sumColumnValue = ({
  rowData,
  field,
}: {
  rowData: RundownRowData[]
  field?: string
}): number => {
  let total = 0
  rowData.forEach((data) => {
    const value = getBlobValue({ data, field })
    if (typeof value === 'number' && isFinite(value)) {
      total += value
    }
  })

  return total
}

export const isClipboardCurrent = (copiedAt: Date): boolean => {
  const diff = differenceInSeconds(new Date(), copiedAt)
  return diff < CLIPBOARD_LIFESPAN_SECONDS
}
