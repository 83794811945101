import { Text, Space } from '@mantine/core'

const PageSubtitle = ({ children }: { children?: React.ReactNode }) => {
  return (
    <div>
      <Text size="16" color="white" ta="center" lh={1.3}>
        {children}
      </Text>
      <Space h={20} />
    </div>
  )
}

export const ForgotPasswordSubtitle = () => (
  <PageSubtitle>
    It happens! To reset your password, enter the email address you use to log
    into Scripto.
  </PageSubtitle>
)

export const UpdatePasswordSubtitle = () => (
  <PageSubtitle>
    For security reasons, you need to change your password. Enter the email you
    use to log into Scripto.
  </PageSubtitle>
)

export const SentOtpSubtitle = ({ email }: { email: string }) => (
  <PageSubtitle>
    <span>We just sent a verification code to&nbsp;</span>
    <Text span fw="bold">
      {email}
    </Text>
    <br />
    <span>It expires in five minutes, so please check your email!</span>
  </PageSubtitle>
)

export const JoinWorkspaceSubtitle = ({ workspace }: { workspace: string }) => (
  <PageSubtitle>
    <span>You&apos;ve been invited to join&nbsp;</span>
    <Text span fw="bold">
      {workspace}
    </Text>
    <br />
    <span>
      Tell us the email address you&apos; like to use for your account
    </span>
  </PageSubtitle>
)

export const CreateWorkspaceSubtitle = () => (
  <PageSubtitle>
    <span>Tell us the email address you&apos;d like to use</span>
    <br />
    <span>for your Scripto account</span>
  </PageSubtitle>
)

// useful to preserve spacing on pages?
export const BlankSubtitle = () => <PageSubtitle />
