/*
  This component handles the wouter-route for displaying a rundown. It is only
  rendered if

    1) the user is logged in an has a currentOrg and
    2) the parsed location.pathname matches the WouterConfig for the rundown route

  Once that's true, we know we have a rundownId that we can try to retrieve

  This handles three different states for a requested rundown:

    * Spinner: while we are fetching the rundown
    * The RundownEditor: when the  rundown is loaded
    * An error page: when we failed to load the rundown

  This route-view pattern can be reused for other resource loading areas of the app that do
  not rely on choo state/routing

*/

import { RouteComponentProps } from 'wouter'
import React from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '@state'
import { useQuery } from '@tanstack/react-query'
import { parseServerError } from '@util'
import { NotFoundPage, UnknownErrorPage } from '@components/ErrorPage'
import { Loader } from '@components/Loader'
import { EditorRoute } from './EditorRoute'
import { SplitLayoutPlaceholder } from './SplitLayoutPlaceholder'
import { RundownAndScript } from '@layouts/RundownAndScript'

export const RundownRoute = observer(function RouteComponentProps({
  params,
}: RouteComponentProps<{ rundownId: string }>) {
  const root = useMst()
  const { prefersSplitLayout, exitSplitLayout } = root.user

  const { rundownId } = params
  const scriptId = root.location.getPathParam('scriptId')
  const { data, isError, isLoading, error } = useQuery({
    queryFn: async () => {
      await root.requestRundown(rundownId)
      return root.currentRundown
    },
    queryKey: ['rundown', rundownId],
    cacheTime: 0,
    staleTime: Infinity,
  })

  // When we enter the view, fetch the rundown and leave a cleanup
  // effect to clear the current rundown when we are done
  React.useEffect(() => {
    // this runs when the route changes
    return () => {
      if (String(root.currentRundown?.id) !== rundownId) {
        root.removeCurrentRundown()
      }
    }
  }, [root, rundownId])

  // When we enter the view, update split layout pref if needed
  React.useEffect(() => {
    if (scriptId && !root.user.prefersSplitLayout) {
      root.user.enterSplitLayout()
    }
  }, [root.user, scriptId])

  if (isError) {
    const parsedError = parseServerError(error)
    return parsedError.status === 404 ? <NotFoundPage /> : <UnknownErrorPage />
  }

  const maybeChild = scriptId ? (
    <EditorRoute params={{ scriptId }} />
  ) : prefersSplitLayout ? (
    <SplitLayoutPlaceholder onClose={exitSplitLayout} />
  ) : undefined

  // if we have the correct rundown loaded, we show the rundown editor
  if (data) {
    return <RundownAndScript rundown={data}>{maybeChild}</RundownAndScript>
  }

  return <Loader visible={isLoading} />
})
