import React from 'react'
import { PageLayout, IPrincePrintStrategy } from './types'
import { RundownPrintPrefs } from '../LocalPersistence'
import { RundownHeaderAndFooter } from './HeaderAndFooter'
import { buildPageCssVars } from './buildPageCssVars'
import { renderToString } from 'react-dom/server'
import * as cssStrings from '@util/printing/cssStrings'
import { getPrintTimestamp } from '@util'
import * as constants from './constants'
import { buildHtmlForPrince } from './buildHtmlForPrince'

type Params = {
  prefs: RundownPrintPrefs
  ref: React.RefObject<HTMLElement>
  title: string
  omitHeaderFooter?: boolean
}

// little DOM trick to turn a relative reference to an
// absolute one: create an anchor and assign the href then
// retrieve it
const convertHrefToAbsolute = (elt: Element) => {
  const href = elt.getAttribute('href')
  if (href) {
    const a = document.createElement('a')
    a.href = href
    elt.setAttribute('href', a.href)
  }
}

class RundownPrintStrategy implements IPrincePrintStrategy {
  constructor(private params: Params) {}

  generatePageLayout(): PageLayout {
    return {
      size: { height: '11in', width: '8.5in' },
      margins: this.margins,
      orientation: this.params.prefs.orientation ?? 'portrait',
    }
  }

  generateStyles() {
    return `
      ${buildPageCssVars({
        layout: this.generatePageLayout(),
        title: this.params.title,
        timestamp: getPrintTimestamp(),
      })}
      ${cssStrings.headerFooterCSS}


      @media print {
        html, body {
          height: unset;
          overflow: visible !important;
        }

        html, header, footer {
          font-family: 'Lato', san-serif;
        }

        .printable-rundown-grid {
          overflow: hidden;
        }

        .printable-rundown-grid .ag-header {
          display: none;
        }
      }

      @media screen {
        body {
          overflow: auto;
        }
      }
    `
  }

  generateBody(): string {
    return this.params.ref.current?.outerHTML ?? ''
  }

  generateHeaderAndFooter() {
    if (this.params.omitHeaderFooter) {
      return ''
    }

    const gridHeader = this.params.ref.current?.querySelector('.ag-header')

    return renderToString(
      <RundownHeaderAndFooter
        gridHeaderHtml={gridHeader?.outerHTML ?? ''}
        prefs={this.params.prefs}
      />
    )
  }
  generateHeadElements(): string {
    const head = document.head.cloneNode(true) as HTMLElement
    Array.from(head.children).forEach((elt) => {
      if (elt.nodeName === 'LINK') {
        convertHrefToAbsolute(elt)
      } else if (elt.nodeName === 'SCRIPT') {
        head.removeChild(elt)
      }
    })
    return head.outerHTML
  }

  margins = constants.RUNDOWN_MARGIN_SIZE
}

export const buildPrintableRundownHtml = (params: Params): string => {
  const strategy = new RundownPrintStrategy(params)
  return buildHtmlForPrince(strategy)
}
