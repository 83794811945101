import React from 'react'
import { useElementDimensions } from '@hooks'
import styles from './PrintPreviewWrapper.module.scss'

export const PrintPreviewWrapper = ({
  children,
}: {
  children: React.ReactNode
}) => {
  // Because we're scaling the editor within the scroller, the normal
  // centering techniques don't work properly. So, we're explicitly measuring them
  // and if there's extra horizontal space, we set a left margin to half of that.
  const scrollerDimensions = useElementDimensions()
  const editorDimensions = useElementDimensions()
  const freeHorizontalSpace =
    scrollerDimensions.dimensions.width - editorDimensions.dimensions.width

  const marginLeft = freeHorizontalSpace > 0 ? freeHorizontalSpace / 2 : 0

  return (
    <div
      className={styles.scriptScrollWrapper}
      data-editor-scroller
      ref={scrollerDimensions.ref}
    >
      <div
        className={styles.scriptCenteringBox}
        style={{ marginLeft }}
        ref={editorDimensions.ref}
      >
        {children}
      </div>
    </div>
  )
}
