import { Plugin, PluginKey } from 'prosemirror-state'
import { pageBreakerKey } from './page-breaker/index.js'
import { types } from '@showrunner/codex'
const ensureNewlineKey = new PluginKey('ENSURE_NEWLINE')
/**
 * Creates a new ensure newline plugin
 * @returns {Plugin} new plugin instance
 */
function ensureNewlinePlugin({ script }) {
  const defaultNodeType =
    script.type === types.SCREENPLAY ? types.ACTION : types.DIALOGUE
  return new Plugin({
    key: ensureNewlineKey,
    /**
     * Add an empty line to the end of the document if it's not there.
     * ref: http://prosemirror.net/docs/ref/#state.PluginSpec.appendTransaction
     * @param {array} transactions - array of transactions
     * @param {EditorState} oldState - old editor state
     * @param {EditorState} newState - new editor state
     * @returns {Transaction|null} pagination transaction or null
     */
    appendTransaction(transactions, oldState, newState) {
      const last = transactions[transactions.length - 1]
      // exit early under the following conditions:
      // last transaction is from this plugin
      // last transaction is from page breaker plugin
      // last transaction is from collab plugin
      // doc is unchanged
      if (
        last.getMeta(ensureNewlineKey) ||
        last.getMeta(pageBreakerKey) ||
        last.getMeta('collab$') ||
        oldState.doc.eq(newState.doc)
      ) {
        return
      }
      // return if last element of doc is empty
      // textContent should account for dual dialogue too
      const lastIsEmpty = newState.doc.lastChild.lastChild.textContent === ''
      // all's well
      if (lastIsEmpty) {
        return
      }
      // if not, create new default element after last line
      const finalBlockPos = newState.doc.nodeSize - 3 // magic number
      return newState.tr.insert(
        finalBlockPos,
        newState.schema.node(defaultNodeType)
      )
    },
  })
}
export { ensureNewlinePlugin, ensureNewlineKey }
