import React from 'react'
import { OrgOption, OrgOptionMap } from '@showrunner/codex'
import { useMst } from '@state'
import { showAlert } from '@components/Modals'
import { OrgPayload } from '@util/ScriptoApiClient/types'
import { useForm } from 'react-hook-form'

type Inputs = Partial<Record<OrgOption, boolean>>

const hasOption = (org: OrgPayload, option: OrgOption): boolean => {
  return !!org.options.find(({ code }) => code === option)?.enabled
}

const { PROMPTER_INTEGRATION, SCRIPT_LIMITING, RUNDOWNS, PRIVATE_SCRIPTS } =
  OrgOptionMap

export function PlanOptionsForm({ org }: { org: OrgPayload }) {
  const { apiClient } = useMst()

  const currentValues: Inputs = {
    PROMPTER_INTEGRATION: hasOption(org, PROMPTER_INTEGRATION),
    SCRIPT_LIMITING: hasOption(org, SCRIPT_LIMITING),
    RUNDOWNS: hasOption(org, RUNDOWNS),
    PRIVATE_SCRIPTS: hasOption(org, PRIVATE_SCRIPTS),
  }

  const { register, watch, resetField } = useForm({
    defaultValues: {
      ...currentValues,
    },
  })

  const changeOption = async (planOption: OrgOption, enabled: boolean) => {
    try {
      await apiClient.togglePlanOptionByStaff({
        orgId: org.id,
        planOption,
        enabled,
      })
      resetField(planOption, { defaultValue: enabled })
    } catch (e) {
      showAlert({
        title: 'Error',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        children: (e as any).message,
      })
    }
  }

  React.useEffect(() => {
    const subscription = watch((data, { name, type }) => {
      if (name && type === 'change') {
        const enable = !!data[name]
        let confirmed = true
        // only ask for confirmation when disabling
        if (!enable) {
          const message = `Are you sure you want to disable ${name} for this workspace? ${type}`
          confirmed = window.confirm(message)
        }
        if (confirmed) {
          changeOption(name, enable)
        } else {
          resetField(name)
        }
      }
    })
    return () => subscription.unsubscribe()
  })

  return (
    <form className="s-form s-form--inline">
      <div className="option-setting">
        <input
          id="PROMPTER_INTEGRATION"
          type="checkbox"
          {...register(PROMPTER_INTEGRATION)}
        />
        <label htmlFor="PROMPTER_INTEGRATION">Prompter Integration</label>
      </div>
      <div className="option-setting">
        <input
          id="PRIVATE_SCRIPTS"
          type="checkbox"
          {...register(PRIVATE_SCRIPTS)}
        />
        <label htmlFor="PRIVATE_SCRIPTS">Private Scripts</label>
      </div>
      <div className="option-setting">
        <input
          id="SCRIPT_LIMITING"
          type="checkbox"
          {...register(SCRIPT_LIMITING)}
        />
        <label htmlFor="SCRIPT_LIMITING">Limited-Access Scripts</label>
      </div>
      <div className="option-setting">
        <input id="RUNDOWNS" type="checkbox" {...register(RUNDOWNS)} />
        <label htmlFor="RUNDOWNS">Rundowns</label>
      </div>
    </form>
  )
}
