import { EditorState, TextSelection } from 'prosemirror-state'
import {
  NodeTypeKey,
  NodeTypeMap,
  schema,
  ScriptFormatMap,
  ScriptDocType,
} from '@showrunner/codex'
import { getBlockType } from '@util'
import { lockedPagesInSelection } from '../../prose-utils.js'
import { insertBlock, insertNewAct } from './commands'
import mapping from './mapping.js'
import { ResolvedPos } from 'prosemirror-model'

const { SCREENPLAY } = ScriptFormatMap
const { ACTION, BRACKET, DIALOGUE, NEW_ACT, PARENTHETICAL } = NodeTypeMap
const CLOSING_CHAR = /(\)|])/

export function lockedPageEnter(viewState: EditorState) {
  if (!viewState.selection.empty) {
    // prevent if there's an active selection containing locked pages
    if (lockedPagesInSelection(viewState)) {
      return true
    }
    // otherwise skip for any active selections
    return false
  }
}

/**
 * Given the current node and doc type, tells you what the next node should be
 * @param {string} blockType - yep
 * @param {string} docType - you guessed it
 * @param {boolean} isDual - that's right
 * @returns {string} next node type
 */
function nextNodeOnEnter(
  blockType: NodeTypeKey,
  docType: ScriptDocType,
  isDual: boolean
) {
  // next for dual is always dialogue
  if (isDual) {
    return DIALOGUE
  }
  const prediction = mapping.enter[blockType][docType]
  // no mapping for next node in current doc type, use fallback
  if (typeof prediction === 'undefined') {
    return docType === SCREENPLAY ? ACTION : DIALOGUE
  }
  return prediction
}

function cursorInDualDialogueBlock(viewState: EditorState) {
  if (!(viewState.selection instanceof TextSelection)) return false
  const { $cursor } = viewState.selection
  if ($cursor && $cursor.depth === 4) {
    return true
  }
  return false
}
/**
 * Handler for enter keypresses inside dual dialogue.
 * @param {object} viewState
 * @param {function} viewDispatch
 * @param {string} docType - script doc type
 * @returns {boolean}
 */
function exitDualColumn(
  viewState: EditorState,
  viewDispatch: Dispatch,
  docType: ScriptDocType
) {
  if (!(viewState.selection instanceof TextSelection)) return false
  const { $cursor } = viewState.selection

  // skip if no cursor or not empty
  if (!$cursor || $cursor.parent.content.size) {
    return false
  }
  // skip if dialogue depth is normal
  if ($cursor.depth === 2) {
    return false
  }
  const firstChild = $cursor.index(3) === 0
  const lastChild = $cursor.index(3) === $cursor.node(3).childCount - 1
  // skip if this node is first child or not last child in column
  if (firstChild || !lastChild) {
    return false
  }
  const { tr } = viewState
  if ($cursor.index(2) === 0) {
    // first column
    // we must delete the empty element of the current selection
    tr.delete($cursor.before(), $cursor.after())
    // then set selection to position of 2nd column character element
    // if all these things are true:
    // - empty element
    // - last child
    // - first column
    // then the position of the first element of the next column is
    //   cursor position
    //   end of empty element (+1)
    //   end of first column (+1)
    //   beginning of second column (+1)
    //   beginning of first element (+1)
    // so cursor position + 4???? I hate traversing in PM!!! ack
    const nextPos = tr.doc.resolve(tr.mapping.map($cursor.pos + 4))
    const sel = TextSelection.findFrom(nextPos, 1, true)
    if (sel) tr.setSelection(sel)
  } else {
    // second column
    const range = $cursor.blockRange()
    if (range) {
      tr.lift(range, 1)
    }
    const pos = tr.mapping.map($cursor.pos)
    const nextNode = docType === SCREENPLAY ? ACTION : BRACKET
    tr.setBlockType(pos, pos, schema.nodes[nextNode])
  }
  viewDispatch(tr.scrollIntoView())
  return true
}

/**
 * Check if last character of block should be skipped over
 * @param {object} $anchor
 * @param {object} viewState
 * @param {string} blockType
 * @return {boolean}
 */
function shouldSkipLastChar(
  $anchor: ResolvedPos,
  viewState: EditorState,
  blockType: NodeTypeKey
) {
  const isNextToLast = $anchor.parentOffset === $anchor.parent.content.size - 1
  const isWrapperChar =
    isNextToLast &&
    CLOSING_CHAR.test(
      viewState.tr.doc.textBetween($anchor.pos, $anchor.pos + 1)
    )
  return isWrapperChar && (blockType === PARENTHETICAL || blockType === BRACKET)
}

/**
 * get enter handler function for current doc type
 * @param {string} docType - script doc type
 * @returns {function}
 */
export function getEnterHandler(docType: ScriptDocType) {
  /**
   * Handler for enter keypresses. Determines next node type.
   * @param {object} viewState
   * @param {function} viewDispatch
   * @returns {boolean}
   */
  return function handleEnter(viewState: EditorState, viewDispatch: Dispatch) {
    const isDual = cursorInDualDialogueBlock(viewState)
    if (isDual) {
      const exitDual = exitDualColumn(viewState, viewDispatch, docType)
      if (exitDual) {
        return exitDual
      }
    }
    const blockType = getBlockType(viewState) as NodeTypeKey
    const nextType = nextNodeOnEnter(blockType, docType, isDual)
    const { $anchor } = viewState.selection
    // need to insert page break if next is new act
    const command = nextType === NEW_ACT ? insertNewAct : insertBlock(nextType)
    // make sure closing paren or bracket doesn't come along for the ride
    if (shouldSkipLastChar($anchor, viewState, blockType)) {
      const tr = viewState.tr.setSelection(
        TextSelection.create(viewState.tr.doc, $anchor.pos + 1)
      )
      const newState = viewState.apply(tr)
      return command(newState, viewDispatch)
    }
    return command(viewState, viewDispatch)
  }
}
