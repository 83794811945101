import createStore from 'choo-store'
import * as script from './script.js'
import * as tracking from './tracking.js'
import { setCutComments } from './comments.js'

export default createStore({
  storeName: 'editor',
  initialState: {
    syncStatus: {
      lastSyncTime: 0,
      hasUnsavedWork: false,
    },
    script: {},
    scriptMeta: {},
    snapshot: {},
    timingDropdown: { visible: false },
    cutComments: [],
  },
  events: {
    ...script,
    ...tracking,
    setCutComments,
  },
})
