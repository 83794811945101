import React from 'react'
import { useNavigation } from '@hooks'
import { useMst } from '@state'
import { jiggle } from '@util/animation'
import { scrollToBlock } from '@util/scrolling'

export const useRundownLinks = ({ scriptId }: { scriptId: string }) => {
  const [showLinkError, setShowLinkError] = React.useState(false)
  const { location, user } = useMst()
  const { navigateToScript } = useNavigation()

  const isCurrentScript = scriptId === location.getPathParam('scriptId')

  const goToScript = (linkElement: HTMLElement | null) => {
    if (isCurrentScript && user.prefersSplitLayout) {
      jiggle(linkElement)
    } else {
      navigateToScript(scriptId)
    }
  }

  const goToBlock = (blockId: string) => {
    if (isCurrentScript && user.prefersSplitLayout) {
      const { blockFound } = scrollToBlock(blockId)
      setShowLinkError(!blockFound)
    } else {
      navigateToScript(scriptId, { block: blockId })
    }
  }

  return {
    showLinkError,
    dismissLinkError: () => setShowLinkError(false),
    goToScript,
    goToBlock,
  }
}
