import html from 'nanohtml'
import Nanocomponent from 'nanocomponent'
import { commentAvatar } from './comment-avatar.js'
class CommentFormNew extends Nanocomponent {
  createElement(state, actions) {
    const submit = (event) => actions.addComment(event)
    const input = html`
      <label class="c-comment__wrapper" data-value="">
        <textarea
          id="editable-new-comment-textarea"
          rows="1"
          placeholder="Add a comment"
          class="c-comment__content"
          oninput=${oninput}
          onkeydown=${(e) => onkeydown(e, actions, submit)}
        ></textarea>
      </label>
      <input
        type="submit"
        value="Send"
        class="c-comment__submit o-button o-button--primary is-small"
        onclick=${submit}
      />
    `
    return html`
      <div>
        <button
          style="top: 8px;"
          onclick=${() => actions.cancelNewCommentEdit()}
          class="c-comment__close o-button o-button--icon o-button--inverted"
        >
          <i class="fa f fa-close"></i>
        </button>
        <div class="c-commentthread">
          <form id="new-comment-form" class="c-comment c-comment--new">
            <div class="c-comment__byline">${commentAvatar(state.user)}</div>
            ${input}
          </form>
        </div>
      </div>
    `
  }
  update() {
    // don't allow renders to reset input data
    return false
  }
  load() {
    this.element.querySelector('#editable-new-comment-textarea').focus()
  }
}
function oninput(event) {
  // copy the text into an invisible wrapper div for autogrow magic.
  event.target.parentNode.dataset.value = event.target.value
}
function onkeydown(event, actions, submit) {
  // ESC hides the form
  if (event.keyCode === 27) {
    actions.cancelNewCommentEdit()
  }
  // Enter submits the form
  if (event.keyCode === 13 && !event.shiftKey) {
    event.preventDefault()
    submit(event)
  }
}
export { CommentFormNew }
export default {
  CommentFormNew,
}
