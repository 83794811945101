import cn from 'classnames'
import styles from './Spinner.module.scss'

export type SpinnerProps = {
  delayMs?: number
  text?: string
  noOverlay?: boolean
  fullScreen?: boolean
}

export function Spinner({
  text = '',
  noOverlay,
  fullScreen,
  delayMs = 0,
}: SpinnerProps) {
  const overlayClasses = cn(styles.wrapper, {
    [styles.overlay]: !noOverlay,
    [styles.fullScreen]: fullScreen,
    [styles.fadeIn]: delayMs > 0,
  })

  return (
    <div className={overlayClasses} style={{ animationDelay: `${delayMs}ms` }}>
      <div className="l-spinnerwrapper">
        <div className="o-spinner">
          <div className="o-spinner__cube is-cube-1"></div>
          <div className="o-spinner__cube is-cube-2"></div>
          <div className="o-spinner__cube is-cube-3"></div>
          <div className="o-spinner__cube is-cube-4"></div>
          <div className="o-spinner__cube is-cube-5"></div>
          <div className="o-spinner__cube is-cube-6"></div>
          <div className="o-spinner__cube is-cube-7"></div>
          <div className="o-spinner__cube is-cube-8"></div>
          <div className="o-spinner__cube is-cube-9"></div>
        </div>
        <div className="o-heading o-heading--2">{text}</div>
      </div>
    </div>
  )
}
