import React from 'react'
import { MantineProvider, createTheme } from '@mantine/core'
import * as components from './components'
import { colors } from './colors'

// This overrides settings in the default theme which you can see here:
// https://github.com/mantinedev/mantine/blob/master/src/mantine-styles/src/theme/default-theme.ts
const theme = createTheme({
  fontFamily: '"Lato", sans-serif',
  headings: {
    fontFamily: '"Montserrat", sans-serif',
    sizes: {
      h1: { fontSize: '32px', lineHeight: '1.3', fontWeight: undefined },
      h2: { fontSize: '24px', lineHeight: '1.35', fontWeight: undefined },
      h3: { fontSize: '20px', lineHeight: '1.4', fontWeight: undefined },
      h4: { fontSize: '16px', lineHeight: '1.45', fontWeight: undefined },
      h5: { fontSize: '14px', lineHeight: '1.5', fontWeight: undefined },
      h6: { fontSize: '12px', lineHeight: '1.5', fontWeight: undefined },
    },
  },
  primaryColor: 'violet',
  primaryShade: { light: 6, dark: 2 },

  colors,
  components,
})

// The entire app gets wrapped in this theme provider, so anything we write automatically
// gets access to the settings configured above.
export const ThemeProvider = ({ children }: { children: React.ReactNode }) => (
  <MantineProvider theme={theme} forceColorScheme="light">
    {children}
  </MantineProvider>
)
