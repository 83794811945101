import React from 'react'
import cn from 'classnames'
import styles from './PagelessWrapper.module.scss'

export const PagelessWrapper = ({
  children,
  noWrap,
}: {
  children: React.ReactNode
  noWrap?: boolean
}) => {
  return (
    <div className={cn(styles.pagelessScroller)} data-editor-scroller>
      <div className={cn(styles.pagelessContent, { [styles.noWrap]: noWrap })}>
        {children}
      </div>
    </div>
  )
}
