import { types } from '@showrunner/codex'
import { getDOMNodeAtPos, safelyGetOffset } from '../../prose-utils.js'
const pmElementNumberToLabel = (elementNumber) =>
  elementNumber != null ? String(elementNumber) : ''
const labelToPmElementNumber = (label) => (label === '' ? null : label)
function collectElementNumberData(editorView, appState) {
  const map = {}
  const { activeTypes = [] } = appState.editor.scriptMeta.elementNumbers || {}
  const currentNodePos = getCurrentNodePos(editorView.state, activeTypes)
  editorView.state.doc.forEach((page, pageOffset) => {
    page.forEach((block, blockOffset) => {
      const { elementNumber } = block.attrs
      const label = pmElementNumberToLabel(elementNumber)
      const pos = pageOffset + blockOffset + 1
      const top = computeTopForElementNumber(editorView, pos)
      const selected = currentNodePos === pos
      if (
        selected ||
        elementNumber != null ||
        activeTypes.includes(block.type.name)
      ) {
        const id = block.attrs.id || 'no-id'
        map[id] = { label, top, pos, attrs: block.attrs }
      }
    })
  })
  return map
}
function getCurrentNodePos(state, activeTypes) {
  const selection = state.selection
  // ignore selection ranges
  if (selection.from !== selection.to) {
    return null
  }
  const resolved = state.doc.resolve(selection.from)
  // using depth of 2 to always check for node at the block level
  const node = resolved.node(2)
  // modified active type check to cover dual dialogue depth edge case
  const type =
    node.type.name === types.DUAL_DIALOGUE ? types.DIALOGUE : node.type.name
  if (
    !activeTypes.includes(type) ||
    typeof node.attrs.elementNumber === 'undefined'
  ) {
    return null
  }
  // using depth of 2 to return block-level node (for dual dialogue edge case)
  return resolved.before(2)
}
function computeTopForElementNumber(editorView, pos) {
  const offset = safelyGetOffset({
    node: getDOMNodeAtPos(editorView, pos + 1),
    includeParent: true,
    caller: 'element-numbers',
  })

  // (brittle) accounting for informational read-only banner
  return editorView.editable ? offset : offset + 45
}
export { collectElementNumberData }
export { labelToPmElementNumber }
export default {
  collectElementNumberData,
  labelToPmElementNumber,
}
