import {
  IFolder,
  IRoot,
  IView,
  IReadonlyFolderListState,
  CollectionId,
} from '@state/types'

export type FolderItemType = 'new-child' | 'folder'

type CollectionItem = {
  folderId: CollectionId
  itemType: 'collection'
}
type FolderListItem = {
  folderId: string
  itemType: FolderItemType
}
export type ListItem = CollectionItem | FolderListItem
export function isCollectionItem(item: ListItem): item is CollectionItem {
  return item.itemType === 'collection'
}

const COLLECTION_ITEMS: CollectionItem[] = [
  {
    folderId: 'root',
    itemType: 'collection',
  },
  {
    folderId: 'favorites',
    itemType: 'collection',
  },
  {
    folderId: 'recentlyEdited',
    itemType: 'collection',
  },
]

export const collectionItemConfig: Record<
  CollectionId,
  {
    icon: `fa-${string}`
    label: string
    indentLevel: number
    selectable: boolean
  }
> = {
  root: {
    icon: 'fa-rectangle-vertical-history',
    label: 'Collections',
    indentLevel: 0,
    selectable: false,
  },
  favorites: {
    icon: 'fa-star',
    label: 'My Favorites',
    indentLevel: 1,
    selectable: true,
  },
  recentlyEdited: {
    icon: 'fa-bolt',
    label: 'Recently Edited',
    indentLevel: 1,
    selectable: true,
  },
}

export const getKey = ({ folderId, itemType }: ListItem) =>
  `${folderId}-${itemType}`

// gets a flat list of actual folders that are expanded based on the
// view.expandedFolderList
const flattenExpandedLiveFolders = (
  parent: IFolder,
  view: IView
): IFolder[] => {
  const parentExpanded = view.expandedFolders.includes(parent)
  const childFolders = parentExpanded ? parent.childFolders : []
  return childFolders.reduce(
    (flattened, currentFolder) => {
      return flattened.concat(
        ...flattenExpandedLiveFolders(currentFolder, view)
      )
    },
    [parent]
  )
}

const flattenReadonlyFolders = (
  parent: IFolder,
  folderListState: IReadonlyFolderListState
): IFolder[] => {
  const parentExpanded = folderListState.expandedFolders.includes(parent.id)
  const childFolders = parentExpanded ? parent.childFolders : []
  return childFolders.reduce(
    (flattened, currentFolder) => {
      return flattened.concat(
        ...flattenReadonlyFolders(currentFolder, folderListState)
      )
    },
    [parent]
  )
}

/*
  The folder list consists of items that correspond to real folder instances in MST
  and to two types of "dummy" listings-- these are items in the list that represent
  "no Sub-folder" or "creating child" -- where we show a folder in the place it WOULD
  show up. (we represent these as actual list items so we can use list virtualization with
  every item in the list having the same height)
*/
const buildFolderListings = ({
  folders,
  mode,
}: {
  folders: IFolder[]
  mode: 'explorer' | 'readonly'
  isFolderExpanded: (f: IFolder) => boolean
}): ListItem[] => {
  const listItems: ListItem[] = []
  folders.forEach((folder) => {
    const folderId = folder.id
    const addNewChildItem = mode === 'explorer' && folder.isCreatingChild

    listItems.push({
      folderId,
      itemType: 'folder',
    })

    if (addNewChildItem) {
      listItems.push({
        folderId,
        itemType: 'new-child',
      })
    }
  })
  return listItems
}

export const getSortedRootFolders = (mst: IRoot): IFolder[] => {
  const { getRootFolder, currentOrg } = mst
  const includePrivate = currentOrg?.hasPrivateScriptsEnabled

  const result: IFolder[] = []
  const sharedRoot = getRootFolder(false, false)
  const sharedTrash = getRootFolder(false, true)
  const privateRoot = getRootFolder(true, false)

  if (sharedRoot) {
    result.push(sharedRoot)
  }
  if (privateRoot && includePrivate) {
    result.push(privateRoot)
  }
  if (sharedTrash) {
    result.push(sharedTrash)
  }
  return result
}

// for the live folder list in the explorer, use all the roots and
// flatten them, including the uncreated child items
export const getLiveFolderList = (mst: IRoot): ListItem[] => {
  const roots = getSortedRootFolders(mst)
  const { view } = mst
  const folders: IFolder[] = roots
    .map((root) => flattenExpandedLiveFolders(root, view))
    .flat()

  const result: ListItem[] = view.explorerState.collectionsExpanded
    ? COLLECTION_ITEMS
    : COLLECTION_ITEMS.slice(0, 1)

  return result.concat(
    buildFolderListings({
      folders,
      mode: 'explorer',
      isFolderExpanded: view.isFolderExpanded,
    })
  )
}

// for the readonly modal we only include one root and ignore the
// uncreated child nodes
export const getReadonlyFolderList = (
  mst: IRoot,
  folderListState: IReadonlyFolderListState
): ListItem[] => {
  const rootFolder = mst.folderMap.get(folderListState.rootId)
  if (!rootFolder) {
    return []
  }
  const folders = flattenReadonlyFolders(rootFolder, folderListState)
  return buildFolderListings({
    folders,
    mode: 'readonly',
    isFolderExpanded: folderListState.isExpanded,
  })
}
