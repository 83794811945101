import React from 'react'
import { Anchor, Stack, Text } from '@mantine/core'

import styles from './EmptyOutline.module.scss'

const EmptyOutlineBase = ({ children }: { children: React.ReactNode }) => (
  <Text component="div" p={'1em'} className={styles.emptyOutline}>
    {children}
  </Text>
)

export function EmptyOutline({ isScreenplay }: { isScreenplay: boolean }) {
  const scene = isScreenplay ? 'Scene Heading' : 'Slug'

  return (
    <EmptyOutlineBase>
      <>
        Once you add&thinsp;
        <Text span fw="bold">
          New Act
        </Text>
        &thinsp;and&thinsp;
        <Text span fw="bold">
          {scene}
        </Text>
        &thinsp;elements, you can see them all at a glance and click to jump to
        them in your script.
      </>
    </EmptyOutlineBase>
  )
}

export function NoFilterMatch({ filterText }: { filterText: string }) {
  const quotedFilterText = `"${filterText}"`
  return (
    <EmptyOutlineBase>
      <>
        No matches found for&nbsp;
        <Text span fw="bold">
          {quotedFilterText}
        </Text>
      </>
    </EmptyOutlineBase>
  )
}

export const NoScriptsInRundown = () => (
  <EmptyOutlineBase>
    <Stack gap={6}>
      <Text fw="bold">There are no scripts in this rundown.</Text>
      <Text span>
        You can drag scripts into your rundown, and they&apos;ll appear here.
      </Text>
      <Anchor
        target="_blank"
        href="http://help.scripto.live/en/articles/7065303-coming-soon-scripts-in-rundowns-and-the-split-editor"
      >
        Learn more
      </Anchor>
    </Stack>
  </EmptyOutlineBase>
)
