import { Text } from '@mantine/core'
import { Toolbar } from '@components/Toolbar'
import { MiniHeader, HeaderTimestamp } from '@components/MiniHeader'
import { ArchivedScriptBanner } from '@components/ReadOnlyBanner'
import { ClassicScript } from './types'
import { ArchivedScriptIcon } from './ArchivedScriptIcon'
import styles from './ArchivedScriptHeader.module.scss'

export const ArchivedScriptHeader = ({ script }: { script: ClassicScript }) => {
  const { name, contentsModifiedAt, folderName, inRundown } = script

  return (
    <>
      <Toolbar>
        <ArchivedScriptIcon goldtooth={inRundown} />
        <MiniHeader.LeftSection>
          {folderName && (
            <>
              <Text className={styles.title}>{folderName}</Text>
              <Text className={styles.separator}>/</Text>
            </>
          )}
          <Text className={styles.title}>{name}</Text>
          <HeaderTimestamp at={contentsModifiedAt} />
        </MiniHeader.LeftSection>
      </Toolbar>
      <ArchivedScriptBanner />
    </>
  )
}
