import cn from 'classnames'
import styles from './SnapshotInfo.module.scss'

export function SnapshotInfo() {
  return (
    <div className={cn(styles.snapshotinfo)}>
      <p>
        <strong>No Snapshots Yet</strong>
      </p>
      <div className={cn(styles.snapshotinfo_p)}>
        <p>
          Snapshots let you view earlier versions of a script or see what&apos;s
          changed.
        </p>
        <p>
          Click the <strong>Snapshot</strong> button in the toolbar to save a
          snapshot to this history panel.
        </p>
      </div>
    </div>
  )
}
