import React from 'react'
import {
  Anchor,
  Button,
  Radio,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core'
import { NavAnchor } from '@components/NavAnchor'
import { useMst } from '@state'
import { Spinner } from '@components/Spinner'
import { showAlert, showError } from '@components/Modals'
import { BetaFlag } from '@util/ScriptoApiClient/types'
import { useEffectOnce } from 'usehooks-ts'

export const BetaFlags = () => {
  const { apiClient } = useMst()
  const [betaFlags, setBetaFlags] = React.useState<BetaFlag[]>([])
  const [currBetaFlag, setCurrBetaFlag] = React.useState<BetaFlag | null>(null)
  const [newFlagData, setNewFlagData] = React.useState<{
    name: string
    description: string
  }>({
    name: '',
    description: '',
  })

  useEffectOnce(() => {
    async function getBetaFlags() {
      const response = await apiClient.getBetaFlags()
      setBetaFlags(response.features)
      setCurrBetaFlag(response.features[0])
    }
    getBetaFlags()
  })

  if (betaFlags.length === 0 || currBetaFlag === null) {
    return <Spinner />
  }

  const radioChangeHandler = (id: string) => {
    const flag = betaFlags.find((f) => f.id === id)
    if (flag) setCurrBetaFlag(flag)
  }

  const bulkAddFlag = () => {
    if (confirm('are you positive??')) {
      apiClient
        .bulkAddFlag({ flagId: currBetaFlag.id })
        .then(() => {
          showAlert({
            title: 'Success',
            children: 'Successfully added flag to all workspaces',
          })
        })
        .catch((e) => {
          showError({
            title: 'Error',
            message: e.message,
          })
        })
    }
  }

  const bulkRemoveFlag = () => {
    if (confirm('are you positive??')) {
      apiClient
        .bulkRemoveFlag({ flagId: currBetaFlag.id })
        .then(() => {
          showAlert({
            title: 'Success',
            children: 'Successfully removed flag from all orgs',
          })
        })
        .catch((e) => {
          showError({
            title: 'Error',
            message: e.message,
          })
        })
    }
  }

  const destroyFlag = () => {
    if (!confirm('are you positive??')) return
    apiClient
      .destroyBetaFlag({ flagId: currBetaFlag.id })
      .then(() => {
        const filteredFlags = betaFlags.filter((f) => f.id !== currBetaFlag.id)
        setBetaFlags(filteredFlags)
        setCurrBetaFlag(filteredFlags[0])
        showAlert({
          title: 'Success',
          children: 'Successfully destroyed flag',
        })
      })
      .catch((e) => {
        const message = e.message.includes('422')
          ? 'Are you sure no workspaces have the flag enabled?'
          : 'Failed to destroy beta flag'

        showError({
          title: 'Error',
          message,
        })
      })
  }

  const createFlag = async () => {
    if (!confirm(`Create new feature flag "${newFlagData.name}"?`)) {
      return
    }
    try {
      const flag = await apiClient.createBetaFlag({
        name: newFlagData.name.trim(),
        description: newFlagData.description.trim(),
      })
      setBetaFlags([...betaFlags, flag])
      setNewFlagData({ name: '', description: '' })
    } catch (e) {
      showAlert({
        title: 'Error',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        children: (e as any).message,
      })
    }
  }

  return (
    <Stack>
      <Title order={2}>
        <NavAnchor href="/staff">Staff Zone</NavAnchor>
      </Title>
      <Text size="12">
        Which flags are&nbsp;
        <Anchor href="https://github.com/showrunner/wallaby/blob/main/src/util/featureFlags.ts">
          currently in use?
        </Anchor>
      </Text>
      <Radio.Group value={currBetaFlag.id} onChange={radioChangeHandler}>
        <Stack>
          {betaFlags
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((f) => (
              <Radio key={f.id} value={f.id} label={f.name} />
            ))}
        </Stack>
      </Radio.Group>
      <Button
        color="green"
        onClick={bulkAddFlag}
      >{`Add "${currBetaFlag.name}" to all workspaces`}</Button>
      <Button
        color="purple"
        onClick={bulkRemoveFlag}
      >{`Remove "${currBetaFlag.name}" from all workspaces`}</Button>
      <NavAnchor
        href={`/staff/workspaces?flag=${currBetaFlag.name}`}
      >{`View workspaces with "${currBetaFlag.name}" enabled`}</NavAnchor>
      <Button
        color="red"
        onClick={destroyFlag}
      >{`☠️ Destroy "${currBetaFlag.name}" flag`}</Button>
      <h2>Need a new flag?</h2>
      <TextInput
        placeholder="new-flag"
        value={newFlagData.name}
        onChange={(e) =>
          setNewFlagData({ ...newFlagData, name: e.target.value })
        }
      />
      <TextInput
        placeholder="description of the new flag"
        value={newFlagData.description}
        onChange={(e) =>
          setNewFlagData({ ...newFlagData, description: e.target.value })
        }
      />
      <Button
        onClick={createFlag}
        disabled={!newFlagData.name.trim() || !newFlagData.description.trim()}
      >
        Create new flag
      </Button>
    </Stack>
  )
}
