import React from 'react'
import { Stack, Title } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import useDimensions from 'react-cool-dimensions'
import { useMst } from '@state'
import { NoShow } from '@components/NoShow'
import { OrgSwitcher } from '@components/OrgSwitcher'
import { DashboardSettings } from './Settings'
import { DashboardBreadcrumbs } from './Breadcrumbs'

import { CreateCards } from './CreateCards'

import styles from './Dashboard.module.scss'

export const Dashboard = observer(function Dashboard() {
  const { currentOrg, view, user } = useMst()
  const [breakpoint, setBreakpoint] = React.useState('')

  const { observe } = useDimensions({
    breakpoints: {
      [styles.dash_buttonrow___smallscreen]: 0,
      [styles.dash_buttonrow___smallmidscreen]: 500,
      [styles.dash_buttonrow___midscreen]: 650,
      '': 800,
    },
    onResize: ({ currentBreakpoint }) => {
      setBreakpoint(currentBreakpoint)
    },
  })

  if (!(user.selectedMembership && currentOrg)) return <NoShow />

  return (
    <div ref={observe} className={styles.dashWrapper}>
      <div className={styles.dash_breadcrumb}>
        <DashboardBreadcrumbs folderId={view.selectedFolderId} />
      </div>
      <Stack gap={40} className={styles.dash}>
        <Stack align="flex-start" gap={0}>
          <Title order={1} className={styles.dash_header}>
            Workspace
          </Title>
          <OrgSwitcher />
        </Stack>
        <CreateCards breakpoint={breakpoint} />
        <DashboardSettings isAdmin={user.selectedMembership.isAdmin} />
      </Stack>
    </div>
  )
})
