import React from 'react'
import NiceModal from '@ebay/nice-modal-react'
import {
  Button,
  Center,
  Checkbox,
  Divider,
  Group,
  Image,
  Radio,
  Select,
  Stack,
  Space,
  Tabs,
  Text,
} from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { useMst } from '@state'
import headerleft from '@assets/images/header-left.png'
import headercenter from '@assets/images/header-center.png'
import headerright from '@assets/images/header-right.png'
import footerleft from '@assets/images/footer-left.png'
import footercenter from '@assets/images/footer-center.png'
import footerright from '@assets/images/footer-right.png'
import { ModalShell, useModalControls } from '@components/Modals'
import { Example } from './Example'
import { useDimensions, useFileCreation } from '@hooks'
import { SlotContent, isSlotContent } from '@util/princePrinting'
import { ScriptPrintPreferences } from '@util/LocalPersistence'
import onecol from '@assets/images/onecol.png'
import twocol from '@assets/images/twocol.png'

import styles from './PrintPrefs.module.scss'

type PrintPrefsParams = { isScreenplay?: boolean }

// modal padding
// modal title
// tab headers + padding
// Done button + padding
// margin (for error)
const staticContentHeight = 60 + 35 + 55 + 50 + 30

const slotData: Array<{
  value: SlotContent
  label: string
}> = [
  { value: 'timestamp', label: 'Date and time' },
  { value: 'page-number', label: 'Page number' },
  { value: 'page-number-total', label: 'Page number/total' },
  { value: 'short-title', label: 'Title' },
  {
    value: 'full-title',
    label: 'Title and section info',
  },
]

const SlotSelect = ({
  label,
  content,
  setContent,
  disabled,
}: {
  disabled?: boolean
  label: string
  content?: SlotContent
  setContent: (value: SlotContent | undefined) => void
}) => {
  const setValue = (value: string | null) => {
    if (value && isSlotContent(value)) {
      setContent(value)
    } else {
      setContent(undefined)
    }
  }

  return (
    <Stack gap={8} className={styles.prefModal_stack}>
      <Text fw="bold">{label}</Text>
      <Select
        clearable
        disabled={disabled}
        value={content ?? 'leaveBlank'}
        onChange={setValue}
        data={slotData}
      />
    </Stack>
  )
}

const ScrollingContent = observer(function ({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <Stack
      style={{
        maxHeight: useDimensions().mainPane.height - staticContentHeight,
      }}
      className={styles.prefModal_scrollingContent}
    >
      {children}
    </Stack>
  )
})

const PrintPrefs = observer(function PrintPrefsParams({
  isScreenplay,
}: PrintPrefsParams) {
  const { user, currentScript } = useMst()
  const prefs = user.scriptPrintPrefs
  const updatePrefs = user.updateScriptPrintPreferences
  const controls = useModalControls()
  const creation = useFileCreation()

  const updateHeaders = (value: Partial<ScriptPrintPreferences['headers']>) => {
    updatePrefs({ headers: { ...prefs.headers, ...value } })
  }
  const updateFooters = (value: Partial<ScriptPrintPreferences['footers']>) => {
    updatePrefs({ footers: { ...prefs.footers, ...value } })
  }

  const handlePrint = async () => {
    await creation.createFile({
      script: currentScript,
      fileType: 'print',
    })
    controls.onClose()
  }

  return (
    <ModalShell
      title="Print preferences"
      size={700}
      opened={controls.opened}
      onClose={controls.onClose}
      centered={false}
      loading={creation.isCreating}
    >
      <Tabs
        classNames={{
          tab: styles.prefModal_tab,
        }}
        defaultValue="page"
      >
        <Tabs.List>
          <Tabs.Tab value="page">Page</Tabs.Tab>
          <Tabs.Tab value="header">Header</Tabs.Tab>
          <Tabs.Tab value="footer">Footer</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="page" pt="xs">
          <ScrollingContent>
            <Space h={5} />
            <Center>
              <Group
                className={styles.prefModal_group}
                justify="center"
                align="flex-start"
                gap={60}
              >
                {!isScreenplay && (
                  <Radio.Group
                    label="Layout"
                    value={prefs.columns ? 'two' : 'one'}
                    onChange={(value) =>
                      updatePrefs({
                        columns: value === 'two',
                      })
                    }
                  >
                    <Space h={10} />
                    <Stack gap="md">
                      <Group>
                        <Radio label="One column" value="one" />
                        <Image src={onecol} w={40} />
                      </Group>
                      <Group>
                        <Radio label="Two column" value="two" />
                        <Image src={twocol} w={40} />
                      </Group>
                    </Stack>
                  </Radio.Group>
                )}

                <Radio.Group
                  label="Text color"
                  value={prefs.monochrome ? 'mono' : 'color'}
                  onChange={(value) =>
                    updatePrefs({
                      monochrome: value === 'mono',
                    })
                  }
                >
                  <Space h={10} />
                  <Stack gap="md">
                    <Radio label="Black and white" value="mono" />
                    <Radio label="Color" value="color" />
                  </Stack>
                </Radio.Group>
                {!isScreenplay && (
                  <Radio.Group
                    label="Right column line spacing"
                    value={String(prefs.twoColDialogueSpacing)}
                    onChange={(value) =>
                      updatePrefs({
                        twoColDialogueSpacing: Number(value) as 1 | 2 | 1.5,
                      })
                    }
                  >
                    <Space h={10} />
                    <Stack gap="md">
                      <Radio
                        label="Single spaced"
                        value="1"
                        disabled={!prefs.columns}
                      />
                      <Radio
                        label="1.5 spaced"
                        value="1.5"
                        disabled={!prefs.columns}
                      />
                      <Radio
                        label="Double spaced"
                        value="2"
                        disabled={!prefs.columns}
                      />
                    </Stack>
                  </Radio.Group>
                )}
              </Group>
            </Center>
          </ScrollingContent>
        </Tabs.Panel>
        <Tabs.Panel value="header" pt="xs">
          <ScrollingContent>
            <Stack gap="xl">
              <Stack px={10}>
                <Space />
                <Checkbox
                  label="Include header"
                  checked={prefs.headers.enabled}
                  onChange={(evt) => {
                    updateHeaders({
                      enabled: evt.target.checked,
                    })
                  }}
                />
                <Checkbox
                  px={30}
                  label="Show header on first page"
                  disabled={!prefs.headers.enabled}
                  checked={prefs.headers.showOnFirstPage}
                  onChange={(evt) =>
                    updateHeaders({
                      showOnFirstPage: evt.target.checked,
                    })
                  }
                />
                <Checkbox
                  px={30}
                  label="Horizontal rule appears below text"
                  disabled={!prefs.headers.enabled}
                  checked={prefs.headers.divider}
                  onChange={(evt) =>
                    updateHeaders({
                      divider: evt.target.checked,
                    })
                  }
                />
              </Stack>
              <Divider />
              <Group
                align="flex-start"
                justify="space-between"
                className={styles.prefModal_group___selects}
                gap={10}
                px={10}
              >
                <Stack px={10} align="flex-start">
                  <img src={headerleft} />
                  <SlotSelect
                    disabled={!prefs.headers.enabled}
                    label="Left"
                    content={prefs.headers.left}
                    setContent={(value) => updateHeaders({ left: value })}
                  />
                </Stack>
                <Stack px={10} align="flex-start">
                  <img src={headercenter} />
                  <SlotSelect
                    disabled={!prefs.headers.enabled}
                    label="Center"
                    content={prefs.headers.center}
                    setContent={(value) => updateHeaders({ center: value })}
                  />
                </Stack>
                <Stack px={10} align="flex-start">
                  <img src={headerright} />
                  <SlotSelect
                    disabled={!prefs.headers.enabled}
                    label="Right"
                    content={prefs.headers.right}
                    setContent={(value) => updateHeaders({ right: value })}
                  />
                </Stack>
              </Group>
              <Divider />
              <Example
                left={prefs.headers.left}
                center={prefs.headers.center}
                right={prefs.headers.right}
                hr={prefs.headers.divider}
                hidden={!prefs.headers.enabled}
              ></Example>
            </Stack>
          </ScrollingContent>
        </Tabs.Panel>
        <Tabs.Panel value="footer" pt="xs">
          <ScrollingContent>
            <Stack gap="xl">
              <Stack px={10}>
                <Space />
                <Checkbox
                  label="Include footer"
                  checked={prefs.footers.enabled}
                  onChange={(evt) => {
                    updateFooters({
                      enabled: evt.target.checked,
                    })
                  }}
                />
                <Checkbox
                  px={30}
                  label="Show footer on first page"
                  disabled={!prefs.footers.enabled}
                  checked={prefs.footers.showOnFirstPage}
                  onChange={(evt) => {
                    updateFooters({
                      showOnFirstPage: evt.target.checked,
                    })
                  }}
                />
                <Checkbox
                  px={30}
                  disabled={!prefs.footers.enabled}
                  label="Horizontal rule appears above text"
                  checked={prefs.footers.divider}
                  onChange={(evt) => {
                    updateFooters({
                      divider: evt.target.checked,
                    })
                  }}
                />
              </Stack>
              <Divider />
              <Group
                className={styles.prefModal_group___selects}
                align="flex-start"
                justify="space-between"
                gap={10}
                px={10}
              >
                <Stack px={10} align="flex-start">
                  <img src={footerleft} />
                  <SlotSelect
                    disabled={!prefs.footers.enabled}
                    label="Left"
                    content={prefs.footers.left}
                    setContent={(value) => updateFooters({ left: value })}
                  />
                </Stack>
                <Stack px={10} align="flex-start">
                  <img src={footercenter} />
                  <SlotSelect
                    disabled={!prefs.footers.enabled}
                    label="Center"
                    content={prefs.footers.center}
                    setContent={(value) => updateFooters({ center: value })}
                  />
                </Stack>
                <Stack px={10} align="flex-start">
                  <img src={footerright} />
                  <SlotSelect
                    disabled={!prefs.footers.enabled}
                    label="Right"
                    content={prefs.footers.right}
                    setContent={(value) => updateFooters({ right: value })}
                  />
                </Stack>
              </Group>
              <Divider />
              <Example
                left={prefs.footers.left}
                center={prefs.footers.center}
                right={prefs.footers.right}
                hr={prefs.footers.divider}
                mode="footer"
                hidden={!prefs.footers.enabled}
              ></Example>
            </Stack>
          </ScrollingContent>
          <Space />
        </Tabs.Panel>
      </Tabs>
      <Group gap="xs" justify="center">
        <Button type="submit" onClick={handlePrint}>
          Print
        </Button>
        <Button variant="outline" onClick={controls.onClose}>
          Close
        </Button>
      </Group>
    </ModalShell>
  )
})

export const PrintPrefsModal = NiceModal.create((props: PrintPrefsParams) => (
  <PrintPrefs {...props} />
))

export const showPrintPrefsModal = (props: PrintPrefsParams) =>
  NiceModal.show(PrintPrefsModal, props)
