import { initClient, tsRestFetchApi } from '@ts-rest/core'
import { scrapi, legacyApi } from '@showrunner/scrapi'
import { handle401 } from '@util/handle401'

export const buildScrapiClient = () =>
  initClient(scrapi, {
    // since we're proxying the path prefix we don't need to supply the base url
    baseUrl: '',
    credentials: 'include',
    baseHeaders: {
      'content-type': 'application/json',
    },
    api: async (args) => {
      const result = await tsRestFetchApi(args)
      if (result.status === 401) {
        await handle401()
      }
      return result
    },
  })

export type ScrapiClient = ReturnType<typeof buildScrapiClient>

export const buildLegacyApiClient = (baseUrl: string) =>
  initClient(legacyApi, {
    baseUrl,
    baseHeaders: {
      'content-type': 'application/json',
    },
    credentials: 'include',
    api: async (args) => {
      const result = await tsRestFetchApi(args)
      if (result.status === 401) {
        await handle401()
      }
      return result
    },
  })
export type LegacyApiClient = ReturnType<typeof buildLegacyApiClient>
