import { FaIcon } from '@components/FaIcon'
import { Button } from '@mantine/core'

export const CopyableId = ({ id }: { id: string }) => (
  <div className="l-box">
    <code>{id.slice(0, 7)}</code>
    <Button
      variant="subtle"
      onClick={() => navigator.clipboard.writeText(id)}
      leftSection={<FaIcon icon="fa-copy" />}
    />
  </div>
)
