import { isMacOs, isIOS } from 'react-device-detect'

export const CMD = 'platform-specific-command'
// The values of these are recognized by the useHotkeys mantine hook
export const DEL = 'Backspace'
export const ALT = 'alt'
export const SHIFT = 'shift'
export const TAB = 'Tab'
export const ENTER = 'Enter'
export const CTRL = 'Control'
export const RIGHT = '→'
export const LEFT = '←'
export const UP = '↑'
export const DOWN = '↓'

const MODIFIER_KEYS = [CMD, ALT, SHIFT] as const
export type ModifierKey = typeof MODIFIER_KEYS[number]
export type Platform = 'mac' | 'win'

export const detectedPlatform: Platform = isIOS || isMacOs ? 'mac' : 'win'

function isModifierKey(value: string): value is ModifierKey {
  return MODIFIER_KEYS.includes(value as ModifierKey)
}

const winSortOrder: Record<ModifierKey, number> = {
  [CMD]: 1,
  [SHIFT]: 2,
  [ALT]: 3,
}

const macSortOrder: Record<ModifierKey, number> = {
  [CMD]: 3,
  [SHIFT]: 1,
  [ALT]: 2,
}

const keyRepresentation = (key: string, platform: Platform): string => {
  const isWin = platform === 'win'
  switch (key) {
    case CMD:
      return isWin ? 'Ctrl' : '⌘'
    case SHIFT:
      return isWin ? 'Shift' : '⇧'
    case ALT:
      return isWin ? 'Alt' : '⌥'
    case CTRL:
      return isWin ? 'Ctrl' : '⌃'
    case ENTER:
      return isWin ? 'Enter' : 'Return'
    case DEL:
      return isWin ? 'Backspace' : 'Delete'
    default:
      return key
  }
}

export const sortKeys = (keys: string[], platform: Platform): string[] => {
  const sortOrder = platform === 'mac' ? macSortOrder : winSortOrder
  return [...keys].sort((k1, k2) => {
    const k1sort = isModifierKey(k1) ? sortOrder[k1] : keys.indexOf(k1) + 10
    const k2sort = isModifierKey(k2) ? sortOrder[k2] : keys.indexOf(k2) + 10
    return k1sort - k2sort
  })
}

export const buildShortcutTip = (
  keys: string[],
  platform: Platform = detectedPlatform
): string => {
  const separator = platform === 'win' ? '+' : ''

  return sortKeys(keys, platform)
    .map((key) => keyRepresentation(key, platform))
    .join(separator)
}

const platformCmd = (key: string, platform: Platform): string => {
  if (key === CMD) {
    return platform === 'win' ? 'ctrl' : 'meta'
  }
  return key
}

export const buildShortcutCode = (keys: string[], platform: Platform) =>
  keys.map((k) => platformCmd(k, platform)).join('+')
