import { Button, Table, Text } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { showAlert, showError } from '@components/Modals'
import { IInvite, IOrg } from '@state/types'
import styles from './MemberTableRow.module.scss'
import { useInviteData } from '@components/Settings/useInviteData'

export const InviteTableRow = observer(function InviteTableRow({
  invite,
  workspace,
}: {
  invite: IInvite
  workspace: IOrg
}) {
  const { invalidateInviteQuery } = useInviteData(workspace)
  const revokeInvite = async () => {
    try {
      await invite.revoke()
      invalidateInviteQuery()
      showAlert({
        title: 'Success',
        children: 'Invite revoked',
      })
    } catch (e) {
      showError({ message: 'Failed to revoke invite' })
    }
  }

  return (
    <Table.Tr className="membertable_row___pending">
      <Table.Td>
        <Text fz={14} c="dimmed">
          {invite.email}
        </Text>
      </Table.Td>
      <Table.Td>
        <Text fz={14} fs="italic" c="dimmed">
          Pending Invitation
        </Text>
      </Table.Td>

      <Table.Td className={styles.membertable_action}>
        <Button
          variant="subtle"
          onClick={revokeInvite}
          loading={invite.isRevoking}
        >
          <Text fz={15}>Revoke Invite</Text>
        </Button>
      </Table.Td>
    </Table.Tr>
  )
})
