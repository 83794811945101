import React from 'react'
import { useReactToPrint } from 'react-to-print'
import { wrapForPrint } from './printTemplate'

export const usePrintableRef = ({
  bodyClass,
  fileName,
  templateWrap,
}: {
  fileName: string
  bodyClass?: string
  templateWrap: {
    headerHeight: number
    footerHeight: number
    headerWidth?: number
  }
}) => {
  const printRef = React.useRef<HTMLDivElement>(null)
  const { headerHeight, footerHeight, headerWidth } = templateWrap
  const pageStyle = `
    body {
      --file-name: '${CSS.escape(fileName)}';
    }
    table {
    }
    thead {
      display: table-header-group;
    }
    tfoot {
      display: table-footer-group;
    }
  `

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle,
    removeAfterPrint: true,
    print: async (iframeElt) => {
      if (iframeElt.contentWindow) {
        const { document } = iframeElt.contentWindow
        document.title = fileName
        document.body.innerHTML = wrapForPrint({
          html: iframeElt.contentWindow.document.body.innerHTML,
          headerHeight,
          footerHeight,
          headerWidth,
        })
        iframeElt.contentWindow.window.print()
      }
    },
    bodyClass,
  })

  return {
    printRef,
    handlePrint,
  }
}
