export const TOOLBAR_ITEMS = [
  'SCRIPT_STATUS_BUTTON',
  'PAGE_COUNT',
  'ELEMENT_MENU',
  'TIMING_POPOVER_BUTTON',
  'EXCLUDE_FROM_TIMING_BUTTON',
  'FORMATTING_BUTTONS',
  'COLLABORATORS',
  'COMMENT_MENU',
  'SNAPSHOT_BUTTON',
  'EXTRAS_MENU',
  'ZOOM_MENU',
  'INK_EXPORT_MENU',
] as const
export type ToolbarItem = typeof TOOLBAR_ITEMS[number]

export const MICRO_TOOLBAR_ITEMS = [
  'COLLABORATORS',
  'FORMATTING_BUTTONS',
] as const
export type MicroToolbarItem = typeof MICRO_TOOLBAR_ITEMS[number]

type ItemSizing = {
  normal: number
  mini: number
  micro?: number
}

export const toolbarItemSizes: Record<ToolbarItem, ItemSizing> = {
  SCRIPT_STATUS_BUTTON: {
    // LIMITED with all padding/gap and icons
    normal: 102,
    mini: 52,
  },
  PAGE_COUNT: {
    // measured with "999 Page"
    normal: 95,
    // We hide in mini mode
    mini: 0,
  },
  ELEMENT_MENU: {
    // less than Scene Heading
    normal: 120,
    // widest icon
    mini: 40,
  },
  TIMING_POPOVER_BUTTON: {
    // using 33:33:33
    normal: 115,
    // icon with caret
    mini: 50,
  },
  EXCLUDE_FROM_TIMING_BUTTON: {
    normal: 40,
    mini: 40,
  },
  FORMATTING_BUTTONS: {
    // formatting, alignment and undo-redo
    normal: 327,
    // collapse alignment only
    mini: 279,
    // icon with caret
    micro: 50,
  },
  COLLABORATORS: {
    // measured with "99"
    normal: 110,
    mini: 78,
    micro: 0,
  },
  COMMENT_MENU: {
    // "999 comments"
    normal: 160,
    // icon, 999 + caret
    mini: 82,
  },
  SNAPSHOT_BUTTON: {
    normal: 102,
    mini: 40,
  },
  EXTRAS_MENU: {
    normal: 40,
    mini: 40,
  },
  ZOOM_MENU: {
    normal: 100,
    mini: 40,
  },
  INK_EXPORT_MENU: {
    normal: 90,
    mini: 40,
  },
}

// we switch to mini mode in different phases
type Phases = 1 | 2 | 3 | 4 | 5 | 6

export const collapsePhases: Record<ToolbarItem, Phases> = {
  // normal to mini
  COMMENT_MENU: 1,
  ELEMENT_MENU: 1,
  SCRIPT_STATUS_BUTTON: 1,
  SNAPSHOT_BUTTON: 1,

  // normal to mini to micro
  FORMATTING_BUTTONS: 2,

  // normal to mini to micro (ie: hidden)
  COLLABORATORS: 3,

  // normal to mini
  ZOOM_MENU: 3,

  // normal to mini (ie: hidden)
  PAGE_COUNT: 5,

  // normal to mini
  TIMING_POPOVER_BUTTON: 5,
  INK_EXPORT_MENU: 5,

  // these last two have no effect, the mini mode
  // is the same as the regular mode
  EXTRAS_MENU: 6,
  EXCLUDE_FROM_TIMING_BUTTON: 6,
}

// to micro
export const miniCollapsePhases: Record<MicroToolbarItem, Phases> = {
  FORMATTING_BUTTONS: 4,
  //  (ie: hidden)
  COLLABORATORS: 6,
}

const measureItemsAtPhase = (
  items: ToolbarItem[],
  phase: 0 | 1 | 2 | 3 | 4 | 5
): number => {
  let total = 0
  items.forEach((item) => {
    const isNormal = collapsePhases[item] > phase
    // cryptically account for no micro mode existing
    const miniPhase = miniCollapsePhases[item as MicroToolbarItem] ?? 99
    const isMini = miniPhase > phase

    const itemWidth = isNormal
      ? toolbarItemSizes[item].normal
      : isMini
      ? toolbarItemSizes[item].mini
      : toolbarItemSizes[item].micro

    total += Number(itemWidth)
  })
  return total
}

const gatherItems = ({
  items,
  phase,
  includeMicro = false,
}: {
  items: ToolbarItem[]
  phase: Phases
  includeMicro?: boolean
}) => {
  const micro = includeMicro
    ? items.filter(
        (item) => miniCollapsePhases[item as MicroToolbarItem] < phase
      )
    : []
  const mini = items.filter(
    (item) => collapsePhases[item] < phase && !micro.includes(item)
  )
  return { mini, micro }
}

const isMicro = (item: ToolbarItem) =>
  MICRO_TOOLBAR_ITEMS.includes(item as MicroToolbarItem)

// pass in an array of toolbar items and this will
// return an array of items that need to be in mini mode to fit
export const getItemsToShrink = (
  items: ToolbarItem[],
  width: number
): { mini: ToolbarItem[]; micro: ToolbarItem[] } => {
  // fits at any phase, don't shrink anything
  if (measureItemsAtPhase(items, 0) < width) {
    return { mini: [], micro: [] }
  }
  // test shrinking different sets until it fits
  if (measureItemsAtPhase(items, 1) < width) {
    return gatherItems({ items, phase: 2 })
  }
  if (measureItemsAtPhase(items, 2) < width) {
    return gatherItems({ items, phase: 3 })
  }
  if (measureItemsAtPhase(items, 3) < width) {
    return gatherItems({ items, phase: 4 })
  }
  if (measureItemsAtPhase(items, 4) < width) {
    return gatherItems({ items, phase: 5, includeMicro: true })
  }
  if (measureItemsAtPhase(items, 5) < width) {
    return gatherItems({ items, phase: 6, includeMicro: true })
  }
  // shrink everything possible
  return {
    mini: items.filter((i) => !isMicro(i)),
    micro: items.filter((i) => isMicro(i)),
  }
}
