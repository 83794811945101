import { useMst } from '@state'
import axios from 'axios'

const DOCRAPTOR_API_KEY = 'YOUR_API_KEY_HERE'
const docraptorUrl = `/raptor/docs?user_credentials=${DOCRAPTOR_API_KEY}`

export const usePassthroughPrinting = () => {
  const { nodeEnv, apiClient, view, doDebug } = useMst()
  const bypassApi = nodeEnv === 'development'
  const unwatermark = view.shouldUnwatermark

  const print = async ({
    html,
    fileName,
  }: {
    html: string
    fileName: string
  }): Promise<Blob> => {
    await doDebug()
    if (bypassApi) {
      const { data } = await axios.post(
        docraptorUrl,
        {
          type: 'pdf',
          document_content: html,
          test: true,
        },
        {
          responseType: 'blob',
        }
      )
      return data
    } else {
      return apiClient.passThroughPrint({
        html,
        fileName,
        unwatermark,
      })
    }
  }

  return {
    print,
  }
}
