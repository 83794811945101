import cn from 'classnames'
import { UnstyledButton } from '@mantine/core'
import styles from './CreateCard.module.scss'

export const CreateCard = ({
  name,
  description,
  onClick,
  icon,
  isDisabled = false,
}: {
  name: string
  description: string
  onClick?: () => void
  icon: string
  isDisabled?: boolean
}) => (
  <UnstyledButton
    disabled={isDisabled}
    className={styles.createdoc}
    onClick={onClick}
  >
    <div className={styles.createdoc_row}>
      <div className={styles.createdoc_icon}>
        <i className={cn('fas', icon)}></i>
      </div>
      <div className={styles.createdoc_column}>
        <div className={styles.createdoc_label}>{name}</div>
        <div className={styles.createdoc_description}>{description}</div>
      </div>
    </div>
  </UnstyledButton>
)
