import React from 'react'
import { noop, styleHelpers } from '@util'

/*
  This hook and context provider allow a tree of components to coordinate the data
  and visibility of a floating menu. This is used in rundowns by mounting a context
  menu on the toolbar but positioning it dynamically based on where the mouse event
  was triggered
*/

type FloatingMenuState = {
  coords: XYCoords | null
  setCoords: (value: XYCoords | null) => void
}

const Context = React.createContext<FloatingMenuState>({
  coords: null,
  setCoords: noop,
})

export const FloatingMenuProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [coords, setCoords] = React.useState<XYCoords | null>(null)
  return (
    <Context.Provider value={{ coords, setCoords }}>
      {children}
    </Context.Provider>
  )
}

export type FloatingMenuProps = {
  coords: XYCoords | null
  opened: boolean
  targetStyle: React.CSSProperties
  handleContextMenu: (e: React.MouseEvent) => void
  hide: () => void
}

export const useFloatingMenu = (): FloatingMenuProps => {
  const { coords, setCoords } = React.useContext(Context)
  const targetStyle = styleHelpers.buildFloatingTargetStyle(coords)

  const handleContextMenu = (e: React.MouseEvent) => {
    e.preventDefault()
    const { clientX: x, clientY: y } = e
    setCoords({ x, y })
  }

  return {
    coords,
    opened: !!coords,
    targetStyle,
    handleContextMenu,
    hide: () => setCoords(null),
  }
}
