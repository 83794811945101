import NiceModal from '@ebay/nice-modal-react'
import { Text } from '@mantine/core'
import { IReadonlyFolderListState } from '@state/types'
import { ReadonlyFolderList } from '@components/FolderExplorer/FolderList'
import { Toast } from '@components/Toast'
import { useMst } from '@state'
import { ModalShell } from './ModalShell'
import { useModalControls } from './useModalControls'

import styles from './MoveToFolder.module.scss'

type FolderTreeModalProps = {
  folderListState: IReadonlyFolderListState
  title: string
  description?: string
  itemName?: string
  warningMessage?: string
  failureMessage?: string
  disabledFolderId?: string
  onSubmit?: (folderId: string) => Promise<void>
  onCancel?: () => void
}

const MoveToFolder = ({
  folderListState,
  title,
  description = 'Choose a new location for ',
  itemName = 'Item',
  warningMessage,
  failureMessage = 'An error occurred',
  onSubmit,
  onCancel,
}: FolderTreeModalProps) => {
  const controls = useModalControls()
  const { doDebug } = useMst()

  const handleClose = () => {
    onCancel?.()
    controls.onClose()
  }

  const handleSubmit = async () => {
    const { selectedFolderId } = folderListState
    if (!selectedFolderId) {
      controls.setErrorMessage('You must select a folder')
      return
    }
    controls.setLoading(true)
    try {
      await doDebug()
      await onSubmit?.(selectedFolderId)
      controls.onClose()
    } catch (e) {
      controls.setErrorMessage(failureMessage)
    }
    controls.setLoading(false)
  }

  return (
    <ModalShell
      title={title}
      confirmLabel="OK"
      cancelLabel="Cancel"
      onConfirm={handleSubmit}
      opened={controls.opened}
      loading={controls.loading}
      errorMessage={controls.errorMessage}
      onClose={handleClose}
    >
      {description && (
        <Text>
          {description} &quot;{itemName}&quot;
        </Text>
      )}
      <div className={styles.moveToFolder___tree}>
        <ReadonlyFolderList folderListState={folderListState} />
      </div>
      {warningMessage && (
        <Toast
          type="warning"
          icon="fas fa-exclamation-triangle"
          message={warningMessage}
          dismissable={false}
        />
      )}
    </ModalShell>
  )
}

export const MoveToFolderModal = NiceModal.create(MoveToFolder)

export const showMoveToFolder = (props: FolderTreeModalProps) =>
  NiceModal.show(MoveToFolderModal, props)
