import { Route, Redirect, useLocation, Switch } from 'wouter'
import * as Layouts from '../layouts'
import { MultiRoute } from './util'
import * as snapshots from '@components/SnapshotViews'
import { findConfigForPathname, ROUTE_PATTERNS } from '@util/pathConfigs'
import { OAuthCallbackPage } from '@components/OAuthCallbackPage'
import { NotFoundPage } from '@components/ErrorPage'
import * as components from './components'
import { SettingsRouter } from './SettingsRouter'
import { StaffRouter } from './StaffRouter'
import { useRouteChangeEffects } from '@hooks'

/*
  Once we get to this router, we know the user has a current org. Later we'll use this
  fact to swap in an org context, but for now it just lets us sidestep the very janky
  choo-driven bootstrap sequence
*/
export const AppRouter = () => {
  const [pathname] = useLocation()
  useRouteChangeEffects()
  const currentRouteConfig = findConfigForPathname(pathname)

  const sidebarRoutes = [
    ROUTE_PATTERNS.scriptEditor,
    ROUTE_PATTERNS.rundown,
    ROUTE_PATTERNS.root,
    ROUTE_PATTERNS.folder,
    ROUTE_PATTERNS.splitEditor,
    ROUTE_PATTERNS.diffSnapshot,
    ROUTE_PATTERNS.snapshot,
    ROUTE_PATTERNS.compareSnapshot,
    ROUTE_PATTERNS.archivedScript,
    ROUTE_PATTERNS.settings,
    ROUTE_PATTERNS.staffFlags,
    ROUTE_PATTERNS.staffFormats,
    ROUTE_PATTERNS.staffInvites,
    ROUTE_PATTERNS.staffLanding,
    ROUTE_PATTERNS.staffRundowns,
    ROUTE_PATTERNS.staffTemplateWorkspaces,
    ROUTE_PATTERNS.staffUsers,
    ROUTE_PATTERNS.staffWorkspaces,
    ROUTE_PATTERNS.staffWorkspaceDetail,
    ROUTE_PATTERNS.staffCloneWorkspace,
  ]

  // useEffectOnce(() => {
  //   showWhatsNewModal()
  // })

  return (
    <Layouts.AppWithHeader>
      {/* Header, Sidebar + body (script, rundown, new dashboard)
      Soon to be any authenticated route....
      */}
      <MultiRoute paths={sidebarRoutes}>
        <Layouts.SidebarAndBody>
          <Switch>
            <Route
              path="/wayback/:scriptId"
              component={components.ArchivedScriptRoute}
            />
            <Route
              path="/rundowns/:rundownId/:rest*"
              component={components.RundownRoute}
            />
            <Route
              path="/scripts/:scriptId"
              component={components.EditorRoute}
            />
            <Route path="/" component={components.DashboardRoute} />
            <Route
              path="/folders/:folderId"
              component={components.DashboardRoute}
            />
            <Route path="/folders">
              <Redirect to="/" />
            </Route>
            <Route
              path={ROUTE_PATTERNS.diffSnapshot}
              component={snapshots.SideBySideDiffPage}
            />
            <Route
              path={ROUTE_PATTERNS.snapshot}
              component={snapshots.StaticSnapshotPage}
            />
            <Route
              path={ROUTE_PATTERNS.compareSnapshot}
              component={snapshots.CompareSnapshotPage}
            />
            {/* Delegate anything that starts with /settings here */}
            <Route path="/settings/:rest*" component={SettingsRouter} />
            <StaffRouter />
          </Switch>
        </Layouts.SidebarAndBody>
      </MultiRoute>

      {/* oauth callback for slack integration */}
      <Route path="/oauth/callback" component={OAuthCallbackPage} />

      {/* Not found page */}
      {!currentRouteConfig && (
        <Layouts.CenteredScrollingBody>
          <NotFoundPage />
        </Layouts.CenteredScrollingBody>
      )}
    </Layouts.AppWithHeader>
  )
}
