import { Text } from '@mantine/core'
import { CommentFilter } from './helpers'

const filterLabels: Record<
  CommentFilter,
  { title: string; instruction: string }
> = {
  open: {
    title: 'No open comments yet.',
    instruction: 'Open comments on this script will appear here.',
  },
  resolved: {
    title: 'No resolved comments yet.',
    instruction: 'Resolved comments threads will appear here.',
  },
}

export const CommentInfo = ({ filter }: { filter: CommentFilter }) => (
  <>
    <Text fw={700} c="dimmed">
      {filterLabels[filter].title}
    </Text>
    <Text c="dimmed">{filterLabels[filter].instruction}</Text>
  </>
)
