import cn from 'classnames'
import { Select as MantineSelect, SelectProps } from '@mantine/core'

import styles from './Select.module.scss'

export const FilterSelect = (props: SelectProps & { isSelected?: boolean }) => {
  const { isSelected, ...passThroughProps } = props
  return (
    <MantineSelect
      classNames={{
        option: styles.select_option,
        input: cn({
          [styles.select_input___selected]: isSelected,
        }),
      }}
      {...passThroughProps}
    />
  )
}
