import Nanocomponent from 'nanocomponent'
import html from 'nanohtml'
import { Plugin, PluginKey } from 'prosemirror-state'
import {
  getDOMNodeAtPos,
  safelyGetOffset,
  getInputSize,
} from '../prose-utils.js'
const lockedPageNumbersKey = new PluginKey('PAGE_NUMBERS')
class LockedPageNumbers extends Nanocomponent {
  constructor(props) {
    super()
    this.props = props
    this._createPageNumberInput = this._createPageNumberInput.bind(this)
    this._onPageNumberChange = this._onPageNumberChange.bind(this)
  }
  createElement() {
    const { editorView, appState } = this.props
    const pageNumberInputs = appState.editor.scriptMeta.locked
      ? collectPages(editorView).map(this._createPageNumberInput)
      : null
    return html`<div id="editor-page-numbers">${pageNumberInputs}</div>`
  }
  update(view, prevState) {
    const docIsEq = view.state.doc.eq(prevState.doc)
    if (docIsEq) {
      return false
    }
    return true
  }
  _createPageNumberInput({ page, offset }) {
    const { editorView } = this.props
    const top =
      safelyGetOffset({
        node: getDOMNodeAtPos(editorView, offset + 1),
        includeParent: true,
        caller: 'locked-page-numbers',
      }) + 1
    const style = `top: calc(1em + 0.5in + ${top}px)`
    const { pageNumber = '' } = page.attrs
    const val = pageNumber == null ? '' : pageNumber + '' // coerce string
    return html`
      <div class="e-page-number" style=${style} onclick=${selectInput}>
        <input
          class="e-page-number-input"
          type="text"
          value=${val}
          placeholder="#"
          maxlength="59"
          oninput=${(e) => this._onPageNumberChange(e, page, offset, val)}
          size=${getInputSize(val)}
        />.
      </div>
    `
  }
  _onPageNumberChange(event, page, offset, val) {
    event.preventDefault()
    const pageNumber = event.target.value
    // update size
    event.target.size = getInputSize(pageNumber)
    // return early if there was no real change
    if (pageNumber === val) {
      return
    }
    // merge w/ current block attrs to avoid wiping them out
    const { editorView } = this.props
    const newAttrs = { ...page.attrs, pageNumber }
    const tr = editorView.state.tr.setNodeMarkup(offset, null, newAttrs)
    editorView.dispatch(tr)
  }
  _onInput(event, data) {
    const { pos, attrs } = data
    const val = event.target.value
    event.target.size = getInputSize(val)
    const elementNumber = val === '' ? null : val
    // merge w/ current block attrs to avoid wiping them out
    const newAttrs = { ...attrs, elementNumber }
    const tr = this.editorView.state.tr.setNodeMarkup(pos, null, newAttrs)
    this.editorView.dispatch(tr)
  }
}
function collectPages(editorView) {
  const pages = []
  editorView.state.doc.forEach((page, offset, index) => {
    if (index === 0) {
      return
    } // ignore first page
    pages.push({ page, offset })
  })
  return pages
}
function selectInput(event) {
  if (event.target.tagName === 'DIV') {
    event.target.firstChild.select()
  }
}
function lockedPageNumbersPlugin({ appState }) {
  return new Plugin({
    key: lockedPageNumbersKey,
    view(editorView) {
      const gutterRight = document.querySelector('.c-editor__gutterright')
      const lockedPageNumbers = new LockedPageNumbers({ editorView, appState })
      const el = lockedPageNumbers.render()
      gutterRight.appendChild(el)
      return {
        update: lockedPageNumbers.render.bind(lockedPageNumbers),
        destroy() {
          gutterRight.removeChild(el)
        },
      }
    },
  })
}
export { lockedPageNumbersKey }
export { lockedPageNumbersPlugin }
export default {
  lockedPageNumbersKey,
  lockedPageNumbersPlugin,
}
