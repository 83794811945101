/*
  Crazy hacks to get headers and stuff when printing.

  https://medium.com/@Idan_Co/the-ultimate-print-html-template-with-header-footer-568f415f6d2a
*/

export const wrapForPrint = ({
  html,
  headerHeight,
  footerHeight,
  headerWidth,
}: {
  html: string
  headerHeight: number
  footerHeight: number
  headerWidth?: number
}) => {
  const headerStyleParts = [`height: ${headerHeight}px`]
  if (typeof headerWidth === 'number') {
    headerStyleParts.push(`width: ${headerWidth}px`)
  }

  const headerStyle = headerStyleParts.join(';')

  return `
  <table style="width: 100%">
    <thead>
      <tr>
        <td>
          <div class="print-header-space" style="${headerStyle}">&nbsp;</div>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>${html}</td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td><div style="height: ${footerHeight}px">&nbsp;</div></td>
      </tr>
    </tfoot>
  </table>
`
}
