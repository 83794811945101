import React from 'react'
import { Card, Group, Stack, Text } from '@mantine/core'
import { format } from 'date-fns'
import { CommentMeta } from '@util/ScriptoApiClient/types'
import { CommentCore } from './Core'
import { CommentSnippet } from './Snippet'
import { UnresolveButton } from './Unresolve'
import {
  chronologicalCreation,
  isChildComment,
  isOrphaned,
  isParentComment,
  ResolvedCommentMeta,
} from './helpers'

import styles from './CommentHistory.module.scss'

export const ResolvedComments = ({
  comments,
  scriptId,
}: {
  comments: CommentMeta[]
  scriptId: string
}) => {
  let lastCommentDate: string
  const today = new Date()

  // 1. (filter) gather all resolved parent comments
  // 2. (map) add replies
  // 3. (map) build threads, adding date header as needed
  const parents: Record<string, ResolvedCommentMeta> = {}
  // gotta do all parents first because of ordering
  comments.filter(isParentComment).forEach((c) => {
    parents[c.id] = { ...c, replies: [] }
  })
  // gather children
  comments.filter(isChildComment).forEach((c) => {
    if (parents[c.parentId] !== undefined) {
      parents[c.parentId].replies.push(c)
    }
  })

  const commentCards = Object.keys(parents).map((parentId) => {
    const parent = parents[parentId]
    let formattedCommentDate = <></>
    const updatedAt = new Date(parent.updatedAt)
    // today is a special day
    // resolvedAt and updatedAt are equivalent for resolved comments
    // we use updatedAt because orphaned comments arent actually resolved
    const isToday = format(updatedAt, 'M d yyyy') === format(today, 'M d yyyy')
    const commentDate = isToday ? 'Today' : format(updatedAt, 'LLLL d, yyyy')
    if (commentDate !== lastCommentDate) {
      formattedCommentDate = <Text fw={700}>{commentDate}</Text>
    }
    lastCommentDate = commentDate

    const isOrphan = isOrphaned(parent)
    return (
      <React.Fragment key={parentId}>
        {formattedCommentDate}
        <Card className={styles.comment_card}>
          <Stack>
            <Group
              justify="space-between"
              align="normal"
              className={styles.foo}
              gap="xs"
            >
              <CommentSnippet comment={parent} />
              {!isOrphan && (
                <UnresolveButton commentId={parent.id} scriptId={scriptId} />
              )}
            </Group>
            <CommentCore comment={parent} />
            {parent.replies.sort(chronologicalCreation).map((reply) => (
              <React.Fragment key={reply.id}>
                <CommentCore comment={reply} />
              </React.Fragment>
            ))}
          </Stack>
        </Card>
      </React.Fragment>
    )
  })

  return <>{commentCards}</>
}
