import { useQuery } from '@tanstack/react-query'
import { useMst } from '@state'
import { Loader } from '@components/Loader'

export const LogoutPage = () => {
  const mst = useMst()

  useQuery({
    queryKey: ['logout'],
    queryFn: mst.logout,
  })

  return <Loader />
}

export const LogoutRoute = () => <LogoutPage />
