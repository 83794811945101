import { useState } from 'react'
import { useMst } from '@state'
import { Checkbox } from '@mantine/core'
import { UserListing } from './types'

const staffable = (email: string) =>
  /.@(scripto|showrunner).(cc|computer|io|live)/.test(email)

export function StaffCheckbox({ u }: { u: UserListing }) {
  const { user, apiClient } = useMst()
  const { email, id: userId } = u
  const isSelf = user.id === userId
  const [staff, setStaff] = useState(u.staff)

  const onChange = async () => {
    await apiClient.updateUserByStaff({ userId, staff: !staff })
    setStaff(!staff)
  }

  if (staffable(email)) {
    return (
      <Checkbox onChange={onChange} defaultChecked={staff} disabled={isSelf} />
    )
  }
  return null
}
