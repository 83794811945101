import { TextSelection, EditorState } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'

/**
 * we manipulate multiblock selections that begin at the start of
 * a block and either encompass an entire single block or more than
 * one block so that cut/paste doesnt leave an orphaned empty block
 * of the same type with the same id behind.
 */
const extendSelection = (
  viewState: EditorState,
  viewDispatch: EditorView['dispatch']
) => {
  const { selection, doc, tr } = viewState
  const { $from, $to } = selection

  const isTextSelection = selection instanceof TextSelection
  /**
   * abort if any of the below are detected:
   * 1. plain cursor
   * 1. NodeSelection or AllSelection
   * 1. text selection that doesn't begin at the beginning of a block
   *
   * */
  if (selection.empty || !isTextSelection || $from.parentOffset !== 0) {
    return false
  }

  const intraBlockSelection = $from.sameParent($to)
  const entireBlockSelection = $to.pos - $from.pos + 2 === $from.parent.nodeSize

  if (!intraBlockSelection || entireBlockSelection) {
    // move one position to the outside edge of the current block
    // do not pass go, do not collect $200
    const priorFrom = doc.resolve($from.pos - 1)
    tr.setSelection(new TextSelection(priorFrom, $to))
    viewDispatch(tr)
  }

  return false
}

export { extendSelection }
