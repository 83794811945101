import { Plugin } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'
import { CommentNodeView } from './comment-node-view.js'
import { CommentsView } from './comments-view.js'
import { commentsKey } from './plugin-key.js'
/**
 * Creates a new comments plugin
 * @returns {Plugin} new plugin instance
 */
function commentsPlugin({ scriptId, user, emit, appState }) {
  let commentsView
  return new Plugin({
    key: commentsKey,
    state: {
      init() {
        return {}
      },
      apply(tr) {
        const commentData = tr.getMeta(commentsKey)
        if (commentsView && commentData && commentData.commentId) {
          commentsView.actions.onSocketEvent(commentData)
        }
        return commentData
      },
    },
    view(editorView) {
      commentsView = new CommentsView({
        editorView,
        scriptId,
        user,
        emit,
        appState,
      })
      const rightGutter = document.querySelector('.c-editor__gutterright')
      const el = commentsView.render(editorView)
      rightGutter.appendChild(el)
      return {
        update: commentsView.render.bind(commentsView),
        destroy() {
          rightGutter.removeChild(el)
          // not sure why but unload is failing to get called when removing view from DOM,
          // so calling it manually for now to avoid uncaught errors piling up in console
          commentsView.unload()
          commentsView = null
        },
      }
    },
    props: {
      nodeViews: {
        comment(node) {
          return new CommentNodeView(node)
        },
      },
      // it feels a bit roundabout, but right now this same plugin is triggering a meta only
      // transaction when a comment is begun in order to decorate the selection
      decorations() {
        if (!commentsView || commentsView.state.isAdding == null) {
          return DecorationSet.empty
        }
        const { from, to } = commentsView.state.editorView.state.selection
        const deco = Decoration.inline(from, to, { class: 'is-highlighted' })
        const decos = DecorationSet.create(
          commentsView.state.editorView.state.doc,
          [deco]
        )
        return decos
      },
    },
  })
}
export { commentsKey, commentsPlugin }
