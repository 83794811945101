import * as AgGrid from '@ag-grid-community/core'
import { suppressKeyboardEvent } from '../eventHandlers'
export * from './getColumnDefs'

export const DEFAULT_COLUMN_DEF: AgGrid.ColDef = {
  suppressMenu: true,
  suppressMovable: true,
  resizable: true,
  suppressKeyboardEvent,
}
