/*
  In various places we have links to scripts where alt+click
  sets the user pref to split view and double-click sends you into
  single view. This handles the single/double click recognition
  and setting the preference.
*/
import React from 'react'
import useDoubleClick from 'use-double-click'
import { useMst } from '@state'

export const useScriptMultiClick = <T extends HTMLElement = HTMLDivElement>({
  ref,
  onClick,
}: {
  ref: React.RefObject<T>
  onClick: (e: React.MouseEvent) => void
}) => {
  const { user } = useMst()

  const setSplitViewPrefs = (preferSplit: boolean) => {
    if (preferSplit && !user.prefersSplitLayout) {
      user.enterSplitLayout()
    } else if (!preferSplit && user.prefersSplitLayout) {
      user.exitSplitLayout()
    }
  }

  useDoubleClick({
    onSingleClick: (e) => {
      if (e.altKey) {
        setSplitViewPrefs(true)
      }
      onClick(e)
    },
    onDoubleClick: (e) => {
      if (!e.altKey) {
        setSplitViewPrefs(false)
      }
      onClick(e)
    },
    ref,
    latency: 250,
  })

  return {
    ref,
  }
}
