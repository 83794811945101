import { Tooltip } from '@mantine/core'
import { FaIcon } from '@components/FaIcon'

export const RundownLinkIcon = ({ isLinked }: { isLinked: boolean }) => (
  <Tooltip label={`${isLinked ? 'L' : 'Not l'}inked to this rundown`}>
    {isLinked ? (
      <FaIcon size="14" icon="fa-table-cells" color="violet.7" />
    ) : (
      <FaIcon
        size="14"
        icon="fa-table-cells-slash"
        color="red"
        faIconSet="fa-kit"
      />
    )}
  </Tooltip>
)
