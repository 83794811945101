import { Node as PmNode, DOMSerializer } from 'prosemirror-model'
import { NodeTypeMap, schema } from '@showrunner/codex'
import { removeBracketsFromNode } from '@util'

const domSerializer = DOMSerializer.fromSchema(schema)

export type TwoColumnRow = {
  columns: 2
  bracket?: PmNode
  rightNodes: PmNode[]
  // A two column row gets sealed if a blank line shows up
  sealed?: boolean
}

export type OneColumnRow = {
  columns: 1
  fullNodes: PmNode[]
}

export type Row = OneColumnRow | TwoColumnRow

export function isTwoColumnRow(row: Row): row is TwoColumnRow {
  return row.columns === 2
}

export type Page = {
  number?: number
  rows: Row[]
}

export type TwoColBreakdown = Page[]

type Placement = 'left' | 'right' | 'center'

export const getColumnPlacement = (nodeType: string): Placement => {
  switch (nodeType) {
    case NodeTypeMap.BRACKET:
      return 'left'
    case NodeTypeMap.CHARACTER:
    case NodeTypeMap.DIALOGUE:
    case NodeTypeMap.PARENTHETICAL:
      return 'right'
    default:
      return 'center'
  }
}

export const createNewRow = (node: PmNode, placement: Placement): Row => {
  switch (placement) {
    case 'left':
      return {
        columns: 2,
        bracket: node,
        rightNodes: [],
      }
    case 'right':
      return {
        columns: 2,
        rightNodes: [node],
      }
    case 'center':
      return {
        columns: 1,
        fullNodes: [node],
      }
  }
}

export const pmNodeToHtml = (pmNode: PmNode) => {
  const domNode = domSerializer.serializeNode(pmNode)
  if (domNode instanceof HTMLElement) {
    const elementNumber = pmNode.attrs.elementNumber
    const prefix = elementNumber ? `${elementNumber}. ` : ''
    const isBracket = pmNode.type.name === NodeTypeMap.BRACKET
    if (isBracket) removeBracketsFromNode(domNode)
    const html = domNode.innerHTML
    return prefix + html
  }
}

export type MarginSlotContent =
  | 'full-title'
  | 'short-title'
  | 'timestamp'
  | 'page-number'

export type MarginSlotPosition =
  | 'topLeft'
  | 'topCenter'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomCenter'
  | 'bottomRight'
