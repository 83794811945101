import { IPrincePrintStrategy, PageLayout } from './types'

// universal page directive for prince printing, across any doc
// type. If we ever want to configure this per doc type, we can add it
// IPrincePrintStrategy
const buildPageDirective = (layout: PageLayout): string => {
  const { orientation, size, margins } = layout
  const { top, left, right, bottom } = margins
  const { height, width } = size
  return `
    @page {
      margin: ${top} ${right} ${bottom} ${left};
      size: ${width} ${height} ${orientation};

      @bottom {
        content: flow(footer-html);
        vertical-align: top;
      }

      @top {
        content: flow(header-html);
        vertical-align: bottom;
      }
    }`
}

export const buildHtmlForPrince = (strategy: IPrincePrintStrategy) => `
<html>
  <head>
    ${strategy.generateHeadElements()}
    <style>
      ${buildPageDirective(strategy.generatePageLayout())}
      ${strategy.generateStyles()}
    </style>
    </head>
    ${strategy.generateHeaderAndFooter()}
    <body>
    ${strategy.generateBody()}
  </body>
</html>`
