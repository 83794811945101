import { TextSelection } from 'prosemirror-state'
import { joinPoint } from 'prosemirror-transform'
import { types } from '@showrunner/codex'
import { pageBreakerKey } from '../page-breaker/index.js'
import { lockedPagesInSelection } from '../../prose-utils.js'
// empty page contains one empty block node
const EMPTY_PAGE_NODE_SIZE = 4

/**
 * disable DELETE when cursor is right before a locked page
 * @param {ProseMirror.EditorState} viewState - editor state instance
 * @return {boolean} prevent any other keydown handlers from firing
 */
function lockedPageDelete(viewState) {
  if (!viewState.selection.empty) {
    // prevent if there's an active selection containing locked pages
    if (lockedPagesInSelection(viewState)) {
      return true
    }
    // otherwise skip for any active selections
    return false
  }
  const { $cursor } = viewState.selection
  // skip if we're not at the END of a text block
  if ($cursor.parentOffset !== $cursor.node().nodeSize - 2) {
    return false
  }
  const $cut = findCutAfter($cursor)
  const nodeAfter = $cut && $cut.nodeAfter
  // prevent delete if node before is not a page,
  // or next page is not locked
  const shouldPrevent =
    nodeAfter && nodeAfter.type.name === types.PAGE && nodeAfter.attrs.locked
  if (shouldPrevent) {
    return true
  }
  return false
}

/**
 * Handler for when a plain cursor is positioned within an empty
 * block with another empty block directly above, or the very first block
 * of the script (with another block below) when Backspace is pressed.
 *
 * On its own PM will either join the two, which allows the current
 * block to persist, or do nothing.
 * Instead, we can remove the current block from the document and place the cursor
 * manually when necessary in a transaction of our own.
 *
 * @param {ProseMirror.EditorState} viewState - editor state instance
 * @param {function} viewDispatch - transaction dispatcher
 * @return {boolean} prevent any other keydown handlers from firing
 */
function emptyBlockBackspace(viewState, viewDispatch) {
  // skip if there's an active selection
  if (!viewState.selection.empty) return false

  // skip if:
  // cursor isnt at start of current block
  // current block is not empty
  const { $cursor } = viewState.selection
  if ($cursor.parentOffset > 0 || $cursor.parent.nodeSize > 2) return false

  const $cutBefore = findCutBefore($cursor)
  const nodeBefore = $cutBefore && $cutBefore.nodeBefore

  const $cutAfter = findCutAfter($cursor)
  const nodeAfter = $cutAfter && $cutAfter.nodeBefore

  const hasNodeBefore = nodeBefore && nodeBefore.nodeSize === 2
  // current block is first in script followed by another text block
  const hasNodeAfter =
    $cursor.pos === 2 && nodeAfter && nodeAfter.type.name !== types.PAGE

  // skip if no previous block or not empty AND no node after
  if (!hasNodeBefore && !hasNodeAfter) return false

  const { tr } = viewState
  tr.delete($cursor.before(), $cursor.after())

  if (hasNodeBefore) {
    tr.setSelection(
      // we need to inch one more pos into the previous block
      // i hate magic numbers, but $cursor.pos - 2 isn't any better
      // and you can't do something like nodeBefore.end() like you
      // can with an already active selection
      TextSelection.create(tr.doc, tr.mapping.map($cursor.before() - 1))
    )
  }

  tr.scrollIntoView()
  viewDispatch(tr)

  // return true to sidestep the default PM behavior
  return true
}

/**
 * join dynamic page at boundary on backspace before normal delete op
 * FIXME: see if instead of joining we can move the block to the end of the next
 * page and let page-breaker take care of breaking (and retaining page number).
 * @param {ProseMirror.EditorState} viewState - editor state instance
 * @param {function} viewDispatch - transaction dispatcher
 * @return {boolean} prevent any other keydown handlers from firing
 */
function dynamicPageBackspace(viewState, viewDispatch) {
  // skip if there's an active selection
  if (!viewState.selection.empty) {
    return false
  }
  const { $cursor } = viewState.selection
  // skip if we're not at the start of a text block
  if ($cursor.parentOffset > 0) {
    return false
  }
  const $cut = findCutBefore($cursor)
  const nodeBefore = $cut && $cut.nodeBefore
  // skip if node before is not a page,
  // or current node is not a dynamic page
  const skip =
    !nodeBefore ||
    nodeBefore.type.name !== types.PAGE ||
    !$cursor.node(1).attrs.dynamic
  if (skip) {
    return false
  }
  viewDispatch(
    viewState.tr
      .join(joinPoint(viewState.tr.doc, $cut.pos))
      .setMeta(pageBreakerKey, 'pageJoin')
      .scrollIntoView()
  )
  return false
}
/**
 * special case for locked pages:
 * - if selection includes locked page boundary, prevent transaction
 * - if page has content, move cursor to last page instead of joining
 * - if page is empty, delete the whole page
 * @param {ProseMirror.EditorState} viewState - editor state instance
 * @param {function} viewDispatch - transaction dispatcher
 * @return {boolean} prevent any other keydown handlers from firing
 */
function lockedPageBackspace(viewState, viewDispatch) {
  if (!viewState.selection.empty) {
    // prevent if there's an active selection containing locked pages
    if (lockedPagesInSelection(viewState)) {
      return true
    }
    // otherwise skip for any active selections
    return false
  }
  const { $cursor } = viewState.selection
  // skip if we're not at the start of a text block
  if ($cursor.parentOffset > 0) {
    return false
  }
  const $cut = findCutBefore($cursor)
  const nodeBefore = $cut && $cut.nodeBefore
  const page = $cursor.node(1)
  // skip if node before is not a page,
  // or current node is not a locked page
  const skip =
    !nodeBefore || nodeBefore.type.name !== types.PAGE || !page.attrs.locked
  if (skip) {
    return false
  }
  const { tr } = viewState
  const isEmptyPage = page.nodeSize === EMPTY_PAGE_NODE_SIZE
  if (isEmptyPage) {
    // if locked page is empty, remove it completely
    tr.delete($cut.pos, $cut.pos + EMPTY_PAGE_NODE_SIZE)
  } else {
    // if locked page has content, move cursor to last page
    const $before = $cursor.doc.resolve($cursor.pos - 1)
    tr.setSelection(TextSelection.findFrom($before, -1, true))
  }
  viewDispatch(tr.scrollIntoView())
  return true
}
/**
 * find cut position before node at given position
 * copied from https://github.com/ProseMirror/prosemirror-commands/blob/51a2f46ae40acb771c179b005836d3532fab99b3/src/commands.js#L90
 * @param {ProseMirror.ResolvedPos} $pos - position to query
 * @returns {ProseMirror.ResolvedPos} cut position
 */
function findCutBefore($pos) {
  if (!$pos.parent.type.spec.isolating) {
    for (let i = $pos.depth - 1; i >= 0; i--) {
      if ($pos.index(i) > 0) {
        return $pos.doc.resolve($pos.before(i + 1))
      }
      if ($pos.node(i).type.spec.isolating) {
        break
      }
    }
  }
  return null
}
/**
 * find cut position after node at given position
 * copied from https://github.com/ProseMirror/prosemirror-commands/blob/51a2f46ae40acb771c179b005836d3532fab99b3/src/commands.js#L90
 * @param {ProseMirror.ResolvedPos} $pos - position to query
 * @returns {ProseMirror.ResolvedPos} cut position
 */
function findCutAfter($pos) {
  if (!$pos.parent.type.spec.isolating) {
    for (let i = $pos.depth - 1; i >= 0; i--) {
      const parent = $pos.node(i)
      if ($pos.index(i) + 1 < parent.childCount) {
        return $pos.doc.resolve($pos.after(i + 1))
      }
      if (parent.type.spec.isolating) {
        break
      }
    }
  }
  return null
}

export {
  emptyBlockBackspace,
  dynamicPageBackspace,
  lockedPageBackspace,
  lockedPageDelete,
}
