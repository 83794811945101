import React from 'react'
import { Stack } from '@mantine/core'
import { Loader } from '@components/Loader'
import { UnknownErrorPage } from '@components/ErrorPage'
import { SnapshotMiniHeader } from '@components/MiniHeader'

import { SnapshotToolbar } from './SnapshotToolbar'

const pageStyles: React.CSSProperties = {
  flex: 1,
  overflow: 'hidden',
}

export const SnapshotPage = ({
  scriptId,
  snapshotId,
  children,
  loading,
  error,
}: {
  scriptId: string
  snapshotId: string
  children: React.ReactNode
  loading?: boolean
  error?: boolean
}) => (
  <Stack gap={0} style={pageStyles}>
    <Loader visible={loading} fullScreen />
    <SnapshotMiniHeader scriptId={scriptId} snapshotId={snapshotId} />
    {children}
    {error && <UnknownErrorPage />}
  </Stack>
)

SnapshotPage.Toolbar = SnapshotToolbar
