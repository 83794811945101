import { observer } from 'mobx-react-lite'
import { Avatar, Group, Tooltip } from '@mantine/core'
import { useMst } from '@state'
import { ButtonMode, IRemoteUser } from '@state/types'
import { Toolbar } from '@components/Toolbar'
import { OverflowCollaborators } from './Overflow'
import { jumpToCollaborator, getMaxCollaborators } from './helpers'

import styles from './Collaborators.module.scss'

export const Collaborators = observer(function Collaborators({
  clientId,
  users,
  mode,
}: {
  clientId: string
  users: Array<IRemoteUser>
  mode: ButtonMode
}) {
  const { view } = useMst()
  const remoteUsers = users.filter(
    (u) => u.clientId !== undefined && u.clientId !== clientId
  )

  // on extremely small screens, we suppress the component entirely
  if (mode === 'micro') return null

  const maxCollab = getMaxCollaborators(mode)
  const collabCount = remoteUsers.length
  const hasOverflow = collabCount > maxCollab
  const primary = remoteUsers.slice(0, maxCollab)

  return (
    <Toolbar.ButtonGroup>
      <Group gap={0}>
        <Avatar.Group spacing="sm">
          {primary.map((u, idx) => (
            <Tooltip label={u.name + ' - Click to find'} key={idx}>
              <Avatar
                src={u.avatar}
                className={styles.collaborators_avatar}
                style={{
                  border: `2px solid ${u.color}`,
                }}
                size={28}
                radius="xl"
                onClick={() => jumpToCollaborator(u)}
              />
            </Tooltip>
          ))}
          {hasOverflow && (
            <OverflowCollaborators
              containerHeight={view.dimensions.scriptScroller.height}
              collaborators={remoteUsers}
              mode={mode}
            />
          )}
        </Avatar.Group>
      </Group>
    </Toolbar.ButtonGroup>
  )
})
