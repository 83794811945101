import { Avatar, Text } from '@mantine/core'
import { CommentMeta } from '@util/ScriptoApiClient/types'
import { getAvatarUrl } from '@util'
import { useMst } from '@state'
import { AutolinkedText } from './Link'

import styles from './CommentHistory.module.scss'

export const CommentCore = ({ comment }: { comment: CommentMeta }) => {
  const { environment } = useMst()

  const text = comment.deletedAt ? (
    <Text fs="italic" span>
      Comment deleted by author
    </Text>
  ) : (
    <AutolinkedText text={comment.text} />
  )

  return (
    <div className={styles.comment_contents}>
      <div className={styles.comment_byline}>
        <Avatar
          src={getAvatarUrl(comment.creator.avatar, environment.config)}
          alt={comment.creator.name}
          radius="xl"
          size="sm"
        ></Avatar>
        <div className={styles.comment_text}>
          <span className={styles.comment_author}>{comment.creator.name}:</span>
          {text}
        </div>
      </div>
    </div>
  )
}
