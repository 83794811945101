import Choo from 'choo'
import { Socket } from 'socket.io-client'
import { ScriptDocType } from '@showrunner/codex'
import { IRoot } from '@state/types'
import Nanobus from 'nanobus'
import { EditorManager } from './lib/editor/EditorManager'
import { registerRoutes } from './routes/index.js'
import { registerStores } from './stores'
import env from './lib/env.js'
import chooDevtools from 'choo-devtools'

const { IS_DEV, isDebug } = env

// this is what new Choo() actually creates, the typings are wrong
type CreatedChooApp = Choo & {
  emitter: Nanobus
  state: Choo.IState
}

// In this file, we augment the globals hanging off of
// the choo app
type ScriptoExtensions = {
  io: Socket
  editorManager: EditorManager
  state: Choo.IState & {
    editor: {
      cutComments: string[]
      script: {
        id: string
        type: ScriptDocType
        version: number
      }
    }
    mst: IRoot
  }
}

export type ScriptoChooApp = CreatedChooApp & ScriptoExtensions

export function buildChooApp(mst: IRoot): ScriptoChooApp {
  const app = new Choo() as CreatedChooApp & Partial<ScriptoExtensions>
  const { socketForChooAppOnly: io } = mst.socketManager
  app.state.mst = mst
  app.io = io
  app.editorManager = new EditorManager({
    state: app.state,
    io,
    emit: app.emitter.emit.bind(app.emitter),
  })

  // register stores with application
  registerStores(app)
  // register routes to application
  registerRoutes(app)

  const scriptoChooApp = app as ScriptoChooApp

  // add dev logging as needed
  // PROTIP: debug in prod by setting logLevel to debug in localStorage
  if (IS_DEV || isDebug()) {
    window.scripto.app = scriptoChooApp
    app.use(chooDevtools())
  }
  return scriptoChooApp
}
