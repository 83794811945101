import React from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '@mantine/core'
import { FormatUtils } from '@showrunner/codex'
import { useMst } from '@state'
import { showAlert } from '@components/Modals'
import { saveTextToFile } from '@util'
import { ScriptSnapshotPayload } from '@util/ScriptoApiClient/types'
import { ErrorMessage } from './ErrorMessage'
import { parseServerError } from '@util'

type Inputs = {
  id: string
  name: string
  description: string
  icon: string
  definitionJSON: string
}

export const CreateFormatForm = ({
  currentFormat,
  onApplyFormat,
}: {
  currentFormat: ScriptSnapshotPayload['scriptFormat']
  onApplyFormat: (definition: FormatUtils.ScriptConfiguration) => void
}) => {
  const { apiClient } = useMst()
  const {
    register,
    handleSubmit,
    setError,
    formState,
    getValues,
    clearErrors,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      id: currentFormat.id,
      name: currentFormat.name,
      description: currentFormat.description,
      icon: currentFormat.icon,
      definitionJSON: JSON.stringify(currentFormat.definition, null, 2),
      apiError: '',
    },
  })

  const parseJson = (
    jsonDef: string
  ): FormatUtils.ScriptConfiguration | void => {
    try {
      const parsed = JSON.parse(jsonDef)
      return parsed as FormatUtils.ScriptConfiguration
    } catch {
      setError('definitionJSON', { message: 'Invalid JSON' })
    }
  }

  const validateDefinition = (jsonDef: string) => {
    const parsed = parseJson(jsonDef)
    if (parsed) {
      const result = FormatUtils.validateScriptConfiguration(parsed)
      if (result.success) {
        return parsed
      } else {
        setError('definitionJSON', {
          message:
            'The config is valid JSON but violates the schema. See browser console',
        })
        // eslint-disable-next-line no-console
        console.error(result.error)
      }
    }
  }

  const saveNewFormat = async (inputs: Inputs) => {
    const { id, name, description, icon, definitionJSON } = inputs
    const definition = validateDefinition(definitionJSON)
    if (definition) {
      try {
        await apiClient.createScriptFormat({
          id,
          name,
          definition,
          description,
          icon,
        })

        showAlert({
          title: 'New format created',
          children: `You can now assign ${getValues(
            'id'
          )} as a studioFormat or screenplayFormat to individual shows`,
        })
      } catch (e) {
        const { message } = parseServerError(e)
        setError('apiError', { type: 'custom', message })
      }
    }
  }

  const applyFormat = () => {
    const definition = validateDefinition(getValues('definitionJSON'))
    if (definition) {
      onApplyFormat(definition)
    }
  }

  const validateId = (id: string) => {
    const valid = id.trim().length > 0 && id.trim() !== currentFormat.id
    return valid || 'Unique ID is required (must not match original value)'
  }

  return (
    <>
      <form style={{ height: '100%' }} onSubmit={handleSubmit(saveNewFormat)}>
        <div>
          <span>ID</span>
          <input
            {...register('id', {
              validate: {
                id: validateId,
              },
            })}
          />
        </div>
        <ErrorMessage message={formState.errors.id?.message} />
        <div>
          <span>Name</span>
          <input
            {...register('name', { required: 'Display Name is required' })}
          />
        </div>
        <ErrorMessage message={formState.errors.name?.message} />
        <div>
          description:
          <input {...register('description', { required: true })} />
        </div>
        <ErrorMessage message={formState.errors.description?.message} />
        <div>
          icon:
          <input {...register('icon', { required: true })} />
        </div>
        <ErrorMessage message={formState.errors.icon?.message} />
        <br />
        <div className="button-bar">
          <Button
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault()
              applyFormat()
            }}
          >
            Preview Format on Snapshot
          </Button>
          <Button
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault()
              saveTextToFile({
                text: getValues('definitionJSON'),
                fileName: 'new-script-format.json',
              })
            }}
          >
            Download Format JSON
          </Button>
          <Button type="submit">Save new Format to Database</Button>
        </div>
        <br />
        <ErrorMessage
          message={
            formState.errors.definitionJSON?.message ??
            formState.errors.apiError?.message
          }
        />
        <textarea
          {...register('definitionJSON')}
          style={{ height: 500 }}
          onChange={() => clearErrors('definitionJSON')}
        />
      </form>
    </>
  )
}
