import { history } from 'prosemirror-history'
import { metaPlugin } from './meta'
import { elementMenuPlugin } from './element-menu'
import { inputRulesPlugin } from './input-rules.js'
import { remoteCursorPlugin } from './remote-cursor/index.js'
import { pageBreakerPlugin } from './page-breaker/index.js'
import { editorKeymapPlugin } from './editor-keymap/index.js'
import { baseKeymapPlugin } from './base-keymap.js'
import { placeholderPlugin } from './placeholder.js'
import { characterAutocompletePlugin } from './character-autocomplete.js'
import { commentsPlugin } from './comments/index.js'
import { collabUpdatePlugin } from './collab-update.js'
import { elementNumbersPlugin } from './element-numbers/index.js'
import { lockedPageNumbersPlugin } from './locked-page-numbers.js'
import { ensureNewlinePlugin } from './ensure-newline.js'
import { idMakerPlugin } from './id-maker.js'
import { marginInfo, marginSlots } from './margins'
import { recordSelection } from './recordSelection'
import { inkKeymapPlugin } from './ink-keymap'
import { inkInputRulesPlugin } from './ink-input-rules'
import { PluginConfig } from './types'
import { indentationStylesFactory } from './indentation-styles'

export const meta: PluginConfig = { key: 'meta', plugin: metaPlugin }

export const collabUpdate: PluginConfig = {
  key: 'collabUpdate',
  plugin: collabUpdatePlugin,
}
export const characterAutocomplete: PluginConfig = {
  key: 'characterAutocomplete',
  plugin: characterAutocompletePlugin,
  liveEditorOnly: true,
  requiresWriteAccess: true,
}
export const elementMenu: PluginConfig = {
  key: 'elementMenu',
  plugin: elementMenuPlugin,
  liveEditorOnly: true,
  requiresWriteAccess: true,
}
export const inputRules: PluginConfig = {
  key: 'inputRules',
  plugin: inputRulesPlugin,
}
export const editorKeymap: PluginConfig = {
  key: 'editorKeymap',
  plugin: editorKeymapPlugin,
}

export const baseKeymap: PluginConfig = {
  key: 'baseKeymap',
  plugin: baseKeymapPlugin,
}
export const ensureNewline: PluginConfig = {
  key: 'ensureNewline',
  plugin: ensureNewlinePlugin,
  classicFlagOnly: true,
  requiresWriteAccess: true,
}
export const pageBreaker: PluginConfig = {
  key: 'pageBreaker',
  plugin: pageBreakerPlugin,
  liveEditorOnly: true,
  requiresWriteAccess: true,
}
export const remoteCursor: PluginConfig = {
  key: 'remoteCursor',
  plugin: remoteCursorPlugin,
}
export const placeholder: PluginConfig = {
  key: 'placeholder',
  plugin: placeholderPlugin,
}
export const comments: PluginConfig = {
  key: 'comments',
  plugin: commentsPlugin,
  liveEditorOnly: true,
  // NOTE: this is one of the few exceptions to the combination of
  // live editor and requires write access-- read-only users in a live
  // editor DO have get the comments plugin
  requiresWriteAccess: false,
}
export const lockedPageNumbers: PluginConfig = {
  key: 'lockedPageNumbers',
  plugin: lockedPageNumbersPlugin,
  liveEditorOnly: true,
  requiresWriteAccess: true,
}
export const idMaker: PluginConfig = {
  key: 'idMaker',
  plugin: idMakerPlugin,
  liveEditorOnly: true,
  requiresWriteAccess: true,
}
export const elementNumbers: PluginConfig = {
  key: 'elementNumbers',
  plugin: elementNumbersPlugin,
  liveEditorOnly: true,
  requiresWriteAccess: false,
}
// margin info needs to go after pagination
// and element numbers
export const marginInfoPlugin: PluginConfig = {
  key: 'marginInfo',
  plugin: marginInfo,
}
export const marginSlotsPlugin: PluginConfig = {
  key: 'marginSlots',
  plugin: marginSlots,
}
export const historyPlugin: PluginConfig = {
  key: 'history',
  plugin: () => history(),
  liveEditorOnly: true,
  requiresWriteAccess: true,
}
export const recordSelectionPlugin: PluginConfig = {
  key: 'recordSelection',
  plugin: recordSelection,
}
export const inkKeymap: PluginConfig = {
  key: 'inkKeymap',
  plugin: inkKeymapPlugin,
  requiresWriteAccess: true,
}
export const inkInputRules: PluginConfig = {
  key: 'inkInputRules',
  plugin: inkInputRulesPlugin,
  requiresWriteAccess: true,
}
export const indentationStyles: PluginConfig = {
  key: 'detectIndentation',
  plugin: indentationStylesFactory,
}
