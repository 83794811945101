import { Title, Table, ActionIcon } from '@mantine/core'
import { useMst } from '@state'
import { FaIcon } from '@components/FaIcon'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { SCHEMA_QUERY_KEY } from '../helpers'
import { rundownSchemaQuery } from '../helpers'
import { RundownSchemaListing } from '@util/ScriptoApiClient/types'

export const RundownSchemaList = () => {
  const mst = useMst()
  const queryClient = useQueryClient()

  const { data: schemas } = useQuery(rundownSchemaQuery(mst.apiClient))

  const updateDescription = async (schema: RundownSchemaListing) => {
    const { id: schemaId, description: oldDescription } = schema
    const description = prompt('New description:', oldDescription)
    if (!description) return
    await mst.apiClient.updateRundownSchema({
      schemaId,
      description,
    })
    queryClient.invalidateQueries({ queryKey: [SCHEMA_QUERY_KEY] })
  }

  const rows = schemas
    ?.sort((a, b) => (a.id > b.id ? 1 : -1))
    .map((schema) => (
      <tr key={schema.id}>
        <td>{schema.id}</td>
        <td>{schema.name}</td>
        <td>{schema.description}</td>
        <td>
          <ActionIcon color="purple" onClick={() => updateDescription(schema)}>
            <FaIcon size="12" icon="fa-pencil" />
          </ActionIcon>
        </td>
      </tr>
    ))

  return (
    <>
      <Title order={3}>Rundown Schemas</Title>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>ID</Table.Th>
            <Table.Th>Name</Table.Th>
            <Table.Th>Description</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </>
  )
}
