import { FaIcon } from '@components/FaIcon'
import styles from './ArchivedScriptIcon.module.scss'

export const ArchivedScriptIcon = ({ goldtooth }: { goldtooth: boolean }) => (
  <FaIcon
    c="dark"
    icon="fa-box-archive"
    className={goldtooth ? styles.goldtooth : undefined}
  />
)
