import { CommentMeta } from '@util/ScriptoApiClient/types'

export type CommentFilter = 'open' | 'resolved'
export const CommentFilterValues = ['open', 'resolved'] as const

export type ResolvedCommentMeta = CommentMeta & { replies: CommentMeta[] }
export type ChildCommentMeta = CommentMeta & { parentId: string }
export type ParentCommentMeta = CommentMeta & { parentId: null }

export function isParentComment(meta: CommentMeta): meta is ParentCommentMeta {
  return meta.parentId === null
}

export function isChildComment(meta: CommentMeta): meta is ChildCommentMeta {
  return meta.parentId !== null
}

export type ThreadCount = Record<string, number>
export type Counter = { el: HTMLElement; commentIds: string[] | undefined }

export const isOrphaned = (comment: CommentMeta) =>
  comment.parentId === null
    ? document.querySelector(`#e-comment-${comment.id}`) === null
    : document.querySelector(`#e-comment-${comment.parentId}`) === null

const filterOpen = (comments: CommentMeta[]) =>
  comments.filter((c) => c.resolvedAt === null && !isOrphaned(c))

const filterResolved = (comments: CommentMeta[]) =>
  comments.filter((c) => c.resolvedAt !== null || isOrphaned(c))

export const filterComments = (
  filter: CommentFilter,
  comments: CommentMeta[] = []
) => (filter === 'open' ? filterOpen(comments) : filterResolved(comments))

export const chronologicalCreation = (a: CommentMeta, b: CommentMeta) =>
  new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf()
