import {
  Anchor,
  Avatar,
  Blockquote,
  Card,
  Group,
  Space,
  Stack,
  Text,
} from '@mantine/core'

import styles from './CommentHistory.module.scss'

export const CommentMock = () => (
  <>
    <Text fw={700}>Today</Text>
    <Card className={styles.comment_card}>
      <Stack gap="xs">
        <Group justify="space-between" align="normal" gap="xs">
          <Blockquote
            classNames={{
              icon: styles.comment_quoteicon,
              root: styles.comment_quoteinner,
            }}
            color="yellow"
            className={styles.comment_quote}
          >
            <Text lineClamp={2}>cellar door</Text>
          </Blockquote>
          <Anchor size="sm" className={styles.comment_link}>
            Unresolve
          </Anchor>
        </Group>

        <div className={styles.comment_contents}>
          <div className={styles.comment_byline}>
            <Avatar src={null} alt="Mel Thomas" radius="xl" size="sm"></Avatar>
            <div className={styles.comment_text}>
              <span className={styles.comment_author}>mel:</span>
              <span>
                Paleo meggings YOLO prism viral man braid synth pok pok
                wayfarers pug occupy la croix squid tilde. Gochujang banh mi
                typewriter, enamel pin viral vice glossier slow-carb kitsch
                bitters.
              </span>
            </div>
          </div>
        </div>
      </Stack>
    </Card>
    <Card className={styles.comment_card}>
      <Stack gap="xs">
        <Group justify="space-between" align="normal" gap="xs">
          <Blockquote
            classNames={{
              icon: styles.comment_quoteicon,
              root: styles.comment_quoteinner,
            }}
            color="yellow"
            className={styles.comment_quote}
          >
            <Text lineClamp={2}>
              La croix semiotics mixtape fam, selvage tumblr church-key hexagon
              pok pok shabby chic vape asymmetrical blue bottle
            </Text>
          </Blockquote>
          <Anchor size="sm" className={styles.comment_link}>
            Unresolve
          </Anchor>
        </Group>
        <div className={styles.comment_contents}>
          <div className={styles.comment_byline}>
            <Avatar src={null} alt="Mel Thomas" radius="xl" size="sm"></Avatar>
            <div className={styles.comment_text}>
              <span className={styles.comment_author}>mel:</span>
              <span>Gochujang banh mi</span>
            </div>
          </div>
        </div>
        <div className={styles.comment_contents}>
          <div className={styles.comment_byline}>
            <Avatar src={null} alt="Mel Thomas" radius="xl" size="sm"></Avatar>
            <div className={styles.comment_text}>
              <span className={styles.comment_author}>mel:</span>
              <span>DIY poutine jean shorts</span>
            </div>
          </div>
        </div>
        <div className={styles.comment_contents}>
          <div className={styles.comment_byline}>
            <Avatar src={null} alt="Mel Thomas" radius="xl" size="sm"></Avatar>
            <div className={styles.comment_text}>
              <span className={styles.comment_author}>mel:</span>
              <span>ennui squid single-origin coffee</span>
            </div>
          </div>
        </div>
        <div className={styles.comment_contents}>
          <div className={styles.comment_byline}>
            <Avatar src={null} alt="Mel Thomas" radius="xl" size="sm"></Avatar>
            <div className={styles.comment_text}>
              <span className={styles.comment_author}>mel:</span>
              <span>iceland chicharrones trust fund</span>
            </div>
          </div>
        </div>
      </Stack>
    </Card>
    <Card className={styles.comment_card}>
      <Stack gap="xs">
        <div className={styles.comment_contents}>
          <div className={styles.comment_byline}>
            <Avatar src={null} alt="Mel Thomas" radius="xl" size="sm"></Avatar>
            <div className={styles.comment_text}>
              <span className={styles.comment_author}>mel:</span>
              <span>
                Im baby cloud bread pug lomo letterpress pabst. Keffiyeh
                flexitarian sriracha ethical.
              </span>
            </div>
          </div>
          <Space h="xs" />
          <Anchor size="sm" className={styles.comment_link}>
            Jump to thread (3)
          </Anchor>
        </div>
      </Stack>
    </Card>
    <Card className={styles.comment_card}>
      <Stack gap="xs">
        <Blockquote
          classNames={{
            icon: styles.comment_quoteicon,
            root: styles.comment_quoteinner,
          }}
          color="yellow"
          className={styles.comment_quote}
        >
          <Text lineClamp={1}>
            La croix semiotics mixtape fam, selvage tumblr church-key hexagon
            pok pok shabby chic vape asymmetrical blue bottle
          </Text>
        </Blockquote>
        <div className={styles.comment_contents}>
          <div className={styles.comment_byline}>
            <Avatar src={null} alt="Mel Thomas" radius="xl" size="sm"></Avatar>
            <div className={styles.comment_text}>
              <span className={styles.comment_author}>mel:</span>
              <span>
                Paleo meggings YOLO prism viral man braid synth pok pok
                wayfarers pug occupy la croix squid tilde. Gochujang banh mi
                typewriter, enamel pin viral vice glossier slow-carb kitsch
                bitters.
              </span>
            </div>
          </div>
          <Space h="xs" />
          <Anchor size="sm" className={styles.comment_link}>
            Jump to comment
          </Anchor>
        </div>
      </Stack>
    </Card>
    <Text fw={700}>January 30, 2023</Text>
    <Card className={styles.comment_card}>
      <Stack gap="xs">
        <Blockquote
          classNames={{
            icon: styles.comment_quoteicon,
            root: styles.comment_quoteinner,
          }}
          color="yellow"
          className={styles.comment_quote}
        >
          <Text lineClamp={1}>Polaroid +1 mlkshk VHS mixtape</Text>
        </Blockquote>
        <div className={styles.comment_contents}>
          <div className={styles.comment_byline}>
            <Avatar src={null} alt="Mel Thomas" radius="xl" size="sm"></Avatar>
            <div className={styles.comment_text}>
              <span className={styles.comment_author}>mel:</span>
              <span>vice sartorial lyft</span>
            </div>
          </div>
          <Space h="xs" />
          <Anchor size="sm" className={styles.comment_link}>
            Jump to comment
          </Anchor>
        </div>
      </Stack>
    </Card>
  </>
)
