import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import { useRoute } from 'wouter'
import { UnstyledButton, Stack, Tooltip, Space } from '@mantine/core'
import { useMst } from '@state'
import { NavAnchor } from '@components/NavAnchor'
import { UserMenu } from '@components/Sidebar/UserMenu'
import { HelpMenu } from '@components/Sidebar/HelpMenu'
import { ExplorerViewType } from '@state/types'
import { ROUTE_PATTERNS } from '@util/pathConfigs'

import Logo from '@assets/images/logomark.svg'
import styles from './ExplorerSidestrip.module.scss'

const configs: Record<
  ExplorerViewType,
  {
    tooltip: string
    icon: string
  }
> = {
  browser: {
    tooltip: 'Folders and documents',
    icon: 'fas fa-folder',
  },
  outline: {
    tooltip: 'Outline',
    icon: 'fas fa-list',
  },
  history: {
    tooltip: 'Snapshot history',
    icon: 'fas fa-clock-rotate-left',
  },
  search: {
    tooltip: 'Search',
    icon: 'fas fa-magnifying-glass',
  },
}

const SidestripButton = ({
  view,
  active,
  disabled,
  onClick,
}: {
  view: ExplorerViewType
  active: boolean
  onClick: (view: ExplorerViewType) => void
  disabled?: boolean
}) => (
  <Tooltip label={configs[view].tooltip} position="right" withinPortal>
    <UnstyledButton
      disabled={disabled}
      className={cn(styles.explorerButtons_button, {
        [styles.explorerButtons_button__selected]: active,
      })}
      onClick={() => onClick(view)}
    >
      <i className={configs[view].icon}></i>
    </UnstyledButton>
  </Tooltip>
)

export const ExplorerSidestrip = observer(function ExplorerSidestrip({
  currentView,
  onSelectView,
  enabledViews,
}: {
  currentView?: ExplorerViewType
  onSelectView: (view: ExplorerViewType) => void
  enabledViews: ExplorerViewType[]
}) {
  const { currentScript } = useMst()
  const [isEditorRoute] = useRoute(ROUTE_PATTERNS.scriptEditor)
  const showShortcuts = isEditorRoute && !currentScript?.isInk

  return (
    <Stack justify="space-between" className={styles.explorerButtons}>
      <Stack w="100%">
        <Stack w="100%" gap={0}>
          <Tooltip label="Go to Dashboard" position="right" withinPortal>
            <div>
              <NavAnchor href="/" className={styles.explorerButtons_logo}>
                <img src={Logo} />
              </NavAnchor>
            </div>
          </Tooltip>
          <Space h={4} />
          {enabledViews.map((view) => (
            <SidestripButton
              view={view}
              active={currentView === view}
              onClick={onSelectView}
              key={view}
            />
          ))}
        </Stack>
      </Stack>
      <Stack align="center">
        <HelpMenu showShortcuts={showShortcuts} />
        <UserMenu />
        <Space h={5} />
      </Stack>
    </Stack>
  )
})
