import { useWindowSize } from 'usehooks-ts'
import { ButtonMode } from '@state/types'

// this is when the buttons collapse to mini
const BUTTON_BREAKPOINT = 900
const TAB_BREAKPOINT = 650

export function useToolbarBreakpoints(): {
  buttonMode: ButtonMode
  tabMode: ButtonMode
} {
  const { width } = useWindowSize()
  const buttonMode: ButtonMode = width < BUTTON_BREAKPOINT ? 'mini' : 'normal'
  const tabMode: ButtonMode = width < TAB_BREAKPOINT ? 'mini' : 'normal'
  return {
    buttonMode,
    tabMode,
  }
}
