/*
  When we're using an <a href> to navigate WITHIN the app, we need to
  wrap it in a wouter Link to prevent the default html behavior of reloading
  the page.

  This component will work/look just like <Anchor> but requires an href that
  starts with / and cannot accept a target prop
*/
import React from 'react'
import { Link } from 'wouter'
import { Anchor, AnchorProps } from '@mantine/core'

export type NavAnchorHref = `/${string}`

interface ComponentProps extends React.ComponentPropsWithoutRef<'a'> {}
type NavAnchorProps = Omit<ComponentProps, 'target'> &
  AnchorProps & {
    href: NavAnchorHref
  }

export const NavAnchor = (props: NavAnchorProps) => {
  const { href, ...passthrough } = props
  return (
    <Link href={href}>
      <Anchor {...passthrough} />
    </Link>
  )
}
