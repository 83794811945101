import { FormatUtils, schema } from '@showrunner/codex'
import { DOMSerializer } from 'prosemirror-model'
import { PageMarginSizes, PageLayout, IPrincePrintStrategy } from './types'
import { ScriptPrintPreferences } from '../LocalPersistence'
import { HeaderAndFooter } from './HeaderAndFooter'
import { TwoColumnScript } from '@components/TwoColumnScript'
import { buildPageCssVars } from './buildPageCssVars'
import { renderToString } from 'react-dom/server'
import { ScriptSnapshotPayload } from '../ScriptoApiClient/types'
import * as cssStrings from '@util/printing/cssStrings'
import { getPrintTimestamp } from '@util'
import * as constants from './constants'
import { buildHtmlForPrince } from './buildHtmlForPrince'

type ScriptPrintStrategyParams = {
  snapshot: ScriptSnapshotPayload
  prefs: ScriptPrintPreferences
  timestamp: string
  title: string
}

type AsteriskPrintStrategyParmas = {
  html: string
} & ScriptPrintStrategyParams

const generateOneColumnStyles = ({
  monochrome,
  blocks,
}: {
  monochrome: boolean
  blocks: FormatUtils.BlockConfiguration
}) => `
${cssStrings.editorCSS}
${FormatUtils.createPrintStylesheet(blocks, {
  monochrome,
})}
${cssStrings.oneColumnCSS}
`

// This abstract class is the common base for printing 1 or 2 column scripts and should contain
// any state or logic that is common across both
abstract class BaseScriptPrintStrategy implements IPrincePrintStrategy {
  protected snapshot: ScriptSnapshotPayload
  protected prefs: ScriptPrintPreferences
  protected title: string
  protected timestamp: string

  constructor({
    snapshot,
    prefs,
    title,
    timestamp,
  }: ScriptPrintStrategyParams) {
    this.snapshot = snapshot
    this.prefs = prefs
    this.timestamp = timestamp
    this.title = title
  }

  // The One and Two-column strategies are responsible for implementing these
  // measurements for the margins
  abstract margins: PageMarginSizes
  // any styles specific to the print format
  abstract generateFormatSpecificStyles(): string
  // the HTML that goes into the body
  abstract generateBody(): string

  generateHeadElements(): string {
    return constants.FONTS
  }

  // this combines styles common to all script print html with
  // the format-specific ones provided by the subclass
  generateStyles() {
    return `
      ${
        this.prefs.headers.showOnFirstPage
          ? ''
          : constants.HIDE_HEADERS_ON_FIRST_PAGE
      }
      ${
        this.prefs.footers.showOnFirstPage
          ? ''
          : constants.HIDE_FOOTERS_ON_FIRST_PAGE
      }
      ${buildPageCssVars({
        layout: this.generatePageLayout(),
        title: this.title,
        timestamp: this.timestamp,
        monochrome: this.prefs.monochrome,
      })}
      ${cssStrings.headerFooterCSS}
      ${this.generateFormatSpecificStyles()}
    `
  }

  generatePageLayout(): PageLayout {
    return {
      size: { height: '11in', width: '8.5in' },
      margins: this.margins,
      orientation: 'portrait',
    }
  }

  generateHeaderAndFooter() {
    return renderToString(
      <HeaderAndFooter
        header={this.prefs.headers}
        footer={this.prefs.footers}
      />
    )
  }
}

class OneColumnPrintStrategy extends BaseScriptPrintStrategy {
  constructor(params: ScriptPrintStrategyParams) {
    super(params)
  }

  margins = constants.ONE_COLUMN_MARGIN_SIZE

  generateFormatSpecificStyles() {
    return generateOneColumnStyles({
      monochrome: this.prefs.monochrome,
      blocks: this.snapshot.scriptFormat.definition.blocks,
    })
  }

  generateBody() {
    const domSerializer = DOMSerializer.fromSchema(schema)
    const doc = schema.nodeFromJSON(this.snapshot.doc)
    const docFragment = domSerializer.serializeFragment(doc.content)
    const serializer = new XMLSerializer()

    return `
      <div contenteditable="false" translate="no" class="ProseMirror is-static">
        ${serializer.serializeToString(docFragment)}
      </div>
    `
  }
}

class TwoColumnPrintStrategy extends BaseScriptPrintStrategy {
  constructor(params: ScriptPrintStrategyParams) {
    super(params)
  }

  margins = constants.TWO_COLUMN_MARGIN_SIZE

  generateFormatSpecificStyles() {
    return cssStrings.twoColumnCSS
  }

  generateBody() {
    return renderToString(
      <TwoColumnScript
        prefs={this.prefs}
        scriptJson={this.snapshot.doc}
        config={this.snapshot.scriptFormat.definition}
      />
    )
  }
}

class OneColumnAsteriskPrintStrategy extends BaseScriptPrintStrategy {
  protected html: string

  constructor(params: AsteriskPrintStrategyParmas) {
    super(params)
    this.html = params.html
  }

  margins = constants.ONE_COLUMN_MARGIN_SIZE

  generateFormatSpecificStyles() {
    return generateOneColumnStyles({
      monochrome: this.prefs.monochrome,
      blocks: this.snapshot.scriptFormat.definition.blocks,
    })
  }

  generateBody() {
    return this.html
  }
}

export const buildPrintableScriptHtml = (
  params: Omit<ScriptPrintStrategyParams, 'timestamp'>
): string => {
  const shouldPrintTwoColumn =
    params.prefs.columns && params.snapshot.doc.attrs.docType !== 'screenplay'
  const timestamp = getPrintTimestamp()

  const strategyParams = { ...params, timestamp }
  const strategy: IPrincePrintStrategy = shouldPrintTwoColumn
    ? new TwoColumnPrintStrategy(strategyParams)
    : new OneColumnPrintStrategy(strategyParams)

  return buildHtmlForPrince(strategy)
}

export const buildPrintableRevisionHtml = (
  params: Omit<ScriptPrintStrategyParams, 'timestamp'> & { html: string }
): string => {
  const strategy = new OneColumnAsteriskPrintStrategy({
    ...params,
    timestamp: getPrintTimestamp(),
  })
  return buildHtmlForPrince(strategy)
}
