// couple of helpers to get around circular references.
// The gist is to give state tree nodes a way to get a typed reference to the rootnode
// and its environment which are globally available.
import { getEnv, getRoot, IAnyStateTreeNode } from 'mobx-state-tree'
import type { Environment, IListing, IRoot } from '../types'
import { SAME_TIME_DIFF_IN_SECONDS } from '@hooks'

export const getEnvironment = (node: IAnyStateTreeNode) =>
  getEnv<Environment>(node)

export const getRootInstance = (node: IAnyStateTreeNode) => getRoot<IRoot>(node)

// we don't want to sort by the ACTUAL time difference if two
// value are super close in time or when docs are being edited
// rapidly, they flap in the document list. So, if the difference is within
// SAME_TIME_DIFF_IN_SECONDS of each other, treat them the same
const areVeryCloseInTime = (a: Date, b: Date): boolean => {
  const diff = a.valueOf() - b.valueOf()
  return Math.abs(diff) < SAME_TIME_DIFF_IN_SECONDS * 1000
}

// if two items are close together, preserve the current sort order
// when sorting
export const sortRecentFn = (a: IListing, b: IListing): number => {
  if (areVeryCloseInTime(a.contentsModifiedAt, b.contentsModifiedAt)) {
    return 0
  }
  return b.contentsModifiedAt.valueOf() - a.contentsModifiedAt.valueOf()
}

export const sortAlphaByNameFn = (a: IListing, b: IListing) =>
  a.name.localeCompare(b.name)

export const sortByCreationFn = (a: IListing, b: IListing) =>
  (b.createdAt?.valueOf() ?? 1) - (a.createdAt?.valueOf() ?? 1)
