import { observer } from 'mobx-react-lite'
import { IRundown } from '@state/types'
import { useMst } from '@state'
import { RundownGrid } from '@components/RundownGrid'
import { RundownToolbar } from '@components/RundownToolbar'
import { RundownStatusbar } from '@components/RundownStatusbar'
import { Spinner } from '@components/Spinner'
import { FloatingMenuProvider } from '@hooks'

import styles from './RundownEditor.module.scss'
import { RundownHeader } from '@components/MiniHeader/RundownHeader'

/*
  This component has the rundown grid inside it (RundownGrid)
*/
export const RundownEditor = observer(function RundownEditor({
  rundown,
}: {
  rundown: IRundown
}) {
  const { user } = useMst()

  return (
    <FloatingMenuProvider>
      <div className={styles.rundownEditor}>
        {rundown.gridIsLoading && <Spinner delayMs={300} />}
        <RundownHeader />
        <RundownToolbar rundown={rundown} />
        <div className={styles.rundownEditor_gridWrapper}>
          {/* we pass a css class to the rundown grid to set its size, border & any positioning we want */}
          <RundownGrid
            rundown={rundown}
            className={styles.rundownEditor_grid}
            userCanEdit={user.canEditRundowns}
          />
        </div>
        <RundownStatusbar rundown={rundown} />
      </div>
    </FloatingMenuProvider>
  )
})
