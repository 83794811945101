import React from 'react'
import { ButtonProps } from '@mantine/core'
import { FaIcon } from '@components/FaIcon'
import { StatusBarButton } from './StatusBarButton'

type StatusBarFaActionIconProps = {
  icon: `fa-${string}`
  tooltip?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  btnVariant?: ButtonProps['variant']
  dangerous?: boolean
}

export const StatusBarFaActionIcon = React.forwardRef<
  HTMLButtonElement,
  StatusBarFaActionIconProps
>(({ icon, tooltip, onClick, dangerous = false }, ref) => (
  <StatusBarButton ref={ref} tooltip={tooltip} onClick={onClick}>
    <FaIcon icon={icon} color={dangerous ? 'red' : 'dimmed'} />
  </StatusBarButton>
))

StatusBarFaActionIcon.displayName = 'StatusBarFaActionIcon'
