/*
  Duration values are stored in the database as an integer value of seconds
  and they're displayed in the grid in one of two ways:

  For duration and cumululativeDuration column types, they're shown like you'd
  see on a digital clock-- e.g. 2:34 is 2 minutes and 34 seconds (in the database
  its 154)

  For front and back timing, the accrued duration is applied to a known start/end
  time which is displayed as a date in the grid. The rundown-wide value for start-time
  is stored in the database as a Date but the front/back timing are not stored as they
  are computed.

  When copying/pasting these types of values, we want to capture a string representation
  that makes sense if you're pasting into a spreadsheet but one that also works if
  you paste into our grid.

  This is achieved with a combination of these AgGrid helpers:
  * valueGetters: look at the stored values in the database and figure out a new raw
    value to show in the grid. In cumulative duration this gives us the sum
    of all the durations for this row and above. In front/back, this puts together a
    Date
  * valueFormatters: These are used to transform the integer and Date values when displaying
    the value in ag-grid. This is what makes 91 into 1:31 or turns a raw Date object into
    a formatted start time.
  * valueParser: if you paste 1:23 into a duration field, this decodes that to mean 83 seconds
  * exporters: When we copy to the clipboard or (in the future) export values,
    we'd normally just get a string-- so a duration of 83 would turn into '83'. The
    exporters are where we change the result of a valueGetter into one useful for OTHER
    software (similar to valueFormatters but for external consumption)
*/

import * as AgGrid from '@ag-grid-community/core'
import { addSeconds } from 'date-fns'
import { util } from '@showrunner/codex'
import { RundownRowData } from '@util/ScriptoApiClient/types'
import { IRundown } from '@state/types'
import { showError } from '@components/Modals'
import { formatSeconds } from '@util'
import { getBlobValue, sumColumnValue } from '@util/rundowns'

export const durationFormatter: AgGrid.ValueFormatterFunc = ({ value }) => {
  return typeof value !== 'number' ? '' : formatSeconds(value)
}

export const durationExporter = (value: unknown) => {
  if (typeof value === 'number') {
    return formatSeconds(value)
  }
  return value
}

const filterRowData = ({
  allRows,
  currentRow,
  type,
}: {
  allRows: RundownRowData[]
  currentRow: RundownRowData
  type: 'cumulative' | 'front-timing' | 'back-timing'
}): RundownRowData[] => {
  if (type === 'cumulative') {
    return allRows.filter((r) => r.sequence <= currentRow.sequence)
  }
  if (type === 'front-timing') {
    return allRows.filter((r) => r.sequence < currentRow.sequence)
  }
  if (type === 'back-timing') {
    return allRows.filter((r) => r.sequence >= currentRow.sequence)
  }
  return []
}

const sumDurations = (
  params: AgGrid.ValueGetterParams<RundownRowData>,
  type: 'cumulative' | 'front-timing' | 'back-timing'
) => {
  const { data, colDef, context } = params
  const rundown = context as IRundown
  const field = colDef.field
  const thisRowValue = getBlobValue({ data, field })
  if (data && field && typeof thisRowValue === 'number') {
    const rowData = filterRowData({
      allRows: rundown.immutableRowData,
      currentRow: data,
      type,
    })
    return sumColumnValue({ rowData, field })
  }
}

export const cumulativeGetter: AgGrid.ValueGetterFunc<RundownRowData> = (
  params
) => sumDurations(params, 'cumulative')

export const frontTimingGetter = (
  params: AgGrid.ValueGetterParams<RundownRowData>
): Date | undefined => {
  const { context } = params
  const rundown = context as IRundown
  const { startTime } = rundown.blobData
  if (startTime) {
    const elapsedTime = sumDurations(params, 'front-timing')
    if (typeof elapsedTime === 'number') {
      return addSeconds(startTime, elapsedTime)
    }
  }
}

export const backTimingGetter = (
  params: AgGrid.ValueGetterParams<RundownRowData>
): Date | undefined => {
  const { context } = params
  const rundown = context as IRundown
  const { endTime } = rundown.blobData
  if (endTime) {
    const remainingTime = sumDurations(params, 'back-timing')
    if (typeof remainingTime === 'number') {
      return addSeconds(endTime, -1 * remainingTime)
    }
  }
}

export const timeFormatter: AgGrid.ValueFormatterFunc<RundownRowData> = (
  params
) => {
  if (params.value instanceof Date) {
    return params.value.toLocaleTimeString()
  }
  return ''
}

// When parsing duration inputs, invalid values are rejected
// and an error message is shown. Valid inputs are converted to
// non-negative integers or null (when clearing out the cell)
export const durationParser: AgGrid.ValueParserFunc = ({
  newValue,
  oldValue,
}) => {
  const newValueString = String(newValue).trim()
  // return null to indicate a deleted value
  if (newValueString.length === 0) {
    return null
  }
  // ensure the new value can be interpreted as an integer using our
  // timing string parsing rules
  const parsed = util.parseTimingString(newValue)
  if (isNaN(parsed)) {
    showError({
      title: 'Invalid Duration',
      message: `Sorry! Scripto doesn’t recognize “${newValueString}” as a duration.`,
    })
    // ag-grid bug. If the oldValue wasn't set we need to convert undefined
    // to null or we get an error
    return oldValue ?? null
  }
  return parsed
}
