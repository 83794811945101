import { TextSelection } from 'prosemirror-state'
import { schema, types } from '@showrunner/codex'
import {
  SCRIPT_COMMENT_DELETED,
  SCRIPT_COMMENT_EDITED,
  SCRIPT_COMMENT_RESOLVED,
  SCRIPT_COMMENT_CREATED,
} from '@util/mixpanel/eventNames'
import { launchScriptToast } from '@components/Toast'
import { EDITOR_EVENTS } from '@state/models/SocketManager/types'
import { selectCommentById, selectReplyById } from './state-selectors.js'
import { commentsKey } from './plugin-key.js'

// index of all user actions for comments plugin.
// actions receive params and modify state (reducer pattern).
// state is mutable (choo pattern).
// actions can be sync or async (be careful!).
// after action runs view is rerendered by default.
// can stop rerendering by returning false.
// each action is bound to actions object and has access to:
// - state
// - emit
// - rerender
// WARNING: do NOT use fat arrows for action function definition, breaks binding
// sync actions
// ------------
/**
 * dispatches a transaction with metadata only to give PM a chance to
 * stop decorating a pending comment when it is aborted
 * @param {EditorView} - PM EditorView
 */
function dispatchMetaTransaction(editorView) {
  const { tr } = editorView.state
  tr.setMeta(commentsKey, {
    message: 'forced-update-for-comment',
  })
  editorView.dispatch(tr)
}
/**
 * toggles visibility of comment container
 * @param {array} [commentIds=null] - array of comment IDs in selected block
 * @param {number} [top=null] - top position of block
 */
function toggleComments(commentIds = null, top = null) {
  // reset comments and editing either way
  this.state.comments = []
  this.state.isEditing = null
  // if comments are being toggled on and the user
  // was in the middle of composing a new comment
  // we need to reset local state AND dispatch a transaction
  // to clear the associated decoration
  if (top && this.state.isAdding) {
    this.state.isAdding = null
    dispatchMetaTransaction(this.state.editorView)
  }
  // close if called without params or if current top value is same
  if (top == null || this.state.top === top) {
    Object.assign(this.state, { commentIds: null, top: null })
  } else {
    /*
      avoid passing along malformed comment attributes
      that TDS has been able to sneak into the DB
    {
      "type": "comment",
      "attrs": {  "id": "e-comment-e-comment-a0673144..." }
    */
    if (commentIds != null) {
      commentIds = commentIds.map((id) => id.replace(/e-comment-/g, ''))
    }
    Object.assign(this.state, { commentIds, top })
    // kick off API call
    this.getComments(commentIds)
  }
}
function startEditing(commentId) {
  // allow toggle
  if (this.state.isEditing === commentId) {
    this.state.isEditing = null
    this.rerender()
    return false
  }
  this.state.isEditing = commentId
  this.rerender()
  // give the textarea focus AND highlight existing text
  document.querySelector('#comment-edit-form textarea').select()
  return false
}
function cancelEdit() {
  this.state.isEditing = null
}
function cancelNewCommentEdit() {
  this.state.isAdding = null
  this.state.top = null
  this.state.comments = []
  this.resetSelection()
}
function addCommentMark(id) {
  const { editorView } = this.state
  const mark = schema.marks[types.COMMENT].create({ id })
  // get the current selection to get any mapped changes
  const { anchor, from, to } = editorView.state.selection
  const { tr } = this.state.editorView.state
  // itd be more correct to preserve the selection when the new comment is submitted.
  // ie. TextSelection.create(tr.doc, from, to)
  // but this doesnt cause the TextFormatMenu reappear immediately
  // to make things worse, if the users subsequent action is to click on the element menu
  // or a comment count icon *then* the TextElementMenu reappears distractingly
  //
  // replacing the selection with a plain old cursor is a bit of a hack, but it makes
  // the behavior consistent with cancelling a pending new comment and
  // we are planning on taking another pass to allow intermediate selections anyway.
  tr.setMeta('addToHistory', false)
    .addMark(from, to, mark)
    .setSelection(TextSelection.create(tr.doc, anchor))
  editorView.dispatch(tr)
  editorView.focus()
}
function removeCommentMark(id) {
  const { tr, doc } = this.state.editorView.state
  const size = doc.nodeSize - 2
  const mark = schema.marks[types.COMMENT].create({ id })
  tr.removeMark(0, size, mark)
  tr.setMeta('addToHistory', false)
  this.state.editorView.dispatch(tr)
  // modifies doc not comment view so skip rerender
  return false
}
function resetSelection() {
  const { editorView } = this.state
  const { from, to } = editorView.state.selection
  const { tr } = this.state.editorView.state
  // the TextFormatMenu menu doesnt reappear for the selection if Esc was used
  // but i can live with that
  tr.setSelection(TextSelection.create(tr.doc, from, to))
  editorView.dispatch(tr)
  editorView.focus()
  // modifies doc not comment view so skip rerender
  return false
}
function setTotalComments(num) {
  // cache to avoid over-reporting
  if (num === this.state.totalComments) {
    return false
  }
  this.state.totalComments = num
  this.emit('editor:setScript', { totalComments: num })
  return false
}
// async actions
// -------------
// BEWARE: SIDE-EFFECTS!!
function getComments(commentIds) {
  this.apiClient
    .getCommentThreads({
      scriptId: this.state.scriptId,
      commentIds,
    })
    .then((comments) => {
      // async side effect
      this.state.comments = comments
      this.rerender() // force async rerender
    })
    .catch((err) => {
      console.error('Failed to get comments', err)
      launchScriptToast({
        type: 'error',
        message: 'Unexpected API Error! Unable to retrieve comments.',
      })
    })
  // return false to prevent rerender
  return false
}
function getCommentCounts() {
  // we used to pass along commentIds in the request, but that
  // is too unwieldy in scripts with 200+ comment threads
  this.apiClient
    .getCommentCounts({
      scriptId: this.state.scriptId,
    })
    .then((counts) => {
      if (this.state.commentCounts == null) {
        this.state.commentCounts = counts

        // 0 + 1 + 2 + 3 + 4
        const totalComments = Object.values(counts).reduce(
          (prev, curr) => prev + curr,
          0 // initial
        )
        this.state.totalComments = totalComments
        this.emit('editor:setScript', { totalComments })
      } else {
        // merge if there's preexisting data
        Object.assign(this.state.commentCounts, counts)
      }
      this.rerender() // force async rerender to show counts
    })
    .catch((err) => {
      console.error('Failed to get comment counts', err)
      launchScriptToast({
        type: 'error',
        message: 'Unexpected API Error! Unable to retrieve comment counts.',
      })
    })
  // dont rerender until after the promise resolves
  return false
}
function addComment(event) {
  event.preventDefault()
  const { value = '' } = event.target.parentElement.querySelector(
    '.c-comment__content'
  )
  const text = value.trim()
  // to avoid duplicate submissions
  if (this.state.isSubmitting || text === '') {
    return
  }
  this.state.isSubmitting = true
  this.apiClient
    .createComment({
      scriptId: this.state.scriptId,
      text,
      snippet: this.state.snippet,
    })
    .then((comment) => {
      this.addCommentMark(comment.id)
      // addCommentReply does something tricky with resetting form values here, but i dont *think* its relevant in this case.
      // reset
      this.cancelNewCommentEdit()
    })
    .catch((err) => {
      console.error('Failed to create comment', err)
      launchScriptToast({
        type: 'error',
        message: 'Unexpected API Error! Unable to create comment.',
      })
    })
    .finally(() => {
      // we reset the flag regardless of outcome
      this.state.isSubmitting = false
      this.rerender()
    })

  this.emit('analytics:track', {
    name: SCRIPT_COMMENT_CREATED,
    opts: {
      scriptId: this.state.scriptId,
    },
  })
  return false
}
function addCommentReply(event, parentId) {
  event.preventDefault()
  const { value = '' } = event.target.parentElement.querySelector(
    '.c-comment__content'
  )
  const text = value.trim()
  // to avoid duplicate submissions
  if (this.state.isSubmitting || text === '') {
    return
  }
  this.state.isSubmitting = true
  this.apiClient
    .createComment({
      scriptId: this.state.scriptId,
      parentId,
      text,
    })
    .then((comment) => {
      comment.creator = {
        name: this.state.user.name,
        avatar: this.state.user.avatar,
      }
      const parentComment = selectCommentById(this.state, parentId)
      parentComment.replies.push(comment)
      // reset form ONLY on successful POST,
      // so if there's a failure user doesn't lose what they wrote
      const form = document.querySelector('#comment-reply-form')
      form.querySelector('textarea').value = ''
      form.querySelector('label').dataset.value = ''
    })
    .catch((err) => {
      console.error('Failed to create comment', err)
      launchScriptToast({
        type: 'error',
        message: 'Unexpected API Error! Unable to create comment.',
      })
    })
    .finally(() => {
      // we reset the flag regardless of outcome
      this.state.isSubmitting = false
      this.rerender()
    })

  this.emit('analytics:track', {
    name: SCRIPT_COMMENT_CREATED,
    opts: {
      scriptId: this.state.scriptId,
    },
  })
  return false
}
function editComment(event, commentId) {
  const { value = '' } = event.target.parentElement.querySelector(
    '.c-comment__content'
  )
  const text = value.trim()
  // to avoid duplicate submissions we toggle a flag regardless of outcome
  if (this.state.isSubmitting || text === '') {
    return
  }
  this.state.isSubmitting = true
  this.apiClient
    .editComment({
      scriptId: this.state.scriptId,
      commentId,
      text,
    })
    .then((updatedComment) => {
      // update the corresponding value locally instead of re-fetching
      for (const comment of this.state.comments) {
        if (comment.id === this.state.isEditing) {
          comment.text = updatedComment.text
          break
        }
        if (comment.replies) {
          for (const reply of comment.replies) {
            if (reply.id === this.state.isEditing) {
              reply.text = updatedComment.text
              break
            }
          }
        }
      }
      // only reset `isEditing` on success
      this.state.isEditing = null
    })
    .catch((err) => {
      console.error('Failed to update comment', err)
      launchScriptToast({
        type: 'error',
        message: 'Unexpected API Error! Unable to update comment.',
      })
    })
    .finally(() => {
      // we reset the flag regardless of outcome
      this.state.isSubmitting = false
      this.rerender()
    })
  this.emit('analytics:track', {
    name: SCRIPT_COMMENT_EDITED,
    opts: {
      scriptId: this.state.scriptId,
    },
  })
  return false
}
function resolveComment(commentId) {
  this.emit('analytics:track', {
    name: SCRIPT_COMMENT_RESOLVED,
    opts: {
      scriptId: this.state.scriptId,
    },
  })
  this.apiClient
    .resolveComment({
      scriptId: this.state.scriptId,
      commentId,
    })
    .then((data) => {
      if (data.status === 'success') {
        const { tr, doc } = this.state.editorView.state
        const size = doc.nodeSize - 2
        const unresolvedMark = schema.marks[types.COMMENT].create({
          id: commentId,
          resolved: false,
        })
        const removed = tr.removeMark(0, size, unresolvedMark)
        if (removed.steps.length) {
          removed.steps.forEach((step) => {
            const resolvedMark = schema.marks[types.COMMENT].create({
              id: commentId,
              resolved: true,
            })
            tr.addMark(step.from, step.to, resolvedMark)
          })
          tr.setMeta('addToHistory', false)
          this.state.editorView.dispatch(tr)
        }
      } else {
        console.error('Failed to resolve comment', data)
        launchScriptToast({
          type: 'error',
          message: 'Unexpected API Error! Unable to resolve comment.',
        })
      }
    })
    .catch((err) => {
      console.error('Failed to resolve comment', err)
      launchScriptToast({
        type: 'error',
        message: 'Unexpected API Error! Unable to resolve comment.',
      })
    })
  // return false to prevent rerender
  return false
}
function deleteComment(commentId, parentId) {
  this.emit('analytics:track', {
    name: SCRIPT_COMMENT_DELETED,
    opts: {
      scriptId: this.state.scriptId,
    },
  })
  this.apiClient
    .deleteComment({
      scriptId: this.state.scriptId,
      commentId,
    })
    .then((data) => {
      // comment is a reply
      if (parentId) {
        if (data.parentDeleted && data.replyCount === 0) {
          this.removeCommentMark(parentId)
        }
      } else {
        // comment has replies
        if (data.replyCount) {
          const comment = selectCommentById(this.state, commentId)
          comment.text = null
          this.rerender()
        } else {
          this.removeCommentMark(commentId)
        }
      }
    })
    .catch((err) => {
      console.error('Failed to delete comment', err)
      launchScriptToast({
        type: 'error',
        message: 'Unexpected API Error! Unable to delete comment.',
      })
    })
  return false
}
// socket handlers
function onSocketEvent(data) {
  switch (data.eventType) {
    case EDITOR_EVENTS.COMMENT_ADDED:
      return this.onCommentAdded(data)
    case EDITOR_EVENTS.COMMENT_DELETED:
      return this.onCommentDeleted(data)
    case EDITOR_EVENTS.COMMENT_RESOLVED:
      return this.onCommentResolved(data)
    case EDITOR_EVENTS.COMMENT_UNRESOLVED:
      return this.onCommentUnresolved(data)
    case EDITOR_EVENTS.COMMENT_UPDATED:
      return this.onCommentUpdated(data)
    default:
      console.error('unhandled socket event', data)
  }
  return false
}
function onCommentAdded(data) {
  const { commentId, parentId } = data
  if (parentId) {
    if (
      this.state.commentCounts &&
      this.state.commentCounts[parentId] != null
    ) {
      this.state.commentCounts[parentId] += 1
    }
    // if comment's not open, rerender count without requesting reply data
    const parentComment = selectCommentById(this.state, parentId)
    if (!parentComment) {
      return true
    }
    this.apiClient
      .getComment({
        scriptId: this.state.scriptId,
        commentId,
      })
      .then((comment) => {
        const parentComment = selectCommentById(this.state, parentId)
        if (!parentComment) {
          return
        }
        // check first that it's not already in there (race condition?)
        const exists = parentComment.replies.find(
          (reply) => reply.id === commentId
        )
        if (exists) {
          return
        }
        // add reply to active parent comment
        parentComment.replies.push(comment)
        this.rerender()
      })
    return false
  } else {
    if (
      this.state.commentCounts &&
      this.state.commentCounts[commentId] == null
    ) {
      this.state.commentCounts[commentId] = 1
    }
  }
}
function onCommentDeleted(data) {
  const { commentId, parentId, replyCount, parentDeleted } = data
  if (parentId) {
    // update count
    if (
      this.state.commentCounts &&
      this.state.commentCounts[parentId] != null
    ) {
      this.state.commentCounts[parentId] -= 1
    }
    // exit if parent comment not active
    const parentComment = selectCommentById(this.state, parentId)
    if (!parentComment) {
      return false
    }
    // remove parent if needed
    if (parentDeleted && replyCount === 0) {
      this.state.comments = filterComments(this.state, parentId)
      if (this.state.comments.length === 0) {
        this.state.top = null
      }
    } else {
      // remove reply from parent comment
      parentComment.replies = parentComment.replies.filter(
        (reply) => reply.id !== commentId
      )
    }
  } else {
    const comment = selectCommentById(this.state, commentId)
    // deleted comment not active, exit w/o rerender
    if (!comment) {
      return false
    }
    if (replyCount === 0) {
      // no replies, remove from list
      this.state.comments = filterComments(this.state, commentId)
      if (this.state.comments.length === 0) {
        this.state.top = null
      }
    } else {
      // has replies, remove text
      comment.text = null
      // update count
      if (
        this.state.commentCounts &&
        this.state.commentCounts[commentId] != null
      ) {
        this.state.commentCounts[commentId] -= 1
      }
    }
  }
}
function onCommentResolved(data) {
  const { commentId } = data
  // resolved comment not active, exit w/o rerender
  if (!selectCommentById(this.state, commentId)) {
    return false
  }
  this.state.comments = filterComments(this.state, commentId)
  if (this.state.comments.length === 0) {
    this.state.top = null
  }
}
function onCommentUnresolved(data) {
  const { commentId } = data
  // if count for id exists, correct # is already in memory.
  // if not, make async call to get count and defer render.
  if (this.state.commentCounts && this.state.commentCounts[commentId] == null) {
    this.getCommentCounts()
    return false
  }
}
function onCommentUpdated(data) {
  const { commentId, parentId } = data
  const comment = parentId
    ? selectReplyById(this.state, commentId)
    : selectCommentById(this.state, commentId)
  // updated comment not active, exit w/o rerender
  if (!comment) {
    return false
  }
  comment.text = data.text
}
function onCommentStarted(data) {
  this.state.top = data.top
  this.state.snippet = data.snippet
  this.state.isAdding = true
  this.state.comments = []
  // dispatch a transaction for decoration. thats it and thats all
  dispatchMetaTransaction(this.state.editorView)
  return true
}
// add all action functions here
const actions = {
  // sync
  toggleComments,
  startEditing,
  cancelEdit,
  cancelNewCommentEdit,
  addCommentMark,
  removeCommentMark,
  resetSelection,
  setTotalComments,
  // async
  getComments,
  getCommentCounts,
  addComment,
  addCommentReply,
  editComment,
  resolveComment,
  deleteComment,
  // sockets
  onSocketEvent,
  onCommentAdded,
  onCommentDeleted,
  onCommentResolved,
  onCommentUnresolved,
  onCommentUpdated,
  onCommentStarted,
}
/**
 * bind action handlers to comments state, view component, and app emitter
 * @param {object} state - comments state
 * @param {Nanocomponent} component - comments view component
 * @param {function} emit - app emitter
 * @return {object} bound actions
 */
export function bindActions(state, component, emit, appState) {
  const { apiClient } = appState.mst
  // attach state, emit, and rerender for all actions to access
  const boundActions = {
    state,
    emit,
    apiClient,
    rerender() {
      // protect against race condition:
      // check for element first in case it's been removed from DOM
      if (component.element) {
        component.rerender()
      }
    },
  }
  for (const [key, value] of Object.entries(actions)) {
    boundActions[key] = function (...args) {
      // bind function to actions object for access to siblings
      const action = value.bind(boundActions)
      const res = action(...args)
      // call rerender unless result of action is strictly false
      if (res !== false) {
        component.rerender()
      }
    }
  }
  return boundActions
}
// misc
function filterComments(state, commentId) {
  return state.comments.filter((comment) => comment.id !== commentId)
}
