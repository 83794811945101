import axios from 'axios'
import pkg from '../../package.json'

export type ParsedVersion = {
  major: number
  minor: number
  patch: number
}

const VERSION_REGEX = /^(?<major>\d+)\.(?<minor>\d+).(?<patch>\d+)$/

export const parseVersion = (value: unknown): ParsedVersion | null => {
  if (typeof value === 'string') {
    const reResult = VERSION_REGEX.exec(value.trim())
    if (reResult?.groups) {
      const { major, minor, patch } = reResult.groups
      return {
        major: parseInt(major),
        minor: parseInt(minor),
        patch: parseInt(patch),
      }
    }
  }
  return null
}

const packageVersion = parseVersion(pkg.version)

const fetchAvailableVersion = async (): Promise<{
  availableVersion: ParsedVersion | null
}> => {
  try {
    const result = await axios({
      method: 'GET',
      url: '/version.json',
    })
    if (result.data) {
      const { version } = result.data
      return {
        availableVersion: parseVersion(version),
      }
    }
  } catch {
    // noop
  }
  return { availableVersion: null }
}

// export to test
export const isCurrent = ({
  available,
  current,
}: {
  available: ParsedVersion
  current: ParsedVersion
}) => {
  if (current.major > available.major) {
    return true
  }
  if (current.major < available.major) {
    return false
  }
  if (current.minor > available.minor) {
    return true
  }
  if (current.minor < available.minor) {
    return false
  }
  return current.patch >= available.patch
}

export const isUpdateAvailable = async () => {
  const { availableVersion } = await fetchAvailableVersion()
  if (packageVersion && availableVersion) {
    return !isCurrent({
      available: availableVersion,
      current: packageVersion,
    })
  }
  return false
}
