import { Step } from 'prosemirror-transform'
import { ApiConfig } from './types'

type CreateStepsParams = {
  clientId: string
  scriptId: string
  steps: Step[]
  version: number
}

type CreateStepsPayload = { success: 'true'; version: number }

export const createSteps: ApiConfig<CreateStepsParams, CreateStepsPayload> = ({
  clientId,
  scriptId,
  steps,
  version,
}) => ({
  method: 'POST',
  url: `/scripts/${scriptId}/steps`,
  data: { clientId, steps, version },
})

type GetStepsParams = { scriptId: string; from: number }
export type GetStepsPayload = {
  clientId: string
  prose_script_id: string
  schemaVersion: string
  step: { [key: string]: JSONObject }
  version: number
}[]

export const getSteps: ApiConfig<GetStepsParams, GetStepsPayload> = ({
  scriptId,
  from,
}) => ({
  method: 'GET',
  url: `/scripts/${scriptId}/steps?from=${from}`,
})
