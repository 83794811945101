/*
  Modal for a yes/no situation
*/
import NiceModal from '@ebay/nice-modal-react'
import { MantineSize } from '@mantine/core'
import { ModalShell } from './ModalShell'
import { useModalControls } from './useModalControls'

type ConfirmModalProps = {
  title: string
  children: React.ReactNode
  onConfirm: () => void
  confirmLabel?: string
  cancelLabel?: string
  dangerous?: boolean
  size?: MantineSize
}
const ConfirmInternal = (props: ConfirmModalProps) => {
  const controls = useModalControls()
  const {
    onConfirm,
    children,
    size = 'md',
    confirmLabel = 'OK',
    cancelLabel = 'Cancel',
    ...passThroughProps
  } = props

  const handleConfirm = () => {
    onConfirm()
    controls.onClose()
  }

  return (
    <ModalShell
      {...passThroughProps}
      confirmLabel={confirmLabel}
      cancelLabel={cancelLabel}
      size={size}
      onConfirm={handleConfirm}
      opened={controls.opened}
      onClose={controls.onClose}
    >
      {children}
    </ModalShell>
  )
}

const ConfirmModal = NiceModal.create<ConfirmModalProps>(ConfirmInternal)

export const showConfirmModal = (props: ConfirmModalProps) => {
  NiceModal.show(ConfirmModal, props)
}
