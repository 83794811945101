import React from 'react'
import { useMst } from '@state'
import { IFolder } from '@state/types'
import { showError } from '@components/Modals'
import { useNavigation } from './useNavigation'

export const useResourceCreation = (
  source: 'dashboard' | 'import' | 'explorer'
) => {
  const [isCreating, setIsCreating] = React.useState(false)
  const mst = useMst()
  const { navigateToRundown, navigateToScript } = useNavigation()

  const trackCreation = (
    folder: IFolder,
    type: 'screenplay' | 'rundown' | 'studio'
  ) => {
    const { currentOrg, analytics } = mst
    if (!currentOrg) {
      return
    }

    const accessLevel = folder.isPrivate ? 'private' : 'shared'
    const format =
      type === 'screenplay'
        ? currentOrg.screenplayFormat.id
        : type === 'studio'
        ? currentOrg.studioFormat.id
        : currentOrg.rundownSchemaName

    analytics.trackDocCreated({
      type,
      from: source,
      accessLevel,
      format,
    })
  }

  // opportunistically refresh current folder but
  // don't error if it fails
  const refreshFolder = (folderId: string) => {
    // eslint-disable-next-line no-console
    mst.refreshFolder(folderId).catch((e) => console.error(e))
  }

  const createScript = async ({
    docType,
    folder,
  }: {
    docType: 'screenplay' | 'studio'
    folder: IFolder
  }): Promise<void> => {
    if (!mst.currentOrg) {
      return
    }
    const folderId = folder.id
    setIsCreating(true)
    if (!mst.currentOrg) {
      return
    }
    try {
      await mst.doDebug()
      const { id } = await mst.currentOrg.createScript({
        docType,
        folderId,
      })
      trackCreation(folder, docType)
      navigateToScript(id)
      refreshFolder(folderId)
    } catch (e) {
      showError({ message: `Couldn't create new script` })
    } finally {
      setIsCreating(false)
    }
  }

  const createRundown = async (folder: IFolder) => {
    setIsCreating(true)
    try {
      await mst.doDebug()
      const { rundownId } = await folder.createRundown()
      trackCreation(folder, 'rundown')
      navigateToRundown(String(rundownId))
    } catch (e) {
      showError({
        message: "Couldn't create new rundown",
      })
    } finally {
      setIsCreating(false)
    }
  }

  const importScript = async ({
    file,
    folder,
  }: {
    file: File
    folder: IFolder
  }) => {
    setIsCreating(true)
    try {
      await mst.doDebug()
      const result = await folder.importScriptFromFdx(file)
      if (result) {
        trackCreation(folder, 'screenplay')
        refreshFolder(folder.id)
        navigateToScript(result.id)
      }
    } catch (e) {
      showError({
        message: `Couldn't import script from fdx file.`,
      })
    } finally {
      setIsCreating(false)
    }
  }

  return {
    isCreating,
    createScript,
    createRundown,
    importScript,
  }
}
