import { observer } from 'mobx-react-lite'
import { Button, Group } from '@mantine/core'
import { OrgTierMap } from '@showrunner/codex'
import { Toast } from '@components/Toast'
import { showUpgradeInquiryModal } from '@components/Modals'
import { showCreateInviteModal } from './CreateInviteModal'
import { useMst } from '@state'

import styles from './CreateInviteButton.module.scss'

export const CreateInviteButton = observer(function CreateInviteButton({
  inviteCount,
}: {
  inviteCount: number
}) {
  const { currentOrg } = useMst()
  if (!currentOrg) return <></>

  const canInvite = !(
    currentOrg.tier === OrgTierMap.FREE &&
    currentOrg.members.length + inviteCount >= 3
  )

  const showModal = () => {
    if (currentOrg) {
      showCreateInviteModal({ org: currentOrg })
    }
  }

  return canInvite ? (
    <Group justify="flex-end">
      <Button onClick={showModal}>
        <i className="fa fa-plus is-link-icon"></i>
        Invite New Member
      </Button>
    </Group>
  ) : (
    <Toast
      message={
        <>
          Your workspace is on the&nbsp;<strong>Free Plan</strong>&nbsp;and is
          limited to 3 members.
        </>
      }
      dismissable={false}
    >
      <Button
        className={styles.toast_button___neutral}
        variant="outline"
        onClick={() => showUpgradeInquiryModal(currentOrg)}
      >
        Request Upgrade
      </Button>
    </Toast>
  )
})
