import React from 'react'
import NiceModal from '@ebay/nice-modal-react'
import { Checkbox, Group, Text } from '@mantine/core'
import { ModalShell, useModalControls } from '@components/Modals'
import { useMst } from '@state'
import cursors from '../../assets/images/cursors.png'
import avatars from '../../assets/images/avatars.png'

import styles from './CollabPreferencesModal.module.scss'

export const CollabPreferences = () => {
  const controls = useModalControls()
  const { user, currentScript } = useMst()

  const [hideAvatars, setHideAvatars] = React.useState<boolean>(
    user.prefs.hideCollabAvatars ?? false
  )
  const [showCursors, setShowCursors] = React.useState(
    user.prefs.showCollabCursors ?? false
  )

  const togglePrefs = () => {
    user.updatePreferences({
      hideCollabAvatars: hideAvatars,
      showCollabCursors: showCursors,
    })
    currentScript?.pmEditor.rerender()
    controls.onClose()
  }

  return (
    <ModalShell
      title="Collaborator Preferences"
      onConfirm={togglePrefs}
      onClose={controls.onClose}
      confirmLabel="Save"
      cancelLabel="Cancel"
      size="md"
      opened={controls.opened}
    >
      <Group justify="space-between" wrap="nowrap">
        <Checkbox
          classNames={{
            body: styles.collabPreferenceModal_body,
            description: styles.collabPreferenceModal_description,
          }}
          label={<Text fw="bold">Show collaborators in margin</Text>}
          description="You'll see other people's avatars to the left of any text block their cursor is in"
          checked={!hideAvatars}
          onChange={() => setHideAvatars(!hideAvatars)}
        />
        <img src={avatars} alt="collaborator avatars" />
      </Group>
      <Group justify="space-between" wrap="nowrap">
        <Checkbox
          classNames={{
            body: styles.collabPreferenceModal_body,
            description: styles.collabPreferenceModal_description,
          }}
          label={<Text fw="bold">Show collaborator cursors</Text>}
          description="You'll see other people's cursors and text selections"
          checked={showCursors}
          onChange={() => setShowCursors(!showCursors)}
        />
        <img src={cursors} alt="collaborator cursors" />
      </Group>
    </ModalShell>
  )
}

export const CollabPreferenceModal = NiceModal.create(CollabPreferences)
export const showCollabPreferencesModal = () =>
  NiceModal.show(CollabPreferenceModal)
