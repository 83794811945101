import { types } from '@showrunner/codex'
import { getLastBlockType } from '../../prose-utils.js'
import { getBlockType, setEditorBlockType } from '@util'
import { dualTypes } from '@components/EditorToolbar/ElementMenu/display-data'
import mapping from './mapping.js'
/**
 * Given the current node and doc type, tells you what the next node should be
 * @param {string} blockType - yep
 * @param {string} lastBlockType - you guessed it
 * @param {string} docType - that's right
 * @param {boolean} [reverse] - are we going backwards? (shift+tab)
 * @returns {string} next node type
 */
function nextNodeOnTab(blockType, lastBlockType, docType, options) {
  const { reverse, dual } = options || {}
  const nodeTypes = dual
    ? dualTypes
    : lastBlockType
    ? mapping.tab[lastBlockType][docType]
    : mapping.tab.blank[docType]
  // no mapping for next node in current doc type, use fallback
  if (typeof nodeTypes === 'undefined') {
    if (docType === types.SCREENPLAY) {
      return types.ACTION
    }
    return types.DIALOGUE
  }
  let index = nodeTypes.indexOf(blockType)
  // if current node is not in list, use first
  if (index === -1) {
    return nodeTypes[0]
  }
  const len = nodeTypes.length
  if (reverse) {
    index--
    // return previous if index is valid
    if (index >= 0) {
      return nodeTypes[index]
    }
    return nodeTypes[len - 1] // return last
  }
  index++
  // if next index is less than length, it's valid
  if (index < len) {
    return nodeTypes[index]
  }
  // if next index is equal to or higher than length, start over
  return nodeTypes[0] // return first
}
function getTabHandler(docType, emit) {
  /**
   * Generalized tab handler. Handles Tab & Shift+Tab in various contexts.
   * @param {object} viewState - PM view state
   * @param {function} [viewDispatch] - PM event dispatcher
   * @param {boolean} reverse - reverse direction (shift)
   * @returns {boolean} required thing for PM input rules (always true)
   */
  return function handleTab(viewState, viewDispatch, reverse) {
    const { $from } = viewState.selection
    const dual = $from.depth > 2
    // skip it if we're in the first node of a dual dialogue column
    // because it can only be character
    if (dual && $from.index(3) === 0) {
      return true
    }
    const blockType = getBlockType(viewState)
    // skip tabbing if this is a new act or end of act
    if (blockType === types.NEW_ACT || blockType === types.END_OF_ACT) {
      return true
    }
    const lastBlockType = getLastBlockType(viewState)
    const nextBlockType = nextNodeOnTab(blockType, lastBlockType, docType, {
      reverse,
      dual,
    })
    // dont cycle block type via tab when the selection spans several
    if (nextBlockType != null) {
      const { $from, $to } = viewState.selection
      if ($from.parent.eq($to.parent)) {
        setEditorBlockType(viewState, viewDispatch, nextBlockType)
        const source = reverse ? 'shift+tab' : 'tab'
        emit('editor:elementChange', { source, type: nextBlockType })
      }
    }
    return true
  }
}
export { getTabHandler }
