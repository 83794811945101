import React from 'react'
import * as AgGrid from '@ag-grid-community/core'
import { useMst } from '@state'
import cn from 'classnames'
import { AgGridReact } from '@ag-grid-community/react'
import { observer } from 'mobx-react-lite'
import { IRundown } from '@state/types'
import { RundownRowData } from '@util/ScriptoApiClient/types'
import { getRowId } from '../RundownGrid/eventHandlers'
import { getPrintColumnDefs, DEFAULT_COLUMN_DEF } from '../RundownGrid/columns'
import { rowClassRules } from '../RundownGrid/helpers/rowClassRules'
import { PAGE_BREAK_BLOB_KEY } from '../RundownGrid/helpers/rowClassRules'

import './PrintableRundownGrid.scss'

/*
  Q: Why is there a <table> in this component?

  A: In order to get the grid header to print on every page, we're using the technique
  described here. This seems to do the trick everywhere except Safari (which doesn't support
  the @page directive).

  https://medium.com/@Idan_Co/the-ultimate-print-html-template-with-header-footer-568f415f6d2a

*/

export type PrintableRundownGridProps = {
  rundown: IRundown
  onWidthChange?: () => void
}

export type ColumnConfigs = Array<{
  colId: string
  width: number
}>

const PrintableRundownGridWithRef = (
  { rundown, onWidthChange }: PrintableRundownGridProps,
  printRef: React.LegacyRef<HTMLDivElement>
) => {
  const [gridReady, setGridReady] = React.useState(false)
  const { user } = useMst()
  const colDefs = getPrintColumnDefs({ rundown })
  const editable = user.canEditRundowns

  const handleColumnEvent = (e: AgGrid.ColumnEvent) => {
    rundown.saveColumnState(e.columnApi.getColumnState(), 'print')
    onWidthChange?.()
  }

  const handleDoubleClick = ({
    node,
  }: AgGrid.CellDoubleClickedEvent<RundownRowData>) => {
    const rowId = node.data?.id
    if (typeof rowId === 'number') {
      rundown.updateRowBlobData({
        rowId,
        columnKey: `blobData.${PAGE_BREAK_BLOB_KEY}`,
        value: !rundown
          .getRow(rowId)
          ?.getBlobValue(`blobData.${PAGE_BREAK_BLOB_KEY}`),
      })
    }
  }

  React.useEffect(() => {
    if (gridReady) {
      rundown.printableGridRef.current?.api.redrawRows()
    }
  }, [gridReady, rundown, user.rundownPrintPrefs.rowDensity])

  return (
    <div ref={printRef} className="printable-rundown-grid">
      <div
        className={cn('ag-theme-alpine', user.rundownPrintPrefs.rowDensity)}
        key={rundown.id}
      >
        <AgGridReact
          ref={rundown.printableGridRef}
          onGridReady={() => setGridReady(true)}
          onGridSizeChanged={onWidthChange}
          rowClassRules={rowClassRules}
          getRowId={getRowId}
          context={rundown}
          columnDefs={colDefs}
          rowData={rundown.immutableRowData}
          domLayout="print"
          suppressContextMenu
          onCellDoubleClicked={editable ? handleDoubleClick : undefined}
          defaultColDef={DEFAULT_COLUMN_DEF}
          onColumnVisible={handleColumnEvent}
          onColumnResized={(e) => {
            if (e.finished && handleColumnEvent) {
              handleColumnEvent(e)
            }
          }}
        />
      </div>
    </div>
  )
}

export const PrintableRundownGrid = observer(
  React.forwardRef(PrintableRundownGridWithRef)
)
